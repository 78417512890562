import React from 'react';
import Box from '@mui/material/Box';
import { useStyles } from '../Tooltip.styles';
import makeStyles from '@mui/styles/makeStyles';
import { HtmlTooltip } from '../HtmlTooltip/HtmlTooltip';
import { grey1000, grey200, petrol800 } from '../../helper/MuiStyles';

const useStyles2 = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: '16px',
  },
  number: {
    background: props => props.bgColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '20px',
    border: props => props.borderColor,
    textAlign: 'center',
    width: '41px',
    height: '30px',
    color: props => props.fontColor,
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.6px',
  },
}));

function SampleIcon(props) {
  const { sampleValue, hasTestCases, accessPoint, samples, index } = props;

  let iconValue = '';
  if (!hasTestCases) {
    iconValue = 'NOT_ASSIGNED';
  } else {
    if (index === 3) {
      iconValue = 'CURRENT';
    } else if (index === 2) {
      if (samples[3].hasTestCases) {
        iconValue = 'ASSIGNED';
      } else {
        iconValue = 'CURRENT';
      }
    } else if (index === 1) {
      if (samples[2].hasTestCases || samples[3].hasTestCases) {
        iconValue = 'ASSIGNED';
      } else {
        iconValue = 'CURRENT';
      }
    } else if (index === 0) {
      if (samples[1].hasTestCases || samples[2].hasTestCases || samples[3].hasTestCases) {
        iconValue = 'ASSIGNED';
      } else {
        iconValue = 'CURRENT';
      }
    }
  }


  const RESULT_MAPPER = {
    bordercolor: {
      'ASSIGNED': `1px solid ${grey200}`,
      'CURRENT': `1px solid ${petrol800}`,
      'NOT_ASSIGNED': `1px solid ${grey200}`,
    },
    bgcolor: {
      'ASSIGNED': grey200,
      'CURRENT': petrol800,
      'NOT_ASSIGNED': 'white',
    },
    fontcolor: {
      'ASSIGNED': grey1000,
      'CURRENT': 'white',
      'NOT_ASSIGNED': grey200,
    },
  }

  const styleProps = {
    borderColor: RESULT_MAPPER.bordercolor[iconValue],
    bgColor: RESULT_MAPPER.bgcolor[iconValue],
    fontColor: RESULT_MAPPER.fontcolor[iconValue],
  };

  const classes = useStyles();
  const classes2 = useStyles2(styleProps);

  function replaceBulk(str, findArray, replaceArray) {
    let i, regex = [], map = {};
    for (i = 0; i < findArray.length; i++) {
      regex.push(findArray[i].replace(/([-[\]{}()*+?.\\^$|#,])/g, '\\$1'));
      map[findArray[i]] = replaceArray[i];
    }
    regex = regex.join('|');
    str = str.replace(new RegExp(regex, 'g'), function (matched) {
      return map[matched];
    });
    return str;
  }

  return (
    <HtmlTooltip data-testid="tooltip"
      title={
        <Box display="flex " flexDirection="row" justifyContent="space-between">
          <Box display="flex " flexDirection="column" justifyContent="space-between">
            <Box display="flex " flexDirection="row" p={1}>
              <Box className={classes.title} style={{minWidth: '60px'}}>Sample:</Box>
              <Box ml={0.5} className={classes.value}>{replaceBulk(sampleValue, ['P', 'A', 'B', 'C'], ['Prototype', 'A-Sample', 'B-Sample', 'C-Sample'])}</Box>
            </Box>
          </Box>
        </Box>
      }
    >
      <div className={classes2.number} style={accessPoint === "Table" ? { marginLeft: '4px', marginRight: '4px' } : { marginRight: '8px' }}>
        {sampleValue}
      </div>
    </HtmlTooltip>
  )
}

export default SampleIcon;