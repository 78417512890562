import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { TEST_STATUS_MAPPER } from '../../other/helper/utilsMapper/utilsMapper'
import { grey1000, grey200 } from '../../other/helper/MuiStyles';


const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      padding: '16px'
    },
    number: ({ radius, width, height, margin, fontSize }) => ({ 
      background: grey200,
      borderRadius: radius || '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: width || '128px',
      height: height || '28px',
      color: grey1000,
      fontSize: fontSize,
      lineHeight: '32px',
      margin: margin || `0`,
      fontWeight: '700'
    }),
  }
  ));

function TestStatus(props) {
    const { testStatusName, width, height, radius, color, margin, sizeWindow } = props;

    const style_props = {
      width: width,
      height: height,
      borderRadius: radius,
      color: color,
      margin: margin,
      // fontSize: (sizeWindow < 5) ? '14px' : '16px',
      fontSize: (sizeWindow < 5) ? '14px' : (sizeWindow >= 5 ? '16px' : '6px'),
    }
    
    const classes = useStyles(style_props);

    return (
      <div className={classes.number}>
          {TEST_STATUS_MAPPER.NAME[testStatusName]}
      </div>
    )
}


export default TestStatus;