import React from 'react';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import { useStyles } from '../Tooltip.styles';
import { HtmlTooltip } from '../../../other/tooltips/HtmlTooltip/HtmlTooltip';
import { grey200, grey800, signalGreen800, signalRed800 } from '../../helper/MuiStyles';

const useStyles2 = makeStyles(() => ({
  percent_container_div: {
    width: "100%",
    height: "16px",
    backgroundColor: grey200,
    borderRadius: "8px",
    display: 'flex', //delete for percent_bars left and right
  },
  percent_bar_passed: {
    width: props => props.widthTestsPassed,
    height: "16px",
    backgroundColor: signalGreen800,
    borderRadius: "8px 0 0 8px",
    left: "0",
    zIndex: "2"
  },
  percent_bar_failed: {
    width: props => props.widthTestsFailed,
    height: "16px",
    backgroundColor: signalRed800,
    borderRadius: "0",
    zIndex: "2"
  },
  percent_bar_notevaluated: {
    width: props => props.widthTestsNotEvaluated,
    height: "16px",
    backgroundColor: grey800,
    borderRadius: "0 8px 8px 0",
    zIndex: "2"
  },
  text: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
  },
}
));

export default function TestResultInfo(props) {
  const { passedTests, failedTests, notEvaluatedTests, notAvailableTests, totalTestNumber, accessPoint } = props;

  const styleProps = {
    widthTestsPassed: (passedTests / totalTestNumber) * 100 + '%',
    widthTestsFailed: (failedTests / totalTestNumber) * 100 + '%',
    widthTestsNotEvaluated: (notEvaluatedTests / totalTestNumber) * 100 + '%'
  };

  const classes = useStyles();
  const classes2 = useStyles2(styleProps);

  return (
    <HtmlTooltip data-testid="tooltip"
      title={
        <Box display="flex " flexDirection="row" justifyContent="space-between">
          <Box display="flex " flexDirection="column">
            <Box display="flex " flexDirection="row" px={1} pt={1}>
              <Box className={classes.title} style={{ minWidth: '55px' }}>Passed:</Box>
              <Box ml={0.5} className={classes.value}>{passedTests}</Box>
            </Box>
            <Box display="flex " flexDirection="row" p={1}>
              <Box className={classes.title} style={{ minWidth: '55px' }}>Failed:</Box>
              <Box ml={0.5} className={classes.value}>{failedTests}</Box>
            </Box>
          </Box>
          <Box display="flex " flexDirection="column">
            <Box display="flex " flexDirection="row" px={1} pt={1}>
              <Box className={classes.title} style={{ minWidth: '105px' }}>Not Evaluated:</Box>
              <Box ml={0.5} className={classes.value}>{notEvaluatedTests}</Box>
            </Box>
            <Box display="flex " flexDirection="row" p={1}>
              <Box className={classes.title} style={{ minWidth: '105px' }}>Not Available:</Box>
              <Box ml={0.5} className={classes.value}>{notAvailableTests}</Box>
            </Box>
          </Box>
        </Box>
      }
    >
      {accessPoint === 'Table' ?
        <Box className={classes2.text}>{failedTests + passedTests + notEvaluatedTests} / {totalTestNumber}</Box>
        :
        <Box mx={2} mb={2} display="flex" justifyContent="center">
          <Box className={classes2.percent_container_div}>
            <Box id="passed" className={classes2.percent_bar_passed} style={failedTests === 0 && notEvaluatedTests === 0 && notAvailableTests === 0 ? { borderRadius: '8px' } : { borderRadius: '8px 0 0 8px' }}></Box>
            <Box id="failed" className={classes2.percent_bar_failed} style={passedTests === 0 && notEvaluatedTests === 0 && notEvaluatedTests === 0 ? { borderRadius: '8px' } : (passedTests === 0 ? { borderRadius: '8px 0 0 8px' } : ((notAvailableTests === 0 && notEvaluatedTests === 0) ? { borderRadius: '0 8px 8px 0' } : { borderRadius: '0' }))}></Box>
            <Box id="notevaluated" className={classes2.percent_bar_notevaluated} style={failedTests === 0 && passedTests === 0 && notAvailableTests === 0 ? { borderRadius: '8px' } : (passedTests === 0 && failedTests === 0 && notAvailableTests !== 0 ? { borderRadius: '8px 0 0 8px' } : (notAvailableTests === 0 ? { borderRadius: '0 8px 8px 0' } : { borderRadius: '0' }))}></Box>
          </Box>
        </Box>
      }
    </HtmlTooltip>
  )
}