import React, { useContext, useEffect, useState } from 'react';
import { Button, Dialog, IconButton, DialogContent, Box, Typography, InputBase, Select, MenuItem } from '@mui/material';
import { useStyles } from '../UniversalDialog.styles.js';
import Icon from '../../../../assets/icons/Icon.js';
import { grey400, grey600, petrol800, signalRed800 } from '../../helper/MuiStyles.js';
import { useMutation, useQuery } from '@apollo/client';
import { DOCUMENTCLASSES, PROJECTDETAIL, TESTCASE, TESTSPECIFICATION } from '../../../../operations/queries/query.js';
import { formatSize } from '../../helper/globalFunctions/globalFunctions.js';
import { UploadProgressContext } from '../../contexts/UploadContext.js';
import { UPLOADPROJECTDOCUMENT, UPLOADSUBSAMPLEDOCUMENT, UPLOADTESTCASEDOCUMENT, UPLOADTESTSPECIFICATIONDOCUMENT } from '../../../../operations/mutations/mutations.js';
import { currentProjectId, currentTestCaseId, currentTestSpecificationId } from '../../../../operations/localeStorage.js';
import { SnackbarContext, SnackbarType } from '../../contexts/SnackBarContext.js';
import { getDocVersion } from './UploadDocumentUtils.js';

const DOC_SIZE_LIMIT = 1.5e+10  // 15 Gb

function UploadDocument(props) {
    const {
        appendId,
        testSpecId,
        access,
        documents,
        maxCharacterName,
        isLocked
    } = props;
    const { setIsSnackbarOpen, setMessage, setSnackbarType } = useContext(SnackbarContext);

    const handleOnError = async (error) => {
        setMessage(error.message);
        setSnackbarType(SnackbarType.ERROR);
        setIsSnackbarOpen(true);
    }

    const handleOnCompleted = async () => {
        setSnackbarType(SnackbarType.SUCCESS);
        setIsSnackbarOpen(true);
    }

    const [uploadProjectDoc, { loading: uploadProjectDocLoading }] = useMutation(UPLOADPROJECTDOCUMENT, {
        onError: handleOnError,
        refetchQueries: [{ query: PROJECTDETAIL(currentProjectId()) }],
        awaitRefetchQueries: true,
        context: { isUpload: true }
    });
    const [uploadSubSampleDoc, { loading: uploadSubSampleDocLoading }] = useMutation(UPLOADSUBSAMPLEDOCUMENT, {
        onError: handleOnError,
        refetchQueries: [{ query: PROJECTDETAIL(currentProjectId()) }],
        awaitRefetchQueries: true,
        context: { isUpload: true }
    });
    const [uploadTestCaseDoc, { loading: uploadTestCaseLoading }] = useMutation(UPLOADTESTCASEDOCUMENT, {
        onError: handleOnError,
        refetchQueries: [{ query: TESTCASE(currentTestCaseId()) }],
        awaitRefetchQueries: true,
        context: { isUpload: true }
    });
    const [uploadTestSpecDoc, { loading: uploadTestSpecDocLoading }] = useMutation(UPLOADTESTSPECIFICATIONDOCUMENT, {
        onCompleted: handleOnCompleted,
        onError: handleOnError,
        refetchQueries: [{ query: TESTSPECIFICATION(currentTestSpecificationId()) }],
        awaitRefetchQueries: true,
        context: { isUpload: true }
    });

    let queryEnum = ""

    switch (access) {
        case "test specification":
            queryEnum = "TEST_SPECIFICATION";
            break;
        case "project":
            queryEnum = "PROJECT";
            break;
        case "test case":
            queryEnum = "TEST_CASE";
            break;
        case "sub sample":
            queryEnum = "SUB_SAMPLE";
            break;
        default:
            break;
    }

    const { uploadProgress } = UploadProgressContext();

    const { data: docClasses, loading: docClassesLoading, error: docClassesError } = useQuery(DOCUMENTCLASSES(queryEnum));

    const [docUpload, setDocUpload] = useState([]);
    const [show, setShow] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(false);

    const calcUploadSize = () => {
        let sum = 0;
        for (let i of docUpload) {
            sum += i.file?.size;
        }
        return formatSize(sum)
    };

    const isLoading = () => {
        return (docClassesLoading)
    };

    const hasError = () => {
        return docClassesError !== undefined
    };

    const ToggleModal = () => {
        setShow(!show);
    };

    const handleCancel = () => {
        setDocUpload([]);
        ToggleModal();
    };

    const handleNameChange = (index, event) => {
        const values = [...docUpload];
        values[index][event.target.name] = event.target.value;
        setDocUpload(values);

        values[index]['version'] = getDocVersion(documents.documents, docUpload, index);
        setDocUpload(values);
    };

    const handleCategoryChange = (index, event) => {
        const values = [...docUpload];
        values[index][event.target.name] = event.target.value;
        setDocUpload(values);

        values[index]['version'] = getDocVersion(documents.documents, docUpload, index);
        setDocUpload(values);
    };

    const documentHandler = (event) => {
        const newFiles = [...event.target.files].map((f) => ({
            name: f.name.slice(0, f.name.lastIndexOf('.')), 
            classification: '', 
            version: 1, 
            file: f, 
        }))
        if (newFiles.length) {
            setDocUpload([...docUpload, ...newFiles]);
        }
    };

    const handleRemoveDocument = (index) => {
        const values = [...docUpload];
        values.splice(index, 1);
        setDocUpload(values);
    };

    const addDocument = () => {
        if (access === "project") {
            for (let i of docUpload) {
                uploadProjectDoc({ variables: { projectId: appendId, classification: i.classification, name: i.name, version: i.version, file: i.file } });
            }
        }
        if (access === "test specification") {
            for (let i of docUpload) {
                uploadTestSpecDoc({ variables: { testSpecificationId: appendId, classification: i.classification, name: i.name, version: i.version, file: i.file } });
            }
        }
        if (access === "test case") {
            for (let i of docUpload) {
                uploadTestCaseDoc({ variables: { testCaseId: appendId, classification: i.classification, name: i.name, version: i.version, file: i.file } });
            }
        }
        if (access === "sub sample") {
            for (let i of docUpload) {
                uploadSubSampleDoc({ variables: { subSampleId: appendId, classification: i.classification, name: i.name, version: i.version, file: i.file } });
            }
        }
        setDocUpload([]);
        setShow(false);
    };

    useEffect(() => {
        if (documents !== undefined) {
            setSaveDisabled(false);

            for (const element of docUpload) {
                // check name not null
                if (element.name.length < 1) {
                    setSaveDisabled(true);
                }
                // check classification not null
                if (element.classification.length < 1) {
                    setSaveDisabled(true);
                }
                // check name > maxChar
                if (element.name.length > maxCharacterName) {
                    setSaveDisabled(true);
                }                
                // check file not null or bigger than limit
                if (element.file === null || element.file === undefined || element.file?.size > DOC_SIZE_LIMIT) {
                    setSaveDisabled(true);
                }
                // check if there already is a document with the same name but different classification
                const expectedClassification = documents.documents.find(d => d.name === element.name.trim())?.classification
                if (element.classification && expectedClassification !== undefined && expectedClassification.docClassId !== element.classification) {
                    setSaveDisabled(true);
                }
            }
        }
        else if (documents === undefined) {
            setSaveDisabled(true);
        }

    }, [documents, docUpload]) // eslint-disable-line

    const classes = useStyles();

    return <>
        {!testSpecId &&
            <Box display="flex" flexDirection="column">
                <>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={ToggleModal}
                        style={{ marginRight: '16px' }}
                        disabled={(Object.keys(uploadProgress).length !== 0 ? true : false) || isLocked}
                    >
                        <div style={{ display: 'flex', marginRight: '8px' }}><Icon iconName={"UploadIcon"} fill={isLocked ? grey400 : petrol800} /></div>Upload Document
                    </Button>
                    {(Object.keys(uploadProgress).length !== 0 || uploadProjectDocLoading || uploadSubSampleDocLoading || uploadTestCaseLoading || uploadTestSpecDocLoading) &&
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="subtitle2" className={classes.alert_message}>Wait until current upload is finished.</Typography>
                        </Box>
                    }
                </>
            </Box>
        }

        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={show}
            onClose={handleCancel}
        >
            <DialogContent>

                {!isLoading() && !hasError() &&
                    <>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography variant="h2">
                                Document Upload
                            </Typography>
                            <IconButton onClick={handleCancel} className={classes.hover_blue}>
                                <Icon iconName={"CloseIcon"} fill={petrol800} />
                            </IconButton>
                        </Box>
                        <Box mt={2} display="flex" alignItems="center">
                            <Typography variant="h4" style={{ fontWeight: '400' }}>
                                These documents will be assigned to the selected {access}. 
                                Be aware that a document cannot be larger than {formatSize(DOC_SIZE_LIMIT, 0)}. 
                                Please make sure to give the documents a correct title and category.
                            </Typography>
                        </Box>

                        {docUpload.length > 0 && docUpload.map((document, index) => {
                            // check if there already is a document with the same name but different classification
                            const expectedClassification = documents.documents.find(d => d.name === document.name.trim())?.classification
                            return (
                            <Box mt={3} key={index} className={classes.upload_box} style={document.file?.size > DOC_SIZE_LIMIT ? { border: `1px solid ${signalRed800}`, boxShadow: '0px 2px 4px rgba(218, 12, 31, 0.1), 0px 3px 4px rgba(218, 12, 31, 0.08), 0px 1px 5px rgba(218, 12, 31, 0.1)', } : {}}>
                                {/* UPLOAD BUTTON */}
                                <Box display='flex' flexDirection="column">
                                    <Box display='flex' alignItems="center" justifyContent="space-between">
                                        <Box display='flex'>
                                            <Typography variant="h5" style={{ lineHeight: '32px' }}>{document.file?.name}</Typography>
                                        </Box>
                                        <IconButton
                                            className={classes.hover_blue}
                                            onClick={() => handleRemoveDocument(index)}
                                        >
                                            <Icon iconName={"TrashIcon"} fill={petrol800} />
                                        </IconButton>
                                    </Box>
                                    {(document.file !== undefined && document.file !== null) &&
                                        <Box display='flex' alignItems="center">
                                            <Typography variant="h5" style={{ color: grey600, fontWeight: '400' }}>Version {document.version}</Typography>
                                            <Typography variant="h5" style={{ margin: '0px 8px', color: grey600, fontWeight: '400' }}>-</Typography>
                                            <Typography variant="h5" style={{ color: grey600, fontWeight: '400' }}>{formatSize(document.file?.size)}</Typography>
                                        </Box>
                                    }
                                    {document.file?.size > DOC_SIZE_LIMIT &&
                                        <Box display="flex" justifyContent="flex-start">
                                            <Typography variant="subtitle2" className={classes.alert_message}>
                                                A document cannot be larger than {formatSize(DOC_SIZE_LIMIT, 0)}. Please remove it or compress the document and upload it again.
                                            </Typography>
                                        </Box>
                                    }
                                </Box>
                                

                                {/* TITLE */}
                                <Box mt={3} display='flex' justifyContent="space-between">
                                    <Box mr={1.5} display='flex' flexDirection='column' justifyContent="flex-start" flexGrow="1" style={(document.file !== undefined && document.file !== null) ? { maxWidth: '412px' } : { maxWidth: '412px', opacity: 0.38 }}>
                                        <Typography variant="h6" className={classes.index_title}>
                                            Title *
                                        </Typography>
                                        <InputBase
                                            name="name"
                                            className={classes.box_freeText}
                                            multiline={true}
                                            fullWidth={true}
                                            value={document.name}
                                            onChange={(event) => handleNameChange(index, event)}
                                            error={ document.name.length > maxCharacterName }
                                        />
                                        {document.name.length > maxCharacterName &&
                                            <Box display="flex" justifyContent="space-between">
                                                <Typography variant="subtitle2" className={classes.alert_message}>The title must not contain more than {maxCharacterName} characters.</Typography>
                                                <Typography variant="subtitle2" className={classes.alert_message}>{document.name.length}/{maxCharacterName}</Typography>
                                            </Box>
                                        }
                                    </Box>

                                    {/* CLASSIFICATION */}
                                    <Box ml={1.5} display='flex' flexDirection='column' justifyContent="flex-start" flexGrow="1" style={(document.file !== undefined && document.file !== null) ? { maxWidth: '412px' } : { maxWidth: '412px', opacity: 0.38 }}>
                                        <Typography variant="h6" className={classes.index_title}>
                                            Category *
                                        </Typography>
                                        <Select
                                            data-testid="classification-select"
                                            name="classification"
                                            value={document.classification}
                                            onChange={(event) => handleCategoryChange(index, event)}
                                            error={ !!document.classification && !!expectedClassification && expectedClassification.docClassId !== document.classification }
                                        >
                                            {docClasses?.documentClasses.map((option) => (
                                                <MenuItem
                                                    key={option.docClassId}
                                                    value={option.docClassId}
                                                >
                                                    {option.classification}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                </Box>
                                <Box>
                                    { !!document.classification && !!expectedClassification && expectedClassification.docClassId !== document.classification && 
                                    <Typography variant="subtitle2" className={classes.alert_message}>
                                        Are you sure that you have selected the correct category? 
                                        Last time you uploaded a file with the same title, you chose the category {expectedClassification.classification}.
                                    </Typography>}
                                </Box>
                            </Box>)}
                        )}

                        <>
                            <input
                                style={{ display: 'none' }}
                                id="contained-button-file"
                                multiple
                                type="file"
                                name="file"
                                onChange={(event) => documentHandler(event)}
                            />
                            <label htmlFor="contained-button-file">
                                <Button variant="outlined" color="secondary" component="span" style={{ width: '130px', marginTop: '24px' }}>
                                    <div style={{ display: 'flex', marginRight: '8px' }}><Icon iconName={"AddIcon"} fill={petrol800} /></div>Add File
                                </Button>
                            </label>
                        </>

                        <Box mt={4} display="flex" alignItems="center" justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ width: '130px' }}
                                    disabled={saveDisabled || docUpload.length < 1}
                                    onClick={addDocument}
                                >
                                    Upload
                                </Button>
                                <Typography variant="h6" className={classes.required_tag}>
                                    * Mandatory field
                                </Typography>
                            </Box>
                            <Typography variant="h5">
                                Upload Size: {calcUploadSize()}
                            </Typography>
                        </Box>
                    </>
                }
            </DialogContent>
        </Dialog>
    </>
}

export default UploadDocument;