import React from 'react';
import Box from '@mui/material/Box';
import { HtmlTooltip } from '../HtmlTooltip/HtmlTooltip';
import { useStyles } from '../Tooltip.styles';

export default function ParameterInfo(props) {
    const { parameter, description, active } = props;
    const classes = useStyles();

    return (
        <HtmlTooltip data-testid="tooltip" maxwidth="500px"
            title={
                <Box display="flex " flexDirection="row" justifyContent="space-between">

                    <Box display="flex " flexDirection="column" justifyContent="space-between">
                        <Box display="flex " flexDirection="row" px={1} pt={1}>
                            <Box className={classes.title} style={{ minWidth: '90px' }}>Parameter:</Box>
                            <Box ml={0.5} className={classes.value}>{parameter}</Box>
                        </Box>
                        <Box display="flex " flexDirection="row" p={1}>
                            <Box className={classes.title} style={{ minWidth: '90px' }}>Description:</Box>
                            <Box ml={0.5} className={classes.value}>{description}</Box>
                        </Box>
                        {!active &&
                            <Box display="flex " flexDirection="row" p={1}>
                                <Box className={classes.title}>This parameter was deactivated by an admin. You can still use it for this test specification, but you cannot add it to a test specification.</Box>
                            </Box>
                        }
                    </Box>

                </Box>
            }
        >
            <div style={active ? { maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' } : { maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', opacity: 0.38 }}>{parameter}</div>
        </HtmlTooltip>
    )
}