import React, { useState, useEffect } from 'react';

import { useNavigate } from "react-router-dom";
import { Box, Paper, Typography, Grid, IconButton } from '@mui/material';
import Counter from '../../other/helper/Counter/Counter';
import { handleCategoryIcon } from '../../other/helper/IconHandler/IconHandler';
import Icon from '../../../assets/icons/Icon.js';
import { useStyles } from '../editing/Table.styles';
import FilterButton from '../../other/helper/FilterButton/FilterButton';
import TableFilter, { upDateFilter } from '../table_utils/templates/TableFilter';
import CollapseRow from '../table_utils/CollapseRow';
import { grey800, petrol800 } from '../../other/helper/MuiStyles';
import PlanningTable from './PlanningTable';


export default function PlanningTableLayout({ id, categoryTests, testStatuses, testInstances, testCaseList }) {
    const navigate = useNavigate();

    let sizeWindow;

    switch (true) {
        case window.innerWidth < 1576:
            sizeWindow = 4;
            break;
        case window.innerWidth < 1872:
            sizeWindow = 5;
            break;
        case window.innerWidth < 2168:
            sizeWindow = 6;
            break;
        case window.innerWidth < 2464:
            sizeWindow = 7;
            break;
        case window.innerWidth < 2760:
            sizeWindow = 8;
            break;
        case window.innerWidth >= 2760:
            sizeWindow = 9;
            break;
        default:
            sizeWindow = 4;
            break;
    }

    const [allTestCases, setAllTestCases] = useState(0);
    const [collapsed, setCollapsed] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [activeTestInstances, setActiveTestInstances] = useState([]);
    const [activeTestStatuses, setActiveTestStatuses] = useState([]);
    const [activeTestCases, setActiveTestCases] = useState([]);
    const [zoomLevel, setZoomLevel] = useState(2);

    const [MIN_ZOOM_LEVEL, MAX_ZOOM_LEVEL] = [0, 3];

    const zoomIn = () => {
        if (zoomLevel < MAX_ZOOM_LEVEL) {
            setZoomLevel(zoomLevel + 1)
        }
    }

    const zoomOut = () => {
        if (zoomLevel > MIN_ZOOM_LEVEL) {
            setZoomLevel(zoomLevel - 1)
        }
    }

    const handleTestInstanceClick = (event, testInstance) => {
        setActiveTestInstances(testInstance);
    };

    const handleTestStatusClick = (event, testStatus) => {
        setActiveTestStatuses(testStatus);
    };

    //inital load of testCases in activeTestCase array
    useEffect(() => {
        setActiveTestCases(testCaseList?.testCases);
    }, [testCaseList]);


    //update activeTestCase array if changes on filters occur
    useEffect(() => {
        upDateFilter(setActiveTestCases, testCaseList, activeTestInstances, testInstances, activeTestStatuses, testStatuses);
    }, [activeTestInstances, activeTestStatuses, testCaseList, testInstances, testStatuses]);

    const styleProps = {
        fontSizeTable: (sizeWindow < 5) ? '14px' : '16px',
    };

    const classes = useStyles(styleProps);

    return categoryTests?.testCases?.length > 0 ?
        <Grid container spacing={3}>
            <Grid item xs={12} display="grid">
                <Paper className={classes.paper}>
                    <Box display="flex" justifyContent="space-between" className={classes.head}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Box display="flex" alignItems="center"><div style={{ display: 'flex', alignItems: 'center', paddingLeft: sizeWindow < 5 ? '8px' : '12px', paddingRight: sizeWindow < 5 ? '8px' : '12px' }}><Icon iconName={handleCategoryIcon(categoryTests?.category?.name)} /></div></Box>
                                <Typography variant="h3" className={classes.title}>
                                    {categoryTests?.category?.name}
                                </Typography>
                            </Box>
                            <Box m={2}>
                                <Counter number={activeTestCases?.length} />
                            </Box>
                        </Box>

                        <Box mr={3} display="flex" alignItems="center" justifyContent="space-between">
                            <IconButton 
                                className={classes.zoomButton} 
                                size="small" 
                                aria-label="Zoom Out" 
                                onClick={zoomOut} 
                                disabled={zoomLevel <= MIN_ZOOM_LEVEL}
                            >
                                    <Icon iconName={"ZoomOutIcon"} fill={petrol800} />
                            </IconButton>
                            <IconButton 
                                className={classes.zoomButton} 
                                size="small" 
                                aria-label="Zoom In" 
                                onClick={zoomIn} 
                                style={{ margin: '0 16px' }} 
                                disabled={zoomLevel >= MAX_ZOOM_LEVEL}
                            >
                                <Icon iconName={"ZoomInIcon"} fill={petrol800} />
                            </IconButton>
                            <FilterButton
                                filterState={!showFilters}
                                activecount={(activeTestInstances?.length + activeTestStatuses?.length)}
                                onClick={() => setShowFilters(!showFilters)}
                            />
                        </Box>
                    </Box>
                    {showFilters &&
                        <TableFilter
                            activeTestInstances={activeTestInstances}
                            activeTestStatuses={activeTestStatuses}
                            handleTestInstanceClick={handleTestInstanceClick}
                            handleTestStatusClick={handleTestStatusClick}
                            testInstances={testInstances}
                            testStatuses={testStatuses}
                        />
                    }
                   
                   {activeTestCases.length > 0 && 
                        <PlanningTable 
                            testCases={activeTestCases?.filter((item, idx) => idx < 3 + allTestCases)} 
                            zoomLevel={zoomLevel} 
                            sizeWindow={sizeWindow}
                            onTestCaseClick={(test) => {
                                    navigate({
                                        pathname: `/testcasedetails/${id}/${test.testCaseId}`,
                                        state: test,
                                        category: categoryTests.category,
                                    });
                            }} />}

                    {3 < categoryTests?.testCases?.length &&
                        <CollapseRow collapsed={collapsed} setAllTestCases={setAllTestCases} setCollapsed={setCollapsed} allTestCases={allTestCases} testCaseList={testCaseList} sizeWindow={sizeWindow}/>
                    }
                    {activeTestCases?.length < 1 &&
                        <Box className={classes.border}>
                            <Box display="flex" alignItems="center" justifyContent="center" style={{ lineHeight: '48px', color: grey800 }}>No test case available for currently selected filters.</Box>
                        </Box>
                    }
                </Paper>
            </Grid>
        </Grid>
        : null;
}