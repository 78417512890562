import makeStyles from '@mui/styles/makeStyles';
import { buttonHoverPetrol800, buttonPressedPetrol800, grey1000, grey200, grey600, petrol100, petrol800, petrol900 } from '../../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    button_add_parameter: {
        marginLeft: '24px',
    },
    selected_button_selected: {
        backgroundColor: buttonPressedPetrol800,
    },
    error_icon_wrapper: {
        marginLeft: '8px',
        transform: 'rotate(45deg)',
        '&:hover': {
            backgroundColor: buttonHoverPetrol800,
        },
    },
    hover_blue: {
        '&:hover': {
            backgroundColor: buttonHoverPetrol800,
        },
    },
    filter_icon: {
        display: 'flex',
        marginLeft: '8px',
    },
    search: {
        width: '216px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      search_icon: {
        padding: '0 16px',
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      search_field: {
        "& #search-parameter": {
          width: '184px',
          paddingLeft: '32px !important',
        },
      },
    freeText: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '28px',
        letterSpacing: '0.3px',
        padding: '5px 24px 5px 8px',
        color: grey600,
        border: `1px solid ${grey600}`,
        boxSizing: "border-box",
        borderRadius: "2px",
        minWidth: '350px',
    },
    toggle_button_group: {
        display: 'flex !important',
        flexWrap: 'wrap !important',
        borderRadius: 'none !important',
    },
    filter_number: {
        marginLeft: '8px',
        height: '24px',
        minWidth: '24px',
        borderRadius: '24px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '0.8px',
        backgroundColor: '#006384',
        color: '#FFFFFF',
    },
    parameter_button: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        borderRadius: "8px !important",
        boxShadow: 'none !important',
        textTransform: 'none',
        padding: '8px 16px',
        marginRight: '16px',
        marginBottom: '16px',
        marginLeft: '0',
        cursor: 'pointer',
        height: '38px',
        border: `1px solid ${petrol800} !important`,
        color: petrol800,
        '&:hover': {
            backgroundColor: `${buttonHoverPetrol800} !important`,
          },
    },
    parameter_button_selected: {
        backgroundColor: '#006384 !important',
        borderColor: `${petrol800} !important`,
        color: '#FFF !important',
        '&:hover': {
            backgroundColor: `${petrol900} !important`,
            borderColor: `${petrol900} !important`,
          },
    },
    parameter_selected: {
        lineHeight: '24px',
        margin: '0px 0px 0px 16px',
    },
    parameter_box: {
        maxWidth: '880px',
        minHeight: '79px',
        borderRadius: '2px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.1)',
        marginBottom: '16px',
    },
    list_header: {
        height: '58px',
    },
    line: {
        borderBottom: `1px solid ${grey200}`,
    },
    parameter_title: {
        color: grey1000,
        marginTop: '16px',
        marginLeft: '24px',
        letterSpacing: '0.6px',
        marginBottom: '4px',
    },
    parameter_unit: {
        marginTop: '16px',
        marginLeft: '8px',
        letterSpacing: '0.6px',
        fontWeight: '400',
        color: grey600,
        marginBottom: '4px',
    },
    parameter_description: {
        letterSpacing: '0.8px',
        fontWeight: '400',
        color: grey600,
        marginLeft: '24px',
        marginBottom: '16px',
    },
    selected_parameter_box: {
        backgroundColor: petrol100,
        padding: '0px 16px',
        borderRadius: '8px',
        marginRight: '8px',
        marginBottom: '8px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '40px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    cancel_button: {
        minWidth: '105px',
    },
    add_button: {
        minWidth: '80px',
    },
    error_text: {
        marginTop: '8px',
        fontWeight: '400',
    },
    error_type: {
        padding: '0px 24px',
        textAlign: 'center',
        marginTop: '4px',
        fontWeight: '400',
        color: grey600,
    },
    list: {
        maxHeight: '50vh',
        overflowY: "auto",
        paddingLeft: '8px',
        paddingRight: '8px',
        marginTop: '24px',
        paddingTop: '8px',
        marginBottom: '24px',
        paddingBottom: '8px',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: grey600,
            borderRadius: '8px',
        }
    },
}));