import React, { useState } from 'react';
import { Dialog, IconButton, DialogContent, Box, Tab, Tabs } from '@mui/material';
import Icon from '../../../../assets/icons/Icon.js';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import { buttonHoverPetrol800, petrol800, grey600 } from '../../helper/MuiStyles.js';
import EmployeeData from './EmployeeData.js';
import EmployeesVWGroup from './EmployeesVWGroup.js';
import EmployeesVWPartners from './EmployeesVWPartners.js';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, padding: '32px' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const CustomTabs = withStyles(() => ({
    root: {
        minHeight: "38px",
        display: "flex",
    },
    indicator: {
        backgroundColor: "transparent",
    }
}))(Tabs)

const CustomTab = withStyles(() => ({
    root: {
        minHeight: "38px",
        minWidth: "220px",
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '32px',
        letterSpacing: '0.6px',
        color: grey600,
        whiteSpace: 'nowrap',
        maxWidth: 'none'
    },
    selected: {}
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles(() => ({
    tab_box: {
        color: petrol800,
    },
    button: {
        padding: '0px',
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0)"
        }
    },
    hover_blue: {
        '&:hover': {
            backgroundColor: buttonHoverPetrol800,
          },
    },
}));

function PrivacyPolicy() {
    const [show, setShow] = useState(false);

    const ToggleModal = () => {
        setShow(!show);
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const classes = useStyles();

    return <>
        <IconButton onClick={ToggleModal} className={classes.button} size="large">
            <Icon
                fill="white"
                iconName={"LegalIcon"}
                width={32}
                height={32}
            />
        </IconButton>

        <Dialog
            fullWidth={true}
            maxWidth="xl"
            open={show}
            onClose={ToggleModal}
        >
            <DialogContent sx={{ paddingLeft: 0, paddingRight: 0 }}>
                <Box sx={{ boxShadow: '0px 2px 8px 0px #00000033', alignItems: 'center' }} display="flex" flexDirection="row" >
                    <Box display="flex" sx={{ flex: '1 auto', justifyContent: 'center' }}>
                        <CustomTabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            className={classes.tab_box}
                            centered
                        >
                            <CustomTab label={"Beschäftigtendaten"} {...a11yProps(0)} />
                            <CustomTab label={"Beschäftigte des Volkswagen Konzerns"} {...a11yProps(1)} />
                            <CustomTab label={"Beschäftigte der Volkswagen Partner (Markt Deutschland)"} {...a11yProps(2)} />
                        </CustomTabs>
                    </Box>
                    <Box display="flex" sx={{ marginRight: '32px', height: 'fit-content' }}>
                        <IconButton onClick={ToggleModal} className={classes.hover_blue}>
                            <Icon iconName={"CloseIcon"} fill={petrol800} />
                        </IconButton>
                    </Box>
                </Box>
                <Box>
                    <CustomTabPanel value={value} index={0}>
                        <EmployeeData />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <EmployeesVWGroup />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <EmployeesVWPartners />
                    </CustomTabPanel>
                </Box>
            </DialogContent>
        </Dialog>
    </>
}

export default PrivacyPolicy;