
import React, { useContext, useState } from 'react';
import { Button, Dialog, IconButton, DialogContent, Box, Typography, InputAdornment, InputBase } from '@mui/material';

import { useStyles } from '../UniversalDialog.styles.js';
import Icon from '../../../../assets/icons/Icon.js';
import { SnackbarContext, SnackbarType } from '../../contexts/SnackBarContext.js';
import { petrol800, grey1000, buttonPressedPetrol800 } from '../../helper/MuiStyles.js';
import { isCDSLoading } from '../../../../operations/localeStorage.js';

const EditGrid = ({ values, unit, parseValue, buttonLabel, header, info, errorInfo, createMutation, deleteMutation }) => {
    const { setIsSnackbarOpen, setMessage, setSnackbarType } = useContext(SnackbarContext);

    const [show, setShow] = useState(false)
    const [userInput, setUserInput] = useState('')
    const [collection, setCollection] = useState({ items: [] })

    const toggleModal = () => { 
        if (!show) {
            setUserInput('')
            setCollection({ items: values.map(v => ({ value: v, isNew: false, isRemoved: false })) })
        }
        setShow(!show) 
    }

    const addToCollection = (value) => {
        const newValue = parseInt(value)
        let newCollection = collection['items']
        const ind = newCollection.findIndex(item => item.value === newValue)

        if (ind > -1) {
            newCollection[ind].isRemoved = false
        }
        else {
            newCollection = newCollection.concat([ { value: newValue, isRemoved: false, isNew: true } ])
               .sort((a, b) => a.value - b.value)
        }

        setCollection({ items: newCollection })
    }

    const removeFromCollection = (value) => {
        const newCollection = collection['items']
        const ind = newCollection.findIndex(item => item.value === value)
        if (newCollection[ind].isNew)  {
            newCollection.splice(ind, 1)
        }
        else {
            newCollection[ind].isRemoved = true
        }
        setCollection({ items: newCollection })
    }

    const isInputValid = (input) => {
        if (input === '') {
            return true
        }

        const parsedInput = parseValue(input)
        // if parsed correctly and is new
        return parsedInput !== null && !isNaN(parsedInput) && 
            !collection['items'].filter(item => !item.isRemoved).map(item => item.value).includes(parsedInput)
    }

    const update = () => {
        isCDSLoading(true);
        toggleModal();

        const promises = collection['items'].filter(item => item.isRemoved || item.isNew).map((item) => {
            if (item.isNew) {
                return createMutation(item.value)
            }
            if (item.isRemoved) {
                return deleteMutation(item.value)
            }
        })

        Promise.allSettled(promises).then((response) => {
            const rejected = response.filter(item => item.status !== 'fulfilled')
            if (rejected.length === 0) {
                setMessage('Grid values have been updated')
                setSnackbarType(SnackbarType.SUCCESS)
            }
            else {
                setMessage(rejected.map(item => item.reason).join('\n\n'))
                setSnackbarType(SnackbarType.ERROR)
            }

            isCDSLoading(false);
            setIsSnackbarOpen(true);
        });
    }

    const classes = useStyles();

    return (
        <>
            <Button
                variant="outlined"
                color="secondary"
                onClick={toggleModal}
                style={{ marginLeft: '16px' }}
            >
                <div style={{ display: 'flex', marginRight: '8px' }}>
                    <Icon iconName={"EditIcon"} fill={petrol800} />
                </div>
                {buttonLabel}
            </Button>

            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={show}
                onClose={toggleModal}
            >
                <DialogContent>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h2">
                            {header}
                        </Typography>
                        <IconButton onClick={toggleModal} className={classes.hover_blue}>
                            <Icon iconName={"CloseIcon"} fill={petrol800} />
                        </IconButton>
                    </Box>

                    <Box display='flex' flexDirection='column' flexGrow="1">
                        <Typography className={classes.grid_level_info}>
                            {info}
                        </Typography>
                    </Box>

                    <Box display='flex' justifyContent="flex-start" flexWrap="wrap">
                        {collection['items'].map( (item) => {

                            let style = {}
                            if (item.isRemoved) {
                                style = { 'opacity': '0.2' }
                            }
                            else if (item.isNew) {
                                style = { 'backgroundColor': buttonPressedPetrol800 }
                            }

                            return (
                                <Box key={item.value} className={classes.standard_box} display="flex" style={style} aria-label="grid-item"> 
                                    <Box display='flex'>{item.value} {unit}</Box>
                                    
                                    {item.isRemoved && 
                                    <IconButton 
                                        onClick={() => { addToCollection(item.value) }} >
                                        <Icon iconName={"AddIcon"} fill={grey1000} />
                                    </IconButton>}

                                    {!item.isRemoved && 
                                    <IconButton className={classes.delete_icon_wrapper} 
                                        onClick={() => { removeFromCollection(item.value) }} >
                                        <Icon iconName={"AddIcon"} fill={grey1000} />
                                    </IconButton>}
                                </Box>
                            )
                        })}
                        <InputBase
                            value={userInput}
                            onChange={event => setUserInput(event.target.value.trim())}
                            error={!isInputValid(userInput)}
                            placeholder={'New value'}
                            style={{ borderRadius: '8px', width: '160px', height: '40px' }}
                            endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label='Add new value'
                                    className={classes.add_unit_button}
                                    onClick={ () => { 
                                        addToCollection(userInput)
                                        setUserInput('')
                                     } }
                                    disabled={userInput === '' || !isInputValid(userInput)}
                                    edge="end"
                                  >
                                    <Icon iconName={"AddIcon"} fill={petrol800} />
                                  </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Box>

                    {!isInputValid(userInput) && 
                        <Box display='flex' flexDirection='column' flexGrow="1">
                            <Typography className={`${classes.grid_level_info} ${classes.alert_message}`}>
                                Duplicated value or Incorrect input format. {errorInfo}
                            </Typography>
                        </Box>
                    }

                    <Box display='flex' flexDirection='column' flexGrow="1">
                        <Typography className={classes.grid_level_info}>
                            Please note that the updated grid points will be set for all Power Limits tables belonging to this sub sample.
                        </Typography>
                    </Box>

                    <Box display='flex' flexDirection='column' flexGrow="1">       
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ width: '130px' }}
                            disabled={collection['items'].filter(item => item.isRemoved || item.isNew).length === 0}
                            onClick={() => { update() }}
                        >
                            Update
                        </Button>
                    </Box>   
                </DialogContent>
            </Dialog>
        </>
    )
}

export default EditGrid