import React from 'react';
import { Box, IconButton } from '@mui/material';
import { useStyles } from '../SupplierManagement.styles';
import clsx from 'clsx';
import { Link } from "react-router-dom";
import { IMAGE_MAP } from '../../../other/helper/ImgMapper/ImgMapper';
import Icon from '../../../../assets/icons/Icon';
import DeleteData from '../../../other/dialogs/DeleteData/DeleteData';
import { petrol800 } from '../../../other/helper/MuiStyles';

function SupplierManagementListElement(props) {
    const { className, i, mutateFunction } = props;

    const styleProps = {
        supplierImg: IMAGE_MAP.SUPPLIER[i.name],
    };
    const classes = useStyles(styleProps);

    return (
        <Box display="flex" >
            <Box className={clsx(className, classes.header1)} display="flex">
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Box pl={3} pr={2} style={i.active ? {} : { opacity: 0.38 }}>{i.name}</Box>
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" className={clsx(className, classes.header2)}>
                <Box my={2} mr={1}>
                    <div className={classes.supplierimage} title="SupplierLogo"></div>
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" className={clsx(className, classes.header3)}>
                <Box pr={2} style={i.active ? {} : { opacity: 0.38 }}>
                    {i.rating === null ? '-' : `${i.rating.name}`}
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" className={clsx(className, classes.header4)}>
                <Box pr={2} display="flex" flexWrap="wrap" style={i.active ? {} : { opacity: 0.38 }}>
                    {i.supplierPortfolio.map((supplierObject, index) => {
                        return (
                            <Box key={supplierObject.name} style={index === i.supplierPortfolio.length - 1 ? {} : { marginRight: '4px' }}>{supplierObject.name + (index === i.supplierPortfolio.length - 1 ? '' : ', ')}</Box>
                        )
                    })}
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" className={clsx(className, classes.header5)}>
                <Box pr={2} style={i.active ? {} : { opacity: 0.38 }}>
                    {(i.city === null || i.city === "") ? '-' : `${i.city}`}
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" className={clsx(className, classes.header6)}>
                <Box pr={2} style={i.active ? {} : { opacity: 0.38 }}>
                    {i.contact === null ? '-' : `${i.contact.firstName} ${i.contact.lastName}`}
                </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end" className={clsx(className, classes.header7)}>
                {i.active &&
                    <Box display="flex" flexDirection="column" justifyContent="center">
                        <IconButton
                            aria-controls={'menu-list-grow'}
                            aria-haspopup="true"
                            component={Link} to={`/editsupplier/${i.cellSupplierId}`}
                            className={classes.hover_blue}
                            style={{ padding: '4px' }}
                        >
                            <Icon
                                fill={petrol800}
                                iconName={"EditIcon"}
                                width={24}
                                height={24}
                            />
                        </IconButton>
                    </Box>
                }
                <Box mr={3} display="flex" flexDirection="column" justifyContent="center">
                    <DeleteData headline={'Supplier'} cellSupplier={i} name={i.name} idValue={i.cellSupplierId} mutateFunction={mutateFunction} activate={i.active ? false : true} />
                </Box>
            </Box>
        </Box>
    );
}

export default SupplierManagementListElement;