import React from 'react';
import { Typography } from '@mui/material';

import { styles, Link } from './PrivacyPolicyComponents';

function EmployeesVWGroupEN() {

    const classes = styles();

    return (<>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            In this document, we inform you about the collection, processing and use of your personal data as 
            an employee of a company in the Volkswagen Group (“Group employees”) by Volkswagen AG as the data controller 
            for purposes of data protection.
        </Typography>

        {/* SECTION A */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            A. Controller
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            The entity responsible for data processing is Volkswagen AG, Berliner Ring 2, 38440 Wolfsburg, Germany, 
            entered in the register of companies of the district court of Braunschweig under HRB 100484 (“Volkswagen AG”).
        </Typography>

        {/* SECTION B */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            B. Processing of your personal data
        </Typography>

        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            We process your personal data in connection with, inter alia,
        </Typography>
        <ul className={classes.list}>
            <li>
                Your <b>cooperation</b> with (employees) of Volkswagen AG (e.g. email communication, preparing, 
                taking part in and documenting appointments, events and business trips (e.g. Group management information, 
                international meetings, trade fairs, conferences, doctoral research conferences, visiting memorial sites), 
                collaboration in cross-brand projects, as a contact person for divisions and departments)
            </li>
            <li>
                <b>Documentation</b> of the use of company equipment (e.g. vehicles, image and sound technology, 
                IT hardware) as well as personalisation and assignment to a specific person of resources 
                (e.g. work clothes, protective clothing, keys, telecommunications devices)
            </li>
            <li>
                <b>Ensuring your occupational health and safety</b> (e.g. accident analyses, occupational health and 
                safety training courses)
            </li>
            <li>
                Ensuring <b>in-house safety</b> and <b>health</b> of employees (e.g. in order to track the spread 
                of infections)
            </li>
            <li>
                Professional <b>use of IT systems</b> (e.g. as part of user administration for accessing 
                the Volkswagen AG systems you use, logging changes made in IT systems, in support cases, 
                access controls for ensuring secure system operation, system tests for quality assurance, 
                operation of IT infrastructure)
            </li>
            <li>
                <b>Defending against digital risks</b> to you and the company (e.g. ensuring the confidentiality, 
                integrity and availability of all data, identifying cyber attacks and countermeasures)
            </li>
            <li>
                Your <b>qualification and further training</b> provided throughout the Group 
                (e.g. your specific development programmes and exams for leadership or expert duties, 
                participation in qualification measures)
            </li>
            <li>
                Your <b>participation in events</b> (e.g. trade fairs, congresses, meetings, conferences)
            </li>
            <li>
                Your <b>participation in employee surveys</b> (e.g. the “Sentiment barometer” employee survey)
            </li>
            <li>
                Processing your <b>company improvement ideas</b> (e.g. Group idea management)
            </li>
            <li>
                The <b>exchange of your data within the Group</b> (e.g. cooperation in cross-brand projects, 
                successor planning, transfer of retirement claims, international remuneration rounds, 
                performance/development assessments)
            </li>
            <li>
                Business trips you may undertake (e.g. travel planning, hotel and flight reservations, 
                reimbursement of expenses, provision of information on safety and risks)
            </li>
            <li>
                Use of the <b>cashless payment function</b> of the Group card (plant ID cards) for services 
                (e. g. catering and canteens of Volkswagen AG) and products (e.g. accessories and brand articles, 
                refuelling vehicles for business purposes)
            </li>
            <li>
                Conducting <b>audit activities</b> of the Group Audit department of the Volkswagen Group on behalf 
                of the Group Board of Management or the Investigation Office
            </li>
            <li>
                The possible <b>provision of non-cash benefits</b> or other tax-relevant matters (e.g. invitation 
                to sports and cultural events, trade fairs, dealer events, documentation of hospitality recipients) 
                to ensure proper taxation and statutory contributions as well as the proper issuing of an invitation 
                in accordance with company compliance requirements
            </li>
            <li>
                Enforcing, exercising and <b>defending legal claims</b>. In pertinent cases, relevant personal data 
                may be transferred by Volkswagen AG to investigative authorities (e.g. tax office, police, 
                public prosecutor&apos;s office) and courts.
            </li>
            <li>
                <b>Reporting processes</b> (e.g. to the supervisory authority in order to fulfil the statutory obligation 
                of reporting data protection violations in accordance with Article 33 GDPR)
            </li>
            <li>
                The creation of <b>statistics and benchmarks</b> (e.g. remuneration comparisons)
            </li>
        </ul>

        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            The following categories of personal data are processed as part of the processes named above:
        </Typography>
        <ul className={classes.list}>
            <li>
                <b>Professional contact and (work) organisational data</b> (e.g. surname, first name, work address, 
                work email address and telephone number, personnel number, company, division, department, cost centre, 
                signature)
            </li>
            <li>
                <b>Information on personal/professional circumstances and attributes</b> (e.g. job description, 
                years spent working for the Group, tasks performed, qualifications)
            </li>
            <li>
                <b>Contract data</b> (e.g. accommodation contracts, senior expert contracts)
            </li>
            <li>
                <b>IT user data</b> (e.g. user ID, passwords, system rights, IP addresses)
            </li>
            <li>
                <b>Employee photo</b>
            </li>
            <li>
                <b>Particularly sensitive personal data</b> (e.g. health data)
            </li>
        </ul>

        {/* SECTION C */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            C. Legal basis for collecting your personal data
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Your personal data is collected, processed and transferred only for the intended purpose and only 
            if there is a pertinent legal basis for doing so.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            The purposes described above are based on the following legal justifications:
        </Typography>
        <ul className={classes.list}>
            <li>
                Your <b>consent</b> (Article 6 (1) (a) GDPR) (e.g. employee photo)
            </li>
            <li>
                Contract to which the data subject is party or in order to take steps at the request of the data subject 
                prior to entering into a contract (Article 6 (1) (b) GDPR)
            </li>
            <li>
                For compliance with a legal obligation to which the controller is subject (Article 6 (1) (c) GDPR) 
                (special regulations not covered by the GDPR, such as income tax law in connection with general fiscal 
                regulations regarding the retention of taxation- relevant documents the German Infection Protection Act 
                [Infektionsschutzgesetz])
            </li>
            <li>
                <b>Collective agreements</b> (e.g. Group works agreements)
            </li>
            <li>
                Protect the vital interests of the data subject or of another natural person (Article 6 (1) (d) GDPR) 
                (e.g. contact lists for pandemics)
            </li>
            <li>
                A legitimate interests (Article 6 (1) (f) GDPR)
            </li>
        </ul>

        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Our legitimate interests are, among other things, in simplifying, or enabling cooperation within the Group, 
            in communication with the individual Group companies and in exercising our function as a parent company. 
            In addition, we have a legitimate interest in documenting and ensuring work results and 
            strengthening cooperation between the individual companies.
        </Typography>

        {/* SECTION D */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            D. Transfer of your personal data to third parties
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            In individual cases we transfer your personal data to authorised third parties. They may include:
        </Typography>
        <ul className={classes.list}>
            <li>
                <b>Processors</b> (e.g. to IT service providers as part of the Enterprise Help Desk Germany support)
            </li>
            <li>
                <b>Group companies</b> (e.g. in minutes of meetings)
            </li>
            <li>
                <b>Public authorities</b> (e.g. financial administration as part of requests for information and 
                external tax audits, official/judicial measures for the purposes of collecting evidence, prosecution 
                and enforcement of civil claims on the part of investigative authorities and courts
            </li>
            <li>
                <b>Service providers</b> (e.g. hotels and airlines in the context of business travel)
            </li>
        </ul>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            When transferring personal data to third countries (countries that are neither a member of the 
            European Union nor the European Economic Area), Volkswagen AG strictly complies with the relevant 
            data protection regulations. For example, Volkswagen AG only transmits personal data to processors 
            (e.g. IT service providers) in third countries if the EU Commission has passed a resolution that this is 
            appropriate for the respective third country or if the data transfer is adequately protected by EU standard 
            contract clauses as created by the EU Commission that have been concluded with the processor. The standard 
            contractual clauses can be found under the following URL
            <Link href={"https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32010D0087&from=DE"}>
                https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32010D0087&from=DE
            </Link>.
        </Typography>

        {/* SECTION E */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            E. Retention and erasure periods for your personal data
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Your personal data is processed and stored only for as long as required for the purpose in question or 
            as prescribed by law.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            The following list is intended to give you an overview of some of the periods of time after which we erase 
            your personal data once it is no longer needed for the intended purpose:
        </Typography>
        <ul className={classes.list}>
            <li>
                <b>Up to 180 days:</b> data regarding personal/professional circumstances and attributes 
                (e.g. application documentation)
            </li>
            <li>
                <b>2 to 4 years:</b> particularly sensitive data within the framework of the labour law / work 
                regulations (e.g. warnings or formal warnings) and time management data (e.g. certificates for authorities)
            </li>
            <li>
                <b>10 years:</b> data regarding personal/professional circumstances and characteristics as well 
                as professional contact and (work) organisational data within the scope of recruitment, personnel support 
                and development (e.g. job title, employment history, activities, qualifications, assessments/evaluations) 
                and particularly sensitive personal data (e.g. documentation of accidents at work)
            </li>
            <li>
                <b>15 years:</b> bank and credit details and payroll data (e.g. income tax bracket, bank account, 
                special payments/bonuses)
            </li>
        </ul>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            In certain circumstances, your personal data must also be kept for a longer period, e.g. in relation to a 
            legal hold or litigation hold (i.e. a prohibition of data deletion for the duration of the legal proceedings) 
            that is ordered in connection with an official or legal case.
        </Typography>

        {/* SECTION F */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            F. Your rights
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            You may exercise the following rights vis-a-vis Volkswagen AG at any time free of charge. 
            You can obtain further information about asserting your rights in Section G.
        </Typography>
        <ul className={classes.list}>
            <li>
                <b>Right to information:</b> You have the right to obtain information from us regarding 
                the processing of your personal data.
            </li>
            <li>
                <b>Right to rectification:</b> You have the right to request that we rectify any of your personal data 
                that may be inaccurate or incomplete.
            </li>
            <li>
                <b>Right to deletion:</b> You have the right to have your personal data deleted if the conditions set out 
                in Article 17 of the GDPR are met. For example, you may ask for your data to be deleted if it is 
                no longer necessary for the purposes for which it was collected. You may also ask for your data to 
                be deleted if we process your data based on your consent and you withdraw that consent
            </li>
            <li>
                <b>Right to restriction of processing:</b> You have the right to ask for a restriction of the processing 
                of your personal data if the conditions set out in Article 18 of the GDPR are met. That is the case, 
                for example, if you dispute the accuracy of your personal data. You can then demand a restriction of 
                processing for the period it takes to verify the accuracy of the data.
            </li>
            <li>
                <b>Right of data transferability:</b> You have the right to receive your personal data in a structured, 
                commonly used and machine-readable format and to transfer this personal data to another data processor, 
                provided that data processing is based on consent or contract fulfilment and that automated 
                processing methods are used.
            </li>
            <li style={{ border: '1px solid black' }}>
                <b>Right to object:</b> You have the right to object to the processing of your personal data if 
                the processing is based on an overriding interest or if your personal data is used for the purpose of 
                direct marketing. If you object to the processing of your personal data, please notify us of the grounds 
                for your objection. You also have the right to object to data processing for the purposes of 
                direct marketing. The same applies to profiling, insofar as it is related to direct marketing.
            </li>
            <li>
                <b>Right to withdraw consent:</b> Where data processing is based on consent, you have the right to 
                withdraw your consent to data processing, with future effect, at any time free of charge.
            </li>
            <li>
                <b>Right of complaint:</b> You also have the right to lodge a complaint about our processing of 
                your personal data with a supervisory authority (such as the Data Protection Commissioner for the State 
                of Lower Saxony in Germany [Landesbeauftragte für den Datenschutz Niedersachsen]).
            </li>
        </ul>

        {/* SECTION G */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            G. Your contacts
        </Typography>
        <ul className={classes.list}>
            <li>
                <Typography variant="h5" marginTop={'8px'} align='left'>
                    Contacts for exercising your rights
                </Typography>
                <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
                    The contact persons for exercising your rights and further information can be found
                    on the following website:
                </Typography>
                <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
                    <Link href={"https://datenschutz.volkswagen.de/"}>datenschutz.volkswagen.de</Link>
                </Typography>
            </li>
            <li>
                <Typography variant="h5" marginTop={'8px'} align='left'>
                    Data Protection Officer
                </Typography>
                <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
                    If you have any data protection concerns, contact our Data Protection Officer:
                </Typography>
                <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
                    <div>Data Protection Officer at Volkswagen AG</div> 
                    <div>Berliner Ring 2</div>
                    <div>38440 Wolfsburg</div>
                </Typography>
                <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
                    <a href="mailto:datenschutz@volkswagen.de">datenschutz@volkswagen.de</a>
                </Typography>
            </li>
        </ul>

        {/* Stand */}
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'}>
            Date: <b>08.03.2021</b>
        </Typography>
    </>)
}

export default EmployeesVWGroupEN;