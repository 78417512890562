import React from 'react';
import { Box, IconButton } from '@mui/material';
import { useStyles } from '../DocumentList.styles';
import { timeConverterComment } from '../../helper/timeConverter/timeConverter';
import { IMAGE_MAP } from '../../helper/ImgMapper/ImgMapper';
import { formatSize } from '../../helper/globalFunctions/globalFunctions';
import { petrol800 } from '../../helper/MuiStyles';
import Icon from '../../../../assets/icons/Icon';
import DeleteDocument from '../../dialogs/DeleteDocument/DeleteDocument';
import { projectRole } from '../../../../operations/localeStorage';

function DocumentListElement(props) {
    const {
        document,
        access,
        sizeWindow,
        isLocked
    } = props;

    const role = projectRole();

    const styleProps = {
        fontSizeTable: (sizeWindow < 5 && access === "sub sample") ? '14px' : '16px',
    };

    const classes = useStyles(styleProps);

    return <>
        <Box key={document.docId} display="flex" className={classes.border} >


            {/* DOCUMENT NAME */}
            <Box display="flex" alignItems="center" className={classes.header1_text} >
                <Box className={classes.document_name} style={{ paddingLeft: '16px' }}>{document.name}</Box>
            </Box>

            {/* VERSION */}
            <Box display="flex" alignItems="center" className={classes.header2_text} >
                <Box className={classes.text}>{document.version}</Box>
            </Box>

            {/* SIZE */}
            <Box display="flex" alignItems="center" className={classes.header3_text} >
                <Box className={classes.text}>{formatSize(document.size)}</Box>
            </Box>

            {/* TYPE */}
            <Box display="flex" alignItems="center" className={classes.header4_text} >
                <Box className={classes.text}>{document.type}</Box>
            </Box>

            {/* CATEGORY */}
            <Box display="flex" alignItems="center" className={classes.header5_text} >
                <Box className={classes.text}>{document.classification.classification}</Box>
            </Box>

            {/* UPLOADER */}
            <Box display="flex" alignItems="center" className={classes.header6_text} >
                <div className={classes.userimage}>
                    <img src={IMAGE_MAP.AVATAR[document.uploadedBy.avatarId]?.pngSrc} alt="avatar" />
                </div>
                <Box className={classes.text}>{document.uploadedBy.firstName} {document.uploadedBy.lastName}</Box>
            </Box>

            {/* UPLOAD DATE */}
            <Box display="flex" alignItems="center" className={classes.header7_text} >
                <Box className={classes.text}>{timeConverterComment(document.uploadedAt)}</Box>
            </Box>

            <Box display="flex" justifyContent="flex-end" alignItems="center" className={classes.header8}>
                {/* DOWNLOAD */}
                <div style={{ display: 'flex', flexDirection: "column", justifyContent: "center", paddingRight: ((role === 'Administrator') && (!isLocked)) ? '8px' : '16px' }}>
                    <IconButton
                        className={classes.hover_blue}
                        style={{ maxWidth: '32px' }}
                        onClick={() => window.open(document.location, "_blank")}
                    >
                        <Icon
                            fill={petrol800}
                            iconName={"DownloadIcon"}
                        />
                    </IconButton>
                </div>
                {/* DELETE */}
                {((role === 'Administrator') && (!isLocked)) &&
                    <Box mr={2} display="flex" flexDirection="column" justifyContent="center">
                        <DeleteDocument docId={document.docId}/>
                    </Box>
                }
            </Box>

        </Box>
    </>;
}

export default DocumentListElement;