import React from 'react';
import Box from '@mui/material/Box';
import { useStyles } from '../Tooltip.styles';
import { HtmlTooltip } from '../HtmlTooltip/HtmlTooltip';
import { grey600 } from '../../helper/MuiStyles';
import Icon from '../../../../assets/icons/Icon';

export default function RequirementIdsInfo() {

    const classes = useStyles();

    return (
        <HtmlTooltip data-testid="tooltip" maxwidth="600px"
            title={
                <Box display="flex " flexDirection="column" p={1}>
                    <Box className={classes.title}>You can add one or multiple requirement IDs here. A requirement ID can have one to five digits. To add multiple requirement IDs enter number, a semicolon and a space followed by another number. Your input has to end with a number.</Box>
                    <Box pt={1} className={classes.title}>Examples:</Box>
                    <Box className={classes.value}>- 56789</Box>
                    <Box className={classes.value}>- 56789; 78</Box> 
                    <Box className={classes.value}>- 56789; 78; 78392; 9; 7; 6783; 890</Box>             
                </Box>
            }
        >
            <Box ml={1} display="flex" alignItems="center"><Icon iconName={"InfoIcon"} height={20} width={20} fill={grey600}/></Box>
        </HtmlTooltip>
    )
}