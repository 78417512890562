import makeStyles from '@mui/styles/makeStyles';
import { grey1000, grey200, signalGreen800, signalRed800, grey800, grey600, petrol800 } from "../../../../other/helper/MuiStyles";


export const useStyles = makeStyles(() => ({
    normalButton: {
      color: grey1000,
      width: 'fit-content',
      padding: '0px 8px',
      borderRadius: '8px !important',
      border: `2px solid ${petrol800} !important`,
      boxShadow: 'none !important',
      marginRight: '16px',
      marginBottom: '4px',
      fontStyle: 'normal',
      fontSize: '10px',
      lineHeight: '24px',
      letterSpacing: '0.6px',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: `${petrol800} !important`,
        color: '#FFF',
        cursor: 'pointer'
      },
    },
    supplierimage: {
      backgroundImage: props => `url(${props.supplierImg})`,
      backgroundPosition: 'right',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      height: '35px',
      minWidth: '100px',
      marginTop: '8px',
    },
    meta_width: {
      width: 'calc(100% / 3)'
    },
    meta_title: {
      color: grey1000, letterSpacing: '0.8px',
      lineHeight: '24px', fontSize: '10px'
    },
    meta_value: {
      display: 'flex',
      justifyContent: 'flex-end',
      lineHeight: '24px',
      letterSpacing: '0.8px',
      fontSize: '10px', fontWeight: '800'
    },
    single_bar_container: {
      fontSize: '10px', width: "100%"
    },
    dual_bar_container: {
      fontSize: '10px', width: '50%'
    },
    category_bar_text: {
      fontWeight: 'bold'
    },
    percent_container_div: {
      width: "100%",
      height: "10px",
      backgroundColor: grey200,
      borderRadius: "8px",
      display: 'flex',
    },
    percent_bar_passed: {
      width: props => props.widthTestsPassed,
      height: "10px",
      backgroundColor: signalGreen800,
      borderRadius: "8px 0 0 8px",
      left: "0",
      zIndex: "2"
    },
    percent_bar_failed: {
      width: props => props.widthTestsFailed,
      height: "10px",
      backgroundColor: signalRed800,
      borderRadius: "0",
      zIndex: "2"
    },
    percent_bar_notevaluated: {
      width: props => props.widthTestsNotEvaluated,
      height: "10px",
      backgroundColor: grey800,
      borderRadius: "0 8px 8px 0",
      zIndex: "2"
    },
    seperator: {
      height: '1px',
      borderTop: `1px dotted ${grey200}`,
    },
    // Font needs to bee explict added to export module
    my_content: {
      fontFamily: 'VWAG TheSans-Regular, sans-serif'
    },
    table: {
      width: '100%',
      fontSize: '8px',
      borderCollapse: 'collapse',
      tableLayout: 'auto',
      // border: '1px solid black',
      // margin: '0 32px 0 32px',
      "& th": {
        padding: '3px',
        textAlign: 'left',
        fontWeight: 'normal',
        color: grey600,
        fontSize: '8px'
      },
      "& td": {
        padding: '3px',
        textAlign: 'left',
        color: grey600,
        // "display": "-webkit-box",
        // "WebkitLineClamp": "3",
        // "WebkitBoxOrient": "vertical",
        // "overflow": "hidden",
        // "textOverflow": "ellipsis",
        // whiteSpace: 'nowrap'
      },
    },
    category_name: {
      borderRadius: '8px',
      border: `1px solid ${grey200}`,
      width: 'max-content',
      height: '16px',
      display: 'flex',
      alignItems: 'center',
    },
    testcase_name: {
      // width: '60%'
      // "display": "box",
      // "WebkitLineClamp": "2",
      // "WebkitBoxOrient": "vertical",
      // "overflow": "hidden",
      // whiteSpace: 'nowrap',
      // "textOverflow": "ellipsis"
    },
    comments_field: {
      border: `1px solid ${grey200}`,
      width: 'calc(100%-2px)',
      height: '290px'
    },
    signature_box: {
      border: `1px solid ${grey200}`,
      width: '18%',
      height: '100px'
    },
    signature_field: {
      width: '80%',
      borderTop: `1px solid ${grey200}`,
      color: grey200,
      textAlign: 'right',
      fontSize: '12px',
      margin: '55% 10% 20% 10%'
    }
  }));