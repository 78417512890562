import React from 'react';
import { Paper, Grid, Box, IconButton } from '@mui/material';
import { useStyles } from '../ProjectList.styles';
import Icon from '../../../../assets/icons/Icon.js';
import ProjectListElement from '../ProjectListElement/ProjectListElement';
import { dynamicSort } from '../../../other/helper/globalFunctions/globalFunctions';
import { grey800 } from '../../../other/helper/MuiStyles';

function ProjectListTable(props) {
    const { projects, sortTitleProjects, setSortTitleProjects, setTestSpecKey, testSpecKey, sortCapacityProjects, setSortCapacityProjects, sortSampleProjects, setSortSampleProjects, sortSupplierProjects, setSortSupplierProjects } = props;

    const classes = useStyles();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} display="grid">
                <Paper className={classes.paper}>

                    <Box className={classes.head} />
                    <Box px={4} display="flex" alignItems="center" className={classes.header}>
                        <Box display="flex" alignItems="center" className={classes.header2}>Supplier <IconButton data-testid={"project-filter-cell-supplier-button"} style={sortSupplierProjects === true ? { marginLeft: '8px', padding: '4px' } : { transform: 'rotate(-180deg)', marginLeft: '8px', padding: '4px' }} onClick={() => { projects.sort(dynamicSort(sortSupplierProjects === true ? "name" : "-name", "supplier")); setSortSupplierProjects(!sortSupplierProjects); setTestSpecKey(!testSpecKey) }}><Icon iconName={"DirectionIcon"} width={14} height={14} fill={grey800} /></IconButton></Box>
                        <Box display="flex" alignItems="center" className={classes.header1}>Title <IconButton data-testid={"project-filter-name-button"} style={sortTitleProjects === true ? { marginLeft: '8px', padding: '4px' } : { transform: 'rotate(-180deg)', marginLeft: '8px', padding: '4px' }} onClick={() => { projects.sort(dynamicSort(sortTitleProjects === true ? "name" : "-name")); setSortTitleProjects(!sortTitleProjects); setTestSpecKey(!testSpecKey) }}><Icon iconName={"DirectionIcon"} width={14} height={14} fill={grey800} /></IconButton></Box>
                        <Box display="flex" alignItems="center" className={classes.header3}>Capacity <IconButton data-testid={"project-filter-capacity-button"} style={sortCapacityProjects === true ? { marginLeft: '8px', padding: '4px' } : { transform: 'rotate(-180deg)', marginLeft: '8px', padding: '4px' }} onClick={() => { sortCapacityProjects === true ? projects.sort(function (a, b) { return a.nominalCapacity - b.nominalCapacity; }) : projects.sort(function (a, b) { return b.nominalCapacity - a.nominalCapacity; }); setSortCapacityProjects(!sortCapacityProjects); setTestSpecKey(!testSpecKey) }}><Icon iconName={"DirectionIcon"} width={14} height={14} fill={grey800} /></IconButton></Box>
                        <Box className={classes.header4}>Vehicle Platform</Box>
                        <Box display="flex" alignItems="center" className={classes.header6}>Sample State <IconButton data-testid={"project-filter-sample-state-button"} style={sortSampleProjects === true ? { marginLeft: '8px', padding: '4px' } : { transform: 'rotate(-180deg)', marginLeft: '8px', padding: '4px' }} onClick={() => { sortSampleProjects === true ? projects.sort(function (a, b) { return a.sort - b.sort; }) : projects.sort(function (a, b) { return b.sort - a.sort; }); setSortSampleProjects(!sortSampleProjects); setTestSpecKey(!testSpecKey) }}><Icon iconName={"DirectionIcon"} width={14} height={14} fill={grey800} /></IconButton></Box>
                        <Box className={classes.header5}>Total Test Results</Box>
                        <Box className={classes.header7}>Team</Box>
                    </Box>

                    {projects?.map((project) => {
                        return (
                            <ProjectListElement
                                project={project}
                                key={project.projectId}
                            />)
                    })}

                </Paper>
            </Grid>
        </Grid>
    )
}

export default ProjectListTable;