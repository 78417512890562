import React, {useState, useEffect, useContext} from 'react';
import { Button, Dialog, IconButton, DialogContent, Box, Typography, InputBase } from '@mui/material';
import { useStyles } from '../UniversalDialog.styles.js';
import Icon from '../../../../assets/icons/Icon.js';
import { SnackbarContext } from '../../contexts/SnackBarContext.js';
import { buttonHoverGrey800, grey1000, petrol600, petrol800 } from '../../helper/MuiStyles.js';

function AddEditData(props) {
    const {
        edit,
        headline,
        name,
        idValue,
        mutateFunction,
        maxCharacter,
        activeValues,
        inactiveValues,
        queryName,
        isTSNameUpdate = false
    } = props;
    const { setMessage } = useContext(SnackbarContext);

    const [show, setShow] = useState(false);
    const [newValue, setNewValue] = useState("");
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [maxCharExceeded, setMaxCharExceeded] = useState(false);
    const [alreadyActive, setAlreadyActive] = useState(false);
    const [alreadyInactive, setAlreadyInactive] = useState(false);

    useEffect(() => {
        if (activeValues !== undefined && inactiveValues !== undefined) {
            setSaveDisabled(false);
            setMaxCharExceeded(false);
            setAlreadyActive(false);
            setAlreadyInactive(false);

            if (newValue.length > maxCharacter) {
                setSaveDisabled(true);
                setMaxCharExceeded(true);
            }

            if (newValue === name) {
                setSaveDisabled(true);
            }

            for (let i = 0; i < activeValues[queryName]?.length; i++) {
                if (activeValues[queryName][i]['name'].toUpperCase() === newValue.toUpperCase()) {
                    setSaveDisabled(true);
                    if (newValue !== name) {
                        setAlreadyActive(true);
                    }
                    break;
                }
            }

            for (let i = 0; i < inactiveValues[queryName]?.length; i++) {
                if (inactiveValues[queryName][i]['name'].toUpperCase() === newValue.toUpperCase()) {
                    setSaveDisabled(true);
                    setAlreadyInactive(true);
                    break;
                }
            }
        }
        else if (activeValues === undefined || inactiveValues === undefined) {
            setSaveDisabled(true);
        }
    }, [activeValues, inactiveValues, newValue]) // eslint-disable-line

    useEffect(() => {
        if (name !== undefined) {
            setNewValue(name);
        }
    }, [name]) // eslint-disable-line

    const ToggleModal = () => {
        setShow(!show);
    };

    const handleCancel = () => {
        if (edit) {
            setNewValue(name);
        } else {
            setNewValue("");
        }
        ToggleModal();
    };

    const handleNameChange = () => (event) => {
        setNewValue(event.target.value);
    };

    const handleSave = () => {
        if (isTSNameUpdate) {
            mutateFunction({ variables: { oldName: name, newName: newValue } });
        }
        else {
            mutateFunction({ variables: { id: idValue, active: true, name: newValue } });
        }
        setNewValue("");
        setMessage(`${headline} has been updated.`);
        setShow(false);
    };

    const addData = () => {
        mutateFunction({ variables: { name: newValue } });
        setNewValue("");
        setMessage(`${headline} has been added.`);
        setShow(false);
    };

    const styleProps = {
        editIconWrapperMarginLeft: '8px',
        editIconHoverBackground: buttonHoverGrey800,
        addButtonMarginBottom: '16px',
        addButtonBackgroundColor: '#FFF',
      };

    const classes = useStyles(styleProps);

    return (
        <>
            {edit ?
                <IconButton className={classes.edit_icon_wrapper} onClick={ToggleModal} style={{ alignSelf: 'end' }}>
                    <Icon iconName={"EditIcon"} fill={grey1000} />
                </IconButton>
                :
                <Button variant="contained" color="secondary" className={classes.add_button} onClick={ToggleModal}>
                <div style={{ display: 'flex', marginRight: '8px' }}><Icon iconName={"AddIcon"} fill={petrol600} /></div> Add more
                </Button>
            }

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={show}
                onClose={handleCancel}
            >
                <DialogContent>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h2">
                            {edit ? 'Edit' : 'Add'} {edit ? name : headline}
                        </Typography>
                        <IconButton onClick={handleCancel} className={classes.hover_blue}>
                            <Icon iconName={"CloseIcon"} fill={petrol800} />
                        </IconButton>
                    </Box>
                    <Box mt={2} display="flex" alignItems="center">
                        <Typography variant="h4" style={{ fontWeight: '400' }}>
                            Please assign a new name to this {headline}.
                        </Typography>
                    </Box>
                    <Box mt={3} display="flex" justifyContent="space-between">
                        <Box display='flex' flexDirection='column' justifyContent="flex-start" flexGrow="1">
                            <Typography variant="h6" className={classes.index_title}>
                                Name *
                            </Typography>
                            <InputBase
                                name="newValue"
                                className={classes.box_freeText}
                                multiline={true}
                                fullWidth={true}
                                placeholder="Add new name"
                                value={newValue}
                                onChange={handleNameChange()}
                                error={maxCharExceeded || alreadyActive || alreadyInactive}
                            />
                        </Box>
                    </Box>
                    {maxCharExceeded &&
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="subtitle2" className={classes.alert_message}>The name must not contain more than {maxCharacter} characters.</Typography>
                            <Typography variant="subtitle2" className={classes.alert_message}>{newValue.length}/{maxCharacter}</Typography>
                        </Box>
                    }
                    {alreadyActive &&
                        <Box display="flex" justifyContent="flex-start">
                            <Typography variant="subtitle2" className={classes.alert_message}>This name is already in use by another active {headline}.</Typography>
                        </Box>
                    }
                    {alreadyInactive &&
                        <Box display="flex" justifyContent="flex-start" flexWrap="wrap">
                            <Typography variant="subtitle2" className={classes.alert_message}>This name already belongs to an inactive {headline}. <br /> Please reactivate that instead.</Typography>
                        </Box>
                    }
                    <Box mt={4} display="flex" alignItems="center">
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={newValue?.length < 1 || saveDisabled}
                            onClick={edit ? handleSave : addData}
                        >
                            Save
                        </Button>
                        <Typography variant="h6" className={classes.required_tag}>
                            * Mandatory field
                        </Typography>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AddEditData;