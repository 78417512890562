import makeStyles from '@mui/styles/makeStyles';
import { buttonHoverPetrol800, grey100, grey1000, grey200, grey600, grey800, petrol600 } from '../../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    arrow_hover: {
      padding: '8px',
      '&:hover': {
        backgroundColor: buttonHoverPetrol800,
      },
    },
    addtestspec: {
      marginTop: '64px',
      marginBottom: '64px',
      padding: '24px',
      border: `1px dashed ${grey200}`,
      color: grey800,
    },
    error_text: {
      marginTop: '8px',
      fontWeight: '400',
    },
    error_type: {
      padding: '0px 24px',
      textAlign: 'center',
      marginTop: '4px',
      fontWeight: '400',
      color: grey600,
    },
    toggle_button_group: {
      display: 'flex !important',
      flexWrap: 'wrap !important',
      borderRadius: 'none !important',
    },
    documentButton: {
      backgroundColor: grey100,
      color: grey1000,
      padding: '0px 16px',
      borderRadius: '8px !important',
      border: '0 !important',
      boxShadow: 'none !important',
      marginRight: '0px',
      marginBottom: '8px',
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: '40px',
      letterSpacing: '0.6px',
      textTransform: 'capitalize',
    },
    normalButton: {
      backgroundColor: petrol600,
      color: '#FFF',
      padding: '0px 16px',
      borderRadius: '8px !important',
      border: '0 !important',
      boxShadow: 'none !important',
      marginRight: '16px',
      marginBottom: '8px',
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: '40px',
      letterSpacing: '0.6px',
      textTransform: 'capitalize',
    },
    divider: {
      height: '1px',
      backgroundColor: grey200,
    }
  }));