import React, { useContext }  from 'react';
import { useMutation } from '@apollo/client';

import { 
    CREATESOC, 
    DELETESOC, 
    CREATETEMPERATURE, 
    DELETETEMPERATURE, 
    CREATEPULSETIME, 
    DELETEPULSETIME, 
} from '../../../../operations/mutations/mutations.js';
import { CELLDATASHEET } from '../../../../operations/queries/query.js';
import { CDSContext } from '../../contexts/CDSContext.js';
import EditGrid from './EditGrid.js';

export const EditSoc = ({ values, subSampleId }) => {
    const { currentPowerOCV } = useContext(CDSContext);

    const parseValue = (val) => {
        const matchRes = val.match(/^\d+$/)
        const parsedVal = matchRes !== null ? parseInt(matchRes[0]) : NaN
        return !isNaN(parsedVal) && parsedVal >= 0 && parsedVal <= 100 ? parsedVal : null
    }

    const [createMutation] = useMutation(CREATESOC, { 
        refetchQueries: [{ query: CELLDATASHEET(subSampleId, currentPowerOCV) }], 
        awaitRefetchQueries: true 
    });
    const [deleteMutation] = useMutation(DELETESOC, { 
        refetchQueries: [{ query: CELLDATASHEET(subSampleId, currentPowerOCV) }], 
        awaitRefetchQueries: true 
    })

    return (<EditGrid 
        values={values}
        unit={'%'}
        parseValue={parseValue}
        buttonLabel={'Edit SOC'}
        header={'Edit SOC Levels for Power Limits'}
        info={'SOC Level grid points can only be integer values between 0 and 100.'}
        errorInfo={'Only integer values between 0 and 100 allowed.'}
        createMutation={ 
            (value) => createMutation({ 
                variables: { subSampleId: subSampleId, soc: value, cellDataType: currentPowerOCV } 
            }) 
        }
        deleteMutation={
            (value) => deleteMutation({ 
                variables: { subSampleId: subSampleId, soc: value, cellDataType: currentPowerOCV } 
            }) 
        }
    />)
}

export const EditTemperature = ({ values, subSampleId }) => {
    const { currentPowerOCV } = useContext(CDSContext);

    const parseValue = (val) => {
        const matchRes = val.match(/^-*\d+$/)
        const parsedVal = matchRes !== null ? parseInt(matchRes[0]) : NaN
        return !isNaN(parsedVal) && parsedVal >= -30 && parsedVal <= 80 ? parsedVal : null
    }

    const [createMutation] = useMutation(CREATETEMPERATURE, { 
        refetchQueries: [{ query: CELLDATASHEET(subSampleId, currentPowerOCV) }], 
        awaitRefetchQueries: true 
    });
    const [deleteMutation] = useMutation(DELETETEMPERATURE, { 
        refetchQueries: [{ query: CELLDATASHEET(subSampleId, currentPowerOCV) }], 
        awaitRefetchQueries: true 
    })

    return (<EditGrid 
        values={values} 
        unit={'°C'}
        parseValue={parseValue}
        buttonLabel={'Edit Temperature'}
        header={'Edit Temperature Areas for Power Limits'}
        info={'Temperature Area grid points can only be integer values between -30 and 80.'}
        errorInfo={'Only integer values between -30 and 80 allowed.'}
        createMutation={ 
            (value) => createMutation({ 
                variables: { subSampleId: subSampleId, temperature: value, cellDataType: currentPowerOCV } 
            }) 
        }
        deleteMutation={
            (value) => deleteMutation({ 
                variables: { subSampleId: subSampleId, temperature: value, cellDataType: currentPowerOCV } 
            }) 
        }
    />)
}

export const EditPulseTime = ({ values, subSampleId }) => {
    const { currentPowerOCV } = useContext(CDSContext);

    const parseValue = (val) => {
        const matchRes = val.match(/^\d+$/)
        const parsedVal = matchRes !== null ? parseInt(matchRes[0]) : NaN
        return !isNaN(parsedVal) && parsedVal >= 0 ? parsedVal : null
    }

    const [createMutation] = useMutation(CREATEPULSETIME, { 
        refetchQueries: [{ query: CELLDATASHEET(subSampleId, currentPowerOCV) }], 
        awaitRefetchQueries: true 
    });
    const [deleteMutation] = useMutation(DELETEPULSETIME, { 
        refetchQueries: [{ query: CELLDATASHEET(subSampleId, currentPowerOCV) }], 
        awaitRefetchQueries: true 
    })

    return (<EditGrid 
        values={values} 
        unit={'s'}
        parseValue={parseValue}
        buttonLabel={'Edit Pulse Time'}
        header={'Edit Pulse Times for Power Limits'}
        info={'Pulse Time grid points can only be integer values greater than or equal to 0.'}
        errorInfo={'Only integer values greater or equal to 0 allowed.'}
        createMutation={ 
            (value) => createMutation({ 
                variables: { subSampleId: subSampleId, pulseTime: value } 
            }) 
        }
        deleteMutation={
            (value) => deleteMutation({ 
                variables: { subSampleId: subSampleId, pulseTime: value } 
            }) 
        }
    />)
}