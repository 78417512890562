import React, { useEffect, useRef } from 'react';
import { Box, Paper, Typography, Grid } from '@mui/material';

import TestStatus from '../table_utils/TestStatus';
import TestCaseInfo from '../../other/tooltips/TestCaseInfo/TestCaseInfo';
import { formatDate, getGrid, getPositionProperties, getGridInterval, getTimelineParts } from './PlanningTableUtils';

import { useStyles } from './PlanningTable.styles'
import { useStyles as useTableStyles } from '../editing/Table.styles';
import { grey200, blue700, red700 } from '../../other/helper/MuiStyles';
import { HtmlTooltip } from '../../other/tooltips/HtmlTooltip/HtmlTooltip';
import { useStyles as useTooltipStyles } from '../../other/tooltips/Tooltip.styles';

const InterractivePlanningTable = ({ testCases, zoomLevel, sizeWindow, onTestCaseClick }) => {
    const GRID_LEVELS = [
        ['year', 'quarter'],
        ['quarter', 'month'],
        ['month', 'week'],
        ['week', 'day'],
    ]

    const tableClasses = useTableStyles()
    const customStyles = useStyles()
    const tooltipStyles = useTooltipStyles()
    
    const timelineWrapper = useRef(null)
    const timelineHeader = useRef(null)

    const [level1GridLevel, level2GridLevel] = GRID_LEVELS[zoomLevel]

    const [startDate, endDate] = getGridInterval(
        testCases.map(i => i.startDate), 
        testCases.map(i => i.endDate), 
        level1GridLevel
    )

    const level1Grid = getGrid(startDate, endDate, level1GridLevel, true)
    const level2Grid = getGrid(startDate, endDate, level2GridLevel)

    const todayDate = new Date()
    const todayPosition = getPositionProperties(todayDate, todayDate, startDate, endDate)

    useEffect(() => {
        if (todayPosition[0] <= 100) {
            // scroll to today
            const shift = todayPosition[0] / 100 * timelineHeader.current?.offsetWidth - 0.3 * timelineWrapper.current?.offsetWidth
            timelineWrapper.current?.scroll({ left: shift })
        }
        else {
            timelineWrapper.current?.scroll({ left: timelineHeader.current?.offsetWidth })
        }
    }, [zoomLevel]);

    return (<Grid item xs={12} display="grid" >
        <Paper className={customStyles.paper} style={{ boxShadow: 'none' }}>
            <Box display="flex" >
                {/* left column */}
                <Box pb={2} className={`${customStyles.table} ${customStyles.tableStub}`}>
                    <div>
                        <div>Test Specification</div>
                        <div>Condition</div>
                        <div>Test Instance</div>
                        <div>Test Status</div>
                    </div>

                    {testCases.map((test) => (
                        <div key={test.testCaseId} onClick={() => { onTestCaseClick(test) }}>
                            <div>
                                <TestCaseInfo 
                                    testSpecification={test.testSpecification.name} 
                                    testSpecificationVersion={test.testSpecification.version} 
                                    reference={test?.testSpecification?.groupStandardReference?.name} 
                                    sizeWindow={sizeWindow} 
                                    access="Schedule" 
                                />
                                <Typography variant='subtitle1' className={tableClasses.version}>
                                    <Box mr={2}>V. {test.testSpecification.version}</Box>
                                </Typography>
                            </div>
                            <div> - </div>
                            <div>{test.testInstance.name}</div>
                            <div>
                                <TestStatus testStatusName={test.testStatus.name} width={sizeWindow < 5 ? '90px' : '100px'} sizeWindow={sizeWindow} />
                            </div>
                        </div>
                    ))}
                </Box>

                {/* scrollable timeline */}
                <Box pb={2} display="flex" flexDirection="column" justifyContent="space-between" className={`${customStyles.table} ${customStyles.scrollable}`} ref={timelineWrapper} >
                    <div className={customStyles.timelineHeader} ref={timelineHeader} style={{ width: `${level2Grid.length * 70}px` }} >
                        <div>
                            {level1Grid.map((i, index) => (
                                <div key={index} style={{ position: 'absolute', left: `${i.left}%`, width: `${i.width}%`}}>
                                    {i.label}
                                </div>
                            ))}
                        </div>
                        <div>
                            {level2Grid.map((i, index) => (
                                <div key={index} style={{ position: 'absolute', left: `${i.left}%`, width: `${i.width}%`}}>{i.label}</div>
                            ))}
                        </div>
                    </div>

                    {testCases.map((test) => {
                        const testCaseDates = {
                            plannedStart: new Date(test.plannedStartDate),
                            start: new Date(test.startDate),
                            plannedEnd: new Date(test.plannedEndDate),
                            end: new Date(test.endDate),
                        }

                        const timelineParts = getTimelineParts(
                            testCaseDates,
                            { start: startDate, end: endDate },
                            todayDate
                        )

                        let mainColor = grey200
                        if (timelineParts.isTestCaseActive && !timelineParts.isTestCaseOver) {
                            mainColor = blue700
                        }
                        if (timelineParts.isTestCaseActive && timelineParts.isTestCaseOver && test.testStatus.name !== "APPROVED") {
                            mainColor = red700
                        }

                        return (
                            <div key={`tl_${test.testCaseId}`} className={customStyles.timelineRow}>
                                <>
                                    {Array.from(Array(level2Grid.length).keys()).map((index) => (
                                        <div key={`tl_${test.testCaseId}_${index}`} className={customStyles.timelineItem}/>
                                    ))}
                                    
                                    <HtmlTooltip 
                                        followCursor={true}
                                        data-testid="tooltip" 
                                        maxwidth="500px" 
                                        title={
                                            <Box display="flex" flexDirection="column" className={tooltipStyles.value} style={ {textAlign: 'right'} }>
                                               <Box>Actual: {`${formatDate(test.startDate)} - ${formatDate(test.endDate)}`}</Box> 
                                               <Box>Planned: {`${formatDate(test.plannedStartDate)} - ${formatDate(test.plannedEndDate)}`}</Box> 
                                            </Box>
                                        }
                                    >
                                        <div>
                                        { timelineParts.start &&  
                                            <span className={customStyles.duration} style={{ 
                                                left: `${timelineParts.start.left}%`, 
                                                width: `${timelineParts.start.width}%`, 
                                                backgroundColor: timelineParts.start.isDelay ? red700 : mainColor,
                                                opacity: timelineParts.start.isDelay ? 1 : 0.5
                                            }} />
                                        }

                                        <span className={customStyles.duration} style={{ 
                                            left: `${timelineParts.main.left}%`, 
                                            width: `${timelineParts.main.width}%`, 
                                            backgroundColor: mainColor
                                        }} 
                                        />

                                        { timelineParts.end && timelineParts.end.isDelay &&
                                            <span className={customStyles.duration} style={{ 
                                                left: `${timelineParts.end.left}%`, 
                                                width: `${timelineParts.end.width}%`, 
                                                backgroundColor: timelineParts.end.isDelay ? red700 : mainColor,
                                                opacity: timelineParts.end.isDelay ? 1 : 0.5
                                            }} />
                                        }
                                        </div>
                                    </HtmlTooltip>
                                    
                                    { todayPosition[0] <= 100 && 
                                        <div className={`${customStyles.today} today`} style={{left: `${todayPosition[0]}%`}} /> 
                                    }
                                </>
                            </div>  
                        )
                    })}
                </Box>
            </Box>
        </Paper>
    </Grid>)
}

export default InterractivePlanningTable