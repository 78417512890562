import React, {useContext, useState} from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation } from '@apollo/client';
import { MYACCOUNT } from '../../../operations/queries/query';
import { UPDATEUSERAVATAR } from '../../../operations/mutations/mutations';
import { Paper, Grid, Box, Typography, Button } from '@mui/material';
import { useStyles } from './SelectAvatar.styles';
import { IMAGE_MAP } from '../../other/helper/ImgMapper/ImgMapper';
import Icon from '../../../assets/icons/Icon.js';
import BatteryLoader from '../../other/helper/BatteryLoader/BatteryLoader';
import { NameRoleHeader } from '../templates';
import {
    SnackbarContext, SnackbarType
} from '../../other/contexts/SnackBarContext';
import { petrol800, signalRed800 } from '../../other/helper/MuiStyles';

function SelectAvatar() {
    const navigate = useNavigate();

    const handleOnCompleted = async () => {
        setSnackbarType(SnackbarType.SUCCESS);
        setIsSnackbarOpen(true);
    }

    const handleOnError = async (error) => {
        setMessage(error.message);
        setSnackbarType(SnackbarType.ERROR);
        setIsSnackbarOpen(true);
    }

    const { data: myAccount, loading: myAccountLoading, error: myAccountError } = useQuery(MYACCOUNT);
    const [updateUser, { loading: updateUserLoading }] = useMutation(UPDATEUSERAVATAR, {
        onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: MYACCOUNT }], awaitRefetchQueries: true
    });

    let { userId } = useParams();
    const [avatarId, setAvatarId] = useState(myAccount?.myAccount?.avatarId);
    const { setIsSnackbarOpen, setMessage, setSnackbarType } = useContext(SnackbarContext);
    const classes = useStyles();

    const handleAvatarSwitch = (id) => {
        setAvatarId(id);
    };

    const handleCancel = () => {
        navigate({
            pathname: `/profile/${userId}`,
        });
    };

    const handleSave = (e) => {
        e.preventDefault();
        updateUser({ variables: { avatarId: avatarId } });
        setMessage('Avatar has been updated.');
    };

    return (
        <>
            <div className={classes.root} >
                <Grid container spacing={3}>
                    <Grid item xs={12} display="grid">
                        <Paper className={classes.paper}>

                            {/* ERROR */}
                            {myAccountError !== undefined &&
                                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 64px - 24px - 24px)' }}>
                                    <Box pt={4} >
                                        <Typography variant="h2" className={classes.title}>
                                            Select Avatar
                                        </Typography>
                                    </Box>
                                    <Box mx={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow="1">
                                        <Icon iconName={"WarningIcon"} width={54} height={54} fill={signalRed800} />
                                        <Typography variant="h3" className={classes.error_text}>
                                            Something went wrong. The following errors have occurred:
                                        </Typography>
                                        {myAccountError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {myAccountError.message}
                                            </Typography>
                                        }
                                    </Box>
                                </Box>
                            }

                            {/* LOADING */}
                            {(myAccountLoading || updateUserLoading) && myAccountError === undefined &&
                                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 64px - 24px - 24px)' }}>
                                    <Box pt={4} display="flex" alignItems="center" justifyContent="center">
                                        <Typography variant="h2" className={classes.title}>
                                            Select Avatar
                                        </Typography>
                                    </Box>
                                    <Box mx={2} display="flex" alignItems="center" justifyContent="center" flexGrow="1">
                                        <BatteryLoader type="standard" />
                                    </Box>
                                </Box>
                            }

                            {!myAccountLoading && !updateUserLoading && myAccountError === undefined &&
                                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 64px - 24px - 24px)' }}>

                                    {/* USER INFO */}
                                    <Box display="flex" flexDirection="column">
                                        <Box pt={4} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                                            <Typography variant="h2" className={classes.title}>
                                                Select Avatar
                                            </Typography>
                                        </Box>
                                        <Box mt={4} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                                            <img src={IMAGE_MAP.AVATAR[avatarId]?.pngSrc} className={classes.avatar_image} alt="Avatar" />
                                        </Box>
                                        <NameRoleHeader myAccount={myAccount} />

                                        {/* LINE */}
                                        <Box mt={3} mx={3} className={classes.line}></Box>

                                        {/* AVATARS */}
                                        <Box my={2} style={{ marginLeft: '23.5px', marginRight: '23.5px' }} display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" flexWrap="wrap">
                                            {IMAGE_MAP.AVATAR.map((i, index) => {
                                                return (
                                                    <div key={index} className={classes.selected_circle} style={i.id === avatarId ? { border: `5px solid ${petrol800}` } : { border: "5px solid rgba(255,255,255,0)" }}>
                                                        <img
                                                            className={classes.avatar_option_image}
                                                            src={i.pngSrc}
                                                            alt="Avatar"
                                                            onClick={() => handleAvatarSwitch(i.id)}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </Box>

                                        {/* LINE */}
                                        <Box mb={5} mx={3} className={classes.line}></Box>
                                    </Box>

                                    <Box mx={3} pb={3} display="flex" flexDirection="row" justifyContent="space-between">
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSave}
                                        >
                                            Save
                                        </Button>
                                    </Box>
                                </Box>
                            }

                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </>
    )

}


export default SelectAvatar;