import React, {useState, useEffect, useContext} from 'react';
import { calcLatestSample, sampleVar, subSampleVar } from '../../../../operations/localeStorage';
import { Button, Dialog, DialogContent, Box, Typography, InputBase, IconButton } from '@mui/material';
import { useStyles } from '../UniversalDialog.styles.js';
import Icon from '../../../../assets/icons/Icon.js';
import { grey100, petrol600, petrol700, petrol800 } from '../../helper/MuiStyles.js';
import { SnackbarContext } from '../../contexts/SnackBarContext';

function AddEditIndex(props) {
    const {
        edit,
        mutationFunction,
        formSample,
        index,
        indexName
    } = props;
    const { setMessage } = useContext(SnackbarContext);

    const [show, setShow] = useState(false);
    const [newIndexName, setNewIndexName] = useState('');

    const addIndex = () => {
        calcLatestSample(false)
        mutationFunction({ variables: { subSampleId: formSample[sampleVar()].subSamples[subSampleVar()].subSampleId, name: newIndexName } });
        setMessage('Index has been added.');
        setNewIndexName('');
        setShow(false);
    };

    const handleSave = () => {
        calcLatestSample(false)
        mutationFunction({ variables: { indexId: formSample[sampleVar()].subSamples[subSampleVar()].indices[index].indexId, name: newIndexName, info: formSample[sampleVar()].subSamples[subSampleVar()].indices[index].info } });
        setMessage('Index has been updated.');
        setNewIndexName("");
        setShow(false);
    };

    const ToggleModal = () => {
        setShow(!show);
    };
    
    const handleCancel = () => {
        if (edit) {
            setNewIndexName(indexName);
        } else {
            setNewIndexName("");
        }
        ToggleModal();
    };

    const handleIndexNameChange = () => (event) => {
        setNewIndexName(event.target.value);
    };

    const checkAvailability = () => {
        let check = false;
        if (edit) {
            for (let i = 0; i < formSample[sampleVar()]?.subSamples[subSampleVar()]?.indices?.length; i++) {
                if (newIndexName.toUpperCase() === formSample[sampleVar()]?.subSamples[subSampleVar()]?.indices[i]?.name.toUpperCase()) {
                    if (index !== i) {
                        check = true;
                    }
                }
            }
        } else {
            for (let i = 0; i < formSample[sampleVar()]?.subSamples[subSampleVar()]?.indices?.length; i++) {
                if (newIndexName.toUpperCase() === formSample[sampleVar()]?.subSamples[subSampleVar()]?.indices[i]?.name.toUpperCase()) {
                    check = true;
                }
            }
        }
        return check;
    };
    
    useEffect(() => {
        if (indexName !== undefined) {
            setNewIndexName(indexName);
        }
    }, [indexName]) // eslint-disable-line

    const styleProps = {
        editIconWrapperMarginLeft: '8px',
        editIconHoverBackground: petrol700,
        addButtonMarginBottom: '8px',
        addButtonBackgroundColor: grey100,
      };

    const classes = useStyles(styleProps);

    return (
        <>
            {edit ?
                <IconButton
                    className={classes.edit_icon_wrapper}
                    onClick={ToggleModal}
                    size="large">
                    <Icon iconName={"EditIcon"} fill={"#FFF"} />
                </IconButton>
                :
                <Button variant="contained" color="secondary" className={classes.add_button} onClick={ToggleModal}>
                <div style={{ display: 'flex', marginRight: '8px' }}><Icon iconName={"AddIcon"} fill={petrol600} /></div> Add more
                </Button>
            }

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={show}
                onClose={handleCancel}
            >
                <DialogContent>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h2">
                            {edit ? 'Edit' : 'Add'} {edit ? indexName : 'Index'}
                        </Typography>
                        <IconButton onClick={handleCancel} className={classes.hover_blue}>
                            <Icon iconName={"CloseIcon"} fill={petrol800} />
                        </IconButton>
                    </Box>
                    <Box mt={2} display="flex" alignItems="center">
                        <Typography variant="h4" style={{ fontWeight: '400' }}>
                            Please assign a new name to this Index.
                        </Typography>
                    </Box>
                    <Box mt={3} display="flex" justifyContent="space-between">
                        <Box display='flex' flexDirection='column' justifyContent="flex-start" flexGrow="1">
                            <Typography variant="h6" className={classes.index_title}>
                                Index *
                            </Typography>
                            <InputBase
                                className={classes.box_freeText}
                                name="index"
                                multiline={true}
                                fullWidth={true}
                                value={newIndexName}
                                placeholder={'Add new index'}
                                onChange={handleIndexNameChange()}
                                error={(newIndexName?.length > 15) || checkAvailability()}
                            />
                        </Box>
                    </Box>
                    {newIndexName?.length > 15 &&
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="subtitle2" className={classes.alert_message}>The index name must not contain more than 15 characters.</Typography>
                            <Typography variant="subtitle2" className={classes.alert_message}>{newIndexName.length}/15</Typography>
                        </Box>
                    }
                    {checkAvailability() &&
                        <Box display="flex">
                            <Typography variant="subtitle2" className={classes.alert_message}>This index name has already been used. Plase assign a new value.</Typography>
                        </Box>
                    }
                    <Box mt={4} display="flex" alignItems="center">
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={newIndexName?.length > 15 || newIndexName?.length < 1 || checkAvailability()}
                            onClick={edit ? handleSave : addIndex}
                        >
                            Save
                        </Button>
                        <Typography variant="h6" className={classes.required_tag}>
                            * Mandatory field
                        </Typography>
                    </Box>
                </DialogContent>
            </Dialog >
        </>
    )
}

export default AddEditIndex;