import React, { useState } from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { useStyles } from '../Step.styles.js';
import classNames from '../example.module.css'
import Icon from '../../../../assets/icons/Icon.js';
import { MentionsInput, Mention } from 'react-mentions'

import { setParameters, HintBox } from '../setParameters.js';
import { grey600, petrol800 } from '../../../other/helper/MuiStyles.js';

const AddNewStep = ({ steps, setSteps, entry, category, selectedParameters }) => {

    const [value, setValue] = useState('');
    const [show, setShow] = useState(false);
    const [stepDescription, setStepDescription] = useState('');

    // define parameters for suggestion dropdown
    const parameters = setParameters(selectedParameters, entry);

    const onChange = (ev, newValue) => {
        setValue(newValue);
        setStepDescription(newValue);
    };

    //calculate current step number
    let findMax = Math.max.apply(Math, steps.map(function (o) { return o.number }));
    if (findMax < 1) {
        findMax = 0;
    }

    const ToggleModal = () => {
        setShow(!show);
    };

    const handleAddStep = () => {
        if (entry === "create") {
            setSteps([...steps, { number: findMax + 1, stepDescription: stepDescription }]);
        } else {
            setSteps([...steps, { id: null, number: findMax + 1, stepDescription: stepDescription }]);
        }
        setStepDescription('');
        ToggleModal();
        setValue('');
    };

    const classes = useStyles();

    return <>
        {show &&
            <Box display="flex" flexDirection="column" justifyContent="center" mx={3} mb={2} py={3} pr={2} className={classes.step_box}>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Box display="flex" justifyContent="center" alignItems="center" className={classes.menu_icon}>
                            <Icon iconName={"MenuIcon"} fill={grey600} />
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" className={classes.step_bubble_selected}>
                            {findMax + 1}
                        </Box>
                        <Box className={classes.step_step}>
                            Step {findMax + 1}
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <IconButton className={classes.step_icon} onClick={ToggleModal} size="large">
                            <Icon iconName={"TrashIcon"} fill={petrol800} />
                        </IconButton>
                    </Box>
                </Box>
                <div className={classNames.advanced}>
                    <MentionsInput
                        value={value}
                        onChange={onChange}
                        markup="{{__id__}}"
                        className="mentions"
                        classNames={classNames}
                        style={{ marginTop: '24px' }}
                        a11ySuggestionsListLabel={"Suggested mentions"}
                    >
                        <Mention
                            trigger={"@"}
                            data={search => parameters.filter(i => i.display.includes(search))}
                            displayTransform={id => `@${id}`}
                            className={classNames.mentions__mention}
                            markup='@__display__'
                        />
                    </MentionsInput>
                </div>
                {stepDescription.length > 2000 &&
                    <Box display="flex" flexDirection="row" alignItems="center" marginLeft="122px">
                        <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 2000 characters.</Typography>
                    </Box>
                }
                <HintBox />

                <Box mt={3} display="flex" flexDirection="row" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={stepDescription.length > 2000}
                        onClick={handleAddStep}
                    >
                        <div style={{ display: 'flex', marginRight: '8px' }}>
                            <Icon iconName={"AddIcon"} fill={'#FFF'} />
                        </div>
                        Add {category} Step
                    </Button>
                </Box>
            </Box>
        }
        <Button
            variant="contained"
            color="primary"
            disabled={show}
            className={classes.button_add_step}
            onClick={ToggleModal}
        >
            <div style={{ display: 'flex', marginRight: '8px' }}>
                <Icon iconName={"AddIcon"} fill={'#FFF'} />
            </div>
            Add Step
        </Button>
    </>;
};

export default AddNewStep;

