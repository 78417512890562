import makeStyles from '@mui/styles/makeStyles';
import { buttonHoverPetrol800, buttonHoverSignalRed800, grey1000, grey600, petrol1000, signalRed800 } from '../../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    root: {
        padding: '24px',
        backgroundColor: petrol1000,
    },
    paper: {
        margin: "auto",
        minWidth: "870px",
        maxWidth: "870px",
        minHeight: 'calc(100vh - 64px - 24px - 24px)',
        backgroundColor: '#FFF',
    },
    hover_blue: {
        '&:hover': {
            backgroundColor: buttonHoverPetrol800,
          },
    },
    button_add_test_specification: {
        width: '248px',
    },
    button_search_test_specification: {
        marginTop: '24px',
    },
    freeText: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '28px',
        letterSpacing: '0.3px',
        width: '300px',
    },
    availability_message: {
        textAlign: 'left',
        marginTop: '4px',
    },
    draft_message: {
        textAlign: 'left',
        marginTop: '8px',
        marginLeft: '32px',
        color: signalRed800,
    },
    specification_area: {
        marginTop: '32px',
        marginLeft: '16px',
    },
    test_specification_box: {
        textDecoration: 'none',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.1)',
        borderRadius: '2px',
        textAlign: 'left',
        width: '300px',
    },
    specification_button: {
        marginRight: '24px',
    },
    category: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.6px',
    },
    grey_title: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '18px',
        letterSpacing: '0.8px',
        color: grey600,
    },
    black_value: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '18px',
        letterSpacing: '0.8px',
        color: grey1000,
    },
    category_icon: {
        fill: grey600,
    },
    input_title: {
        textAlign: 'left',
        marginBottom: '4px',
    },
    search: {
        width: '236px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      search_icon: {
        padding: '0 16px',
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      search_field: {
        "& #search-test-specification": {
          width: '204px',
          paddingLeft: '32px !important',
        },
      },
    error_text: {
        marginTop: '8px',
        fontWeight: '400',
    },
    error_type: {
        padding: '0px 24px',
        textAlign: 'center',
        marginTop: '4px',
        fontWeight: '400',
        color: grey600,
    },
    user_hint: {        
        fontWeight: '400',
    },
    button_clear: {
        marginTop: '24px',
        marginLeft: '24px',
        color: signalRed800,
        backgroundColor: '#FFF',
        '&:hover': {
            backgroundColor: buttonHoverSignalRed800,
        },
    },
}));