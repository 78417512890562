import React from 'react';
import Box from '@mui/material/Box';
import { useStyles } from '../Tooltip.styles';
import { HtmlTooltip } from '../HtmlTooltip/HtmlTooltip';

export default function DraftInfo() {

    const classes = useStyles();

    return (
        <HtmlTooltip data-testid="tooltip" width="341px"
            title={
                <Box display="flex " flexDirection="row" p={1}>
                    <Box className={classes.title}>Test Specification has to be approved by Admin. Editing is no longer possible afterwards.</Box>
                </Box>
            }
        >
            <Box mr={2} className={classes.tag}>Draft</Box>
        </HtmlTooltip>
    )
}