import React, {useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledNavLink } from '../Homepage/Homepage.styles';
import { useQuery, useMutation } from '@apollo/client';
import { LEADINGDEVELOPMENTS, PROJECTTYPES, PLATFORMS, CELLSUPPLIERS_SLIM, ROLES, USERS_SLIM, MYACCOUNT } from '../../../operations/queries/query';
import { ADDPROJECT } from '../../../operations/mutations/mutations';
import { Grid, Typography, InputAdornment, Box, Button, Paper, MenuItem, Input, InputLabel, Select, Chip, IconButton, InputBase } from '@mui/material';
import { useStyles } from './AddProject.styles.js';
import Icon from '../../../assets/icons/Icon.js';
import { YEARS, WEEKS } from '../../other/helper/utilsMapper/utilsMapper'
import { IMAGE_MAP } from '../../other/helper/ImgMapper/ImgMapper'
import BatteryLoader from '../../other/helper/BatteryLoader/BatteryLoader';
import { SnackbarContext,SnackbarType } from '../../other/contexts/SnackBarContext';
import { petrol800, signalRed800 } from '../../other/helper/MuiStyles';

function AddProject() {
  const navigate = useNavigate();
  const { setIsSnackbarOpen, setMessage, setSnackbarType } = useContext(SnackbarContext);

  const { data: projectTypes, error: projectTypesError, loading: projectTypesLoading } = useQuery(PROJECTTYPES(true));
  const { data: leadingDevelopments, error: leadingDevelopmentsError, loading: leadingDevelopmentsLoading } = useQuery(LEADINGDEVELOPMENTS(true));
  const { data: platforms, error: platformsError, loading: platformsLoading } = useQuery(PLATFORMS(true));
  const { data: cellSuppliers, error: cellSuppliersError, loading: cellSuppliersLoading } = useQuery(CELLSUPPLIERS_SLIM(true));
  const { data: roles, error: rolesError, loading: rolesLoading } = useQuery(ROLES(true));
  const { data: users, error: usersError, loading: usersLoading } = useQuery(USERS_SLIM(true));

  const [formValues, setFormValues] = useState({
    projectType: '',
    leadingDevelopment: '',
    platforms: [],
    sopYear: '',
    sopWeek: '',
    cellSupplier: '',
    cellIndex: '',
    nominalCapacity: '',
    cellSpecification: '',
    cellSpecificationLink: '',
    users: [],
    name: '',
  });

  const [mutationValues, setMutationValues] = useState({
    projectType: '',
    leadingDevelopment: '',
    platforms: [],
    sopYear: '',
    sopWeek: '',
    cellSupplier: '',
    cellIndex: '',
    nominalCapacity: '',
    cellSpecification: '',
    cellSpecificationLink: '',
    users: [],
  });

  const [assignRoles, setRoles] = useState([
    { userId: '', role: '' },
  ]);

  const [mutationRoles, setMutationRoles] = useState([
    { userId: '', role: '' },
  ]);

  const handleOnCompleted = async () => {
    setSnackbarType(SnackbarType.SUCCESS);
    setIsSnackbarOpen(true);
    navigate("/")
  }

  const handleOnError = async (error) => {
    setMessage(error.message);
    setSnackbarType(SnackbarType.ERROR);
    setIsSnackbarOpen(true);
  }

  const [createProject, { loading: createProjectLoading }] = useMutation(
    ADDPROJECT,
    {
      onCompleted: handleOnCompleted, onError: handleOnError,
      refetchQueries: [{ query: MYACCOUNT }],
    });

  const [checkNominalCapacity, setCheckNominalCapacity] = useState(false);
  const [checkRoleAssignment, setCheckRoleAssignments] = useState(true);
  const [checkDoubleAssignment, setCheckDoubleAssignments] = useState(false);

  const handleInputChange = () => (event, child) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
    setMutationValues({ ...mutationValues, [event.target.name]: child.props.datamutationvalue });
  };

  const handleInputChangeFreeText = () => (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
    setMutationValues({ ...mutationValues, [event.target.name]: event.target.value });
  };

  const handlePlatformChange = () => (event, child) => {
    let mutationValue = child.props.datamutationvalue;
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
    let check = false;
    for (let i = 0; i < mutationValues.platforms.length; i++) {
      if (mutationValue === mutationValues.platforms[i]) {
        check = true;
        mutationValues.platforms.splice(i, 1);
      }
    }
    if (!check) {
      let array = JSON.parse(JSON.stringify(mutationValues.platforms));
      array.push(mutationValue);
      setMutationValues({ ...mutationValues, [event.target.name]: array });
    }
  };

  const addTitleName = () => {
    let capacity = '';
    if (formValues.nominalCapacity !== '') {
      capacity = " " + formValues.nominalCapacity + " Ah";
    }
    return formValues.cellSupplier + capacity + " " + formValues.cellIndex;
  };

  const handleSubmitState = () => {
    let buttonStatus = true;
    if (formValues.projectType !== '' && formValues.leadingDevelopment !== '' && formValues.platforms.length > 0 && formValues.sopYear !== '' && formValues.sopWeek !== '' && formValues.cellSupplier !== '' && formValues.cellIndex.length < 21 && formValues.nominalCapacity !== '' && checkNominalCapacity && formValues.cellSpecification.length < 151 && formValues.cellSpecificationLink.length < 151 && checkRoleAssignment) {
      buttonStatus = false;
    }
    return buttonStatus;
  };

  const handleAddRole = () => {
    setRoles([...assignRoles, { userId: '', role: '' }]);
    setMutationRoles([...mutationRoles, { userId: '', role: '' }]);
  };

  const handleRoleChange = (index, event, child) => {
    const values = [...assignRoles];
    const valuesMutation = [...mutationRoles];
    values[index][event.target.name] = event.target.value;
    valuesMutation[index][event.target.name] = child.props.datamutationvalue;
    setRoles(values);
    setMutationRoles(valuesMutation);
  };

  const handleRemoveRole = (index) => {
    const values = [...assignRoles];
    const valuesMutation = [...mutationRoles];
    values.splice(index, 1);
    valuesMutation.splice(index, 1);
    setRoles(values);
    setMutationRoles(valuesMutation);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    createProject({ variables: { projectType: mutationValues.projectType, leadingDevelopment: mutationValues.leadingDevelopment, platforms: mutationValues.platforms, sopYear: mutationValues.sopYear, sopWeek: mutationValues.sopWeek, cellSupplier: mutationValues.cellSupplier, cellIndex: mutationValues.cellIndex, nominalCapacity: mutationValues.nominalCapacity, cellSpecification: mutationValues.cellSpecification, cellSpecificationLink: mutationValues.cellSpecificationLink, users: mutationRoles } });
    setMessage('Project has been created');
  }
    ;

  const isLoading = () => {
    return (projectTypesLoading || leadingDevelopmentsLoading || platformsLoading || cellSuppliersLoading || rolesLoading || usersLoading || createProjectLoading)
  };

  const hasError = () => {
    return !(projectTypesError === undefined && leadingDevelopmentsError === undefined && platformsError === undefined && cellSuppliersError === undefined && rolesError === undefined && usersError === undefined)
  };

  //validation for nominalCapacity
  useEffect(() => {
    const num1 = /^[-+]?\d+\.\d+$/;
    const num2 = /^-?\d+$/;
    if (num1.test(formValues.nominalCapacity) || num2.test(formValues.nominalCapacity) || formValues.nominalCapacity === "") {
      setCheckNominalCapacity(true);
    } else {
      setCheckNominalCapacity(false);
    }
  }, [formValues.nominalCapacity]); // eslint-disable-line

  //validation for mutationRoles
  useEffect(() => {
    let check = false;
    for (let i of mutationRoles) {
      if ((i.userId !== "" && i.role === "") || (i.userId === "" && i.role !== "") || (i.userId === "" && i.role === "")) {
        check = false;
        break;
      } else {
        check = true;
      }
    }
    if (mutationRoles.length === 0) {
      check = false;
    }
    setCheckRoleAssignments(check);
  }, [mutationRoles]); // eslint-disable-line

  //validation for mutationRoles
  useEffect(() => {
    let checkArray = mutationRoles?.map(function (item) { return item.userId + ' ' + item.role });
    let isDouble = checkArray.some(function (item, idx) {
      return checkArray.indexOf(item) !== idx
    });
    setCheckDoubleAssignments(isDouble);
  }, [mutationRoles]);

  const styleProps = {
    supplierImg: IMAGE_MAP.SUPPLIER[formValues.cellSupplier],
  };

  const classes = useStyles(styleProps);

  return <>
    <div className={classes.root} >
      <Grid container spacing={3}>
        <Grid item xs={12} display="grid">
          <Paper className={classes.paper}>

            {/* ERROR */}
            {hasError() &&
              <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 64px - 24px - 24px)' }}>
                <Box m={4} display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                  <Typography variant="h2">
                    Add Project
                  </Typography>
                </Box>
                <Box mx={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow="1">
                  <Icon iconName={"WarningIcon"} width={54} height={54} fill={signalRed800} />
                  <Typography variant="h3" className={classes.error_text}>
                    Something went wrong. The following errors have occurred:
                  </Typography>
                  {projectTypesError !== undefined &&
                    <Typography variant="h5" className={classes.error_type}>
                      {projectTypesError.message}
                    </Typography>
                  }
                  {leadingDevelopmentsError !== undefined &&
                    <Typography variant="h5" className={classes.error_type}>
                      {leadingDevelopmentsError.message}
                    </Typography>
                  }
                  {platformsError !== undefined &&
                    <Typography variant="h5" className={classes.error_type}>
                      {platformsError.message}
                    </Typography>
                  }
                  {cellSuppliersError !== undefined &&
                    <Typography variant="h5" className={classes.error_type}>
                      {cellSuppliersError.message}
                    </Typography>
                  }
                  {rolesError !== undefined &&
                    <Typography variant="h5" className={classes.error_type}>
                      {rolesError.message}
                    </Typography>
                  }
                  {usersError !== undefined &&
                    <Typography variant="h5" className={classes.error_type}>
                      {usersError.message}
                    </Typography>
                  }
                </Box>
                <Box my={4} display="flex" alignItems="center" justifyContent="center">
                  <StyledNavLink to="/">
                    <Button
                      variant="outlined"
                      color="primary">
                      Cancel
                    </Button>
                  </StyledNavLink>
                </Box>
              </Box>
            }

            {/* LOADING */}
            {isLoading() && !hasError() &&
              <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 64px - 24px - 24px)' }}>
                <Box m={4} display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                  <Typography variant="h2">
                    Add Project <b className={classes.cell_title}>{addTitleName()}</b>
                  </Typography>
                </Box>
                <Box mx={4} display="flex" alignItems="center" justifyContent="center" flexGrow="1">
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" justifyContent="center"><BatteryLoader type="standard" /> </Box>
                    {createProjectLoading && <Box mt={2}>Project creation can take up to 30 seconds.</Box>}
                  </Box>
                </Box>
              </Box>
            }

            {!isLoading() && !hasError() &&
              <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 64px - 24px - 24px)' }}>
                <Box display="flex" flexDirection="column">
                  <Box m={4} display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                    <Typography variant="h2">
                      Add Project <b className={classes.cell_title}>{addTitleName()}</b>
                    </Typography>
                    <StyledNavLink to="/">
                      <IconButton size="large" className={classes.hover_blue}>
                        <Icon iconName={"CloseIcon"} fill={petrol800} />
                      </IconButton>
                    </StyledNavLink>
                  </Box>
                  <Box mx={4} display="flex" flexDirection="column" alignItems="flex-start">
                    <InputLabel className={classes.input_title}>Project Type *</InputLabel>
                    <Select
                      className={classes.box}
                      name="projectType"
                      value={formValues.projectType}
                      onChange={handleInputChange()}
                    >
                      {projectTypes?.projectTypes.map((option) => (
                        <MenuItem
                          datamutationvalue={option.projectTypeId}
                          key={option.projectTypeId}
                          value={option.name}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>

                  <Box mx={4} mt={7} display="flex">
                    <Box display="flex" flexDirection="column" alignItems="flex-start">
                      <InputLabel className={classes.input_title}>Leading Development *</InputLabel>
                      <Select
                        className={classes.box}
                        name="leadingDevelopment"
                        value={formValues.leadingDevelopment}
                        onChange={handleInputChange()}
                      >
                        {leadingDevelopments?.leadingDevelopments.map((option) => (
                          <MenuItem
                            datamutationvalue={option.leadingDevelopmentId}
                            key={option.leadingDevelopmentId}
                            value={option.name}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>

                    <Box ml={3} display="flex" flexDirection="column" alignItems="flex-start">
                      <InputLabel className={classes.input_title}>Vehicle Platform *</InputLabel>
                      <Select
                        name='platforms'
                        multiple
                        className={classes.multiple_select_box}
                        value={formValues.platforms}
                        onChange={handlePlatformChange()}
                        style={mutationValues.platforms.length > 0 ? { paddingTop: '4px', paddingBottom: '0px' } : {}}
                        input={<Input />}
                        renderValue={(selected) => (
                          <div className={classes.platforms}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} className={classes.platform} />
                            ))}
                          </div>
                        )}
                      >
                        {platforms?.platforms.map((option) => (
                          <MenuItem
                            datamutationvalue={option.platformId}
                            key={option.platformId}
                            value={option.name}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Box>

                  <Box mx={4} mt={4} display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="column" alignItems="flex-start">
                      <InputLabel className={classes.input_title}>SOP Year *</InputLabel>
                      <Select
                        className={classes.box}
                        name="sopYear"
                        value={formValues.sopYear}
                        onChange={handleInputChange()}
                      >
                        {YEARS.map((option) => (
                          <MenuItem
                            datamutationvalue={option.value}
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>

                    <Box ml={3} display="flex" flexDirection="column" alignItems="flex-start">
                      <InputLabel className={classes.input_title}>SOP Calendar Week *</InputLabel>
                      <Select
                        className={classes.box}
                        name="sopWeek"
                        value={formValues.sopWeek}
                        onChange={handleInputChange()}
                      >
                        {WEEKS.map((option) => (
                          <MenuItem
                            datamutationvalue={option.value}
                            key={option.value}
                            value={option.value}
                          >
                            {"CW " + option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Box>

                  <Box mx={4} mt={7} display="flex" flexDirection="row">
                    <Typography variant="h3">
                      Cell Details
                    </Typography>
                  </Box>

                  <Box mx={4} mt={2} display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="column" alignItems="flex-start">
                      <InputLabel className={classes.input_title}>Cell Supplier *</InputLabel>
                      <Select
                        className={classes.box}
                        name="cellSupplier"
                        value={formValues.cellSupplier}
                        onChange={handleInputChange()}
                      >
                        {cellSuppliers?.cellSuppliers.map((option) => (
                          <MenuItem
                            datamutationvalue={option.cellSupplierId}
                            key={option.cellSupplierId}
                            value={option.name}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>

                    <Box ml={3} mt={3} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                      <div className={classes.supplierimage}>
                      </div>
                    </Box>
                  </Box>

                  <Box mx={4} mt={4} display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="column" alignItems="flex-start">
                      <InputLabel className={classes.input_title}>Cell Index</InputLabel>
                      <InputBase
                        className={classes.box_freeText}
                        name="cellIndex"
                        multiline={true}
                        fullWidth={true}
                        value={formValues.cellIndex}
                        onChange={handleInputChangeFreeText()}
                        error={mutationValues.cellIndex.length > 20}
                      />
                      {mutationValues.cellIndex.length > 20 &&
                        <Box display="flex" flexDirection="row" justifyContent="space-between" style={{ width: '391px' }}>
                          <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 20 characters.</Typography>
                          <Typography variant="subtitle2" className={classes.alert_message}>{mutationValues.cellIndex.length}/20</Typography>
                        </Box>
                      }
                    </Box>

                    <Box ml={3} display="flex" flexDirection="column" alignItems="flex-start">
                      <InputLabel className={classes.input_title}>Nominal Capacity *</InputLabel>
                      <InputBase
                        className={classes.box_freeText}
                        name="nominalCapacity"
                        multiline={true}
                        fullWidth={true}
                        value={formValues.nominalCapacity}
                        onChange={handleInputChangeFreeText()}
                        error={!checkNominalCapacity}
                        endAdornment={<InputAdornment position="end">Ah</InputAdornment>}
                      />
                      {!checkNominalCapacity &&
                        <Typography variant="subtitle2" className={classes.alert_message}>Incorrect input format. Only numbers allowed.</Typography>
                      }
                    </Box>
                  </Box>

                  <Box mx={4} mt={3} display="flex" flexDirection="column" alignItems="flex-start">
                    <InputLabel className={classes.input_title}>LAH</InputLabel>
                    <InputBase
                      className={classes.box_long}
                      name="cellSpecification"
                      multiline={true}
                      fullWidth={true}
                      value={formValues.cellSpecification}
                      onChange={handleInputChangeFreeText()}
                      error={mutationValues.cellSpecification.length > 150}
                    />
                    {mutationValues.cellSpecification.length > 150 &&
                      <Box display="flex" flexDirection="row" justifyContent="space-between" style={{ width: '806px' }}>
                        <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 150 characters.</Typography>
                        <Typography variant="subtitle2" className={classes.alert_message}>{mutationValues.cellSpecification.length}/150</Typography>
                      </Box>
                    }
                  </Box>

                  <Box mx={4} mt={3} display="flex" flexDirection="column" alignItems="flex-start">
                    <InputLabel className={classes.input_title}>LAH Link</InputLabel>
                    <InputBase
                      className={classes.box_long}
                      name="cellSpecificationLink"
                      multiline={true}
                      fullWidth={true}
                      value={formValues.cellSpecificationLink}
                      onChange={handleInputChangeFreeText()}
                      error={mutationValues.cellSpecificationLink.length > 150}
                    />
                    {mutationValues.cellSpecificationLink.length > 150 &&
                      <Box display="flex" flexDirection="row" justifyContent="space-between" style={{ width: '806px' }}>
                        <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 150 characters.</Typography>
                        <Typography variant="subtitle2" className={classes.alert_message}>{mutationValues.cellSpecificationLink.length}/150</Typography>
                      </Box>
                    }
                  </Box>

                  <Box mx={4} mt={7} display="flex" flexDirection="row">
                    <Typography variant="h3">
                      Assign to Project
                    </Typography>
                  </Box>

                  <Box mx={4} mt={2} display="flex" flexDirection="row">
                    <Box className={classes.box}>
                      <InputLabel className={classes.input_title}>Role *</InputLabel>
                    </Box>
                    <Box ml={3} className={classes.box}>
                      <InputLabel className={classes.input_title}>User *</InputLabel>
                    </Box>
                  </Box>

                  {assignRoles.map((inputField, index) => (
                    <div className={classes.assignment_box} key={index}>
                      <Select
                        name="role"
                        value={inputField.role}
                        onChange={(event, child) => handleRoleChange(index, event, child)}
                        className={classes.left_select_box}
                      >
                        {roles?.projectRoles.map((option) => (
                          <MenuItem
                            datamutationvalue={option.roleId}
                            key={option.roleId}
                            value={option.name}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>

                      <Select
                        name="userId"
                        value={inputField.userId}
                        onChange={(event, child) => handleRoleChange(index, event, child)}
                        className={classes.right_select_box}
                      >
                        {users?.users.map((option) => (
                          <MenuItem
                            datamutationvalue={option.userId}
                            key={option.userId}
                            value={option.userId}
                          >
                            {option.lastName + ", " + option.firstName}
                          </MenuItem>
                        ))}
                      </Select>
                      {mutationRoles.length > 1 &&
                        <IconButton
                          className={classes.position_trash_icon}
                          onClick={() => handleRemoveRole(index)}
                          size="large">
                          <Icon iconName={"TrashIcon"} fill={petrol800} />
                        </IconButton>
                      }
                    </div>
                  ))}

                  {checkDoubleAssignment &&
                    <Box mx={4} mt={-2} mb={1.5} display="flex" flexDirection="row">
                      <Typography variant="subtitle2" className={classes.alert_message}>You have selected the same user with the same role twice.</Typography>
                    </Box>
                  }

                  <Box mx={4} display="flex" flexDirection="row">
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleAddRole()}
                    >
                      <div style={{ display: 'flex', marginRight: '8px' }}>
                        <Icon iconName={"AddIcon"} fill={petrol800} />
                      </div>
                      Add User
                    </Button>
                  </Box>
                </Box>

                <Box mx={4} mt={3} pb={4} display="flex" alignItems="center">
                  <Button
                    variant="contained"
                    disabled={handleSubmitState()}
                    onClick={handleSubmit}
                    color="primary"
                    type="submit"
                  >
                    Add Project
                  </Button>
                  <Typography variant="h6" className={classes.required_tag}>
                    * Mandatory field
                  </Typography>
                </Box>

              </Box>
            }
          </Paper>
        </Grid>
      </Grid>
    </div>
  </>;
}

export default AddProject;