import React, {useState, useEffect, useContext} from 'react';
import { useReactiveVar } from '@apollo/client';
import { sampleVar, subSampleVar, indexVar } from '../../../../operations/localeStorage';
import { Button, Dialog, DialogContent, Box, IconButton, Typography, InputBase } from '@mui/material';
import { useStyles } from '../UniversalDialog.styles.js';
import Icon from '../../../../assets/icons/Icon.js';
import { petrol600, petrol800 } from '../../helper/MuiStyles';
import { SnackbarContext } from '../../contexts/SnackBarContext';

function EditIndexInformation(props) {
    const { selectedIndex, updateIndex, formSample, info } = props;
    const { setMessage } = useContext(SnackbarContext);

    const [show, setShow] = useState(false);
    const selectedSamplePosition = useReactiveVar(sampleVar);
    const selectedSubSamplePosition = useReactiveVar(subSampleVar);
    const selectedIndexPosition = useReactiveVar(indexVar);

    const [information, setInformation] = useState("");

    const ToggleModal = () => {
        setShow(!show);
    };

    const handleCancel = () => {
        setInformation(info);
        ToggleModal();
    };

    const handleInformationChange = () => (event) => {
        setInformation(event.target.value);
    };

    const addInformation = () => {
        updateIndex({ variables: { indexId: formSample[selectedSamplePosition].subSamples[selectedSubSamplePosition].indices[selectedIndexPosition].indexId, name: selectedIndex, info: information } });
        setMessage('Information on index has been updated');
        setShow(false);
    };
    
    useEffect(() => {
        if (info !== undefined) {
            setInformation(info);
        }
    }, [info]) // eslint-disable-line

    const classes = useStyles();

    return <>
        <Button variant="contained" color="secondary" className={classes.add_button_information} onClick={ToggleModal}>
        <div style={{ display: 'flex', marginRight: '8px' }}><Icon iconName={((info?.length < 1) || info === null) ? "AddIcon" : "EditIcon"} fill={petrol600} /></div>{((info?.length < 1) || info === null) ? 'Add info' : 'Edit info'}
        </Button>

        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={show}
            onClose={handleCancel}
        >
            <DialogContent>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h2">
                        {((info?.length < 1) || info === null) ? "Add" : "Edit"} {((info?.length < 1) || info === null) ? "Information" : info}
                    </Typography>
                    <IconButton onClick={handleCancel} className={classes.hover_blue}>
                        <Icon iconName={"CloseIcon"} fill={petrol800} />
                    </IconButton>
                </Box>
                <Box mt={2} display="flex" alignItems="center">
                    <Typography variant="h4" style={{ fontWeight: '400' }}>
                        Please add further information to this index.
                    </Typography>
                </Box>
                <Box mt={3} display="flex" alignItems="center">
                    <Box display='flex' flexDirection='column' flexGrow='1'>
                        <Typography variant="h6" className={classes.index_title}>
                            Information on Index
                        </Typography>
                        <InputBase
                            className={classes.box_freeText}
                            name="index"
                            multiline={true}
                            fullWidth={true}
                            placeholder="Add Information"
                            value={information}
                            onChange={handleInformationChange()}
                            error={information?.length > 255}
                        />
                    </Box>
                </Box>
                {information?.length > 255 &&
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 255 characters.</Typography>
                        <Typography variant="subtitle2" className={classes.alert_message}>{information.length}/255</Typography>
                    </Box>
                }
                <Box mt={4} display="flex" alignItems="center">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={information?.length > 255 || information?.length < 1 || information === null}
                        onClick={addInformation}
                    >
                        Save
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    </>
}

export default EditIndexInformation;