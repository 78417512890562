import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

export const styles = makeStyles(() => ({
    list: {
        margin: '0',
        '& > li': {
            marginTop: '8px',
            lineHeight: '25px'
        },
    }
}));

export const Link = ({ children, href }) => (<>
    {' '}<a href={href} rel="noopener noreferrer" target="_blank">{children}</a>{' '}
</>)