import React, { useState } from 'react';
import { IconButton, Box, Typography } from '@mui/material';

import GER from '../../../../assets/logos/GER.png';
import ENG from '../../../../assets/logos/ENG.png';
import { buttonHoverGrey800 } from '../../helper/MuiStyles.js';
import EmployeesVWGroupEN from './EmployeesVWGroup_en.js';
import EmployeesVWGroupDE from './EmployeesVWGroup_de.js';


function EmployeesVWGroup() {

    const activeIconStyles = { marginRight: '8px', padding: '6px' }
    const inactiveIconStyles = { ...activeIconStyles, backgroundColor: buttonHoverGrey800 }

    const [language, setLanguage] = useState("de");

    return (<>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h2">
                {language === "en" 
                    ? "Privacy Policy for employees of Volkswagen Group" 
                    : "Datenschutzerklärung für Beschäftigte des Volkswagenkonzerns"
                }
            </Typography>
            
            <Box>
                <IconButton
                    onClick={() => setLanguage("de")}
                    style={language === "en" ? activeIconStyles : inactiveIconStyles }>
                        <img aria-label="GER" src={GER} alt="GER" style={{ width: '20px' }} />
                </IconButton>
                <IconButton
                    onClick={() => setLanguage("en")}
                    style={language === "de" ? activeIconStyles : inactiveIconStyles }>
                        <img aria-label="ENG" src={ENG} alt="ENG" style={{ width: '20px' }} />
                </IconButton>
            </Box>
        </Box>

        {language === "en" 
            ? <EmployeesVWGroupEN />
            : <EmployeesVWGroupDE />
        }
    </>)
}

export default EmployeesVWGroup;