import React from "react";
import { Button, Box, Typography } from '@mui/material';
import { useStyles } from './TestSpecificationDetails/TestSpecificationDetails.styles';
import Icon from '../../assets/icons/Icon.js';
import { StyledNavLink } from '../homepage/Homepage/Homepage.styles';
import { signalRed800 } from "../other/helper/MuiStyles";


export function TestSpecErrorMessage({ testSpecificationError, categoriesError, allCategoriesError, approvalsError, allApprovalsError, groupStandardReferencesError, allGroupStandardReferencesError, parametersError, allParametersError }) {
    const classes = useStyles();
    return <>
        <Box mx={4} mt={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow="1">
            <Icon iconName={"WarningIcon"} width={54} height={54} fill={signalRed800} />
            <Typography variant="h3" className={classes.error_text}>
                Something went wrong. The following errors have occurred:
            </Typography>
            {testSpecificationError !== undefined &&
                <Typography variant="h5" className={classes.error_type}>
                    {testSpecificationError.message}
                </Typography>
            }
            {categoriesError !== undefined &&
                <Typography variant="h5" className={classes.error_type}>
                    {categoriesError.message}
                </Typography>
            }
            {allCategoriesError !== undefined &&
                <Typography variant="h5" className={classes.error_type}>
                    {allCategoriesError.message}
                </Typography>
            }
            {approvalsError !== undefined &&
                <Typography variant="h5" className={classes.error_type}>
                    {approvalsError.message}
                </Typography>
            }
            {allApprovalsError !== undefined &&
                <Typography variant="h5" className={classes.error_type}>
                    {allApprovalsError.message}
                </Typography>
            }
            {groupStandardReferencesError !== undefined &&
                <Typography variant="h5" className={classes.error_type}>
                    {groupStandardReferencesError.message}
                </Typography>
            }
            {allGroupStandardReferencesError !== undefined &&
                <Typography variant="h5" className={classes.error_type}>
                    {allGroupStandardReferencesError.message}
                </Typography>
            }
            {allParametersError !== undefined &&
                <Typography variant="h5" className={classes.error_type}>
                    {allParametersError.message}
                </Typography>
            }
            {parametersError !== undefined &&
                <Typography variant="h5" className={classes.error_type}>
                    {parametersError.message}
                </Typography>
            }
        </Box>
        <Box my={4} display="flex" alignItems="center" justifyContent="center">
            <StyledNavLink to="/">
                <Button
                    variant="outlined"
                    color="primary">
                    Cancel
                </Button>
            </StyledNavLink>
        </Box>
    </>;
}