import { sampleVar } from "../../../operations/localeStorage";

export const handleSampleName = (name) => {
    switch (name) {
      case "P":
        return "Prototype";
      case "A":
        return "A-Sample";
      case "B":
        return "B-Sample";
      case "C":
        return "C-Sample";
      default:
        break;
    }
  };


export const handleSubSampleName = () => {
    if (sampleVar() === 0) {
      return "Prototype";
    } else {
      return "Sample";
    }
  };