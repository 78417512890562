import makeStyles from '@mui/styles/makeStyles';
import { grey200, grey600, grey800, signalGreen800, signalRed800 } from '../../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
  user: {
    borderTop: `1px solid ${grey200}`,
  },
  supplierimage: {
    backgroundImage: props => `url(${props.supplierImg})`,
    backgroundPosition: 'left',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: '32px',
    width: '72px',
    display: 'flex',
  },
  project_title: {
    maxWidth: '244px',
    marginBottom: 0,
  },
  percent_container_div: {
    width: "100%",
    height: "16px",
    backgroundColor: grey200,
    borderRadius: "8px",
    display: 'flex',
  },
  percent_bar_passed: {
    width: props => props.widthTestsPassed,
    height: "16px",
    backgroundColor: signalGreen800,
    borderRadius: "8px 0 0 8px",
    left: "0",
    zIndex: "2"
  },
  percent_bar_failed: {
    width: props => props.widthTestsFailed,
    height: "16px",
    backgroundColor: signalRed800,
    borderRadius: "0",
    zIndex: "2"
  },
  percent_bar_notevaluated: {
    width: props => props.widthTestsNotEvaluated,
    height: "16px",
    backgroundColor: grey800,
    borderRadius: "0 8px 8px 0",
    zIndex: "2"
  },
  platformamperebox: {
    height: '52px',
  },
  platformbox: {
    flexWrap: 'wrap',
    width: '150px'
  },
  box_typo: {
    fontWeight: '400',
    lineHeight: '18px',
    letterSpacing: "0.8px",
    paddingBottom: '8px',
  },
  test_line: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.8px',
    textTransform: 'uppercase',
    color: grey600,
  },
  tag: {
    border: `1px solid ${signalRed800}`,
    borderRadius: '20px',
    height: '30px',
    padding: '0 8px',    
    color: signalRed800,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
},
}));