import React, {useState, useEffect} from 'react';
import { useQuery } from "@apollo/client";
import Icon from '../../../assets/icons/Icon.js';
import { Box, Typography, Button, ToggleButtonGroup, ToggleButton, Grid, IconButton } from '@mui/material';
import { StyledNavLink } from '../../homepage/Homepage/Homepage.styles';
import Table from './Table';
import PlanningTableLayout from '../planning/PlanningTableLayout';
import SelectionModal from '../../testcasesection/selection/SelectionModal';
import { TESTSPECIFICATIONS, TESTSTATUSES, ALLTESTINSTANCES, TESTRESULTS, CATEGORIES, ALLAGINGSTATUS } from '../../../operations/queries/query';
import Counter from '../../other/helper/Counter/Counter';
import { DutProvider } from './dutContext'
import SampleState from '../../projectdetails/SampleState.js';
import { handleSampleName, handleSubSampleName } from '../../projectdetails/ProjectDetails/samplefunctions.js';
import { buttonPressedPetrol800, petrol800, signalRed800, grey400 } from '../../other/helper/MuiStyles.js';
import IndexInformation from '../../other/tooltips/IndexInformation/IndexInformation.js';
import { indexVar, projectRole, sampleVar, subSampleVar } from '../../../operations/localeStorage.js';
import BatteryLoader from '../../other/helper/BatteryLoader/BatteryLoader.js';
import { dynamicSort } from '../../other/helper/globalFunctions/globalFunctions.js';
import DocumentListTable from '../../other/documenttable/DocumentListTable/DocumentListTable.js';
import { useStyles } from './TestCases.styles.js';

function TestCases(props) {
  const {
    id, project, currentTestCases, testCaseList, selectedSample, setSelectedSample, selectedSubSample, setSelectedSubSample,
    selectedIndex, setSelectedIndex, testCasesLoading, formSample,
    setFormSample, projectError, projectLoading, testCasesError
  } = props


  const role = projectRole();
  const [editSample, setEditSample] = useState(false);
  const [editView, setEditView] = useState(true);
  const [samplesOpen, setSamplesOpen] = useState(true);

  const { data: categories, loading: categoriesLoading, error: categoriesError } = useQuery(CATEGORIES(true));
  const { data: testSpecifications, loading: testSpecificationsLoading, error: testSpecificationsError } = useQuery(TESTSPECIFICATIONS(false, false), { fetchPolicy: "network-only" });
  const { data: testStatuses, loading: testStatusesLoading, error: testStatusesError } = useQuery(TESTSTATUSES);
  const { data: allAgingStatus, loading: allAgingStatusLoading, error: allAgingStatusError } = useQuery(ALLAGINGSTATUS);
  const { data: allTestInstances, loading: allTestInstancesLoading, error: allTestInstancesError } = useQuery(ALLTESTINSTANCES);
  const { data: testResults, loading: testResultsLoading, error: testResultsError } = useQuery(TESTRESULTS);

  const allLoadings = [categoriesLoading, testSpecificationsLoading, testStatusesLoading, allTestInstancesLoading, testResultsLoading, allAgingStatusLoading]

  const [manipulatedTestCases, setManipulatedTestCases] = useState({});
  const [testSpecAmount, setTestSpecAmount] = useState([]);
  const [allTestSpecs, setAllTestSpecs] = useState([]);
  const [activeTestSpecs, setActiveTestSpecs] = useState();

  const [docList, setDocList] = useState({});

  const manipulateTestCases = (initialTestCases) => {
    let array = [];
    for (let i = 0; i < initialTestCases?.testCases?.length; i++) {
      let subarray = [];
      for (let j = 0; j < initialTestCases?.testCases[i]?.testCases?.length; j++) {
        subarray.push({ testCaseId: initialTestCases?.testCases[i]?.testCases[j]?.testCaseId, duts: initialTestCases?.testCases[i]?.testCases[j]?.duts, testResult: initialTestCases?.testCases[i]?.testCases[j]?.testResult, calendarWeeks: initialTestCases?.testCases[i]?.testCases[j]?.calendarWeeks, testInstance: initialTestCases?.testCases[i]?.testCases[j]?.testInstance, testSpecification: initialTestCases?.testCases[i]?.testCases[j]?.testSpecification, commentCount: initialTestCases?.testCases[i]?.testCases[j]?.commentCount, showDuts: false });
      }
      array.push({ category: initialTestCases?.testCases[i]?.category, testCases: subarray });
    }
    setManipulatedTestCases(array);
  };

  const handleEditSampleClick = () => {
    setEditSample(!editSample);
  };

  const countTestCases = () => {
    let sum = 0;
    for (let i = 0; i < currentTestCases?.testCases?.length; i++) {
      sum = currentTestCases?.testCases[i]?.testCases?.length + sum;
    }
    return sum
  };

  //Could be written nicer
  const countTestSpecAmount = (initialTestCases) => {
    let counter = [];
    initialTestCases?.testCases.map(catTestcases => {
      return (
        counter.push(catTestcases.testCases.map(testcase => { return { testSpecificationId: testcase.testSpecification.testSpecificationId } }))
      )
    })
    let result = [];
    for (let i of counter) {
      let res = Object.values(i.reduce((a, { testSpecificationId }) => {
        a[testSpecificationId] = a[testSpecificationId] || { testSpecificationId, amount: 0 };
        a[testSpecificationId].amount++;
        return a;
      }, Object.create(null)));
      result.push(res)
    }
    let result2 = []
    for (let i of result) {
      for (let j of i) {
        result2.push(j)
      }
    }
    setTestSpecAmount(result2);
  }

  // adopt amount of selected testSpecs for "cancel" or "add test case" onClick events via testSpecAmount 
  const resetTestSpecAmount = () => {
    if (testSpecifications !== undefined) {
      let testSpecs = [];
      for (let i of testSpecifications.testSpecifications) {
        testSpecs.push({
          testSpecificationId: i.testSpecificationId,
          name: i.name,
          key:`${i.name} V. ${i.version}`,
          version: i.version,
          category: i.category?.name,
          amount: 0,
          groupStandardReference: i.groupStandardReference?.name
        });
      }
      // userList.users.sort(dynamicSort
      testSpecs.sort(dynamicSort("name"));
      setAllTestSpecs(testSpecs);
    }
  }

  useEffect(() => {
    manipulateTestCases(currentTestCases);
    countTestSpecAmount(currentTestCases);
    
    // update documents
    let allDocs = [];
    if (project?.project?.samples[sampleVar()]?.subSamples[subSampleVar()]?.documents != undefined) {
      for (let i of project.project.samples[sampleVar()].subSamples[subSampleVar()].documents) {
        allDocs.push(i);
      }
      setDocList({ documents: allDocs });
    }else {
      setDocList({ documents: [] });
    }
  }, [currentTestCases, project]);

  useEffect(() => {
    resetTestSpecAmount();
  }, [testSpecAmount, testSpecifications, currentTestCases]); // eslint-disable-line

  const classes = useStyles();

  const allErrors = [categoriesError, testSpecificationsError, testStatusesError, allTestInstancesError, testResultsError, allAgingStatusError]

  return (
    <DutProvider>
      <Grid item xs={12} display="grid">
        <>
          {/* ERROR Handling */}
          {allErrors.includes(!undefined) &&
            <>
              <Box mx={4} py={4} display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
                <Typography variant="h1" style={{ marginRight: '16px' }} >
                  Sample States and Attached Documents
                </Typography>
              </Box>
              <Box my={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Icon iconName={"WarningIcon"} width={54} height={54} fill={signalRed800} />
                <Typography variant="h3" className={classes.error_text}>
                  Something went wrong. The following errors have occurred:
                </Typography>
                {categoriesError !== undefined &&
                  <Typography variant="h5" className={classes.error_type}>
                    {categoriesError.message}
                  </Typography>
                }
                {testSpecificationsError !== undefined &&
                  <Typography variant="h5" className={classes.error_type}>
                    {testSpecificationsError.message}
                  </Typography>
                }
                {testStatusesError !== undefined &&
                  <Typography variant="h5" className={classes.error_type}>
                    {testStatusesError.message}
                  </Typography>
                }
                {allAgingStatusError !== undefined &&
                  <Typography variant="h5" className={classes.error_type}>
                    {allAgingStatusError.message}
                  </Typography>
                }
                {allTestInstancesError !== undefined &&
                  <Typography variant="h5" className={classes.error_type}>
                    {allTestInstancesError.message}
                  </Typography>
                }
                {testResultsError !== undefined &&
                  <Typography variant="h5" className={classes.error_type}>
                    {testResultsError.message}
                  </Typography>
                }
                {projectError !== undefined &&
                  <Typography variant="h5" className={classes.error_type}>
                    {projectError.message}
                  </Typography>
                }
                {testCasesError !== undefined &&
                  <Typography variant="h5" className={classes.error_type}>
                    {testCasesError.message}
                  </Typography>
                }
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center">
                <StyledNavLink to="/">
                  <Button
                    variant="outlined"
                    color="primary">
                    Cancel
                  </Button>
                </StyledNavLink>
              </Box>
            </>
          }

          {/* SAMPLE STATES AND DOCUMENTS */}
          <>
            <Box id="samplestates" mx={4} mt={4} mb={3} display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
              <Typography variant="h2" style={{ lineHeight: '40px' }}>
                Sample States & Documents
              </Typography>
              <Box display="flex">
                {(role === 'Administrator' || role === 'Project User') &&
                  <Button
                    variant={"outlined"}
                    color={"secondary"}
                    onClick={samplesOpen ? () => { handleEditSampleClick() } : () => { setSamplesOpen(true); handleEditSampleClick() }}
                    disabled={project?.project?.isLocked}
                    style={!editSample ? { marginRight: '24px' } : { marginRight: '24px', backgroundColor: buttonPressedPetrol800 }}
                  >
                    <div style={{ marginRight: '8px', display: 'flex' }}>
                      <Icon iconName={'EditIcon'} fill={project?.project?.isLocked ? grey400 : petrol800} />
                    </div>
                    {!editSample ? 'Edit' : 'Finish'}
                  </Button>
                }
                <Box display="flex" alignItems="center">
                  <IconButton onClick={editSample ? () => { setSamplesOpen(!samplesOpen); setEditSample(false) } : () => { setSamplesOpen(!samplesOpen) }} className={classes.arrow_hover}>
                    <Icon
                      fill={petrol800}
                      iconName={samplesOpen ? "ArrowUpIcon" : "ArrowDownIcon"}
                    />
                  </IconButton>
                </Box>
              </Box>
            </Box>

            {/* ARROW ICON - DUT OPENER */}
            <Box mx={4} display="flex" flexDirection="row" justifyContent="space-between">
              {!samplesOpen &&
                <>
                  <Box display="flex">
                    <Box className={classes.normalButton}>{handleSampleName(selectedSample)}</Box>
                    <Box className={classes.normalButton}>{selectedSubSample}-{handleSubSampleName()}</Box>
                    <Box className={classes.normalButton}>{selectedIndex}</Box>
                    <IndexInformation info={project.project.samples[sampleVar()]?.subSamples[subSampleVar()]?.indices[indexVar()]?.info} />
                  </Box>
                  <Box className={classes.documentButton}>{(project.project.samples[sampleVar()]?.subSamples[subSampleVar()]?.documents?.length < 1 || project.project.samples[sampleVar()]?.subSamples[subSampleVar()]?.documents === null) ? '0 Documents' : project.project.samples[sampleVar()]?.subSamples[subSampleVar()]?.documents?.length === 1 ? '1 Document' : `${project.project.samples[sampleVar()]?.subSamples[subSampleVar()]?.documents?.length} Documents`}</Box>
                </>
              }
              {samplesOpen && <Box></Box>}
            </Box>
            {samplesOpen &&
              <Box display="flex" flexDirection="column" mx={4}>
                {/* SAMPLE STATE */}
                {allErrors.every(error => error === undefined) && formSample !== undefined &&
                  <SampleState
                    index
                    id={id}
                    projectId={project?.project?.projectId}
                    formSample={formSample}
                    setFormSample={setFormSample}
                    selectedSample={selectedSample}
                    setSelectedSample={setSelectedSample}
                    setSelectedSubSample={setSelectedSubSample}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    editSample={editSample}
                    setEditSample={setEditSample}
                    projectLoading={projectLoading}
                  />
                }
                {/* DOCUMENTS */}
                <Box mt={4}>
                  <DocumentListTable access="sub sample" appendId={project?.project?.samples[sampleVar()]?.subSamples[subSampleVar()]?.subSampleId} documents={docList} isLocked={project?.project?.isLocked} />
                </Box>
                {/* ToDo Integrate Loading in sample state, to have battery loader inside grey box */}
                {
                  allLoadings.includes(true) && allErrors.every(error => error === undefined) &&
                  <>
                    <Box mt={8} mb={4} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                      <BatteryLoader type="standard" />
                    </Box>
                  </>
                }
              </Box>
            }
          </>

          {/* DIVIDER */}
          <Box mx={4} mt={samplesOpen ? 4 : 3} display="flex" className={classes.divider}></Box>

          {/* TEST CASES */}
          {allErrors.every(error => error === undefined) &&
            <>
              <Box mx={4} pb={4}>
                <Box mt={4} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                  <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
                    <Typography variant="h2" style={{ marginRight: '16px' }} >
                      Test Cases
                    </Typography>
                    <Counter number={countTestCases()} />
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <ToggleButtonGroup
                      value={true}
                      exclusive
                      className={classes.toggle_button_group}
                      aria-label="test-case-view"
                    >
                      <ToggleButton
                        onClick={() => setEditView(true)}
                        value={editView}
                        style={{ borderRadius: '2px 0px 0px 2px' }}
                      >
                        Editing View
                      </ToggleButton>
                      <ToggleButton
                        onClick={() => setEditView(false)}
                        value={!editView}
                        style={{ borderRadius: '0px 2px 2px 0px' }}
                      >
                        Schedule View
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                  {currentTestCases?.testCases !== undefined &&
                    <SelectionModal
                      project={project}
                      selectedSample={selectedSample}
                      selectedSubSample={selectedSubSample}
                      selectedIndex={selectedIndex}
                      testSpecAmount={testSpecAmount}
                      currentTestCases={manipulatedTestCases}
                      allTestSpecs={allTestSpecs}
                      activeTestSpecs={activeTestSpecs}
                      setAllTestSpecs={setAllTestSpecs}
                      setActiveTestSpecs={setActiveTestSpecs}
                      resetTestSpecAmount={resetTestSpecAmount}
                      categories={categories}
                      isLocked={project?.project?.isLocked}
                    />
                  }
                </Box>
                {testCasesLoading &&
                  <Box display="flex" justifyContent="center">
                    <BatteryLoader type="standard" />
                  </Box>
                }
                {
                  currentTestCases?.testCases?.length === 0 && !testCasesLoading &&
                  <Box display="flex" justifyContent="center">
                    <div className={classes.addtestspec}>No test case has been added yet.</div>
                  </Box>
                }
                {editView && allLoadings.every(loading => loading === false) && currentTestCases?.testCases?.map((i, j) => {
                  return (
                    <Table
                      key={j}
                      categoryTests={i}
                      testCaseList={testCaseList?.testCases[j]}
                      id={id}
                      agingStatus={allAgingStatus}
                      testStatuses={testStatuses}
                      testInstances={allTestInstances}
                      testResults={testResults}
                      isLocked={project?.project?.isLocked}
                      // MUTATIONS
                      testCasesLoading={testCasesLoading}
                    />
                  )
                })}
                {!editView && allLoadings.every(loading => loading === false) && currentTestCases?.testCases?.map((i, j) => {
                  return (
                    <PlanningTableLayout
                      id={id}
                      categoryTests={i}
                      testStatuses={testStatuses}
                      testInstances={allTestInstances}
                      testCaseList={testCaseList?.testCases[j]}
                      key={i.category.categoryId}
                    />
                  )
                })}
              </Box>
            </>
          }
        </>
      </Grid >
    </DutProvider >
  )
}

export default TestCases;