import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { projectRole, sampleVar } from '../../../operations/localeStorage'
import { useQuery } from '@apollo/client';
import { TESTSPECIFICATIONS, PROJECTS, MOREPROJECTS, MYPROJECTS, MOREMYPROJECTS, ALLCATEGORIES } from '../../../operations/queries/query';
import { Paper, Grid, Box, Typography, Button, TextField, ToggleButton, ToggleButtonGroup, Autocomplete } from '@mui/material';
import ProjectBox from '../ProjectBox/ProjectBox';
import TestSpecificationBox from '../TestSpecificationBox/TestSpecificationBox';
import Counter from '../../other/helper/Counter/Counter';
import FilterButton from '../../other/helper/FilterButton/FilterButton';
import { useStyles } from './Homepage.styles'
import Icon from '../../../assets/icons/Icon.js';
import BatteryLoader from '../../other/helper/BatteryLoader/BatteryLoader';
import ProjectListTable from '../ProjectList/ProjectListTable/ProjectListTable';
import TestSpecificationListTable from '../TestSpecificationList/TestSpecificationListTable/TestSpecificationListTable';
import ScrollUpIcon from '../../other/helper/ScrollUpIcon/ScrollUpIcon';
import { grey600, signalRed800 } from '../../other/helper/MuiStyles';

function Homepage() {
  sampleVar(0);
  const role = projectRole();
  let amountProjects;

  switch (true) {
    case window.innerWidth < 1280:
      amountProjects = 3;
      break;
    case window.innerWidth < 1576:
      amountProjects = 4;
      break;
    case window.innerWidth < 1872:
      amountProjects = 5;
      break;
    case window.innerWidth < 2168:
      amountProjects = 6;
      break;
    case window.innerWidth < 2464:
      amountProjects = 7;
      break;
    case window.innerWidth < 2760:
      amountProjects = 8;
      break;
    case window.innerWidth >= 2760:
      amountProjects = 9;
      break;
    default:
      amountProjects = 4;
      break;
  }

  const [alignmentMyProjects, setAlignmentMyProjects] = useState('left');
  const [myShowMorePossible, setMyShowMorePossible] = useState(false);
  const [myEndCursor, setMyEndCursor] = useState();
  const [myProjectsAvailable, setMyProjectsAvailable] = useState(false);

  const [alignmentAllProjects, setAlignmentAllProjects] = useState('left');
  const [showMorePossible, setShowMorePossible] = useState(false);
  const [endCursor, setEndCursor] = useState();

  const [alignmentTestSpecifications, setAlignmentTestSpecifications] = useState('right');
  const [filterButtonTestSpecifications, setFilterButtonTestSpecifications] = useState(false);
  const [activeTestSpecificationFilters, setActiveTestSpecificationFilters] = useState([]);
  const [filterKeyTestSpecification, setFilterKeyTestSpecification] = useState(false);

  const [activeMyProjects, setActiveMyProjects] = useState([]);
  const [activeAllProjects, setActiveAllProjects] = useState([]);
  const [activeTestSpecifications, setActiveTestSpecifications] = useState([]);

  const { data: myProjectsInitial, error: myProjectsInitialError, loading: myProjectsInitialLoading } = useQuery(MYPROJECTS(amountProjects), { fetchPolicy: "network-only" });
  const { data: allProjectsInitial, error: allProjectsInitialError, loading: allProjectsInitialLoading } = useQuery(PROJECTS(amountProjects), { fetchPolicy: "network-only" });
  const { data: testSpecifications, error: testSpecificationsError, loading: testSpecificationsLoading } = useQuery(TESTSPECIFICATIONS(true, true), { fetchPolicy: "network-only" });
  const { data: categories, error: categoriesError, loading: categoriesLoading } = useQuery(ALLCATEGORIES);
  const { data: allProjectsMore, error: allProjectsMoreError, loading: allProjectsMoreLoading } = useQuery(MOREPROJECTS(amountProjects, endCursor), { skip: endCursor === undefined || endCursor === "" }, { fetchPolicy: "network-only" });
  const { data: myProjectsMore, error: myProjectsMoreError, loading: myProjectsMoreLoading } = useQuery(MOREMYPROJECTS(amountProjects, myEndCursor), { skip: myEndCursor === undefined || myEndCursor === "" }, { fetchPolicy: "network-only" });

  // SORTING VARIABLES
  const [testSpecKey, setTestSpecKey] = useState(true);

  const [sortSupplierMyProjects, setSortSupplierMyProjects] = useState(true);
  const [sortTitleMyProjects, setSortTitleMyProjects] = useState(true);
  const [sortCapacityMyProjects, setSortCapacityMyProjects] = useState(true);
  const [sortSampleMyProjects, setSortSampleMyProjects] = useState(true);

  const [sortSupplierAllProjects, setSortSupplierAllProjects] = useState(true);
  const [sortTitleAllProjects, setSortTitleAllProjects] = useState(true);
  const [sortCapacityAllProjects, setSortCapacityAllProjects] = useState(true);
  const [sortSampleAllProjects, setSortSampleAllProjects] = useState(true);

  const [sortTitleTestSpec, setSortTitleTestSpec] = useState(true);
  const [sortDraftTestSpec, setSortDraftTestSpec] = useState(true);
  const [sortCategoryTestSpec, setSortCategoryTestSpec] = useState(true);
  const [sortReferenceTestSpec, setSortReferenceTestSpec] = useState(true);
  const [sortApprovalTestSpec, setSortApprovalTestSpec] = useState(true);

  const handleAlignmentMyProjects = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignmentMyProjects(newAlignment);
    }
  };

  const handleAlignmentAllProjects = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignmentAllProjects(newAlignment);
    }
  };

  const handleFilterTestSpecificationClick = () => {
    setFilterButtonTestSpecifications(!filterButtonTestSpecifications);
  };

  const handleAlignmentTestSpecification = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignmentTestSpecifications(newAlignment);
    }
  };

  const handleFilterTestSpecificationSelection = (event, testSpecificationFilter) => {
    setActiveTestSpecificationFilters(testSpecificationFilter);
  };

  const returnCorrectSampleSort = (project) => {
    if (project.samples[3].hasTestCases) {
      return 4;
    }
    else if (project.samples[2].hasTestCases) {
      return 3;
    }
    else if (project.samples[1].hasTestCases) {
      return 2;
    }
    else if (project.samples[0].hasTestCases) {
      return 1;
    }
    else if (!project.samples[0].hasTestCases) {
      return 0;
    }
  };

  const addProjectsToActiveAllProjects = () => {
    let value = [];
    let allValues = [...activeAllProjects];
    let moreProjects = false;
    let cursor = '';
    //check whether we find better solution than if condition
    if (allProjectsMore !== undefined) {
      for (let i of allProjectsMore.projectsConnection.edges) {
        value = { cellSupplier: i.node.cellSupplier, isLocked: i.node.isLocked, name: i.node.name, nominalCapacity: i.node.nominalCapacity, platforms: i.node.platforms, projectId: i.node.projectId, samples: i.node.samples, sort: returnCorrectSampleSort(i.node), testResult: i.node.testResult, users: i.node.users };
        allValues = allValues.concat(value);
      }
      moreProjects = allProjectsMore.projectsConnection.pageInfo.hasNextPage;
      cursor = allProjectsMore.projectsConnection.pageInfo.endCursor;
    }
    setShowMorePossible(moreProjects);
    setEndCursor(cursor);
    setActiveAllProjects(allValues);
  };

  const addProjectsToActiveMyProjects = () => {
    let value = [];
    let allValues = [...activeMyProjects];
    let moreProjects = false;
    let cursor = '';
    //check whether we find better solution than if condition
    if (myProjectsMore !== undefined) {
      for (let i of myProjectsMore.myProjectsConnection.edges) {
        value = { cellSupplier: i.node.cellSupplier, isLocked: i.node.isLocked, name: i.node.name, nominalCapacity: i.node.nominalCapacity, platforms: i.node.platforms, projectId: i.node.projectId, samples: i.node.samples, sort: returnCorrectSampleSort(i.node), testResult: i.node.testResult, users: i.node.users };
        allValues = allValues.concat(value);
      }
      moreProjects = myProjectsMore.myProjectsConnection.pageInfo.hasNextPage;
      cursor = myProjectsMore.myProjectsConnection.pageInfo.endCursor;
    }
    setMyShowMorePossible(moreProjects);
    setMyEndCursor(cursor);
    setActiveMyProjects(allValues);
  };

  const searchTestSpecification = (event, value) => {
    let allTestSpecs = activeTestSpecifications;
    let filter = [];
    let regEx = new RegExp(value);
    let list = allTestSpecs.filter(function (currentElement) {
      return regEx.test(currentElement.name);
    });
    if (value == null) {
      for (let i of activeTestSpecificationFilters) {
        filter = testSpecifications.filter(({ category }) => category === i);
        list = list.concat(filter);
      }
      if (activeTestSpecificationFilters.length < 1) {
        list = testSpecifications.testSpecifications;
      }
    }
    setActiveTestSpecifications(list);
  };

  const isLoadingMyProjects = () => {
    return (myProjectsInitialLoading || myProjectsMoreLoading)
  };

  const isLoadingAllProjects = () => {
    return (allProjectsInitialLoading || allProjectsMoreLoading)
  };

  const isLoadingTestSpecifications = () => {
    return (testSpecificationsLoading || categoriesLoading)
  };

  const hasErrorMyProjects = () => {
    return !(myProjectsInitialError === undefined && myProjectsMoreError === undefined)
  };

  const hasErrorAllProjects = () => {
    return !(allProjectsInitialError === undefined && allProjectsMoreError === undefined)
  };

  const hasErrorTestSpecifications = () => {
    return !(testSpecificationsError === undefined && categoriesError === undefined)
  };


  //intial load of testSpecs
  useEffect(() => {
    let value = [];
    let allValues = [];
    //check whether we find better solution than if condition
    if (testSpecifications !== undefined) {
      for (let i of testSpecifications.testSpecifications) {
        value = i;
        allValues = allValues.concat(value);
      }
      if (activeTestSpecificationFilters.length < 1) {
        allValues = testSpecifications.testSpecifications;
      }
    }
    setActiveTestSpecifications(allValues);
  }, [testSpecifications]); // eslint-disable-line

  //intial load of all projects
  useEffect(() => {
    let value = [];
    let allValues = [];
    let moreProjects = false;
    let cursor = '';
    //check whether we find better solution than if condition
    if (allProjectsInitial !== undefined) {
      for (let i of allProjectsInitial.projectsConnection.edges) {
        value = i.node;
        value.sort = returnCorrectSampleSort(i.node);
        allValues = allValues.concat(value);
      }
      moreProjects = allProjectsInitial.projectsConnection.pageInfo.hasNextPage;
      cursor = allProjectsInitial.projectsConnection.pageInfo.endCursor;
    }
    setShowMorePossible(moreProjects);
    setEndCursor(cursor);
    setActiveAllProjects(allValues);
  }, [allProjectsInitial]);

  //intial load of my projects
  useEffect(() => {
    let value = [];
    let allValues = [];
    let moreProjects = false;
    let cursor = '';
    //check whether we find better solution than if condition
    if (myProjectsInitial !== undefined) {
      for (let i of myProjectsInitial.myProjectsConnection.edges) {
        value = i.node;
        value.sort = returnCorrectSampleSort(i.node);
        allValues = allValues.concat(value);
      }
      moreProjects = myProjectsInitial.myProjectsConnection.pageInfo.hasNextPage;
      cursor = myProjectsInitial.myProjectsConnection.pageInfo.endCursor;
    }
    if (myProjectsInitial !== undefined && allValues.length < 1) {
      setMyProjectsAvailable(false);
    } else {
      setMyProjectsAvailable(true);
    }
    setMyShowMorePossible(moreProjects);
    setMyEndCursor(cursor);
    setActiveMyProjects(allValues);
  }, [myProjectsInitial]);

  //apply filters on testSpecs
  useEffect(() => {
    let value = [];
    let allValues = [];
    setActiveTestSpecifications(value);
    //check whether we find better solution than if condition
    if (testSpecifications !== undefined) {
      for (let i of activeTestSpecificationFilters) {
        value = testSpecifications.testSpecifications.filter(({ category }) => category.name === i);
        allValues = allValues.concat(value);
      }
      if (activeTestSpecificationFilters.length < 1) {
        allValues = testSpecifications.testSpecifications;
      }
    }
    setActiveTestSpecifications(allValues);
    setFilterKeyTestSpecification(!filterKeyTestSpecification);
  }, [activeTestSpecificationFilters]); // eslint-disable-

  function ProjectsHeader({ myProjects }) {
    return (
      <Box mx={4} py={4} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" flexWrap="wrap">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="h1" className={classes.headline}>
            {myProjects ? 'My Projects' : 'Projects'}
          </Typography>
          <Counter number={myProjects ? myProjectsInitial?.myProjectsConnection?.total : allProjectsInitial?.projectsConnection?.total} margin="0 0 0 16px"></Counter>
        </Box>

        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <ToggleButtonGroup
            value={myProjects ? alignmentMyProjects : alignmentAllProjects}
            exclusive
            onChange={myProjects ? handleAlignmentMyProjects : handleAlignmentAllProjects}
            aria-label={myProjects ? 'myProjects-alignment' : 'allProjects-alignment'}
            style={{ marginLeft: '16px' }}
          >
            <ToggleButton
              value="left"
              aria-label="grid"
              style={{ borderRadius: '2px 0px 0px 2px', width: '40px', padding: '0px' }}
            >
              <Icon iconName={'GridIcon'} />
            </ToggleButton>
            <ToggleButton
              value="right"
              aria-label="list"
              data-testid={"project-list-view-button"}
              style={{ borderRadius: '0px 2px 2px 0px', width: '40px', padding: '0px' }}
            >
              <Icon iconName={'MenuIcon'} />
            </ToggleButton>
          </ToggleButtonGroup>

          {role === 'Administrator' &&
            <Button
              component={Link} to={`/addproject`}
              variant="contained"
              color="primary"
              className={classes.add_button}
            >
              <div style={{ display: 'flex', marginRight: '8px' }}>
                <Icon iconName={"AddIcon"} fill={'#FFF'} />
              </div>
              Add Project
            </Button>
          }
        </Box>
      </Box>
    )
  }

  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>

        <Grid container spacing={3}>

          {/* CHECK WHETHER MY PROJECTS APPLICABLE */}
          {myProjectsAvailable &&
            <Grid item xs={12} display="grid">
              <Paper className={classes.paper} style={{ minHeight: 'calc((100vh * (1/3)) - ((64px + 24px + 24px + 48px)*(1/3)))' }}>

                {/* ERROR */}
                {hasErrorMyProjects() &&
                  <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc((100vh * (1/3)) - ((64px + 24px + 24px + 48px)*(1/3)))' }}>
                    <Box mx={4} py={4} display="flex" flexDirection="row" >
                      <Typography variant="h1" className={classes.headline}>
                        My Projects
                      </Typography>
                    </Box>
                    <Box mx={4} py={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow="1">
                      <Icon iconName={'WarningIcon'} width={54} height={54} fill={signalRed800} />
                      <Typography variant="h3" className={classes.error_text}>
                        Something went wrong. The following errors have occurred:
                      </Typography>
                      {myProjectsInitialError !== undefined &&
                        <Typography variant="h5" className={classes.error_type}>
                          {myProjectsInitialError.message}
                        </Typography>
                      }
                      {myProjectsMoreError !== undefined &&
                        <Typography variant="h5" className={classes.error_type}>
                          {myProjectsMoreError.message}
                        </Typography>
                      }
                    </Box>
                  </Box>
                }

                {/* LOADING */}
                {isLoadingMyProjects() && !hasErrorMyProjects() &&
                  <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc((100vh * (1/3)) - ((64px + 24px + 24px + 48px)*(1/3)))' }}>
                    <Box mx={4} py={4} display="flex" alignItems="center">
                      <Typography variant="h1" className={classes.headline}>
                        My Projects
                      </Typography>
                    </Box>
                    <Box mx={4} py={4} display="flex" alignItems="center" justifyContent="center" flexGrow="1">
                      <BatteryLoader type="standard" />
                    </Box>
                  </Box>
                }

                {!isLoadingMyProjects() && !hasErrorMyProjects() &&
                  <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc((100vh * (1/3)) - ((64px + 24px + 24px + 48px)*(1/3)))' }}>
                    <Box display="flex" flexDirection="column">
                      {/* HEADER */}
                      <ProjectsHeader myProjects />
                      {/* PROJECT BOXES */}
                      {alignmentMyProjects === 'left' &&
                        <Box mb={2}>
                          <Box mx={3} display="flex" flexDirection="row" justifyContent="flex-start" flexWrap="wrap">
                            {activeMyProjects.map(i => {
                              return (
                                <Box
                                  mx={1} mb={2} className={classes.test_box} key={'my' + i.projectId}
                                  data-testid={"my-project-box-item"}>
                                  <ProjectBox i={i} />
                                </Box>
                              )
                            })}
                          </Box>
                          {myShowMorePossible &&
                            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" flexWrap="wrap">
                              <Button
                                variant="outlined"
                                color="secondary"
                                onClick={addProjectsToActiveMyProjects}
                              >
                                Show More
                              </Button>
                            </Box>
                          }
                        </Box>
                      }
                      {/* PROJECT LIST */}
                      {alignmentMyProjects === 'right' &&
                        <Box mb={4}>
                          <ProjectListTable projects={activeMyProjects} testSpecKey={testSpecKey} setTestSpecKey={setTestSpecKey} sortTitleProjects={sortTitleMyProjects} setSortTitleProjects={setSortTitleMyProjects} sortCapacityProjects={sortCapacityMyProjects} setSortCapacityProjects={setSortCapacityMyProjects} sortSampleProjects={sortSampleMyProjects} setSortSampleProjects={setSortSampleMyProjects} sortSupplierProjects={sortSupplierMyProjects} setSortSupplierProjects={setSortSupplierMyProjects} />
                          {myShowMorePossible &&
                            <Box mt={2} mb={-2} display="flex" flexDirection="row" alignItems="center" justifyContent="center" flexWrap="wrap">
                              <Button
                                variant="outlined"
                                color="secondary"
                                onClick={addProjectsToActiveMyProjects}
                              >
                                Show More
                              </Button>
                            </Box>
                          }
                        </Box>
                      }

                    </Box>
                  </Box>
                }
              </Paper>
            </Grid>
          }

          <Grid item xs={12} display="grid">
            <Paper className={classes.paper} style={myProjectsAvailable ? { minHeight: 'calc((100vh * (1/3)) - ((64px + 24px + 24px + 48px)*(1/3)))' } : { minHeight: 'calc((100vh * (1/2)) - ((64px + 24px + 24px + 24px)*(1/2)))' }}>

              {/* ERROR */}
              {hasErrorAllProjects() &&
                <Box display="flex" flexDirection="column" justifyContent="space-between" style={myProjectsAvailable ? { minHeight: 'calc((100vh * (1/3)) - ((64px + 24px + 24px + 48px)*(1/3)))' } : { minHeight: 'calc((100vh * (1/2)) - ((64px + 24px + 24px + 24px)*(1/2)))' }}>
                  <Box mx={4} py={4} display="flex" flexDirection="row" >
                    <Typography variant="h1" className={classes.headline}>
                      Projects
                    </Typography>
                  </Box>
                  <Box mx={4} py={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow="1">
                    <Icon iconName={'WarningIcon'} width={54} height={54} fill={signalRed800} />
                    <Typography variant="h3" className={classes.error_text}>
                      Something went wrong. The following errors have occurred:
                    </Typography>
                    {allProjectsInitialError !== undefined &&
                      <Typography variant="h5" className={classes.error_type}>
                        {allProjectsInitialError.message}
                      </Typography>
                    }
                    {allProjectsMoreError !== undefined &&
                      <Typography variant="h5" className={classes.error_type}>
                        {allProjectsMoreError.message}
                      </Typography>
                    }
                  </Box>
                </Box>
              }

              {/* LOADING */}
              {isLoadingAllProjects() && !hasErrorAllProjects() &&
                <Box display="flex" flexDirection="column" justifyContent="space-between" style={myProjectsAvailable ? { minHeight: 'calc((100vh * (1/3)) - ((64px + 24px + 24px + 48px)*(1/3)))' } : { minHeight: 'calc((100vh * (1/2)) - ((64px + 24px + 24px + 24px)*(1/2)))' }}>
                  <Box mx={4} py={4} display="flex" alignItems="center">
                    <Typography variant="h1" className={classes.headline}>
                      Projects
                    </Typography>
                  </Box>
                  <Box mx={4} py={4} display="flex" alignItems="center" justifyContent="center" flexGrow="1">
                    <BatteryLoader type="standard" />
                  </Box>
                </Box>
              }

              {!isLoadingAllProjects() && !hasErrorAllProjects() &&
                <Box display="flex" flexDirection="column" justifyContent="space-between" style={myProjectsAvailable ? { minHeight: 'calc((100vh * (1/3)) - ((64px + 24px + 24px + 48px)*(1/3)))' } : { minHeight: 'calc((100vh * (1/2)) - ((64px + 24px + 24px + 24px)*(1/2)))' }}>
                  <Box display="flex" flexDirection="column">
                    {/* HEADER */}
                    <ProjectsHeader />
                    {/* PROJECT BOXES */}
                    {alignmentAllProjects === 'left' &&
                      <Box mb={2}>
                        <Box mx={3} display="flex" flexDirection="row" justifyContent="flex-start" flexWrap="wrap">
                          {activeAllProjects.map(i => {
                            return (
                              <Box
                                mx={1} mb={2} data-testid={"project-box-item"}
                                className={classes.test_box} key={'all' + i.projectId}>
                                <ProjectBox i={i} />
                              </Box>
                            )
                          })}
                        </Box>
                        {showMorePossible &&
                          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" flexWrap="wrap">
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={addProjectsToActiveAllProjects}
                            >
                              Show More
                            </Button>
                          </Box>
                        }
                      </Box>
                    }
                    {/* PROJECT LIST */}
                    {alignmentAllProjects === 'right' &&
                      <Box mb={4}>
                        <ProjectListTable projects={activeAllProjects} testSpecKey={testSpecKey} setTestSpecKey={setTestSpecKey} sortTitleProjects={sortTitleAllProjects} setSortTitleProjects={setSortTitleAllProjects} sortCapacityProjects={sortCapacityAllProjects} setSortCapacityProjects={setSortCapacityAllProjects} sortSampleProjects={sortSampleAllProjects} setSortSampleProjects={setSortSampleAllProjects} sortSupplierProjects={sortSupplierAllProjects} setSortSupplierProjects={setSortSupplierAllProjects} />
                        {showMorePossible &&
                          <Box mt={2} mb={-2} display="flex" flexDirection="row" alignItems="center" justifyContent="center" flexWrap="wrap">
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={addProjectsToActiveAllProjects}
                            >
                              Show More
                            </Button>
                          </Box>
                        }
                      </Box>
                    }
                  </Box>
                </Box>
              }
            </Paper>
          </Grid>


          <Grid item xs={12} display="grid">
            <Paper className={classes.paper} style={myProjectsAvailable ? { minHeight: 'calc((100vh * (1/3)) - ((64px + 24px + 24px + 48px)*(1/3)))' } : { minHeight: 'calc((100vh * (1/2)) - ((64px + 24px + 24px + 24px)*(1/2)))' }}>

              {/* ERROR */}
              {hasErrorTestSpecifications() &&
                <Box display="flex" flexDirection="column" justifyContent="space-between" style={myProjectsAvailable ? { minHeight: 'calc((100vh * (1/3)) - ((64px + 24px + 24px + 48px)*(1/3)))' } : { minHeight: 'calc((100vh * (1/2)) - ((64px + 24px + 24px + 24px)*(1/2)))' }}>
                  <Box mx={4} py={4} display="flex" flexDirection="row" >
                    <Typography variant="h1" className={classes.headline}>
                      Test Specifications
                    </Typography>
                  </Box>
                  <Box mx={4} py={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow="1">
                    <Icon iconName={'WarningIcon'} width={54} height={54} fill={signalRed800} />
                    <Typography variant="h3" className={classes.error_text}>
                      Something went wrong. The following errors have occurred:
                    </Typography>
                    {testSpecificationsError !== undefined &&
                      <Typography variant="h5" className={classes.error_type}>
                        {testSpecificationsError.message}
                      </Typography>
                    }
                    {categoriesError !== undefined &&
                      <Typography variant="h5" className={classes.error_type}>
                        {categoriesError.message}
                      </Typography>
                    }
                  </Box>
                </Box>
              }

              {/* LOADING */}
              {isLoadingTestSpecifications() && !hasErrorTestSpecifications() &&
                <Box display="flex" flexDirection="column" justifyContent="space-between" style={myProjectsAvailable ? { minHeight: 'calc((100vh * (1/3)) - ((64px + 24px + 24px + 48px)*(1/3)))' } : { minHeight: 'calc((100vh * (1/2)) - ((64px + 24px + 24px + 24px)*(1/2)))' }}>
                  <Box mx={4} py={4} display="flex" alignItems="center">
                    <Typography variant="h1" className={classes.headline}>
                      Test Specifications
                    </Typography>
                  </Box>
                  <Box mx={4} py={4} display="flex" alignItems="center" justifyContent="center" flexGrow="1">
                    <BatteryLoader type="standard" />
                  </Box>
                </Box>
              }

              {!isLoadingTestSpecifications() && !hasErrorTestSpecifications() &&
                <Box display="flex" flexDirection="column" justifyContent="space-between" style={myProjectsAvailable ? { minHeight: 'calc((100vh * (1/3)) - ((64px + 24px + 24px + 48px)*(1/3)))' } : { minHeight: 'calc((100vh * (1/2)) - ((64px + 24px + 24px + 24px)*(1/2)))' }}>
                  <Box display="flex" flexDirection="column">
                    {/* HEADER */}
                    <Box mx={4} py={4} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                      <Box display="flex" flexDirection="row" alignItems="center">
                        <Typography variant="h1" className={classes.headline}>
                          Test Specifications
                        </Typography>
                        <Counter number={activeTestSpecifications?.length} margin="0 0 0 16px"></Counter>
                      </Box>

                      <Box display="flex" flexDirection="row" flexWrap="wrap">
                        <FilterButton
                          filterState={!filterButtonTestSpecifications}
                          activecount={activeTestSpecificationFilters?.length}
                          onClick={handleFilterTestSpecificationClick}
                        />
                        <ToggleButtonGroup
                          value={alignmentTestSpecifications}
                          exclusive
                          onChange={handleAlignmentTestSpecification}
                          aria-label="testSpec-alignment"
                          style={{ marginRight: '16px', marginLeft: '16px' }}
                        >
                          <ToggleButton
                            value="left"
                            aria-label="grid"
                            classes={{
                              root: classes.active_edit_button,
                              selected: classes.active_edit_button_selected,
                            }}
                            style={{ borderRadius: '2px 0px 0px 2px', width: '40px', padding: '0px' }}
                          >
                            <Icon iconName={'GridIcon'} />
                          </ToggleButton>
                          <ToggleButton
                            value="right"
                            aria-label="list"
                            classes={{
                              root: classes.active_edit_button,
                              selected: classes.active_edit_button_selected,
                            }}
                            data-testid={"test-specification-list-view-button"}
                            style={{ borderRadius: '0px 2px 2px 0px', width: '40px', padding: '0px' }}
                          >
                            <Icon iconName={'MenuIcon'} />
                          </ToggleButton>
                        </ToggleButtonGroup>
                        {amountProjects > 3 &&
                          <div className={classes.search} style={role === 'Auditor User' ? { marginRight: '40px' } : {}}>
                            <div className={classes.search_icon}>
                              <Icon iconName={'SearchIcon'} fill={grey600} />
                            </div>
                            <Autocomplete
                              id="search-test-specification"
                              key={filterKeyTestSpecification}
                              className={classes.search_field}
                              freeSolo
                              onChange={searchTestSpecification}
                              options={activeTestSpecifications.map((name) => name.name)}
                              renderInput={(params) => (
                                <TextField {...params} placeholder="Search Test Specification" />
                              )}
                            >
                            </Autocomplete>
                          </div>
                        }
                        {(role === 'Administrator' || role === 'Project User') &&
                          <Box ml={amountProjects > 3 ? 10 : 0}>
                            <Button
                              component={Link} to={`/addtestspecification`}
                              variant="contained"
                              color="primary"
                            >
                              <div style={{ display: 'flex', marginRight: '8px' }}>
                                <Icon iconName={"AddIcon"} fill={'#FFF'} />
                              </div>
                              Add Test Specification
                            </Button>
                          </Box>
                        }
                      </Box>
                    </Box>

                    {/* FILTERS */}
                    {filterButtonTestSpecifications &&
                      <Box mx={4} mb={4} display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                        <ToggleButtonGroup
                          value={activeTestSpecificationFilters}
                          onChange={handleFilterTestSpecificationSelection}
                        >
                          {categories?.categories.map(i => {
                            return (
                              <ToggleButton
                                key={i.categoryId}
                                classes={{
                                  root: classes.active_filter_button,
                                  selected: classes.active_filter_button_selected,
                                }}
                                value={i.name}
                              >
                                {i.name}
                              </ToggleButton>
                            )
                          })}

                        </ToggleButtonGroup>
                      </Box>
                    }

                    {/* TEST SPECIFICATION BOXES */}
                    {alignmentTestSpecifications === 'left' &&
                      <Box mb={2}>
                        {activeTestSpecifications?.length > 0 ?
                          <Box mx={3} display="flex" flexDirection="row" justifyContent="flex-start" flexWrap="wrap">
                            {activeTestSpecifications.map(i => {
                              return (
                                <Box
                                  component={Link} to={`/testspecification/${i.testSpecificationId}`}
                                  mx={1} mb={2} className={classes.test_box} key={i.testSpecificationId}>
                                  <TestSpecificationBox i={i} />
                                </Box>
                              )
                            })}
                          </Box>
                          :
                          <Box mx={4} mt={4} mb={2} display="flex" alignItems="center" justifyContent="center">
                            <Typography variant="h5" className={classes.no_result}>
                              No test specification available for currently selected filters or search term.
                            </Typography>
                          </Box>
                        }
                      </Box>
                    }
                    {/* TEST SPECIFICATION LIST */}
                    {alignmentTestSpecifications === 'right' &&
                      <Box mb={4}>
                        <TestSpecificationListTable key={testSpecKey} testSpecifications={activeTestSpecifications} testSpecKey={testSpecKey} setTestSpecKey={setTestSpecKey} sortTitleTestSpec={sortTitleTestSpec} setSortTitleTestSpec={setSortTitleTestSpec} sortDraftTestSpec={sortDraftTestSpec} setSortDraftTestSpec={setSortDraftTestSpec} sortCategoryTestSpec={sortCategoryTestSpec} setSortCategoryTestSpec={setSortCategoryTestSpec} sortReferenceTestSpec={sortReferenceTestSpec} setSortReferenceTestSpec={setSortReferenceTestSpec} sortApprovalTestSpec={sortApprovalTestSpec} setSortApprovalTestSpec={setSortApprovalTestSpec} />
                      </Box>
                    }

                  </Box>
                </Box>
              }
            </Paper>
          </Grid>

        </Grid>
        <ScrollUpIcon />
      </div >
    </>
  )
}

export default Homepage;