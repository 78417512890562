import { convertTimeStampToCwYear } from "../../other/helper/timeConverter/timeConverter"

const stepCheck = (format) => {
    if (!['day', 'week', 'month', 'quarter', 'year'].includes(format)) {
        throw new Error('Unsupported parameter')
    }
}

export const formatDate = (date) => {
    return (new Date(date)).toLocaleString('de', { day: '2-digit', month: '2-digit', year: 'numeric' })
}

export const getWeekStart = (date) => {
    const dateDate = new Date(date)
    const day = dateDate.getDay()
    const diff = dateDate.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
    const weekStart = new Date(dateDate.setDate(diff))
    weekStart.setHours(0,0,0,0)

    return weekStart
}

export const getWeekEnd = (date) => {
    const dateDate = new Date(date)
    const day = dateDate.getDay()
    const diff = dateDate.getDate() - day + (day === 0 ? -6 : 1) + 7  // adjust when day is sunday
    const weekEnd = new Date(dateDate.setDate(diff))
    weekEnd.setHours(0,0,0,0)

    return weekEnd
}

export const getMonthStart = (date) => {
    const monthStart = new Date(date)
    monthStart.setDate(1)
    monthStart.setHours(0,0,0,0)
    return monthStart
}

export const getMonthEnd = (date) => {
    const dateDate = new Date(date)
    const monthEnd = new Date(dateDate.getFullYear(), dateDate.getMonth() + 1, 1)
    monthEnd.setHours(0,0,0,0)
    return monthEnd
}

export const getQuarterStart = (date) => {
    const dateDate = new Date(date)
    const quarterStart = new Date(dateDate.getFullYear(), Math.floor(dateDate.getMonth() / 3) * 3, 1)
    quarterStart.setHours(0,0,0,0)
    return quarterStart
}

export const getQuarterEnd = (date) => {
    const dateDate = new Date(date)
    const quarterEnd = new Date(dateDate.getFullYear(), Math.floor(dateDate.getMonth() / 3) * 3 + 2 + 1, 1)
    quarterEnd.setHours(0,0,0,0)
    return quarterEnd
}

export const getYearStart = (date) => {
    const dateDate = new Date(date)
    const yearStart = new Date(dateDate.getFullYear(), 0, 1)
    yearStart.setHours(0,0,0,0)
    return yearStart
}

export const getYearEnd = (date) => {
    const dateDate = new Date(date)
    const yearEnd = new Date(dateDate.getFullYear(), 12, 1)
    yearEnd.setHours(0,0,0,0)
    return yearEnd
}

export const getThresholds = (start, end, step) => {
    stepCheck(step)

    let date = new Date(start)
    if (step === 'week') {
        date = getWeekStart(date)
    }
    const thresholds = []
    while (date <= end) {
        thresholds.push(new Date(date))

        switch (step) {
            case 'day':
                date.setDate(date.getDate() + 1)
                break
            case 'week':
                date.setDate(date.getDate() + 7)
                break
            case 'month':
                date.setMonth(date.getMonth() + 1)
                break
            case 'quarter':
                date.setMonth(date.getMonth() + 3)
                break
            case 'year':
                date.setMonth(date.getMonth() + 12)
                break
        }
    }
    return thresholds
}

export const getPositionProperties = (start, end, gridStart, gridEnd) => {
    const left = (start - gridStart) / (gridEnd - gridStart)
    const right = (end - gridStart) / (gridEnd - gridStart)
    const width = right - left

    return [left * 100, width * 100]
}

export const getLabel = (date, format, longFormat=false) => {
    stepCheck(format)

    switch (format) {
        case 'day':
            if (longFormat) {
                return date.toLocaleString('en-us', { month: 'short', day: 'numeric' })
            }
            else {
                return date.toLocaleString('en-us', { day: 'numeric' })
            }
        case 'week':
            if (longFormat) {
                return convertTimeStampToCwYear(date.toISOString())
            }
            else {
                return convertTimeStampToCwYear(date.toISOString()).split('/')[0]
            }
        case 'month':
            if (longFormat) {
                return date.toLocaleString('en-us', { month: 'long', year: 'numeric' })
            }
            else {
                return date.toLocaleString('en-us', { month: 'short' })
            }
        case 'quarter':
            if (longFormat) {
                return `Quarter ${Math.floor(date.getMonth() / 3) + 1} ${date.getFullYear()}`
            }
            else {
                return `Q${Math.floor(date.getMonth() / 3) + 1}`
            }
        case 'year':
            return `${date.getFullYear()}`
    }

}

export const getGridInterval = (startDates, endDates, step) => {
    stepCheck(step)

    const startDate = new Date(Math.min(...startDates.map(i => new Date(i))))
    const endDate = new Date(Math.max(...endDates.map(i => new Date(i))))

    switch (step) {
        case 'day':
            return [startDate, endDate]
        case 'week':
            return [getWeekStart(startDate), getWeekEnd(endDate)]
        case 'month':
            return [getMonthStart(startDate), getMonthEnd(endDate)]
        case 'quarter':
            return [getQuarterStart(startDate), getQuarterEnd(endDate)]
        case 'year':
            return [getYearStart(startDate), getYearEnd(endDate)]
    }

}

export const getGrid = (start, end, step, longFormat=false) => {
    const thresholds = getThresholds(start, end, step)
    const grid = []

    for (let i = 0; i < thresholds.length - 1; i++) {
        const props = getPositionProperties(thresholds[i], thresholds[i+1], start, end)
        grid.push({ 
            date: thresholds[i],
            label: getLabel(thresholds[i], step, longFormat),
            left: props[0],
            width: props[1]
        })
    }

    return grid
}

const getTimelinePartProperties = (start, planned, gridDates) => {
    if (start.getTime() === planned.getTime()) {
        return undefined
    }

    const props = getPositionProperties(Math.min(start, planned), Math.max(start, planned), gridDates.start, gridDates.end)

    return {
        left: props[0],
        width: props[1],
        isDelay: start > planned
    }
}

export const getTimelineParts = (testCaseDates, gridDates, today) => {
    const startProps = getTimelinePartProperties(testCaseDates.start, testCaseDates.plannedStart, gridDates)
    const endProps = getTimelinePartProperties(testCaseDates.end, testCaseDates.plannedEnd, gridDates)
    const mainProps = getPositionProperties(
        Math.max(testCaseDates.start, testCaseDates.plannedStart), 
        Math.min(testCaseDates.end, testCaseDates.plannedEnd), 
        gridDates.start, 
        gridDates.end
    )

    return {
        start: startProps,
        main: { left: mainProps[0], width: mainProps[1] },
        end: endProps,
        isTestCaseActive: Math.min(testCaseDates.start, testCaseDates.plannedStart) < today,
        isTestCaseOver: Math.max(...Object.values(testCaseDates)) < today
    }

}