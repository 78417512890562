import React from 'react';
import { Box} from '@mui/material';
import { useStyles } from '../TestSpecificationList.styles';
import Icon from '../../../../assets/icons/Icon.js';
import DraftInfo from '../../../other/tooltips/DraftInfo/DraftInfo.js';
import { handleCategoryIcon } from '../../../other/helper/IconHandler/IconHandler';
import { Link } from "react-router-dom";
import { grey600 } from '../../../other/helper/MuiStyles';

function TestSpecificationListElement(props) {
    const { testSpecification } = props;

    const classes = useStyles();

    return (
        <Box px={4} key={testSpecification.testSpecificationId} display="flex" className={classes.border} component={Link} to={`/testspecification/${testSpecification.testSpecificationId}`} id="test-specification-box" data-testid={"test-specification-list-item"}>

            {/* TITLE */}
            <Box display="flex" flexDirection="row" alignItems="center" className={classes.header1_text}>
                <Box className={classes.text}>{testSpecification.name}</Box>
            </Box>

            {/* CURRENT VERSION */}
            <Box display="flex" flexDirection="column" justifyContent="center" className={classes.header2_text}>
                <Box className={classes.text}>Version {testSpecification.version}</Box>
            </Box>

            {/* STATUS */}
            <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" className={classes.header6_text}>
                {testSpecification.tag === "DRAFT" &&
                    <DraftInfo />
                }
            </Box>

            {/* CATEGORY */}
            <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" className={classes.header3_text}>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                    <Icon fill={grey600} iconName={handleCategoryIcon(testSpecification.category.name)} />
                    <Box px={1} className={classes.text}>{testSpecification.category.name}</Box>
                </Box>
            </Box>

            {/* REFERENCE */}
            <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" className={classes.header4_text}>
                <Box className={classes.text}>{testSpecification.groupStandardReference?.name === undefined ? "-" : testSpecification.groupStandardReference?.name}</Box>
            </Box>

            {/* APPROVAL */}
            <Box display="flex" flexDirection="column" justifyContent="center" className={classes.header5_text}>
                <Box className={classes.text}>{testSpecification.approval?.name === undefined ? "-" : testSpecification.approval?.name}</Box>
            </Box>

        </Box>
    )
}

export default TestSpecificationListElement;