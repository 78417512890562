export const getDocVersion = (existing, uploading, docIndex) => {
    const doc = uploading[docIndex]
    const othersUploading = uploading.filter((_, i) => i !== docIndex);

    const all_doc = (existing || []).concat(othersUploading)
    
    const all_filtered = all_doc.filter(x => x.name === doc.name && 
            (typeof x.classification === 'object' ? x.classification.docClassId === doc.classification : x.classification === doc.classification)
        )

    const max_version = Math.max(...all_filtered.map(x => x.version), 0)

    return max_version + 1
};