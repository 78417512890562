import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ALLCELLSUPPLIERS } from '../../../../operations/queries/query';
import { Paper, Grid, Box, Typography, Button } from '@mui/material';
import { useStyles } from '../SupplierManagement.styles';
import { Link } from "react-router-dom";
import { StyledNavLink } from '../../../homepage/Homepage/Homepage.styles';
import BatteryLoader from '../../../other/helper/BatteryLoader/BatteryLoader';
import Icon from '../../../../assets/icons/Icon';
import SupplierManagementListElement from '../SupplierManagementListElement/SupplierManagementListElement';
import ScrollUpIcon from '../../../other/helper/ScrollUpIcon/ScrollUpIcon';
import { UPDATECELLSUPPLIER } from '../../../../operations/mutations/mutations';
import { grey200, signalRed800 } from '../../../other/helper/MuiStyles';

function SupplierManagementListTable() {
    const { data: cellSuppliers, error: cellSuppliersError, loading: cellSuppliersLoading } = useQuery(ALLCELLSUPPLIERS);

    const [updateCellSupplier, { loading: updateCellSupplierLoading }] = useMutation(UPDATECELLSUPPLIER, { errorPolicy: 'all', refetchQueries: [{ query: ALLCELLSUPPLIERS }], awaitRefetchQueries: true });

    const classes = useStyles();

    const isLoading = () => {
        return (cellSuppliersLoading || updateCellSupplierLoading)
    };

    const hasError = () => {
        return !(cellSuppliersError === undefined)
    };

    return (
        <>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} display="grid">
                        <Paper className={classes.paper}>

                            {/* ERROR */}
                            {hasError() &&
                                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 64px - 24px - 24px)' }}>
                                    <Box px={3} pt={2} display="flex" flexDirection="row" alignItems="center">
                                        <Typography variant="h3" className={classes.title}>
                                            Supplier Management
                                        </Typography>
                                    </Box>
                                    <Box mx={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow="1">
                                        <Icon iconName={"WarningIcon"} width={54} height={54} fill={signalRed800} />
                                        <Typography variant="h3" className={classes.error_text}>
                                            Something went wrong. The following errors have occurred:
                                        </Typography>
                                        {cellSuppliersError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {cellSuppliersError.message}
                                            </Typography>
                                        }
                                    </Box>
                                    <Box my={4} display="flex" alignItems="center" justifyContent="center">
                                        <StyledNavLink to="/">
                                            <Button
                                                variant="outlined"
                                                color="primary">
                                                Cancel
                                            </Button>
                                        </StyledNavLink>
                                    </Box>
                                </Box>
                            }

                            {/* LOADING */}
                            {isLoading() && !hasError() &&
                                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 64px - 24px - 24px)' }}>
                                    <Box px={3} pt={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                        <Typography variant="h3" className={classes.title}>
                                            Supplier Management
                                        </Typography>
                                    </Box>
                                    <Box mx={2} display="flex" alignItems="center" justifyContent="center" flexGrow="1">
                                        <BatteryLoader type="standard" />
                                    </Box>
                                </Box>
                            }

                            {!isLoading() && !hasError() &&
                                <Box display="flex" flexDirection="column" justifyContent="flex-start" style={{ minHeight: 'calc(100vh - 64px - 24px - 24px)' }}>
                                    <Box px={3} pt={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                        <Typography variant="h3" className={classes.title}>
                                            Supplier Management
                                        </Typography>
                                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                            <Button
                                                component={Link} to={'/addsupplier'}
                                                variant="contained"
                                                color="primary"
                                            >
                                                <div style={{ display: 'flex', marginRight: '8px' }}>
                                                    <Icon iconName={"AddIcon"} fill={'#FFF'} />
                                                </div>
                                                Add Supplier
                                            </Button>
                                        </Box>
                                    </Box>

                                    <Box mt={2} style={{ backgroundColor: grey200, height: '1px' }}></Box>

                                    <Box display="flex" alignItems="center" className={classes.header}>
                                        <Box className={classes.header1}><Box pl={3}>Supplier Name</Box></Box>
                                        <Box className={classes.header2}></Box>
                                        <Box className={classes.header3}>Technical Rating</Box>
                                        <Box className={classes.header4}>Supplier Portfolio</Box>
                                        <Box className={classes.header5}>Headquarter</Box>
                                        <Box className={classes.header6}>Main Contact VW Group</Box>
                                        <Box className={classes.header7}><Box pr={4}></Box></Box>
                                    </Box>
                                    <Box mb={4}>
                                        {cellSuppliers?.cellSuppliers.map((i, j) => {
                                            return <SupplierManagementListElement i={i} className={j % 2 === 0 ? classes.odd : classes.even} key={i.cellSupplierId} mutateFunction={updateCellSupplier} />
                                        })}
                                    </Box>
                                </Box>
                            }
                        </Paper>
                    </Grid>
                </Grid>
                <ScrollUpIcon />
            </div>
        </>
    )
}

export default SupplierManagementListTable;