import React, {useContext} from "react";
import {Alert, Box, LinearProgress, Snackbar} from "@mui/material";
import {SnackbarContext, SnackbarType} from "../../contexts/SnackBarContext";
import {UploadProgressContext} from "../../contexts/UploadContext";

export const InfoSnackbar = () => {
    const {isSnackbarOpen, setIsSnackbarOpen, snackbarType, message} = useContext(SnackbarContext);

    const onCloseSnackbar = (event, reason) => {
        if (reason !== 'clickaway') {
            setIsSnackbarOpen(false);
        }
    };

    return (
        <Snackbar variant="topbar" key={'topcenter'} open={isSnackbarOpen} 
                  autoHideDuration={snackbarType === SnackbarType.SUCCESS ? 2000 : null} onClose={onCloseSnackbar}
                  sx={{ position: 'sticky', top: '0', zIndex: '1', whiteSpace: 'pre-line' }}
                  >
            <div>
                <Alert onClose={onCloseSnackbar} severity={snackbarType} variant="filled">
                    {message}
                </Alert>
            </div>
        </Snackbar>
    );
}

export function UploadSnackBar() {
    const {uploadProgress} = UploadProgressContext();

    const calculateProgress = () => {
        return 100 / (Object.keys(uploadProgress).length * 100) * Object.values(uploadProgress).reduce((accumulator, value) => {
            return accumulator + value;
        }, 0);
    };

    return (
        <Snackbar
            variant="bottomleft"
            severity="info"
            key={'bottomleft'}
            open={Object.keys(uploadProgress).length !== 0}
        >
            <Alert severity="info">
                <Box>
                    <Box mb={2} width={'250px'}>Uploading {Object.keys(uploadProgress).length} file(s) </Box>
                    <LinearProgress variant="determinate" value={calculateProgress()}/>
                    <Box mt={1}>{calculateProgress().toFixed(2)} %</Box>
                </Box>
            </Alert>
        </Snackbar>
    )
}