import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import { useStyles } from '../Tooltip.styles';
import { HtmlTooltip } from '../HtmlTooltip/HtmlTooltip';
import { grey600, signalRed800 } from '../../helper/MuiStyles';
import { CDSContext } from '../../contexts/CDSContext';

export default function SimulatedButton({ soc, tempIndex, cdsIndex, cellIndex, cdsValues, setCdsValues, cdsMutationValues, setCdsMutationValues }) {

    const { currentPowerOCV } = useContext(CDSContext);
    const { currentisCharged } = useContext(CDSContext);
    const { currentPowerUnit } = useContext(CDSContext);

    const crossSimulatedNormal = `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path fill='%237A8084' d='M1 0 L0 1 L99 100 L100 99'/></svg>")`;
    const crossSimulatedError = `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path fill='%23DA0C1F' d='M1 0 L0 1 L99 100 L100 99'/></svg>")`;

    const handleSimulatedClick = () => {
        let matrix = JSON.parse(JSON.stringify(cdsValues));
        if (currentPowerOCV === 'OCV' && currentisCharged) {
            matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[cellIndex].isSimulated = !(matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[cellIndex].isSimulated);
            insertMatrixValue(soc, matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[cellIndex].isSimulated);
        }
        if (currentPowerOCV === 'OCV' && !currentisCharged) {
            matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[((matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs.length / 2) + cellIndex)].isSimulated = !(matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[((matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs.length / 2) + cellIndex)].isSimulated);
            insertMatrixValue(soc, matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[((matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs.length / 2) + cellIndex)].isSimulated);
        }
        if (currentPowerOCV === 'POWER_LIMITS' && currentisCharged && currentPowerUnit === 'MILLI_OHM') {
            matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[(6 * cellIndex)].isSimulated = !(matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[(6 * cellIndex)].isSimulated);
            insertMatrixValue(soc, matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[(6 * cellIndex)].isSimulated);
        }
        if (currentPowerOCV === 'POWER_LIMITS' && !currentisCharged && currentPowerUnit === 'MILLI_OHM') {
            matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[(6 * cellIndex) + 1].isSimulated = !(matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[(6 * cellIndex) + 3].isSimulated);
            insertMatrixValue(soc, matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[(6 * cellIndex) + 3].isSimulated);
        }
        if (currentPowerOCV === 'POWER_LIMITS' && currentisCharged && currentPowerUnit === 'WATT') {
            matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[(6 * cellIndex) + 2].isSimulated = !(matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[(6 * cellIndex) + 1].isSimulated);
            insertMatrixValue(soc, matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[(6 * cellIndex) + 1].isSimulated);
        }
        if (currentPowerOCV === 'POWER_LIMITS' && !currentisCharged && currentPowerUnit === 'WATT') {
            matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[(6 * cellIndex) + 3].isSimulated = !(matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[(6 * cellIndex) + 4].isSimulated);
            insertMatrixValue(soc, matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[(6 * cellIndex) + 4].isSimulated);
        }
        if (currentPowerOCV === 'POWER_LIMITS' && currentisCharged && currentPowerUnit === 'AMPERE') {
            matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[(6 * cellIndex) + 4].isSimulated = !(matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[(6 * cellIndex) + 2].isSimulated);
            insertMatrixValue(soc, matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[(6 * cellIndex) + 2].isSimulated);
        }
        if (currentPowerOCV === 'POWER_LIMITS' && !currentisCharged && currentPowerUnit === 'AMPERE') {
            matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[(6 * cellIndex) + 5].isSimulated = !(matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[(6 * cellIndex) + 5].isSimulated);
            insertMatrixValue(soc, matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[(6 * cellIndex) + 5].isSimulated);
        }
        setCdsValues(matrix);
    };

    const insertMatrixValue = (soc, isSimulated) => {
        // only apply changes if value is of type Float
        if (!checkFloat(soc.value)) {
            // copy OCV or POWER variables
            let updateCellDataInput = JSON.parse(JSON.stringify(cdsMutationValues));

            // check whether cellDataId is manipulated first time or has already been changed in this session 
            let indexOfId = updateCellDataInput.findIndex(cellDataInput => cellDataInput.cellDataId === soc.cellDataId);
            if (indexOfId >= 0) {
                updateCellDataInput[indexOfId].isSimulated = isSimulated;
            } else {
                updateCellDataInput.push({ cellDataId: soc.cellDataId, isSimulated: isSimulated, value: soc.value });
            }
            // apply changes
            setCdsMutationValues(updateCellDataInput);
        }
    };

    const checkFloat = (value) => {
        let check = false;
        const num1 = /^[-+]?\d+\.\d+$/;
        const num2 = /^-?\d+$/;
        if (num1.test(value) || num2.test(value) || value === "" || value === null) {
            check = false;
        } else {
            check = true;
        }
        return check;
    };

    const classes = useStyles();

    return (
        <HtmlTooltip data-testid="tooltip" width="300px"
            title={
                <Box display="flex" p={1}>
                    <Box className={classes.title}>{soc.isSimulated ? 'By clicking you change this input into a measured value.' : 'By clicking you change this input into a simulated value.'}</Box>
                </Box>
            }
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                className={soc.isSimulated ? '' : classes.simulated_button_inactive}
                style={soc.isSimulated ? { cursor: 'pointer', border: '1px solid', borderColor: soc.hasError ? signalRed800 : grey600, color: soc.hasError ? signalRed800 : grey600, width: '14px', height: '14px', fontWeight: '400' } : { cursor: 'pointer', background: soc.hasError ? crossSimulatedError : crossSimulatedNormal, border: '1px solid', borderColor: soc.hasError ? signalRed800 : grey600, width: '14px', height: '14px', fontWeight: '400', color: soc.hasError ? signalRed800 : grey600 }}
                onClick={() => handleSimulatedClick()}
            >
                S
            </Box>
        </HtmlTooltip>
    )
}