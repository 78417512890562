import React, {useContext} from 'react';
import Dut from './Dut';
import { useNavigate } from "react-router-dom";
import { Box, IconButton, TableRow, TableCell } from '@mui/material';
import { useStyles } from './Table.styles';
import DutOverview from '../../other/tooltips/DutOverview/DutOverview';
import Icon from '../../../assets/icons/Icon.js';
import DeleteTest from '../../other/dialogs/DeleteTest/DeleteTest';
import AddDut from '../../other/dialogs/AddDut/AddDut';
import { DutContext } from './dutContext';
import { TableElements } from './tableFunctions';
import { grey1000, petrol800 } from '../../other/helper/MuiStyles';
import TestCaseInfo from '../../other/tooltips/TestCaseInfo/TestCaseInfo';
import { useMutation } from '@apollo/client';
import { CREATEDUT } from '../../../operations/mutations/mutations';
import { TESTCASES } from '../../../operations/queries/query';
import { currentIndexId } from '../../../operations/localeStorage';
import {
    SnackbarContext, SnackbarType
} from '../../other/contexts/SnackBarContext';
import CommentTooltip from './CommentTooltip';

function TestCase(props) {
    const navigate = useNavigate();
    const {
        isLocked,
        activeTestCases,
        projectId,
        categoryId,
        test,
        edit,
        agingStatus,
        testInstances,
        testStatuses,
        testResults,
        sizeWindow
    } = props;
    const { setIsSnackbarOpen, setMessage, setSnackbarType } = useContext(SnackbarContext);

    const styleProps = {
        fontSizeTable: (sizeWindow < 5) ? '14px' : '16px',
        inputFieldPaddingLeft: (sizeWindow < 5) ? '8px !important' : '',
        statusFieldPaddingRight: (sizeWindow < 5) ? '30px !important' : '',
        datePickerPadding: (sizeWindow < 5) ? '0px 0px 0px 8px !important' : '0px 0px 0px 16px !important',
    };

    const handleOnCompleted = async () => {
        setSnackbarType(SnackbarType.SUCCESS);
        setIsSnackbarOpen(true);
    }

    const handleOnError = async (error) => {
        setMessage(error.message);
        setSnackbarType(SnackbarType.ERROR);
        setIsSnackbarOpen(true);
    }

    const [createDut] = useMutation(CREATEDUT, {
        onCompleted: handleOnCompleted,
        onError: handleOnError,
        refetchQueries: [{ query: TESTCASES(currentIndexId()) }],
        awaitRefetchQueries: true
    });

    const classes = useStyles(styleProps);

    let testCaseIds = activeTestCases.map(i => { return i.testCaseId })
    let testCaseNames = activeTestCases.map(i => { return i.testSpecification.name })

    const { state, testId } = React.useContext(DutContext);

    const handleAddDut = (e) => {
        e.preventDefault();
        createDut({ variables: { testCaseId: test.testCaseId } });
        setMessage('DUT has been created');
    }

    const RowClick = () => {
        navigate(
            {
                pathname: `/testcasedetails/${projectId}/${test.testCaseId}`
            },
            { state: { testCaseIds, testCaseNames, categoryId, isLocked } }
        );
    };

    const handleDutOpener = (value) => {
        if (testId[0] === 0) {
            testId[1](value);
            state[1](!state[0]);
        }
        if (testId[0] === value) {
            if (state[0] === false) {
                state[1](true);
            } else {
                state[1](!state[0]);
            }
        } else {
            testId[1](value)
            state[1](true);
        }
    }

    return <>
        <TableRow key={test.testCaseId} id="test-case-line-item">

            {/* ARROW ICON - DUT OPENER */}
            <TableCell>
                <IconButton onClick={() => { handleDutOpener(test.testCaseId) }} className={classes.hover_blue} style={{ maxWidth: sizeWindow < 5 ? '28px' : '32px', marginLeft: sizeWindow < 5 ? '4px' : '8px' }} data-testid={"dut-dropdown-button"}>
                    <Icon
                        fill={petrol800}
                        height={sizeWindow < 5 ? 20 : 24}
                        width={sizeWindow < 5 ? 20 : 24}
                        iconName={state[0] && testId[0] === test.testCaseId ? "ArrowUpIcon" : "ArrowDownIcon"}
                    />
                </IconButton>
            </TableCell>

            {/* SPEC NAME + VERSION */}
            <TableCell
                sx={{cursor: 'pointer'}}
                onClick={edit ? null : () => RowClick()}
            >
                <Box display="flex" alignItems="center">
                    <TestCaseInfo 
                        testSpecification={test.testSpecification.name} 
                        testSpecificationVersion={test.testSpecification.version} 
                        reference={test?.testSpecification?.groupStandardReference?.name} 
                        sizeWindow={sizeWindow} 
                    />
                    <Box className={classes.version} mr={2}>V. {test.testSpecification.version}</Box>
                </Box>
            </TableCell>

            {/* CONDITION */}
            <TableCell
                onClick={edit ? null : () => RowClick()}
            >
                {test.condition === null ? '-' : test.condition}
            </TableCell>

            {/* DUTs */}
            <TableCell>
                {edit &&
                    <Box>
                        <AddDut
                            handleAddDut={handleAddDut}
                            testName={test.testSpecification.name}
                            testVersion={test.testSpecification.version}
                            sizeWindow={sizeWindow}
                        />
                    </Box>
                }{!edit &&
                    <DutOverview duts={test.dutInfo} />
                }
            </TableCell>

            {/* AGING STATUS, TEST INSTANCE, TEST STATUS, START DATE, END DATE, TEST RESULT */}
            <TableElements test={test} classes={classes} edit={edit} testInstances={testInstances} testStatuses={testStatuses} testResults={testResults} agingStatus={agingStatus} sizeWindow={sizeWindow} />

            {/* COMMENT AMMOUNT + DELETE BUTTON*/}
            <TableCell>
                <Box>
                    {edit &&
                        <Box display="flex" flexDirection="row" justifyContent="flex-end" style={{ marginRight: '16px' }}>
                            <DeleteTest
                                test={test}
                                sizeWindow={sizeWindow}
                            />
                        </Box>
                    }{!edit &&
                        <CommentTooltip text={test.comments.slice().sort((a,b) => b.commentId.localeCompare(a.commentId))[0]?.comment}>
                            <Box
                                onClick={edit ? null : () => RowClick()}
                                className={classes.comment}
                            >
                                {String(test.commentCount).padStart(2, '0')}
                                <div style={{ display: 'flex', marginLeft: '8px' }}>
                                    <Icon height={20} width={20} fill={grey1000} iconName={"ChatIcon"} />
                                </div>
                            </Box>
                        </CommentTooltip>
                    }
                </Box>
            </TableCell>
        </TableRow>
        {/* {showDuts && */}
        {test.duts.map((dut, dutIndex) => {
            return (
                <Dut
                    key={dut.dutId}
                    dut={dut}
                    dutIndex={dutIndex}
                    test={test}
                    edit={edit}
                    testStatuses={testStatuses}
                    testResults={testResults}
                    sizeWindow={sizeWindow}
                />
            )
        })}
    </>;
}

export default TestCase;