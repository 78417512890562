import React, { useState } from 'react'

const CDSContext = React.createContext(null);

const CDSProvider = ({ children }) => {
  const [currentPowerOCV, setCurrentPowerOCV] = useState('POWER_LIMITS');
  const [currentisCharged, setCurrentisCharged] = useState(true);
  const [currentPowerUnit, setCurrentPowerUnit] = useState('MILLI_OHM');

  return (
    <CDSContext.Provider value={{
      currentPowerOCV, setCurrentPowerOCV,
      currentisCharged, setCurrentisCharged,
      currentPowerUnit, setCurrentPowerUnit
    }}
    >
      {children}
    </CDSContext.Provider>
  );
};

export { CDSContext, CDSProvider }
