import makeStyles from '@mui/styles/makeStyles';
import styled from 'styled-components';

import { NavLink } from "react-router-dom";
import { grey100, grey600, buttonHoverPetrol800, petrol800, grey800, petrol900 } from '../../other/helper/MuiStyles';

export const StyledNavLink = styled(NavLink)`
  && { 
  text-decoration: none;

  &:focus, &:hover, &:visited, &:link, &:active {
      text-decoration: none;
      color: black
  }

  }
`;

export const useStyles = makeStyles(() => ({
  root: {
    padding: '24px',
    backgroundColor: grey100,
  },
  add_button: {
    marginLeft: '16px',
  },
  paper: {
    margin: "auto",
    minWidth: "936px",
    maxWidth: "936px",
    ['@media (min-width: 984px)']: { // eslint-disable-line no-useless-computed-key
      minWidth: "936px",
      maxWidth: "936px",
    },
    ['@media (min-width: 1280px)']: { // eslint-disable-line no-useless-computed-key
      minWidth: "1232px",
      maxWidth: "1232px",
    },
    ['@media (min-width: 1576px)']: { // eslint-disable-line no-useless-computed-key
      minWidth: "1528px",
      maxWidth: "1528px",
    },
    ['@media (min-width: 1872px)']: { // eslint-disable-line no-useless-computed-key
      minWidth: "1824px",
      maxWidth: "1824px",
    },
    ['@media (min-width: 2168px)']: { // eslint-disable-line no-useless-computed-key
      minWidth: "2120px",
      maxWidth: "2120px",
    },
    ['@media (min-width: 2464px)']: { // eslint-disable-line no-useless-computed-key
      minWidth: "2416px",
      maxWidth: "2416px",
    },
    ['@media (min-width: 2760px)']: { // eslint-disable-line no-useless-computed-key
      minWidth: "2712px",
      maxWidth: "2712px",
    },
    backgroundColor: '#FFF',
  },
  test_box: {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: '2px',
    textAlign: 'left',
    minWidth: '280px',
    maxWidth: '280px',
    textDecoration: 'none',
    cursor: 'pointer'
  },
  headline: {
    marginBottom: '0px !important',
  },
  error_text: {
    marginTop: '8px',
    fontWeight: '400',
  },
  error_type: {
    padding: '0px 24px',
    textAlign: 'center',
    marginTop: '4px',
    fontWeight: '400',
    color: grey600,
  },
  active_filter_button: {
    marginRight: '16px',
    marginLeft: '0',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.6px',
    color: petrol800,
    border: `1px solid ${petrol800} !important`,
    boxSizing: "border-box",
    borderRadius: "8px !important",
    boxShadow: 'none !important',
    textTransform: 'none',
    minWidth: '0px',
    padding: '8px 16px',
  },
  active_filter_button_selected: {
    backgroundColor: `${petrol800} !important`,
    borderColor: `${petrol800} !important`,
    color: '#FFF !important',
    '&:hover': {
      backgroundColor: `${petrol900} !important`,
    },
  },
  search: {
    width: '216px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  search_icon: {
    padding: '0 16px',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  search_field: {
    "& #search-test-specification": {
      width: '184px',
      paddingLeft: '32px !important',
    },
  },
  no_result: {
    color: grey800,
    fontWeight: "400",
    lineHeight: "28px",
    letterSpacing: "0.3px",
  },
  active_edit_button: {
    backgroundColor: '#FFF',
    '&:hover': {
      backgroundColor: buttonHoverPetrol800,
    },
  },
  active_edit_button_selected: {
    backgroundColor: petrol800,
  },
}));