import makeStyles from '@mui/styles/makeStyles';
import { grey100, grey200, grey600 } from '../../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    root: {
        padding: '24px',
        backgroundColor: grey100,
    },
    paper: {        
        margin: "auto",
        minWidth: "647px",
        maxWidth: "647px",
        minHeight: 'calc(100vh - 64px - 24px - 24px)',
        textAlign: 'center',
        backgroundColor: '#FFF',
    },
    box: {
        width: '300px'
    },
    title: {
        lineHeight: '24px',
        letterSpacing: '0.6px',
    },
    avatar_image: {
        height: '80px',
        width: '80px',
    },
    edit_avatar_button: {
        marginTop: '16px',
    },
    line: {
        borderBottom: `1px solid ${grey200}`,
    },
    attributes: {
        fontWeight: '400',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        color: grey600,
        marginBottom: '32px',
        minWidth: '120px',
        textAlign: 'left',
    },
    values: {
        fontWeight: '400',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        marginBottom: '32px',
        marginLeft: '24px',
        marginRight: '24px',
        textAlign: 'left',
        verticalAlign: 'top',
    },
    error_text: {
        marginTop: '8px',
        fontWeight: '400',
    },
    error_type: {
        padding: '0px 24px',
        textAlign: 'center',
        marginTop: '4px',
        fontWeight: '400',
        color: grey600,
    },
}));