import React, {useState, useEffect, useContext} from 'react';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useReactiveVar, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { UPDATETESTCASE } from '../../operations/mutations/mutations';
import { currentIndexId, currentTestCaseId, projectRole } from '../../operations/localeStorage'
import { TESTCASE, ALLTESTINSTANCES, TESTSTATUSES, TESTRESULTS, TESTCASES, PROJECTDETAIL_HEADER, ALLAGINGSTATUS } from '../../operations/queries/query';
import { Grid, Paper, Box, Button, Tabs, Tab, IconButton, InputBase } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useStyles } from './TestCaseDetails.styles.js';
import Icon from '../../assets/icons/Icon.js';
import BatteryLoader from '../other/helper/BatteryLoader/BatteryLoader';
import StickyBar from '../stickybar/StickyBar';
import TestSpecificationDetails from '../testspecifications/TestSpecificationDetails/TestSpecificationDetails';
import CommentSection from './CommentSection';
import HeaderAttributes from './HeaderAttributes';
import { grey1000, grey600, signalRed800, petrol800, buttonPressedPetrol800 } from '../other/helper/MuiStyles';
import { IMAGE_MAP } from '../other/helper/ImgMapper/ImgMapper';
import DocumentListTable from '../other/documenttable/DocumentListTable/DocumentListTable';
import ParameterInfo from '../other/tooltips/ParameterInfo/ParameterInfo';
import {
  SnackbarContext, SnackbarType
} from '../other/contexts/SnackBarContext';

import LeaveConfirmation from '../other/dialogs/LeaveConfirmation/LeaveConfirmation';
import { useCallbackPrompt } from '../other/dialogs/LeaveConfirmation/hooks/useCallbackPrompt';
import { areTestCasesEqual } from './testCaseUtils';

function TestCaseDetails() {
  const navigate = useNavigate();
  const role = useReactiveVar(projectRole);
  const { setIsSnackbarOpen, setMessage, setSnackbarType } = useContext(SnackbarContext);

  let { testCaseId } = useParams();
  let location = useLocation();
  const projectId = location?.pathname?.split('/')?.[2];
  const isLocked = location?.state?.isLocked;

  let sizeWindow;

  switch (true) {
    case window.innerWidth < 1576:
      sizeWindow = 4;
      break;
    case window.innerWidth >= 1872:
      sizeWindow = 5;
      break;
    default:
      sizeWindow = 4;
      break;
  }

  const handleOnCompleted = async () => {
    setSnackbarType(SnackbarType.SUCCESS);
    setIsSnackbarOpen(true);
  }

  const handleOnError = async (error) => {
    setMessage(error.message);
    setSnackbarType(SnackbarType.ERROR);
    setIsSnackbarOpen(true);
  }

  const [getProjectDetails, { data: project, loading: projectLoading, error: projectError }] = useLazyQuery(PROJECTDETAIL_HEADER(projectId));
  const { data: testCaseData, loading: testCaseLoading, error: testCaseError } = useQuery(TESTCASE(testCaseId), { fetchPolicy: "network-only" });
  const { data: allAgingStatus, loading: allAgingStatusLoading, error: allAgingStatusError } = useQuery(ALLAGINGSTATUS);
  const { data: allTestInstances, loading: allTestInstancesLoading, error: allTestInstancesError } = useQuery(ALLTESTINSTANCES);
  const { data: testStatuses, loading: testStatusesLoading, error: testStatusesError } = useQuery(TESTSTATUSES);
  const { data: testResults, loading: testResultsLoading, error: testResultsError } = useQuery(TESTRESULTS);

  const [updateTestCase, { loading: updateTestCaseLoading }] = useMutation(UPDATETESTCASE, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: TESTCASE(testCaseId) }, { query: (currentIndexId() === undefined || currentIndexId() === -1) ? '' : TESTCASES(currentIndexId()) }], awaitRefetchQueries: true });

  const [mutationValues, setMutationValues] = useState({
    testCaseId: '',
    agingStatus: '',
    testResult: '',
    startDate: '',
    endDate: '',
    testInstance: '',
    testStatus: '',
    requirementIds: '',
  });

  const [conditionsAreVisible, setConditionsAreVisible] = useState(false);

  //PARAMETER VALUES
  const [haveConditionsChanged, setHaveConditionsChanged] = useState(false);
  const [selectedParams, setSelectedParams] = useState([]);
  const [selectedConditions, setSelectedConditions] = useState([]);
  //number of currently active condition columns (starting with 1!) 
  const [conditionCounter, setConditionCounter] = useState(() => {
    return 0
  });

  const [docList, setDocList] = useState({});
  const [checkRequirementIds, setCheckRequirementIds] = useState(false);

  const [showDialog, setShowDialog] = useState(false)
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog)

  const styleProps = {
    supplierImg: IMAGE_MAP.SUPPLIER[project?.project?.cellSupplier?.name],
    paddingLockBadge: (sizeWindow === 5) ? '0px 16px' : '0px 8px',
  };

  const classes = useStyles(styleProps);

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const insertParameters = (params) => {
    let filterPara = [];
    let filterCond = [];
    let conditionLength = params?.[0]?.conditions?.length;
    if (conditionLength === undefined) {
      conditionLength = 0;
    }

    params?.map(parameter => {
      return (
        filterPara.push({ assignParameterId: parameter.assignParameterId, parameter: parameter.parameter }),
        filterCond.push(parameter.conditions)
      )
    });

    // assign old status for differantiating between hide and delete functionality
    for (const element of filterCond) {
      for (let j = 0; j < element.length; j++) {
        element[j].status = 'old';
        element[j].number = j + 1;
      }
    }

    setSelectedParams(filterPara);
    setSelectedConditions(filterCond);
    setConditionCounter(conditionLength);
  };

  const handleAddConditionColumn = () => {
    setHaveConditionsChanged(true);
    let conditions = JSON.parse(JSON.stringify(selectedConditions));
    conditions.map(i => {
      return (
        i.push({ id: null, condition: "", isActive: true, status: 'new', number: conditionCounter + 1 })
      )
    });
    setSelectedConditions(conditions);
    setConditionCounter(conditionCounter + 1);
  };

  const handleConditionChange = (event, index, index2) => {
    setHaveConditionsChanged(true);
    const conditions = JSON.parse(JSON.stringify(selectedConditions));
    conditions[index][index2].condition = event.target.value;
    if (event.target.value.length < 256) {
      setSelectedConditions(conditions);
    }
  };

  const handleRemoveConditionColumn = (column) => {
    setHaveConditionsChanged(true);
    let conditions = [...selectedConditions];
    conditions.forEach(function (currentElement) {
      currentElement.splice(column, 1);
    });
    setSelectedConditions(conditions);
    setConditionCounter(conditionCounter - 1);
  };

  const handleHideConditionColumn = (column, hide) => {
    setHaveConditionsChanged(true);
    let conditions = [...selectedConditions];
    // assign old status for differantiating between hide and delete functionality
    for (let i = 0; i < conditions.length; i++) {
      conditions[i][column].isActive = hide;
    }
    setSelectedConditions(conditions);
  };

  const handleSave = () => {
    let para = JSON.parse(JSON.stringify(selectedParams));
    let cond = JSON.parse(JSON.stringify(selectedConditions));
    let cleanedMutationParameters = [];
    for (let i = 0; i < cond.length; i++) {
      cleanedMutationParameters.push({ assignParameterId: para[i].assignParameterId, parameterId: para[i].parameter.parameterId, conditions: [] });
      for (let j = 0; j < cond[i].length; j++) {
        cleanedMutationParameters[i].conditions.push({ id: cond[i][j].id, condition: cond[i][j].condition, isActive: cond[i][j].isActive });
      }
    }
    updateTestCase({ variables: { 
      testCaseId: mutationValues.testCaseId, 
      testResult: mutationValues.testResult, 
      startDate: mutationValues.startDate,
      plannedStartDate: mutationValues.plannedStartDate, 
      endDate: mutationValues.endDate, 
      plannedEndDate: mutationValues.plannedEndDate, 
      testInstance: mutationValues.testInstance, 
      testStatus: mutationValues.testStatus, 
      agingStatus: mutationValues.agingStatus, 
      requirementIds: mutationValues.requirementIds === '' ? null : mutationValues.requirementIds, 
      parameters: cleanedMutationParameters 
    } });
    setHaveConditionsChanged(false);
    setMessage('Test Case has been updated');
  };

  const isLoadingTab0 = () => {
    return (allAgingStatusLoading || testCaseLoading || allTestInstancesLoading || testStatusesLoading || testResultsLoading || updateTestCaseLoading || projectLoading)
  };

  const hasErrorTab0 = () => {
    return !(projectError === undefined && testCaseError === undefined && allTestInstancesError === undefined && allAgingStatusError === undefined && testStatusesError === undefined && testResultsError === undefined)
  };

  const isLoadingTab1 = () => {
    return (projectLoading || testCaseLoading)
  };

  const hasErrorTab1 = () => {
    return !(projectError === undefined && testCaseError === undefined)
  };

  const testCaseDataToValues = (data) => {
    return {
      testCaseId: data?.testCase?.testCaseId, 
      agingStatus: data?.testCase?.agingStatus?.agingStatusId, 
      testResult: data?.testCase?.testResult?.testResultId, 
      startDate: data?.testCase?.startDate, 
      plannedStartDate: data?.testCase?.plannedStartDate, 
      endDate: data?.testCase?.endDate, 
      plannedEndDate: data?.testCase?.plannedEndDate, 
      testInstance: data?.testCase?.testInstance?.testInstanceId, 
      testStatus: data?.testCase?.testStatus?.testStatusId, 
      requirementIds: data?.testCase?.requirementIds 
    }
  }

  useEffect(() => {
    if (testCaseData !== undefined) {
      currentTestCaseId(testCaseData?.testCase?.testCaseId)
      setMutationValues({ 
        ...mutationValues, 
        ...testCaseDataToValues(testCaseData)
      });
      let allDocs = [];
      if (testCaseData?.testCase?.documents != undefined) {
        for (let i of testCaseData.testCase.documents) {
          allDocs.push(i);
        }
        setDocList({ documents: allDocs });
      }else {
        setDocList({ documents: [] });
      }
      insertParameters(testCaseData?.testCase?.parameters?.parameters);
    }
  }, [testCaseData]);

  useEffect(() => {
    const initTestCaseValues = testCaseDataToValues(testCaseData)
    
    const hasChanged = haveConditionsChanged || !areTestCasesEqual(initTestCaseValues, mutationValues)
    setShowDialog(hasChanged)
  }, [mutationValues, haveConditionsChanged])

  useEffect(() => {
    if (projectId !== undefined) {
      getProjectDetails()
    }
  }, [projectId]);

  //validation for requirementIds
  useEffect(() => {
    // [0-9]{1,5}(; [0-9]{1,5})*
    const reg = /^[0-9]{1,5}(; [0-9]{1,5})*$/;
    if (reg.test(mutationValues.requirementIds) || mutationValues.requirementIds === "" || mutationValues.requirementIds === null) {
      setCheckRequirementIds(true);
    } else {
      setCheckRequirementIds(false);
    }
  }, [mutationValues?.requirementIds]);

  return (
    <>
      <LeaveConfirmation
        showDialog={showPrompt}
        onLeave={confirmNavigation}
        onStay={cancelNavigation}
      />
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} display="grid" pb={8}>
            <Paper className={classes.paper}>
              {/* ERROR */}
              {tabValue === 0 && hasErrorTab0() &&
                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc((100vh) - ((128px + 24px + 24px))' }}>
                  <Box mx={4} py={4} display="flex" alignItems="center">
                    <Typography variant="h2">Test Case Details</Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow="1">
                    <Icon iconName={"WarningIcon"} width={54} height={54} fill={signalRed800} />
                    <Typography variant="h3" className={classes.error_text}>
                      Something went wrong. The following errors have occurred:
                    </Typography>
                    {projectError !== undefined &&
                      <Typography variant="h5" className={classes.error_type}>
                        {projectError.message}
                      </Typography>
                    }
                    {testCaseError !== undefined &&
                      <Typography variant="h5" className={classes.error_type}>
                        {testCaseError.message}
                      </Typography>
                    }
                    {allTestInstancesError !== undefined &&
                      <Typography variant="h5" className={classes.error_type}>
                        {allTestInstancesError.message}
                      </Typography>
                    }
                    {allAgingStatusError !== undefined &&
                      <Typography variant="h5" className={classes.error_type}>
                        {allAgingStatusError.message}
                      </Typography>
                    }
                    {testStatusesError !== undefined &&
                      <Typography variant="h5" className={classes.error_type}>
                        {testStatusesError.message}
                      </Typography>
                    }
                    {testResultsError !== undefined &&
                      <Typography variant="h5" className={classes.error_type}>
                        {testResultsError.message}
                      </Typography>
                    }
                  </Box>
                  <Box my={4} display="flex" alignItems="center" justifyContent="center">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => navigate({ pathname: `/projectdetails/${projectId}` })}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              }

              {/* LOADING */}
              {tabValue === 0 && isLoadingTab0() && !hasErrorTab0() &&
                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc((100vh) - ((128px + 24px + 24px))' }}>
                  <Box mx={4} py={4} display="flex" alignItems="center" justifyContent="center" flexGrow="1">
                    <BatteryLoader type="standard" />
                  </Box>
                </Box>
              }

              {/* ERROR */}
              {tabValue === 1 && hasErrorTab1() &&
                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc((100vh) - ((128px + 24px + 24px))' }}>
                  <Box mx={4} py={4} display="flex" alignItems="center">
                    <Typography variant="h2">Test Specification</Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow="1">
                    <Icon iconName={"WarningIcon"} width={54} height={54} fill={signalRed800} />
                    <Typography variant="h3" className={classes.error_text}>
                      Something went wrong. The following errors have occurred:
                    </Typography>
                    {projectError !== undefined &&
                      <Typography variant="h5" className={classes.error_type}>
                        {projectError.message}
                      </Typography>
                    }
                    {testCaseError !== undefined &&
                      <Typography variant="h5" className={classes.error_type}>
                        {testCaseError.message}
                      </Typography>
                    }
                  </Box>
                  <Box my={4} display="flex" alignItems="center" justifyContent="center">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => navigate({ pathname: `/` })}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              }

              {/* LOADING */}
              {tabValue === 1 && isLoadingTab1() && !hasErrorTab1() &&
                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc((100vh) - ((128px + 24px + 24px))' }}>
                  <Box mx={4} py={4} display="flex" alignItems="center" justifyContent="center" flexGrow="1">
                    <BatteryLoader type="standard" />
                  </Box>
                </Box>
              }

              {!isLoadingTab0() && !hasErrorTab0() &&
                <Box display="flex" flexDirection="column" justifyContent="flex-start" style={{ minHeight: 'calc((100vh) - ((128px + 24px + 24px))' }}>

                  {/* HEADER */}
                  <Box mx={4} py={4} display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                    <Box display="flex" alignItems="center">
                      <Box display="flex" alignItems="baseline">
                        <Typography variant="h1">
                          {project?.project?.name}
                        </Typography>
                        <Typography variant="subtitle1" style={{ marginLeft: '8px' }}>
                          ID {project?.project?.projectId}
                        </Typography>
                      </Box>
                      {isLocked &&
                        <Box ml={3} className={classes.tag}>
                          <div style={{ display: 'flex', marginRight: (sizeWindow === 5) ? '8px' : '' }}>
                            <Icon iconName={"LockIcon"} width={24} height={24} fill={signalRed800} />
                          </div>
                          {(sizeWindow === 5) &&
                            'Locked'
                          }
                        </Box>
                      }
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Box display="flex" alignItems="center" justifyContent="center">
                        <div style={{ marginRight: '8px', display: 'flex', alignItems: "center" }}>
                          <Icon iconName={"CarIcon"} fill={grey600} />
                          <Box style={{ color: grey1000, letterSpacing: '0.8px', lineHeight: '24px' }} ml={1}>Vehicle Platform:</Box>
                        </div>
                        {project?.project?.platforms.map((platform, index) => {
                          return (
                            <Typography variant="h5" className={classes.info_values} key={`${platform.name}_${index}`}>
                              {(index ? ', ' : '') + platform.name}
                            </Typography>
                          )
                        })}
                      </Box>
                      <Box ml={5} display="flex" alignItems="center" justifyContent="center">
                        <div style={{ marginRight: '8px', display: 'flex' }}>
                          <Icon iconName={"BatteryIcon"} fill={grey600} />
                          <Box style={{ color: grey1000, letterSpacing: '0.8px', lineHeight: '24px' }} ml={1}>Cell Capacity:</Box>
                        </div>
                        <Typography variant="h5" className={classes.info_values}>
                          {project?.project?.nominalCapacity} Ah
                        </Typography>
                      </Box>
                      <Box ml={5} display="flex">
                        <div className={classes.supplierimage} title="SupplierLogo"></div>
                      </Box>
                    </Box>
                  </Box>

                  {/* TABS */}
                  <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth">
                    <Tab label="Test Case Details" />
                    <Tab label="Test Specification" />
                  </Tabs>
                  {tabValue === 0 && testCaseData && mutationValues.testCaseId &&
                    <>
                      <Box mx={4} py={4}>
                        <HeaderAttributes updateTestCase={updateTestCase} test={testCaseData?.testCase} testInstances={allTestInstances} testStatuses={testStatuses} testResults={testResults} agingStatus={allAgingStatus} mutationValues={mutationValues} setMutationValues={setMutationValues} isLocked={isLocked} checkRequirementIds={checkRequirementIds} />

                        <Box pt={4}>
                          <DocumentListTable access="test case" appendId={testCaseId} documents={docList} isLocked={isLocked} />
                        </Box>

                        {/* PARAMETERS & CONDITIONS */}
                        <Box pt={4} display="flex" alignItems="center" justifyContent="space-between">
                          <Typography variant="h3">
                            Parameters & Conditions
                          </Typography>
                          <Box display="flex">
                            {selectedParams?.length > 0 && (role === 'Administrator' || role === 'Project User') &&
                              <Box display="flex" alignItems="center">
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  onClick={() => { setConditionsAreVisible(!conditionsAreVisible) }}                                
                                  style={conditionsAreVisible ? {backgroundColor: buttonPressedPetrol800} : {}}
                                >
                                  <div style={{ display: 'flex', marginRight: '8px' }}>
                                    <Icon iconName={"EyeClosedIcon"} fill={petrol800} height={20} width={20} />
                                  </div>
                                  Hide Condition
                                </Button>
                                {conditionCounter < 12 &&
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleAddConditionColumn()}
                                    style={{marginLeft: '24px'}}
                                  >
                                    <div style={{ display: 'flex', marginRight: '8px' }}>
                                      <Icon iconName={"AddIcon"} fill={'#FFF'} />
                                    </div>
                                    Add Condition
                                  </Button>
                                }
                              </Box>
                            }
                          </Box>
                        </Box>

                        {/* VISIBLE CONDITIONS FILTER */}
                        {conditionsAreVisible &&
                          <Box my={2} display="flex">
                            {selectedConditions[0].map(function (i, index) {
                              return (
                                <>
                                  {i.status === 'old' &&
                                    <Box mr={2} key={index} display="flex" alignItems="center" className={i.isActive ? classes.condition_visible : classes.condition_invisible} onClick={i.isActive ? () => handleHideConditionColumn(index, false) : () => handleHideConditionColumn(index, true)}>
                                      Condition {i.number}
                                      <div style={{ display: 'flex', marginLeft: '16px' }}>
                                        <Icon iconName={i.isActive ? "EyeIcon" : "EyeClosedIcon"} fill={i.isActive ? '#FFF' : petrol800} height={20} width={20} />
                                      </div>
                                    </Box>
                                  }
                                </>
                              );
                            })
                            }
                          </Box>
                        }

                        <Box mt={2} display="flex" flexDirection="column" className={classes.parameter_box}>
                          <Box pt={3} px={3} display="flex" style={conditionCounter > 0 ? { marginBottom: '0px' } : { marginBottom: '4px' }}>
                            {selectedParams?.length < 1 &&
                              <Box pb={2.5} display="flex" flexDirection="column" alignItems="flex-start">
                                <Box display="flex" alignItems="center">
                                  <Typography variant="h5" className={classes.parameter_add_first}>
                                    No parameters were added to this test specification.
                                  </Typography>
                                </Box>
                              </Box>
                            }
                            {selectedParams?.length > 0 &&
                              <>
                                <Box display="flex" flexDirection="column">
                                  <Box className={classes.condition_title}>
                                    <Typography variant="h4" >
                                      {(testCaseData?.testCase?.parameters?.isPreset && !haveConditionsChanged) ? 'Parameter*' : 'Parameter'}
                                    </Typography>
                                  </Box>
                                  <Box mt={4} mb={1} display="flex" flexDirection="column" alignItems="flex-start">
                                    {selectedParams?.map(function (i, index) {
                                      return (
                                        <Box key={i.parameter?.name + index} display="flex" alignItems="center" className={classes.assigned_parameter}>
                                          <ParameterInfo parameter={i.parameter?.name} description={i.parameter?.description} active={i.parameter?.active} />
                                        </Box>
                                      );
                                    })}
                                  </Box>
                                </Box>
                                {conditionCounter > 0 &&
                                  <Box ml={3} overflow="auto">
                                    <Box display="flex">
                                      {selectedConditions[0].map(function (i, index) {
                                        return (
                                          <React.Fragment key={index}>
                                            {i.isActive &&
                                              <Box ml={3} key={index} style={{ width: '185px' }} display="flex" justifyContent="space-between" className={classes.condition_title}>
                                                <Typography variant="h4" key={index}>
                                                  Condition {i.status === 'old' ? (i.number) : (index + 1)}
                                                </Typography>
                                                {(role === 'Administrator' || role === 'Project User') &&
                                                  <IconButton
                                                    className={classes.trash_icon_wrapper}
                                                    onClick={i.status === 'old' ? () => handleHideConditionColumn(index, false) : () => handleRemoveConditionColumn(index)}
                                                    size="large">
                                                    <Icon iconName={i.status === 'old' ? "EyeClosedIcon" : "TrashIcon"} fill={petrol800} height={i.status === 'old' ? 20 : ''} width={i.status === 'old' ? 20 : ''} />
                                                  </IconButton>
                                                }
                                              </Box>
                                            }
                                          </React.Fragment>
                                        );
                                      })
                                      }
                                    </Box>
                                    <Box mt={4}>
                                      {selectedParams?.map(function (i, indexi) {
                                        return (
                                            <Box key={indexi} display="flex" alignItems="center" >
                                              {selectedConditions[indexi].map(function (j, indexj) {
                                                return (
                                                  <React.Fragment key={indexj}>
                                                    {selectedConditions[indexi][indexj].isActive &&
                                                      <Box ml={3} key={indexj} className={classes.condition_box}>
                                                        <InputBase
                                                          id={`${indexi},${indexj}`}
                                                          name="condition"
                                                          value={j.condition}
                                                          disabled={role === 'Auditor User'}
                                                          className={classes.condition_value}
                                                          onChange={event => handleConditionChange(event, indexi, indexj)}
                                                        >
                                                        </InputBase>
                                                        <Typography variant="h5" className={classes.condition_unit}>
                                                          {i.parameter?.unit?.abbreviation ? i.parameter?.unit?.abbreviation : '-'}
                                                        </Typography>
                                                      </Box>
                                                    }
                                                  </React.Fragment>
                                                )
                                              })}
                                            </Box>
                                        )
                                      })}
                                    </Box>
                                  </Box>
                                }
                              </>
                            }
                          </Box>
                          {testCaseData?.testCase?.parameters?.isPreset && selectedParams?.length > 0 && !haveConditionsChanged &&
                            <Typography variant="h5" className={classes.parameter_preset}>
                              * All parameters and conditions are preset.
                            </Typography>
                          }
                        </Box>


                        <Grid container spacing={3} mt={1}>
                          {/* Comment Section */}
                          <Grid item xs={8}>
                            <CommentSection testCaseData={testCaseData} testCaseId={testCaseId} isLocked={isLocked} />
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  }
                  {tabValue === 1 && testCaseData !== undefined &&
                    <TestSpecificationDetails testSpecId={testCaseData?.testCase?.testSpecification?.testSpecificationId} />
                  }
                </Box>
              }
            </Paper>
          </Grid>
        </Grid>

        <StickyBar
          backButton
          requiredField
          backpath={`/projectdetails/${projectId}/testcases#${location?.state?.categoryId}`}
          ksuNotification
          prevNext
          testCaseIds={location?.state?.testCaseIds}
          testCaseNames={location?.state?.testCaseNames}
          currentTestCaseId={testCaseId}
          projectId={projectId}
          customButton={'Save Test Case Details'}
          customOnClick={handleSave}
          // customDisabled={true}
          scrollButton
        />
      </div >
    </>
  )
}

export default TestCaseDetails;