const handleCategoryIcon = (category) => {
    switch (category) {
        case "Safety":
            return "SafetyIcon";
        case "Vwsafety":
            return "SafetyIcon";
        case "Electrical":
            return "ElectricalIcon";
        case "Vwelectrical":
            return "ElectricalIcon";
        case "Durability":
            return "DurabilityIcon";
        case "Vwdurability":
            return "DurabilityIcon";
        case "Mechanical":
            return "MechanicalIcon";
        case "Environmental":
            return "EnvironmentalIcon";
        case "Documentation":
            return "OtherIcon";
        default:
            return "OtherIcon";
    }
};

const handleClassificationIcon = (classification) => {
    switch (classification) {
        case "Gefahrgutunterlagen":
            return "WarningIcon";
        case "Geschäftsunterlagen":
            return "BookIcon";
        case "Reports":
            return "BookIcon";
        default:
            return "OtherIcon";
    }
};

  export { handleCategoryIcon, handleClassificationIcon }