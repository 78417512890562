import makeStyles from '@mui/styles/makeStyles';
import { grey100, grey200, grey1000, grey400, grey600, petrol800, petrol900, buttonHoverPetrol800, grey800 } from '../../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    devtable: {
        '& thead th': {
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            letterSpacing: '0.8px',
            color: grey800,
            paddingTop: '11px',
            paddingBottom: '11px',
        },
        '& th:first-child, td:first-child': {
            paddingLeft: 0,
            paddingRight: 0
        },
        '& th:nth-child(2), td:nth-child(2)': {
            width: '15%'
        },
        '& th:nth-child(6), td:nth-child(6)': {
            width: '10%'
        }
    },

    root: {
        padding: '24px',
        flexGrow: 1,
        backgroundColor: grey100,
    },
    paper: {
        minWidth: '1232px',
        marginTop: '24px',
        textAlign: 'center',
        backgroundColor: '#FFF',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.1)',
    },
    head: {
        borderBottom: `1px solid ${grey200}`,
    },
    toggle_button_group: {
        display: 'flex !important',
        flexWrap: 'wrap !important',
        borderRadius: 'none !important',
    },
    parameter_button: {
        marginRight: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: petrol800,
        border: `1px solid ${petrol800} !important`,
        boxSizing: "border-box",
        borderRadius: "8px !important",
        boxShadow: 'none !important',
        textTransform: 'none',
        minWidth: '0px',
        padding: '8px 16px',
    },
    parameter_button_selected: {
        backgroundColor: `${petrol800} !important`,
        borderColor: `${petrol800} !important`,
        color: '#FFF !important',
        '&:hover': {
            backgroundColor: `${petrol900} !important`,
        },
    },
    hover_blue: {
        '&:hover': {
            backgroundColor: buttonHoverPetrol800,
        },
    },
    title: {
        display: 'flex',
        lineHeight: '40px',
        letterSpacing: '0.6px',
    },
    filter_title: {
        display: 'flex',
        lineHeight: '40px',
        letterSpacing: '0.6px',
        color: grey800,
        fontWeight: '400',
        width: '120px',
    },
    seperator: {
        width: '1px',
        backgroundColor: grey200,
        height: '40px',
        marginLeft: '24px',
        marginRight: '24px',
    },
    edittestcase: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '18px',
        letterSpacing: '1.8px',
        textAlign: 'center',
        color: petrol800,
        marginRight: '32px',
    },
    edittestcaseactive: {
        backgroundColor: petrol800,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '18px',
        letterSpacing: '1.8px',
        textAlign: 'center',
        color: '#FFF',
        marginRight: '32px',
    },
    addtestcase: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '18px',
        letterSpacing: '1.8px',
        textAlign: 'center',
        color: petrol800,
        marginLeft: '8px',
        marginRight: '24px',
    },
    version: {
        marginLeft: '8px',
        color: petrol800,
        display: 'flex',
        alignItems: 'baseline',
        lineHeight: '16px',
        fontWeight: '700',
        fontSize: props => props.fontSizeTable,
        whiteSpace: 'nowrap'
    },
    box: {
        fontSize: props => props.fontSizeTable,
        "& #mui-component-select-agingStatus": {
            paddingLeft: props => props.inputFieldPaddingLeft,
        },
        "& #mui-component-select-testInstance": {
            paddingLeft: props => props.inputFieldPaddingLeft,
        },
        "& #mui-component-select-testStatusId": {
            paddingLeft: props => props.inputFieldPaddingLeft,
            paddingRight: props => props.statusFieldPaddingRight,
        },
        "& #mui-component-select-testResultId": {
            paddingLeft: props => props.inputFieldPaddingLeft,
            paddingRight: props => props.statusFieldPaddingRight,
        },
    },
    header: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.8px',
        textAlign: 'left',
        color: grey800,
        height: '30px',
        paddingTop: '8px',
        paddingBottom: '8px',
    },
    header0: {
        minWidth: '3.5%',
        textAlign: 'center',
    },
    header1: {
        minWidth: '15%',
        textAlign: 'left',
    },
    header1_text: {
        minWidth: '15%',
        maxWidth: '15%',
        textAlign: 'left',
        fontSize: props => props.fontSizeTable,
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
        display: 'flex',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    header2: {
        minWidth: '9%',
        textAlign: 'left',
    },
    header2_text: {
        minWidth: '9%',
        maxWidth: '9%',
        textAlign: 'left',
        fontSize: props => props.fontSizeTable,
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    header3: {
        minWidth: '6%',
        textAlign: 'left',
    },
    header3_text: {
        minWidth: '6%',
        maxWidth: '6%',
        textAlign: 'left',
        fontSize: props => props.fontSizeTable,
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    headercws: {
        minWidth: '6%',
        textAlign: 'left',
    },
    header4: {
        minWidth: '7%',
        textAlign: 'left',
    },
    header4_text: {
        minWidth: '7%',
        maxWidth: '7%',
        textAlign: 'left',
        fontSize: props => props.fontSizeTable,
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    body4: {
        minWidth: '4.28%',
        textAlign: 'center',
    },
    header5: {
        minWidth: '12%',
        textAlign: 'left',
    },
    header5_planning: {
        minWidth: '12%',
        textAlign: 'left',
    },
    header5_text: {
        minWidth: '12%',
        maxWidth: '12%',
        textAlign: 'left',
        fontSize: props => props.fontSizeTable,
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    header5_text_planning: {
        minWidth: '12%',
        maxWidth: '12%',
        textAlign: 'left',
        fontSize: props => props.fontSizeTable,
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    header6: {
        minWidth: '11%', // -1,5 wenn selectbox zurückkommt
        textAlign: 'left',
    },
    header6_planning: {
        minWidth: '10%',
        textAlign: 'left',
    },
    header6_text: {
        minWidth: '11%',
        maxWidth: '11%',
        textAlign: 'left',
        fontSize: props => props.fontSizeTable,
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    header6_text_planning: {
        minWidth: '10%',
        maxWidth: '10%',
        textAlign: 'left',
        fontSize: props => props.fontSizeTable,
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    header7: {
        minWidth: '9%',
        textAlign: 'left',
    },
    header7_text: {
        minWidth: '9%',
        maxWidth: '9%',
        textAlign: 'left',
        fontSize: props => props.fontSizeTable,
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    header9: {
        minWidth: '11%', // -1,5 wenn selectbox zurückkommt
        textAlign: 'left',
    },
    header9_text: {
        minWidth: '11%',
        maxWidth: '11%',
        textAlign: 'left',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
    },
    header10: {
        minWidth: '7.50%',
        textAlign: 'left',
    },
    header10_text: {
        minWidth: '7.50%',
        maxWidth: '7.50%',
        textAlign: 'left',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    collapseicon: {
        margin: '4px 0px 8px 0px',
        '&:hover': {
            backgroundColor: buttonHoverPetrol800,
        },
    },
    border: {
        border: `1px ${grey200}`,
        borderStyle: 'solid none none none',
        minHeight: '48px',
        cursor: 'pointer',
    },
    comment: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '28px',
        maxWidth: '55px',
        paddingLeft: '8px',
        paddingRight: '8px',
        border: `1px solid ${grey1000}`,
        borderRadius: '24px',
        color: grey1000,
        fontSize: props => props.fontSizeTable,
    },
    firstlast: {
        "& > :nth-child(1)": {
            borderTop: "0px",
        },
        "& > :nth-last-child(1)": {
            borderBottom: "0px",
        },
    },
    dut: {
        backgroundColor: grey100,
        '& td': {
            border: 'none'
        }
    },
    odd: {
        backgroundColor: '#FFF',
    },
    text: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        paddingRight: '8px',
    },
    disabled: {
        color: "rgba(0, 0, 0, 0.87)",
        border: 0,
        "&:before": {
            borderBottom: 0
        },
        padding: 0,
    },
    avatar_image: {
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '80px',
        width: '80px',
    },
    edit_avatar_button: {
        marginTop: '16px',
    },
    role: {
        fontWeight: '400',
        lineHeight: '18px',
        letterSpacing: '0.8px',
        color: grey400,
    },
    line: {
        borderBottom: `1px solid ${grey200}`,
    },
    attributes: {
        fontWeight: '400',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        color: grey600,
        marginBottom: '32px',
    },
    values: {
        fontWeight: '400',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        marginBottom: '32px',
    },
    logout_button: {
        marginBottom: '16px',
    },
    dropdown_list: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    zoomButton: {
        "&.Mui-disabled": {
            backgroundColor: 'initial',
            opacity: '0.2',
        }    
    }
}));