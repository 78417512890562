
import React from 'react';
import { Box } from '@mui/material';
import BatteryLoader from '../BatteryLoader/BatteryLoader';

function Health() {
    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh)' }}>
            <Box mx={2} display="flex" alignItems="center" justifyContent="center" flexGrow="1">
                <BatteryLoader type="standard" />
            </Box>
        </Box>
    )
}
export default Health;