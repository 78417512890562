import { Box, Button, Chip, Grid, IconButton, Input, InputAdornment, InputBase, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Icon from "../../../assets/icons/Icon";
import { StyledNavLink } from "../../homepage/Homepage/Homepage.styles";
import {
    SnackbarContext, SnackbarType
} from "../../other/contexts/SnackBarContext";
import DeleteProject from "../../other/dialogs/DeleteProject/DeleteProject";
import BatteryLoader from "../../other/helper/BatteryLoader/BatteryLoader";
import Counter from "../../other/helper/Counter/Counter";
import { IMAGE_MAP } from "../../other/helper/ImgMapper/ImgMapper";
import AddNewRole from "../AddNewRole/AddNewRole";
import { useStyles } from "./ProjectDetails.styles";
import { handleInputChange } from '../../other/helper/globalFunctions/globalFunctions.js';
import LockComponent from "../../other/dialogs/LockComponent/LockComponent";
import { YEARS, WEEKS } from "../../other/helper/utilsMapper/utilsMapper";
import { indexVar, sampleVar, subSampleVar, projectRole } from '../../../operations/localeStorage';
import { grey1000, grey200, grey600, grey800, petrol800, signalGreen800, signalRed800 } from "../../other/helper/MuiStyles";
import DocumentListTable from "../../other/documenttable/DocumentListTable/DocumentListTable";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATEPROJECT } from "../../../operations/mutations/mutations";
import { ALLLEADINGDEVELOPMENTS, ALLPLATFORMS, MYACCOUNT, PROJECTDETAIL } from "../../../operations/queries/query";

export default function ProjectInformation({
    formValues, setFormValues, mutationValues, setMutationValues, mutationRoles, id, project, documents, setFormSample, setSelectedIndex, projectError, testCasesError,
    projectLoading, assignedRoles, setAssignedRoles, setMutationRoles, editInformation, setEditInformation
}) {
    const { setIsSnackbarOpen, setMessage, setSnackbarType } = useContext(SnackbarContext);
    const role = projectRole();

    const { data: allPlatforms, loading: allPlatformsLoading, error: allPlatformsError } = useQuery(ALLPLATFORMS);
    const { data: allLeadingDevelopments, loading: allLeadingDevelopmentsLoading, error: allLeadingDevelopmentsError } = useQuery(ALLLEADINGDEVELOPMENTS);

    const handleEditInformationClick = () => {
        setEditInformation(!editInformation);
    };

    const handleUpdateProjectOnCompleted = async () => {
        setMessage('Project has been updated.');
        await handleOnCompleted();
    }

    const handleOnCompleted = async () => {
        setSnackbarType(SnackbarType.SUCCESS);
        setIsSnackbarOpen(true);
    }

    const handleOnError = async (error) => {
        setMessage(error.message);
        setSnackbarType(SnackbarType.ERROR);
        setIsSnackbarOpen(true);
    }

    const [updateProject, { loading: updateProjectLoading }] = useMutation(UPDATEPROJECT, {
        onError: handleOnError,
        onCompleted: handleUpdateProjectOnCompleted,
        refetchQueries: [{ query: PROJECTDETAIL(id) }, { query: MYACCOUNT }],
        awaitRefetchQueries: true
    });


    const handleSubmitFirstPaper = (e) => {
        e.preventDefault();
        updateProject({ variables: { projectId: mutationValues.projectId, platforms: mutationValues.platforms, cellIndex: mutationValues.cellIndex, nominalCapacity: mutationValues.nominalCapacity, description: mutationValues.description, users: mutationRoles, leadingDevelopment: mutationValues.leadingDevelopment, sopYear: mutationValues.sopYear, sopWeek: mutationValues.sopWeek, cellSpecification: mutationValues.cellSpecification, cellSpecificationLink: mutationValues.cellSpecificationLink } });
        setEditInformation(!editInformation);
    };

    const [checkNominalCapacity, setCheckNominalCapacity] = useState(false);

    const handleFinishEditing = () => {
        let buttonStatus = true;
        if (mutationValues?.platforms?.length > 0 && mutationValues.cellIndex?.length < 21 && mutationValues.nominalCapacity !== '' && checkNominalCapacity && (mutationValues.description?.length < 256 || mutationValues.description === null) && !mutationValues.isLocked) {
            buttonStatus = false;
        }
        return buttonStatus;
    };

    const handleInputChangeFreeText = () => (event) => {
        setMutationValues({ ...mutationValues, [event.target.name]: event.target.value });
    };

    const handleRemoveRole = (index) => {
        const values = [...assignedRoles];
        const valuesMutation = [...mutationRoles];
        values.splice(index, 1);
        valuesMutation.splice(index, 1);
        setAssignedRoles(values);
        setMutationRoles(valuesMutation);
    };

    const handlePlatformChange = () => (event, child) => {
        let mutationValue = child.props.datamutationvalue;
        setFormValues({ ...formValues, [event.target.name]: event.target.value });
        let check = false;
        for (let i = 0; i < mutationValues.platforms.length; i++) {
            if (mutationValue === mutationValues.platforms[i]) {
                check = true;
                mutationValues.platforms.splice(i, 1);
            }
        }
        if (!check) {
            let array = JSON.parse(JSON.stringify(mutationValues.platforms));
            array.push(mutationValue);
            setMutationValues({ ...mutationValues, [event.target.name]: array });
        }
    };

    const handleCancel = () => {
        let userValues = [];
        let userMutationValues = [];
        for (let i of project.project.users) {
            userValues.push({ userId: i.userId, avatarId: i.avatarId, firstName: i.firstName, lastName: i.lastName, role: i.projects[0].role.name })
            userMutationValues.push({ userId: i.userId, role: i.projects[0].role.roleId })
        }
        setAssignedRoles(userValues);
        setMutationRoles(userMutationValues);

        let platformFormValues = [];
        let platformMutationValues = [];
        for (let j of project.project.platforms) {
            platformFormValues.push(j.name)
            platformMutationValues.push(j.platformId)
        }
        setFormSample(project.project.samples);
        setFormValues({ ...formValues, platforms: platformFormValues, leadingDevelopment: project.project.leadingDevelopment.name, sopYear: project.project.sopYear, sopWeek: project.project.sopWeek });
        setSelectedIndex(project.project.samples[sampleVar()]?.subSamples[subSampleVar()].indices[indexVar()].name);
        let testResultValues = {};
        testResultValues['testsPassed'] = project.project.testResult.testsPassed;
        testResultValues['testsFailed'] = project.project.testResult.testsFailed;
        testResultValues['testsNotEvaluated'] = project.project.testResult.testsNotEvaluated;
        testResultValues['testsNotAvailable'] = project.project.testResult.testsNotAvailable;
        setMutationValues({ ...mutationValues, projectId: project.project.projectId, name: project.project.name, nominalCapacity: project.project.nominalCapacity, platforms: platformMutationValues, leadingDevelopment: project.project.leadingDevelopment.leadingDevelopmentId, sopYear: project.project.sopYear, sopWeek: project.project.sopWeek, cellSpecification: project.project.cellSpecification, cellSpecificationLink: project.project.cellSpecificationLink, cellSupplier: project.project.cellSupplier.name, description: project.project.description === null ? '' : project.project.description, cellIndex: project.project.cellIndex });
        setEditInformation(false);
    };


    const ErrorMessage = ({ prError, tError, plError, title }) => {
        return (<>
            <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc((100vh * (1/3)) - ((64px + 24px + 24px + 24px)*(1/3)))' }}>
                <Box mx={4} py={4} display="flex" flexDirection="row" >
                    <Typography variant="h1">
                        {title}
                    </Typography>
                </Box>
                <Box mx={4} py={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow="1">
                    <Icon iconName={"WarningIcon"} width={54} height={54} fill={signalRed800} />
                    <Typography variant="h3" className={classes.error_text}>
                        Something went wrong. The following errors have occurred:
                    </Typography>
                    {prError && projectError !== undefined &&
                        <Typography variant="h5" className={classes.error_type}>
                            {projectError.message}
                        </Typography>
                    }
                    {tError && testCasesError !== undefined &&
                        <Typography variant="h5" className={classes.error_type}>
                            {testCasesError.message}
                        </Typography>
                    }
                    {plError && allPlatformsError !== undefined &&
                        <Typography variant="h5" className={classes.error_type}>
                            {allPlatformsError.message}
                        </Typography>
                    }
                </Box>
                <Box my={4} display="flex" alignItems="center" justifyContent="center">
                    <StyledNavLink to="/">
                        <Button
                            variant="outlined"
                            color="primary">
                            Cancel
                        </Button>
                    </StyledNavLink>
                </Box>
            </Box>
        </>
        )
    }

    const failedTests = project?.project?.testResult?.testsFailed;
    const passedTests = project?.project?.testResult?.testsPassed;
    const notEvaluatedTests = project?.project?.testResult?.testsNotEvaluated;
    const notAvailableTests = project?.project?.testResult?.testsNotAvailable;
    const totalTestNumber = failedTests + passedTests + notEvaluatedTests + notAvailableTests;

    const styleProps = {
        widthTestsPassed: (passedTests / totalTestNumber) * 100 + '%',
        widthTestsFailed: (failedTests / totalTestNumber) * 100 + '%',
        widthTestsNotEvaluated: (notEvaluatedTests / totalTestNumber) * 100 + '%',
        userImg: IMAGE_MAP.USERS.placeholder,
    };

    const classes = useStyles(styleProps);

    //validation for nominalCapacity
    useEffect(() => {
        const num1 = /^[-+]?\d+\.\d+$/;
        const num2 = /^-?\d+$/;
        if (num1.test(mutationValues.nominalCapacity) || num2.test(mutationValues.nominalCapacity) || mutationValues.nominalCapacity === "") {
            setCheckNominalCapacity(true);
        } else {
            setCheckNominalCapacity(false);
        }
    }, [mutationValues?.nominalCapacity]);

    const isLoadingFirstPaper = () => {
        return (updateProjectLoading || projectLoading || allPlatformsLoading || allLeadingDevelopmentsLoading)
    };

    const hasErrorFirstPaper = () => {
        return !(projectError === undefined && allPlatformsError === undefined && allLeadingDevelopmentsError === undefined)
    };

    return (
        <Grid item xs={12} display="grid" >
            <>

                {/* ERROR */}
                {(projectError !== undefined || allPlatformsError !== undefined) &&
                    <ErrorMessage title={'Project Details'} prError plError />
                }

                {/* LOADING */}
                {isLoadingFirstPaper() && !hasErrorFirstPaper() &&
                    <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc((100vh * (1/3)) - ((64px + 24px + 24px + 24px)*(1/3)))' }}>
                        <Box mx={4} py={4} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                            <Typography variant="h2" className={classes.subheader}>
                                Project Information
                            </Typography>
                        </Box>
                        <Box m={4} display="flex" alignItems="center" justifyContent="center" flexGrow="1">
                            <BatteryLoader type="standard" />
                        </Box>
                    </Box>
                }

                {!isLoadingFirstPaper() && !hasErrorFirstPaper() && project !== undefined &&
                    <>
                        {/* TITLE AND BUTTONS */}
                        <Box mt={4} mx={4} display="flex" alignItems="center" justifyContent="space-between">
                            <Box data-testid={"information-header"}>
                                <Typography variant="h2" className={classes.subheader}>
                                    Project Information
                                </Typography>
                            </Box>
                            {/* EDIT */}
                            <Box display="flex" alignItems="center" justifyContent="center">
                                {!editInformation && role === 'Administrator' &&
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleEditInformationClick}
                                        data-testid={"edit-project-information-button"}
                                    >
                                        <div style={{ marginRight: '8px', display: 'flex' }}>
                                            <Icon iconName={'EditIcon'} fill={petrol800} />
                                        </div>
                                        Edit
                                    </Button>
                                }
                                {editInformation &&
                                    <>
                                        {/* DELETE */}
                                        <DeleteProject project={project?.project} />
                                        {/* LOCK */}
                                        <LockComponent headline={'Project'} lock={!mutationValues.isLocked} projectName={mutationValues.name} />
                                        {/* CANCEL */}
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleCancel}
                                            className={classes.pressed_button}
                                        >
                                            Cancel
                                        </Button>
                                        {/* SAVE */}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={handleFinishEditing()}
                                            onClick={handleSubmitFirstPaper}
                                            className={classes.pressed_button}
                                            data-testid={"save-project-information-button"}
                                        >
                                            <div style={{ display: 'flex', marginRight: '8px' }}>
                                                <Icon iconName={'EditIcon'} fill={'#FFF'} />
                                            </div>
                                            Save
                                        </Button>
                                    </>
                                }
                            </Box>
                        </Box>

                        {/* PLATFORM - CAPACITY - CELL INDEX */}
                        <Grid item xs={12} display="grid" id="first-project-information-section">
                            <Grid container spacing={0}>
                                {/* PLATFORM */}
                                <Grid item xs={4}>
                                    <Box ml={4} mr={0.67} mt={3}>
                                        {(!editInformation) || (mutationValues.isLocked) ?
                                            <>
                                                <Typography variant="caption">
                                                    Vehicle Platform
                                                </Typography>
                                                <Box display="flex" flexDirection="row" >
                                                    {formValues.platforms.map((platform, index) => {
                                                        return (
                                                            <Typography 
                                                                key={`${platform}_${index}`} 
                                                                variant="body1" 
                                                                title="Platform" 
                                                                className={classes.box_typo} 
                                                                style={index === formValues.platforms.length - 1 ? {} : { marginRight: '4px' }}
                                                            >
                                                                {platform + (index === formValues.platforms.length - 1 ? '' : ', ')}
                                                            </Typography>
                                                        )
                                                    })}
                                                </Box>

                                            </>
                                            :
                                            <>
                                                <InputLabel className={classes.input_title}>Vehicle Platform *</InputLabel>
                                                <Select
                                                    name='platforms'
                                                    data-testid='mui-component-select-platformNames'
                                                    multiple
                                                    className={classes.multiple_select_box}
                                                    value={formValues.platforms}
                                                    onChange={handlePlatformChange()}
                                                    style={mutationValues.platforms.length > 0 ? { paddingTop: '4px', paddingBottom: '0px' } : {}}
                                                    input={<Input />}
                                                    error={mutationValues?.platforms?.length < 1}
                                                    renderValue={(selected) => (
                                                        <div className={classes.platforms}>
                                                            {selected.map((value) => (
                                                                <Chip key={value} label={value} className={classes.platform} />
                                                            ))}
                                                        </div>
                                                    )}
                                                >
                                                    {allPlatforms?.platforms.map((option) => (
                                                        <MenuItem
                                                            datamutationvalue={option.platformId}
                                                            key={option.platformId}
                                                            value={option.name}
                                                            disabled={!option.active}
                                                        >
                                                            {option.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </>
                                        }
                                        {mutationValues?.platforms?.length < 1 &&
                                            <Box display="flex" justifyContent="space-between">
                                                <Typography variant="subtitle2" className={classes.alert_message}>At least one vehicle platform needs to be assigned.</Typography>
                                            </Box>
                                        }
                                    </Box>
                                </Grid>
                                {/* CAPACITY */}
                                <Grid item xs={4}>
                                    <Box mx={2.33} mt={3}>
                                        {(!editInformation) || (mutationValues.isLocked) ?
                                            <>
                                                <Typography variant="caption">
                                                    Nominal Capacity
                                                </Typography>
                                                <Typography mt={0.5} variant="body1">
                                                    {mutationValues.nominalCapacity} Ah
                                                </Typography>
                                            </>
                                            :
                                            <>
                                                <InputLabel className={classes.input_title}>Nominal Capacity *</InputLabel>
                                                <InputBase
                                                    name="nominalCapacity"
                                                    value={mutationValues.nominalCapacity}
                                                    onChange={handleInputChangeFreeText()}
                                                    endAdornment={<InputAdornment position="end">Ah</InputAdornment>}
                                                    error={!checkNominalCapacity}
                                                />
                                            </>
                                        }
                                        {!checkNominalCapacity &&
                                            <Box display="flex" justifyContent="space-between">
                                                <Typography variant="subtitle2" className={classes.alert_message}>Incorrect input format. Only numbers allowed.</Typography>
                                            </Box>
                                        }
                                    </Box>
                                </Grid>
                                {/* CELL INDEX */}
                                <Grid item xs={4}>
                                    <Box mr={4} ml={0.67} mt={3}>
                                        {(!editInformation) || (mutationValues.isLocked) ?
                                            <>
                                                <Typography variant="caption">
                                                    Cell Index
                                                </Typography>
                                                <Typography mt={0.5} variant="body1">
                                                    {mutationValues.cellIndex ? mutationValues.cellIndex : '-'}
                                                </Typography>
                                            </>
                                            :
                                            <>
                                                <InputLabel className={classes.input_title}>Cell Index</InputLabel>
                                                <InputBase
                                                    className={classes.box_freeText}
                                                    name="cellIndex"
                                                    value={mutationValues.cellIndex}
                                                    onChange={handleInputChangeFreeText()}
                                                    error={mutationValues?.cellIndex?.length > 20}
                                                />
                                            </>
                                        }
                                        {mutationValues?.cellIndex?.length > 20 &&
                                            <Box display="flex" justifyContent="space-between">
                                                <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 20 characters.</Typography>
                                                <Typography variant="subtitle2" className={classes.alert_message}>{mutationValues?.cellIndex?.length}/20</Typography>
                                            </Box>
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* LEADING DEVELOPMENT - SOP YEAR - SOP WEEK */}
                        <Grid item xs={12} display="grid">
                            <Grid container spacing={0}>
                                {/* LEADING DEVELOPMENT */}
                                <Grid item xs={4}>
                                    <Box ml={4} mr={0.67} mt={3}>
                                        {(!editInformation) || (mutationValues.isLocked) ?
                                            <>
                                                <Typography variant="caption">
                                                    Leading Development
                                                </Typography>
                                                <Typography mt={0.5} variant="body1">
                                                    {formValues.leadingDevelopment}
                                                </Typography>
                                            </>
                                            :
                                            <>
                                                <InputLabel className={classes.input_title}>Leading Development *</InputLabel>
                                                <Select
                                                    name="leadingDevelopment"
                                                    value={formValues.leadingDevelopment}
                                                    onChange={handleInputChange(setFormValues, formValues, setMutationValues, mutationValues)}
                                                >
                                                    {allLeadingDevelopments?.leadingDevelopments.map((option) => (
                                                        <MenuItem
                                                            datamutationvalue={option.leadingDevelopmentId}
                                                            key={option.leadingDevelopmentId}
                                                            value={option.name}
                                                        >
                                                            {option.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </>
                                        }
                                    </Box>
                                </Grid>
                                {/* SOP YEAR */}
                                <Grid item xs={4}>
                                    <Box mx={2.33} mt={3}>
                                        {(!editInformation) || (mutationValues.isLocked) ?
                                            <>
                                                <Typography variant="caption">
                                                    SOP Year
                                                </Typography>
                                                <Typography mt={0.5} variant="body1">
                                                    {mutationValues.sopYear}
                                                </Typography>
                                            </>
                                            :
                                            <>
                                                <InputLabel className={classes.input_title}>SOP Year *</InputLabel>
                                                <Select
                                                    name="sopYear"
                                                    value={mutationValues.sopYear}
                                                    onChange={handleInputChange(setFormValues, formValues, setMutationValues, mutationValues)}
                                                >
                                                    {YEARS.map((option) => (
                                                        <MenuItem
                                                            datamutationvalue={option.label}
                                                            key={option.value}
                                                            value={option.label}
                                                        >
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </>
                                        }
                                    </Box>
                                </Grid>
                                {/* SOP WEEK */}
                                <Grid item xs={4}>
                                    <Box mr={4} ml={0.67} mt={3}>
                                        {(!editInformation) || (mutationValues.isLocked) ?
                                            <>
                                                <Typography variant="caption">
                                                    SOP Week
                                                </Typography>
                                                <Typography mt={0.5} variant="body1">
                                                    CW {mutationValues.sopWeek}
                                                </Typography>
                                            </>
                                            :
                                            <>
                                                <InputLabel className={classes.input_title}>SOP Week *</InputLabel>
                                                <Select
                                                    name="sopWeek"
                                                    value={mutationValues.sopWeek}
                                                    onChange={handleInputChange(setFormValues, formValues, setMutationValues, mutationValues)}
                                                >
                                                    {WEEKS.map((option) => (
                                                        <MenuItem
                                                            datamutationvalue={option.label}
                                                            key={option.value}
                                                            value={option.label}
                                                        >
                                                            {"CW " + option.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </>
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* LAH */}
                        <Grid item xs={12} display="grid">
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Box mx={4} mt={3}>
                                        {(!editInformation) || (mutationValues.isLocked) ?
                                            <>
                                                <Typography variant="caption">
                                                    LAH
                                                </Typography>
                                                <Typography mt={0.5} variant="body1">
                                                    {mutationValues.cellSpecification ? mutationValues.cellSpecification : '-'}
                                                </Typography>
                                            </>
                                            :
                                            <>
                                                <InputLabel className={classes.input_title}>LAH</InputLabel>
                                                <InputBase
                                                    name="cellSpecification"
                                                    value={mutationValues.cellSpecification ? mutationValues.cellSpecification : ''}
                                                    multiline={true}
                                                    fullWidth={true}
                                                    onChange={handleInputChangeFreeText()}
                                                    error={mutationValues?.cellSpecification?.length > 150}
                                                />
                                            </>
                                        }
                                        {mutationValues?.cellSpecification?.length > 150 &&
                                            <Box display="flex" justifyContent="space-between">
                                                <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 150 characters.</Typography>
                                                <Typography variant="subtitle2" className={classes.alert_message}>{mutationValues?.cellSpecification?.length}/150</Typography>
                                            </Box>
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* LAH Link*/}
                        <Grid item xs={12} display="grid">
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Box mx={4} mt={3}>
                                        {(!editInformation) || (mutationValues.isLocked) ?
                                            <>
                                                <Typography variant="caption">
                                                    LAH Link
                                                </Typography>
                                                <Typography mt={0.5} variant="body1">
                                                    {mutationValues.cellSpecificationLink ? mutationValues.cellSpecificationLink : '-'}
                                                </Typography>
                                            </>
                                            :
                                            <>
                                                <InputLabel className={classes.input_title}>LAH Link</InputLabel>
                                                <InputBase
                                                    name="cellSpecificationLink"
                                                    value={mutationValues.cellSpecificationLink ? mutationValues.cellSpecificationLink : ''}
                                                    multiline={true}
                                                    fullWidth={true}
                                                    onChange={handleInputChangeFreeText()}
                                                    error={mutationValues?.cellSpecificationLink?.length > 150}
                                                />
                                            </>
                                        }
                                        {mutationValues?.cellSpecificationLink?.length > 150 &&
                                            <Box display="flex" justifyContent="space-between">
                                                <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 150 characters.</Typography>
                                                <Typography variant="subtitle2" className={classes.alert_message}>{mutationValues?.cellSpecificationLink?.length}/150</Typography>
                                            </Box>
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} display="grid">
                            <Grid container spacing={0}>
                                <Grid item xs={9}>
                                    <Box mt={4} ml={4} mb={1.5} mr={3} display="flex" alignItems="center" justifyContent="space-between">
                                        <Typography variant="h3" className={classes.subheader}>
                                            Test Results
                                        </Typography>
                                        <Typography variant="h5" className={classes.result_number} style={{ color: grey600 }}> {failedTests + passedTests + notEvaluatedTests} / {totalTestNumber} </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box mt={4} mr={4} mb={1.5} display="flex" alignItems="center">
                                        <Typography variant="h3" className={classes.subheader}>
                                            Contact Persons
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} display="grid">
                            <Grid container spacing={0}>
                                <Grid item xs={9}>
                                    {/* TEST RESULTS */}
                                    <Box ml={4} mr={3} display="flex" justifyContent="center">
                                        <Box className={classes.percent_container_div}>
                                            <Box className={classes.percent_bar_passed} style={failedTests === 0 && notEvaluatedTests === 0 && notAvailableTests === 0 ? { borderRadius: '8px' } : { borderRadius: '8px 0 0 8px' }}></Box>
                                            <Box className={classes.percent_bar_failed} style={passedTests === 0 && notEvaluatedTests === 0 && notEvaluatedTests === 0 ? { borderRadius: '8px' } : (passedTests === 0 ? { borderRadius: '8px 0 0 8px' } : ((notAvailableTests === 0 && notEvaluatedTests === 0) ? { borderRadius: '0 8px 8px 0' } : { borderRadius: '0' }))}></Box>
                                            <Box className={classes.percent_bar_notevaluated} style={failedTests === 0 && passedTests === 0 && notAvailableTests === 0 ? { borderRadius: '8px' } : (passedTests === 0 && failedTests === 0 && notAvailableTests !== 0 ? { borderRadius: '8px 0 0 8px' } : (notAvailableTests === 0 ? { borderRadius: '0 8px 8px 0' } : { borderRadius: '0' }))}></Box>
                                        </Box>
                                    </Box>
                                    <Box ml={4} mr={3} mt={1.5} display="flex" flexDirection="row">
                                        <Box display="flex" flexDirection="row" justifyContent="center">
                                            <Box display="flex" flexDirection="column" justifyContent="center">
                                                <Counter number={project.project.testResult.testsPassed} width="32px" bgcolor={signalGreen800} color="white" />
                                            </Box>
                                            <Box display="flex" flexDirection="column" justifyContent="center" p={1} className={classes.result_number}>
                                                Passed
                                            </Box>
                                        </Box>
                                        <Box ml={2} display="flex" flexDirection="row" justifyContent="center">
                                            <Box display="flex" flexDirection="column" justifyContent="center">
                                                <Counter number={project.project.testResult.testsFailed} width="32px" bgcolor={signalRed800} color="white"></Counter>
                                            </Box>
                                            <Box display="flex" flexDirection="column" justifyContent="center" p={1} className={classes.result_number}>
                                                Failed
                                            </Box>
                                        </Box>
                                        <Box ml={2} display="flex" flexDirection="row" justifyContent="center">
                                            <Box display="flex" flexDirection="column" justifyContent="center" className={classes.result_number}>
                                                <Counter number={project.project.testResult.testsNotEvaluated} width="32px" bgcolor={grey800} color="white"></Counter>
                                            </Box>
                                            <Box display="flex" flexDirection="column" justifyContent="center" p={1} className={classes.result_number}>
                                                Not Evaluated
                                            </Box>
                                        </Box>
                                        <Box ml={2} display="flex" flexDirection="row" >
                                            <Box display="flex" flexDirection="column" justifyContent="center" className={classes.result_number}>
                                                <Counter number={project.project.testResult.testsNotAvailable} width="32px" bgcolor={grey200} color={grey1000} margin="0"></Counter>
                                            </Box>
                                            <Box display="flex" flexDirection="column" justifyContent="center" p={1} className={classes.result_number}>
                                                Not Available
                                            </Box>
                                        </Box>
                                    </Box>
                                    {/* DESCRIPTION */}
                                    <Box ml={4} mr={3} mt={3} mb={4}>
                                        {(!editInformation) || (mutationValues.isLocked) ?
                                            <>
                                                <Typography variant="caption">
                                                    Project Description
                                                </Typography>
                                                <Typography mt={0.5} variant="body1">
                                                    {mutationValues.description ? mutationValues.description : '-'}
                                                </Typography>
                                            </>
                                            :
                                            <>
                                                <InputLabel className={classes.input_title}>Project Description</InputLabel>
                                                <InputBase
                                                    name="description"
                                                    className={classes.box_freeText2}
                                                    multiline={true}
                                                    fullWidth={true}
                                                    value={mutationValues.description ? mutationValues.description : ''}
                                                    onChange={handleInputChangeFreeText()}
                                                    error={mutationValues.description?.length > 255}
                                                />
                                            </>
                                        }
                                        {mutationValues.description?.length > 255 &&
                                            <Box display="flex" justifyContent="space-between">
                                                <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 255 characters.</Typography>
                                                <Typography variant="subtitle2" className={classes.alert_message}>{mutationValues.description?.length}/255</Typography>
                                            </Box>
                                        }
                                    </Box>
                                </Grid>

                                <Grid item xs={3}>
                                    <Box mr={4} mb={4} display="flex" flexDirection="column" className={classes.freeText3}>
                                        {assignedRoles?.map((user, index) => {
                                            return (
                                                <Box mb={2} display="flex" alignItems="center" key={index}>
                                                    <Box display="flex" justifyContent="center">
                                                        <div className={classes.userimage}>
                                                            <img src={IMAGE_MAP.AVATAR[user.avatarId]?.pngSrc} alt="avatar" />
                                                        </div>
                                                    </Box>
                                                    <Typography variant="h5" className={classes.users}>
                                                        {user.firstName} {user.lastName}
                                                    </Typography>
                                                    {editInformation && mutationRoles.length > 1 && (!mutationValues.isLocked) &&
                                                        <IconButton onClick={() => handleRemoveRole(index)} className={classes.hover_blue}>
                                                            <Icon iconName={"TrashIcon"} fill={petrol800} />
                                                        </IconButton>
                                                    }
                                                </Box>)
                                        })}
                                        {editInformation &&
                                            <Box mb={2} mt={1} display="flex">
                                                <AddNewRole project={project} assignedRoles={assignedRoles} setAssignedRoles={setAssignedRoles} mutationRoles={mutationRoles} setMutationRoles={setMutationRoles} isLocked={mutationValues.isLocked} />
                                            </Box>
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Box mx={4} pb={4}>
                            <DocumentListTable access="project" appendId={mutationValues.projectId} documents={documents} isLocked={mutationValues.isLocked} />
                        </Box>
                    </>
                }
            </>
        </Grid>
    )
}