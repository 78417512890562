import makeStyles from '@mui/styles/makeStyles';
import { buttonHoverPetrol800, grey100, grey1000, grey200, grey400, grey600, grey800 } from '../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    root: {
        padding: '24px',
        backgroundColor: grey100,
    },
    paper: {
        minWidth: "1232px",
        minHeight: 'calc(100vh - 64px - 24px - 24px)',
        textAlign: 'center',
        backgroundColor: '#FFF',
    },
    title: {
        lineHeight: '40px',
        letterSpacing: '0.6px',
    },
    hover_blue: {
        '&:hover': {
            backgroundColor: buttonHoverPetrol800,
          },
    },
    seperator: {
        width: '1px',
        backgroundColor: grey200,
        height: '40px',
        marginLeft: '24px',
        marginRight: '24px',
    },
    header: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.8px',
        textAlign: 'left',
        color: grey800,
    },
    header1: {
        minWidth: '29.33%',
        maxWidth: '29.33%',
        textAlign: 'left',
    },
    header2: {
        minWidth: '8.75%',
        textAlign: 'left',
    },
    header3: {
        minWidth: '10.75%',
        maxWidth: '10.75%',
        textAlign: 'left',
    },
    header4: {
        minWidth: '13.75%',
        maxWidth: '13.75%',
        textAlign: 'left',
    },
    header5: {
        minWidth: '28.05%', //29,59 mit checkbox
        maxWidth: '28.05%',
        textAlign: 'left',
    },
    header6: {
        minWidth: '9.37%',
        textAlign: 'left',
    },
    even: {
        backgroundColor: '#FFF',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        textAlign: 'left',
        color: grey1000,
    },
    odd: {
        backgroundColor: grey100,
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        textAlign: 'left',
        color: grey1000,
    },
    box: {
        textAlign: 'left',
        width: '160px',
    },
    box_name: {
        textAlign: 'left',
        marginBottom: '8px',
        marginTop: '8px',
        paddingLeft: '16px',
        width: '80px',
    },
    box_email: {
        textAlign: 'left',
        marginBottom: '8px',
        paddingLeft: '16px',
        width: '160px',
    },
    box_freeText: {
        textAlign: 'left',
        width: '160px',
    },
    text: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    disabled: {
        color: "black",
        border: 0,
        "&:before": {
            borderBottom: 0
        }
    },
    avatar_image: {
        backgroundImage: props => `url(${props.avatarImg})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '80px',
        width: '80px',
    },
    edit_avatar_button: {
        marginTop: '16px',
    },
    role: {
        fontWeight: '400',
        lineHeight: '18px',
        letterSpacing: '0.8px',
        color: grey400,
    },
    line: {
        borderBottom: `1px solid ${grey200}`,
    },
    attributes: {
        fontWeight: '400',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        color: grey600,
        marginBottom: '32px',
    },
    values: {
        fontWeight: '400',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        marginBottom: '32px',
    },
    logout_button: {
        marginTop: '24px',
    },
    error_text: {
        marginTop: '8px',
        fontWeight: '400',
    },
    error_type: {
        padding: '0px 24px',
        textAlign: 'center',
        marginTop: '4px',
        fontWeight: '400',
        color: grey600,
    },
}));