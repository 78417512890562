import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Icon from '../../../../assets/icons/Icon.js';
import { Box, Typography, IconButton, Dialog, DialogContent } from '@mui/material';
import { grey1000, grey200, grey600, grey800, petrol800 } from '../../helper/MuiStyles.js';

const useStyles = makeStyles(() => ({
    title: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.8px',
        textAlign: 'left',
        color: grey600,
    },
    value: {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        textAlign: 'left',
        color: grey1000,
    },
    role_column: {
        minWidth: '120px',
        maxWidth: '120px',
        borderLeft: `1px solid ${grey200}`,
        paddingLeft: '16px',
        paddingRight: '16px',
    },
    role_title: {
        color: grey600,
        textAlign: 'center',
        lineHeight: '40px',
    },
    right_text: {
        color: grey600,
        fontWeight: '400',
        textAlign: 'left',
        minWidth: '300px',
        maxWidth: '300px',
        minHeight: '40px',
        maxHeight: '40px',
    },
    right_icon: {
        minHeight: '40px',
        maxHeight: '40px',
    },
    dialog: {
        // minHeight: '100vh',
        height: 'auto'
    }
}
));

export default function ApplicationRoleInfo(props) {
    const { accessPoint } = props;
    const [show, setShow] = useState(false);

    const ToggleModal = () => {
        setShow(!show);
    };

    const permissions = [
        {task: "View My Projects", admin: true, p_user: true, r_user: true, n_user: false },
        {task: "View All Projects", admin: true, p_user: true, r_user: true, n_user: false },
        {task: "View Test Specifications", admin: true, p_user: true, r_user: true, n_user: false },
        {task: "Add Project", admin: true, p_user: false, r_user: false, n_user: false },
        {task: "View Project Details", admin: true, p_user: true, r_user: true, n_user: false },
        {task: "Edit Project Details", admin: true, p_user: false, r_user: false, n_user: false },
        {task: "Lock Project", admin: true, p_user: true, r_user: false, n_user: false },
        {task: "Delete Project", admin: true, p_user: false, r_user: false, n_user: false },
        {task: "Add Sample States", admin: true, p_user: true, r_user: false, n_user: false },
        {task: "Edit Sample States", admin: true, p_user: true, r_user: false, n_user: false },
        {task: "Delete Sample States", admin: true, p_user: false, r_user: false, n_user: false },
        {task: "View Test Case Details", admin: true, p_user: true, r_user: true, n_user: false },
        {task: "Add Test Cases", admin: true, p_user: true, r_user: false, n_user: false },
        {task: "Edit Test Cases", admin: true, p_user: true, r_user: false, n_user: false },
        {task: "Delete Test Cases", admin: true, p_user: true, r_user: false, n_user: false },
        {task: "Add Comment to Test Case", admin: true, p_user: true, r_user: false, n_user: false },
        {task: "Delete Personal Comment on Test Case", admin: true, p_user: true, r_user: false, n_user: false },
        {task: "Edit Conditions on Test Case", admin: true, p_user: true, r_user: false, n_user: false },
        {task: "View Cell Data Sheet", admin: true, p_user: true, r_user: true, n_user: false },
        {task: "Edit Cell Data Sheet", admin: true, p_user: true, r_user: false, n_user: false },
        {task: "Lock Cell Data Sheet", admin: true, p_user: true, r_user: false, n_user: false },
        {task: "Download Documents", admin: true, p_user: true, r_user: true, n_user: false },
        {task: "Upload Documents", admin: true, p_user: true, r_user: false, n_user: false },
        {task: "Delete Documents", admin: true, p_user: false, r_user: false, n_user: false },
        {task: "Add Test Specification", admin: true, p_user: true, r_user: false, n_user: false },
        {task: "View Test Specification Details", admin: true, p_user: true, r_user: true, n_user: false },
        {task: "Edit Draft Test Specification", admin: true, p_user: true, r_user: false, n_user: false },
        {task: "Finalize Draft Test Specification", admin: true, p_user: false, r_user: false, n_user: false },
        {task: "Edit Personal Avatar", admin: true, p_user: true, r_user: true, n_user: false },
        {task: "View User Management", admin: true, p_user: false, r_user: false, n_user: false },
        {task: "Edit Users", admin: true, p_user: false, r_user: false, n_user: false },
        {task: "Delete Users", admin: true, p_user: false, r_user: false, n_user: false },
        {task: "View Supplier Management", admin: true, p_user: true, r_user: false, n_user: false },
        {task: "Add Supplier", admin: true, p_user: false, r_user: false, n_user: false },
        {task: "Edit Supplier", admin: true, p_user: false, r_user: false, n_user: false },
        {task: "Delete Supplier", admin: true, p_user: false, r_user: false, n_user: false },
        {task: "View Data Management", admin: true, p_user: false, r_user: false, n_user: false },
        {task: "Add Data", admin: true, p_user: false, r_user: false, n_user: false },
        {task: "Edit Data", admin: true, p_user: false, r_user: false, n_user: false },
        {task: "Delete Data", admin: true, p_user: false, r_user: false, n_user: false },
    ]

    const classes = useStyles();
    return (
        <>
            <IconButton
                className={classes.delete_icon_wrapper_big}
                onClick={ToggleModal}
                size="large"
                style={accessPoint === "Table" ? { marginLeft: '8px' } : {}}
            >
                <Icon iconName={"InfoIcon"} fill={accessPoint === "Table" ? grey800 : grey600} />
            </IconButton>

            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={show}
                onClose={ToggleModal}
            >
                <DialogContent className={classes.dialog}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h2">
                            Roles & Rights for TONIC42 
                        </Typography>
                        <IconButton onClick={ToggleModal} className={classes.hover_blue}>
                            <Icon iconName={"CloseIcon"} fill={petrol800} />
                        </IconButton>
                    </Box>
                    <Box mt={4} display="flex ">
                        <Box display="flex" flexDirection="column">
                            <Typography variant="h5" title="text1" className={classes.right_text}>
                            
                            </Typography>
                            {permissions.map((i, index) => (
                                <Typography key={index} display="flex" alignItems="center" variant="h5" title="text1" className={classes.right_text}>
                                    {i.task}
                                </Typography>  
                            ))}
                        </Box>
                        <Box display="flex" flexDirection="column" className={classes.role_column}>
                            <Typography variant="h4" title="text1" className={classes.role_title}>
                                Admin
                            </Typography>
                            {permissions.map((i, index) => (
                                <Box key={index} display="flex" alignItems="center" justifyContent="center" className={classes.right_icon}>
                                   {i.admin ?  <Icon iconName={"CorrectIcon"} fill={grey600} /> : ""}
                                </Box>
                            ))}
                        </Box>
                        <Box display="flex" flexDirection="column" className={classes.role_column}>
                            <Typography variant="h4" title="text1" className={classes.role_title}>
                                Project User
                            </Typography>
                            {permissions.map((i, index) => (
                                <Box key={index} display="flex" alignItems="center" justifyContent="center" className={classes.right_icon}>
                                   {i.p_user ?  <Icon iconName={"CorrectIcon"} fill={grey600} /> : ""}
                                </Box>
                            ))}
                        </Box>
                        <Box display="flex" flexDirection="column" className={classes.role_column}>
                            <Typography variant="h4" title="text1" className={classes.role_title}>
                                Auditor User
                            </Typography>
                            {permissions.map((i, index) => (
                                <Box key={index} display="flex" alignItems="center" justifyContent="center" className={classes.right_icon}>
                                   {i.r_user ?  <Icon iconName={"CorrectIcon"} fill={grey600} /> : ""}
                                </Box>
                            ))}
                        </Box>
                        <Box display="flex" flexDirection="column" className={classes.role_column}>
                            <Typography variant="h4" title="text1" className={classes.role_title}>
                                New User
                            </Typography>
                            {permissions.map((i, index) => (
                                <Box key={index} display="flex" alignItems="center" justifyContent="center" className={classes.right_icon}>
                                   {i.n_user ?  <Icon iconName={"CorrectIcon"} fill={grey600} /> : ""}
                                </Box>
                            ))}
                        </Box>

                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}