import React from 'react';
import Box from '@mui/material/Box';
import { useStyles } from '../Tooltip.styles';
import { IMAGE_MAP } from '../../../other/helper/ImgMapper/ImgMapper';
import { HtmlTooltip } from '../../../other/tooltips/HtmlTooltip/HtmlTooltip';

export default function UserInfo(props) {
    const { user, accessPoint } = props;

    const classes = useStyles();
    return (
        <HtmlTooltip data-testid="tooltip"
            title={
                <Box display="flex " flexDirection="column" justifyContent="space-between">
                    <Box display="flex " flexDirection="row" px={1} pt={1}>
                        <Box className={classes.title} style={{minWidth: '50px'}}>Name:</Box>
                        <Box ml={0.5} className={classes.value} >{user.firstName} {user.lastName}</Box>
                    </Box>
                    <Box display="flex " flexDirection="row" p={1}>
                        <Box className={classes.title} style={{minWidth: '50px'}}>Role:</Box>
                        <Box ml={0.5} className={classes.value}>{user.projects[0]?.role?.name}</Box>
                    </Box>
                </Box>
            }
        >
            <div className={classes.userimage} style={accessPoint === "Table" ? {marginLeft: '0', marginBottom: '0'} : {marginRight: '0'}}>
                <img src={IMAGE_MAP.AVATAR[user.avatarId]?.pngSrc} alt="avatar" />
            </div>
        </HtmlTooltip>
    )
}