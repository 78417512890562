import React, { useContext, useState } from 'react';
import { Box, IconButton, MenuItem, Select } from '@mui/material';
import { useStyles } from '../UserManagement.styles';
import { IMAGE_MAP } from '../../other/helper/ImgMapper/ImgMapper';
import Icon from '../../../assets/icons/Icon.js';
import clsx from 'clsx';
import { APP_ROLES_MAPPER } from '../../other/helper/utilsMapper/utilsMapper';
import { SnackbarContext } from '../../other/contexts/SnackBarContext';
import DeleteData from '../../other/dialogs/DeleteData/DeleteData';
import { petrol800 } from '../../other/helper/MuiStyles';

function UserManagementListElement(props) {
    const { className, i, appRoles, mutateFunctionRole, mutateFunctionActive } = props;
    const [edit, setEdit] = useState(false);

    const { setMessage } = useContext(SnackbarContext);

    const classes = useStyles();

    return (
        <Box display="flex" >
            <Box className={clsx(className, classes.header1)} display="flex">
                <Box my={2} pl={3} pr={2} display="flex" alignItems="center" justifyContent="center">
                    <img src={IMAGE_MAP.AVATAR[i.avatarId].pngSrc} height="40px" alt="avatar" style={i.active ? {} : { opacity: 0.38 }} />
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center" >
                    <Box pr={2} display="flex" className={classes.text} style={i.active ? {} : { opacity: 0.38 }}>{i.firstName} {i.lastName}</Box>
                    {/* ROBERT: TBD make overflow hidden */}
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" className={clsx(className, classes.header2)}>
                <Box pr={2} style={i.active ? {} : { opacity: 0.38 }}>{i.userId}</Box>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" className={clsx(className, classes.header3)}>
                <Box pr={2} style={i.active ? {} : { opacity: 0.38 }}>{i.department}</Box>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" className={clsx(className, classes.header4)}>
                {!edit &&
                    <Box pr={2} style={i.active ? {} : { opacity: 0.38 }}>{APP_ROLES_MAPPER.APP_ROLE[i.role.roleId]}</Box>
                }
                {edit &&
                    <Select
                        name="role"
                        value={i.role.roleId}
                        onChange={(event, child) => { mutateFunctionRole({ variables: { userId: i.userId, roleId: event.target.value, role: child.props.datamutationvalue } }); setMessage('Role has been updated.'); }}
                        style={{ maxWidth: '155px', textAlign: 'start', letterSpacing: '0.6px' }}
                    >
                        {appRoles?.roles.map((option) => (
                            <MenuItem
                                datamutationvalue={option.role}
                                key={option.roleId}
                                value={option.roleId}
                                style={{ letterSpacing: '0.6px' }}
                            >
                                {APP_ROLES_MAPPER.APP_ROLE[option.roleId]}
                            </MenuItem>
                        ))}
                    </Select>
                }
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" className={clsx(className, classes.header5)}>
                <Box pr={2} style={i.active ? {} : { opacity: 0.38 }}>
                    {i.projects?.length > 0 ? i.projects.map(((project, index) => { return project.name + (index === i.projects.length - 1 ? '' : ', ') })) : '-'}
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" className={clsx(className, classes.header6)}>
                {i.active &&
                    <Box display="flex" flexDirection="column" justifyContent="center">
                        <IconButton
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={() => setEdit(!edit)}
                            className={classes.hover_blue}
                        >
                            <Icon
                                fill={petrol800}
                                iconName={"EditIcon"}
                                width={24}
                                height={24}
                            />
                        </IconButton>
                    </Box>
                }
                <Box mr={3} display="flex" flexDirection="column" justifyContent="center">
                    <DeleteData headline={'User'} user={i} idValue={i.userId} mutateFunction={mutateFunctionActive} activate={i.active ? false : true} />
                </Box>
            </Box>
        </Box>
    )
}


export default UserManagementListElement;
