import React from 'react';
import { Box } from '@mui/material';
import { useStyles } from '../Tooltip.styles';
import { HtmlTooltip } from '../HtmlTooltip/HtmlTooltip.js';

export default function AgingStatusInfo(props) {
    const { agingStatus, description, sizeWindow } = props;

    const styleProps = {
        fontSizeTable: (sizeWindow < 5) ? '14px' : '16px',
        minWidthTable: (sizeWindow < 5) ? '34px' : '16px',
    };
    const classes = useStyles(styleProps);
    return (
        <HtmlTooltip data-testid="tooltip" maxwidth="500px"
            title={
                <Box display="flex " flexDirection="row" justifyContent="space-between">
                    <Box display="flex " flexDirection="column" justifyContent="space-between">
                        <Box display="flex " flexDirection="row" p={1}>
                            <Box className={classes.title} style={{ minWidth: '100px' }}>Aging Status:</Box>
                            <Box ml={0.5} className={classes.value}>{agingStatus} {description?.length > 0 ? ` (${description})` : ''}</Box>
                        </Box>
                    </Box>
                </Box>
            }
        >
            <Box className={classes.test_specification_text}>{agingStatus}</Box>
        </HtmlTooltip>
    )
}