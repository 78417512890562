import React, { useState, useEffect } from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import Icon from '../../../../assets/icons/Icon.js';
import { useStyles } from '../Step.styles.js';
import classNames from '../example.module.css';
import { MentionsInput, Mention } from 'react-mentions';
import { setParameters, HintBox } from '../setParameters';
import Mark from "mark.js";
import { grey600, petrol800 } from '../../../other/helper/MuiStyles.js';

const StepDescription = ({
    steps,
    setSteps,
    step,
    stepIndex,
    entry,
    category,
    mutationValues,
    readOnly,
    selectedParameters,
}) => {

    const [selected, setSelected] = useState(false);

    const parameters = setParameters(selectedParameters, entry);

    const handleEdit = () => {
        setSelected(!selected);
        handleHighlight();
    };

    const handleRemoveStep = (index) => {
        let newArr = JSON.parse(JSON.stringify(steps));
        newArr.splice(index, 1);
        let value = [];
        for (let i = 0; i < newArr.length; i++) {
            value[i] = { number: i + 1, stepDescription: newArr[i].stepDescription };
        }
        setSteps(value);
        setSelected(false);
    };

    const handleCancel = () => {
        setSelected(false);
    };

    const onChange = (ev, newValue) => {
        let string = newValue;
        let newArr = JSON.parse(JSON.stringify(steps));
        newArr[stepIndex].stepDescription = string;
        setSteps(newArr);
        handleHighlight();
    };

    // define parameter as search words for handleHighlight
    let searchwords = [];

    if (parameters?.length > 0) {
        parameters.forEach(i => {
            searchwords.push(`@${i.display}`)
        })
    }

    // eslint-disable-next-line
    const handleHighlight = () => {
        let markInstance = new Mark(document.querySelector("#search-node"));
        markInstance.unmark({
            done: () => {
                markInstance.mark(searchwords, {
                    "element": "span",
                    "className": classes.highlight_parameter
                })
            }
        });
    };

    useEffect(() => {
        handleHighlight();
    }, [handleHighlight]);

    const classes = useStyles();

    return <>
        {!selected &&
            <Box mx={3} mb={2} py={3} pr={2} display="flex" alignItems="center" justifyContent="space-between" className={classes.step_box} id="search-node">
                <Box display="flex" alignItems="center">
                    <Box display="flex" justifyContent="center" alignItems="center" className={classes.menu_icon}>
                        <Icon iconName={'MenuIcon'} fill={grey600} />
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center" className={classes.step_bubble}>
                        {step.number}
                    </Box>
                    <Box className={classes.step_step}>
                        Step {step.number}
                    </Box>
                    <Box className={classes.step_description}>
                        {steps[stepIndex]?.stepDescription}
                    </Box>
                </Box>
                {!readOnly && mutationValues.tag === "DRAFT" &&
                    <Box>
                        <IconButton
                            className={classes.step_icon}
                            data-testid={"edit-step-button"}
                            onClick={() => handleEdit()}
                        >
                            <Icon iconName={'EditIcon'} fill={petrol800} />
                        </IconButton>
                        <IconButton
                            className={classes.step_icon}
                            onClick={() => handleRemoveStep(stepIndex)}
                        >
                            <Icon iconName={'TrashIcon'} fill={petrol800} />
                        </IconButton>
                    </Box>
                }
            </Box>
        }
        {selected && !readOnly &&
            <Box mx={3} mb={2} py={3} pr={2} display="flex" flexDirection="column" justifyContent="center" className={classes.step_box}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                        <Box display="flex" justifyContent="center" alignItems="center" className={classes.menu_icon}>
                            <Icon iconName={"MenuIcon"} fill={grey600} />
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" className={classes.step_bubble_selected}>
                            {step.number}
                        </Box>
                        <Box className={classes.step_step}>
                            Step {step.number}
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <IconButton
                            className={classes.step_icon}
                            onClick={() => handleRemoveStep(stepIndex)}>
                            <Icon iconName={"TrashIcon"} fill={petrol800} />
                        </IconButton>
                    </Box>
                </Box>
                <div className={classNames.advanced}>
                    <MentionsInput
                        value={step.stepDescription}
                        onChange={onChange}
                        markup="{{__id__}}"
                        className="mentions"
                        classNames={classNames}
                        style={{ marginTop: '24px' }}
                        a11ySuggestionsListLabel={"Suggested mentions"}
                        data-testid={"step-description-input"}
                    >
                        <Mention
                            trigger={"@"}
                            data={search => parameters.filter(i => i.display.includes(search))}
                            displayTransform={id => `@${id}`}
                            className={classNames.mentions__mention}
                            markup='@__display__'
                        />
                    </MentionsInput>
                </div>
                {step.stepDescription.length > 2000 &&
                    <Box display="flex" alignItems="center" marginLeft="122px">
                        <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 2000 characters.</Typography>
                    </Box>
                }
                <HintBox />

                <Box mt={3} ml={15.25} display="flex" justifyContent="space-between">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        data-testid={"save-step-button"}
                        disabled={step.stepDescription.length > 2000}
                        onClick={() => handleEdit()}
                    >
                        <div style={{ display: 'flex', marginRight: '8px' }}>
                            <Icon iconName={"SaveIcon"} fill={'#FFF'} />
                        </div>
                        Save {category} Step
                    </Button>
                </Box>
            </Box>
        }
    </>;
};

export default StepDescription;

