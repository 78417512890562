import React, { useContext, useState } from 'react';
import { Button, Dialog, DialogContent, Box, Typography, IconButton } from '@mui/material';
import { useStyles } from '../UniversalDialog.styles.js';
import Icon from '../../../../assets/icons/Icon.js';
import { petrol800, signalRed800 } from '../../helper/MuiStyles.js';
import { SnackbarContext, SnackbarType } from "../../contexts/SnackBarContext.js";
import { currentProjectId, currentTestSpecificationId, isLockLoading } from '../../../../operations/localeStorage.js';
import { useMutation } from '@apollo/client';
import { LOCKCDS, LOCKPROJECT, UPDATETESTSPECIFICATION } from '../../../../operations/mutations/mutations.js';
import { CELLDATASHEET, PROJECTDETAIL, TESTSPECIFICATION } from '../../../../operations/queries/query.js';
import { CDSContext } from '../../contexts/CDSContext.js';

function LockComponent(props) {
    const { headline, mutationValues, lock, pretreatmentSteps, procedureSteps, selectedConditions, selectedParams, isLockDisabled, subSampleId, projectName } = props;

    const { setIsSnackbarOpen, setMessage, setSnackbarType } = useContext(SnackbarContext);    
    const { currentPowerOCV } = useContext(CDSContext);
    const [show, setShow] = useState(false);

    const ToggleModal = () => {
        setShow(!show);
    };

    const handleOnCompleted = async () => {
        isLockLoading(false);
        setSnackbarType(SnackbarType.SUCCESS);
        setIsSnackbarOpen(true);
    };

    const handleOnError = async (error) => {
        isLockLoading(false);
        setMessage(error.message);
        setSnackbarType(SnackbarType.ERROR);
        setIsSnackbarOpen(true);
    };

    // MUTATIONS
    const [lockCDS] = useMutation(LOCKCDS, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: CELLDATASHEET(subSampleId, currentPowerOCV) }, { query: PROJECTDETAIL(currentProjectId()) }], awaitRefetchQueries: true });
    const [lockProject] = useMutation(LOCKPROJECT, { onError: handleOnError, onCompleted: handleOnCompleted, refetchQueries: [{ query: PROJECTDETAIL(currentProjectId()) }], awaitRefetchQueries: true });
    const [updateTestSpec] = useMutation(UPDATETESTSPECIFICATION, { refetchQueries: [{ query: TESTSPECIFICATION(currentTestSpecificationId()) }], awaitRefetchQueries: true, onCompleted: handleOnCompleted, onError: handleOnError, });

    const handleLockProject = () => {
        isLockLoading(true);
        lockProject({ variables: { projectId: currentProjectId(), isLocked: lock } });
        setMessage(`Project has been ${lock ? 'locked' : 'unlocked'}`);
        setShow(false);
    };

    const handleFinalize = () => {
        let para = JSON.parse(JSON.stringify(selectedParams));
        let cond = JSON.parse(JSON.stringify(selectedConditions));
        let cleanedMutationParameters = [];
        for (let i = 0; i < cond.length; i++) {
            cleanedMutationParameters.push({ assignParameterId: para[i].assignParameterId, parameterId: para[i].parameter.parameterId, conditions: [] });
            for (let j = 0; j < cond[i].length; j++) {
                cleanedMutationParameters[i].conditions.push({ id: cond[i][j].id, condition: cond[i][j].condition });
            }
        }
        updateTestSpec({ variables: { testSpecificationId: currentTestSpecificationId(), name: mutationValues.name, version: mutationValues.version, category: mutationValues.category, procedureSteps: procedureSteps, tag: "FINAL", approval: mutationValues.approval, groupStandardReference: mutationValues.groupStandardReference, description: mutationValues.description, pretreatmentSteps: pretreatmentSteps, terminationCriteria: mutationValues.terminationCriteria, testProtocolContents: mutationValues.testProtocolContents, postCondition: mutationValues.postCondition, reference: mutationValues.reference, testDuration: mutationValues.testDuration, parameters: cleanedMutationParameters, requirementIds: mutationValues.requirementIds } });
        setMessage('Test Specification has been finalized');
        isLockLoading(true);
        setShow(false);
    };

    const handleLockCDS = () => {
        lockCDS({ variables: { subSampleId: subSampleId, isLocked: lock } });
        isLockLoading(true);
        setMessage(`Cell Data Sheet has been ${lock ? 'locked' : 'unlocked'}`);
        setShow(false);
    };

    const classes = useStyles();

    return (
        <>
            <Button
                data-testid={"lock-button"}
                variant="contained"
                color="primary"
                disabled={isLockDisabled}
                className={classes.button_delete}
                onClick={ToggleModal}
                style={(headline === "Project") ? { marginLeft: '24px' } : (headline === "Cell Data Sheet") ? { marginRight: '24px' } : {}}
            >
                <div style={{ display: 'flex', marginRight: '8px' }}>
                    <Icon iconName={(lock || headline === "Draft") ? "LockIcon" : "UnlockIcon"} width={24} height={24} fill={isLockDisabled ? '#FFF' : signalRed800} />
                </div>
                {(headline === "Draft") ? 'Finalize Draft' : lock ? `Lock ${headline}` : `Unlock ${headline}`}
            </Button>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={show}
                onClose={ToggleModal}
            >
                <DialogContent>
                    <Box display="flex" alignItems="center" flexWrap="wrap" justifyContent="space-between">
                        {headline === 'Cell Data Sheet' ?
                            <Typography variant="h2">
                                {lock ? `Lock` : `Unlock`} Cell Data Sheet
                            </Typography>
                            :
                            <Typography variant="h2">
                                {(headline === "Draft") ? `Finalize ${mutationValues?.name} V. ${mutationValues?.version}` : lock ? `Lock ${projectName}` : `Unlock ${projectName}`}
                            </Typography>
                        }
                        <IconButton onClick={ToggleModal} className={classes.hover_blue}>
                            <Icon iconName={"CloseIcon"} fill={petrol800} />
                        </IconButton>
                    </Box>
                    <Box mt={2} display="flex" alignItems="center" >
                        {headline === "Project" &&
                            <>
                                {lock &&
                                    <Typography variant="h4" style={{ fontWeight: '400' }}>
                                        Approvals and documents requiring documentation for series and end products and approved concept versions (CSD Class 5.3) must be deleted no later than 35 years after creation. <br /><br /> Locking this project representes the creation and will start the retention period and hence disable editing of all information belonging to this project. However, you can still unlock the project afterwards if changes are needed.<br /> <br /> Do you want to lock this project?
                                    </Typography>
                                }
                                {!lock &&
                                    <Typography variant="h4" style={{ fontWeight: '400' }}>
                                        Approvals and documents requiring documentation for series and end products and approved concept versions (CSD Class 5.3) must be deleted no later than 35 years after creation. <br /><br /> Unlocking this project will stop the retention period and hence enable editing of all information belonging to this project. Once you have unlocked the project, you can lock it again. However, the retention period starts from the beginning again.<br /> <br /> Do you want to unlock this project?
                                    </Typography>
                                }
                            </>
                        }
                        {headline === "Draft" &&
                            <Typography variant="h4" style={{ fontWeight: '400' }}>
                                Approvals and documents requiring documentation for series and end products and approved concept versions (CSD Class 5.3) must be deleted no later than 35 years after creation. <br /><br /> Finalizing this test specification representes the creation and will start the retention period and hence disable editing of all information belonging to this test specification. Changes are no longer possible afterwards.<br /> <br /> Do you want to finalize the test specification?
                            </Typography>
                        }
                        {headline === "Cell Data Sheet" &&
                            <>
                                {lock &&
                                    <>
                                        <Typography variant="h4" style={{ fontWeight: '400' }}>
                                            By locking this cell data sheet, editing of this section is prohibited for all users.<br /> However, you can still unlock the cell data sheet afterwards if changes are needed.<br /> <br /> Do you want to lock this cell data sheet?
                                        </Typography>
                                    </>
                                }
                                {!lock &&
                                    <Typography variant="h4" style={{ fontWeight: '400' }}>
                                        By unlocking this cell data sheet, all eligible users can edit this section.<br /> You can lock the cell data sheet again afterwards.<br /> <br /> Do you want to unlock this cell data sheet?
                                    </Typography>
                                }
                            </>
                        }
                    </Box>
                    <Box mt={4} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                        <Button
                            data-testid={"confirm-lock-button"}
                            variant="contained"
                            color="primary"
                            onClick={(headline === "Draft") ? handleFinalize : (headline === "Project") ? handleLockProject : handleLockCDS}
                        >
                            {headline !== "Draft" ?
                                lock ? `Lock ${headline}` : `Unlock ${headline}`
                                :
                                'Finalize Draft'
                            }
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default LockComponent;