import { Button } from "@mui/material"
import { Box } from "@mui/system"
import React, { useContext } from "react"
import { useStyles } from './CellDataSheet.styles.js';
import { CDSContext } from '../../other/contexts/CDSContext';
import { petrol800 } from "../../other/helper/MuiStyles.js";

export default function PowerOCVSwitch() {

    const { currentPowerOCV, setCurrentPowerOCV } = useContext(CDSContext);
    const { setCurrentPowerUnit } = useContext(CDSContext);

    const classes = useStyles();

    return (
        <>
            <Box my={3} display="flex" justifyContent="center">
                <Box display="flex" flexDirection="column">
                    <Box display="flex" justifyContent="center">
                        <Button 
                        role="button" 
                        className={(currentPowerOCV === 'POWER_LIMITS') ? classes.circle_selected : classes.circle} 
                        selected={currentPowerOCV === 'POWER_LIMITS'} 
                        value={'POWER_LIMITS'} 
                        onClick={() => {setCurrentPowerOCV('POWER_LIMITS'); setCurrentPowerUnit('MILLI_OHM')}}
                        >
                            1
                        </Button>
                    </Box>
                    <Box mt={1.5} display="flex" justifyContent="flex-end" className={classes.text} style={(currentPowerOCV === 'POWER_LIMITS') ? {color: petrol800, fontWeight: '700'} : {}}>Power Limits</Box>
                </Box>
                <Box className={classes.line}></Box>
                <Box display="flex" flexDirection="column">
                    <Box display="flex" justifyContent="center">
                        <Button 
                        data-testid={"ocv-button"} 
                        role="button" className={(currentPowerOCV === 'OCV') ? classes.circle_selected : classes.circle} 
                        selected={currentPowerOCV === 'OCV'} 
                        value={'OCV'} 
                        onClick={() => {setCurrentPowerOCV('OCV'); setCurrentPowerUnit('VOLT')}}
                        >
                            2
                        </Button>
                    </Box>
                    <Box mt={1.5} display="flex" justifyContent="flex-end" className={classes.text} style={(currentPowerOCV === 'OCV') ? {color: petrol800, fontWeight: '700'} : {}}>OCV Characteristic</Box>
                </Box>
            </Box>
        </>
    )
}