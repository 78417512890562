import React from "react";
import { Box } from "@mui/system";
import { grey600 } from "../../../../other/helper/MuiStyles";

// Header & Footer for each page

export const PageTemplate = (props) => {

  let today = new Date();
  let date = "date of report: " + today.getDate() + "." + parseInt(today.getMonth() + 1) + "." + today.getFullYear();
  let delte_date = "retention period: " + today.getDate() + "." + parseInt(today.getMonth() + 1) + "." + (today.getFullYear() + 30);

  return (
    <>
      <Box
        style={{
          position: "absolute",
          top: "32px",
          left: "32px",
          fontSize: '8px',
          color: grey600,
          fontFamily: 'VWAG TheSans-Regular, sans-serif'
        }}
      >
        RELEASE REPORT {props.pageNum} / {props.totalPages}
      </Box>
      <div
        style={{
          position: "absolute",
          top: "32px",
          right: "32px",
          fontSize: '8px',
          color: grey600,
          fontFamily: 'VWAG TheSans-Regular, sans-serif'
        }}>
        {date}
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "32px",
          left: "32px",
          fontSize: '8px',
          color: grey600,
          fontFamily: 'VWAG TheSans-Regular, sans-serif'
        }}>
        {props.project?.name} - ID {props.project?.projectId}
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "32px",
          right: "32px",
          fontSize: '8px',
          color: grey600,
          fontFamily: 'VWAG TheSans-Regular, sans-serif'
        }}>
        KSU - 5.3, {delte_date}
      </div>
    </>
  );
};