import React from 'react';
import { Typography } from '@mui/material';

import { styles, Link } from './PrivacyPolicyComponents';

function EmployeesVWGroupDE() {

    const classes = styles();

    return (<>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Nachfolgend informieren wir Sie über die Erhebung,
            <Link href={"https://volkswagen-net.de/wikis/display/SCHUTZPBD/Verarbeitung"}>Verarbeitung</Link>
            und Nutzung Ihrer
            <Link href={"https://volkswagen-net.de/wikis/display/SCHUTZPBD/Personenbezogene+Daten"}>personenbezogenen Daten</Link>
            als Beschäftigte einer Gesellschaft des Volkswagen Konzerns („Konzernmitarbeiter“) durch die Volkswagen AG
            als alleinige datenschutzrechtlich
            <Link href={"https://volkswagen-net.de/wikis/display/SCHUTZPBD/Verantwortlicher"}>Verantwortliche</Link>.
        </Typography>

        {/* SECTION A */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            A. Verantwortlicher
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Verantwortlich für die Datenverarbeitung ist die Volkswagen AG, Berliner Ring 2, 38440 Wolfsburg, eingetragen im
            Handelsregister des Amtsgerichts Braunschweig unter der Nr. HRB 100484 („Volkswagen AG“).
        </Typography>

        {/* SECTION B */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            B. Verarbeitung Ihrer personenbezogenen Daten
        </Typography>

        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Wir verarbeiten Ihre personenbezogenen Daten u. a. in Zusammenhang mit
        </Typography>
        <ul className={classes.list}>
            <li>
                Ihrer <b>Zusammenarbeit</b> mit (Mitarbeitern) der Volkswagen AG (z. B. E-Mail- Kommunikation, Vorbereitung,
                Teilnahme und Dokumentation von Terminen, Veranstaltungen und Dienstreisen (z. B. Konzern-Management
                Informationen, internationalen Tagungen, Messen, Kongressen, Doktorandentag, Besuch von Gedenkstätten),
                Zusammenarbeit in markenübergreifenden Projekten, als Ansprechpartner für Geschäfts- und Fachbereiche)
            </li>
            <li>
                der <b>Dokumentation</b> der beruflichen Nutzung von unternehmenseigenen Betriebsmitteln
                (z. B. Fahrzeugen, Bild und Tontechnik, IT-Hardware) sowie der Personalisierung und namentlichen Zuordnung
                von Arbeitsmitteln (z. B. Berufs- /Schutzbekleidung, Schlüssel, Telekommunikationsgeräten)
            </li>
            <li>
                der <b>Sicherstellung Ihres Arbeitsschutzes</b> (z. B. Unfallanalysen, Arbeitssicherheitsunterweisungen)
            </li>
            <li>
                zur Sicherstellung der <b>innerbetrieblichen Sicherheit</b> und <b>Gesundheit</b> der Belegschaft
                (z.B. Infektionsausbreitung nachvollziehen zu können)
            </li>
            <li>
                der beruflichen <b>Nutzung von IT-Systemen</b> (z. B. im Rahmen der Userverwaltung für den Zugriff
                auf die von Ihnen genutzten Systeme der Volkswagen AG, Protokollierungen bei in IT-Systemen
                vorgenommenen Änderungen, in Support- Fällen, Zugriffskontrollen für die Gewährleistung
                eines sicheren Systembetriebs, System-Tests zur Qualitätssicherung, Betrieb der IT-Infrastruktur)
            </li>
            <li>
                der <b>Abwehr digitaler Risiken</b> für Sie und das
                <Link href={"https://volkswagen-net.de/wikis/display/SCHUTZPBD/Unternehmen"}>Unternehmen</Link>
                (z. B. Sicherstellung der
                <Link href={"https://volkswagen-net.de/wikis/display/SCHUTZPBD/Vertraulichkeit"}>Vertraulichkeit</Link>,
                <Link href={"https://volkswagen-net.de/wikis/pages/viewpage.action?pageId=1253739795"}>Integrität</Link>
                und
                <Link href={"https://volkswagen-net.de/wikis/pages/viewpage.action?pageId=1253739843"}>Verfügbarkeit</Link>
                aller Daten, Erkennen von Cyberangriffen und Gegenmaßnahmen)
            </li>
            <li>
                Ihrer <b>Qualifizierung und Weiterbildung</b>, die konzernweit angeboten wird
                (z. B. Ihre spezifischen Entwicklungsprogramme und Prüfungen für Führungs- oder Expertenaufgaben,
                die Teilnahme an Qualifizierungsmaßnahmen),
            </li>
            <li>
                Ihrer <b>Teilnahme an Veranstaltungen</b> (z. B. Messen, Kongresse, Tagungen, Konferenzen)
            </li>
            <li>
                Ihrer <b>Teilnahme an Mitarbeiterbefragungen</b> (z. B. Stimmungsbarometer)
            </li>
            <li>
                der Bearbeitung Ihrer <b>betrieblichen Verbesserungsideen</b> (z. B. Konzern- Ideenmanagement)
            </li>
            <li>
                dem <b>Austausch Ihrer Daten innerhalb des Konzerns</b> (z. B. Zusammenarbeit in markenübergreifenden
                Projekten, Nachfolgeplanung, Übernahme Rentenanwartschaften, internationale Vergütungsrunden,
                Leistungs-/ Entwicklungsbewertungen)
            </li>
            <li>
                Ihren möglichen Dienstreisen (z. B. Reiseplanung, Hotel- und Flugbuchungen, Auslagenerstattung,
                Informationsversorgung bzgl. Sicherheit und Risiken)
            </li>
            <li>
                der Nutzung der <b>bargeldlosen Bezahlfunktion</b> der Konzern-Card (Werksausweise)
                für Dienstleistungen (z. B. Gastronomie und Kantinen der Volkswagen AG) und Produkten
                (z. B. Zubehör- und Markenartikeln, dienstliches Betanken von Fahrzeugen)
            </li>
            <li>
                der Durchführung von <b>Prüfungstätigkeiten</b> der Konzernrevision des Volkswagen Konzerns u.a.
                im Auftrag des Konzernvorstandes oder des Aufklärungsoffices.
            </li>
            <li>
                der möglichen <b>Gewährung von geldwerten Vorteilen</b> oder im Kontext anderer
                steuerrelevanter Sachverhalte (z.B. Einladung zu Sport- und Kulturveranstaltungen, Messen,
                Händlerveranstaltungen, Dokumentation von Empfängern von Bewirtungen) zur Sicherstellung
                der ordnungsgemäßen Versteuerung und Verbeitragung sowie der ordnungsgemäßen Vergabe einer Einladung
                im Rahmen der unternehmerischen Compliance-Vorgaben
            </li>
            <li>
                der Geltendmachung, Ausübung und <b>Verteidigung von Rechtsansprüchen</b>. Es können in einschlägigen
                Fällen relevante
                <Link href={"https://volkswagen-net.de/wikis/display/SCHUTZPBD/Personenbezogene+Daten"}>personenbezogene Daten</Link>
                durch die Volkswagen AG an Ermittlungsbehörden (z.B. Finanzamt, Polizei, Staatsanwaltschaft)
                und Gerichte übermittelt werden.
            </li>
            <li>
                <Link href={"https://volkswagen-net.de/wikis/display/SCHUTZPBD/Meldeprozess"}><b>Meldeprozessen</b></Link>
                (z.B. an die
                <Link href={"https://volkswagen-net.de/wikis/pages/viewpage.action?pageId=1253739755"}>Aufsichtsbehörde</Link>
                zur Durchführung der gesetzlichen Meldepflicht von Datenschutzverletzungen nach Art. 33 DSGVO)
            </li>
            <li>
                der Erstellung von <b>Statistiken und Benchmarks</b> (z.B. Vergütungsvergleiche)
            </li>
        </ul>

        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Im Rahmen der oben genannten Prozesse werden folgende Kategorien Ihrer personenbezogenen Daten verarbeitet:
        </Typography>
        <ul className={classes.list}>
            <li>
                <b>Berufliche Kontakt- und (Arbeits-) Organisationsdaten</b> (z. B. Name, Vorname, dienstliche Anschrift, 
                dienstliche E-Mail-Adresse und Telefonnummer, Personalnummer, Gesellschaft, Fachbereich, Abteilung, 
                Kostenstelle, Unterschrift)
            </li>
            <li>
                <b>Daten zu persönlichen/beruflichen Verhältnissen und Merkmalen</b> (z. B. Berufsbezeichnung, 
                Dauer der Konzernzugehörigkeit, ausgeübte Tätigkeiten, Qualifikationen)
            </li>
            <li>
                <b>Vertragsdaten</b> (z. B. Beherbergungsverträge, Senior-Expert-Verträge)
            </li>
            <li>
                <b>IT-Nutzungsdaten</b> (z. B. User ID, Passwörter, Systemberechtigungen, IP-Adressen)
            </li>
            <li>
                <b>Mitarbeiterfoto</b>
            </li>
            <li>
                <b>Besonders sensible 
                <Link href={"https://volkswagen-net.de/wikis/display/SCHUTZPBD/Personenbezogene+Daten"}>personenbezogene Daten</Link>
                </b>(z. B. 
                <Link href={"https://volkswagen-net.de/wikis/display/SCHUTZPBD/Gesundheitsdaten"}>Gesundheitsdaten</Link>
                )
            </li>
        </ul>

        {/* SECTION C */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            C. Rechtsgrundlagen für die Erhebung Ihrer personenbezogenen Daten
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Ihre 
            <Link href={"https://volkswagen-net.de/wikis/display/SCHUTZPBD/Personenbezogene+Daten"}>personenbezogenen Daten</Link>
            werden nur zweckbezogen erhoben, verarbeitet und übermittelt und nur wenn eine einschlägige Rechtsgrundlage 
            dafür vorliegt.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Folgende Rechtsgrundlagen liegen den oben beschriebenen Zwecken zugrunde:
        </Typography>
        <ul className={classes.list}>
            <li>
                Ihre 
                <Link href={"https://volkswagen-net.de/wikis/display/SCHUTZPBD/Einwilligung"}><b>Einwilligung</b></Link>
                (Art. 6 Abs. 1 lit. a DSGVO) (z. B. Mitarbeiterfoto)
            </li>
            <li>
                <b>Vertrag oder Vertragsanbahnung</b> mit dem Betroffenen (Art. 6 Abs. 1 lit. b DSGVO)
            </li>
            <li>
                zur Erfüllung einer <b>rechtlichen Verpflichtung</b> nach EU-Recht oder dem Recht eines EU-Mitgliedstaates 
                (Art. 6 Abs. 1 lit. c DSGVO) (Spezialgesetzliche Regelung außerhalb der DSGVO wie z. B. Einkommensteuergesetz 
                i.V.m. Abgabenordnung für die Aufbewahrung steuerlich relevanter Unterlagen, Infektionsschutzgesetz)
            </li>
            <li>
                <b>Kollektivvereinbarungen</b> (z. B. Konzern-Betriebsvereinbarungen)
            </li>
            <li>
                <b>Schutz lebenswichtiger Interessen</b> (Art. 6 Abs. 1 lit. d DGSVO) (z. B. Kontaktlisten bei Pandemien)
            </li>
            <li>
                ein <b>berechtigtes, abgewogenes Interesse</b> (Art. 6 Abs. 1 lit. f DSGVO)
            </li>
        </ul>

        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Unsere berechtigten Interessen liegen u. a. in der Erleichterung bzw. der Ermöglichung der Zusammenarbeit 
            innerhalb des Konzerns, in der Kommunikation mit den einzelnen 
            <Link href={"https://volkswagen-net.de/wikis/display/SCHUTZPBD/Konzerngesellschaften"}>Konzerngesellschaften</Link>
            sowie in der Ausübung der Funktion als Konzernmutter. Des Weiteren haben wir ein berechtigtes Interesse 
            daran, Arbeitsergebnisse zu dokumentieren, abzusichern und die Zusammenarbeit zwischen 
            den einzelnen Gesellschaften zu stärken.
        </Typography>

        {/* SECTION D */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            D. Weitergabe Ihrer personenbezogenen Daten an Dritte
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            In einzelnen Fällen geben wir Ihre Daten an befugte Dritte weiter. Dies können sein:
        </Typography>
        <ul className={classes.list}>
            <li>
                <Link href={"https://volkswagen-net.de/wikis/display/SCHUTZPBD/Auftragsverarbeiter"}><b>Auftragsverarbeiter</b></Link> 
                (z. B. im Rahmen der Enterprise Help Desk Deutschland Betreuung an IT-Dienstleister)
            </li>
            <li>
                <Link href={"https://volkswagen-net.de/wikis/display/SCHUTZPBD/Konzerngesellschaften"}><b>Konzerngesellschaften</b></Link> 
                (z. B. in Protokollen)
            </li>
            <li>
                <b>öffentliche Stellen</b> (z. B. Finanzverwaltung im Rahmen von Auskunftsersuchen und steuerlichen 
                Außenprüfungen, Ermittlungsbehörden und Gerichte im Rahmen behördlicher/gerichtlicher Maßnahmen zu Zwecken 
                der Beweiserhebung, Strafverfolgung und Durchsetzung zivilrechtlicher Ansprüche
            </li>
            <li>
                <b>Dienstleister</b> (z. B. im Rahmen von Dienstreisen an Hotels, Fluggesellschaften)
            </li>
        </ul>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Volkswagen AG beachtet bei Datenübermittlungen in sog. Drittstaaten (solche Länder, die weder Mitglied 
            der Europäischen Union noch des Europäischen Wirtschaftsraums sind) strikt die einschlägigen 
            datenschutzrechtlichen Vorgaben. So übermittelt die Volkswagen AG nur dann Daten an 
            <Link href={"https://volkswagen-net.de/wikis/pages/viewpage.action?pageId=1253739783"}>Empfänger</Link>
            (z. B. IT-Dienstleister) in Drittstaaten, wenn für den jeweiligen Drittstaat ein Angemessenheitsbeschluss 
            der EU Kommission besteht oder die Datenübermittlungen durch den Abschluss von durch die EU-Kommission erlassene 
            <Link href={"https://volkswagen-net.de/wikis/pages/viewpage.action?pageId=1253739783"}>EU-Standardvertragsklauseln</Link>
            mit dem Empfänger der Daten angemessen abgesichert sind. Die verwendeten EU-Standardvertragsklauseln 
            können Sie über die URL 
            <Link href={"https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=CELEX%3A32010D0087"}>
                https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=CELEX%3A32010D0087
            </Link>
            abrufen.
        </Typography>

        {/* SECTION E */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            E. Aufbewahrungs- und Löschfristen für Ihre personenbezogenen Daten
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Ihre 
            <Link href={"https://volkswagen-net.de/wikis/display/SCHUTZPBD/Personenbezogene+Daten"}>personenbezogenen Daten</Link>
            werden nur solange verarbeitet und gespeichert, wie der jeweilige Zweck besteht oder ein Gesetz dies vorschreibt.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Im Folgenden finden Sie eine auszughafte Übersicht der Fristen, nach denen wir Ihre personenbezogenen Daten 
            nach Zweckentfall löschen:
        </Typography>
        <ul className={classes.list}>
            <li>
                <b>Bis zu 180 Tage:</b> Daten zu persönlichen/beruflichen Verhältnissen und Merkmalen 
                (z. B. bei Bewerbungsunterlagen)
            </li>
            <li>
                <b>2 bis 4 Jahre:</b> besonders sensible Daten im Rahmen des Arbeitsrechts/der Arbeitsordnung 
                (z. B. Verwarnungen oder Verweise) sowie Zeitwirtschaftsdaten (z. B. Bescheinigungen an Behörden)
            </li>
            <li>
                <b>10 Jahre:</b> Daten zu persönlichen/beruflichen Verhältnissen und Merkmalen sowie berufliche Kontakt 
                und (Arbeits-) Organisationsdaten im Rahmen der Einstellung, Personalbetreuung und -entwicklung 
                (z. B. Berufsbezeichnung, beruflicher Werdegang, Tätigkeiten, Qualifikationen, Bewertungen/ Beurteilungen) 
                sowie besonders sensible personenbezogene Daten (z. B. Dokumentationen von Arbeitsunfällen)
            </li>
            <li>
                <b>15 Jahre:</b> Bank- und Bonitätsdaten sowie Entgeltdaten (z. B. Lohnsteuerklasse, 
                Bankverbindung, Sonderzahlungen)
            </li>
        </ul>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Unter bestimmten Umständen müssen Daten länger aufbewahrt werden, z.B. wenn im Zusammenhang mit einem 
            behördlichen oder gerichtlichen Verfahren ein sog. Legal Hold oder Litigation Hold (d.h. ein Verbot 
            der Datenlöschung für die Dauer des Verfahren) angeordnet wird.
        </Typography>

        {/* SECTION F */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            F. Ihre Rechte
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Ihre nachfolgenden Rechte können Sie gegenüber der Volkswagen AG jederzeit unentgeltlich geltend machen. 
            Weitere Informationen zur Wahrnehmung Ihrer Rechte finden Sie unter Abschnitt G.
        </Typography>
        <ul className={classes.list}>
            <li>
                <b>Auskunftsrecht:</b> Sie haben das Recht, von uns 
                <Link href={"https://volkswagen-net.de/wikis/pages/viewpage.action?pageId=1253739757"}>Auskunft</Link>
                über die 
                <Link href={"https://volkswagen-net.de/wikis/display/SCHUTZPBD/Verarbeitung"}>Verarbeitung</Link> 
                Ihrer 
                <Link href={"https://volkswagen-net.de/wikis/display/SCHUTZPBD/Personenbezogene+Daten"}>personenbezogenen Daten</Link> 
                zu erhalten.
            </li>
            <li>
                <b>Berichtigungsrecht:</b> Sie haben das Recht, von uns die Berichtigung Sie betreffender unrichtiger 
                bzw. unvollständiger personenbezogener Daten zu verlangen.
            </li>
            <li>
                <b>Recht auf Löschung:</b> Sie haben das Recht, bei Vorliegen der in Art. 17 DSGVO genannten 
                Voraussetzungen, die Löschung Ihrer Daten zu verlangen. Danach können Sie beispielsweise die Löschung 
                Ihrer Daten verlangen, soweit diese für die Zwecke, für die sie erhoben wurden, nicht mehr notwendig sind. 
                Außerdem können Sie Löschung verlangen, wenn wir Ihre Daten auf der Grundlage Ihrer Einwilligung 
                verarbeiten und Sie diese 
                <Link href={"https://volkswagen-net.de/wikis/display/SCHUTZPBD/Einwilligung"}>Einwilligung</Link>
                widerrufen.
            </li>
            <li>
                <b>Recht auf Einschränkung der Verarbeitung:</b> Sie haben das Recht, die Einschränkung der Verarbeitung 
                Ihrer Daten zu verlangen, wenn die Voraussetzungen des Art. 18 DSGVO vorliegen. Dies ist beispielsweise 
                der Fall, wenn Sie die Richtigkeit Ihrer Daten bestreiten. Für die Dauer der Überprüfung der Richtigkeit 
                der Daten können Sie dann die Einschränkung der Verarbeitung verlangen.
            </li>
            <li>
                <b>Recht auf Datenübertragbarkeit:</b> Sofern die Datenverarbeitung auf der Grundlage eine Einwilligung 
                oder einer Vertragserfüllung beruht und diese zudem unter Einsatz einer automatisierten Verarbeitung erfolgt, 
                haben Sie das Recht, Ihre Daten in einem strukturierten, gängigem und maschinenlesbaren Format 
                zu erhalten und diese an einen anderen Datenverarbeiter zu übermitteln.
            </li>
            <li style={{ border: '1px solid black' }}>
                <b>Widerspruchsrecht:</b> Sofern die Verarbeitung auf einem überwiegenden Interesse oder Ihre Daten 
                zum Zwecke der Direktwerbung genutzt werden, haben Sie das Recht, der Verarbeitung Ihrer Daten 
                zu widersprechen. Im Falle des 
                <Link href={"https://volkswagen-net.de/wikis/pages/viewpage.action?pageId=1253739849"}>Widerspruchs</Link>
                bitten wir Sie, uns Ihre Gründe mitzuteilen, aus denen Sie der Datenverarbeitung widersprechen. 
                Daneben haben Sie das Recht, der Datenverarbeitung zu Zwecken der Direktwerbung zu widersprechen. 
                Das gilt auch für 
                <Link href={"https://volkswagen-net.de/wikis/display/SCHUTZPBD/Profiling"}>Profiling Trigger</Link>
                , soweit es mit der Direktwerbung zusammenhängt.
            </li>
            <li>
                <b>Widerrufsrecht:</b> Sofern die Datenverarbeitung auf einer Einwilligung beruht, haben Sie das Recht, 
                die Datenverarbeitung im Rahmen einer Einwilligung mit Wirkung für die Zukunft jederzeit kostenlos 
                zu widerrufen.
            </li>
            <li>
                <b>Beschwerderecht:</b> Sie haben außerdem das Recht, sich bei einer 
                <Link href={"https://volkswagen-net.de/wikis/pages/viewpage.action?pageId=1253739755"}>Aufsichtsbehörde</Link>
                (z. B. bei der Landesbeauftragten für den Datenschutz Niedersachsen) über unsere Verarbeitung 
                Ihrer Daten zu beschweren.
            </li>
        </ul>

        {/* SECTION G */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            G. Ihre Ansprechpartner
        </Typography>
        <ul className={classes.list}>
            <li>
                <Typography variant="h5" marginTop={'8px'} align='left'>
                    Ansprechpartner für die Ausübung Ihrer Rechte
                </Typography>
                <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
                    Die Ansprechpartner für die Ausübung Ihrer Rechte und weitergehende
                    Informationen finden Sie auf der folgenden Webseite:
                </Typography>
                <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
                    <Link href={"https://datenschutz.volkswagen.de/"}>datenschutz.volkswagen.de</Link>
                </Typography>
            </li>
            <li>
                <Typography variant="h5" marginTop={'8px'} align='left'>
                    Datenschutzbeauftragter
                </Typography>
                <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
                    Unser Datenschutzbeauftragter steht Ihnen als Ansprechpartner für
                    datenschutzbezogene Anliegen zur Verfügung:
                </Typography>
                <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
                    <div>Datenschutzbeauftragter der Volkswagen AG</div> 
                    <div>Berliner Ring 2</div>
                    <div>38440 Wolfsburg</div>
                </Typography>
                <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
                    <a href="mailto:datenschutz@volkswagen.de">datenschutz@volkswagen.de</a>
                </Typography>
            </li>
        </ul>

        {/* Stand */}
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'}>
            Datum: <b>08.03.2021</b>
        </Typography>
    </>)
}

export default EmployeesVWGroupDE;