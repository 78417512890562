import React from 'react';
import { Box, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Icon from '../../../../assets/icons/Icon.js';
import { petrol800, buttonPressedPetrol800, buttonHoverPetrol800 } from '../MuiStyles.js';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        padding: '16px'
    },
    button: {
        width: '155px',
        '&:hover': {
            backgroundColor: `${buttonHoverPetrol800} !important`,
        },
    },
    filter_number: {
        marginRight: '8px',
        height: '24px',
        minWidth: '24px',
        borderRadius: '24px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '0.8px',
        backgroundColor: '#006384',
        color: '#FFFFFF',
    },
    icon: {
        paddingRight: '8px',
        display: 'flex',
        flexDirection: 'column'
    },
}
));

function FilterButton(props) {
    const { filterState, activecount, onClick } = props;

    const classes = useStyles();
    return (
        <Button
            variant="outlined"
            color="secondary"
            onClick={onClick}
            className={classes.button}
            style={filterState ? {} : { backgroundColor: buttonPressedPetrol800 }}
        >
            {activecount < 1 ?
                <div className={classes.icon} data-testid="filtericon">
                    <Icon iconName={'FilterIcon'} fill={petrol800} />
                </div>
                :
                <Box data-testid="filteramount" display="flex" justifyContent="center" alignItems="center" className={classes.filter_number}>
                    {activecount}
                </Box>
            }
            {filterState ? "Show Filter" : "Hide Filter"}
        </Button>
    )
}


export default FilterButton;