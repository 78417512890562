import React, { useState, useEffect } from 'react';
import { Paper, Grid, Box, Typography, Button, IconButton } from '@mui/material';
import { Table as MUITable, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import TestCase from './TestCase';
import { useStyles } from './Table.styles';
import Counter from '../../other/helper/Counter/Counter';
import { handleCategoryIcon } from '../../other/helper/IconHandler/IconHandler';
import FilterButton from '../../other/helper/FilterButton/FilterButton';
import Icon from '../../../assets/icons/Icon.js';
import TableFilter, { upDateFilter } from '../table_utils/templates/TableFilter';
import CollapseRow from '../table_utils/CollapseRow';
import { dynamicSort } from '../../other/helper/globalFunctions/globalFunctions';
import { timeConverterSorting } from '../../other/helper/timeConverter/timeConverter';
import { grey1000, petrol800, buttonPressedPetrol800, grey400, grey800 } from '../../other/helper/MuiStyles';
import BatteryLoader from '../../other/helper/BatteryLoader/BatteryLoader';
import { useReactiveVar } from '@apollo/client';
import { isTestCasesLoading } from '../../../operations/localeStorage';

const HeaderItem = ({ label, isSorted, handleClick }) => {
    return (
        <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'flex-start' }}>
            <span>{label}</span>
            <IconButton 
                style={
                    isSorted === true 
                        ? { marginLeft: '4px', padding: '4px' } 
                        : { transform: 'rotate(-180deg)', marginLeft: '4px', padding: '4px' }
                    } 
                onClick={() => { handleClick() }}
            >
                    <Icon iconName={"DirectionIcon"} width={14} height={14} fill={grey800} />
            </IconButton>
        </div>
    )
}

function Table(props) {
    const {
        categoryTests,
        testCaseList,
        id,
        agingStatus,
        testStatuses,
        testInstances,
        testResults,
        isLocked
    } = props;

    const testCaseLoading = useReactiveVar(isTestCasesLoading);

    let sizeWindow;

    switch (true) {
        case window.innerWidth < 1576:
            sizeWindow = 4;
            break;
        case window.innerWidth < 1872:
            sizeWindow = 5;
            break;
        case window.innerWidth < 2168:
            sizeWindow = 6;
            break;
        case window.innerWidth < 2464:
            sizeWindow = 7;
            break;
        case window.innerWidth < 2760:
            sizeWindow = 8;
            break;
        case window.innerWidth >= 2760:
            sizeWindow = 9;
            break;
        default:
            sizeWindow = 4;
            break;
    }

    const [edit, setEdit] = useState(false);
    const [currentEditTestCaseId, setCurrentEditTestCaseId] = useState(0);

    const [showFilters, setShowFilters] = useState(false);
    const [allTestCases, setAllTestCases] = useState(0);
    const [collapsed, setCollapsed] = useState(false);

    const [activeTestInstances, setActiveTestInstances] = useState([]);
    const [activeTestStatuses, setActiveTestStatuses] = useState([]);
    const [activeTestResults, setActiveTestResults] = useState([]);
    const [activeTestCases, setActiveTestCases] = useState([]);

    // SORTING VARIABLES
    const [testCaseKey, setTestCaseKey] = useState(true);
    const [sortTestSpecificationTestCases, setSortTestSpecificationTestCases] = useState(true);
    const [sortAgingStatusTestCases, setSortAgingStatusTestCases] = useState(true);
    const [sortTestInstanceTestCases, setSortTestInstanceTestCases] = useState(true);
    const [sortTestStatusTestCases, setSortTestStatusTestCases] = useState(true);
    const [sortTestResultTestCases, setSortTestResultTestCases] = useState(true);
    const [sortStartDateTestCases, setSortStartDateTestCases] = useState(true);
    const [sortEndDateTestCases, setSortEndDateTestCases] = useState(true);

    const handleTestInstanceClick = (event, testInstance) => {
        setActiveTestInstances(testInstance);
    };

    const handleTestStatusClick = (event, testStatus) => {
        setActiveTestStatuses(testStatus);
    };

    const handleTestResultClick = (event, testResult) => {
        setActiveTestResults(testResult);
    };

    const handleSortingClick = (sortingProperty) => {
        switch (sortingProperty) {
            case "testSpecification":
                activeTestCases.sort(dynamicSort(sortTestSpecificationTestCases === true ? "name" : "-name", "testSpecification"));
                setSortTestSpecificationTestCases(!sortTestSpecificationTestCases);
                setSortAgingStatusTestCases(true);
                setSortTestInstanceTestCases(true);
                setSortTestStatusTestCases(true);
                setSortTestResultTestCases(true);
                setSortStartDateTestCases(true);
                setSortEndDateTestCases(true);
                setTestCaseKey(!testCaseKey);
                break;
            case "agingStatus":
                activeTestCases.sort(dynamicSort(sortAgingStatusTestCases === true ? "name" : "-name", "agingStatus"));
                setSortTestSpecificationTestCases(true);
                setSortAgingStatusTestCases(!sortAgingStatusTestCases);
                setSortTestInstanceTestCases(true);
                setSortTestStatusTestCases(true);
                setSortTestResultTestCases(true);
                setSortStartDateTestCases(true);
                setSortEndDateTestCases(true);
                setTestCaseKey(!testCaseKey);
                break;
            case "testInstance":
                activeTestCases.sort(dynamicSort(sortTestInstanceTestCases === true ? "name" : "-name", "testInstance"));
                setSortTestSpecificationTestCases(true);
                setSortAgingStatusTestCases(true);
                setSortTestInstanceTestCases(!sortTestInstanceTestCases);
                setSortTestStatusTestCases(true);
                setSortTestResultTestCases(true);
                setSortStartDateTestCases(true);
                setSortEndDateTestCases(true);
                setTestCaseKey(!testCaseKey);
                break;
            case "testStatus":
                activeTestCases.sort(dynamicSort(sortTestStatusTestCases === true ? "name" : "-name", "testStatus"));
                setSortTestSpecificationTestCases(true);
                setSortAgingStatusTestCases(true);
                setSortTestInstanceTestCases(true);
                setSortTestStatusTestCases(!sortTestStatusTestCases);
                setSortTestResultTestCases(true);
                setSortStartDateTestCases(true);
                setSortEndDateTestCases(true);
                setTestCaseKey(!testCaseKey);
                break;
            case "startDate":
                sortStartDateTestCases === true ? activeTestCases.sort(function (a, b) { return timeConverterSorting(a.startDate) - timeConverterSorting(b.startDate); }) : activeTestCases.sort(function (a, b) { return timeConverterSorting(b.startDate) - timeConverterSorting(a.startDate); });
                setSortTestSpecificationTestCases(true);
                setSortAgingStatusTestCases(true);
                setSortTestInstanceTestCases(true);
                setSortTestStatusTestCases(true);
                setSortTestResultTestCases(true);
                setSortStartDateTestCases(!sortStartDateTestCases);
                setSortEndDateTestCases(true);
                setTestCaseKey(!testCaseKey);
                break;
            case "endDate":
                sortEndDateTestCases === true ? activeTestCases.sort(function (a, b) { return timeConverterSorting(a.endDate) - timeConverterSorting(b.endDate); }) : activeTestCases.sort(function (a, b) { return timeConverterSorting(b.endDate) - timeConverterSorting(a.endDate); });
                setSortTestSpecificationTestCases(true);
                setSortAgingStatusTestCases(true);
                setSortTestInstanceTestCases(true);
                setSortTestStatusTestCases(true);
                setSortTestResultTestCases(true);
                setSortStartDateTestCases(true);
                setSortEndDateTestCases(!sortEndDateTestCases);
                setTestCaseKey(!testCaseKey);
                break;
            case "testResult":
                activeTestCases.sort(dynamicSort(sortTestResultTestCases === true ? "name" : "-name", "testResult"));
                setSortTestSpecificationTestCases(true);
                setSortAgingStatusTestCases(true);
                setSortTestInstanceTestCases(true);
                setSortTestStatusTestCases(true);
                setSortTestResultTestCases(!sortTestResultTestCases);
                setSortStartDateTestCases(true);
                setSortEndDateTestCases(true);
                setTestCaseKey(!testCaseKey);
                break;
            default:
                break;
        }
    }

    const classes = useStyles();

    //inital load of testCases in activeTestCase array
    useEffect(() => {
        setActiveTestCases(testCaseList?.testCases);
        setSortTestSpecificationTestCases(true);
        setSortAgingStatusTestCases(true);
        setSortTestInstanceTestCases(true);
        setSortTestStatusTestCases(true);
        setSortTestResultTestCases(true);
        setSortStartDateTestCases(true);
        setSortEndDateTestCases(true);
    }, [testCaseList]); // eslint-disable-line

    //update activeTestCase array if changes on filters occur
    useEffect(() => {
        upDateFilter(setActiveTestCases, testCaseList, activeTestInstances, testInstances, activeTestStatuses, testStatuses, activeTestResults, testResults);
    }, [activeTestInstances, activeTestResults, activeTestStatuses, testCaseList, testInstances, testResults, testStatuses]); // eslint-disable-line

    return testCaseList?.testCases?.length > 0 &&
        <Grid container spacing={3} id={categoryTests?.category?.name} data-testid={"test-case-section"}>
            <Grid item xs={12} display="grid">
                <Paper className={classes.paper}>

                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Box display="flex" alignItems="center"><div style={{ display: 'flex', alignItems: 'center', paddingLeft: sizeWindow < 5 ? '8px' : '12px', paddingRight: sizeWindow < 5 ? '8px' : '12px' }}><Icon fill={grey1000} iconName={handleCategoryIcon(categoryTests?.category?.name)} /></div></Box>
                                <Typography variant="h3" className={classes.title}>
                                    {categoryTests?.category?.name}
                                </Typography>
                            </Box>
                            <Box m={2}>
                                <Counter number={activeTestCases?.length} />
                            </Box>
                        </Box>

                        <Box py={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                            <FilterButton
                                filterState={!showFilters}
                                activecount={(activeTestInstances?.length + activeTestStatuses?.length + activeTestResults?.length)}
                                onClick={() => setShowFilters(!showFilters)}
                            />
                            <div className={classes.seperator}></div>
                            <Button
                                variant={"outlined"}
                                color={"secondary"}
                                data-testid={"test-case-edit-button"}
                                onClick={() => setEdit(!edit)}
                                disabled={isLocked}
                                style={!edit ? { marginRight: '24px' } : { marginRight: '24px', backgroundColor: buttonPressedPetrol800 }}
                            >
                                <div style={{ display: 'flex', marginRight: '8px' }}>
                                    <Icon iconName={"EditIcon"} fill={isLocked ? grey400 : petrol800} />
                                </div>
                                {!edit ? 'Edit' : 'Finish'}
                            </Button>
                        </Box>
                    </Box>
                    {showFilters &&
                        <TableFilter
                            activeTestInstances={activeTestInstances}
                            activeTestStatuses={activeTestStatuses}
                            activeTestResults={activeTestResults}
                            handleTestInstanceClick={handleTestInstanceClick}
                            handleTestStatusClick={handleTestStatusClick}
                            handleTestResultClick={handleTestResultClick}
                            agingStatus={agingStatus}
                            testInstances={testInstances}
                            testStatuses={testStatuses}
                            testResults={testResults}
                            table
                        />
                    }
                    <Box className={classes.head} />

                    <TableContainer>
                        <MUITable className={classes.devtable} >
                            <TableHead>
                                
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <HeaderItem 
                                            label="Test Specification"
                                            isSorted={sortTestSpecificationTestCases}
                                            handleClick={ () => { handleSortingClick("testSpecification") } }
                                        />
                                    </TableCell>
                                    <TableCell>Conditions</TableCell>
                                    <TableCell>DUTs</TableCell>
                                    <TableCell>
                                        <HeaderItem 
                                            label={sizeWindow > 6 ? 'Aging Status' : 'Aging'}
                                            isSorted={sortAgingStatusTestCases}
                                            handleClick={ () => { handleSortingClick("agingStatus") } }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <HeaderItem 
                                            label="Test Instance"
                                            isSorted={sortTestInstanceTestCases}
                                            handleClick={ () => { handleSortingClick("testInstance") } }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <HeaderItem 
                                            label="Test Status"
                                            isSorted={sortTestStatusTestCases}
                                            handleClick={ () => { handleSortingClick("testStatus") } }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <HeaderItem 
                                            label={sizeWindow > 4 ? 'Planned Start Date' : 'Planned Start'}
                                            isSorted={sortStartDateTestCases}
                                            handleClick={ () => { handleSortingClick("startDate") } }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <HeaderItem 
                                            label={sizeWindow > 4 ? 'Actual Start Date' : 'Actual Start'}
                                            isSorted={sortStartDateTestCases}
                                            handleClick={ () => { handleSortingClick("startDate") } }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <HeaderItem 
                                            label={sizeWindow > 4 ? 'Planned End Date' : 'Planned End'}
                                            isSorted={sortEndDateTestCases}
                                            handleClick={ () => { handleSortingClick("endDate") } }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <HeaderItem 
                                            label={sizeWindow > 4 ? 'Actual End Date' : 'Actual End'}
                                            isSorted={sortEndDateTestCases}
                                            handleClick={ () => { handleSortingClick("endDate") } }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <HeaderItem 
                                            label="Test Result"
                                            isSorted={sortTestResultTestCases}
                                            handleClick={ () => { handleSortingClick("testResult") } }
                                        />
                                    </TableCell>
                                    <TableCell>{edit ? "" : "Comments"}</TableCell>
                                </TableRow>

                            </TableHead>
                            <TableBody>
                        
                            {testCaseLoading &&
                                <TableRow>
                                    <TableCell align="center" colSpan={13}>
                                        <Box display="flex" alignItems="center" justifyContent="center"><BatteryLoader type="small" /></Box>
                                    </TableCell>
                                </TableRow>
                            }
                            {!testCaseLoading && activeTestCases?.filter((item, idx) => idx < 3 + allTestCases).map((test) => {
                                return (
                                    <TestCase
                                        currentEditTestCaseId={currentEditTestCaseId}
                                        setCurrentEditTestCaseId={setCurrentEditTestCaseId}
                                        isLocked={isLocked}
                                        activeTestCases={activeTestCases}
                                        key={test.testCaseId}
                                        testCaseId={test.testCaseId}
                                        projectId={id}
                                        categoryId={categoryTests?.category?.name}
                                        test={test}
                                        edit={edit}
                                        agingStatus={agingStatus}
                                        testInstances={testInstances}
                                        testStatuses={testStatuses}
                                        testResults={testResults}
                                        sizeWindow={sizeWindow}
                                    />
                                )
                            })}

                            </TableBody>
                        </MUITable>
                    </TableContainer>

                    {3 < activeTestCases?.length &&
                        <CollapseRow collapsed={collapsed} setAllTestCases={setAllTestCases} setCollapsed={setCollapsed} allTestCases={allTestCases} testCaseList={testCaseList} sizeWindow={sizeWindow} />
                    }
                    {activeTestCases?.length < 1 &&
                        <Box className={classes.border}>
                            <Box display="flex" alignItems="center" justifyContent="center" style={{ lineHeight: '48px', color: grey800 }}>No test case available for currently selected filters.</Box>
                        </Box>
                    }
                </Paper>
            </Grid>
        </Grid>
}

export default Table;