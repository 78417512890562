import { Box, Button, InputBase, InputLabel, Typography } from "@mui/material";
import React, {useContext, useState} from "react";
import { useStyles } from './TestCaseDetails.styles.js';
import {
    SnackbarContext, SnackbarType
} from '../other/contexts/SnackBarContext';
import { currentIndexId, projectRole } from '../../operations/localeStorage';
import Counter from "../other/helper/Counter/Counter";
import { IMAGE_MAP } from "../other/helper/ImgMapper/ImgMapper";
import { timeConverterComment } from "../other/helper/timeConverter/timeConverter";
import DeleteComment from "../other/dialogs/DeleteComment/DeleteComment";
import { useMutation } from "@apollo/client";
import { CREATECOMMENT, DELETECOMMENT } from "../../operations/mutations/mutations.js";
import { TESTCASE, TESTCASES } from "../../operations/queries/query.js";


export default function CommentSection({ testCaseData, testCaseId, isLocked }) {
    const role = projectRole();
    const { setIsSnackbarOpen, setMessage, setSnackbarType } = useContext(SnackbarContext);

    const handleOnCompleted = async () => {
        setSnackbarType(SnackbarType.SUCCESS);
        setIsSnackbarOpen(true);
    }

    const handleOnError = async (error) => {
        setMessage(error.message);
        setSnackbarType(SnackbarType.ERROR);
        setIsSnackbarOpen(true);
    }

    const [createComment] = useMutation(CREATECOMMENT, {
        onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: TESTCASE(testCaseId) }, { query: (currentIndexId() === undefined || currentIndexId() === -1) ? '' : TESTCASES(currentIndexId()) }], awaitRefetchQueries: true
    });
    const [deleteComment] = useMutation(DELETECOMMENT, {
        onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: TESTCASE(testCaseId) }, { query: (currentIndexId() === undefined || currentIndexId() === -1) ? '' : TESTCASES(currentIndexId()) }], awaitRefetchQueries: true
    });


    const [newComment, setNewComment] = useState('');

    const handleCreateNewComment = (e) => {
        e.preventDefault();
        createComment({ variables: { testCaseId: testCaseId, comment: newComment } });
        setNewComment('');
        setMessage('Comment has been added');
    };

    const classes = useStyles();

    return (
        <Box display="flex" flexDirection="column" >
            <Box mb={2} display="flex">
                <Box display="flex" flexDirection="column" justifyContent="center">
                    <Typography variant="h3">Comments</Typography>
                </Box>
                <Box ml={1}>
                    <Counter number={testCaseData?.testCase?.commentCount} />
                </Box>
            </Box>
            {(testCaseData?.testCase?.comments?.length === 0) &&
                <div className={classes.comment_wrapper}>
                    <div className={classes.tbd_text} style={{ marginLeft: '0' }}>
                        No comments yet
                    </div>
                </div>
            }
            {(testCaseData?.testCase?.comments?.length > 0) &&
                <div className={classes.comment_wrapper}>
                    {testCaseData?.testCase?.comments.slice(0).map((com, i, arr) => {
                        return (
                            <div key={com.commentId} className={classes.comment_box} style={i < arr.length - 1 ? { marginBottom: '16px' } : {}}>
                                <Box display="flex" justifyContent="space-between" alignItems="center" style={{ minHeight: '40px' }}>
                                    <Box display="flex" alignItems="center">
                                        <div className={classes.userimage}>
                                            <img src={IMAGE_MAP.AVATAR[com.user.avatarId]?.pngSrc} alt="avatar" />
                                        </div>
                                        <Typography variant="h4" className={classes.comment_user}>
                                            {com.user.firstName} {com.user.lastName}
                                        </Typography>
                                        <Typography variant="h5" className={classes.comment_timestamp}>
                                            {timeConverterComment(com.timestamp)}
                                        </Typography>
                                    </Box>

                                    {(role === 'Administrator' && !isLocked) &&
                                        <DeleteComment
                                            comment={com}
                                            mutationFunction={deleteComment}
                                        />
                                    }
                                </Box>

                                <Typography variant="h4" className={classes.comment_text}>
                                    {com.comment}
                                </Typography>
                            </div>
                        )
                        // }
                    })}
                </div>
            }
            {(role === 'Administrator' || role === 'Project User') &&
                <>
                    <InputLabel className={classes.input_title}>New Comment</InputLabel>
                    <InputBase
                        data-testid={"new-comment-input"}
                        name="comment"
                        className={classes.newComment}
                        multiline={true}
                        fullWidth={true}
                        value={newComment}
                        onChange={event => setNewComment(event.target.value)}
                        disabled={isLocked}
                        style={newComment?.length > 0 ? { fontWeight: '700' } : { fontWeight: '400' }}
                    />
                    {newComment?.length > 255 &&
                        <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 255 characters.</Typography>
                    }
                    <Box display="flex" justifyContent="flex-end" mt={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={newComment.length < 1 || newComment.length > 255 || isLocked}
                            className={classes.comment_button}
                            onClick={handleCreateNewComment}
                        >
                            Add Comment
                        </Button>
                    </Box>
                </>
            }
        </Box>
    )

}