import makeStyles from '@mui/styles/makeStyles';
import { grey1000, grey600 } from '../../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    header: {
        height: '32px',
    },
    category: {        
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        margin:'0 0 0 8px'
    }, 
    grey_title: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '18px',
        letterSpacing: '0.8px',
        color: grey600,
    },
    black_value: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '18px',
        letterSpacing: '0.8px',
        color: grey1000,
    },    
    category_icon: {
        height: '24px',
        width: '24px',
        fill: grey1000,
    },
}));