import makeStyles from '@mui/styles/makeStyles';
import { petrol1000, grey1000, signalRed800, grey100, grey400, grey600, buttonHoverPetrol800 } from '../../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    root: {
        padding: '24px',
        backgroundColor: petrol1000,
    },
    paper: {
        margin: "auto",
        minWidth: "870px",
        maxWidth: "870px",
        minHeight: 'calc(100vh - 64px - 24px - 24px)',
        backgroundColor: '#FFF',
    },
    hover_blue: {
        '&:hover': {
            backgroundColor: buttonHoverPetrol800,
          },
    },
    snackbar: {
      position: 'sticky',
      height: '100%',
      top: '0',
      zIndex: '1' 
    },
    supplierimage: {
        backgroundImage: props => `url(${props.supplierImg})`,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '40px',
        maxWidth: '120px',
        width: '80px',
        display: 'flex',
        marginLeft: '155.5px',
    },
    cell_title: {
        fontWeight: '400',
        paddingLeft: '8px',
        lineHeight: '40px',
    },
    required_tag: {
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '18px',
        letterSpacing: '0.8px',
        color: grey600,
        marginLeft: '24px',
    },
    box: {
        width: '391px',
        textAlign: 'left',
    },
    box_freeText: {
        width: '391px',
        textAlign: 'left',
    },
    box_long: {
        width: '806px',
        textAlign: 'left',
    },
    input_title: {
        marginBottom: '4px',
    },
    last_grid: {
        paddingBottom: '20px',
    },
    platforms: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    platform: {
        backgroundColor: grey100,
        marginRight: '16px',
        height: '30px',
        marginBottom: '4px',
        fontSize: '16px',
        letterSpacing: '0.8px',
        fontWeight: '400',
        color: grey1000,
    },
    freeText: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '28px',
        letterSpacing: '0.3px',
        padding: '5px 24px 5px 8px',
        border: `1px solid ${grey400}`,
        boxSizing: "border-box",
        borderRadius: "2px",
    },
    select_box: {
        height: '40px',
    },
    multiple_select_box: {
        width: '391px',
        height: 'auto !important',
        "& #mui-component-select-platforms": {
            paddingTop: '0px !important',
            paddingBottom: '0px !important',
        },
    },
    left_select_box: {
        width: '391px',
        height: '40px',
        marginLeft: '32px',
        marginRight: '24px',
        textAlign: 'left',
    },
    right_select_box: {
        width: '347px',
        height: '40px',
        textAlign: 'left',
    },
    assignment_box: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '24px',
    },
    position_trash_icon: {
        padding: '8px',
        marginLeft: '12px',
    },    
    error_text: {
        marginTop: '8px',
        fontWeight: '400',
    },
    error_type: {
        padding: '0px 24px',
        textAlign: 'center',
        marginTop: '4px',
        fontWeight: '400',
        color: grey600,
    },
    alert_message: {
        marginTop: '4px',
        color: signalRed800,
    },
}));
