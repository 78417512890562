import { InputAdornment, InputBase } from "@mui/material"
import { Box } from "@mui/system";
import { useStyles } from './CellDataSheet.styles.js';
import React, { useContext } from "react";
import { CDSContext } from "../../other/contexts/CDSContext";
import { grey800 } from "../../other/helper/MuiStyles.js";
import SimulatedButton from "../../other/tooltips/SimulatedButton/SimulatedButton.js";
import { projectRole } from "../../../operations/localeStorage.js";
import { getUpdatedCDSMutationValues, isValid } from "./CDSUtils.js";

export default function CDSTableRow({ socs, cdsIndex, tempIndex, cdsValues, setCdsValues, cdsMutationValues, setCdsMutationValues, isCDSLocked }) {

    const { currentPowerOCV } = useContext(CDSContext);
    const { currentisCharged } = useContext(CDSContext);
    const { currentPowerUnit } = useContext(CDSContext);

    const role = projectRole();

    const handleMatrixValueChange = (event, index2, soc) => {
        let matrix = JSON.parse(JSON.stringify(cdsValues));

        // FIXME: inplement access to values in a better way: index access will fail when backend starts to return data in different order
        if (currentPowerOCV === 'OCV' && currentisCharged) {
            matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[2 * index2].value = event.target.value;
            insertMatrixValue(event.target.value, soc);
        }
        if (currentPowerOCV === 'OCV' && !currentisCharged) {
            matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[2 * index2 + 1].value = event.target.value;
            insertMatrixValue(event.target.value, soc);
        }
        if (currentPowerOCV === 'POWER_LIMITS' && currentisCharged && currentPowerUnit === 'MILLI_OHM') {
            matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[(6 * index2)].value = event.target.value;
            insertMatrixValue(event.target.value, soc);
        }
        if (currentPowerOCV === 'POWER_LIMITS' && !currentisCharged && currentPowerUnit === 'MILLI_OHM') {
            matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[(6 * index2) + 1].value = event.target.value;
            insertMatrixValue(event.target.value, soc);
        }
        if (currentPowerOCV === 'POWER_LIMITS' && currentisCharged && currentPowerUnit === 'WATT') {
            matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[(6 * index2) + 2].value = event.target.value;
            insertMatrixValue(event.target.value, soc);
        }
        if (currentPowerOCV === 'POWER_LIMITS' && !currentisCharged && currentPowerUnit === 'WATT') {
            matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[(6 * index2) + 3].value = event.target.value;
            insertMatrixValue(event.target.value, soc);
        }
        if (currentPowerOCV === 'POWER_LIMITS' && currentisCharged && currentPowerUnit === 'AMPERE') {
            matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[(6 * index2) + 4].value = event.target.value;
            insertMatrixValue(event.target.value, soc);
        }
        if (currentPowerOCV === 'POWER_LIMITS' && !currentisCharged && currentPowerUnit === 'AMPERE') {
            matrix.cellDataSheet[cdsIndex].temperatures[tempIndex].socs[(6 * index2) + 5].value = event.target.value;
            insertMatrixValue(event.target.value, soc);
        }
        setCdsValues(matrix);
    };

    const backgroundImageMatrixNormal = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%231B1E1FFF' stroke-width='2' stroke-dasharray='4%2c 6' stroke-dashoffset='26' stroke-linecap='round'/%3e%3c/svg%3e")`;
    const backgroundImageMatrixError = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23DA0C1FFF' stroke-width='2' stroke-dasharray='4%2c 6' stroke-dashoffset='26' stroke-linecap='round'/%3e%3c/svg%3e")`;

    const insertMatrixValue = (value, soc) => {
        const updateCellDataInput = getUpdatedCDSMutationValues(cdsMutationValues, [{ value, soc }])
        setCdsMutationValues(updateCellDataInput)
    };

    const classes = useStyles();

    return (
        <Box display="flex" mr={2}>
            {socs.filter(obj => (currentPowerOCV === "POWER_LIMITS" ? obj.stateOfCharge === currentPowerUnit && obj.isCharged === currentisCharged : obj.isCharged === currentisCharged)).map((soc, index2) => (
                <Box key={soc.cellDataId} my={3} ml={2} display="flex" justifyContent="center" style={{ minWidth: '120px', flex: 1 }} >
                    <InputBase
                        name="value"
                        value={ soc.value === null ? '' : soc.value }
                        placeholder={(soc.value === null || soc.value === '') ? 'n/a' : ''}
                        fullWidth={true}
                        className={classes.cds_field}
                        style={soc.isSimulated ? {
                            color: grey800,
                            fontWeight: 700,
                            border: 'none',
                            backgroundImage: !isValid(soc.value) ? backgroundImageMatrixError : backgroundImageMatrixNormal,
                            borderRadius: '2px',
                        } : {}}
                        onChange={event => handleMatrixValueChange(event, index2, soc)}
                        disabled={isCDSLocked || (role === 'Auditor User')}
                        error={!isValid(soc.value)}
                        endAdornment={(soc.value === null || soc.value === '') ?
                            <></>
                            :
                            <InputAdornment position="end">
                                <SimulatedButton soc={soc} cdsIndex={cdsIndex} tempIndex={tempIndex} cellIndex={index2} cdsValues={cdsValues} setCdsValues={setCdsValues} cdsMutationValues={cdsMutationValues} setCdsMutationValues={setCdsMutationValues} />
                            </InputAdornment>
                        }
                    />
                </Box>
            ))
            }
        </Box>
    )
}