import makeStyles from '@mui/styles/makeStyles';
import { grey100, signalRed800 } from '../other/helper/MuiStyles.js';

export const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: grey100,
        padding: '24px',
    },
    paper: {
        margin: "auto",
        minWidth: "1232px",
        maxWidth: "1232px",
        backgroundColor: '#FFF',
    },
    title: {
        display: 'flex',
        lineHeight: '40px',
        letterSpacing: '0.6px',
    },
    input_title: {
        marginBottom: '4px',
    },
    dropdown_list:{
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    iconButton: {
        cursor: 'default',
        backgroundColor: '#006384',
        '&:hover': {
            backgroundColor: '#006384',
        },
    },
    header9_text:{
        minWidth: '100%',
        maxWidth: '100%',
        textAlign: 'left',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
    },
    header6_text:{
        minWidth: '100%',
        maxWidth: '100%',
        textAlign: 'left',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
    },
    header5_text:{
        minWidth: '100%',
        maxWidth: '100%',
        textAlign: 'left',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
    },
    header7_text:{
        minWidth: '100%',
        maxWidth: '100%',
        textAlign: 'left',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
    },    
    alert_message: {
        marginTop: '4px',
        color: signalRed800,
    },
}));