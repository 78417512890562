import React from 'react';

import { ReactComponent as AddIcon } from './add-24.svg';
import { ReactComponent as ArrowDownIcon } from './arrow-down-24.svg';
import { ReactComponent as ArrowUpIcon } from './arrow-up-24.svg';
import { ReactComponent as ArrowLeftIcon } from './arrow-left-24.svg';
import { ReactComponent as ArrowRightIcon } from './arrow-right-24.svg';
import { ReactComponent as BatteryIcon } from './battery-basic-24.svg';
import { ReactComponent as BookIcon } from './library-24.svg';
import { ReactComponent as CarIcon } from './car-24.svg';
import { ReactComponent as ChatIcon } from './chat-24.svg';
import { ReactComponent as CloseIcon } from './close-24.svg';
import { ReactComponent as CloudIcon } from './upload-cloud-24.svg';
import { ReactComponent as CorrectIcon } from './correct-24.svg';
import { ReactComponent as DurabilityIcon } from './tools-24.svg';
import { ReactComponent as AddIconFilled } from './plus-filled-24.svg';
import { ReactComponent as ElectricalIcon } from './electricity-24.svg';
import { ReactComponent as EnvironmentalIcon } from './leaf-24.svg';
import { ReactComponent as FilterIcon } from './filter-24.svg';
import { ReactComponent as GlobeIcon } from './network-24.svg';
import { ReactComponent as GridIcon } from './grid-view-24.svg';
import { ReactComponent as InfoIcon } from './tooltip-24.svg';
import { ReactComponent as ListIcon } from './list-view-24.svg';
import { ReactComponent as MechanicalIcon } from './gears-24.svg';
import { ReactComponent as MenuIcon } from './menu-24.svg';
import { ReactComponent as OtherIcon } from './checklist-24.svg';
import { ReactComponent as SafetyIcon } from './cyber-security-24.svg';
import { ReactComponent as SearchCrossIcon } from './search-cross-24.svg';
import { ReactComponent as SearchIcon } from './search-24.svg';
import { ReactComponent as SettingsIcon } from './settings-24.svg';
import { ReactComponent as TrashIcon } from './delete-24.svg';
import { ReactComponent as UploadIcon } from './upload-24.svg';
import { ReactComponent as WarningIcon } from './warning-24.svg';
import { ReactComponent as EditIcon } from './pencil-24.svg';
import { ReactComponent as LegalIcon } from './legal-24.svg';
import { ReactComponent as DirectionIcon } from './direction-up-24.svg';
import { ReactComponent as LockIcon } from './locked-24.svg';
import { ReactComponent as UnlockIcon } from './unlocked-24.svg';
import { ReactComponent as BuildIcon } from './build-24.svg';
import { ReactComponent as CalendarIcon } from './calendar-24.svg';
import { ReactComponent as DownloadIcon } from './cloud-download-24.svg';
import { ReactComponent as DeleteIcon } from './circle-minus-24.svg';
import { ReactComponent as SaveIcon } from './save-24.svg';
import { ReactComponent as EyeIcon } from './eye-24.svg';
import { ReactComponent as EyeClosedIcon } from './eye-closed-24.svg';
import { ReactComponent as ZoomInIcon } from './zoom-in.svg';
import { ReactComponent as ZoomOutIcon } from './zoom-out.svg';


const icons = {
  AddIconFilled: AddIconFilled,
  AddIcon: AddIcon,
  ArrowDownIcon: ArrowDownIcon,
  ArrowUpIcon: ArrowUpIcon,
  ArrowLeftIcon: ArrowLeftIcon,
  ArrowRightIcon: ArrowRightIcon,
  BatteryIcon: BatteryIcon,
  BuildIcon: BuildIcon,
  BookIcon: BookIcon,
  CalendarIcon: CalendarIcon,
  CarIcon: CarIcon,
  ChatIcon: ChatIcon,
  CloseIcon: CloseIcon,
  CloudIcon: CloudIcon,
  CorrectIcon: CorrectIcon,
  DeleteIcon: DeleteIcon,
  DirectionIcon: DirectionIcon,
  DownloadIcon: DownloadIcon,
  DurabilityIcon: DurabilityIcon,
  EditIcon: EditIcon,
  ElectricalIcon: ElectricalIcon,
  EnvironmentalIcon: EnvironmentalIcon,
  EyeIcon: EyeIcon,
  EyeClosedIcon: EyeClosedIcon,
  FilterIcon: FilterIcon,
  GlobeIcon: GlobeIcon,
  GridIcon: GridIcon,
  InfoIcon: InfoIcon,
  LegalIcon: LegalIcon,
  ListIcon: ListIcon,
  LockIcon: LockIcon,
  MechanicalIcon: MechanicalIcon,
  MenuIcon: MenuIcon,
  OtherIcon: OtherIcon,
  SafetyIcon: SafetyIcon,
  SaveIcon: SaveIcon,
  SearchCrossIcon: SearchCrossIcon,
  SearchIcon: SearchIcon,
  SettingsIcon: SettingsIcon,
  TrashIcon: TrashIcon,
  UnlockIcon: UnlockIcon,
  UploadIcon: UploadIcon,
  WarningIcon: WarningIcon,
  ZoomInIcon: ZoomInIcon,
  ZoomOutIcon: ZoomOutIcon
}

// See: https://reactjs.org/docs/jsx-in-depth.html#choosing-the-type-at-runtime

export default function Icon(props) {
  const MyIcon = icons[props.iconName];
  return <MyIcon width={props.width ? props.width : 24} height={props.height ? props.height : 24} fill={props.fill} />;
}