import makeStyles from '@mui/styles/makeStyles';
import styled from 'styled-components';

import { NavLink } from "react-router-dom";
import { grey200, grey600, petrol800 } from '../other/helper/MuiStyles';

export const StyledNavLink = styled(NavLink)`
  && { 
  text-decoration: none;

  &:focus, &:hover, &:visited, &:link, &:active {
      text-decoration: none;
      color: black
  }

  }
`;

export const useStyles = makeStyles(() => ({
  root: {
    background: 'white',
  },
  root2: {
    background: 'transparent',
    boxShadow: 'none'
  },
  appBar: {
    top: 'auto',
    bottom: '-7px',
    height: '72px'
  },
  routerButton: {
    color: petrol800,
    fontSize: '14px',
    letterSpacing: '1.8px'
  },
  required_tag: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '18px',
    letterSpacing: '0.8px',
    color: grey600,
    marginLeft: '24px',
    minWidth: '105px'
  },
  iconButton: {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.1)',
    margin: '0px 16px',
    backgroundColor: petrol800,
    '&:hover': {
      backgroundColor: 'rgba(0, 70, 102, 0.9)',
    },
  },
  iconButton2: {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.1)',
    marginLeft: '16px',
    backgroundColor: petrol800,
    '&:hover': {
      backgroundColor: 'rgba(0, 70, 102, 0.9)',
    },
  },
  left: {
    // minWidth: 'calc((100vw * (1/3)) - 24px)',
  },
  seperator: {
    width: '1px',
    backgroundColor: grey200,
    height: '48px',
    margin: '0px 16px'
  },
  // nexttext: {
  //   color: grey400,
  //   letterSpacing: '0.4px',
  //   textAlign: 'end'
  // },
  // prevtext: {
  //   color: grey400,
  //   letterSpacing: '0.4px',
  // },
  center: {
    // minWidth: 'calc((100vw * (1/3)))',
  },
  toolbar: {
    justifyContent: 'space-between',
    margin: '0px 24px'
  }
}));