import React, { useContext } from "react";
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useReactiveVar } from "@apollo/client";

import { useStyles } from './CellDataSheet.styles.js';
import { CDSContext } from "../../other/contexts/CDSContext";
import { grey200, grey600 } from "../../other/helper/MuiStyles.js";
import CDSTableRow from "./CDSTableRow.js";
import { isCDSLoading } from "../../../operations/localeStorage.js";
import BatteryLoader from "../../other/helper/BatteryLoader/BatteryLoader.js";
import DataInput from "./DataInput.js";
import { getUpdatedCDSMutationValues, getUpdatedCDSValues } from "./CDSUtils.js";

export default function CDSTable({ cdsValues, setCdsValues, cdsIndex, cdsMutationValues, setCdsMutationValues, updateCDSLoading, isCDSLocked }) {
  const cdsLoading = useReactiveVar(isCDSLoading)

  const { currentPowerOCV } = useContext(CDSContext);
  const { currentisCharged, setCurrentisCharged } = useContext(CDSContext);
  const { currentPowerUnit, setCurrentPowerUnit } = useContext(CDSContext);

  const handlePowerUnitChange = (event, newUnit) => {
    if (newUnit !== null) {
      setCurrentPowerUnit(newUnit);
    }
  };
  
  const classes = useStyles();

  let unique_socs = [...new Set(cdsValues?.cellDataSheet[cdsIndex]?.temperatures[0]?.socs?.map(i => i.soc))];

  return (
    <>
      {!cdsLoading && !updateCDSLoading &&
        <>
          <Box display="flex" justifyContent="space-between" style={{ borderBottom: `1px solid ${grey200}` }} >
            <Box pl={4} display="flex" alignItems="center" data-testid={"cds-table-header"}>
              <Typography variant="h3" className={classes.title} >
                {currentPowerOCV === "POWER_LIMITS" ? `Pulse Time ${cdsValues?.cellDataSheet[cdsIndex]?.pulseTime} Seconds` : cdsValues?.cellDataSheet[cdsIndex]?.agingStatus}
              </Typography>
            </Box>
            <Box py={2} display="flex" justifyContent="space-between">
              <DataInput 
                rows={cdsValues?.cellDataSheet[cdsIndex]?.temperatures.map((t) => t.temperature)} 
                cols={unique_socs}
                onApply={(userInput) => {
                  const [newCDSValues, userInputMutations] = getUpdatedCDSValues(cdsValues, cdsIndex, currentPowerUnit, 
                    currentisCharged, userInput, currentPowerOCV === 'OCV')
                  setCdsValues(newCDSValues)

                  const newCdsMutationValues = getUpdatedCDSMutationValues(cdsMutationValues, userInputMutations)
                  setCdsMutationValues(newCdsMutationValues)
                }}
              />
              <ToggleButtonGroup
                value={true}
                exclusive
                className={classes.toggle_button_group}
                aria-label="test-case-view"
              >
                <ToggleButton
                  onClick={() => setCurrentisCharged(true)}
                  value={currentisCharged}
                  style={{ borderRadius: '2px 0px 0px 2px' }}
                >
                  Charge
                </ToggleButton>
                <ToggleButton
                  onClick={() => setCurrentisCharged(false)}
                  value={!currentisCharged}
                  style={{ borderRadius: '0px 2px 2px 0px' }}
                >
                  Discharge
                </ToggleButton>
              </ToggleButtonGroup>
              <div className={classes.seperator}></div>
              <Box pr={4}>
                {currentPowerOCV === 'POWER_LIMITS' &&
                  <ToggleButtonGroup
                    value={currentPowerUnit}
                    onChange={handlePowerUnitChange}
                    exclusive
                    className={classes.toggle_button_group}
                    aria-label="test-case-view"
                  >
                    <ToggleButton
                      value={'MILLI_OHM'}
                      style={{ borderRadius: '2px 0px 0px 2px' }}
                    >
                      mΩ
                    </ToggleButton>
                    <ToggleButton
                      value={'WATT'}
                      style={{ borderRadius: '0px' }}
                    >
                      W
                    </ToggleButton>
                    <ToggleButton
                      value={'AMPERE'}
                      style={{ borderRadius: '0px 2px 2px 0px' }}
                    >
                      A
                    </ToggleButton>
                  </ToggleButtonGroup>
                }
                {currentPowerOCV === 'OCV' &&
                  <ToggleButtonGroup
                    value={currentPowerUnit}
                    onChange={handlePowerUnitChange}
                    exclusive
                    className={classes.toggle_button_group}
                    aria-label="test-case-view"
                  >
                    <ToggleButton
                      value={'VOLT'}
                      style={{ borderRadius: '2px' }}
                    >
                      V
                    </ToggleButton>
                  </ToggleButtonGroup>
                }
              </Box>
            </Box>
          </Box>
          <Box display="-webkit-box">
            <Box style={{ width: '120px', borderRight: `1px solid ${grey200}` }}></Box>
            <Box display="flex" justifyContent="space-between" style={{ width: 'calc(100% - 120px - 24px - 8px)' }} >
              <Box pl={2} py={2} display="flex" alignItems="center" justifyContent="center" style={{ width: '120px' }}>
                <Typography variant="h6" style={{ color: grey600 }}>
                  SOC
                </Typography>
              </Box>
              <Box pr={4} py={2} display="flex">
                <Box display="flex" alignItems="center" justifyContent="center"
                  style={{
                    borderRadius: '5px',
                    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%231B1E1FFF' stroke-width='2' stroke-dasharray='2%2c 5' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`,
                    width: '24px',
                    height: '24px'
                  }}
                >
                  <Box display="flex" alignItems="center" justifyContent="center" style={{ border: `1px solid ${grey600}`, width: '14px', height: '14px', color: grey600 }}>S</Box>
                </Box>
                <Box display="flex" alignItems="center" ml={1}>
                  <Typography variant="h6" style={{ color: grey600 }}>
                    simulated values
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" >
            <Box pb={2} display="flex" flexDirection="column" style={{ width: '120px' }}>
              <Box pb={2} display="flex" alignItems="center" justifyContent="center" style={{ borderRight: `1px solid ${grey200}`, borderBottom: `1px solid ${grey200}`, width: '120px' }}>
                <Typography variant="h6" style={{ color: grey600 }}>
                  Temperature
                </Typography>
              </Box>
              {cdsValues?.cellDataSheet[cdsIndex]?.temperatures?.map((row, index) => (
                <Box key={index} display="flex" alignItems="center" justifyContent="center" className={classes.temperature} style={{ width: '120px' }} >
                  <Typography variant="h6" style={{ fontWeight: '400', color: grey600 }}>
                    {row.temperature}°C
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box pb={2} display="flex" flexDirection="column" justifyContent="space-between" className={classes.scrollable}>
              <Box display="flex">
                {unique_socs.map((step) => (
                  <Box pl={2} pb={2} className={classes.percentile} key={step} display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="h6" style={{ fontWeight: '400', color: grey600 }}>
                      {step}%
                    </Typography>
                  </Box>
                ))}
              </Box>

              {cdsValues?.cellDataSheet[cdsIndex]?.temperatures?.map((row, index) => (
                <Box key={index} style={{ borderBottom: `1px solid ${grey200}`, width: 'fit-content', minWidth: '100%' }}>
                  <CDSTableRow cdsValues={cdsValues} setCdsValues={setCdsValues} socs={row.socs} cdsIndex={cdsIndex} tempIndex={index} cdsMutationValues={cdsMutationValues} setCdsMutationValues={setCdsMutationValues} isCDSLocked={isCDSLocked} />
                </Box>
              ))}

            </Box>
          </Box>
        </>
      }
      {/* LOADING */}
      {(cdsLoading || updateCDSLoading) &&
        <Box display="flex" flexDirection="column" justifyContent="space-between">
          <Box m={4} display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
            <Typography variant="h3" className={classes.title}>
              {currentPowerOCV === "POWER_LIMITS" ? 'Pulse Time' : 'Aging Status'}
            </Typography>
          </Box>
          <Box mb={4} display="flex" alignItems="center" justifyContent="center" flexGrow="1">
            <BatteryLoader type="standard" />
          </Box>
        </Box>
      }
    </>
  )
}