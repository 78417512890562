import makeStyles from '@mui/styles/makeStyles';
import { buttonHoverPetrol800, grey100, grey1000, grey600, petrol1000, signalRed800 } from '../../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    root: {
        padding: '24px',
        backgroundColor: petrol1000,
    },
    paper: {
        margin: "auto",
        minWidth: "870px",
        maxWidth: "870px",
        minHeight: 'calc(100vh - 64px - 24px - 24px)',
        backgroundColor: '#FFF',
    },
    hover_blue: {
        '&:hover': {
            backgroundColor: buttonHoverPetrol800,
          },
    },
    snackbar: {
      position: 'sticky',
      height: '100%',
      top: '0',
      zIndex: '1' 
    },
    input_title: {
        marginBottom: '4px',
    },
    alert_message: {
        marginTop: '4px',
        color: signalRed800,
    },
    box: {
        width: '391px',
        textAlign: 'left',
    },
    box_freeText: {
        width: '391px',
        textAlign: 'left',
    },
    supplierPortfolio: {
        backgroundColor: grey100,
        height: '40px',
        padding: '0px 16px',
        marginTop: '16px',
        borderRadius: '8px',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    box_title: {
        lineHeight: '20px',
        letterSpacing: '0.7px',
    },
    error_text: {
        marginTop: '8px',
        fontWeight: '400',
    },
    error_type: {
        padding: '0px 24px',
        textAlign: 'center',
        marginTop: '4px',
        fontWeight: '400',
        color: grey600,
    },
    required_tag: {
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '18px',
        letterSpacing: '0.8px',
        color: grey600,
        marginLeft: '24px',
    },
    DatePickerInput: {
        width: '294.61px',
    },
    DatePickerAndornment: {
        marginTop: '1.5px',
        padding: '4px',
    },
}));