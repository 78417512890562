import React from 'react';
import { Box, InputBase, Typography, InputLabel, Select, MenuItem, Grid } from '@mui/material';
import { useStyles } from './HeaderAttributes.styles';
import Icon from '../../assets/icons/Icon.js';
import DutOverview from '../other/tooltips/DutOverview/DutOverview';
import { handleCategoryIcon } from '../other/helper/IconHandler/IconHandler';
import { grey1000 } from "../other/helper/MuiStyles";
import TestStatus from '../testcasesection/table_utils/TestStatus';
import TestResult from '../testcasesection/table_utils/TestResult';
import { handleInputChangeFreeText, handleMutationChange } from '../other/helper/globalFunctions/globalFunctions.js';
import RequirementIdsInfo from '../other/tooltips/RequirementIdsInfo/RequirementIdsInfo';
import DatePicker from '../other/helper/CustomDatePicker/DatePicker';

export default function HeaderAttributes({ test, testInstances, testStatuses, testResults, agingStatus, mutationValues, setMutationValues, isLocked, checkRequirementIds }) {

    const classes = useStyles();

    const Heading = () => {
        return (
            <Box mb={3} display="flex" alignItems="center">
                <Box mr={1} display="flex" alignItems="center"><Icon fill={grey1000} iconName={handleCategoryIcon(test?.testSpecification?.category?.name)} /></Box>
                <Box display="flex" alignItems="baseline">
                    <Typography variant="h2" id="tableTitle" component="div" className={classes.subheader}>
                        {test?.testSpecification?.name}
                    </Typography>
                    <Typography variant="subtitle1" style={{ marginLeft: '8px' }}>
                        V. {test?.testSpecification?.version}
                    </Typography>
                    <Typography variant="subtitle1" style={{ marginLeft: '8px', marginRight: '8px' }}>
                        -
                    </Typography>
                    <Typography variant="subtitle1">
                        ID {test?.testCaseId}
                    </Typography>
                </Box>
            </Box>
        );
    };

    return (
        <>
            <div>
                <Heading />
                {/* TEST INSTANCE - TEST STATUS - TEST RESULT */}
                <Grid item xs={12} display="grid">
                    <Grid container spacing={0}>
                        {/* TEST INSTANCE */}
                        <Grid item xs={4}>
                            <Box mr={2}>
                                <InputLabel className={classes.input_title}>Test Instance *</InputLabel>
                                <Select
                                    name="testInstance"
                                    value={mutationValues.testInstance}
                                    disabled={isLocked}
                                    onChange={handleMutationChange(setMutationValues, mutationValues)}
                                >
                                    {testInstances?.testInstances.map((option) => (
                                        <MenuItem
                                            key={option.testInstanceId}
                                            value={option.testInstanceId}
                                            disabled={!option.active}
                                            datamutationvalue={option.testInstanceId}
                                        >
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Grid>
                        {/* TEST STATUS */}
                        <Grid item xs={4}>
                            <Box mx={1}>
                                <InputLabel className={classes.input_title}>Test Status *</InputLabel>
                                <Select
                                    className={classes.box_freeText}
                                    name="testStatus"
                                    value={mutationValues.testStatus}
                                    disabled={isLocked}
                                    onChange={handleMutationChange(setMutationValues, mutationValues)}
                                >
                                    {testStatuses?.testStatuses.map((option) => (
                                        <MenuItem
                                            key={option.testStatusId}
                                            value={option.testStatusId}
                                            datamutationvalue={option.testStatusId}
                                            className={classes.dropdown_list}
                                        >
                                            <TestStatus testStatusName={option.name} width={'100px'} sizeWindow={3} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Grid>
                        {/* TEST RESULT */}
                        <Grid item xs={4}>
                            <Box ml={2}>
                                <InputLabel className={classes.input_title}>Test Result *</InputLabel>
                                <Select
                                    className={classes.box_freeText}
                                    name="testResult"
                                    value={mutationValues.testResult}
                                    disabled={isLocked}
                                    onChange={handleMutationChange(setMutationValues, mutationValues)}
                                >
                                    {testResults?.testResults.map((option) => (
                                        <MenuItem
                                            key={option.testResultId}
                                            value={option.testResultId}
                                            datamutationvalue={option.testResultId}
                                        >
                                            <TestResult testResultName={option.name} width={'130px'} sizeWindow={3} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                {/* AGING STATUS - START DATE - END DATE */}
                <Grid item xs={12} display="grid">
                    <Grid container spacing={0}>
                        {/* AGING STATUS */}
                        <Grid item xs={4}>
                            <Box mr={2} mt={3}>
                                <InputLabel className={classes.input_title}>Aging Status *</InputLabel>
                                <Select
                                    name="agingStatus"
                                    value={mutationValues.agingStatus}
                                    disabled={isLocked}
                                    onChange={handleMutationChange(setMutationValues, mutationValues)}
                                >
                                    {agingStatus?.agingStatus.map((option) => (
                                        <MenuItem
                                            key={option.agingStatusId}
                                            value={option.agingStatusId}
                                            disabled={!option.active}
                                            datamutationvalue={option.agingStatusId}
                                        >
                                            {option.name} {option.description?.length < 1 ? '' : `(${option.description})`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Grid>
                        {/* START DATE */}
                        <Grid item xs={2}>
                            <Box mx={1} mt={3}>
                                <InputLabel className={classes.input_title}>Planned Start Date *</InputLabel>
                                <DatePicker 
                                    date={mutationValues.plannedStartDate} 
                                    onBlur={ (newVal) => { 
                                        setMutationValues({ ...mutationValues, plannedStartDate: newVal, startDate: newVal })
                                    }} 
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box mx={1} mt={3}>
                                <InputLabel className={classes.input_title}>Actual Start Date *</InputLabel>
                                <DatePicker 
                                    trackInitDate={true}
                                    date={mutationValues.startDate} 
                                    onBlur={ (newVal) => { setMutationValues({ ...mutationValues, startDate: newVal })}} 
                                />
                            </Box>
                        </Grid>
                        {/* END DATE */}
                        <Grid item xs={2}>
                            <Box ml={2} mt={3}>
                                <InputLabel className={classes.input_title}>Planned End Date *</InputLabel>
                                <DatePicker 
                                    date={mutationValues.plannedEndDate} 
                                    onBlur={ (newVal) => { 
                                        setMutationValues({ ...mutationValues, plannedEndDate: newVal, endDate: newVal })
                                    }} 
                                    endOfWeek={true}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box ml={2} mt={3}>
                                <InputLabel className={classes.input_title}>Actual End Date *</InputLabel>
                                <DatePicker 
                                    trackInitDate={true}
                                    date={mutationValues.endDate} 
                                    onBlur={ (newVal) => { setMutationValues({ ...mutationValues, endDate: newVal })}} 
                                    endOfWeek={true}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                {/* REQ IDS - CONDITIONS - DUTS */}
                <Grid item xs={12} display="grid">
                    <Grid container spacing={0}>
                        {/* REQUIREMENT IDS */}
                        <Grid item xs={4}>
                            <Box mr={2} mt={3}>
                                <Box pb={0.5} display="flex" alignItems="center">
                                    <InputLabel>Referred Requirement IDs</InputLabel>
                                    <RequirementIdsInfo />
                                </Box>
                                <InputBase
                                    name="requirementIds"
                                    value={mutationValues.requirementIds || ''}
                                    disabled={isLocked}
                                    onChange={handleInputChangeFreeText(setMutationValues, mutationValues)}
                                    error={!checkRequirementIds}
                                />
                                {!checkRequirementIds &&
                                    <Typography variant="subtitle2" className={classes.alert_message}>Incorrect input format. Check info icon above for further information.</Typography>
                                }
                            </Box>
                        </Grid>
                        {/* CONDITIONS */}
                        <Grid item xs={4}>
                            <Box mx={1} mt={3}>
                                <InputLabel className={classes.input_title}>Conditions</InputLabel>
                                <InputBase
                                    name="conditions"
                                    value={test.condition === null ? '-' : test.condition}
                                    disabled={true}
                                />
                            </Box>
                        </Grid>
                        {/* DUTs */}
                        <Grid item xs={4}>
                            <Box ml={2} mt={3}>
                                <InputLabel className={classes.input_title}>DUTs</InputLabel>
                                <InputBase
                                    name="duts"
                                    value={`${test?.dutInfo?.planned} / ${test?.dutInfo?.started} / ${test?.dutInfo?.finished}`}
                                    disabled={true}
                                    startAdornment={<DutOverview duts={test?.dutInfo} icon />}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}