import React, {useContext, useState} from 'react';
import { Button, Dialog, IconButton, DialogContent, Box, Typography } from '@mui/material';
import Icon from '../../../../assets/icons/Icon.js';
import { useStyles } from '../UniversalDialog.styles.js';
import {SnackbarContext, SnackbarType} from '../../contexts/SnackBarContext.js';
import { petrol800 } from '../../helper/MuiStyles.js';
import { useMutation } from '@apollo/client';
import { DELETETESTCASE } from '../../../../operations/mutations/mutations.js';
import { PROJECTDETAIL, TESTCASES } from '../../../../operations/queries/query.js';
import { currentIndexId, currentProjectId } from '../../../../operations/localeStorage.js';

function DeleteTest(props) {
    const { test,  sizeWindow } = props;
    const { setMessage, setIsSnackbarOpen, setSnackbarType } = useContext(SnackbarContext);

    const [show, setShow] = useState(false);

    const ToggleModal = () => {
        setShow(!show);
    };

    const handleOnError = async (error) => {
        setMessage(error.message);
        setSnackbarType(SnackbarType.ERROR);
        setIsSnackbarOpen(true);
    }

    const handleOnCompleted = async () => {
        setSnackbarType(SnackbarType.SUCCESS);
        setIsSnackbarOpen(true);
    }

    const [deleteTestCase] = useMutation(DELETETESTCASE, {
        onCompleted: handleOnCompleted,
        onError: handleOnError,
        refetchQueries: [{ query: TESTCASES(currentIndexId()) }, { query: PROJECTDETAIL(currentProjectId()) }],
        awaitRefetchQueries: true
      });

    const handleDeleteTestCase = (e) => {
        e.preventDefault();
        deleteTestCase({ variables: { testCaseId: test.testCaseId } })
        setMessage('Test Case has been deleted!');
        setShow(!show);
    }

    const classes = useStyles();

    return <>
        <IconButton onClick={ToggleModal} className={classes.hover_blue}>
            <Icon iconName={"TrashIcon"}
                fill={petrol800}
                height={sizeWindow < 5 ? 20 : 24}
                width={sizeWindow < 5 ? 20 : 24}
            />
        </IconButton>

        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={show}
            onClose={ToggleModal}
        >
            <DialogContent>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h2">
                        Delete {test.testSpecification.name} V. {test.testSpecification.version}
                    </Typography>
                    <IconButton onClick={ToggleModal} className={classes.hover_blue}>
                        <Icon iconName={"CloseIcon"} fill={petrol800} />
                    </IconButton>
                </Box>
                <Box mt={2} display="flex" alignItems="center">
                    <Typography variant="h4" style={{ fontWeight: '400' }}>
                        Are you sure that you want to delete the selected Test Case?
                    </Typography>
                </Box>
                <Box mt={4} display="flex" alignItems="center">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDeleteTestCase}
                    >
                        Delete
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    </>
}

export default DeleteTest;