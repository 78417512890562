import makeStyles from '@mui/styles/makeStyles';
import { buttonHoverPetrol800, grey1000, grey200, grey600, grey800, petrol600, petrol800, signalRed800 } from '../../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
  title: {
    display: 'flex',
    lineHeight: '40px',
    letterSpacing: '0.6px',
},
  percentile: {
    minWidth: "120px",
    borderBottom: `1px solid ${grey200}`,
    flex: '1',
  },
  temperature: {
    height: '88px',
    borderRight: `1px solid ${grey200}`,
    borderBottom: `1px solid ${grey200}`,
  },
  scrollable: {
    overflowX: 'auto',
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      height: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: grey600,
      borderRadius: '8px'
    },
  },
  seperator: {
    width: '1px',
    backgroundColor: grey200,
    height: '40px',
    marginLeft: '24px',
    marginRight: '24px',
  },
  simulated_button_inactive: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '100% 100%, auto'
  },
  addtestspec: {
    marginTop: '64px',
    marginBottom: '64px',
    padding: '24px',
    border: '1px dashed #979797',
    color: grey800,
  },
  input_title: {
    display: 'flex',
    width: '227px',
    color: grey1000,
    fontWeight: "400",
    fontSize: "16px",
    fontStyle: 'normal',
    lineHeight: '18px',
    letterSpacing: '0.8px',
    marginBottom: '8px',
  },
  error_text: {
    marginTop: '8px',
    fontWeight: '400',
  },
  error_type: {
    padding: '0px 24px',
    textAlign: 'center',
    marginTop: '4px',
    fontWeight: '400',
    color: grey600,
  },
  toggle_button_group: {
    display: 'flex !important',
    flexWrap: 'wrap !important',
    borderRadius: 'none !important',
  },
  active_edit_button: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '18.2px',
    letterSpacing: '1.8px',
    color: `${petrol800} !important`,
    backgroundColor: '#FFF',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  active_edit_button_selected: {
    color: '#FFF !important',
    backgroundColor: petrol800,
  },
  normalButton: {
    color: '#FFF',
    padding: '0px 16px',
    borderRadius: '8px !important',
    border: '0 !important',
    boxShadow: 'none !important',
    marginRight: '16px',
    marginBottom: '8px',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '40px',
    letterSpacing: '0.6px',
    textTransform: 'capitalize',
    backgroundColor: petrol600,
  },
  arrow_hover: {
    padding: '8px',
    '&:hover': {
      backgroundColor: buttonHoverPetrol800,
    },
  },
  circle: {
    minWidth: '52px',
    height: '52px',
    borderRadius: '100%',
    border: `1px solid ${petrol800}`,
    backgroundColor: '#FFF',
    float: 'left',
    color: petrol800,
    '&:hover': {
      backgroundColor: buttonHoverPetrol800
    },
  },
  circle_selected: {
    minWidth: '52px',
    height: '52px',
    borderRadius: '100%',
    border: `2px solid ${petrol800}`,
    backgroundColor: petrol800,
    float: 'left',
    color: '#FFF',
    '&:hover': {
      backgroundColor: petrol800
    },
  },
  line: {
    marginTop: '26px',
    marginLeft: '-20px',
    marginRight: '-40px',
    width: '450px',
    height: '2px',
    backgroundColor: grey200,
    float: 'left'
  },
  alert_message: {
      marginTop: '4px',
      color: signalRed800,
  },
  subheader: {
    lineHeight: "24px",
    letterSpacing: "0.4px",
  },
}));