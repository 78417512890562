import React from 'react';
import { Typography } from '@mui/material';

import { styles, Link } from './PrivacyPolicyComponents';

function EmployeeData() {

    const classes = styles();

    return (<>
        <Typography variant="h2">
            Datenschutzerklärung Beschäftigtendaten
        </Typography>

        {/* SECTION A */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            A. Verantwortlicher
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Mit dieser Datenschutzerklärung informieren wir Sie über die Erhebung, Verarbeitung und Nutzung Ihrer
            personenbezogenen Daten durch die Volkswagen AG, Berliner Ring 2, 38440 Wolfsburg, eingetragen im Handelsregister des Amtsgerichts Braunschweig unter der Nr. HRB 100484 („Volkswagen AG“) im Rahmen Ihres Beschäftigungsverhältnisses bei der Volkswagen AG.
        </Typography>

        {/* SECTION B */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            B. Grundsätze
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Volkswagen AG ist einer der größten Arbeitgeber in Deutschland. Daraus resultiert eine große
            Verantwortung für unsere Beschäftigten. Hiermit informieren wir Sie als Beschäftigte/n darüber,
            wie wir Ihre personenbezogenen Daten erheben, verarbeiten und übermitteln, aus welchen Gründen wir das tun,
            welche Rechte Sie dabei haben und wie Sie mit uns Kontakt aufnehmen können. Wir nutzen Ihre
            personenbezogenen Daten regelmäßig in vielen Prozessen. Selbstverständlich verkaufen wir Ihre Daten nicht,
            noch stellen wir sie anderen nicht berechtigten Dritten zur Verfügung. Darüber hinaus leiten wir
            Ihre Daten nur im notwendigen Maß innerhalb der Volkswagen AG und an berechtigte Dritte weiter.
        </Typography>

        {/* SECTION C */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            C. Verarbeitung Ihrer personenbezogenen Daten
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die meisten Daten erhalten wir direkt von Ihnen und speichern sie in Personalsystemen. Weitere Daten
            können wir auch von Dritten erhalten, z.B. von Behörden, Krankenkassen oder anderen Gesellschaften
            des Volkswagen Konzerns, etwa bei einem personellen Wechsel aus einer Konzerngesellschaft in die Volkswagen AG.
        </Typography>

        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die erhaltenen Daten verwenden wir u. a. in Zusammenhang mit:
        </Typography>
        <ul className={classes.list}>
            <li>
                Ihrem <b>Eintritt</b> in das Unternehmen (z. B. Durchführung Ihres Auswahlverfahrens, Vergabe Ihrer
                Personalnummer, Durchführung Ihres Einstellungsgesprächs, Erstellung Ihres Werksausweises)
            </li>
            <li>
                der <b>Durchführung Ihres Beschäftigungsverhältnisses</b> (z. B. Mitarbeitergespräche, Versetzungen und
                persönliche Veränderungen, Gesundheitsförderung und arbeitsmedizinische Betreuung, Zutrittskontrollen,
                interne und externe Kommunikation, Büroarbeitsplatzverwaltung)
            </li>
            <li>
                Ihrer <b>beruflichen Nutzung von IT-Systemen</b> (z. B. User IDs, Passwörter, Protokollierungen in
                IT- Systemen, System-Tests zur Qualitätssicherung)
            </li>
            <li>
                Ihrer Nutzung von <b>Ticketing-Systemen</b> (z. B. HR-Beratungscenter, Enterprise Helpdesk)
            </li>
            <li>
                der <b>Abwehr digitaler Risiken</b> für Sie und das Unternehmen (z. B. Sicherstellung der Vertraulichkeit,
                Integrität und Verfügbarkeit aller Daten, Erkennen von Cyberangriffen und Einleiten von Gegenmaßnahmen)
            </li>
            <li>
                <b>Abrechnungen</b> innerhalb Ihres Arbeitsverhältnisses (z. B. Entgeltabrechnung, Freistellung,
                Sonderurlaub, Daten-übermittlung an Sozial-/ Kranken-/ Rentenversicherungsträger, Besuch
                der Betriebsrestaurants)
            </li>
            <li>
                Ihrer Nutzung unserer <b>betrieblichen Leistungen</b> (z. B. Informationsveranstaltungen für Eltern,
                Diversity-Programme, tarifvertragliche Angebote)
            </li>
            <li>
                der Sicherstellung einer <b>ordnungsgemäßen Versteuerung</b> und
                Verbeitragung Ihrer <b>geldwerten Vorteile</b> im Rahmen der unternehmerischen Compliancevorgaben
                (z. B. Sport- und Kulturveranstaltungen, Messen oder Händler-veranstaltungen)
            </li>
            <li>
                der <b>Personalisierung und namentlichen Zuordnung</b> Ihrer Arbeitsmittel
                (z. B. Berufs-/ Schutzbekleidung, Schlüssel, Telekommunikationsgeräte, Visitenkarten)
            </li>
            <li>
                Ihrer <b>Nutzung und Entleihung von (Geschäfts-) Fahrzeugen</b> (z. B. Fahrtenbücher, Torpässe)
            </li>
            <li>
                der <b>Dokumentation</b> Ihrer Nutzung unternehmenseigener Betriebsmittel (z. B. Fahrzeuge,
                Kameratechnik, IT-Hardware)
            </li>
            <li>
                der <b>Sicherstellung Ihres Arbeitsschutzes</b> (z. B. Unfallanalysen, Arbeitssicherheitsunterweisungen)
            </li>
            <li>
                Ihrer <b>innerbetrieblichen Sicherheit</b> und der <b>Gesundheit der Belegschaft</b> (z. B.
                Kontaktverfolgung zur Infektionseindämmung)
            </li>
            <li>
                Ihrer <b>Teilnahmemöglichkeit an betrieblichen Wahlen</b> (z. B. Betriebsrat, Schwerbehindertenvertretung,
                Jugend- und Auszubildendenvertretung)
            </li>
            <li>
                Ihrer <b>Qualifizierung</b> und <b>Weiterbildung</b> (z. B. Berufsausbildung, Schulungen,
                spezifische Entwicklungsprogramme, Prüfungen für Führungs­ oder Expertenaufgaben)
            </li>
            <li>
                Ihrer <b>Teilnahme an Veranstaltungen</b> (z. B. Messen, Kongresse, Tagungen, Konferenzen)
            </li>
            <li>
                der Bearbeitung Ihrer betrieblichen <b>Verbesserungsideen</b> (z. B. Konzern-Ideenmanagement)
            </li>
            <li>
                dem <b>Austausch Ihrer Daten innerhalb des Konzerns</b> (z. B. Zusammenarbeit in markenübergreifenden
                Projekten, Nachfolgeplanung, Übernahme Rentenanwartschaften,)
            </li>
            <li>
                Ihren möglichen <b>Dienstreisen</b> (z. B. Reiseplanung, Hotel- und Flugbuchungen, Auslagenerstattung,
                Informations-versorgung bzgl. Sicherheit und Risiken)
            </li>
            <li>
                Ihren möglichen <b>Auslandsentsendungen</b> (z. B. kurz- oder langfristige Einsätze an internationalen
                Standorten, Daten-übermittlung an Konzerngesellschaften (auch in Nicht-EU-Länder), Behörden und
                Krankenversicherungsträger)
            </li>
            <li>
                der Durchführung von <b>Prüfungstätigkeiten</b> und anlassbezogenen <b>Untersuchungen</b> (z. B.
                Aufklärungsoffice, Konzernrevision, Konzernsicherheit)
            </li>
            <li>
                der Umsetzung von <b>Meldepflichten</b> (z. B. an Aufsichtsbehörden, Sozialversicherungsträger,
                Gesundheitsämter)
            </li>
            <li>
                der <b>Verteidigung von Rechtsansprüchen</b> der Volkswagen AG (z. B. Arbeitsgerichtsprozesse,
                Patentmeldungen)
            </li>
            <li>
                der <b>Beendigung oder Unterbrechung</b> Ihres <b>Arbeitsverhältnisses</b> (z. B. Austritt,
                Meine Auszeit, Wiedereinstellungszusage, Arbeitszeugnis, Renteneintritt einschließlich Betriebsrente)
            </li>
            <li>
                der Erstellung <b>anonymisierte statistischer Auswertungen</b> (z. B. Statistik zur Vergütungsstruktur
                oder zum Hinweisgebersystem – Ihre Daten sind Grundlage der Statistik und fließen anonym in die
                statistischen Werte ein)
            </li>
        </ul>

        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Sollte sich ein <b>Notfall</b> mit Gefahr für Leib und Leben ereignen, kann es erforderlich sein, dass wir uns mit
            dem von Ihnen angegebenen Notfallansprechpartner in Verbindung setzen. Sollte es zu einem <b>Todesfall</b> kommen,
            werden wir Ihre Hinterbliebenen kontaktieren.
            Bitte <b>informieren Sie die von Ihnen genannten Angehörigen und/oder Ansprechpartner</b> darüber,
            dass Sie uns die entsprechenden Kontaktdaten zum Zweck der Kontaktaufnahme
            in einem Not- bzw. Sterbefall zur Verfügung gestellt haben.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Darüber hinaus weisen wir darauf hin, dass wir zu bestimmten Themen gesonderte Datenschutzerklärungen
            verfasst haben, die Ihnen bei Durchführung der jeweiligen Prozesse zur Verfügung gestellt werden.
        </Typography>

        {/* SECTION D */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            D. Kategorien von Daten
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Im Rahmen der oben genannten Prozesse werden folgende Kategorien Ihrer personenbezogenen Daten verarbeitet:
        </Typography>
        <ul className={classes.list}>
            <li>
                <b>Vertragsdaten</b> (z. B. Daten aus Arbeitsvertrag, Altersteilzeitvertrag, Wiedereinstellungszusage)
            </li>
            <li>
                <b>Berufliche Kontakt- und (Arbeits-) Organisationsdaten</b> (z. B. Name, Vorname, Unterschrift,
                dienstliche Anschrift, dienstliche E­Mail­Adresse und Telefonnummer, Personalnummer, Gesellschaft,
                Fachbereich, Abteilung, Kostenstelle)
            </li>
            <li>
                <b>Daten zu persönlichen/beruflichen Verhältnissen und Merkmalen</b> (z. B. Familienstand, Geschlecht,
                Berufs-bezeichnung, beruflicher Werdegang, Dauer der Werkszugehörigkeit, Daten des Ehepartners oder
                der Kinder, ausgeübte Tätigkeiten, Qualifikationen, Bewertungen)
            </li>
            <li>
                <b>Entgelt- und Zeitwirtschaftsdaten</b> (z. B. Entgeltstufen, Entgeltabrechnung, Sonderzahlungen,
                An- und Abwesenheits-zeiten, Abwesenheitsgründe, Arbeitssysteme und Tätigkeitsbeschreibungen)
            </li>
            <li>
                <b>Private Kontakt- und Identifikationsdaten</b> (z. B. private Anschrift, private E-Mail-Adresse,
                private Telefonnummer, Geburtsdatum und -ort, Identifikationsnummern, Nationalität)
            </li>
            <li>
                <b>IT-Nutzungsdaten</b> (z. B. User ID, Passwörter)
            </li>
            <li>
                <b>Mitarbeiterfotos</b>
            </li>
            <li>
                <b>Bankdaten</b>
            </li>
            <li>
                <b>Besonders sensible personenbezogene Daten</b> (z. B. Unfalldaten, Konfession, Grad der Behinderung)
            </li>
        </ul>

        {/* SECTION E */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            E. Rechtsgrundlagen für die Erhebung Ihrer personenbezogenen Daten
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Ihre personenbezogenen Daten werden nur zweckbezogen erhoben, verarbeitet und übermittelt und nur
            wenn eine einschlägige Rechtsgrundlage dafür vorliegt. Folgende Rechtsgrundlagen liegen den oben
            beschriebenen Zwecken zugrunde:
        </Typography>
        <ul className={classes.list}>
            <li>
                Begründung, Durchführung oder die Beendigung eines <b>Beschäftigungsverhältnisses</b> (Art. 88 DSGVO
                i. V. m. § 26 BDSG)
            </li>
            <li>
                <b>Vertrag</b> oder Vertragsanbahnung mit dem Betroffenen (Art. 6 Abs. 1 lit. b DSGVO)
                (z. B. Arbeitsvertrag, Altersteilzeitvertrag)
            </li>
            <li>
                <b>Spezialgesetzliche Regelungen</b> (z. B. Bürgerliches Gesetzbuch für die Zeugniserstellung,
                Sozialgesetzbuch beim Betrieblichen Eingliederungsmanagement und bei der Erfassung von Unfalldaten,
                Verordnung über die Zulassung von Personen im Straßenverkehr zur Feststellung der Eignung zur
                Ausführung bestimmter Tätigkeiten)
            </li>
            <li>
                <b>Kollektivvereinbarungen</b> (z. B. Manteltarifvertrag, Tarifverträge, Betriebsvereinbarungen)
            </li>
            <li>
                <b>Einwilligung</b> (Art. 6 Abs. 1 lit. a DSGVO) (z. B. Freigabe eines Fotos auf der Kurzbiografie,
                Teilnahme an Auswahlverfahren im Rahmen der Personalentwicklung, Anmeldung zu
                Informationsveranstaltungen)
            </li>
            <li>
                <b>berechtigtes, abgewogenes Interesse</b> (Art. 6 Abs. 1 lit. f DSGVO) (z. B. zur Kontaktaufnahme
                im Rahmen von Spendenaktionen zu Gunsten karitativer Einrichtungen, Verarbeitung von Daten Ihrer
                Angehörigen zur Kontaktaufnahme in Notfällen)
            </li>
            <li>
                <b>Schutz lebenswichtiger Interessen</b> (Art. 6 Abs. 1 lit. d DSGVO) (z. B. Kontaktnachverfolgung
                bei Pandemien)
            </li>
        </ul>

        {/* SECTION F */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            F. Weitergabe Ihrer personenbezogenen Daten an Dritte
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            In einzelnen Fällen geben wir Ihre Daten an befugte Dritte weiter. Dies können z. B. sein:
        </Typography>
        <ul className={classes.list}>
            <li>
                <b>Konzerngesellschaften</b> (z. B. im Rahmen von Leasingverträgen an die Volkswagen Leasing GmbH,
                im Rahmen Ihrer Entwicklungsplanung an die Audi AG, Porsche AG etc., in Zusammenhang mit der Erbringung
                von Serviceleistungen an die Volkswagen Group Services GmbH)
            </li>
            <li>
                <b>öffentliche Stellen</b> (z. B. im Rahmen Ihrer Entgeltabrechnung an Rentenversicherungen sowie
                Finanzämter, von Gesundheitsmaßnahmen an Ihre Krankenversicherung)
            </li>
            <li>
                <b>Hotels und Fluggesellschaften</b> (z. B. im Rahmen von Dienstreisen)
            </li>
        </ul>

        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Unter Umständen geben wir Ihre Daten an Auftragsverarbeiter weiter. Diese unterliegen den Weisungen
            der Volkswagen AG und dürfen nicht selbst über die Datenverarbeitungszwecke und die Verwendung der Daten
            entscheiden. Wir setzen Auftragsverarbeiter u. a. in folgenden Bereichen ein:
        </Typography>
        <ul className={classes.list}>
            <li>
                <b>IT-Dienstleistungen</b> (z. B. in Zusammenhang mit den Betrieb und Support von Systemen oder
                der Nutzung von Clouddiensten)
            </li>
            <li>
                Dienstleistungen für <b>versicherungsmathematische Berechnungen</b> (z. B. in Zusammenhang mit der
                Durchführung Ihrer betrieblichen Altersversorgung)
            </li>
            <li>
                <b>Serviceleistungen</b> (z. B. bei der Durchführung von Events oder der Abwicklung
                betrieblicher Leistungen)
            </li>
        </ul>

        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Wir haben mit unseren Auftragsverarbeitern jeweils einen Auftragsverarbeitungsvertrag geschlossen und
            angemessene Garantien zur Wahrung des Schutzes personenbezogener Daten vereinbart.
            Die Auftragsverarbeiter verfügen über geeignete technische und organisatorische Maßnahmen zum Schutz
            personenbezogener Daten und werden von uns regelmäßig kontrolliert.
        </Typography>

        {/* SECTION G */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            G. Weitergabe Ihrer Daten in Drittländer
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Es kann ein Datenaustausch zwischen Gesellschaften, Joint Ventures oder anderen Geschäftspartnern
            außer- und innerhalb des Volkwagen Konzerns in Staaten außerhalb der Europäischen Union (EU) stattfinden,
            z.B. bei Auslandseinsätzen oder in Zusammenhang mit Personalentwicklungsmaßnahmen.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Wir weisen darauf hin, dass das Datenschutzniveau in anderen Ländern außerhalb des Geltungsbereiches
            der DSGVO häufig nicht dem gleichen Datenschutzniveau entspricht. Das heißt, dass die relevanten Daten
            möglicherweise an andere Stellen weitergegeben werden und es keine vergleichbaren
            staatlichen Kontrollmechanismen durch Datenschutzbehörden gibt. Außerdem bestehen nicht in allen Ländern
            dem europäischen Recht entsprechende Rechtsschutzmöglichkeiten.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Sofern wir eine Übermittlung Ihrer personenbezogenen Daten in unsichere Drittstaaten nicht auf Ihre
            ausdrückliche Einwilligung oder einen mit Ihnen geschlossenen Vertrag gründen, haben wir als
            geeignete Garantie EU-Standardvertragsklauseln vereinbart.
        </Typography>

        {/* SECTION H */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            H. Aufbewahrungs- und Löschfristen für Ihre personenbezogenen Daten
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Ihre personenbezogenen Daten werden nur verarbeitet und gespeichert, solange der jeweilige Zweck besteht
            oder ein Gesetz dies vorschreibt. Im Folgenden finden Sie eine Übersicht der wichtigsten Fristen, nach denen
            wir Ihre personenbezogenen Daten löschen. Dazu gehören u. a.:
        </Typography>
        <ul className={classes.list}>
            <li>
                <b>Bis zu 180 Tage:</b> Daten zu persönlichen/beruflichen Verhältnissen und Merkmalen (z. B. bei
                Bewerbungsunterlagen)
            </li>
            <li>
                <b>2 bis 4 Jahre:</b> besonders sensible Daten im Rahmen des Arbeitsrechts/der Arbeitsordnung
                (z. B. Verwarnungen oder Verweise) sowie Zeitwirtschaftsdaten (z. B. Bescheinigungen an Behörden)
            </li>
            <li>
                <b>10 Jahre:</b> Daten zu persönlichen/beruflichen Verhältnissen und Merkmalen sowie
                berufliche Kontakt- und (Arbeits-) Organisationsdaten im Rahmen der Einstellung, Personalbetreuung
                und -entwicklung (z. B. Berufsbezeichnung, beruflicher Werdegang, Tätigkeiten, Qualifikationen,
                Bewertungen/ Beurteilungen) sowie besonders sensible personenbezogene Daten (z. B. Dokumentationen
                von Arbeitsunfällen)
            </li>
            <li>
                <b>15 Jahre:</b> Bank- und Bonitätsdaten sowie Entgeltdaten (z. B. Lohnsteuerklasse, Bankverbindung,
                Sonderzahlungen)
            </li>
            <li>
                <b>60 Jahre:</b> Daten zu persönlichen/beruflichen Verhältnissen und Merkmalen bei Austritt
                ausschließlich zum Zweck der Zahlung einer betrieblichen Altersvorsorge (z. B. Dauer
                der Betriebszugehörigkeit, Eintritts- und Austrittsdaten)
            </li>
        </ul>

        {/* SECTION I */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            I. Ihre Rechte
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Ihre nachfolgenden Rechte können Sie gegenüber der Volkswagen AG jederzeit unentgeltlich geltend machen.
            Weitere Informationen zur Wahrnehmung Ihrer Rechte finden Sie unter Abschnitt J.
        </Typography>
        <ul className={classes.list}>
            <li>
                <b>Auskunftsrecht:</b> Sie haben das Recht, von uns Auskunft über die Verarbeitung Ihrer
                personenbezogenen Daten zu erhalten.
            </li>
            <li>
                <b>Berichtigungsrecht:</b> Sie haben das Recht, von uns die Berichtigung Sie betreffender
                unrichtiger bzw. unvollständiger personenbezogener Daten zu verlangen.
            </li>
            <li>
                <b>Recht auf Löschung:</b> Sie haben das Recht, bei Vorliegen der in Art. 17 DSGVO genannten
                Voraussetzungen, die Löschung Ihrer Daten zu verlangen. Danach können Sie beispielsweise
                die Löschung Ihrer Daten verlangen, soweit diese für die Zwecke, für die sie erhoben wurden,
                nicht mehr notwendig sind. Außerdem können Sie Löschung verlangen, wenn wir Ihre Daten auf
                der Grundlage Ihrer Einwilligung verarbeiten und Sie diese Einwilligung widerrufen.
            </li>
            <li>
                <b>Recht auf Einschränkung der Verarbeitung:</b> Sie haben das Recht, die Einschränkung
                der Verarbeitung Ihrer Daten zu verlangen, wenn die Voraussetzungen des Art. 18 DSGVO vorliegen.
                Dies ist beispielsweise der Fall, wenn Sie die Richtigkeit Ihrer Daten bestreiten. Für die Dauer
                der Überprüfung der Richtigkeit der Daten können Sie dann die Einschränkung der Verarbeitung verlangen.
            </li>
            <li style={{ border: '1px solid black' }}>
                <b>Widerspruchsrecht:</b> Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation
                ergeben, der Verarbeitung Ihrer Daten durch uns zu widersprechen, wenn die Verarbeitung aufgrund
                eines berechtigten, abgewogenen Interesses stattfindet. Im Falle des Widerspruchs bitten wir Sie,
                uns Ihre Gründe mitzuteilen, aus denen Sie der Datenverarbeitung widersprechen. Wir verarbeiten Ihre
                personenbezogenen Daten dann nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für
                die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder
                die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung unserer Rechtsansprüche.
            </li>
            <li>
                <b>Recht auf Datenübertragbarkeit:</b> Sofern die Datenverarbeitung auf der Grundlage eine Einwilligung
                oder einer Vertragserfüllung beruht und diese zudem unter Einsatz einer automatisierten Verarbeitung
                erfolgt, haben Sie das Recht, Ihre Daten in einem strukturierten, gängigem und maschinenlesbaren Format
                zu erhalten und diese an einen anderen Datenverarbeiter zu übermitteln.
            </li>
            <li>
                <b>Widerrufsrecht:</b> Sofern die Datenverarbeitung auf einer Einwilligung beruht, haben Sie das Recht,
                die Daten-verarbeitung mit Wirkung für die Zukunft jederzeit kostenlos zu widerrufen.
                Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis
                zum Widerruf erfolgten Verarbeitung nicht berührt.
            </li>
            <li>
                <b>Beschwerderecht:</b> Sie haben außerdem das Recht, sich bei einer Aufsichtsbehörde
                (z. B. bei der Landesbeauftragten für den Datenschutz Niedersachsen) über unsere Verarbeitung
                Ihrer Daten zu beschweren.
            </li>
        </ul>

        {/* SECTION J */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            J. Ihre Ansprechpartner
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Transparenz im Umgang mit Ihren personenbezogenen Daten ist uns wichtig. Wenn Sie Zweifel oder Fragen haben,
            sprechen Sie uns an:
        </Typography>
        <ul className={classes.list}>
            <li>
                <Typography variant="h5" marginTop={'8px'} align='left'>
                    Ansprechpartner für die Ausübung Ihrer Rechte
                </Typography>
                <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
                    Die Ansprechpartner für die Ausübung Ihrer Rechte und weitergehende Informationen finden Sie auf
                    der folgenden Webseite:
                </Typography>
                <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
                    <Link href={"https://datenschutz.volkswagen.de/"}>https://datenschutz.volkswagen.de</Link>
                </Typography>
            </li>
            <li>
                <Typography variant="h5" marginTop={'8px'} align='left'>
                    Datenschutzbeauftragter
                </Typography>
                <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
                    Unser Datenschutzbeauftragter steht Ihnen als Ansprechpartner für datenschutzbezogene Anliegen
                    zur Verfügung:
                </Typography>
                <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
                    <div>Datenschutzbeauftragter der Volkswagen AG</div>
                    <div>Berliner Ring 2</div>
                    <div>38440 Wolfsburg</div>
                </Typography>
                <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
                    <a href="mailto:dataprivacy@volkswagen.de">dataprivacy@volkswagen.de</a>
                </Typography>
            </li>
        </ul>

        {/* Stand */}
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'}>
            Version: <b>2.1</b>, Datum: <b>06.03.2023</b>
        </Typography>

    </>)
}

export default EmployeeData;