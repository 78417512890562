import React from 'react';
import { useQuery } from '@apollo/client';
import { useLocation, Link } from 'react-router-dom';
import { projectRole } from '../../operations/localeStorage'
import { Typography, Box, IconButton, Modal, MenuItem, MenuList, Paper, ClickAwayListener } from '@mui/material';
import { useStyles } from './Header.styles.js';
import { IMAGE_MAP } from '../other/helper/ImgMapper/ImgMapper'
import { HashLink } from 'react-router-hash-link';
import TONICLOGO from '../../assets/logos/TONIC_LOGO.svg';
import Icon from '../../assets/icons/Icon.js';
import PrivacyPolicy from '../other/dialogs/PrivacyPolicy/PrivacyPolicy';
import BatteryLoader from '../other/helper/BatteryLoader/BatteryLoader';

import { MYACCOUNT } from '../../operations/queries/query';
import { KsuNotification } from '../stickybar/KsuNotification';

function Header() {
  const role = projectRole();

  const { data: myAccount, error: myAccountError, loading: myAccountLoading } = useQuery(MYACCOUNT);

  const location = useLocation();
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // for checking path to display confidential and KSU if no StickyBar
  const headerpaths = ['', 'usermanagement', 'suppliermanagement', 'profile', 'selectavatar', 'datamanagement'];
  const locationpath = location?.pathname?.split('/')?.[1];

  return (
    <>
      {myAccountError &&
        <Box id="back-to-top-anchor" className={classes.app_header} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          <Icon
            fill="white"
            iconName={"WarningIcon"}
            width={32}
            height={32}
          />
          <Typography variant="h5" className={classes.error_type}>{myAccountError.message}</Typography>
        </Box>
      }
      {
        myAccountLoading && !myAccountError &&
        < Box id="back-to-top-anchor" className={classes.app_header} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          <BatteryLoader type="header" />
        </Box>
      }
      {
        !myAccountLoading && !myAccountError &&
        <Box pl={2} id="back-to-top-anchor" display="flex" alignItems="center" justifyContent="space-between" className={classes.app_header}>
          <Box className={classes.title_box}>
            <IconButton
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              size="large">
              <Icon
                fill="white"
                iconName={"MenuIcon"}
                width={40}
                height={40}
              />
            </IconButton>
            <Box component={Link} to={`/`} mx={2} display="flex" flexDirection="row" alignItems="center" justifyContent="center" className={classes.logo_background}>
              <img src={TONICLOGO} className={classes.logo2} alt="Avatar" />
            </Box>
            <Typography className={classes.title_text}>TONIC42</Typography>
          </Box>
          {headerpaths.some(path => path === locationpath) &&
            <KsuNotification borderColor="#FFF"/>
          }
          <Box display="flex">
            <PrivacyPolicy />
            <Box component={Link} to={`/profile/${myAccount?.myAccount?.userId.toUpperCase()}`} className={classes.nolink} display="flex" alignItems="center">
              <img src={IMAGE_MAP.AVATAR[myAccount.myAccount.avatarId]?.pngSrc} className={classes.userimage} alt="Avatar" />
              <Typography className={classes.username}>{myAccount?.myAccount?.firstName} {myAccount?.myAccount?.lastName}</Typography>
            </Box>
          </Box>
          <Modal className={classes.popper} open={open}>
            <>
              <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown} style={{ padding: '0px' }}>
                    {/* <MenuItem component={Link} to={`/`} onClick={handleToggle}>Overview</MenuItem> */}
                    <MenuItem selected={location.pathname === '/' && location.hash === ''} component={Link} to={`/`} onClick={handleToggle} style={{ minHeight: '48px' }}>Projects</MenuItem>
                    <MenuItem selected={location.pathname === '/' && location.hash === '#testspecs'} component={HashLink} to={`/#testspecs`} onClick={handleToggle} style={{ minHeight: '48px' }}>Test Specifications</MenuItem>
                    {/* <MenuItem selected={location.pathname === '/addtestspecification'} component={Link} to={`/addtestspecification`} onClick={handleToggle} style={{ minHeight: '48px' }}>Add Test Specification</MenuItem> */}
                    {role === 'Administrator' &&
                      <div>
                        <div className={classes.seperator}></div>
                        <Box className={classes.seperatortext}>Administration</Box>
                        <MenuItem selected={location.pathname === '/usermanagement'} component={Link} to={`/usermanagement`} onClick={handleToggle} style={{ minHeight: '48px' }}>User Management</MenuItem>
                        <MenuItem selected={location.pathname === '/suppliermanagement'} component={Link} to={`/suppliermanagement`} onClick={handleToggle} style={{ minHeight: '48px' }}>Supplier Management</MenuItem>
                        <MenuItem selected={location.pathname === '/datamanagement'} component={Link} to={`/datamanagement`} onClick={handleToggle} style={{ minHeight: '48px' }}>Data Management</MenuItem>
                      </div>
                    }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
              <div className={classes.background}></div>
            </>
          </Modal>
        </Box>
      }
    </>
  )
}



export default Header;