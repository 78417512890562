import React, {useContext, useEffect, useState} from 'react';
import { Button, Dialog, IconButton, DialogContent, Box, Typography } from '@mui/material';
import { useStyles } from '../UniversalDialog.styles.js';
import Icon from '../../../../assets/icons/Icon.js';
import {
    SnackbarContext, SnackbarType
} from '../../contexts/SnackBarContext.js';
import { petrol800, grey1000, buttonHoverPetrol800, buttonHoverGrey800, red500 } from '../../helper/MuiStyles.js';

function DeleteData(props) {
    const { headline, name, idValue, mutateFunction, activate, parameter, unit, cellSupplier, user, agingStatus } = props;
    const { setMessage, setSnackbarType } = useContext(SnackbarContext);

    const [show, setShow] = useState(false);
    const [supplierPortfolioArray, setSupplierPortfolioArray] = useState();

    const ToggleModal = () => {
        setShow(!show);
    };

    const handleSave = () => {
        if (parameter) {
            mutateFunction({ variables: { id: idValue, active: activate, name: name, physicalQuantity: parameter.physicalQuantity.physicalQuantityId, description: parameter.description, unit: parameter.unit.unitId } });
        } else if (cellSupplier) {
            mutateFunction({ variables: { cellSupplierId: idValue, active: activate, name: name, rating: cellSupplier.rating === null ? null : cellSupplier.rating.ratingId, supplierPortfolio: supplierPortfolioArray, street: cellSupplier.street, number: cellSupplier.number, postalCode: cellSupplier.postalCode, city: cellSupplier.city, contact: cellSupplier.contact === null ? null : cellSupplier.contact.userId } });
        } else if (user) {
            mutateFunction({ variables: { userId: idValue, active: activate } });
        } else if (unit) {
            mutateFunction({ variables: { id: idValue, active: activate, name: name, abbreviation: unit.abbreviation } });
        } else if (agingStatus) {
            mutateFunction({ variables: { id: idValue, active: activate, name: name, description: agingStatus.description } });
        } else {
            mutateFunction({ variables: { id: idValue, active: activate, name: name } });
        }
        setMessage(`${headline} has been ${activate ? 'reactivated' : 'deactivated'}`)
        setSnackbarType(SnackbarType.SUCCESS);
        setShow(false);
    };

    //convert supplierPortfolio Ids in propper 
    useEffect(() => {
        let array = [];
        if (cellSupplier !== undefined) {
            for (let i of cellSupplier.supplierPortfolio) {
                array.push(cellSupplier?.supplierPortfolio[i]?.supplierPortfolioId);
            }
        }
        setSupplierPortfolioArray(array);
    }, [cellSupplier]); // eslint-disable-line

    const styleProps = {
        editIconWrapperMarginLeft: (cellSupplier || user || activate) ? '8px' : '4px',
        editIconHoverBackground: (cellSupplier || user) ? buttonHoverPetrol800 : activate ? red500 : buttonHoverGrey800,
        deleteIconHoverBackground: buttonHoverGrey800,
      };

    const classes = useStyles(styleProps);

    return (
        <>
            {(cellSupplier || user) &&
                <IconButton className={classes.edit_icon_wrapper} onClick={ToggleModal}>
                    <Icon iconName={activate ? "AddIcon" : "TrashIcon"} fill={petrol800} />
                </IconButton>

            }
            {(!cellSupplier && !user) &&
                <IconButton className={activate ? classes.edit_icon_wrapper : classes.delete_icon_wrapper} onClick={ToggleModal}>
                    <Icon iconName={"AddIcon"} fill={activate ? '#FFF' : grey1000} />
                </IconButton>
            }

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={show}
                onClose={ToggleModal}
            >
                <DialogContent>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h2">
                            {activate ? "Reactivate" : "Deactivate"} {user ? `${user.firstName} ${user.lastName}` : name}
                        </Typography>
                        <IconButton onClick={ToggleModal} className={classes.hover_blue}>
                            <Icon iconName={"CloseIcon"} fill={petrol800} />
                        </IconButton>
                    </Box>
                    <Box mt={2} display="flex" flexDirection="column" alignItems="flex-start">
                        {!activate &&
                            <>
                                <Typography variant="h4" style={{ fontWeight: '400' }}>
                                    Are you sure that you want to deactivate the selected {headline}? <br></br> <br></br> The value will be set inactive and can no longer be selected in dropdown fields. However, previous links will remain and you can reactivate the value if you wish.
                                </Typography>
                                {user &&
                                    <Typography variant="h4" style={{ fontWeight: '400' }}>
                                        <br />Deactivating the user will update its role to <b>New User</b>. However, the user will still be able to access the application. If you want to grant or deny access to a user, please contact the Administrator to adjust the <b>KIRA</b> role VWAG_TONIC42_USER.
                                    </Typography>
                                }
                            </>
                        }
                        {activate &&
                            <>
                                <Typography variant="h4" style={{ fontWeight: '400' }}>
                                    Are you sure that you want to reactivate the selected {headline}? <br></br> <br></br> The value will be reactivated and can be selected in dropdown fields from now on.
                                </Typography>
                                {user &&
                                    <Typography variant="h4" style={{ fontWeight: '400' }}>
                                        <br />Reactivating the user has no effect on the role or the ability of this user to access the application. If you want to grant or deny access to a user, please contact the Administrator to adjust the <b>KIRA</b> role VWAG_TONIC42_USER.
                                    </Typography>
                                }
                            </>
                        }
                    </Box>

                    <Box mt={4} display="flex" alignItems="center" justifyContent="space-between">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                        >
                            {activate ? "Reactivate" : "Deactivate"}
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default DeleteData;