import makeStyles from '@mui/styles/makeStyles';
import { buttonHoverPetrol800, grey1000, grey200, grey800, petrol800 } from '../helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    paper: {
        textAlign: 'center',
        backgroundColor: '#FFF',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.1)',
    },
    title: {
        display: 'flex',
        lineHeight: '40px',
        letterSpacing: '0.6px',
    },
    border: {
        border: `1px ${grey200}`,
        borderStyle: 'solid none none none',
        minHeight: '48px',
    },
    text: {        
        paddingRight: '8px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    head: {
        borderBottom: `1px solid ${grey200}`,
    },
    header: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.8px',
        textAlign: 'left',
        color: grey800,
        height: '30px',
        paddingTop: '8px',
        paddingBottom: '8px',
    },
    header1: {
        minWidth: '25%',
        textAlign: 'left',
    },
    header1_text: {
        minWidth: '25%',
        maxWidth: '25%',
        textAlign: 'left',
        fontSize: props => props.fontSizeTable,
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: petrol800,
        display: 'flex',
    },
    header2: {
        minWidth: '6.5%',
        textAlign: 'left',
    },
    header2_text: {
        minWidth: '6.5%',
        maxWidth: '6.5%',
        textAlign: 'left',
        fontSize: props => props.fontSizeTable,
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    header3: {
        minWidth: '10%',
        textAlign: 'left',
    },
    header3_text: {
        minWidth: '10%',
        maxWidth: '10%',
        textAlign: 'left',
        fontSize: props => props.fontSizeTable,
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    header4: {
        minWidth: '5%',
        textAlign: 'left',
    },
    header4_text: {
        minWidth: '5%',
        maxWidth: '5%',
        textAlign: 'left',
        fontSize: props => props.fontSizeTable,
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    header5: {
        minWidth: '12.5%',
        textAlign: 'left',
    },
    header5_text: {
        minWidth: '12.5%',
        maxWidth: '12.5%',
        textAlign: 'left',
        fontSize: props => props.fontSizeTable,
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    header6: {
        minWidth: '17.5%',
        textAlign: 'left',
    },
    header6_text: {
        minWidth: '17.5%',
        maxWidth: '17.5%',
        textAlign: 'left',
        fontSize: props => props.fontSizeTable,
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    header7: {
        minWidth: '13.5%',
        textAlign: 'left',
    },
    header7_text: {
        minWidth: '13.5%',
        maxWidth: '13.5%',
        textAlign: 'left',
        fontSize: props => props.fontSizeTable,
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    header8: {
        minWidth: '10%',
    },
    userimage: {
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      height: '32px',
      width: '32px',
      borderRadius: '16px',
      display: 'flex',
      paddingRight: '16px'
    },
    hover_blue: {
        '&:hover': {
            backgroundColor: buttonHoverPetrol800,
          },
    },
    document_name: {
        padding: '12px 12px',
        overflowWrap: 'break-word'
    }
}));