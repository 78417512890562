import { gql } from "@apollo/client";

export const PROJECTTYPES = (active) => gql`
query { projectTypes(input: {active: ${active}})  {
  projectTypeId
  name
  }
}
`;

export const LEADINGDEVELOPMENTS = (active) => gql`
query { leadingDevelopments(input: {active: ${active}})  {
  leadingDevelopmentId
  name
  }
}
`;

export const ALLLEADINGDEVELOPMENTS = gql`
query { 
  leadingDevelopments{
    leadingDevelopmentId
    name
    active
  }
}
`;

export const PLATFORMS = (active) => gql`
query { platforms(input: {active: ${active}})  {
  platformId
  name
  }
}
`;

export const ALLPLATFORMS = gql`
query {
  platforms {
    platformId
    name
    active
  }
}
`;

export const CELLSUPPLIER = (id) => gql`
query {
  cellSupplier(cellSupplierId: ${id}){
    cellSupplierId
    name
    rating{
      ratingId
      name
    }
    ratingDate
    supplierPortfolio{
      supplierPortfolioId
      name
    }
    street
    number
    postalCode
    city
    contact{
      userId,
      firstName,
      lastName
    }
  }
}
`;

export const CELLSUPPLIERS_SLIM = (active) => gql`
query { cellSuppliers (input: {active: ${active}})  {
  cellSupplierId
  name
}
}
`;

export const ALLCELLSUPPLIERS_SLIM = gql`
query { cellSuppliers {
  cellSupplierId
  name
  active
}
}
`;

export const ALLCELLSUPPLIERS = gql`
query { cellSuppliers {
  cellSupplierId
  name
  active
  rating{
    ratingId
    name
  }
  supplierPortfolio{
    supplierPortfolioId
    name
  }
  street
  number
  postalCode
  city
  contact{
    userId,
    firstName,
    lastName
  }
  }
}
`;

export const ROLES = (active) => gql`
query { 
  projectRoles (input: {active: ${active}})  {
    roleId
    name
  }
}
`;

export const ALLROLES = gql`
query { 
  projectRoles {
    roleId
    name
    active
  }
}
`;

export const ALLUSERS = gql`
query { users {
    userId
    avatarId
    firstName
    lastName
    active
    department
    projects {
      projectId
      role {
        roleId
        name
        }
      name
    }
    role {
      roleId
      role
    }
  }
}
`;

export const ALLUSERS_SLIM = gql`
query { users {
    userId
    firstName
    lastName
    avatarId
    active
  }
}
`;

export const USERS_SLIM = (active) => gql`
query { users (input: {active: ${active}})  {
    userId
    firstName
    lastName
    avatarId
  }
}
`;

export const USER = (id) => gql`
query{
  user(userId: ${id}){
    userId
    avatarId
    firstName
    lastName
    department
    active
    projects {
      projectId
      role {
        roleId
        name
        }
      name
    }
    role {
      roleId
      role
    }
  }
}
`;

export const PARAMETERS = (active) => gql`
query{ 
  parameters (input: {active: ${active}})  {
    parameterId
    name
    physicalQuantity{
      physicalQuantityId
      name
    }
    unit{
      unitId
      abbreviation
      name
    }
    description
    active
  }
}
`;

export const ALLPARAMETERS = gql`
query{ 
  parameters {
    parameterId
    name
    physicalQuantity{
      physicalQuantityId
      name
    }
    unit{
      unitId
      abbreviation
      name
    }
    description
    active
  }
}
`;

export const TESTSPECIFICATIONS = (draft, latestVersion) => gql`
query{ 
  testSpecifications(input: {filter: {allowDrafts: ${draft}, isLatestVersion: ${latestVersion}}}) {
    testSpecificationId
    name
    tag
    category {
      categoryId
      name
    }
    version
    groupStandardReference{
      groupStandardReferenceId
      name
    }
    approval {
      approvalId
      name
    }
  }
}
`;

export const PROJECTS = (initial) => gql`
query{
  projectsConnection(first: ${initial}){
  edges{
    node {
      projectId
      name
      isLocked
      platforms{name}
      cellSupplier{name}
      nominalCapacity
      testResult{testsPassed testsFailed testsNotEvaluated testsNotAvailable}
      samples{name hasTestCases}
      users{
        userId
        firstName 
        lastName 
        avatarId
        projects {
          role {
            name
          }
        }
      }
    }
    cursor
  }
  pageInfo {
    startCursor
    endCursor
    hasNextPage
  }
  total
}
}
`;

export const MYPROJECTS = (myInitial) => gql`
query{
  myProjectsConnection(first: ${myInitial}){
  edges{
    node {
      projectId
      name
      isLocked
      platforms{name}
      cellSupplier{name}
      nominalCapacity
      testResult{testsPassed testsFailed testsNotEvaluated testsNotAvailable}
      samples{name hasTestCases}
      users{
        userId
        firstName 
        lastName 
        avatarId
        projects {
          role {
            name
          }
        }
      }
    }
    cursor
  }
  pageInfo {
    startCursor
    endCursor
    hasNextPage
  }
  total
}
}
`;

export const MOREPROJECTS = (first, after) => gql`
query{
  projectsConnection(first: ${first}, after: "${after}"){
  edges{
    node {
      projectId
      name
      isLocked
      platforms{name}
      cellSupplier{name}
      nominalCapacity
      testResult{testsPassed testsFailed testsNotEvaluated testsNotAvailable}
      samples{name hasTestCases}
      users{
        userId
        firstName 
        lastName 
        avatarId
        projects {
          role {
            name
          }
        }
      }
    }
    cursor
  }
  pageInfo {
    startCursor
    endCursor
    hasNextPage
  }
}
}
`;

export const MOREMYPROJECTS = (myFirst, myAfter) => gql`
query{
  myProjectsConnection(first: ${myFirst}, after: "${myAfter}"){
  edges{
    node {
      projectId
      name
      isLocked
      platforms{name}
      cellSupplier{name}
      nominalCapacity
      testResult{testsPassed testsFailed testsNotEvaluated testsNotAvailable}
      samples{name hasTestCases}
      users{
        userId
        firstName 
        lastName 
        avatarId
        projects {
          role {
            name
          }
        }
      }
    }
    cursor
  }
  pageInfo {
    startCursor
    endCursor
    hasNextPage
  }
}
}
`;

export const PROJECTDETAIL_HEADER = (id) => gql`
query{
  project(projectId: ${id}){
    projectId
    name
    platforms {
      name
    }
    cellSupplier {
      name
    }
    nominalCapacity    
  }
}
`;

export const PROJECTDETAIL = (id) => gql`
query{
  project(projectId: ${id}){
    projectId
    name
    isLocked
    platforms {
      platformId
      name
    }
    cellSupplier {
      name
    }
    nominalCapacity
    cellIndex
    description
    leadingDevelopment {
      leadingDevelopmentId
      name
    }
    sopYear
    sopWeek
    cellSpecification
    cellSpecificationLink
    testResult {
      testsPassed
      testsFailed
      testsNotEvaluated
      testsNotAvailable
    }
    users {
      userId
      avatarId
      firstName
      lastName
      projects {
        projectId
        role {
          roleId
          name
        }
      }
    }
    documents {
      docId
      name
      classification {
        docClassId
        classification
      }
      location
      version
      size
      type
      uploadedBy {
        avatarId
        firstName
        lastName
      }
      uploadedAt
    }
    samples {
      sampleId
      name      
      hasTestCases
      subSamples{
        subSampleId
        name
        hasTestCases
        isCellDataSheetLocked
        documents {
          docId
          name
          classification {
            docClassId
            classification
          }
          location
          version
          size
          type
          uploadedBy {
            avatarId
            firstName
            lastName
          }
          uploadedAt
        }
        indices{
          indexId
          name
          info
        }
      }
    }
  }
}
`;

export const TESTSPECIFICATION = (id) => gql`
query{
  testSpecification(testSpecificationId: ${id}) {
    testSpecificationId
    name
    tag
    category{
      categoryId
      name
    }
    version
    groupStandardReference {
      groupStandardReferenceId
      name
    }
    approval {
      approvalId
      name
    }
    requirementIds
    description
    terminationCriteria
    testProtocolContents
    postCondition
    reference
    testDuration
    pretreatmentSteps {
      id
      number
      stepDescription
    }
    procedureSteps {
      id
      number
      stepDescription
    }
    relations {
      testSpecificationId      
      version
      tag
    }
    parameters {
      assignParameterId
      parameter {
        name
        description
      }
      conditions {
        id
        condition
      }
    }
    documents {
      docId
      name
      classification {
        docClassId
        classification
      }
      location
      version
      type
      size
      uploadedBy {
        avatarId
        firstName
        lastName
      }
      uploadedAt
    }
  }
}
`;

export const CATEGORIES = (active) => gql`
query{
  categories (input: {active: ${active}})  {
    categoryId
    name
  }
}
`;

export const ALLCATEGORIES = gql`
query{
  categories {
    categoryId
    name
    active
  }
}
`;

export const APPROVALS = (active) => gql`
query{
  approvals (input: {active: ${active}})  {
    approvalId
    name
  }
}
`;

export const ALLAPPROVALS = gql`
query{
  approvals {
    approvalId
    name
    active
  }
}
`;

export const GROUPSTANDARDREFERENCES = (active) => gql`
query { 
  groupStandardReferences(input: {active: ${active}})  {
  groupStandardReferenceId
  name
  }
}
`;

export const ALLGROUPSTANDARDREFERENCES = gql`
query { 
  groupStandardReferences {
    groupStandardReferenceId
    name
    active
  }
}
`;

export const TESTSTATUSES = gql`
query{
  testStatuses {
    testStatusId
    name
  }
}
`;

export const TESTINSTANCES = (active) => gql`
query{
  testInstances(input: {active: ${active}})  {
    testInstanceId
    name
  }
}
`;

export const ALLTESTINSTANCES = gql`
query{
  testInstances {
    testInstanceId
    name
    active
  }
}
`;

export const TESTRESULTS = gql`
query{
  testResults {
    testResultId
    name
  }
}
`;

export const ALLAGINGSTATUS = gql`
query { 
  agingStatus {
    agingStatusId
    name
    description
    active
  }
}
`;

export const AGINGSTATUS = (active) => gql`
query { 
  agingStatus (input: {active: ${active}})  {
    agingStatusId
    name
    description
  }
}
`;

export const PHYSICALQUANTITIES = (active) => gql`
query{
  physicalQuantities (input: {active: ${active}})  {
    physicalQuantityId
    name
    active
  }
}
`;

export const ALLPHYSICALQUANTITIES = gql`
query{
  physicalQuantities {
    physicalQuantityId
    name
    active
  }
}
`;

export const UNITS = (active) => gql`
query { 
  units (input: {active: ${active}})  {
    unitId
    abbreviation
    name
    active
  }
}
`;

export const ALLUNITS = gql`
query { 
  units {
    unitId
    abbreviation
    name
    active
  }
}
`;

export const TESTCASES = (id) => gql`
query{
  testCases(indexId: ${id}) {
    category{
      categoryId
      name
    }
    testCases{
      testCaseId
      duts{
        dutId
        startDate
        endDate
        testStatus{
          testStatusId
          name
        }
        testResult{
          testResultId
          name
        }
      }
      requirementIds
      dutInfo{
        planned
        started
        finished
      }
      agingStatus{
        agingStatusId
        name
      }
      testStatus{
        testStatusId
        name
      }
      testResult{
        testResultId
        name
      }
      startDate
      plannedStartDate
      endDate
      plannedEndDate
      calendarWeeks{
        cw
        status
      }
      testInstance{
        testInstanceId
        name
      }
      condition
      testSpecification{
        testSpecificationId
        name
        version
        groupStandardReference {
          name
        }        
      }
      commentCount
      comments {
        commentId
        comment
        timestamp
      }
    }
  }
}
`;

export const TESTCASE = (id) => gql`
query{
  testCase(testCaseId: ${id}) {
    testCaseId
    duts{
      dutId
      startDate
      endDate
      testStatus{
        testStatusId
        name
      }
      testResult{
        testResultId
        name
      }
    }    
    agingStatus{
      agingStatusId
      name
    }
    requirementIds
    dutInfo{
      planned
      started
      finished
    }
    testStatus{
      testStatusId
      name
    }
    testResult{
      testResultId
      name
    }
    startDate
    plannedStartDate
    endDate
    plannedEndDate
    calendarWeeks{
      cw
      status
    }
    testInstance{
      testInstanceId
      name
    }
    parameters{
      isPreset
      parameters{
        assignParameterId
        parameter {
          parameterId
          name
          description
          unit {
            abbreviation
          }
          active
        }
        conditions {
          id
          condition
          isActive
        }
      }
    }
    condition
    documents {
      docId
      name
      classification {
        docClassId
        classification
      }
      location
      version
      size
      type
      uploadedBy {
        avatarId
        firstName
        lastName
      }
      uploadedAt
    }
    testSpecification{
      testSpecificationId
      name
      version      
    }
    commentCount
    comments {
      commentId
      user{
        userId
        avatarId
        firstName
        lastName
      }
      comment
      timestamp
    }
    }  
}
`;

export const SUPPLIERPORTFOLIOS = (active) => gql`
query { supplierPortfolios(input: {active: ${active}})  {
  supplierPortfolioId
  name
  }
}
`;

export const ALLSUPPLIERPORTFOLIOS = gql`
query { 
  supplierPortfolios{
    supplierPortfolioId
    name
    active
  }
}
`;

export const RATINGS = (active) => gql`
query { ratings(input: {active: ${active}})  {
  ratingId
  name
  }
}
`;

export const ALLRATINGS = gql`
query { 
  ratings {
    ratingId
    name
    active
  }
}
`;

export const APPROLES = gql`
query { roles {
  roleId
  role
  }
}
`;

export const CHECKROLE = gql`
query { checkRole {
    roleId
    role
  }
}
`;

export const MYACCOUNT = gql`
query { myAccount {
    userId
    avatarId
    firstName
    lastName
    department
    active
    projects {
      projectId
      role {
        roleId
        name
      } 
      name
    }
    role {
      roleId
      role
    }
  }
}
`;

export const DOCUMENTCLASSES = (docDomain) => gql`
query { 
  documentClasses(input: {requestDomain: ${docDomain}})  {
    docClassId
    classification
  }
}
`;


export const CREATEREPORT = (indexId) => gql`
query{
  createReport(input: {indexId: "${indexId}"}) {
    testCases{
      testResult {
        testResultId
        name
      }
      testCases{
        category {
          categoryId
          name
        }
        testCases{
          testCaseId
          dutInfo{
            planned
            started
            finished
          }
          agingStatus{
            agingStatusId
            name
          }
          testStatus{
            testStatusId
            name
          }
          testResult{
            testResultId
            name
          }
          startDate
          endDate
          testInstance{
            testInstanceId
            name
          }
          testSpecification{
            testSpecificationId
            name
            version
            groupStandardReference {
              name
            }        
          }
          requirementIds
        }
      }
    }
    categoryTestScores {
      category
      testScore {
        testsPassed
        testsFailed
        testsNotEvaluated
        testsNotAvailable
      }
    }
  }  
}
`;

export const CELLDATASHEET = (subSampleId, cellDataType) => gql`
query { 
  cellDataSheet(subSampleId: ${subSampleId}, cellDataType: ${cellDataType})  {
    __typename
    ... on CellPowerLimit {
      pulseTime,
      temperatures {
        __typename
        ... on CellPowerLimitTemperature {
          temperature
          socs {
            __typename
            ... on CellPowerLimitSoc {
              cellDataId
              isCharged
              stateOfCharge
              soc
              isSimulated
              value
            }
          }
        }
      }
    }
    __typename
    ... on CellOcvCharacteristic  {
      agingStatus,
      temperatures {
        __typename
        ... on CellOcvCharacteristicTemperature  {
          temperature
          socs {
            __typename
            ... on CellOcvCharacteristicSoc {
              cellDataId
              isCharged
              soc
              isSimulated
              value
            }
          }
        }
      }
    }
  }
}
`;
