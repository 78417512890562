import React, {useContext, useState} from 'react';
import { useReactiveVar } from '@apollo/client';
import { calcLatestSample, sampleVar, subSampleVar } from '../../../../operations/localeStorage'
import { useStyles } from '../UniversalDialog.styles.js';
import { Button, Dialog, IconButton, DialogContent, Box, Typography } from '@mui/material';
import Icon from '../../../../assets/icons/Icon.js';
import { petrol700, petrol800 } from '../../helper/MuiStyles';
import { SnackbarContext } from '../../contexts/SnackBarContext';

function DeleteState(props) {
    const { mutationFunction, formSample, setFormSample, changeSubSample, index, subSampleName, setCountPSamples, setCountASamples, setCountBSamples, setCountCSamples } = props;
    const { setMessage } = useContext(SnackbarContext);
    const [show, setShow] = useState(false);
    const selectedSamplePosition = useReactiveVar(sampleVar);
    const selectedSubSamplePosition = useReactiveVar(subSampleVar);

    const ToggleModal = () => {
        setShow(!show);
    };

    const deleteSubSample = () => {
        calcLatestSample(false)
        let value = JSON.parse(JSON.stringify(formSample));
        value[selectedSamplePosition]?.subSamples.splice(index, 1);
        setFormSample(value);
        if (index > 0) {
            let subName = formSample[selectedSamplePosition]?.subSamples[index - 1]?.name;
            changeSubSample(subName, index - 1);
        }
        if (index === 0) {
            let subName = formSample[selectedSamplePosition]?.subSamples[index + 1]?.name;
            changeSubSample(subName, index);
        }
        mutationFunction({ variables: { subSampleId: formSample[selectedSamplePosition].subSamples[selectedSubSamplePosition].subSampleId } });
        setMessage('Sub Sample has been deleted');
        //updating count for further subsamples
        switch (selectedSamplePosition) {
            case 0:
                setCountPSamples(value[0]?.subSamples[value[selectedSamplePosition]?.subSamples.length - 1]?.name);
                break;
            case 1:
                setCountASamples(value[1]?.subSamples[value[selectedSamplePosition]?.subSamples.length - 1]?.name);
                break;
            case 2:
                setCountBSamples(value[2]?.subSamples[value[selectedSamplePosition]?.subSamples.length - 1]?.name);
                break;
            case 3:
                setCountCSamples(value[3]?.subSamples[value[selectedSamplePosition]?.subSamples.length - 1]?.name);
                break;
            default:
                break;
        }
        setShow(!show);
    };

    const styleProps = {
        deleteIconHoverBackground: petrol700,
      };

    const classes = useStyles(styleProps);

    return <>
        <IconButton className={classes.delete_icon_wrapper} style={{marginLeft: '8px'}} onClick={ToggleModal}>
            <Icon iconName={"AddIcon"} fill={"#FFF"} />
        </IconButton>

        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={show}
            onClose={ToggleModal}
        >
            <DialogContent>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h2">
                        Delete {subSampleName}-{selectedSamplePosition === 0 ? 'Prototype' : 'Sample'}
                    </Typography>
                    <IconButton onClick={ToggleModal} className={classes.hover_blue}>
                        <Icon iconName={"CloseIcon"} fill={petrol800} />
                    </IconButton>
                </Box>
                <Box mt={2} display="flex" alignItems="center">
                    <Typography variant="h4" style={{ fontWeight: '400'}}>
                        Are you sure that you want to delete the selected sub sample? <br/> All indices and test cases of this sub sample will be deleted.
                    </Typography>
                </Box>
                <Box mt={4} display="flex" alignItems="center">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={deleteSubSample}
                    >
                        Delete
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    </>
}

export default DeleteState;