import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { TEST_RESULT_MAPPER } from '../../other/helper/utilsMapper/utilsMapper'
import { grey200 } from '../../other/helper/MuiStyles';

const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      padding: '16px'
    },
    number: ({ background, radius, width, height, color, margin, fontSize }) => ({ 
      background: background || grey200,
      borderRadius: radius || '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: width || '128px',
      height: height || '28px',
      color: color || 'white',
      fontSize: fontSize,
      lineHeight: '28px',
      margin: margin || `0`,
      fontWeight: '700'
    }),
  }
  ));

function TestResult(props) {
    const { testResultName, width, height, radius, margin, sizeWindow } = props;

    const style_props = {
      background: TEST_RESULT_MAPPER.BGCOLOR[testResultName],
      width: width,
      height: height,
      borderRadius: radius,
      color: TEST_RESULT_MAPPER.COLOR[testResultName],
      margin: margin,
      fontSize: (sizeWindow < 5) ? '14px' : (sizeWindow >= 5 ? '16px' : '6px'),
    }
    const classes = useStyles(style_props);

    return (
      <div className={classes.number}>
          {TEST_RESULT_MAPPER.NAME[testResultName]}
      </div>
    )
}


export default TestResult;