import React, {useContext, useState} from 'react';
import { Button, Dialog, IconButton, DialogContent, Box, Typography } from '@mui/material';
import Icon from '../../../../assets/icons/Icon.js';
import { useStyles } from '../UniversalDialog.styles.js';
import {
    SnackbarContext, SnackbarType
} from '../../contexts/SnackBarContext.js';
import { petrol800 } from '../../helper/MuiStyles.js';
import { DELETEDOCUMENT } from '../../../../operations/mutations/mutations.js';
import { useMutation } from '@apollo/client';
import { PROJECTDETAIL, TESTCASE, TESTSPECIFICATION } from '../../../../operations/queries/query.js';
import { currentProjectId, currentTestCaseId, currentTestSpecificationId } from '../../../../operations/localeStorage.js';

function DeleteDocument(props) {
    const { docId } = props;
    const { setIsSnackbarOpen, setMessage, setSnackbarType } = useContext(SnackbarContext);

    const handleOnError = async (error) => {
        setMessage(error.message);
        setSnackbarType(SnackbarType.ERROR);
        setIsSnackbarOpen(true);
    };


    const handleOnCompleted = async () => {
        setSnackbarType(SnackbarType.SUCCESS);
        setIsSnackbarOpen(true);
    };

    const [deleteDocument] = useMutation(DELETEDOCUMENT, {
        onCompleted: handleOnCompleted,
        onError: handleOnError,
        refetchQueries: [{ query: TESTSPECIFICATION(currentTestSpecificationId()) }, { query: PROJECTDETAIL(currentProjectId()) }, { query: TESTCASE(currentTestCaseId()) }],
        awaitRefetchQueries: true
    });






    const [show, setShow] = useState(false);

    const ToggleModal = () => {
        setShow(!show);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        deleteDocument({ variables: { documentId: docId } });
        setMessage('Document has been deleted.');
        setShow(false);
    };

    const classes = useStyles();

    return <>
        <IconButton onClick={ToggleModal} className={classes.hover_blue}>
            <Icon iconName={"TrashIcon"} fill={petrol800} />
        </IconButton>

        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={show}
            onClose={ToggleModal}
        >
            <DialogContent >
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h2">
                        Delete Document
                    </Typography>
                    <IconButton onClick={ToggleModal} size="large">
                        <Icon iconName={"CloseIcon"} fill={petrol800} />
                    </IconButton>
                </Box>
                <Box mt={2} display="flex" alignItems="center">
                    <Typography variant="h4" style={{ fontWeight: '400' }}>
                        Are you sure that you want to delete this document?
                    </Typography>
                </Box>
                <Box mt={4} display="flex" alignItems="center" justifyContent="space-between">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Delete
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    </>
}

export default DeleteDocument;