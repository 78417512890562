import React from "react";
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles'

export function KsuNotification(borderColor) {
    
    const useStyles = makeStyles({
        confidential_seperator: {
            height: '0.5px',
            width: '80%',
            backgroundColor: borderColor.borderColor,
        },
        confidential: {
            textAlign: 'center',
            fontSize: '11px',
            padding: '3px 6px 3px 6px'
        },
        confidential_box: {
            border: `1px solid ${borderColor.borderColor}`,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
    });
  
    const classes = useStyles();
    
    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" alignItems="center">
                <Box className={classes.confidential_box}>
                    <Box className={classes.confidential}>CONFIDENTIAL</Box>
                    <Box className={classes.confidential_seperator}></Box>
                    <Box className={classes.confidential}>VERTRAULICH</Box>
                </Box>
                <Box className={classes.confidential_box} style={{ 'marginLeft': '16px' }}>
                    <Box className={classes.confidential}>KSU</Box>
                    <Box className={classes.confidential_seperator}></Box>
                    <Box className={classes.confidential}>5.3</Box>
                </Box>
            </Box>
        </Box >
    )
}