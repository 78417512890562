import makeStyles from '@mui/styles/makeStyles';
import { buttonHoverPetrol800, grey100, grey1000, grey200, grey400, grey600, grey800, petrol800, petrol900, signalRed800 } from '../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: grey100,
        padding: '24px',
    },
    paper: {
        minWidth: "1232px",
        // maxHeight: 'calc(100vh - 128px - 24px - 24px)',
        backgroundColor: '#FFF',
    },
    snackbar: {
        position: 'sticky',
        height: '100%',
        top: '0',
        zIndex: '1'
    },
    comment_wrapper: {
        maxHeight: '400px',
        overflow: 'auto',
        marginBottom: '32px',
        '&::-webkit-scrollbar': {
            width: '0.4em',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: grey600,
            borderRadius: '8px',
        }
    },
    comment_box: {
        border: `1px solid ${grey200}`,
        padding: '16px',
        borderRadius: '2px',
    },
    comment_user: {
        fontWeight: '400',
        lineHeight: '27px',
        letterSpacing: '0.6px',
        marginRight: '8px',
    },
    comment_timestamp: {
        color: grey400,
        fontSize: '14px',
        fontWeight: '400',
    },
    comment_text: {
        textAlign: 'left',
        lineHeight: '27px',
        letterSpacing: '0.6px',
        marginTop: '8px',
    },
    newComment: {
        fontStyle: 'normal',
        fontSize: '18px',
        lineHeight: '26px !important',
        letterSpacing: '0.6px',
        padding: '16px',
        border: `1px solid ${grey400}`,
        boxSizing: "border-box",
        borderRadius: "2px",
        minHeight: '60px',
        height: 'auto',
        alignItems: 'start',
        display: 'inline-grid',
    },
    comment_button: {
        align: 'right',
        marginRight: 'calc(8px + 0.4em)',
    },
    icon: {
        color: petrol800,
    },
    freeText: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '28px',
        letterSpacing: '0.3px',
        width: '300px',
    },
    freeText2: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '28px',
        letterSpacing: '0.3px',
        padding: '16px',
        border: `1px solid ${grey400}`,
        boxSizing: "border-box",
        borderRadius: "2px",
        marginTop: '8px',
        minHeight: '177.33px',
        height: 'auto',
        alignItems: 'start',
        display: 'inline-grid',
    },
    freeText3: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        display: 'block',
        lineHeight: '28px',
        letterSpacing: '0.3px',
        padding: '16px',
        border: `1px solid ${grey400}`,
        boxSizing: "border-box",
        borderRadius: "2px",
        minHeight: '120px',
        height: 'auto',
        alignItems: 'start',
    },
    box: {
        maxWidth: '160px',
    },
    tbd_text: {
        marginLeft: '16px',
        color: grey600,
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        lineHeight: '18px',
        letterSpacing: '0.8px',
        fontWeight: '400',
    },
    input_title: {
        marginBottom: '4px',
    },
    line: {
        borderBottom: `1px solid ${grey200}`,
    },
    noselectbox: {
        border: `1px solid ${grey200}`,
        height: '40px',
        minWidth: '240px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingLeft: '8px',
        borderRadius: '2px',
        fontSize: '16px',
        lineHeight: '28px',
        letterSpacing: '0.3px',
        color: grey400,
    },
    label: {
        fontSize: '14px',
        fontWeight: 'bold',
        whiteSpace: 'nowrap'
    },
    description: {
        border: `1px solid ${grey200}`,
        borderRadius: '2px',
        height: '200px',
        overflow: 'auto',
        padding: '8px',
        fontSize: '18px',
        lineHeight: '27px',
        letterSpacing: '0.6px',
        color: grey400
    },
    info_values: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: '24px',
      letterSpacing: '0.8px',
    },
    input_title_big: {
        marginBottom: '8px',
    },
    steps_box: {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
    },
    parameter_add_first: {
        color: grey800,
        fontWeight: "400",
        lineHeight: "28px",
        letterSpacing: "0.3px",
    },
    parameter_box: {
        backgroundColor: grey100,
    },
    condition_title: {
        height: '40px',
        lineHeight: "18px",
        letterSpacing: "0.7px",
        minWidth: '183px',
        maxWidth: '183px',
        display: 'flex',
        alignItems: 'center',
    },
    condition_box: {
        display: 'flex',
        alignItems: 'center',
        minWidth: '183px',
        maxWidth: '183px',
        height: '40px',
        backgroundColor: '#FFFFFF',
        border: `1px solid ${grey400}`,
        boxSizing: 'border-box',
        borderRadius: '2px',
        marginBottom: '16px',
    },
    condition_value: {
        maxWidth: '140px',
        border: 'none',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px !important',
        letterSpacing: '0.6px',
    },
    condition_unit: {
        display: "flex",
        justifyContent: "center",
        fontWeight: '400',
        width: "40px",
        lineHeight: "28px",
        letterSpacing: "0.6px",
        marginBottom: '0',
        borderLeft: `1px solid ${grey400}`,
        color: grey1000,
    },
    assigned_parameter: {
        height: '24px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
        backgroundColor: '#FFFFFF',
        padding: '8px 16px',
        borderRadius: '8px',
        marginBottom: '16px',
    },
    userimage: {
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '32px',
        width: '32px',
        borderRadius: '16px',
        display: 'flex',
        marginRight: '8px',
    },
    alert_message: {
        marginTop: '4px',
        color: signalRed800,
        textAlign: 'left',
    },
    error_text: {
        marginTop: '8px',
        fontWeight: '400',
    },
    error_type: {
        padding: '0px 24px',
        textAlign: 'center',
        marginTop: '4px',
        fontWeight: '400',
        color: grey600,
    },
    supplierimage: {
      backgroundImage: props => `url(${props.supplierImg})`,
      backgroundPosition: 'right',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      height: '35px',
      minWidth: '100px',
    },
    subheader: {
      lineHeight: "24px",
      letterSpacing: "0.4px",
    },
    tag: {
      border: `1px solid ${signalRed800}`,
      borderRadius: '20px',
      height: '30px',
      padding: props => props.paddingLockBadge,
      fontStyle: 'normal',
      fontWeight: '700',
      fontFamily: 'VWAG TheSans',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.6px',
      color: signalRed800,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  },
  error_icon_wrapper: {
      marginLeft: '8px',
      transform: 'rotate(45deg)',
  },
  trash_icon_wrapper: {
        width: '32px',
        height: '32px',
      marginRight: '5px',
      '&:hover': {
          backgroundColor: buttonHoverPetrol800,
        },
  },
  parameter_preset: {
    marginLeft: '24px',
    marginBottom: '24px',
    fontWeight: '400',
    color: grey800,
  },  
  condition_visible: {
    paddingLeft: '16px',
    paddingRight: '16px',
    cursor: 'pointer',
    height: '38px',
    borderRadius: '8px',
    border: `1px solid ${petrol800}`,
    backgroundColor: petrol800,
    color: '#FFF',
    '&:hover': {
        backgroundColor: petrol900,
        border: `1px solid ${petrol900}`,
      },
  },  
  condition_invisible: {
    paddingLeft: '16px',
    paddingRight: '16px',
    cursor: 'pointer',
    height: '38px',
    borderRadius: '8px',
    border: `1px solid ${petrol800}`,
    color: petrol800,
    '&:hover': {
        backgroundColor: buttonHoverPetrol800,
      },
  }
}));