import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useStyles } from './TestSpecificationBox.styles.js';
import { handleCategoryIcon } from '../../other/helper/IconHandler/IconHandler';
import Icon from '../../../assets/icons/Icon.js';
import DraftInfo from '../../other/tooltips/DraftInfo/DraftInfo.js';
import ReferenceInfo from '../../other/tooltips/ReferenceInfo/ReferenceInfo';
import { grey600 } from '../../other/helper/MuiStyles.js';

function TestSpecificationBox({ i }) {
  const classes = useStyles();

  return (
    <>
      <Box pt={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" className={classes.header}>
        <Box px={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Icon fill={grey600} iconName={handleCategoryIcon(i.category.name)} />
          <Typography variant="h5" px={1} py={0.5} className={classes.category}>{i.category.name}</Typography>
        </Box>
        {i.tag === "DRAFT" &&
          <DraftInfo/>
        }
      </Box>
      <Box pt={2.5} pb={1} px={2} display="flex" flexDirection="row" alignItems="center">
        <Typography variant="h3">
          {i.name}
        </Typography>
      </Box>
      <Box py={1} px={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box className={classes.grey_title}>CURRENT VERSION</Box>
        <Box className={classes.black_value}>{i.version}</Box>
      </Box>
      <Box py={1} px={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box className={classes.grey_title}>REFERENCE</Box>
        <ReferenceInfo reference={i?.groupStandardReference?.name} accessPoint="TestSpecificationBox"/>
      </Box>
      <Box pt={1} pb={2} px={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box className={classes.grey_title}>APPROVAL</Box>
        <Box className={classes.black_value}>{i.approval?.name === undefined ? "-" : i.approval?.name}</Box>
      </Box>
    </>
  );
}


export default TestSpecificationBox;