import React from 'react';
import { useNavigate } from "react-router-dom";
import { Box, Button, AppBar, Toolbar, IconButton, Typography } from '@mui/material';

import Icon from '../../assets/icons/Icon.js';

import { KsuNotification } from './KsuNotification.js';

import { useStyles } from './StickyBar.styles';
import ScrollUpIcon from '../other/helper/ScrollUpIcon/ScrollUpIcon';
import { calcLatestSample } from '../../operations/localeStorage.js';
import { petrol1000, petrol800, grey1000 } from '../other/helper/MuiStyles.js';


function StickyBar({
    requiredField,
    ksuNotification,
    scrollButton,
    prevNext,
    testCaseIds,
    testCaseNames,
    currentTestCaseId,
    projectId,
    customButton,
    customDisabled,
    customOnClick,
    backButton,
    backpath,
    releaseReport,
    releaseReportComponent
}) {
    const navigate = useNavigate();
    // Calculate current index with props
    const currentIndex = testCaseIds?.indexOf(currentTestCaseId);

    // Build paths for prev and next
    const prevpath = '/testcasedetails/' + projectId + '/' + testCaseIds?.[currentIndex - 1]
    const nextpath = '/testcasedetails/' + projectId + '/' + testCaseIds?.[currentIndex + 1]

    // Navigate and send props
    const handlePrev = () => {
        navigate(prevpath,
            { state: { testCaseIds, testCaseNames } }
        )
    }

    // Navigate and send props
    const handleNext = () => {
        navigate(nextpath,
            { state: { testCaseIds, testCaseNames } }
        )
    }

    const handleBackButton = () => {
        let route = backpath ? backpath : '';
        navigate({ pathname: route });
        calcLatestSample(false);
    }

    const classes = useStyles();

    return (
        <AppBar
            classes={{
                root: classes.root,
            }}
            position="fixed" color="default" className={classes.appBar}
        >
            <Toolbar className={classes.toolbar}>

                {/* BACK */}
                <Box pr={3} display="flex" justifyContent="flex-start">
                    {backButton &&
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleBackButton}
                        >
                            <Box display="flex" alignItems="center">
                                <Icon iconName={"ArrowLeftIcon"} fill={petrol800} />
                                Back
                            </Box>
                        </Button>
                    }
                    {requiredField &&
                        <Box display="flex" alignItems="center">
                            <Typography variant="h6" className={classes.required_tag}>
                                * Mandatory field
                            </Typography>
                        </Box>
                    }
                </Box>

                {/* TEST CASE NAVIGATION */}
                <Box display="flex" justifyContent="center" className={classes.center}>
                    {ksuNotification && !prevNext &&
                        <KsuNotification borderColor={petrol1000} />
                    }
                    {prevNext && testCaseIds !== undefined &&
                        <>
                            <Box display="flex" alignItems="center" style={{height: '64px'}}>
                                <Box display="flex" alignItems="center">
                                    <Box display="flex" alignItems="center">
                                        <IconButton
                                            size="small"
                                            aria-label="prev testcase"
                                            className={classes.iconButton}
                                            onClick={handlePrev}
                                            disabled={currentTestCaseId === testCaseIds?.[0]}
                                        >
                                            <Icon iconName={"ArrowLeftIcon"} fill={"#FFF"} />
                                        </IconButton>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        {/* <Box className={classes.prevtext}>Previous</Box> */}
                                        <Typography variant="h6" style={{fontWeight: '400'}}>{testCaseNames?.[currentIndex - 1]}</Typography>
                                    </Box>
                                </Box>
                                <Box className={classes.seperator}></Box>
                                <Box display="flex" alignItems="center">
                                <Box display="flex" alignItems="center">
                                        {/* <Box className={classes.nexttext}>Next</Box> */}
                                        <Typography variant="h6" style={{fontWeight: '400', textAlign: 'right'}}>{testCaseNames?.[currentIndex + 1]}</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <IconButton
                                            size="small"
                                            aria-label="next testcase"
                                            className={classes.iconButton}
                                            onClick={handleNext}
                                            disabled={currentTestCaseId === testCaseIds?.[testCaseIds.length - 1]}
                                        >
                                            <Icon iconName={"ArrowRightIcon"} fill={"#FFF"} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    }
                </Box>

                {/* RIGHT PART */}
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                    {prevNext && <Box mr={3}><KsuNotification borderColor={grey1000} /></Box>}
                    {customButton.length > 0 && <Button data-testid={"sticky-bar-custom-button"} variant="contained" color="primary" disabled={customDisabled} onClick={customOnClick} style={{ marginRight: scrollButton ? '0px' : '32px' }}>{customButton}</Button>}
                    {releaseReport && releaseReportComponent}
                    {scrollButton && <ScrollUpIcon />}
                </Box>

            </Toolbar>
        </AppBar>
    );
}



export default StickyBar;