import Keycloak from 'keycloak-js';

const DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const REALM = process.env.REACT_APP_AUTH0_REALM;
const CLIENTID = process.env.REACT_APP_AUTH0_CLIENT_ID;
// const SECRET = process.env.REACT_APP_AUTH0_SECRET

const keycloakConfig = {
  url: DOMAIN,
  realm: REALM,
  clientId: CLIENTID,
  // clientSecret: SECRET,
}

const initOptions = {
  checkLoginIframe: false,
  pkceMethod: 'S256',
  onLoad: 'check-sso'
}

const keycloak = new Keycloak(keycloakConfig, initOptions);

export default keycloak;


