import React, {useContext, useState} from 'react';
import { Button, Dialog, IconButton, DialogContent, Box, Typography } from '@mui/material';
import { useStyles } from '../UniversalDialog.styles.js';
import Icon from '../../../../assets/icons/Icon.js';
import { SnackbarContext } from '../../contexts/SnackBarContext';
import { petrol800 } from '../../helper/MuiStyles.js';

function DeleteDut(props) {
    const { testName, testVersion, deleteDut, dutId, dutIndex, sizeWindow } = props;
    const { setMessage } = useContext(SnackbarContext);
    const [show, setShow] = useState(false);

    const handleDeleteDut = (e) => {
        e.preventDefault();
        deleteDut({ variables: { dutId: dutId } })
        setMessage('DUT has been deleted.');
        setShow(!show);
    }

    const ToggleModal = () => {
        setShow(!show);
    };
    
    const classes = useStyles();


    return <>
        <IconButton onClick={ToggleModal} className={classes.hover_blue} data-testid={"delete-dut-button"}>
            <Icon iconName={"TrashIcon"}
                  fill={petrol800}
                  height={sizeWindow < 5 ? 20 : 24}
                  width={sizeWindow < 5 ? 20 : 24}
            />
        </IconButton>

        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={show}
            onClose={ToggleModal}
        >
            <DialogContent>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h2">
                        Delete DUT #{dutIndex + 1} of {testName} V. {testVersion}
                    </Typography>
                    <IconButton onClick={ToggleModal} className={classes.hover_blue}>
                        <Icon iconName={"CloseIcon"} fill={petrol800} />
                    </IconButton>
                </Box>
                <Box mt={2} display="flex" alignItems="center">
                    <Typography variant="h4" style={{ fontWeight: '400' }}>
                        Are you sure that you want to delete the selected DUT?
                    </Typography>
                </Box>
                <Box mt={4} display="flex" alignItems="center">
                    <Button
                        variant="contained"
                        color="primary"
                        data-testid={"confirm-delete-dut-button"}
                        onClick={handleDeleteDut}
                    >
                        Delete
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    </>
}

export default DeleteDut;