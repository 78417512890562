import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from './components/other/helper/MuiStyles';
import { Box } from '@mui/material';
import '@progress/kendo-theme-default/dist/all.css';
import './App.css';

import { CDSProvider } from './components/other/contexts/CDSContext';
import Header from './components/header/Header';
import ProjectDetails from './components/projectdetails/ProjectDetails/ProjectDetails';
import TestSpecificationDetails from './components/testspecifications/TestSpecificationDetails/TestSpecificationDetails';
import TestCaseDetails from './components/testcasedetails/TestCaseDetails';
import Homepage from './components/homepage/Homepage/Homepage';
import NewUser from './components/other/helper/NewUser/NewUser';
import AddProject from './components/homepage/AddProject/AddProject';
import AddTestSpecification from './components/homepage/AddTestSpecification/AddTestSpecification';
import UserProfile from './components/userprofile/UserProfile/UserProfile';
import DataManagement from './components/datamanagement/DataManagement';
import SelectAvatar from './components/userprofile/SelectAvatar/SelectAvatar';
import AddEditSupplier from './components/suppliermanagement/AddEditSupplier/AddEditSupplier';
import SupplierManagementListTable from './components/suppliermanagement/SupplierManagementList/SupplierManagementListTable/SupplierManagementListTable';
import UserManagementListTable from './components/usermanagement/UserManagementListTable/UserManagementListTable';
import Health from './components/other/helper/Health/Health';

import { APP_ROLES_MAPPER } from './components/other/helper/utilsMapper/utilsMapper';
import ScrollToTop from './components/other/helper/ScrollToTop/scrollToTop';
import BatteryLoader from './components/other/helper/BatteryLoader/BatteryLoader';

import { useQuery } from '@apollo/client';
import { CHECKROLE } from './operations/queries/query';
import { projectRole } from './operations/localeStorage'
import {InfoSnackbar, UploadSnackBar} from './components/other/helper/Snackbars/Snackbars';
import CreateNewSpecification from './components/testspecifications/CreateNewSpecification/CreateNewSpecification';

function App() {
  const { data: myRole, error: myRoleError, loading: myRoleLoading } = useQuery(CHECKROLE);

  const [role, setRole] = useState(projectRole());

  useEffect(() => {
    projectRole(APP_ROLES_MAPPER.APP_ROLE[myRole?.checkRole?.roleId]);
    setRole(projectRole());
  }, [myRole?.checkRole?.roleId]);

  return (
    <CDSProvider>
      <Router>
        <ScrollToTop />
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <UploadSnackBar />
            {location.pathname != '/health' && <Header />}
            {myRoleError && myRoleError.message}
            {myRoleLoading &&
              <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: '100vh' }}>
                <Box mx={2} display="flex" alignItems="center" justifyContent="center" flexGrow="1">
                  <BatteryLoader type="standard" />
                </Box>
              </Box>
            }
            <InfoSnackbar />
            {/* NEW USER */}
            {!myRoleLoading && role === 'New User' ?
              <NewUser />
              :
              !myRoleLoading &&
              <Routes>
                {/* ALL */}
                <Route path={`/health`} element={<Health />} />
                <Route path="/" element={<Homepage />} />
                <Route path={`/projectdetails/:id/*`} element={<ProjectDetails />} />
                <Route path={`/testcasedetails/:id/:testCaseId`} element={<TestCaseDetails />} />
                <Route path={`/testspecification/:id`} element={<TestSpecificationDetails />} />
                <Route exact path="/profile/:userId" element={<UserProfile />} />
                <Route exact path="/selectavatar/:userId" element={<SelectAvatar />} />
                {/* ADMIN & PROJECT USER */}
                {(role === 'Administrator' || role === 'Project User') &&
                  <Route exact path="/addproject" element={<AddProject />} />
                }
                {(role === 'Administrator' || role === 'Project User') &&
                  <Route exact path="/addtestspecification" element={<AddTestSpecification />} />
                }
                {(role === 'Administrator' || role === 'Project User') &&
                  <Route exact path="/createtestspecification" element={<CreateNewSpecification />} />
                }
                {(role === 'Administrator' || role === 'Project User') &&
                  <Route exact path="/suppliermanagement" element={<SupplierManagementListTable />} />
                }
                {/* ADMIN */}
                {role === 'Administrator' &&
                  <Route exact path="/usermanagement" element={<UserManagementListTable />} />
                }
                {role === 'Administrator' &&
                  <Route exact path="/addsupplier" element={<AddEditSupplier />} />
                }
                {role === 'Administrator' &&
                  <Route path="/editsupplier/:id" element={<AddEditSupplier edit />} />
                }
                {role === 'Administrator' &&
                  <Route exact path="/datamanagement" element={<DataManagement />} />
                }
              </Routes>
            }
          </ThemeProvider>
        </StyledEngineProvider>
      </Router>
    </CDSProvider>
  );
}

export default App;