import React, {useState} from 'react';
import InputBase from '@mui/material/InputBase';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  search: {
    position: 'relative',
    borderRadius: '2px',
    backgroundColor: '#FFF',
    marginLeft: 0,
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   marginRight: '0',
    //   width: 'auto',
    // },
  },
  searchIcon: {
    padding: '0 16px',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    // padding: '16px 16px 16px 0',
    // vertical padding + font size from searchIcon
    padding: `0px 16px`,
    // transition: theme.transitions.create('width'),
    width: '320px',
    // [theme.breakpoints.up('sm')]: {
    //   width: '0ch',
    //   '&:focus': {
    //   width: '20ch',
    //   },
    // },
  },
}));

function SearchBoxUser(props) {
  const { users, setUserList } = props;
  const classes = useStyles();

  const [search, setSearch] = useState("");

  const handleSearch = () => (event) => {
    setSearch(event.target.value);
    let allUsers = users;
    let regEx = new RegExp(event.target.value, 'i');
    let listID = allUsers?.users?.filter(function (currentElement) {
      return regEx.test(currentElement.userId)
    });
    let listFirstName = allUsers?.users?.filter(function (currentElement) {
      return regEx.test(currentElement.firstName)
    });
    let listLastName = allUsers?.users?.filter(function (currentElement) {
      return regEx.test(currentElement.lastName)
    });

    let concatedList = listID?.concat(listFirstName).concat(listLastName);
    concatedList = concatedList?.filter((item, index) => {
      return (concatedList?.indexOf(item) === index)
    });
    setUserList({ "users": concatedList });
  };

  return (
    <div className={classes.search}>
      <InputBase
        placeholder="Search User (First Name, Last Name, User ID)"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        value={search}
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleSearch()}
      />
    </div>
  )
}


export default SearchBoxUser;