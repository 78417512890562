import React from 'react';
import Box from '@mui/material/Box';
import { HtmlTooltip } from '../../other/tooltips/HtmlTooltip/HtmlTooltip';
import { useStyles } from '../../other/tooltips/Tooltip.styles.js';

export default function CommentTooltip({children, text}) {
    const classes = useStyles();

    return text !== undefined ? (
        <HtmlTooltip data-testid="tooltip" maxwidth="600px"
            title={
                <Box display="flex " flexDirection="column" p={1}>
                    <Box pt={1} className={classes.title}>Last comment:</Box>
                    <Box className={classes.value}>{text}</Box>            
                </Box>
            }
        >
            {children}
        </HtmlTooltip>
    ) : children
    
}