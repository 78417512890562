import React, {useState, useEffect, useContext} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { IconButton, Grid, Paper, Box, Typography, InputLabel, Button, InputBase, Select, MenuItem, Checkbox, TextField } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { StyledNavLink } from '../../homepage/Homepage/Homepage.styles';
import BatteryLoader from '../../other/helper/BatteryLoader/BatteryLoader';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useQuery, useMutation } from '@apollo/client';
import { USERS_SLIM, ALLUSERS_SLIM, CELLSUPPLIER, SUPPLIERPORTFOLIOS, RATINGS, ALLRATINGS, ALLCELLSUPPLIERS_SLIM, ALLCELLSUPPLIERS, ALLSUPPLIERPORTFOLIOS, CELLSUPPLIERS_SLIM } from '../../../operations/queries/query';
import { useStyles } from './AddEditSupplier.styles.js';
import Icon from '../../../assets/icons/Icon.js';
import { ADDCELLSUPPLIER, UPDATECELLSUPPLIER } from '../../../operations/mutations/mutations';
import {
  SnackbarContext, SnackbarType
} from '../../other/contexts/SnackBarContext';
import { grey200, grey400, petrol800, petrol900, signalRed800 } from '../../other/helper/MuiStyles';
import { handleInputChange } from '../../other/helper/globalFunctions/globalFunctions.js';

const CustomCheckbox = withStyles({
  root: {
    borderRadius: '2px',
    height: '24px',
    width: '24px',
    color: grey400,
    padding: '0px',
    marginRight: '12px',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',    
    '&:hover': {
      color: petrol800,
      backgroundColor: 'transparent',
    },
    '&$checked': {
      color: petrol800,  
      '&:hover': {
        color: petrol900,
      },
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function AddEditSupplier(props) {
  const { edit } = props;

  let { id } = useParams();
  const navigate = useNavigate();
  const { setIsSnackbarOpen, setMessage, setSnackbarType } = useContext(SnackbarContext);

  const handleOnCompleted = async () => {
    setSnackbarType(SnackbarType.SUCCESS);
    setIsSnackbarOpen(true);
    navigate("/suppliermanagement");
  }

  const handleOnError = async (error) => {
    setMessage(error.message);
    setSnackbarType(SnackbarType.ERROR);
    setIsSnackbarOpen(true);
  }

  const { data: users, error: usersError, loading: usersLoading } = useQuery(USERS_SLIM(true));
  const { data: allUsers, error: allUsersError, loading: allUsersLoading } = useQuery(ALLUSERS_SLIM);
  const { data: allRatings, error: allRatingsError, loading: allRatingsLoading } = useQuery(ALLRATINGS);
  const { data: ratings, error: ratingsError, loading: ratingsLoading } = useQuery(RATINGS(true));
  const { data: supplierPortfolios, error: supplierPortfoliosError, loading: supplierPortfoliosLoading } = useQuery(SUPPLIERPORTFOLIOS(true), { skip: edit });
  const { data: allSupplierPortfolios, error: allSupplierPortfoliosError, loading: allSupplierPortfoliosLoading } = useQuery(ALLSUPPLIERPORTFOLIOS, { skip: !edit });
  const { data: cellSuppliers, error: cellSuppliersError, loading: cellSuppliersLoading } = useQuery(ALLCELLSUPPLIERS_SLIM);
  const { data: cellSupplier, error: cellSupplierError, loading: cellSupplierLoading } = useQuery(CELLSUPPLIER(id), { skip: !edit }, { fetchPolicy: "network-only" } );

  const [updateCellSupplier, {loading: updateCellSupplierLoading }] = useMutation(UPDATECELLSUPPLIER, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: ALLCELLSUPPLIERS }, { query: ALLCELLSUPPLIERS_SLIM }, {query: CELLSUPPLIER(id)}], awaitRefetchQueries: true });
  const [createCellSupplier, {loading: createCellSupplierLoading }] = useMutation(ADDCELLSUPPLIER, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: ALLCELLSUPPLIERS }, { query: ALLCELLSUPPLIERS_SLIM }, { query: CELLSUPPLIERS_SLIM(true) }], awaitRefetchQueries: true });

  const [formValues, setFormValues] = useState({
    rating: '',
    userId: '',
  });

  const [mutationValues, setMutationValues] = useState({
    cellSupplierId: '',
    name: '',
    rating: null,
    ratingDate: null,
    street: '',
    number: '',
    postalcode: '',
    city: '',
    userId: null,
  });

  const [supplierPortfolioList, setSupplierPortfolioList] = useState([]);
  const [checkboxStatus, setCheckboxStatus] = useState({});

  const [saveDisabled, setSaveDisabled] = useState(false);
  const [maxCharSupplierName, setMaxCharSupplierName] = useState(false);
  const [maxCharStreet, setMaxCharStreet] = useState(false);
  const [maxCharHouseNum, setMaxCharHouseNum] = useState(false);
  const [maxCharPostalCode, setMaxCharPostalCode] = useState(false);
  const [maxCharCity, setMaxCharCity] = useState(false);
  const [alreadyActive, setAlreadyActive] = useState(false);
  const [alreadyInactive, setAlreadyInactive] = useState(false);

  const handleInputChangeFreeText = () => (event) => {
    setMutationValues({ ...mutationValues, [event.target.name]: event.target.value });
  };

  const handleRatingChange = () => (event, child) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
    setMutationValues({
      cellSupplierId: mutationValues.cellSupplierId,
      name: mutationValues.name,
      rating: child.props.datamutationvalue,
      ratingDate: child.props.datamutationvalue === null ? null : mutationValues.ratingDate,
      supplierPortfolio: supplierPortfolioList,
      street: mutationValues.street,
      number: mutationValues.number,
      postalCode: mutationValues.postalcode,
      city: mutationValues.city,
      contact: mutationValues.userId,
    });
  };

  const handleCheckboxChange = event => {
    setCheckboxStatus({
      ...checkboxStatus,
      [event.target.value]: event.target.checked
    });
    handleSupplierPortfolioListChange(event.target.value);
  }

  const handleSupplierPortfolioListChange = (supplierPortfolio) => {
    let check = false;
    for (let i = 0; i < supplierPortfolioList.length; i++) {
      if (supplierPortfolio === supplierPortfolioList[i]) {
        check = true;
        supplierPortfolioList.splice(i, 1);
      }
    }
    if (!check) {
      let array = JSON.parse(JSON.stringify(supplierPortfolioList));
      array.push(supplierPortfolio);
      setSupplierPortfolioList(array);
    }
  }

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    updateCellSupplier({
      variables: {
        cellSupplierId: mutationValues.cellSupplierId,
        name: mutationValues.name,
        rating: mutationValues.rating,
        ratingDate: mutationValues.ratingDate,
        supplierPortfolio: supplierPortfolioList,
        street: mutationValues.street,
        number: mutationValues.number,
        postalCode: mutationValues.postalcode,
        city: mutationValues.city,
        contact: mutationValues.userId,
        active: true
      }
    });
    setMessage('Supplier has been updated');
  };

  const handleSubmitCreate = async (e) => {
    e.preventDefault();
    createCellSupplier({
      variables: {
        name: mutationValues.name,
        rating: mutationValues.rating,
        ratingDate: mutationValues.ratingDate,
        supplierPortfolio: supplierPortfolioList,
        street: mutationValues.street,
        number: mutationValues.number,
        postalCode: mutationValues.postalcode,
        city: mutationValues.city,
        contact: mutationValues.userId
      }
    });
    setMessage('Supplier has been created.');
    setSnackbarType(SnackbarType.SUCCESS);
    setIsSnackbarOpen(true)
  };

  // Load data from query into mutation values
  useEffect(() => {
    if (cellSupplier?.cellSupplier?.cellSupplierId) {
      setMutationValues({
        ...mutationValues,
        cellSupplierId: cellSupplier.cellSupplier.cellSupplierId,
        name: cellSupplier.cellSupplier.name,
        street: cellSupplier.cellSupplier.street,
        number: cellSupplier.cellSupplier.number,
        postalcode: cellSupplier.cellSupplier.postalCode,
        city: cellSupplier.cellSupplier.city,
        rating: cellSupplier.cellSupplier.rating?.ratingId,
        ratingDate: cellSupplier.cellSupplier.ratingDate,
        userId: cellSupplier.cellSupplier.contact?.userId
      })
      setFormValues({
        ...formValues,
        rating: cellSupplier.cellSupplier.rating?.name,
        userId: cellSupplier.cellSupplier.contact?.userId
      })

      let supplierPortfolioListIds = [];
      for (let i of cellSupplier.cellSupplier.supplierPortfolio) {
        supplierPortfolioListIds.push(i.supplierPortfolioId)
      }
      setSupplierPortfolioList(supplierPortfolioListIds);

      // might be a problem since data may not be there before cellSupplier data is there (maybe stick to TestSpecDetails solution for parametersData and testSpecification)
      let checkboxStatusTemp = {};
      if (edit) {
        if (allSupplierPortfolios) {
          for (let i of allSupplierPortfolios.supplierPortfolios) {
            let isAssigned = supplierPortfolioListIds.some(element => element === i.supplierPortfolioId) ? true : false;
            checkboxStatusTemp[i.supplierPortfolioId] = isAssigned;
          }
        }
      } else {
        if (supplierPortfolios) {
          for (let i of supplierPortfolios.supplierPortfolios) {
            let isAssigned = supplierPortfolioListIds.some(element => element === i.supplierPortfolioId) ? true : false;
            checkboxStatusTemp[i.supplierPortfolioId] = isAssigned;
          }
        }
      }
      setCheckboxStatus(checkboxStatusTemp);
    }
  }, [cellSupplier]); // eslint-disable-line

  useEffect(() => {
    setSaveDisabled(false);
    setMaxCharSupplierName(false);
    setMaxCharStreet(false);
    setMaxCharHouseNum(false);
    setMaxCharPostalCode(false);
    setMaxCharCity(false);
    setAlreadyActive(false);
    setAlreadyInactive(false);

    if (mutationValues.name?.length > 50) {
      setSaveDisabled(true);
      setMaxCharSupplierName(true);
    }

    if (mutationValues.street?.length > 45) {
      setSaveDisabled(true);
      setMaxCharStreet(true);
    }

    if (mutationValues.number?.length > 15) {
      setSaveDisabled(true);
      setMaxCharHouseNum(true);
    }

    if (mutationValues.postalcode?.length > 10) {
      setSaveDisabled(true);
      setMaxCharPostalCode(true);
    }

    if (mutationValues.city?.length > 30) {
      setSaveDisabled(true);
      setMaxCharCity(true);
    }

    if (mutationValues.name?.length < 1 || Object.values(checkboxStatus).every(selected => selected === false) || (mutationValues.rating !== null && mutationValues.ratingDate === null)) {
      setSaveDisabled(true);
    }

    for (let i = 0; i < cellSuppliers?.cellSuppliers?.length; i++) {
      if (cellSuppliers?.cellSuppliers[i]['name'].toUpperCase() === mutationValues.name.toUpperCase()) {
        if (edit) {
          if (mutationValues.cellSupplierId !== cellSuppliers.cellSuppliers[i]['cellSupplierId']) {
            setSaveDisabled(true);
            if (cellSuppliers.cellSuppliers[i].active) {
              setAlreadyActive(true);
            } else {
              setAlreadyInactive(true);
            }
            break;
          }
        } else {
          setSaveDisabled(true);
          if (cellSuppliers.cellSuppliers[i].active) {
            setAlreadyActive(true);
          } else {
            setAlreadyInactive(true);
          }
        }
        break;
      }
    }
  }, [mutationValues, checkboxStatus]); // eslint-disable-line

  const isLoading = () => {
    return (allUsersLoading || allSupplierPortfoliosLoading || cellSuppliersLoading || usersLoading || cellSupplierLoading || ratingsLoading || allRatingsLoading || supplierPortfoliosLoading || updateCellSupplierLoading || createCellSupplierLoading)
  };

  const hasError = () => {
    return !(allUsersError === undefined && allSupplierPortfoliosError === undefined && cellSuppliersError === undefined && usersError === undefined && cellSupplierError === undefined && ratingsError === undefined && allRatingsError === undefined && supplierPortfoliosError === undefined)
  };

  const classes = useStyles();

  return (
    <>
      <div className={classes.root} >
        <Grid container spacing={3}>
          <Grid item xs={12} display="grid">
            <Paper className={classes.paper}>

              {/* ERROR */}
              {hasError() &&
                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 64px - 24px - 24px)' }}>
                  <Box mx={4} my={4} display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                    <Typography variant="h2">
                      {edit ? 'Edit Supplier' : 'Add Supplier'}
                    </Typography>
                  </Box>
                  <Box mx={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow="1">
                    <Icon iconName={"WarningIcon"} width={54} height={54} fill={signalRed800} />
                    <Typography variant="h3" className={classes.error_text}>
                      Something went wrong. The following errors have occurred:
                    </Typography>
                    {usersError !== undefined &&
                      <Typography variant="h5" className={classes.error_type}>
                        {usersError.message}
                      </Typography>
                    }
                    {allUsersError !== undefined &&
                      <Typography variant="h5" className={classes.error_type}>
                        {allUsersError.message}
                      </Typography>
                    }
                    {cellSupplierError !== undefined &&
                      <Typography variant="h5" className={classes.error_type}>
                        {cellSupplierError.message}
                      </Typography>
                    }
                    {ratingsError !== undefined &&
                      <Typography variant="h5" className={classes.error_type}>
                        {ratingsError.message}
                      </Typography>
                    }
                    {supplierPortfoliosError !== undefined &&
                      <Typography variant="h5" className={classes.error_type}>
                        {supplierPortfoliosError.message}
                      </Typography>
                    }
                    {allSupplierPortfoliosError !== undefined &&
                      <Typography variant="h5" className={classes.error_type}>
                        {allSupplierPortfoliosError.message}
                      </Typography>
                    }
                    {cellSuppliersError !== undefined &&
                      <Typography variant="h5" className={classes.error_type}>
                        {cellSuppliersError.message}
                      </Typography>
                    }
                  </Box>
                  <Box my={4} display="flex" alignItems="center" justifyContent="center">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => { navigate("/suppliermanagement") }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              }

              {/* LOADING */}
              {!hasError() && isLoading() &&
                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 64px - 24px - 24px)' }}>
                  <Box mx={4} my={4} display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                    <Typography variant="h2">
                      {edit ? 'Edit Supplier' : 'Add Supplier'}
                    </Typography>
                  </Box>
                  <Box mx={4} display="flex" alignItems="center" justifyContent="center" flexGrow="1">
                    <BatteryLoader type="standard" />
                  </Box>
                </Box>
              }

              {!isLoading() && !hasError() &&
                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 64px - 24px - 24px)' }}>
                  <Box display="flex" flexDirection="column">
                    <Box mx={4} my={4} display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                      <Typography variant="h2">
                        {edit ? 'Edit' : 'Add'} {edit ? cellSupplier.cellSupplier.name : 'Supplier'}
                      </Typography>
                      <StyledNavLink to="/suppliermanagement">
                        <IconButton size="large" className={classes.hover_blue}>
                          <Icon iconName={"CloseIcon"} fill={petrol800} />
                        </IconButton>
                      </StyledNavLink>
                    </Box>
                    <Box mx={4} display="flex" flexDirection="column" alignItems="flex-start">
                      <InputLabel className={classes.input_title}>Supplier Name *</InputLabel>
                      <InputBase
                        className={classes.box_freeText}
                        name="name"
                        multiline={true}
                        fullWidth={true}
                        value={mutationValues.name}
                        onChange={handleInputChangeFreeText()}
                        error={maxCharSupplierName || alreadyActive || alreadyInactive}
                      />
                      {maxCharSupplierName &&
                        <Box display="flex" justifyContent="space-between" style={{ width: '391px' }}>
                          <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 50 characters.</Typography>
                          <Typography variant="subtitle2" className={classes.alert_message}>{mutationValues.name.length}/50</Typography>
                        </Box>
                      }
                      {alreadyActive &&
                        <Typography variant="subtitle2" className={classes.alert_message}>This supplier name is already in use by another active supplier.</Typography>
                      }
                      {alreadyInactive &&
                        <Typography variant="subtitle2" className={classes.alert_message}>This supplier name already belongs to an inactive supplier. Please reactivate that instead.</Typography>
                      }
                    </Box>

                    <Box mx={4} mt={4} display="flex" flexDirection="column" alignItems="flex-start">
                      <InputLabel className={classes.input_title}>Technical Rating</InputLabel>
                      <Select
                        className={classes.box}
                        name="rating"
                        value={formValues?.rating === undefined ? '' : formValues.rating}
                        onChange={handleRatingChange()}
                      >
                        <MenuItem
                          value=''
                          datamutationvalue={null}
                        >
                          <em>None</em>
                        </MenuItem>
                        {!edit && ratings?.ratings.map((option) => (
                          <MenuItem
                            datamutationvalue={option?.ratingId}
                            key={option.ratingId}
                            value={option?.name}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                        {edit && allRatings?.ratings.map((option) => (
                          <MenuItem
                            datamutationvalue={option?.ratingId}
                            key={option.ratingId}
                            value={option?.name}
                            disabled={!option.active}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>

                    {(mutationValues?.rating !== null && mutationValues?.rating !== undefined) &&
                      <Box mx={4} mt={4} display="flex" flexDirection="column" alignItems="flex-start">
                        <InputLabel className={classes.input_title}>Date of Rating *</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            InputProps={{ classes: { input: classes.DatePickerInput } }}
                            InputAdornmentProps={{ classes: { positionEnd: classes.DatePickerAndornment } }}
                            format="dd/MM/yy"
                            value={mutationValues?.ratingDate}
                            onChange={(newValue) => { setMutationValues({ ...mutationValues, ratingDate: newValue }) }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Box>
                    }

                    <Box my={5} mx={4} style={{ backgroundColor: grey200, height: '1px', width: '806px' }}></Box>

                    <Box mx={4} display="flex" flexDirection="column" alignItems="flex-start">
                      <InputLabel>Select Supplier Portfolio *</InputLabel>
                      {!edit && supplierPortfolios?.supplierPortfolios.map((item) => (
                        <Box key={item.name} className={classes.supplierPortfolio} display="flex" alignItems="center">
                          <CustomCheckbox
                            name={item.name}
                            value={item.supplierPortfolioId}
                            checked={checkboxStatus[item.supplierPortfolioId] !== undefined ? checkboxStatus[item.supplierPortfolioId] : false}
                            onChange={handleCheckboxChange}
                          /> {item.name}
                        </Box>
                      ))}
                      {edit && allSupplierPortfolios?.supplierPortfolios.map((item) => (
                        <Box key={item.name} className={classes.supplierPortfolio} display="flex" alignItems="center" style={!item.active && !checkboxStatus[item.supplierPortfolioId] ? { opacity: 0.38 } : !item.active && checkboxStatus[item.supplierPortfolioId] ? { color: 'rgba(52, 57, 59, 0.38)' } : {}}>
                          <CustomCheckbox
                            name={item.name}
                            value={item.supplierPortfolioId}
                            checked={checkboxStatus[item.supplierPortfolioId] !== undefined ? checkboxStatus[item.supplierPortfolioId] : false}
                            onChange={handleCheckboxChange}
                            disabled={!checkboxStatus[item.supplierPortfolioId] && !item.active}
                          /> {item.name}
                        </Box>
                      ))}
                    </Box>

                    <Box mx={4} mt={4} display="flex" flexDirection="column" alignItems="flex-start">
                      <Typography variant="h4" className={classes.box_title}>
                        Headquarter
                      </Typography>
                    </Box>

                    <Box mx={4} mt={2} display="flex">
                      <Box display="flex" flexDirection="column" alignItems="flex-start">
                        <InputLabel className={classes.input_title}>Street</InputLabel>
                        <InputBase
                          className={classes.box_freeText}
                          name="street"
                          multiline={true}
                          fullWidth={true}
                          value={mutationValues.street === null ? '' : mutationValues.street}
                          onChange={handleInputChangeFreeText()}
                          error={maxCharStreet}
                        />
                        {maxCharStreet &&
                          <Box display="flex" justifyContent="space-between" style={{ width: '391px' }}>
                            <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 45 characters.</Typography>
                            <Typography variant="subtitle2" className={classes.alert_message}>{mutationValues.street.length}/45</Typography>
                          </Box>
                        }
                      </Box>

                      <Box ml={3} display="flex" flexDirection="column" alignItems="flex-start">
                        <InputLabel className={classes.input_title}>Number</InputLabel>
                        <InputBase
                          className={classes.box_freeText}
                          name="number"
                          multiline={true}
                          fullWidth={true}
                          value={mutationValues.number === null ? '' : mutationValues.number}
                          onChange={handleInputChangeFreeText()}
                          error={maxCharHouseNum}
                        />
                        {maxCharHouseNum &&
                          <Box display="flex" justifyContent="space-between" style={{ width: '391px' }}>
                            <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 15 characters.</Typography>
                            <Typography variant="subtitle2" className={classes.alert_message}>{mutationValues.number.length}/15</Typography>
                          </Box>
                        }
                      </Box>
                    </Box>

                    <Box mx={4} mt={3} display="flex">
                      <Box display="flex" flexDirection="column" alignItems="flex-start">
                        <InputLabel className={classes.input_title}>Postalcode</InputLabel>
                        <InputBase
                          className={classes.box_freeText}
                          name="postalcode"
                          multiline={true}
                          fullWidth={true}
                          value={mutationValues.postalcode === null ? '' : mutationValues.postalcode}
                          onChange={handleInputChangeFreeText()}
                          error={maxCharPostalCode}
                        />
                        {maxCharPostalCode &&
                          <Box display="flex" justifyContent="space-between" style={{ width: '391px' }}>
                            <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 10 characters.</Typography>
                            <Typography variant="subtitle2" className={classes.alert_message}>{mutationValues.postalcode.length}/10</Typography>
                          </Box>
                        }
                      </Box>

                      <Box ml={3} display="flex" flexDirection="column" alignItems="flex-start">
                        <InputLabel className={classes.input_title}>City</InputLabel>
                        <InputBase
                          className={classes.box_freeText}
                          name="city"
                          multiline={true}
                          fullWidth={true}
                          value={mutationValues.city === null ? '' : mutationValues.city}
                          onChange={handleInputChangeFreeText()}
                          error={maxCharCity}
                        />
                        {maxCharCity &&
                          <Box display="flex" justifyContent="space-between" style={{ width: '391px' }}>
                            <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 30 characters.</Typography>
                            <Typography variant="subtitle2" className={classes.alert_message}>{mutationValues.city.length}/30</Typography>
                          </Box>
                        }
                      </Box>
                    </Box>

                    <Box mx={4} mt={4} display="flex">
                      <Typography variant="h4" className={classes.box_title}>
                        Main Contact VW Group
                      </Typography>
                    </Box>

                    <Box mx={4} mt={2} display="flex" flexDirection="column" alignItems="flex-start">
                      <InputLabel className={classes.input_title}>Contact</InputLabel>
                      <Select
                        className={classes.box}
                        name="userId"
                        value={formValues?.userId === undefined ? '' : formValues.userId}
                        onChange={handleInputChange(setFormValues, formValues, setMutationValues, mutationValues)}
                      >
                        <MenuItem
                          value=''
                          datamutationvalue={null}
                        >
                          <em>None</em>
                        </MenuItem>
                        {!edit && users?.users.map((option) => (
                          <MenuItem
                            datamutationvalue={option.userId}
                            key={option.userId}
                            value={option.userId}
                          >
                            {option.lastName + ", " + option.firstName}
                          </MenuItem>
                        ))}
                        {edit && allUsers?.users.map((option) => (
                          <MenuItem
                            datamutationvalue={option.userId}
                            key={option.userId}
                            value={option.userId}
                            disabled={!option.active}
                          >
                            {option.lastName + ", " + option.firstName}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Box>

                  <Box mx={4} mt={3} pb={4} display="flex" alignItems="center">
                    <Button
                      variant="contained"
                      disabled={saveDisabled}
                      onClick={!edit ? handleSubmitCreate : handleSubmitEdit}
                      color="primary"
                      type="submit"
                      style={{ width: '165px' }}
                    >
                      {edit ? 'Save' : 'Add Supplier'}
                    </Button>
                    <Typography variant="h6" className={classes.required_tag}>
                      * Mandatory field
                    </Typography>
                  </Box>

                </Box>
              }
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default AddEditSupplier;