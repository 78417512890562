import React from 'react';
import Box from '@mui/material/Box';
import Icon from '../../../../assets/icons/Icon.js';
import { useStyles } from '../Tooltip.styles';
import { HtmlTooltip } from '../HtmlTooltip/HtmlTooltip.js';
import { grey600 } from '../../helper/MuiStyles.js';

export default function ReferenceInfo(props) {
  const { reference, accessPoint } = props;
  const classes = useStyles();
  return (
    <HtmlTooltip data-testid="tooltip"
      title={
        <Box display="flex " flexDirection="row">
          <Box display="flex " flexDirection="column" p={1}>
            <Box className={classes.title}>Reference:</Box>
            <Box mt={0.5} className={classes.value} title="reference">{reference === undefined ? "-" : reference}</Box>
          </Box>
        </Box>
      }
    >
      {accessPoint === 'Table' ?
      <div style={{ 'height': '24px', 'width': '24px', 'cursor': 'help' }}>
        <Icon iconName={"InfoIcon"} fill={grey600}/>
      </div>
      :
      <Box className={classes.black_value_ref}>{reference === undefined ? "-" : reference}</Box>
    }
    </HtmlTooltip>
  )
}