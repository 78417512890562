import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { ALLPHYSICALQUANTITIES } from '../../../operations/queries/query';
import { Dialog, Button, Typography, DialogContent, Box, TextField, IconButton, ToggleButton, ToggleButtonGroup, Autocomplete } from '@mui/material';
import Counter from '../../other/helper/Counter/Counter';
import FilterButton from '../../other/helper/FilterButton/FilterButton';
import { useStyles } from './ParameterSelection.styles.js';
import BatteryLoader from '../../other/helper/BatteryLoader/BatteryLoader';
import Icon from '../../../assets/icons/Icon';
import { grey1000, grey600, petrol800, signalRed800 } from '../../other/helper/MuiStyles';

const ParameterSelection = (props) => {
    const {
        conditionCounter,
        setConditionCounter,
        allParameters,
        setAllParameters,
        selectedParameters,
        setSelectedParameters,
        selectedConditions,
        setSelectedConditions,
        activeParameters,
        setActiveParameters,
        newParameter
    } = props;

    const { data: physicalQuantities, error: physicalQuantitiesError, loading: physicalQuantitiesLoading } = useQuery(ALLPHYSICALQUANTITIES);

    const [show, setShow] = useState(false);
    const [filterButton, setFilterButton] = useState(false);
    const [selectedButton, setSelectedButton] = useState(false);
    const [filterKey, setFilterKey] = useState(false);

    //Currently selected physical quantities
    const [activePhysicalQuantities, setActivePhysicalQuantities] = useState([]);

    const ToggleModal = () => {
        setShow(!show);
    };

    const handlePhysicalQuantityClick = (event, physicalQuantity) => {
        setActivePhysicalQuantities(physicalQuantity);
    };

    const handleFilterClick = () => {
        setFilterButton(!filterButton);
    };

    const handleSelectedClick = () => {
        setSelectedButton(!selectedButton);
    };

    const handleAddParamaterClick = (parameter) => {
        let conditionsAmount = [];
        for (let i = 0; i < conditionCounter; i++) {
            newParameter ? conditionsAmount.push("") : conditionsAmount.push({ id: null, condition: "" });
        }
        let params = [...selectedParameters];
        let allparams = [...allParameters];
        let conditions = [...selectedConditions];
        let activeParams = [...activeParameters];


        newParameter ? params.push(parameter) : params.push({ assignParameterId: null, parameter: parameter });
        allparams.find(x => x.parameterId === parameter.parameterId).selected = true;
        activeParams.find(x => x.parameterId === parameter.parameterId).selected = true;
        conditions.push(conditionsAmount);

        setAllParameters(allparams);
        setActiveParameters(activeParams);
        setSelectedParameters(params);
        setSelectedConditions(conditions);
    };

    const handleRemoveParameterClick = (parameter, entry) => {
        let params = [...selectedParameters];
        let conditions = [...selectedConditions];
        let allparams = [...allParameters];
        let activeParams = [...activeParameters];

        entry === "selected" ? (allparams.find(x => x.parameterId === parameter.parameter.parameterId).selected = false) : (allparams.find(x => x.parameterId === parameter.parameterId).selected = false);
        entry === "selected" ? (activeParams.find(x => x.parameterId === parameter.parameter.parameterId).selected = false) : (activeParams.find(x => x.parameterId === parameter.parameterId).selected = false);

        let indexparam = entry === "selected" ? (params.findIndex(x => x.parameter.parameterId === parameter.parameter.parameterId)) : (params.findIndex(x => x.parameter.parameterId === parameter.parameterId));
        params.splice(indexparam, 1);
        conditions.splice(indexparam, 1);

        setAllParameters(allparams);
        setSelectedParameters(params);
        setSelectedConditions(conditions);
        setActiveParameters(activeParams);

        if (params.length === 0) {
            setConditionCounter(0);
        }
    };

    const handleInputChange = (event, value) => {
        let allPara = activeParameters;
        let filter = [];
        let regEx = new RegExp(value);
        let list = allPara.filter(function (currentElement) {
            return regEx.test(currentElement.name);
        });
        if (value == null) {
            for (let i of activePhysicalQuantities) {
                filter = allParameters.filter(({ physicalQuantity }) => physicalQuantity === i);
                list = list.concat(filter);
            }
            if (activePhysicalQuantities.length < 1) {
                list = allParameters;
            }
        }
        setActiveParameters(list);
    };

    useEffect(() => {
        let value = [];
        let allValues = [];
        setActiveParameters(value);
        for (let i of activePhysicalQuantities) {
            value = allParameters.filter(({ physicalQuantity }) => physicalQuantity === i);
            allValues = allValues.concat(value);
        }
        if (activePhysicalQuantities.length < 1) {
            allValues = allParameters;
        }
        setActiveParameters(allValues);
        setFilterKey(!filterKey);
    }, [activePhysicalQuantities]); // eslint-disable-line


    const classes = useStyles();

    return <>
        <Button
            variant="contained"
            color="primary"
            className={classes.button_add_parameter}
            onClick={ToggleModal}
            data-testid={"add-parameter-button"}
        >
            <div style={{ display: 'flex', marginRight: '8px' }}>
                <Icon iconName={"AddIcon"} fill={'#FFF'} />
            </div>
            Add Parameter
        </Button>

        {show &&
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={show}
                onClose={ToggleModal}
            >
                <DialogContent>

                    {/* ERROR */}
                    {physicalQuantitiesError !== undefined &&
                        <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(70vh)' }}>
                            <Box b={4} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                <Typography variant="h2">
                                    Add Parameter
                                </Typography>
                            </Box>
                            <Box mx={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow="1">
                                <Icon iconName={"WarningIcon"} width={54} height={54} fill={signalRed800} />
                                <Typography variant="h3" className={classes.error_text}>
                                    Something went wrong. The following errors have occurred:
                                </Typography>
                                {physicalQuantitiesError !== undefined &&
                                    <Typography variant="h5" className={classes.error_type}>
                                        {physicalQuantitiesError.message}
                                    </Typography>
                                }
                            </Box>
                            <Box mt={4} mb={3} display="flex" alignItems="center" justifyContent="center">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={ToggleModal}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    }

                    {/* LOADING */}
                    {physicalQuantitiesLoading && physicalQuantitiesError === undefined &&
                        <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(70vh)' }}>
                            <Box mb={4} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                <Typography variant="h2">
                                    Add Parameter
                                </Typography>
                            </Box>
                            <Box mx={1} mb={3} display="flex" alignItems="center" justifyContent="center" flexGrow="1">
                                <BatteryLoader type="standard" />
                            </Box>
                        </Box>
                    }

                    {!physicalQuantitiesLoading && physicalQuantitiesError === undefined &&
                        <>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Box>
                                    <Typography variant="h2">
                                        Add Parameter
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" justifyContent="flex-end">
                                    <div className={classes.search}>
                                        <div className={classes.search_icon}>
                                            <Icon iconName={'SearchIcon'} fill={grey600} />
                                        </div>
                                        <Autocomplete
                                            id="search-parameter"
                                            key={filterKey}
                                            className={classes.search_field}
                                            freeSolo
                                            onChange={handleInputChange}
                                            options={activeParameters?.map((name) => name.name)}
                                            renderInput={(params) => (
                                                <TextField {...params} placeholder="Search Parameter" />
                                            )}
                                        >
                                        </Autocomplete>
                                    </div>
                                    <Box mr={2} ml={10}>
                                        <FilterButton
                                            filterState={!filterButton}
                                            activecount={activePhysicalQuantities?.length}
                                            onClick={handleFilterClick}
                                        />
                                    </Box>
                                    <IconButton onClick={ToggleModal} className={classes.hover_blue} style={{ padding: '8px' }}>
                                        <Icon iconName={"CloseIcon"} fill={petrol800} />
                                    </IconButton>
                                </Box>
                            </Box>

                            {filterButton &&
                                <Box mt={2.5} mb={-2} display="flex" alignItems="center" flexWrap="wrap">
                                    <ToggleButtonGroup
                                        value={activePhysicalQuantities}
                                        onChange={handlePhysicalQuantityClick}
                                        className={classes.toggle_button_group}
                                    >
                                        {physicalQuantities?.physicalQuantities.map(i => {
                                            return (
                                                <ToggleButton
                                                    key={i.physicalQuantityId}
                                                    classes={{
                                                        root: classes.parameter_button,
                                                        selected: classes.parameter_button_selected,
                                                    }}
                                                    value={i.name}
                                                >
                                                    {i.name}
                                                </ToggleButton>
                                            )
                                        })}
                                    </ToggleButtonGroup>
                                </Box>
                            }

                            <Box mt={3} mb={1} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                <Box display="flex" alignItems="center">
                                    <Counter number={selectedParameters?.length}></Counter>
                                    <Typography variant="h5" className={classes.parameter_selected}>
                                        Selected Parameters
                                    </Typography>
                                </Box>
                                {!selectedButton && selectedParameters?.length > 0 &&
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleSelectedClick}
                                    >
                                        Show Selected
                                        <div className={classes.filter_icon}>
                                            <Icon iconName={"ArrowDownIcon"} fill={petrol800} />
                                        </div>
                                    </Button>
                                }
                                {selectedButton &&
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        className={classes.selected_button_selected}
                                        onClick={handleSelectedClick}
                                    >
                                        Hide Selected
                                        <div className={classes.filter_icon}>
                                            <Icon iconName={"ArrowUpIcon"} fill={petrol800} />
                                        </div>
                                    </Button>
                                }
                            </Box>

                            {selectedButton &&
                                <Box mt={2} mb={1} display="flex" alignItems="center" flexWrap="wrap">
                                    {selectedParameters?.map(i => {
                                        return (
                                            <Box display="flex" alignItems="center" key={i.parameterId} className={classes.selected_parameter_box} style={i.parameter?.active ? {} : { color: 'rgba(52, 57, 59, 0.38)' }}>
                                                {newParameter ? i.name : i.parameter?.name}
                                                <IconButton
                                                    className={classes.error_icon_wrapper}
                                                    onClick={() => newParameter ? handleRemoveParameterClick(i) : handleRemoveParameterClick(i, "selected")}
                                                    size="large">
                                                    <Icon iconName={"AddIcon"} fill={grey1000} />
                                                </IconButton>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            }

                            <Box className={classes.line}></Box>

                            <Box mx={-1} className={classes.list}>
                                {activeParameters?.map(i => {
                                    return (
                                        <Box key={i.parameterId} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" style={i.selected ? { border: `2px solid ${petrol800}` } : { border: '1px solid rgba(0, 70, 102, 0)' }} className={classes.parameter_box}>
                                            <Box display="flex" flexDirection="column" style={i.active ? {} : { opacity: 0.38 }}>
                                                <Box display="flex" flexDirection="row" alignItems="center">
                                                    <Typography className={classes.parameter_title} >
                                                        {i.name}
                                                    </Typography>
                                                    {i.unit !== '' &&
                                                        <Typography variant='h5' className={classes.parameter_unit}>
                                                            ({i.unit})
                                                        </Typography>
                                                    }
                                                </Box>
                                                <Typography variant='h6' className={classes.parameter_description}>
                                                    {i.description}
                                                </Typography>
                                            </Box>
                                            <Box mx={1.5}>
                                                {!i.selected && i.active &&
                                                    <IconButton data-testid={"select-parameter-button"} onClick={() => handleAddParamaterClick(i)} className={classes.hover_blue} >
                                                        <Icon iconName={"AddIcon"} width={40} height={40} fill={petrol800} />
                                                    </IconButton>
                                                }
                                                {i.selected &&
                                                    <IconButton onClick={() => newParameter ? handleRemoveParameterClick(i) : handleRemoveParameterClick(i, "active")} style={{ "transform": 'rotate(45deg)' }} className={classes.hover_blue}>
                                                        <Icon iconName={"AddIcon"} width={40} height={40} fill={petrol800} />
                                                    </IconButton>
                                                }
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </Box>

                            <Box display="flex" flexDirection="row">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    data-testid={"finish-parameter-selection-button"}
                                    className={classes.add_button}
                                    onClick={ToggleModal}
                                >
                                    Finish Selection
                                </Button>
                            </Box>
                        </>
                    }

                </DialogContent>

            </Dialog>
        }

    </>;
};

export default ParameterSelection;