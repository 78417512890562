import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { ALLUSERS, APPROLES } from '../../../operations/queries/query';
import { Paper, Grid, Box, Typography, Button, IconButton } from '@mui/material';
import { useStyles } from '../UserManagement.styles';
import SearchBoxUser from '../../other/helper/SearchBox/SearchBoxUser/SearchBoxUser';
import AddUser from '../../other/dialogs/AddUser/AddUser';
import Icon from '../../../assets/icons/Icon.js';
import BatteryLoader from '../../other/helper/BatteryLoader/BatteryLoader';
import ApplicationRoleInfo from '../../other/dialogs/ApplicationRoleInfo/ApplicationRoleInfo';
import UserManagementListElement from '../UserManagementListElement/UserManagementListElement';
import { StyledNavLink } from '../../homepage/Homepage/Homepage.styles';
import { UPDATEAPPROLE, UPDATEUSERACTIVE } from '../../../operations/mutations/mutations';
import { dynamicSort } from '../../other/helper/globalFunctions/globalFunctions';
import ScrollUpIcon from '../../other/helper/ScrollUpIcon/ScrollUpIcon';
import { grey200, grey600, grey800, signalRed800 } from '../../other/helper/MuiStyles';
import {SnackbarContext, SnackbarType} from "../../other/contexts/SnackBarContext";

function UserManagementListTable() {
    const { data: users, error: usersError, loading: usersLoading } = useQuery(ALLUSERS);
    const { data: appRoles, error: appRolesError, loading: appRolesLoading } = useQuery(APPROLES);

    const { setIsSnackbarOpen, setSnackbarType, setMessage } = useContext(SnackbarContext);

    const handleOnCompleted = async () => {
        setSnackbarType(SnackbarType.SUCCESS);
        setIsSnackbarOpen(true);
    }

    const handleOnError = async (error) => {
        setMessage(error.message);
        setSnackbarType(SnackbarType.ERROR);
        setIsSnackbarOpen(true);
    }

    const [updateAppRole, { loading: updateAppRoleLoading }] = useMutation(UPDATEAPPROLE, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: ALLUSERS }], awaitRefetchQueries: true });
    const [updateUserActive, { loading: updateUserActiveLoading}] = useMutation(UPDATEUSERACTIVE, {onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: ALLUSERS }], awaitRefetchQueries: true });

    const [count, setCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [userList, setUserList] = useState({});

    //SORTING VARIABLES
    const [userMgmtKey, setUserMgmtKey] = useState(false);
    const [sortName, setSortName] = useState(false);
    const [sortUserID, setSortUserID] = useState(false);
    const [sortDepartment, setSortDepartment] = useState(false);
    const [sortRole, setSortRole] = useState(false);

    const handleToggle = () => {
        setOpen(!open);
    };

    const isLoading = () => {
        return (usersLoading || appRolesLoading || updateAppRoleLoading || updateUserActiveLoading)
    };

    const hasError = () => {
        return !(usersError === undefined && appRolesError === undefined)
    };

    useEffect(() => {
        let allValues = [];
        if (users !== undefined) {
            for (let i of users.users) {
                allValues.push(i);
            }
            setUserList({ users: allValues });
        }
    }, [users]); // eslint-disable-line

    const classes = useStyles();

    return (
        <>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} display="grid">
                        <Paper className={classes.paper}>

                            {/* ERROR */}
                            {hasError() &&
                                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 64px - 24px - 24px)' }}>
                                    <Box px={3} pt={2} display="flex" flexDirection="row" alignItems="center">
                                        <Typography variant="h3" className={classes.title}>
                                            User Management
                                        </Typography>
                                    </Box>
                                    <Box mx={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow="1">
                                        <Icon iconName={"WarningIcon"} width={54} height={54} fill={signalRed800} />
                                        <Typography variant="h3" className={classes.error_text}>
                                            Something went wrong. The following errors have occurred:
                                        </Typography>
                                        {usersError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {usersError.message}
                                            </Typography>
                                        }
                                        {appRolesError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {appRolesError.message}
                                            </Typography>
                                        }
                                    </Box>
                                    <Box my={4} display="flex" alignItems="center" justifyContent="center">
                                        <StyledNavLink to="/">
                                            <Button
                                                variant="outlined"
                                                color="primary">
                                                Cancel
                                            </Button>
                                        </StyledNavLink>
                                    </Box>
                                </Box>
                            }

                            {/* LOADING */}
                            {isLoading() && !hasError() &&
                                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 64px - 24px - 24px)' }}>
                                    <Box px={3} pt={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                        <Typography variant="h3" className={classes.title}>
                                            User Management
                                        </Typography>
                                    </Box>
                                    <Box mx={2} display="flex" alignItems="center" justifyContent="center" flexGrow="1">
                                        <BatteryLoader type="standard" />
                                    </Box>
                                </Box>
                            }

                            {!isLoading() && !hasError() &&
                                <>
                                    <Box px={3} pt={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                        <Typography variant="h3" className={classes.title}>
                                            User Management
                                        </Typography>
                                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                            <IconButton
                                                aria-controls={open ? 'menu-list-grow' : undefined}
                                                aria-haspopup="true"
                                                onClick={handleToggle}
                                                style={open ? { marginRight: '8px', padding: '8px' } : { padding: '8px' }}
                                                size="large">
                                                <Icon
                                                    fill={grey600}
                                                    iconName={"SearchIcon"}
                                                    width={24}
                                                    height={24}
                                                />
                                            </IconButton>
                                            {open && < SearchBoxUser users={users} setUserList={setUserList} />}
                                            <div className={classes.seperator}></div>
                                            <AddUser />
                                        </Box>
                                    </Box>

                                    <Box mt={2} style={{ backgroundColor: grey200, height: '1px' }}></Box>


                                    <Box my={2} display="flex" flexDirection="row" alignItems="center" className={classes.header}>
                                        <Box className={classes.header1}>
                                            <Box display="flex" flexDirection="row" alignItems="center" ml={3} >Name & E-Mail <IconButton style={sortName === true ? { marginLeft: '8px' } : { transform: 'rotate(-180deg)', marginLeft: '8px' }} onClick={() => { userList.users.sort(dynamicSort(sortName === true ? "firstName" : "-firstName")); setSortName(!sortName); setUserMgmtKey(!userMgmtKey) }}><Icon iconName={"DirectionIcon"} width={16} height={16} fill={grey800} /></IconButton></Box>
                                        </Box>
                                        <Box display="flex" flexDirection="row" alignItems="center" className={classes.header2}>User ID<IconButton style={sortUserID === true ? { marginLeft: '8px' } : { transform: 'rotate(-180deg)', marginLeft: '8px' }} onClick={() => { userList.users.sort(dynamicSort(sortUserID === true ? "userId" : "-userId")); setSortUserID(!sortUserID); setUserMgmtKey(!userMgmtKey) }}><Icon iconName={"DirectionIcon"} width={16} height={16} fill={grey800} /></IconButton></Box>
                                        <Box display="flex" flexDirection="row" alignItems="center" className={classes.header3}>Department<IconButton style={sortDepartment === true ? { marginLeft: '8px' } : { transform: 'rotate(-180deg)', marginLeft: '8px' }} onClick={() => { userList.users.sort(dynamicSort(sortDepartment === true ? "department" : "-department")); setSortDepartment(!sortDepartment); setUserMgmtKey(!userMgmtKey) }}><Icon iconName={"DirectionIcon"} width={16} height={16} fill={grey800} /></IconButton></Box>
                                        <Box display="flex" flexDirection="row" alignItems="center" className={classes.header4}>Role<IconButton style={sortRole === true ? { marginLeft: '8px' } : { transform: 'rotate(-180deg)', marginLeft: '8px' }} onClick={() => { userList.users.sort(dynamicSort(sortRole === true ? "role" : "-role", "role")); setSortRole(!sortRole); setUserMgmtKey(!userMgmtKey) }}><Icon iconName={"DirectionIcon"} width={16} height={16} fill={grey800} /></IconButton><ApplicationRoleInfo accessPoint="Table" /></Box>
                                        <Box className={classes.header5}>Assigned Projects</Box>
                                        <Box className={classes.header6}></Box>
                                    </Box>
                                    {userList?.users?.filter((item, idx) => idx < 9 + count).map((i, j) => {
                                        return <UserManagementListElement i={i} className={j % 2 === 0 ? classes.odd : classes.even} key={i.userId} appRoles={appRoles} mutateFunctionRole={updateAppRole} mutateFunctionActive={updateUserActive} />
                                    })}
                                    {count + 9 < userList?.users?.length &&
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            className={classes.logout_button}
                                            onClick={() => setCount(count + 9)}
                                        >
                                            Show more
                                        </Button>
                                    }
                                    <Box style={{ backgroundColor: "#FFF", height: '24px', borderRadius: '4px' }}></Box>
                                </>
                            }

                        </Paper>
                    </Grid>
                </Grid>
                <ScrollUpIcon />
            </div>
        </>
    );
}

UserManagementListTable.propTypes = {
    className: PropTypes.string,
    i: PropTypes.array,
}

export default UserManagementListTable;