import React, { useState } from "react"

import { Button, Box, Dialog, DialogContent, Typography, IconButton, InputBase, Divider,
    TableContainer, Table, TableHead, TableBody, TableRow, TableCell
} from '@mui/material';

import Icon from '../../../assets/icons/Icon.js';
import { petrol800 } from "../../other/helper/MuiStyles.js";
import { mergeUserInputAndScale, parseDataInput } from "./CDSUtils.js";

import { useStyles } from '../../other/dialogs/UniversalDialog.styles.js';

const ValuesPreviewTable = ({ values, rows, cols }) => {
    const n_rows = Math.max(values.length, rows.length)
    const n_cols = Math.max(cols.length, values.reduce((acc, val) => Math.max(acc, val.length), 0))
    return (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell style={ { border: 'none' } } />
                {[...Array(n_cols).keys()].map((col_ind) => (
                    <TableCell align="center" sx={{ fontWeight: 'bold', border: 'none' }} key={`th_${col_ind}`}>{cols[col_ind]}%</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {[...Array(n_rows).keys()].map((row_ind) => (
                <TableRow key={row_ind}>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>{rows[row_ind]}°C</TableCell>
                  {[...Array(n_cols).keys()].map((col_ind) => {
                        const value = values[row_ind] ? values[row_ind][col_ind] : undefined
                        const isInvalid = value === undefined || isNaN(value) || 
                            row_ind > rows.length - 1 || col_ind > cols.length - 1
                        return (
                            <TableCell 
                                align="center" 
                                key={`${row_ind}_${col_ind}`}
                                style={ isInvalid ? {color: 'red'} : {} }
                            >
                                { value === undefined ? '...' : `${value}` }
                            </TableCell>
                        )
                    }
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
}

const DataInput = ({ rows, cols, onApply }) => {
    const [show, setShow] = useState(false)
    const [values, setValues] = useState()
    const [isInputValud, setIsInputValid] = useState(false)

    const toggleModal = () => { 
        if (show) {
            setValues(undefined)
        }
        setShow(!show) 
    }

    const handleChange = (userInput) => { 
        if (userInput === '') {
            setValues(undefined)
            setIsInputValid(false)
        }
        else {
            const vls = parseDataInput(userInput)
            setValues(vls)

            const isValid = vls.length === rows.length && 
                vls.reduce((acc, val) => Math.max(acc, val.length), 0) === cols.length &&
                vls.reduce((acc, val) => Math.min(acc, val.length), Infinity) === cols.length &&
                vls.reduce((acc, val) => acc && !val.includes(NaN), true)

            setIsInputValid(isValid)
        }
    }

    const classes = useStyles();

    return (<>
        <Box display="flex" flexDirection="column">
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={toggleModal}
                    style={{ marginRight: '16px' }}
                >
                    <div style={{ display: 'flex', marginRight: '8px' }}>
                        <Icon iconName={"UploadIcon"} fill={petrol800}/>
                    </div>Insert Values
                </Button>
        </Box>

        <Dialog
            fullWidth={true}
            maxWidth="xl"
            open={show}
            onClose={toggleModal}
        >
            <DialogContent>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h2">
                        Insert Values
                    </Typography>
                    
                    <IconButton onClick={toggleModal}>
                        <Icon iconName={"CloseIcon"} />
                    </IconButton>
                </Box>
                <Box display='flex' flexDirection='column' flexGrow="1">
                    <Typography className={classes.grid_info}> 
                        Copy and paste values from Excel
                    </Typography>
                    <InputBase
                        multiline={true}
                        minRows={5}
                        fullWidth={true}
                        onChange={event => handleChange(event.target.value)}
                    />
                </Box>
                <Divider className={classes.grid_info} />
                <Box display='flex' flexDirection='column' flexGrow="1">
                    {values && <Typography> 
                        Table preview
                    </Typography>}
                    {values && <ValuesPreviewTable values={values} rows={rows} cols={cols} />}
                </Box>

                {values && !isInputValud && <Box display='flex' flexDirection='column' flexGrow="1">
                    <Typography className={`${classes.alert_message} ${classes.grid_info}`}>
                        Incorrect input format. Please check that data has correct dimention and format
                    </Typography>
                </Box>}


                {values && <Box display='flex' flexDirection='column' flexGrow="1">
                        <Typography className={classes.grid_info}>
                            Please note that inserted values will OVERWRITE existing Cell Data Sheet data.
                        </Typography>
                    </Box>}

                <Box display='flex' flexDirection='column' flexGrow="1">       
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ width: '130px' }}
                        disabled={!isInputValud}
                        onClick={() => { 
                            toggleModal()
                            onApply(mergeUserInputAndScale(values, rows, cols)) 
                        }}
                    >
                        Insert Values
                    </Button>
                </Box>             
            </DialogContent>
        </Dialog>
    </>)
}

export default DataInput
