import { makeVar } from "@apollo/client";

export const sampleVar = makeVar(0);
export const subSampleVar = makeVar(0);
export const indexVar = makeVar(0);
export const calcLatestSample = makeVar(true);

export const projectRole = makeVar('New User');
export const screenWidth = makeVar(1280);

// for updating attributes in table and stopping Loading
export const currentUpdateTestCaseId = makeVar(0);
// currently selected testCase
export const currentTestCaseId = makeVar(1);
export const currentProjectId = makeVar(1);
export const currentIndexId = makeVar(-1);

// currently selected testSpec
export const currentTestSpecificationId = makeVar(1);

// LOADING VARIABLES
// loading for grid points mutations
export const isCDSLoading = makeVar(false);
// loading for lockProject & lockCDS & finalizeDraft
export const isLockLoading = makeVar(false);
// TestCases in table loading
export const isTestCasesLoading = makeVar(false);
