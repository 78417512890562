import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DatePicker as KendoDatePicker, Calendar } from "@progress/kendo-react-dateinputs";
import { IntlProvider, LocalizationProvider, IntlService } from "@progress/kendo-react-intl";
import { IconButton, InputBase } from '@mui/material';

import Icon from '../../../../assets/icons/Icon';
import { convertTimeStampToCwYear, getDateOfISOWeek } from '../timeConverter/timeConverter';

class CustomFormatIntlService extends IntlService {
    firstDay() {
        return 1;
    }
}

class CustomFormatIntlProvider extends IntlProvider {
    getIntlService() {
        return new CustomFormatIntlService(this.props.locale);
    }
}


const StyledDatePicker = styled(KendoDatePicker)`
    border-radius: initial;
    width: auto;

    &:focus, &:focus-within {
        box-shadow: none;
    }

`;

const StyledCalendar = styled(Calendar)`
    .k-calendar-table .k-calendar-th,
    .k-calendar-content .k-calendar-td.k-alt {
        font-weight: bold;
        color: initial;   
    }
`

export const CW_FORMAT = /^(?:cw)?\s?(\d{1}|[0-4]{1}[0-9]{1}|5{1}[0-3]{1})\/(?:20)?(\d{2})$/i;

export const getDateFromDateFormat = (val) => {
    // supporting date format: DD.MM.YYYY
    const DATE_FORMAT = /^(\d{2}).(\d{2}).(\d{4})$/i;
    const matchRes = val.match(DATE_FORMAT)

    if (matchRes !== null) {
        const newDate = new Date(`${matchRes[3]}-${matchRes[2]}-${matchRes[1]} 00:00:00`)
        return newDate.toString() !== 'Invalid Date' ? newDate : null
    }
    return null    
}

export const parseUserInput = (val, endOfWeek) => {
    if (val === null) {
        return [false, null]
    }

    let isValid = false
    let parsedDate = null

    const matchCW = val.trim().match(CW_FORMAT)
    const matchDate = getDateFromDateFormat(val.trim())

    if (matchCW !== null){
        isValid = true
        parsedDate = getDateOfISOWeek(parseInt(matchCW[1]), parseInt(matchCW[2]), endOfWeek).toISOString()
    } else if (matchDate != null) {
        isValid = true
        parsedDate = matchDate.toISOString()
    }

    return [isValid, parsedDate]
}

const CustomDateInput = ({ userInput, validValue, propagateUserInput, onBlur }) => {
    const [isActive, setIsActive] = useState(false)

    const handleChange = (syntheticEvent) => {
        if (isActive){
            const inputVal = syntheticEvent.target.value
            propagateUserInput(inputVal, isActive)
        }
    }

    const handleBlur = () => {
        setIsActive(false)
        if (validValue !== null) {
            propagateUserInput(convertTimeStampToCwYear(validValue), false)
        }
        onBlur(validValue)
    }

    return (
        <InputBase
            color='primary'
            style={{ width: '100%', 'backgroundColor': 'white', 'flex': 1 }}
            onChange={handleChange}
            value={userInput || ""}
            onFocus={() => { setIsActive(true) }}
            onBlur={handleBlur}
            error={validValue === null}
      />
    )
}
const locale =
{
    language: "en-US",
    locale: "en",
};

const CustomToggleButton = props => {
    return (
        <IconButton {...props}>
            {props.children}
            <Icon iconName={"CalendarIcon"} fill={"grey"} />
        </IconButton>
    )
};



const DatePicker = ({ date, onChange = () => {}, onBlur = () => {}, endOfWeek = false, trackInitDate = false }) => {
    const [userInput, setUserInput] = useState(date ? convertTimeStampToCwYear(date) : null)
    const [validValue, setValidValue] = useState(date)  // in date format

    useEffect(() => {
        onChange(validValue)
    }, [validValue]);

    if (trackInitDate) {
        useEffect(() => {
            setUserInput(date ? convertTimeStampToCwYear(date) : null)
            setValidValue(date)
        }, [date]);
    }
    
    const onUserInput = (newUserInput, updateValidValue) => {
        setUserInput(newUserInput)

        if (updateValidValue) {
            const newDate = parseUserInput(newUserInput, endOfWeek)[1]
            setValidValue(newDate)
        }
    }

    const handleBlur = (newValue) => {
        if (convertTimeStampToCwYear(date) !== convertTimeStampToCwYear(newValue)) {
            onBlur(newValue)
        }
    }

    return (<div style={{ display: 'flex' }}>
        <CustomDateInput userInput={userInput} validValue={validValue} propagateUserInput={onUserInput} onBlur={handleBlur}/>
        <LocalizationProvider language={locale.language}>
            <CustomFormatIntlProvider locale={locale.locale}>
                <StyledDatePicker
                    value={validValue ? new Date(validValue): new Date()}
                    onChange={(event) => { 
                        const newDate = event.target.value.toISOString()
                        
                        setUserInput(convertTimeStampToCwYear(newDate))
                        setValidValue(newDate)
                        handleBlur(newDate)
                    }}
                    weekNumber={true}
                    dateInput={() => <></>}
                    calendar={StyledCalendar}
                    toggleButton={CustomToggleButton}
                />
            </CustomFormatIntlProvider>
        </LocalizationProvider>
    </div>)
}

export default DatePicker