function timeConverterSorting(UNIX_timestamp) {
    return (new Date(UNIX_timestamp).getTime());
}

function timeConverterComment(UNIX_timestamp) {
    let a = new Date(UNIX_timestamp);
    let months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    let year = a.getFullYear();
    let month = months[a.getMonth()];
    let date = ('0' + a.getDate()).slice(-2);
    let hour = a.getHours();
    let min = ('0' + a.getMinutes()).slice(-2);
    return (date + '.' + month + '.' + year + ' - ' + hour + ':' + min);
}

function convertTimeStampToCwYear(UNIX_timestamp) {
    // eslint-disable-next-line no-extend-native
    Date.prototype.getWeek = function (dowOffset) {
        /*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com */

        dowOffset = typeof (dowOffset) == 'number' ? dowOffset : 0; //default dowOffset to zero
        let newYear = new Date(this.getFullYear(), 0, 1);
        let day = newYear.getDay() - dowOffset; //the day of week the year begins on
        day = (day >= 0 ? day : day + 7);
        let daynum = Math.floor((this.getTime() - newYear.getTime() -
            (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1;
        let weeknum;
        //if the year starts before the middle of a week
        if (day < 4) {
            weeknum = Math.floor((daynum + day - 1) / 7) + 1;
            if (weeknum > 52) {
                let nYear = new Date(this.getFullYear() + 1, 0, 1);
                let nday = nYear.getDay() - dowOffset;
                nday = nday >= 0 ? nday : nday + 7;
                /*if the next year starts before the middle of
                  the week, it is week #1 of that year*/
                weeknum = nday < 4 ? 1 : 53;
            }
        }
        else {
            weeknum = Math.floor((daynum + day - 1) / 7);
        }
        return weeknum;
    };
    let a = new Date(UNIX_timestamp);
    let year = a.getFullYear();
    let week = a.getWeek(1);
    return ('CW ' + String(week).padStart(2, '0') + '/' + String(year).substring(2, 4));
}

function getDateOfISOWeek(w, y, weekEnd = false) {
    var simple = new Date(2000 + y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());

    if (weekEnd) {
        ISOweekStart.setHours(ISOweekStart.getHours() + 24 * 6 + 23)
    }

    // stay in the same year
    var ISOday = weekEnd 
        ? new Date(Math.min(new Date(2000 + y, 11, 31, 23), ISOweekStart)) 
        : new Date(Math.max(new Date(2000 + y, 0, 1), ISOweekStart))

    return ISOday;
}

export {
    timeConverterSorting,
    timeConverterComment,
    convertTimeStampToCwYear,
    getDateOfISOWeek
}