import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';

export const HtmlTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: '#20607E',
        color: '#FFFFFF',
        fontSize: '0.75rem',
        borderRadius: '2px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.1)',
        minWidth: props => props.minwidth ? props.minwidth : 'auto',
        maxWidth: props => props.maxwidth ? props.maxwidth : 'auto',
    },
    arrow: {
        color: '#20607E'
    }
}))((props) => <Tooltip arrow {...props} />);