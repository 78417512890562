import makeStyles from '@mui/styles/makeStyles';
import { grey200, grey600, grey800 } from '../../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    // todo: refactor, same style as in ProjectDetails.styles.js
    paper: {
        minWidth: "1232px",
        backgroundColor: '#FFF',  
    },
    // todo: refactor, same style as in CellDataSheet.styles.js
    scrollable: {
        overflowX: 'auto',
        width: '100%',
        '&::-webkit-scrollbar': {
          width: '0.4em',
          height: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: grey600,
          borderRadius: '8px'
        },
    },
    ////

    table: {
        display: 'flex',
        flexFlow: 'column nowrap',
        whiteSpace: 'nowrap',
        position: 'relative',

        '& > div': {
            display: 'flex',
            flexFlow: 'row nowrap',
            borderTop: `1px solid ${grey200}`,

            '& > div': {
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                height: '56px',
                boxSizing: 'border-box',
            }
        },
    },
    tableStub: {
        width: '60%',
        justifyContent: 'left',
        
        // header
        '& > div:first-child': {
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            letterSpacing: '0.8px',
            color: grey800,
            borderRight: `1px solid ${grey200}`,
        },
        '& > div > div': {
            padding: '0 8px',
            '&:first-child': {
                paddingLeft: '16px',
            },
            '&:last-child': {
                paddingRight: '16px',
            },

            '&:nth-child(1)': {
                minWidth: '32%',
                maxWidth: '32%',
            },
            '&:nth-child(2)': {
                minWidth: '20%',
                maxWidth: '20%'
            },
            '&:nth-child(3)': {
                minWidth: '25%',
                maxWidth: '25%'
            },
            '&:nth-child(4)': {
                minWidth: '23%',
                maxWidth: '23%'
            },
        },

        // rows
        '& > div:not(:first-child)': {
            cursor: 'pointer',

            // padding comes from TestCaseInfo
            '& > div:first-child': {
                paddingLeft: 0
            }
        }
    },


    timelineHeader: {
        position: 'relative', 
        width: 'fit-content',
        flexFlow: 'column nowrap !important',
        minWidth: '100%',
        height: '56px',
        letterSpacing: '0.8px',
        color: grey800,

        '& > div': {
            flexFlow: 'row nowrap',
            height: '28px',
            position: 'relative',

            '&:first-child': {
                width: '100%', 
            },
            '&:last-child': {
                minWidth: '100%',
                borderTop: `1px solid ${grey200}`,
            },

            '& > div': {
                borderLeft: `1px solid ${grey200}`,
                height: '100%',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
            }
        }
    },
    timelineRow: {
        position: 'relative', 
        width: 'fit-content', 
        minWidth: '100%',

        '&:nth-child(2) .today': {
            marginTop: 0,
            borderTopLeftRadius: '2px',
            borderTopRightRadius: '2px',
        },
        '&:last-child .today': {
            marginBottom: 0,
            borderBottomLeftRadius: '2px',
            borderBottomRightRadius: '2px',
        }

    },
    timelineItem: {
        width: '70px', 
        flex: 1
    },
    duration: {
        backgroundColor: 'gray', 
        display: 'block', 
        position: 'absolute', 
        top: '20px', 
        height: '16px !important',
        borderRadius: 0,

        '&:first-of-type': {
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
        },
        '&:last-of-type': {
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
        }

    },
    today: {
        backgroundColor: grey600, 
        display: 'block', 
        position: 'absolute', 
        width: '4px',
        margin: '-2px 0',
        height: '58px !important'
    }

}))