import makeStyles from '@mui/styles/makeStyles';
import { buttonHoverPetrol800, grey100, grey1000, grey200, grey400, grey600, grey800, signalGreen800, signalRed800 } from '../../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
  root: {
      padding: '24px',
      backgroundColor: grey100,
  },
  paper: {
    minWidth: "1232px",
    // margin: "auto",
    backgroundColor: '#FFF',  
  },
  userimage: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '32px',
    width: '32px',
    borderRadius: '16px',
    display: 'flex',
  },
  supplierimage: {
    backgroundImage: props => `url(${props.supplierImg})`,
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: '35px',
    minWidth: '100px',
  },
  percent_container_div: {
    width: "100%",
    height: "16px",
    // align: "center",
    backgroundColor: grey200,
    borderRadius: "8px",
    // textAlign: "center",
    // verticalAlign: "middle",
    // position: "relative",
    display: 'flex', //delete for percent_bars left and right
  },
  percent_bar_passed: {
    width: props => props.widthTestsPassed,
    height: "16px",
    backgroundColor: signalGreen800,
    borderRadius: "8px 0 0 8px",
    // position: "absolute",
    left: "0",
    zIndex: "2"
  },
  percent_bar_failed: {
    width: props => props.widthTestsFailed,
    height: "16px",
    backgroundColor: signalRed800,
    borderRadius: "0",
    // position: "absolute",
    // right: "0",
    zIndex: "2"
  },
  percent_bar_notevaluated: {
    width: props => props.widthTestsNotEvaluated,
    height: "16px",
    backgroundColor: grey800,
    borderRadius: "0 8px 8px 0",
    // position: "absolute",
    // right: "0",
    zIndex: "2"
  },
  hover_blue: {
      '&:hover': {
          backgroundColor: buttonHoverPetrol800,
        },
  },
  pressed_button: {
    marginLeft: '24px',
  },
  subheader: {
    lineHeight: "24px",
    letterSpacing: "0.4px",
  },
  input_title: {
      marginBottom: '4px',
  },
  input_title_big: {
    display: 'flex',
    width: '362px',
    color: grey1000,
    fontWeight: "400",
    fontSize: "16px",
    fontStyle: 'normal',
    lineHeight: '18px',
    letterSpacing: '0.8px',
    marginBottom: '8px',
  },
  info_values: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '24px',
    letterSpacing: '0.8px',
  },
  tbd_text: {
    marginLeft: '16px',
    color: grey600,
    display: 'flex',
    alignItems: 'center',
    lineHeight: '18px',
    letterSpacing: '0.8px',
    fontWeight: '400',
  },
  users: {
    paddingLeft: '16px',
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    lineHeight: '18px',
    letterSpacing: '0.8px',
    fontWeight: '400',
    textAlign: 'left',
  },
  freeText2: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing: '0.3px',
    padding: '16px !important',
    border: `1px solid ${grey400}`,
    boxSizing: "border-box",
    borderRadius: "2px",
    minHeight: '120px',
    height: 'auto',
    alignItems: 'flex-start !important',
  },
  freeText3: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing: '0.3px',
    paddingTop: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
    border: `1px solid ${grey400}`,
    boxSizing: "border-box",
    borderRadius: "2px",
    height: 'auto',
    minHeight: '205px',
    // '&:hover': {
    //   border: `1px solid ${petrol800}`,
    // },
  },
  // box_freeText: {
  //   width: 'auto',
  // },
  box_freeText2: {
    width: 'auto',
    minHeight: '95px !important',
    alignItems: 'flex-start',
  },
  alert_message: {
    marginTop: '4px',
    color: signalRed800,
  },
  multiple_select_box: {
    height: 'auto !important',
    "& #mui-component-select-platforms": {
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
    },
  },
  platforms: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  platform: {
    backgroundColor: grey100,
    marginRight: '16px',
    height: '30px',
    marginBottom: '4px',
    fontSize: '16px',
    letterSpacing: '0.8px',
    fontWeight: '400',
    color: grey1000,
  },
  result_number: {
    display: "flex",
    color: grey1000,
    fontWeight: "700",
    lineHeight: "18px",
    letterSpacing: "0.8px",
  },
  snackbar: {
    position: 'sticky',
    height: '100%',
    top: '0',
    zIndex: '1' 
  },
  error_text: {
    marginTop: '8px',
    fontWeight: '400',
  },
  error_type: {
    padding: '0px 24px',
    textAlign: 'center',
    marginTop: '4px',
    fontWeight: '400',
    color: grey600,
  },  
  tag: {
    border: `1px solid ${signalRed800}`,
    borderRadius: '20px',
    height: '30px',
    padding: props => props.paddingLockBadge,
    fontStyle: 'normal',
    fontWeight: '700',
    fontFamily: 'VWAG TheSans',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.6px',
    color: signalRed800,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
},
}));