import React from 'react';
import { Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import { grey1000, petrol100, petrol800 } from '../MuiStyles';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        padding: '16px'
    },
    selected_parameter_box: {
        backgroundColor: petrol100,
        padding: '0px 16px',
        borderRadius: '8px',
        marginRight: '8px',
        marginBottom: '8px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '40px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    number: {
        marginLeft: '8px',
        height: '24px',
        minWidth: '24px',
        borderRadius: '24px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '0.8px',
        backgroundColor: petrol800,
        color: '#FFFFFF',
    }
}
));

function SelectedTestSpec(props) {
    const { name, version, activecount } = props;

    const classes = useStyles();
    return (
        <Box data-testid="name" className={classes.selected_parameter_box} display="flex" alignItems="center">
            {name}  V. {version}  
            <Box data-testid="number" display="flex" justifyContent="center" alignItems="center" className={classes.number}>
            {activecount}
            </Box>
            
        </Box>
    )
}


export default SelectedTestSpec;