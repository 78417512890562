import makeStyles from '@mui/styles/makeStyles';
import { buttonHoverPetrol800, grey600, signalRed800 } from '../../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    petrol_icon: {    
        padding: '8px',   
        '&:hover': {
            backgroundColor: buttonHoverPetrol800,
          },
    },
    assignment_box: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: '24px',
    },
    left_select_box: {
        width: '436px',
        height: '40px',
        marginRight: '24px',
        textAlign: 'left',
    },
    right_select_box: {
        width: '392px',
        height: '40px',
        textAlign: 'left',
    },
    alert_message: {
        marginTop: '4px',
        color: signalRed800,
    },
    error_text: {
        marginTop: '8px',
        fontWeight: '400',
    },
    error_type: {
        padding: '0px 24px',
        textAlign: 'center',
        marginTop: '4px',
        fontWeight: '400',
        color: grey600,
    },
    required_tag: {
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '18px',
        letterSpacing: '0.8px',
        color: grey600,
        marginLeft: '24px',
    },
    index_title: {
        marginBottom: '4px',
        fontWeight: '700',
        lineHeight: '21px',
        letterSpacing: "0.2px",
    },
}));