import React from 'react';
import Box from '@mui/material/Box';
import { useStyles } from '../Tooltip.styles';
import { HtmlTooltip } from '../HtmlTooltip/HtmlTooltip.js';

export default function ParameterNameInfo(props) {
  const { name, inactive } = props;
  const classes = useStyles();
  return (
    <HtmlTooltip data-testid="tooltip" maxwidth="600px"
      title={
        <Box display="flex " flexDirection="row">
          <Box display="flex " flexDirection="column" p={1}>
            <Box className={classes.title}>Parameter:</Box>
            <Box mt={0.5} className={classes.value} title="reference">{name}</Box>
          </Box>
        </Box>
      }
    >
      <Box className={classes.parameter_title} style={inactive ? {color: '#FFF'} : {}}>{name}</Box>
    </HtmlTooltip>
  )
}