//CELL SUPPLIERS
import A123 from '../../../../assets/logos/A123.png';
import CALB from '../../../../assets/logos/CALB.png';
import CATL from '../../../../assets/logos/CATL.png';
import Farasis from '../../../../assets/logos/Farasis.png';
import Gotion from '../../../../assets/logos/Gotion.png';
import LG from '../../../../assets/logos/LG.png';
import PowerCo from '../../../../assets/logos/PowerCo.png';
import Northvolt from '../../../../assets/logos/Northvolt.png';
import SK from '../../../../assets/logos/SK.png';
import Sunwoda from '../../../../assets/logos/Sunwoda.png';
import SamsungSDI from '../../../../assets/logos/SamsungSDI.png';

//AVATARS
import Avatar1 from '../../../../assets/avatars/Avatar1.png';
import Avatar2 from '../../../../assets/avatars/Avatar2.png';
import Avatar3 from '../../../../assets/avatars/Avatar3.png';
import Avatar4 from '../../../../assets/avatars/Avatar4.png';
import Avatar5 from '../../../../assets/avatars/Avatar5.png';
import Avatar6 from '../../../../assets/avatars/Avatar6.png';
import Avatar7 from '../../../../assets/avatars/Avatar7.png';
import Avatar8 from '../../../../assets/avatars/Avatar8.png';
import Avatar9 from '../../../../assets/avatars/Avatar9.png';
import Avatar10 from '../../../../assets/avatars/Avatar10.png';
import Avatar11 from '../../../../assets/avatars/Avatar11.png';
import Avatar12 from '../../../../assets/avatars/Avatar12.png';
import Avatar13 from '../../../../assets/avatars/Avatar13.png';
import Avatar14 from '../../../../assets/avatars/Avatar14.png';

export const IMAGE_MAP = {
  SUPPLIER: {
    A123: A123,
    CALB: CALB,
    CATL: CATL,
    Farasis: Farasis,
    Gotion: Gotion,
    'LG Chem': LG,
    PowerCo: PowerCo,
    Northvolt: Northvolt,
    'Samsung SDI': SamsungSDI,
    'SK Innovation': SK,
    Sunwoda: Sunwoda,
  },
  USERS: {
    placeholder: 'https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png',
  },
  SAMPLEDOCUMENTIMAGE: {
    upload: 'upload-24',
    Geschäftsunterlagen: 'document-24',
    Gefahrgutunterlagen: 'tooltip-24'
  },
  AVATAR: [
    {id: 0, pngSrc: Avatar1},
    {id: 1, pngSrc: Avatar2},
    {id: 2, pngSrc: Avatar3},
    {id: 3, pngSrc: Avatar4},
    {id: 4, pngSrc: Avatar5},
    {id: 5, pngSrc: Avatar6},
    {id: 6, pngSrc: Avatar7},
    {id: 7, pngSrc: Avatar8},
    {id: 8, pngSrc: Avatar9},
    {id: 9, pngSrc: Avatar10},
    {id: 10, pngSrc: Avatar11},
    {id: 11, pngSrc: Avatar12},
    {id: 12, pngSrc: Avatar13},
    {id: 13, pngSrc: Avatar14},
  ],
};