import React, { useContext, useState } from 'react'

const UploadContext = React.createContext(null);

const UploadProgressContext = () => {
    const { uploadProgressValue } = useContext(UploadContext);
    const [uploadProgress, setUploadProgress] = uploadProgressValue;
    const cumulateProgress = (value) => {
        setUploadProgress(prevState => ({
            ...prevState,
            [value.requestId]: value.progress
        }));
    };
    const resetProgress = () => {
        setUploadProgress(prevState => {
            if (Object.values(prevState).filter(progress => progress < 100).length === 0) {
                return {};
            }

            return { ...prevState };
        });
    };

    return { uploadProgress: uploadProgress, setUploadProgress: cumulateProgress, resetUploadProgress: resetProgress };
};


const UploadProvider = ({ children }) => {
    const [uploadProgress, setUploadProgress] = useState({});

    return (
        <UploadContext.Provider
            value={{
                uploadProgressValue: [uploadProgress, setUploadProgress]
            }}
        >
            {children}
        </UploadContext.Provider>
    );
};

export {
    UploadProvider,
    UploadProgressContext
}