import React, {useContext, useState} from 'react';
import { sampleVar, subSampleVar, indexVar, currentIndexId, calcLatestSample } from '../../operations/localeStorage';
import { Box, Typography, Button } from '@mui/material';
import { useStyles } from './SampleState.styles.js';
import Icon from '../../assets/icons/Icon.js';
import DeleteState from '../other/dialogs/DeleteState/DeleteState';
import DeleteIndex from '../other/dialogs/DeleteIndex/DeleteIndex';
import AddEditIndex from '../other/dialogs/AddEditIndex/AddEditIndex';
import EditIndexInformation from '../other/dialogs/EditIndexInformation/EditIndexInformation';
import {  SnackbarContext, SnackbarType} from '../other/contexts/SnackBarContext';
import { handleSampleName, handleSubSampleName } from './ProjectDetails/samplefunctions';
import { grey100, petrol600 } from '../other/helper/MuiStyles';
import BatteryLoader from '../other/helper/BatteryLoader/BatteryLoader';
import { useMutation } from '@apollo/client';
import { ADDINDEX, ADDSUBSAMPLE, DELETEINDEX, DELETESUBSAMPLE, UPDATEINDEX } from '../../operations/mutations/mutations';
import { PROJECTDETAIL, TESTCASES } from '../../operations/queries/query';

function SampleState(props) {
  const { editSample, index, id, projectId, formSample, setFormSample, selectedSample, setSelectedSample, setSelectedSubSample, selectedIndex, setSelectedIndex } = props;
  const { setIsSnackbarOpen, setMessage, setSnackbarType } = useContext(SnackbarContext);

  const handleOnError = async (error) => {
    setMessage(error.message);
    setSnackbarType(SnackbarType.ERROR);
    setIsSnackbarOpen(true);
  }

  const handleOnCompleted = async () => {
    setSnackbarType(SnackbarType.SUCCESS);
    setIsSnackbarOpen(true);
  }

  const [mutationAddSubSample, { loading: addSubSampleLoading }] = useMutation(ADDSUBSAMPLE, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
    refetchQueries: [{ query: TESTCASES(currentIndexId()) }, { query: PROJECTDETAIL(id) }],
    awaitRefetchQueries: true
  });
  const [deleteSubSample, { loading: deleteSubSampleLoading }] = useMutation(DELETESUBSAMPLE, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
    refetchQueries: [{ query: TESTCASES(currentIndexId()) }, { query: PROJECTDETAIL(id) }],
    awaitRefetchQueries: true
  });
  const [addIndex, { loading: addIndexLoading }] = useMutation(ADDINDEX, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
    refetchQueries: [{ query: TESTCASES(currentIndexId()) }, { query: PROJECTDETAIL(id) }],
    awaitRefetchQueries: true
  });
  const [updateIndex, { loading: updateIndexLoading }] = useMutation(UPDATEINDEX, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
    refetchQueries: [{ query: TESTCASES(currentIndexId()) }, { query: PROJECTDETAIL(id) }],
    awaitRefetchQueries: true
  });
  const [deleteIndex, { loading: deleteIndexLoading }] = useMutation(DELETEINDEX, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
    refetchQueries: [{ query: TESTCASES(currentIndexId()) }, { query: PROJECTDETAIL(id) }],
    awaitRefetchQueries: true
  });

  //intial load of highest SubSample
  const [countPSamples, setCountPSamples] = useState(() => {
    return formSample[0]?.subSamples[formSample[0].subSamples.length - 1]?.name;
  });
  const [countASamples, setCountASamples] = useState(() => {
    return formSample[1]?.subSamples[formSample[1].subSamples.length - 1]?.name;
  });
  const [countBSamples, setCountBSamples] = useState(() => {
    return formSample[2]?.subSamples[formSample[2].subSamples.length - 1]?.name;
  });
  const [countCSamples, setCountCSamples] = useState(() => {
    return formSample[3]?.subSamples[formSample[3].subSamples.length - 1]?.name;
  });

  const handleNewSubSampleName = (currentlyHighestSubSample) => {
    let splitSubSampleName = currentlyHighestSubSample.split('');
    let newSubSample = splitSubSampleName[0] + (parseInt(currentlyHighestSubSample.slice(1)) + 1);
    switch (splitSubSampleName[0]) {
      case "P":
        setCountPSamples(newSubSample);
        break;
      case "A":
        setCountASamples(newSubSample);
        break;
      case "B":
        setCountBSamples(newSubSample);
        break;
      case "C":
        setCountCSamples(newSubSample);
        break;
      default:
        break;
    }
    return newSubSample;
  };

  const addSubSample = () => {
    switch (selectedSample) {
      case "P":
        calcLatestSample(false);
        mutationAddSubSample({ variables: { sampleId: formSample[0].sampleId, name: handleNewSubSampleName(countPSamples) } });
        break;
      case "A":
        calcLatestSample(false);
        mutationAddSubSample({ variables: { sampleId: formSample[1].sampleId, name: handleNewSubSampleName(countASamples) } });
        break;
      case "B":
        calcLatestSample(false);
        mutationAddSubSample({ variables: { sampleId: formSample[2].sampleId, name: handleNewSubSampleName(countBSamples) } });
        break;
      case "C":
        calcLatestSample(false);
        mutationAddSubSample({ variables: { sampleId: formSample[3].sampleId, name: handleNewSubSampleName(countCSamples) } });
        break;
      default:
        break;
    }
    setMessage('Sub Sample has been added');
  };

  const changeSample = (value, index) => {
    setSelectedSample(value);
    sampleVar(index);
    setSelectedSubSample(formSample?.filter(function (sample) { return sample.name === value })[0]?.subSamples[0]?.name);
    subSampleVar(0);
    setSelectedIndex(formSample?.filter(function (sample) { return sample.name === value })[0]?.subSamples[0]?.indices[0]?.name);
    indexVar(0);
  };

  const changeSubSample = (value, index) => {
    setSelectedSubSample(value);
    subSampleVar(index);
    setSelectedIndex(formSample[sampleVar()]?.subSamples?.filter(function (subsample) { return subsample.name === value })[0]?.indices[0]?.name);
    indexVar(0);
  };

  const changeIndex = (value, index) => {
    setSelectedIndex(value);
    indexVar(index);
    currentIndexId(formSample[sampleVar()]?.subSamples[subSampleVar()]?.indices[index]?.indexId);
  };

  const classes = useStyles();

  return <>
    <Box p={3} style={{ backgroundColor: grey100 }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" style={{ fontWeight: '400' }}>
          Sample
        </Typography>
      </Box>

      <Box mt={0.5} display="flex" alignItems="center" flexWrap="wrap">
        {formSample?.map((sample, index) => {
          return (
            <Box key={sample.name} display="flex" alignItems="center" className={sampleVar() === index ? classes.selectedButton : classes.normalButton}>
              <Box
                onClick={() => { changeSample(sample.name, index) }}
              >
                {handleSampleName(sample.name)}
              </Box>
              {sample.hasTestCases && <Box ml={1} className={classes.hasTestCases}></Box>}
            </Box>
          )
        })}
      </Box>

      <Box mt={1} display="flex" alignItems="center">
        <Typography variant="h6" style={{ fontWeight: '400' }}>
          Sub Sample
        </Typography>
      </Box>

      <Box mt={0.5} display="flex" alignItems="center" flexWrap="wrap">
        {(deleteSubSampleLoading || addSubSampleLoading) && <BatteryLoader type="small" />}
        {!deleteSubSampleLoading && !addSubSampleLoading && formSample[sampleVar()]?.subSamples?.map((subSample, index) => {
          return (
            <Box key={subSample.name} display="flex" alignItems="center" className={subSampleVar() === index ? classes.selectedButton : classes.normalButton}>
              <Box
                onClick={() => { changeSubSample(subSample.name, index) }}
              >
                {subSample.name}-{handleSubSampleName()}
              </Box>
              {subSample.hasTestCases && <Box ml={1} className={classes.hasTestCases}></Box>}
              {editSample && formSample[sampleVar()]?.subSamples?.length > 1 && subSampleVar() === index &&
                <DeleteState mutationFunction={deleteSubSample} formSample={formSample} setFormSample={setFormSample} subSampleName={subSample.name} changeSubSample={changeSubSample} index={index} setCountPSamples={setCountPSamples} setCountASamples={setCountASamples} setCountBSamples={setCountBSamples} setCountCSamples={setCountCSamples} />
              }
            </Box>
          )
        })}
        {!deleteSubSampleLoading && !addSubSampleLoading && editSample &&
          <Button variant="contained" color="secondary" className={classes.add_button_index} onClick={addSubSample}>
            <div style={{ display: 'flex', marginRight: '8px' }}><Icon iconName={"AddIcon"} fill={petrol600} /></div> Add more
          </Button>
        }
      </Box>
      {index &&
        <>
          <Box mt={1} display="flex" flexDirection="row" alignItems="center">
            <Typography variant="h6" style={{ fontWeight: '400' }}>
              Index
            </Typography>
          </Box>

          <Box mt={0.5} display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
            {(deleteIndexLoading || addIndexLoading || updateIndexLoading) && <BatteryLoader type="small" />}
            {!deleteIndexLoading && !addIndexLoading && !updateIndexLoading && formSample[sampleVar()]?.subSamples[subSampleVar()]?.indices?.map((indexName, index) => {
              return (
                <Box key={index} display="flex" flexDirection="row" alignItems="center" className={indexVar() === index ? classes.selectedButton : classes.normalButton}>
                  <Box
                    onClick={() => { changeIndex(indexName.name, index) }}
                  >
                    {indexName.name}
                  </Box>
                  {editSample && indexVar() === index &&
                    <AddEditIndex edit={true} mutationFunction={updateIndex} formSample={formSample} indexName={indexName.name} index={index} />
                  }
                  {editSample && formSample[sampleVar()]?.subSamples[subSampleVar()]?.indices?.length > 1 && indexVar() === index &&
                    <DeleteIndex mutationFunction={deleteIndex} formSample={formSample} indexName={indexName.name} index={index} changeIndex={changeIndex} />
                  }
                </Box>
              )
            })}
            {!deleteIndexLoading && !addIndexLoading && !updateIndexLoading && editSample &&
              <AddEditIndex mutationFunction={addIndex} formSample={formSample} />
            }
          </Box>

          <Box mt={1} display="flex" flexDirection="row" alignItems="center">
            <Typography variant="h6" style={{ fontWeight: '400' }}>
              Information on Index
            </Typography>
          </Box>

          <Box mt={0.5} display="flex" flexDirection="row" alignItems="center" style={{ height: '40px' }}>
            <Typography variant="h5" style={{ textAlign: 'left' }}>
              {(formSample[sampleVar()]?.subSamples[subSampleVar()]?.indices[indexVar()]?.info?.length < 1 || formSample[sampleVar()]?.subSamples[subSampleVar()]?.indices[indexVar()]?.info === null) ? "-" : formSample[sampleVar()]?.subSamples[subSampleVar()]?.indices[indexVar()]?.info}
            </Typography>
            {editSample &&
              <EditIndexInformation selectedIndex={selectedIndex} selectedSample={selectedSample} projectId={projectId} updateIndex={updateIndex} formSample={formSample} setFormSample={setFormSample} info={formSample[sampleVar()]?.subSamples[subSampleVar()]?.indices[indexVar()]?.info} />
            }
          </Box>

        </>
      }
    </Box>
  </>;
}

export default SampleState;