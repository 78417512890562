import { gql } from "@apollo/client";

export const ADDPROJECT = gql`
mutation ($projectType: ID!, $leadingDevelopment: ID!, $platforms: [ID!]!, $sopYear: Int!, $sopWeek: Int!, $cellSupplier: ID!, $cellIndex: String, $nominalCapacity: Float!, $cellSpecification: String, , $cellSpecificationLink: String, $users: [UserProjectInput]!){
    createProject(
      input: {
          projectType: $projectType, 
          leadingDevelopment: $leadingDevelopment,
          platforms: $platforms, 
          sopYear: $sopYear, 
          sopWeek: $sopWeek, 
          cellSupplier: $cellSupplier, 
          cellIndex: $cellIndex, 
          nominalCapacity: $nominalCapacity,
          cellSpecification: $cellSpecification,
          cellSpecificationLink: $cellSpecificationLink,
          users: $users,
        }
    ) {
      project {
        projectId
        name
      }
    }
  }
`;

export const UPDATEPROJECT = gql`
mutation ($projectId: ID!, $platforms: [ID!]!, $nominalCapacity: Float!, $cellIndex: String, $description: String, $users: [UserProjectInput]!, $leadingDevelopment: ID!, $sopYear: Int!, $sopWeek: Int!, $cellSpecification: String, $cellSpecificationLink: String){
    updateProject(
      input: {
          projectId: $projectId, 
          platforms: $platforms,
          nominalCapacity: $nominalCapacity,
          leadingDevelopment: $leadingDevelopment,
          sopYear: $sopYear,
          sopWeek: $sopWeek,
          cellSpecification: $cellSpecification,
          cellSpecificationLink: $cellSpecificationLink,
          cellIndex: $cellIndex, 
          description: $description,
          users: $users,
        }
    ) {
      project {
        projectId
        name
      }
    }
  }
`;

export const DELETEPROJECT = gql`
mutation ($projectId: ID!){
    deleteProject(projectId: $projectId)
    {
      id
      name
    }
  }
`;

export const LOCKPROJECT = gql`
mutation ($projectId: ID!, $isLocked: Boolean!){
  updateProjectLockStatus(
    input: {
      projectId: $projectId, 
      isLocked: $isLocked,
    }
) {
  project {
    projectId    
  }
}
}
`;

export const ADDTESTSPECIFICATION = gql`
mutation ($name: String!, $version: Int!, $category: ID!, $procedureSteps: [CreateStepInput!]!, $approval: ID, $groupStandardReference: ID, $description: String, $pretreatmentSteps: [CreateStepInput], $terminationCriteria: String, $testProtocolContents: String, $postCondition: String, $reference: String, $testDuration: Float, $parameters: [AssignParameterInput], $requirementIds: String){
  createTestSpecification(
      input: {
          name: $name,
          version: $version,
          category: $category,
          procedureSteps: $procedureSteps,
          approval: $approval, 
          groupStandardReference: $groupStandardReference,
          description: $description,
          pretreatmentSteps: $pretreatmentSteps,
          terminationCriteria: $terminationCriteria,
          testProtocolContents: $testProtocolContents,
          postCondition: $postCondition,
          reference: $reference,
          testDuration: $testDuration,
          parameters: $parameters,
          requirementIds: $requirementIds,
        }
    ) {
      testSpecification {
        testSpecificationId
        name
        version
      }
    }
  }
`;

export const UPDATETESTSPECIFICATION = gql`
mutation ($testSpecificationId: ID!, $name: String!, $version: Int!, $category: ID!, $procedureSteps: [UpdateStepInput!]!, $tag: TestSpecificationTag!, $approval: ID, $groupStandardReference: ID, $description: String, $pretreatmentSteps: [UpdateStepInput], $terminationCriteria: String, $testProtocolContents: String, $postCondition: String, $reference: String, $testDuration: Float, $parameters: [UpdateAssignedParameterInput], $requirementIds: String){
  updateTestSpecification(
      input: {
          testSpecificationId: $testSpecificationId,
          name: $name,
          version: $version,
          category: $category,
          procedureSteps: $procedureSteps,
          tag: $tag,
          approval: $approval, 
          groupStandardReference: $groupStandardReference,
          description: $description,
          pretreatmentSteps: $pretreatmentSteps,
          terminationCriteria: $terminationCriteria,
          testProtocolContents: $testProtocolContents,
          postCondition: $postCondition,
          reference: $reference,
          testDuration: $testDuration,
          parameters: $parameters,
          requirementIds: $requirementIds,
        }
    ) {
      testSpecification {
        testSpecificationId
        name
        version
      }
    }
  }
`;

export const UPDATETESTSPECIFICATIONNAME = gql`
mutation ($oldName: String!, $newName: String!){
  updateTestSpecificationName(
      input: {
        oldName: $oldName,
        newName: $newName
      }
    ) {
      testSpecifications {
        testSpecificationId,
        name
      }
    }
  }
`;

export const ADDSUBSAMPLE = gql`
mutation ($sampleId: ID!, $name: String!){
  createSubSample(
      input: {
        sampleId: $sampleId, 
        name: $name,          
        }
    ) {
      subSample{
        subSampleId
        }
    }
  }
`;

export const DELETESUBSAMPLE = gql`
mutation ($subSampleId: ID!){
  deleteSubSample(
      input: {
        subSampleId: $subSampleId,      
        }
    ) {
      subSampleId
    }
  }
`;

export const ADDINDEX = gql`
mutation ($subSampleId: ID!, $name: String!, $info: String){
  createIndex(
      input: {
        subSampleId: $subSampleId, 
        name: $name,
        info: $info,      
        }
    ) {
      index{
        indexId
        }
    }
  }
`;

export const UPDATEINDEX = gql`
mutation ($indexId: ID!, $name: String!, $info: String){
  updateIndex(
      input: {
        indexId: $indexId, 
        name: $name,
        info: $info,      
        }
    ) {
      index{
        indexId
        }
    }
  }
`;

export const DELETEINDEX = gql`
mutation ($indexId: ID!){
  deleteIndex(
      input: {
        indexId: $indexId,     
        }
    ) {
      indexId
    }
  }
`;

export const CREATETESTCASES = gql`
mutation ($indexId: ID!, $testSpecifications: [TestCaseSpecificationsInput]!){
  createTestCases(
    input: {
      indexId: $indexId,
      testSpecifications: $testSpecifications,
    }
  ) {
      testCaseIds
    }
  }
`;

export const UPDATETESTCASE = gql`
mutation (
  $testCaseId: ID!, 
  $testResult: ID!, 
  $startDate: DateTime!, 
  $plannedStartDate: DateTime!, 
  $endDate: DateTime!, 
  $plannedEndDate: DateTime!, 
  $testInstance: ID!, 
  $testStatus: ID!, 
  $agingStatus: ID!, 
  $requirementIds: String, 
  $parameters: [UpdateAssignedParameterInput] 
){
  updateTestCase(
      input: {
          testCaseId: $testCaseId,
          testResult: $testResult,
          startDate: $startDate,
          plannedStartDate: $plannedStartDate,
          endDate: $endDate,
          plannedEndDate: $plannedEndDate,
          testInstance: $testInstance,
          testStatus: $testStatus,
          agingStatus: $agingStatus,
          requirementIds: $requirementIds,
          parameters: $parameters,
        }
    ) {
      testCaseId
    }
  }
`;


export const UPDATEDUT = gql`
mutation ($dutId: ID!, $startDate: DateTime, $endDate: DateTime, $testStatus: ID, $testResult: ID ){
  updateDut(
      input: {
          dutId: $dutId,
          startDate: $startDate,
          endDate: $endDate,
          testStatus: $testStatus,
          testResult: $testResult
        }
    ) {
      dutId
    }
  }
`;

export const DELETETESTCASE = gql`
mutation ($testCaseId: ID!){
  deleteTestCase(testCaseId: $testCaseId)
    {
      testCaseId
    }
  }
`;

export const CREATEDUT = gql`
mutation ($testCaseId: ID!){
  createDut(testCaseId: $testCaseId)
    {
      dutId
    }
  }
`;

export const DELETEDUT = gql`
mutation ($dutId: ID!){
  deleteDut(dutId: $dutId)
    {
      dutId
    }
  }
`;

export const UPDATELEADINGDEVELOPMENT = gql`
mutation ($id: ID!, $active: Boolean!, $name: String){
  updateLeadingDevelopment(
    input : {
      leadingDevelopmentId: $id,
      active              : $active,
      name                : $name
    }
  ) {
      leadingDevelopment{
        leadingDevelopmentId
    }
  }
}
`;

export const UPDATETESTINSTANCE = gql`
mutation ($id: ID!, $active: Boolean!, $name: String){
  updateTestInstance(
    input : {
      testInstanceId: $id,
      active        : $active,
      name          : $name
    }
  ) {
      testInstance{
        testInstanceId
        active
        name
      }
    }
}
`;

export const UPDATEPLATFORM = gql`
mutation ($id: ID!, $active: Boolean!, $name: String){
  updatePlatform(
    input : {
      platformId: $id,
      active    : $active,
      name      : $name
    }
  ) {
      platform{
        platformId
        active
        name
      }
    }
}
`;

export const CREATECOMMENT = gql`
mutation ($testCaseId: ID!, $comment: String! ){
  createTestComment(
      input: {
          testCaseId: $testCaseId,
          comment: $comment,
        }
    ) {
      commentId
    }
  }
`;

export const DELETECOMMENT = gql`
mutation ($commentId: ID!){
  deleteTestComment(commentId: $commentId)
  {
      commentId
    }
  }
`;

export const UPDATEUSERAVATAR = gql`
mutation ($avatarId: ID!){
  updateUserAvatar(
    input: {
      avatarId: $avatarId,
    }
  ){
    firstName
    lastName
  }
}
`;

export const ADDCELLSUPPLIER = gql`
mutation ($name: String!, $rating: ID, $ratingDate: DateTime, $supplierPortfolio: [ID!]!, $street: String, $number: String, $postalCode: String, $city: String, $contact: ID ){
  createCellSupplier(
      input: {
        name: $name,
        rating: $rating,
        ratingDate: $ratingDate,
        supplierPortfolio: $supplierPortfolio,
        street: $street,
        number: $number,
        postalCode: $postalCode,
        city: $city,
        contact: $contact,
      }
    ) {
      cellSupplier{
        cellSupplierId
        name
      }
    }
  }
`;

export const UPDATECELLSUPPLIER = gql`
mutation ($cellSupplierId: ID!, $name: String!, $rating: ID, $ratingDate: DateTime, $supplierPortfolio: [ID!]!, $street: String, $number: String, $postalCode: String, $city: String, $contact: ID, $active: Boolean!){
  updateCellSupplier(
      input: {
        cellSupplierId: $cellSupplierId,
        name: $name,
        active: $active,
        rating: $rating,
        ratingDate: $ratingDate,
        supplierPortfolio: $supplierPortfolio,
        street: $street,
        number: $number,
        postalCode: $postalCode,
        city: $city,
        contact: $contact,
      }
    ) {
      cellSupplier{
        cellSupplierId
        name
        ratingDate
      }
    }
  }
`;

export const UPDATECATEGORY = gql`
mutation ($id: ID!, $active: Boolean!, $name: String){
  updateCategory(
    input : {
      categoryId: $id,
      active    : $active,
      name      : $name
    }
  ) {
      category{
        categoryId
        active
        name
      }
    }
}
`;

export const UPDATEGROUPSTANDARDREFERENCE = gql`
mutation ($id: ID!, $active: Boolean!, $name: String){
  updateGroupStandardReference(
    input : {
      groupStandardReferenceId: $id,
      active                  : $active,
      name                    : $name
    }
  ) {
      groupStandardReference{
        groupStandardReferenceId
        active
        name
      }
    }
}
`;

export const UPDATEPROJECTROLE = gql`
mutation ($id: ID!, $active: Boolean!, $name: String){
  updateProjectRole(
    input : {
      roleId: $id,
      active: $active,
      name  : $name
    }
  ) {
      role{
        roleId
        active
        name
      }
    }
}
`;

export const UPDATEAPPROLE = gql`
mutation ($userId: ID!, $roleId: ID!, $role: Role!){
  updateRole(
    input : {
      userId: $userId,
      roleId: $roleId,
      role  : $role
    }
  ) {
      role{
        roleId
        role
      }
    }
}
`;

export const UPDATEUSERACTIVE = gql`
mutation ($userId: ID!, $active: Boolean!){
  updateUserActiveState(
    input : {
      userId: $userId,
      active: $active,
    }
  ) {
      firstName
      lastName
    }
}
`;

export const ADDPROJECTTYPE = gql`
mutation ($name: String!){
  createProjectType(
    input : {
      name: $name
    }
  ) {
    projectType{
      projectTypeId
      }
    }
}
`;

export const ADDPLATFORM = gql`
mutation ($name: String!){
  createPlatform(
    input : {
      name: $name
    }
  ) {
    platform{
      platformId
      }
    }
}
`;

export const ADDTESTINSTANCE = gql`
mutation ($name: String!){
  createTestInstance(
    input : {
      name: $name
    }
  ) {
    testInstance{
      testInstanceId
      }
    }
}
`;

export const ADDLEADINGDEVELOPMENT = gql`
mutation ($name: String!){
  createLeadingDevelopment(
    input : {
      name: $name
    }
  ) {
    leadingDevelopment{
      leadingDevelopmentId
      }
    }
}
`;

export const ADDCATEGORY = gql`
mutation ($name: String!){
  createCategory(
    input : {
      name: $name
    }
  ) {
    category{
      categoryId
      }
    }
}
`;

export const ADDPROJECTROLE = gql`
mutation ($name: String!){
  createProjectRole(
    input : {
      name: $name
    }
  ) {
    role{
      roleId
      }
    }
}
`;

export const ADDGROUPSTANDARDREFERENCE = gql`
mutation ($name: String!){
  createGroupStandardReference(
    input : {
      name: $name
    }
  ) {
    groupStandardReference{
      groupStandardReferenceId
      }
    }
}
`;

export const ADDAGINGSTATUS = gql`
mutation ($name: String!, $description: String){
  createAgingStatus(
    input : {
      name: $name,
      description: $description
    }
  ) {
    agingStatus{
      agingStatusId
      }
    }
}
`;

export const ADDAPPROVAL = gql`
mutation ($name: String!){
  createApproval(
    input : {
      name: $name
    }
  ) {
    approval{
      approvalId
      }
    }
}
`;

export const ADDPHYSICALQUANTITY = gql`
mutation ($name: String!){
  createPhysicalQuantity(
    input : {
      name: $name
    }
  ) {
    physicalQuantity{
      physicalQuantityId
      }
    }
}
`;

export const ADDUNIT = gql`
mutation ($name: String!, $abbreviation: String!){
  createUnit(
    input : {
      name: $name,
      abbreviation: $abbreviation
    }
  ) {
    unit{
      unitId
      }
    }
}
`;

export const ADDRATING = gql`
mutation ($name: String!){
  createRating(
    input : {
      name: $name,
    }
  ) {
    rating{
      ratingId
      }
    }
}
`;

export const ADDSUPPLIERPORTFOLIO = gql`
mutation ($name: String!){
  createSupplierPortfolio(
    input : {
      name: $name,
    }
  ) {
    supplierPortfolio{
      supplierPortfolioId
      }
    }
}
`;

export const ADDPARAMETER = gql`
mutation ($name: String!, $physicalQuantity: ID!, $description: String!, $unit: ID){
  createParameter(
    input : {
      name: $name,
      physicalQuantity: $physicalQuantity,
      description: $description,
      unit: $unit
    }
  ) {
    parameter{
      parameterId
      }
    }
}
`;

export const UPDATEAPPROVAL = gql`
mutation ($id: ID!, $active: Boolean!, $name: String!){
  updateApproval(
    input : {
      approvalId: $id,
      active: $active,
      name: $name
    }
  ) {
    approval{
      approvalId
      }
    }
}
`;

export const UPDATEPROJECTTYPE = gql`
mutation ($id: ID!, $active: Boolean!, $name: String!){
  updateProjectType(
    input : {
      projectTypeId: $id,
      active: $active,
      name: $name
    }
  ) {
    projectType{
      projectTypeId
      }
    }
}
`;

export const UPDATEPHYSICALQUANTITY = gql`
mutation ($id: ID!, $active: Boolean!, $name: String!){
  updatePhysicalQuantity(
    input : {
      physicalQuantityId: $id,
      active: $active,
      name: $name
    }
  ) {
    physicalQuantity{
      physicalQuantityId
      }
    }
}
`;

export const UPDATESUPPLIERPORTFOLIO = gql`
mutation ($id: ID!, $name: String!, $active: Boolean!){
  updateSupplierPortfolio(
    input : {
      supplierPortfolioId: $id,
      name: $name,
      active: $active,
    }
  ) {
    supplierPortfolio{
      supplierPortfolioId
      }
    }
}
`;

export const UPDATEUNIT = gql`
mutation ($id: ID!, $name: String!, $abbreviation: String!, $active: Boolean!){
  updateUnit(
    input : {
      unitId: $id,
      name: $name,
      abbreviation: $abbreviation,
      active: $active,
    }
  ) {
    unit{
      unitId
      }
    }
}
`;

export const UPDATEAGINGSTATUS = gql`
mutation ($id: ID!, $name: String!, $description: String!, $active: Boolean!){
  updateAgingStatus(
    input : {
      agingStatusId: $id,
      name: $name,
      description: $description,
      active: $active,
    }
  ) {
    agingStatus{
      agingStatusId
      }
    }
}
`;

export const UPDATERATING = gql`
mutation ($id: ID!, $name: String!, $active: Boolean!){
  updateRating(
    input : {
      ratingId: $id,
      name: $name,
      active: $active,
    }
  ) {
    rating{
      ratingId
      }
    }
}
`;

export const UPDATEPARAMETER = gql`
mutation ($id: ID!, $name: String!, $physicalQuantity: ID!, $description: String!, $unit: ID, $active: Boolean!){
  updateParameter(
    input : {
      parameterId: $id,
      name: $name,
      physicalQuantity: $physicalQuantity,
      description: $description,
      unit: $unit,
      active: $active,
    }
  ) {
    parameter{
      parameterId
      }
    }
}
`;

export const UPLOADTESTSPECIFICATIONDOCUMENT = gql`
mutation ($testSpecificationId: ID!, $classification: ID!, $name: String!, $version: Int!, $file: Upload!){
uploadTestSpecificationDocument(
  input: {
    testSpecificationId: $testSpecificationId, 
    classification: $classification,
    name: $name, 
    version: $version,
  },
  file: $file,
  ){
    documentId
  }
}
`;

export const UPLOADTESTCASEDOCUMENT = gql`
mutation ($testCaseId: ID!, $classification: ID!, $name: String!, $version: Int!, $file: Upload!){
  uploadTestCaseDocument(
  input: {
    testCaseId: $testCaseId, 
    classification: $classification,
    name: $name, 
    version: $version,
  },
  file: $file,
  ){
    documentId
  }
}
`;

export const UPLOADPROJECTDOCUMENT = gql`
mutation ($projectId: ID!, $classification: ID!, $name: String!, $version: Int!, $file: Upload!){
  uploadProjectDocument(
  input: {
    projectId: $projectId, 
    classification: $classification,
    name: $name, 
    version: $version,
  },
  file: $file,
  ){
    documentId
  }
}
`;

export const UPLOADSUBSAMPLEDOCUMENT = gql`
mutation ($subSampleId: ID!, $classification: ID!, $name: String!, $version: Int!, $file: Upload!){
  uploadSubSampleDocument(
  input: {
    subSampleId: $subSampleId, 
    classification: $classification,
    name: $name, 
    version: $version,
  },
  file: $file,
  ){
    documentId
  }
}
`;

export const DELETEDOCUMENT = gql`
mutation ($documentId: ID!){
  deleteDocument(documentId: $documentId)
  {
    documentId
  }
}
`;

export const LOCKCDS = gql`
mutation ($subSampleId: ID!, $isLocked: Boolean!){
  updateCellDataSheetLockStatus(
      input: {
        subSampleId: $subSampleId, 
        isLocked: $isLocked,    
        }
    ) {
      subSampleId
    }
  }
`;

export const CREATEPULSETIME = gql`
mutation ($subSampleId: ID!, $pulseTime: Int!){
  createPulseTime(
    input : {
      subSampleId: $subSampleId,
      pulseTime: $pulseTime
    }
  ) {
    cellDataIds
    }
}
`;

export const CREATETEMPERATURE = gql`
mutation ($subSampleId: ID!, $cellDataType: CellDataType!, $temperature: Int!){
  createTemperature(
    input : {
      subSampleId: $subSampleId,
      cellDataType: $cellDataType,
      temperature: $temperature
    }
  ) {
    cellDataIds
    }
}
`;

export const CREATESOC = gql`
mutation ($subSampleId: ID!, $cellDataType: CellDataType!, $soc: Int!){
  createSoc(
    input : {
      subSampleId: $subSampleId,
      cellDataType: $cellDataType,
      soc: $soc
    }
  ) {
    cellDataIds
    }
}
`;

export const DELETEPULSETIME = gql`
mutation ($subSampleId: ID!, $pulseTime: Int!){
  deletePulseTime(
    input : {
      subSampleId: $subSampleId,
      pulseTime: $pulseTime
    }
  ) {
    subSampleId
    }
}
`;

export const DELETETEMPERATURE = gql`
mutation ($subSampleId: ID!, $cellDataType: CellDataType!, $temperature: Int!){
  deleteTemperature(
    input : {
      subSampleId: $subSampleId,
      cellDataType: $cellDataType,
      temperature: $temperature
    }
  ) {
    subSampleId
    }
}
`;

export const DELETESOC = gql`
mutation ($subSampleId: ID!, $cellDataType: CellDataType!, $soc: Int!){
  deleteSoc(
    input : {
      subSampleId: $subSampleId,
      cellDataType: $cellDataType,
      soc: $soc
    }
  ) {
    subSampleId
    }
}
`;

export const UPDATECDS = gql`
mutation ($cellDataType: CellDataType!, $cellDataEntries: [UpdateCellDataEntryInput!]! ){
  updateCellData(
    input : {
      cellDataType: $cellDataType,
      cellDataEntries: $cellDataEntries,
      }
  ) {
    cellDataIds
    }
}
`;