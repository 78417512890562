import React from 'react';
import { Typography } from '@mui/material';

import { styles, Link } from './PrivacyPolicyComponents';

function EmployeesVWPartners() {

    const classes = styles();

    return (<>
        <Typography variant="h2">
            Datenschutzerklärung für Beschäftigte der Volkswagen Partner (Markt Deutschland)
        </Typography>

        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Im Rahmen der Geschäftsbeziehung zwischen Volkswagen Partnern und der Volkswagen AG kommt es zu einer Reihe
            von Prozessen, in denen von Ihnen als Mitarbeiter eines Volkswagen Partners Informationen durch
            die Volkswagen AG verarbeitet werden. Über die diesbezüglichen Datenverarbeitungen durch die Volkswagen AG
            informieren wir Sie in den nachfolgenden Abschnitten.
        </Typography>

        {/* SECTION A */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            A. Verantwortlicher
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Mit dieser Datenschutzerklärung informieren wir Sie über die Erhebung, Verarbeitung und Nutzung Ihrer
            personen- bezogenen Daten durch die Volkswagen AG, Berliner Ring 2,
            38440 Wolfsburg <a href="mailto:kundenbetreuung@volkswagen.de">kundenbetreuung@volkswagen.de</a>,
            eingetragen im Handelsregister des Amtsgerichts Braunschweig unter der Nr. HRB 100484 („Volkswagen AG“)
            im Rahmen der Geschäftsbeziehung zwischen Volkswagen Partnern und der Volkswagen AG.
        </Typography>

        {/* SECTION B */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            B. Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten
        </Typography>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            I. Allgemeines
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Wir verarbeiten personenbezogene Daten, die wir im Rahmen der Ausübung Ihrer Tätigkeit
            bei einem Volkswagen Partner von Ihnen zulässigerweise erhalten oder über Sie in zulässiger Weise erfassen.
            Hierzu zählen insbesondere die Nutzung von IT-Systemen (u.a. Webseiten, Plattformen) und
            Kommunikationsmitteln (Anmeldung für Newsletter), Teilnahme an Schulungs- und Qualifizierungsmaßnahmen,
            Beteiligung an Aktionen, die von der Volkswagen AG angeboten werden oder die Teilnahme an Veranstaltungen.
        </Typography>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            II. Personenbezug
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Um Sie höchstmöglich bei der Ausübung Ihrer Tätigkeit bei unserem Volkswagen Partner unterstützen und
            gewisse Leistungen erbringen zu können, ist es erforderlich, Ihre personenbezogenen Daten zu verarbeiten.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Je nach Zweck der Verarbeitungstätigkeit werden für gewöhnlich Ihre folgenden, personenbezogene Daten
            erhoben, verarbeitet und genutzt:
        </Typography>
        <ul className={classes.list}>
            <li>Anrede</li>
            <li>Vorname </li>
            <li>Nachname </li>
            <li>Funktion  </li>
            <li>Dienstliche Kommunikationsdaten (dienstliche Anschrift, Telefonnummer und E-Mail-Adresse) </li>
            <li>Schulungs- und Qualifizierungsdaten </li>
            <li>Ggf. leistungsbezogene Informationen (z. B. Verkaufszahlen)  </li>
            <li>IT- Nutzungsdaten (z.B. User-ID, Systemberechtigungen)</li>
        </ul>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            III. Datenverarbeitung durch die Volkswagen AG
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Im Nachfolgenden finden Sie die konkreten Verarbeitungszwecke und die personenbezogenen Daten die
            die Volkswagen AG verarbeitet sowie die Rechtsgrundlage und die Speicherdauer der Daten.
        </Typography>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            1. Erfüllung gesetzlicher Anforderungen
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Um gesetzlichen Anforderungen nachzukommen, verarbeitet die Volkswagen AG Ihren Namen, Ihre berufliche
            E-Mail- Adresse sowie bei Bedarf weitere personenbezogene Daten.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Volkswagen AG unterliegt diversen gesetzlichen Anforderungen, denen sie nachkommen muss.
            Diese können sich z. B. aus zoll-, steuer- und zulassungsrechtlichen Vorgaben ergeben.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Datenverarbeitung ist zur Erfüllung rechtlicher Verpflichtungen (vgl. Art. 6 Abs. 1 lit. c DSGVO) notwendig.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Speicherdauer richtet sich nach dem jeweiligen, durch die gesetzlichen Bestimmungen vorgegebenen, Verarbeitungszweck.
        </Typography>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            2. Dienstliche Kommunikation
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Im Rahmen der dienstlichen Korrespondenz verarbeitet die Volkswagen AG betriebliche Kommunikationsdaten
            wie z. B. Ihren Vornamen, Ihren Namen, Ihre Funktion, Ihre Telefonnummer und Ihre E-Mail-Adresse.
            Dies dient der Abwicklung Ihrer dienstlichen Anliegen und Anfragen und/oder der Erledigung
            dienstlicher Tätigkeiten durch Beschäftigte der Volkswagen AG.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Diese Datenverarbeitung unterstützt die Aufrechterhaltung des ordnungsgemäßen Geschäftsbetriebs und dient
            der Sicherung von Arbeitsabläufen zwischen Ihnen und den Beschäftigten der Volkswagen AG.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Volkswagen AG stützt sich dabei auf ein überwiegendes berechtigtes Interesse (vgl. Art. 6 Abs. 1 lit. f DSGVO).
            Das berechtigte Interesse besteht darin, die reibungslose Zusammenarbeit zu gewährleisten sowie die Prozesse
            innerhalb der Geschäftsbeziehung praktikabel zu gestalten.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die im Rahmen der dienstlichen Kommunikation verarbeiteten, personenbezogenen Daten werden
            nach Zweckerreichung spätestens nach 15 Jahren aus steuerrechtlichen Gründen gelöscht.
        </Typography>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            3. Nutzung der IT-Infrastruktur
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Im Zuge der Ausübung Ihrer Tätigkeiten bei Ihrem Volkswagen Partner nutzen Sie für gewöhnlich
            eine IT-Infrastruktur, die Ihnen von der Volkswagen AG bereitgestellt wird. Hierbei fällt insbesondere
            das Verarbeiten von IT-Nutzungsdaten wie User-ID, Passwort und Nutzungsdauer an. Ferner speichert
            die Volkswagen AG die Ihrerseits hinterlegten, personenbezogenen Profildaten.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Verarbeitung Ihrer o. g. personenbezogenen Daten dient ausschließlich Ihrer Nutzung
            der entsprechenden Applikation. Weiterführende Datenschutzerklärungen bezüglich der Nutzung
            der IT-Infrastruktur der Volkswagen AG sind der jeweiligen Applikation zu entnehmen.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Volkswagen AG stützt sich bei o. g. Datenverarbeitung auf ein überwiegendes berechtigtes Interesse
            (vgl. Art. 6 Abs. 1 lit. f DSGVO). Das berechtigte Interesse besteht darin, die Sicherheit und Integrität
            der Nutzung unserer Systeme zu gewährleisten.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Speicherdauer o. g. Daten richtet sich nach der Dauer Ihrer Nutzung der jeweiligen Applikation.
            Im Fall von gesetzlichen oder steuerrechtlichen Aufbewahrungsgründen, erfolgt eine Löschung Ihrer Daten
            nach 15 Jahren.
        </Typography>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            4. Schulung und Qualifizierung
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Im Zuge der Mitarbeiterqualifizierung (z.B. MyLearn, Information zu bevorstehenden Qualifizierungen,
            Versenden von Einladungen, Überprüfung erforderlicher Schulungen) verarbeitet die Volkswagen AG
            betriebliche Daten wie z.B. Ihren Namen, Ihre berufliche E-Mail-Adresse, Ihre berufliche Telefonnummer,
            Ihre Funktion, den Namen und die Adresse Ihres Betriebes und die Betriebsnummer.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Volkswagen AG stützt sich bei o. g. Datenverarbeitung auf ein überwiegendes berechtigtes Interesse
            (vgl. Art. 6 Abs. 1 lit. f DSGVO). Das berechtigte Interesse besteht darin, Mitarbeiter im Handel zu
            qualifizieren und die Entwicklung verschiedener Geschäftsfelder voranzutreiben.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Für Qualifizierungen verarbeitete Daten werden in der Regel bis zu 2 Jahren aufbewahrt und anschließend
            gelöscht. Ihre für den Systemzugang benötigten Daten werden zwischen 60 und 127 Jahre aufbewahrt.
            Dies ist dem geschuldet, dass der Mitarbeiter einen Useraccount bis zum Austritt aus dem Betrieb benötigt.
        </Typography>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            5. Veranstaltungs- und Einladungsmanagement
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Um Sie im Falle eines Events (z.B. Fußball Loge Wolfsburg, Jahresauftaktveranstaltung, Fahrzeugkongresse)
            einladen zu können, verarbeitet die Volkswagen AG Daten wie z.B. Ihren Namen, Ihre berufliche E-Mail-Adresse,
            Ihre berufliche Telefonnummer, Ihre Funktion, den Namen und die Adresse Ihres Betriebes, die Betriebsnummer
            sowie Ihre privaten Kontakt- und Identifikationsdaten.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Volkswagen AG stützt sich dabei auf ein überwiegendes berechtigtes Interesse (vgl. Art. 6 Abs. 1 lit. f DSGVO).
            Dieses liegt in der Erhaltung einer guten Geschäftsbeziehung zwischen der VW AG und dem Volkswagen Händler.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Zur Abwicklung der Einladung eines Events verarbeiteten Daten werden in der Regel bis zu zwei Jahre
            aufbewahrt. Ausgenommen ist die Löschung von Verwaltungs-, Rechts- und Geschäftsunterlagen, welche nach
            einer Aufbewahrungsfrist von 5 Jahren gelöscht werden sowie steuerlich relevante Daten, die nach
            einer Aufbewahrungsfrist von 15 Jahren gelöscht werden.
        </Typography>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            6. Zahlungsstörungen und Insolvenzen
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Im Falle von Zahlungsstörungen oder Insolvenzen verarbeitet die Volkswagen AG betriebliche Daten wie
            den Firmennamen, die Anschrift, die Betriebsnummer.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Volkswagen AG stützt sich bei o. g. Datenverarbeitung auf ein überwiegendes berechtigtes Interesse
            (vgl. Art. 6 Abs. 1 lit. f DSGVO). Das berechtigte Interesse besteht darin, im Falle einer Insolvenz
            die nötigen Zahlungsströme und Prozesse zu verwalten.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die im Rahmen von Zahlungsstörungen und Insolvenzen erhobenen Daten werden in der Regel aus
            steuerlich- rechtlichen Gründen 15 Jahre aufbewahrt und anschließend gelöscht.
        </Typography>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            7. Risikomanagement
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Im Zuge des Risikomanagements (z.B. Kontrolle des Zahlungsstandes / Liquidität, offene Posten Liste)
            verarbeitet die Volkswagen AG betriebliche Daten wie z.B. den Firmennamen, die Anschrift,
            die Betriebsnummer sowie Rechnungsnummern.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Volkswagen AG stützt sich bei o. g. Datenverarbeitung auf ein überwiegendes berechtigtes Interesse
            (vgl. Art. 6 Abs. 1 lit. f DSGVO). Das berechtigte Interesse besteht darin, das Risiko der Volkswagen AG
            bzgl. ausstehender Zahlungen des Handelspartners zu minimieren.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die im Rahmen des Risikomanagements erhobenen Daten werden in der Regel aus steuerlich-rechtlichen
            Gründen bis zu 15 Jahre aufbewahrt und anschließend gelöscht.
        </Typography>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            8. Fahrzeugankauf
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Im Rahmen des Ankaufs von Fahrzeugen (z.B. GWS, Jahreswagen, GW-Storno) verarbeitet die Volkswagen AG
            dienstliche Daten wie z.B. Ihren Namen, Ihre berufliche E-Mail-Adresse, Ihre berufliche Telefonnummer,
            den Namen und die Adresse Ihres Betriebes.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Volkswagen AG stützt sich bei o. g. Datenverarbeitung auf ein überwiegendes berechtigtes Interesse
            (vgl. Art. 6 Abs. 1 lit. f DSGVO). Das berechtigte Interesse besteht darin, reibungslose Arbeitsabläufe
            mit den Vertragspartnern zu gewährleisten sowie die geschäftlichen Beziehungen aufrecht zu erhalten und
            auszubauen.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Ihre verarbeiteten Daten werden in der Regel bis zu 7 Jahre aufbewahrt und anschließend gelöscht.
            Im Fall von gesetzlichen oder steuerrechtlichen Aufbewahrungsgründen, erfolgt eine Löschung Ihrer Daten
            nach 15 Jahren.
        </Typography>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            9. Kulanz
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Im Zuge der Erstellung von Kulanzanträgen (z.B. Gebrauchtwagen Kulanzanträge, Kulanzen für Verkaufshilfen
            oder Werbekostenzuschüsse) verarbeitet die Volkswagen AG Daten wie z.B. Ihren Namen, Ihre berufliche
            E-Mail-Adresse, Ihre berufliche Telefonnummer, Ihre Betriebsnummer, den Namen und die Adresse Ihres Betriebes.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Volkswagen AG stützt sich bei o. g. Datenverarbeitung auf ein überwiegendes berechtigtes Interesse
            (vgl. Art. 6 Abs. 1 lit. f DSGVO). Das berechtigte Interesse besteht darin, die Kundenzufriedenheit
            (Handel) sicherzustellen, indem sich die Volkswagen AG der Kulanzen annimmt und diese innerhalb kurzer Zeit
            beantwortet.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Ihre zur Abwicklung von Kulanzanträgen verarbeiteten Daten werden in der Regel nach 300 Tagen gelöscht.
        </Typography>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            10. Bereitstellung Leihfahrzeuge zu Testfahrten
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Um Leihfahrzeuge im Rahmen des Direktvertriebs der Volkswagen AG zu Testzwecken zur Verfügung stellen zu
            können, verarbeitet die Volkswagen AG betriebliche Daten wie z.B. Ihren Namen, Ihre berufliche E-Mail-Adresse,
            Ihre berufliche Telefonnummer, Ihre Funktion, den Namen und die Adresse Ihres Betriebes.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Volkswagen AG stützt sich bei o. g. Datenverarbeitung auf ein überwiegendes berechtigtes Interesse
            (vgl. Art. 6 Abs. 1 lit. f DSGVO). Das berechtigte Interesse besteht in der Absicherung und/oder
            Steigerung des verkauften Fahrzeugvolumens.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die im Rahmen der Bereitstellung von Leihfahrzeugen für Kunden erhobenen Daten werden in der Regel nach
            15 Jahren gelöscht. Eine Aufbewahrungsfrist von 15 Jahren ist aus steuerrechtlichen Gründen notwendig.
        </Typography>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            11. Leadmanagement
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Im Rahmen des Leadmanagement-Prozesses (z.B. Interessentenmanager pflegen) verarbeitet die Volkswagen AG
            betriebliche Daten wie z.B. Ihren Namen, Ihre berufliche E-Mail-Adresse, Ihre berufliche Telefonnummer,
            den Namen und die Adresse Ihres Betriebes, die Betriebsnummer.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Volkswagen AG stützt sich bei o. g. Datenverarbeitung auf ein überwiegendes berechtigtes Interesse
            (vgl. Art. 6 Abs. 1 lit. f DSGVO). Das berechtigte Interesse besteht in der Absatzförderung des Händlers.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die von Ihnen verarbeiteten Daten im Zuge des Leadmanagementprozesses werden in der Regel 15 Jahre
            nach Bearbeitung des Vorgangs gelöscht.
        </Typography>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            12. Verkaufsprozess
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Im Zuge des Fahrzeug Verkaufsprozesses (z.B. Kundenanfragen, Auftragsbestätigungen, Rechnungsstellung,
            Auslieferung von Fahrzeugen, Radsatzanlieferung, Rücktritt vom Kaufvertrag) verarbeitet die Volkswagen AG
            betriebliche Daten wie z.B. Ihren Namen, Ihre berufliche E-Mail-Adresse, Ihre berufliche Telefonnummer,
            den Namen und die Adresse Ihres Betriebes und die Betriebsnummer.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Volkswagen AG stützt sich bei o. g. Datenverarbeitung auf ein überwiegendes berechtigtes Interesse
            (vgl. Art. 6 Abs. 1 lit. f DSGVO). Das berechtigte Interesse besteht darin, die Zusammenarbeit mit
            dem Handel zu erleichtern und die Kommunikation mit dem Kunden zu gewährleisten.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Ihre im Verkaufsprozess verarbeiteten Daten werden in der Regel bis zu 4 Jahre aufbewahrt und anschließend
            gelöscht. Im Fall von gesetzlichen oder steuerrechtlichen Aufbewahrungsgründen, erfolgt eine Löschung
            Ihrer Daten nach 15 Jahren.
        </Typography>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            13. Rechnungsstellung
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Im Rahmen des Rechnungserstellungsprozesses (z.B. Fahrzeugkorrekturen im Rahmen der Rechnungsstellung,
            Anfrage Fahrzeugrechnung, Sichtung Rechnungsbetrag, Versand des Überweisungsträgers) verarbeitet
            die Volkswagen AG betriebliche Daten wie z.B. Ihren Namen, Ihre berufliche E-Mail-Adresse, Ihre
            berufliche Telefonnummer, Ihre Funktion, den Namen und die Adresse Ihres Betriebes.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Volkswagen AG stützt sich bei o. g. Datenverarbeitung auf ein überwiegendes berechtigtes Interesse
            (vgl. Art. 6 Abs. 1 lit. f DSGVO). Das berechtigte Interesse besteht darin, die Kommunikation und
            Zusammenarbeit mit dem Handel zu erleichtern sowie die korrekte Rechnungsstellung an den Kunden zu
            gewährleisten.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die im Rechnungserstellungsprozess verarbeiteten Daten werden in der Regel bis zu 4 Jahre aufbewahrt
            und anschließend gelöscht. Im Fall von gesetzlichen oder steuerrechtlichen Aufbewahrungsgründen,
            erfolgt eine Löschung Ihrer Daten nach 15 Jahren.
        </Typography>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            14. Performance Evaluation
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Im Rahmen der Performance Evaluation (z.B. Monitoring, Potenzialermittlung, Readiness Check / Sondertest,
            Werkstatttest, FACT, Jahresabschlussanalyse, CEMD Kundenbefragung) verarbeitet die Volkswagen AG
            betriebliche Daten wie z.B. Ihren Namen, Ihre berufliche E-Mail-Adresse, Ihre berufliche Telefonnummer,
            Ihre Funktion, den Namen und die Adresse Ihres Betriebes sowie Ihre Betriebsnummer.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Volkswagen AG stützt sich bei o. g. Datenverarbeitung auf ein überwiegendes berechtigtes Interesse
            (vgl. Art. 6 Abs. 1 lit. f DSGVO). Das berechtigte Interesse besteht darin, einen Überblick über
            die wirtschaftliche Gesamtsituation des Händlernetzes zu erlangen, Potentiale zu erkennen und
            die Performance zu steigern.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die durch die Performance Evaluation verarbeiteten Daten werden in der Regel bis zu 4 Jahre aufbewahrt
            und anschließend gelöscht.
        </Typography>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            15. Aktionen
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Um an Verkaufsförderprogrammen teilnehmen zu können (z.B. Incentive Programm &quot;motiVWation&quot;) verarbeitet
            die Volkswagen AG Daten wie z.B. Ihren Namen, Ihre berufliche E-Mail-Adresse, Ihre berufliche Telefonnummer,
            Ihre Funktion, den Namen und die Adresse Ihres Betriebes, die Betriebsnummer sowie ggf. Ihre privaten
            Kontakt- und Identifikationsdaten.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Volkswagen AG stützt sich dabei auf ein überwiegendes berechtigtes Interesse
            (vgl. Art. 6 Abs. 1 lit. f DSGVO). Das berechtigte Interesse besteht darin, eine Motivation
            zur Absatzförderung zu schaffen und dementsprechend Verkäufe gezielt steuern zu können.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die zur Teilnahme an der jeweiligen Aktion von Ihnen verarbeiteten Daten werden in der Regel nach
            4 Jahren gelöscht. Im Fall von gesetzlichen oder steuerrechtlichen Aufbewahrungsgründen, erfolgt
            eine Löschung Ihrer Daten nach 15 Jahren.
        </Typography>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            16. Prämienbeantragung und Vergütung
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Im Zuge der Prämienbeantragung und Vergütung (z.B. Individuelle Förderungen, Sonderstützungen, Aktionen,
            Großkundenbonus) verarbeitet die Volkswagen AG betriebliche Daten wie z.B. Ihren Namen, Ihre berufliche
            E-Mail- Adresse, Ihre berufliche Telefonnummer, Ihre Funktion, die FIN und Kommissionsnummer, den Namen
            und die Adresse Ihres Betriebes sowie Ihre Betriebs- oder Bezirksnummer.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Volkswagen AG stützt sich bei o. g. Datenverarbeitung auf ein überwiegendes berechtigtes Interesse
            (vgl. Art. 6 Abs. 1 lit. f DSGVO). Das berechtigte Interesse besteht darin, Anreize für den Vertrieb von
            Fahrzeugen zu schaffen und die Absatzzahlen zu erhöhen.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die durch die Prämienbeantragung und Vergütung verarbeiteten Daten werden in der Regel bis zu 4 Jahre
            aufbewahrt und anschließend gelöscht. Im Fall von gesetzlichen oder steuerrechtlichen Aufbewahrungsgründen,
            erfolgt eine Löschung Ihrer Daten nach 15 Jahren.
        </Typography>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            17. Auditierung und Außendienstprüfung
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Im Rahmen von Außendienstprüfungen (z.B. Auditierung, Erstellung von Berichten/Auswertungen geprüfter
            Unterlagen/Systeme/Konten, Archivierung der Berichte) verarbeitet die Volkswagen AG betriebliche Daten wie
            z.B. den Namen und die Adresse Ihres Betriebes, Ihre Telefonnummer, Ihre Betriebsnummer, Ihre Vertragsnummer.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Berechtigung der Volkswagen AG Prüfungen im Handel durchzuführen, resultiert aus dem Händler- bzw.
            Servicepartnervertrag. Demnach sind die Prüfbeauftragten berechtigt, in Unterlagen, Systeme, Konten und
            Belege Einsicht zu nehmen. Geprüft wird, ob finanzielle Zuwendungen sachgerecht verwendet werden (Aktionen,
            Zusatzleistungen etc.).
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Volkswagen AG verarbeitet die Daten, welche zur Erstellung von Endberichten bei Außendienstprüfungen
            erhoben wurden, zur Erfüllung Ihres Vertrags (vgl. Art. 6 Abs. 1 lit. b DSGVO).
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die von Ihnen verarbeiteten Daten zur Erstellung von Berichten und Auswertungen bei Außendienstprüfungen
            werden in der Regel aus steuerrechtlichen Gründen 15 Jahre aufbewahrt und anschließend gelöscht.
        </Typography>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            18. Geokodierung
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Im Rahmen der Geokodierung (kartografische Abbildung der Vertragspartnerstandorte) verarbeitet
            die Volkswagen AG Händler Stammdaten wie z.B. Ihren Firmennamen, Ihre betriebliche Telefon- und Faxnummer,
            Ihre Betriebsnummer, Ihre Adresse, die Geokoordinaten Ihres betrieblichen Standortes.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Volkswagen AG stützt sich bei o. g. Datenverarbeitung auf ein überwiegendes berechtigtes Interesse
            (vgl. Art. 6 Abs. 1 lit. f DSGVO). Das berechtigte Interesse besteht darin, netzplanerische Analysen
            vorzunehmen.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die im Rahmen der Geokodierung erhobenen Daten werden in der Regel bis zu 15 Jahre aufbewahrt und
            anschließend gelöscht. Die Aufbewahrungsdauer ist notwendig, um den erforderlichen Nachweis in Bezug auf
            eine bestehende bzw. ursprüngliche Anzeige im weiterführenden System zu gewährleisten.
        </Typography>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            19. Vertragsvergabe
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Der Prozess Vertragsvergabe beschreibt die Vergabe des Händler- und/oder Servicepartnervertrages
            der Volkswagen Aktiengesellschaft ab der Bewerbung. Im Rahmen der Vertragsvergabe verarbeitet
            die Volkswagen AG Händler-Stammdaten (z.B. Ihren Firmennamen, Ihre betriebliche Telefon- und Faxnummer,
            Ihre Adresse) sowie berufliche Kontakt- und Organisationsdaten (z.B. Name, E-Mail, Adresse, Geburtsdatum)
            des Inhabers, operativen Geschäftsführers, Gesellschafters, Kommanditist, Komplementärs, Vorstands,
            Aktionärs oder Aufsichtsrats.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Volkswagen AG stützt sich bei o. g. Datenverarbeitung auf ein überwiegendes berechtigtes Interesse
            (vgl. Art. 6 Abs. 1 lit. f DSGVO). Das berechtigte Interesse besteht darin, die Einhaltung der qualitativen
            Standards zwischen den jeweils beteiligten Vertragsparteien zu gewährleisten sowie ein erfolgreiches
            Vertriebs- und Servicenetz zu schaffen bzw. zu erhalten.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Ihre im Rahmen der Vertragsvergabe erhobenen Daten werden in der Regel bis zu 15 Jahre ab Ende der
            Geschäftsbeziehung aufbewahrt und anschließend gelöscht. Die Aufbewahrungsdauer ist notwendig, da
            die Daten auch nach Beendigung des Vertrages für Rechtsverfahren und zur weiteren Abwicklung zur Verfügung
            stehen müssen.
        </Typography>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            20. Assessment Center
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Im Rahmen der Durchführung von Assessment Center (z.B. AC für neuen Geschäftsführer) verarbeitet
            die Volkswagen AG betriebliche Daten wie z.B. Ihren Namen, Ihre berufliche E-Mail-Adresse, Ihre berufliche
            Telefonnummer und den Namen und die Adresse Ihres Betriebes.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Volkswagen AG stützt sich bei o. g. Datenverarbeitung auf ein überwiegendes berechtigtes Interesse
            (vgl. Art. 6 Abs. 1 lit. f DSGVO). Das berechtigte Interesse besteht darin, den Volkswagen Partner bei
            der Auswahl des besten Kandidaten für die Stelle als Geschäftsführer zu unterstützen.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Ihre im Rahmen des Assessment Center erhobenen Daten werden in der Regel bis zu 60 Jahre aufbewahrt und
            anschließend gelöscht. Eine Aufbewahrung von bis zu 60 Jahren kann vorkommen, da die Volkswagen AG erst
            eine Löschung veranlassen kann, sobald die Daten durch Ihren Betrieb aus dem System genommen oder geändert
            werden, oder wenn Sie aus dem Unternehmen ausscheiden.
        </Typography>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            IV. Empfänger der Daten außerhalb der Volkswagen AG
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            In einzelnen Fällen geben wir Ihre Daten an befugte Dritte weiter. Dies können z. B. sein:
        </Typography>
        <ul className={classes.list}>
            <li>
                <div><b>Konzerngesellschaften</b></div>
                <div>
                    (z. B. im Rahmen des Verkaufsprozesses an die Volkswagen Financial Services AG, im Rahmen
                    von Leasingverträgen an die Volkswagen Leasing GmbH, in Zusammenhang mit der Erbringung
                    von Serviceleistungen an die Volkswagen Group Services GmbH)
                </div>
            </li>
        </ul>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Unter Umständen geben wir Ihre Daten an Auftragsverarbeiter weiter. Diese unterliegen den Weisungen
            der Volkswagen AG und dürfen nicht selbst über die Datenverarbeitungszwecke und die Verwendung
            der Daten entscheiden. Wir setzen Auftragsverarbeiter u. a. in folgenden Bereichen ein:
        </Typography>
        <ul className={classes.list}>
            <li>
                <div><b>IT-Dienstleistungen</b></div>
                <div>
                    (z. B. in Zusammenhang mit dem Betrieb und Support von Systemen oder der Nutzung von Clouddiensten)
                </div>
            </li>
            <li>
                <div><b>Serviceleistungen</b></div>
                <div>
                    (z. B. bei der Durchführung von Events, Außendienstprüfungen, Assessment Center)
                </div>
            </li>
        </ul>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Wir haben mit unseren Auftragsverarbeitern jeweils einen Auftragsverarbeitungsvertrag geschlossen und
            angemessene Garantien zur Wahrung des Schutzes personenbezogener Daten vereinbart. Die Auftragsverarbeiter
            verfügen über geeignete technische und organisatorische Maßnahmen zum Schutz personenbezogener Daten und
            werden von uns regelmäßig kontrolliert.
        </Typography>

        <Typography variant="h4" marginTop={'24px'} align='left' >
            V. Datenübermittlung in Drittstaaten
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die Volkswagen AG beachtet bei Datenübermittlungen in sog. Drittstaaten (solche Länder, die weder Mitglied
            der Europäischen Union noch des Europäischen Wirtschaftsraums sind) strikt die einschlägigen
            datenschutzrechtlichen Vorgaben. So übermittelt die Volkswagen AG nur dann Daten an Empfänger
            (z. B. IT-Dienstleister die technischen Support leisten) in Drittstaaten, wenn für den jeweiligen Drittstaat
            ein Angemessenheitsbeschluss der EU Kommission besteht oder die Datenübermittlungen durch den Abschluss
            von durch die EU-Kommission erlassenen EU-Standardvertragsklauseln mit dem Empfänger der Daten angemessen
            abgesichert sind.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Sie haben die Möglichkeit, eine Kopie dieser geeigneten Garantien, die wir zur Sicherstellung des
            angemessenen Datenschutzniveaus schließen, zu erhalten. Bitte kontaktieren Sie uns hierfür unter den
            in <b>Abschnitt D</b> aufgeführten Kontaktangaben.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Weitere Informationen hierzu können Sie auch per folgendem Link entnehmen
            <Link href={"https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en"}>
                https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en.
            </Link>
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Die verwendeten EU-Standardvertragsklauseln können Sie über den folgenden Link einsehen:
            <Link href={"https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=CELEX%3A32010D0087"}>
                https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=CELEX%3A32010D0087.
            </Link>
        </Typography>

        {/* SECTION C */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            C. Ihre Rechte
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            Ihre nachfolgenden Rechte können Sie gegenüber der Volkswagen AG jederzeit unentgeltlich geltend machen.
            Weitere Informationen zur Wahrnehmung Ihrer Rechte sind auf der folgenden Webseite:
            <Link href={"https://datenschutz.volkswagen.de/"}>https://datenschutz.volkswagen.de</Link>
            zu finden.
        </Typography>

        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            <b>Auskunftsrecht:</b> Sie haben das Recht, von uns Auskunft über die Verarbeitung Ihrer personenbezogenen
            Daten zu erhalten.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            <b>Berichtigungsrecht:</b> Sie haben das Recht, von uns die Berichtigung Sie betreffender unrichtiger bzw.
            unvollständiger personenbezogener Daten zu verlangen.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            <b>Recht auf Löschung:</b> Sie haben das Recht, bei Vorliegen der in Art. 17 DSGVO genannten Voraussetzungen,
            die Löschung Ihrer Daten zu verlangen. Danach können Sie beispielsweise die Löschung Ihrer Daten verlangen,
            soweit diese für die Zwecke, für die sie erhoben wurden, nicht mehr notwendig sind. Außerdem können Sie
            Löschung verlangen, wenn wir Ihre Daten auf der Grundlage Ihrer Einwilligung verarbeiten und Sie
            diese Einwilligung widerrufen.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            <b>Recht auf Einschränkung der Verarbeitung:</b> Sie haben das Recht, die Einschränkung der Verarbeitung
            Ihrer Daten zu verlangen, wenn die Voraussetzungen des Art. 18 DSGVO vorliegen. Dies ist beispielsweise
            der Fall, wenn Sie die Richtigkeit Ihrer Daten bestreiten. Für die Dauer der Überprüfung der Richtigkeit
            der Daten können Sie dann die Einschränkung der Verarbeitung verlangen.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} style={{ border: '1px solid black' }}>
            <b>Widerspruchsrecht:</b> Sofern die Verarbeitung auf einem überwiegenden Interesse oder Ihre Daten
            zum Zwecke der Direktwerbung genutzt werden, haben Sie das Recht, der Verarbeitung Ihrer Daten zu widersprechen.
            Ein Widerspruch ist zulässig, wenn die Verarbeitung entweder im öffentlichen Interesse liegt oder in Ausübung
            öffentlicher Gewalt oder aufgrund eines berechtigten Interesses der Volkswagen AG oder eines Dritten erfolgt.
            Im Falle des Widerspruchs bitten wir Sie, uns Ihre Gründe mitzuteilen, aus denen Sie der Datenverarbeitung
            widersprechen. Daneben haben Sie das Recht, der Datenverarbeitung zu Zwecken der Direktwerbung zu widersprechen.
            Das gilt auch für Profiling, soweit es mit der Direktwerbung zusammenhängt.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            <b>Recht auf Datenübertragbarkeit:</b> Sofern die Datenverarbeitung auf der Grundlage einer Einwilligung
            oder einer Vertragserfüllung beruht und diese zudem unter Einsatz einer automatisierten Verarbeitung erfolgt,
            haben Sie das Recht, Ihre Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten
            und diese an einen anderen Datenverarbeiter zu übermitteln.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            <b>Widerrufsrecht:</b> Sofern die Datenverarbeitung auf einer Einwilligung beruht, haben Sie das Recht, 
            die Datenverarbeitung im Rahmen einer Einwilligung mit Wirkung für die Zukunft jederzeit kostenlos zu 
            widerrufen.
        </Typography>
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
            <b>Beschwerderecht:</b> Sie haben außerdem das Recht, sich bei einer Aufsichtsbehörde (z. B. bei 
            der Landesbeauftragten für den Datenschutz Niedersachsen) über unsere Verarbeitung Ihrer Daten zu beschweren.
        </Typography>

        {/* SECTION G */}
        <Typography variant="h4" marginTop={'24px'} align='left' >
            D. Ihre Ansprechpartner
        </Typography>
       
                <Typography variant="h5" marginTop={'8px'} align='left'>
                    Ansprechpartner für die Ausübung Ihrer Rechte
                </Typography>
                <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
                    Die Ansprechpartner für die Ausübung Ihrer Rechte und weitergehender Informationen finden Sie 
                    auf der folgenden Webseite
                    <Link href={"https://datenschutz.volkswagen.de/"}>datenschutz.volkswagen.de</Link>.
                </Typography>
        
                <Typography variant="h5" marginTop={'8px'} align='left'>
                    Datenschutzbeauftragter
                </Typography>
                <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
                    Unser Datenschutzbeauftragter steht Ihnen als Ansprechpartner für datenschutzbezogene Anliegen 
                    zur Verfügung:
                </Typography>
                <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
                    <div>Datenschutzbeauftragter der Volkswagen AG</div> 
                    <div>Berliner Ring 2, 38440 Wolfsburg</div>
                </Typography>
                <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'} >
                    <a href="mailto:datenschutz@volkswagen.de">datenschutz@volkswagen.de</a>
                </Typography>
    

        {/* Stand */}
        <Typography variant="h5" marginTop={'8px'} align='left' fontWeight={'normal'}>
            Version: <b>1.0</b>, Datum: <b>29.04.2021</b>
        </Typography>


    </>)
}

export default EmployeesVWPartners;