import React from 'react';
import { Box } from '@mui/material';
import { useStyles } from '../Tooltip.styles';
import { HtmlTooltip } from '../HtmlTooltip/HtmlTooltip.js';
import { petrol800 } from '../../helper/MuiStyles.js';

export default function TestCaseInfo(props) {
    const { testSpecification, testSpecificationVersion, reference, sizeWindow, access } = props;

    const styleProps = {
        fontSizeTable: (sizeWindow < 5) ? '14px' : '16px',
        minWidthTable: (sizeWindow < 5) ? '34px' : '16px',
    };
    const classes = useStyles(styleProps);
    return (
        <HtmlTooltip data-testid="tooltip" maxwidth="500px"
            title={
                <Box display="flex " flexDirection="row" justifyContent="space-between">

                    <Box display="flex " flexDirection="column" justifyContent="space-between">
                        <Box display="flex " flexDirection="row" px={1} pt={1}>
                            <Box className={classes.title} style={{ minWidth: '140px' }}>Test Specification:</Box>
                            <Box ml={0.5} className={classes.value}>{testSpecification}</Box>
                        </Box>
                        <Box display="flex " flexDirection="row" px={1} pt={1}>
                            <Box className={classes.title} style={{ minWidth: '140px' }}>Version:</Box>
                            <Box ml={0.5} className={classes.value}>{testSpecificationVersion}</Box>
                        </Box>
                        <Box display="flex " flexDirection="row" p={1}>
                            <Box className={classes.title} style={{ minWidth: '140px' }}>Reference:</Box>
                            <Box ml={0.5} className={classes.value}>{reference}</Box>
                        </Box>
                    </Box>

                </Box>
            }
        >
            <Box className={classes.test_specification_text} style={ access ==="Schedule" ? {color: petrol800, fontWeight: '700', paddingLeft: sizeWindow < 5 ? '12px' : '16px' } : {color: petrol800, fontWeight: '700'}}>{testSpecification}</Box>
        </HtmlTooltip>
    )
}