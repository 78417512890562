import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { grey600, petrol800 } from '../MuiStyles';

function BatteryLoader(props) {
    const { type } = props;

    const styleChanger = (standard, small, header, backdrop) => {
        if (type === 'standard') {
            return standard
        }
        if (type === 'small') {
            return small
        }
        if (type === 'header') {
            return header
        }
        if (type === 'backdrop') {
            return backdrop
        }
    }

    const useStyles = makeStyles(() => ({
        container: {
            marginBottom: styleChanger('-20px', '-12px', '-5px', '-20px'),
        },
        battery: {
            width: styleChanger('110px', '80px', '55px', '110px'),
            height: styleChanger('50px', '34px', '25px', '50px'),
            border: styleChanger(`3px solid ${grey600}`, `2px solid ${grey600}`, '1.5px solid rgba(255,255,255,0.8)', `3px solid rgba(255,255,255,0.8)`),
            borderRadius: styleChanger('4px', '3px', '3px', '4px'),
        },
        top: {
            height: styleChanger('20px', '12px', '5px', '20px'),
            width: styleChanger('4px', '3px', '2px', '4px'),
            backgroundColor: styleChanger(grey600, grey600, 'rgba(255,255,255,0.8)', 'rgba(255,255,255,0.8)'),
            position: 'relative',
            bottom: styleChanger('38px', '25px', '16.5px', '38px'),
            left: styleChanger('116px', '84px', '58px', '116px'),
            borderRadius: styleChanger('0 2.5px 2.5px 0', '0 2px 2px 0', '0 1.5px 1.5px 0', '0 2.5px 2.5px 0'),
        },
        charge1: {
            height: styleChanger('40px', '28px', '19px', '40px'),
            width: styleChanger('21.25px', '16.25px', '10px', '21.25px'),
            backgroundColor: styleChanger('#C6DFE7', '#C6DFE7', 'rgba(255,255,255,0.2)', 'rgba(255,255,255,0.2)'),
            position: 'relative',
            top: styleChanger('5px', '3px', '3px', '5px'),
            left: styleChanger('5px', '3px', '3px', '5px'),
            animation: '$charge-1 1.5s infinite',
        },
        charge2: {
            height: styleChanger('40px', '28px', '19px', '40px'),
            width: styleChanger('21.25px', '16.25px', '10px', '21.25px'),
            backgroundColor: styleChanger('#80B0C8', '#80B0C8', 'rgba(255,255,255,0.4)', 'rgba(255,255,255,0.4)'),
            position: 'relative',
            bottom: styleChanger('35px', '25px', '16px', '35px'),
            left: styleChanger('31.25px', '22.25px', '16px', '31.25px'),
            animation: '$charge-2 1.5s infinite',
        },
        charge3: {
            height: styleChanger('40px', '28px', '19px', '40px'),
            width: styleChanger('21.25px', '16.25px', '10px', '21.25px'),
            backgroundColor: styleChanger('#4A849F', '#4A849F', 'rgba(255,255,255,0.6)', 'rgba(255,255,255,0.6)'),
            position: 'relative',
            bottom: styleChanger('75px', '53px', '35px', '75px'),
            left: styleChanger('57.5px', '41.5px', '29px', '57.5px'),
            animation: '$charge-3 1.5s infinite',
        },
        charge4: {
            height: styleChanger('40px', '28px', '19px', '40px'),
            width: styleChanger('21.25px', '16.25px', '10px', '21.25px'),
            backgroundColor: styleChanger(petrol800, petrol800, 'rgba(255,255,255,0.8)', 'rgba(255,255,255,0.8)'),
            position: 'relative',
            bottom: styleChanger('115px', '82px', '54px', '115px'),
            left: styleChanger('83.75px', '60.75px', '42px', '83.75px'),
            animation: '$charge-4 1.5s infinite',
        },
        '@keyframes charge-1': {
            '0%,25%': { opacity: 0 },
            '25%,100%': { opacity: 1 }
        },
        '@keyframes charge-2': {
            '0%,25%': { opacity: 0 },
            '50%,100%': { opacity: 1 },
        },
        '@keyframes charge-3': {
            '0%,50%': { opacity: 0 },
            '75%,100%': { opacity: 1 },
        },
        '@keyframes charge-4': {
            '0%,75%': { opacity: 0 },
            '100%': { opacity: 1 },
        },
    }));
    
    const classes = useStyles();

    return (
        <div className={classes.container} data-testid="container">
            <div className={classes.battery}>
                <div className={classes.charge1}></div>
                <div className={classes.charge2}></div>
                <div className={classes.charge3}></div>
                <div className={classes.charge4}></div>
            </div>
            <div className={classes.top}></div>
        </div>
    );
}

export default BatteryLoader;