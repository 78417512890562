import React, {useContext, useMemo} from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { createUploadLink } from "apollo-upload-client";
import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { UploadProgressContext } from "./components/other/contexts/UploadContext";
import {SnackbarContext} from './components/other/contexts/SnackBarContext';
import App from './App';
import {RetryLink} from "@apollo/client/link/retry";

function ApolloProviderWrapper() {
  const { REACT_APP_CDA_URL } = process.env;

  const { setUploadProgress, resetUploadProgress } = UploadProgressContext();
  const { setMessage } = useContext(SnackbarContext);

  function customFetch(url, opts = {}) {
    return new Promise((resolve, reject) => {
      const requestUuid = Math.random().toString(36).substr(2, 15);
      const xhr = new XMLHttpRequest()

      xhr.open(opts.method || 'get', url)

      for (let k in opts.headers || {}) xhr.setRequestHeader(k, opts.headers[k])

      xhr.onload = e => {
        resolve({
          ok: true,
          text: () => Promise.resolve(e.target.responseText),
          json: () => Promise.resolve(JSON.parse(e.target.responseText))
        })
      }

      xhr.onerror = reject

      if (xhr.upload)
        xhr.upload.onprogress = event => {
          const progress = event.loaded / event.total * 100;
          setUploadProgress({requestId: requestUuid, progress});
        }

      xhr.upload.onloadend = () => {
        resetUploadProgress();
        setMessage('Upload has been finished');
      }

      xhr.send(opts.body)
    })
  }

  const uploadLink = createUploadLink({
    uri: `${REACT_APP_CDA_URL}`,
    fetch: typeof window === 'undefined' ? global.fetch : customFetch,
  });

  const httpLink = createHttpLink({
    uri: `${REACT_APP_CDA_URL}`,
  });

  let directionalLink;

  if (process.env.REACT_APP_IS_SYSTEM_TEST) {
    directionalLink = new RetryLink().split(operation => operation.getContext().isUpload, uploadLink, httpLink);
  } else {
    const {keycloak} = useKeycloak();
    const authLink = setContext((_, {headers}) => {
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${keycloak.token}`,
        },
      };
    });

    directionalLink = authLink.split(operation => operation.getContext().isUpload, uploadLink, httpLink);
  }

  const client = new ApolloClient({
    connectToDevTools: process.env.REACT_APP_DEVTOOLS,
    link: directionalLink,
    cache: new InMemoryCache({ addTypename: false }),
  });

  return useMemo(() => {
    return (
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    );
  }, []);
}

export default ApolloProviderWrapper;
