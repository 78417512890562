import React from 'react';
import ReactDOM from 'react-dom/client';
import ApolloProviderWrapper from './ApolloProviderWrapper';
import reportWebVitals from './reportWebVitals';
import {ReactKeycloakProvider} from '@react-keycloak/web';
import keycloak from './keycloak';
import {Box, StyledEngineProvider, ThemeProvider} from '@mui/material';
import BatteryLoader from './components/other/helper/BatteryLoader/BatteryLoader';
import {theme} from './components/other/helper/MuiStyles';
import {SnackbarProvider} from './components/other/contexts/SnackBarContext';
import {UploadProvider} from './components/other/contexts/UploadContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
const isSystemTestEnvironment = process.env.REACT_APP_IS_SYSTEM_TEST;

const authWrapper = (children) => (
    <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={{
            onLoad: 'login-required',
            enableLogging: true,
            checkLoginIframe: false,
            pkceMethod: 'S256',
        }}
        LoadingComponent={
            <Box display="flex" flexDirection="column" justifyContent="space-between" style={{minHeight: '100vh'}}>
                <Box mx={2} display="flex" alignItems="center" justifyContent="center" flexGrow="1">
                    <BatteryLoader type="standard"/>
                </Box>
            </Box>
        }>
        {children}
    </ReactKeycloakProvider>
);

const appContent = () => (
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <SnackbarProvider>
                <UploadProvider>
                    <ApolloProviderWrapper/>
                </UploadProvider>
            </SnackbarProvider>
        </ThemeProvider>
    </StyledEngineProvider>
);

root.render(isSystemTestEnvironment ?  appContent() : authWrapper(appContent()));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
