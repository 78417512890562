import React, { useState, useEffect, useRef, useContext } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Paper, Grid, Box, Button, Dialog, Typography, TextField, ToggleButton, ToggleButtonGroup, IconButton } from '@mui/material';
import SelectionTestCaseBox from './SelectionTestCaseBox';
import { useStyles } from './SelectionModal.styles.js';
import Counter from '../../other/helper/Counter/Counter';
import FilterButton from '../../other/helper/FilterButton/FilterButton';
import SelectedTestSpec from '../../other/helper/SelectedTestSpec/SelectedTestSpec';
import Icon from '../../../assets/icons/Icon.js';
import { SnackbarContext, SnackbarType } from '../../other/contexts/SnackBarContext';
import { handleSubSampleName } from '../../projectdetails/ProjectDetails/samplefunctions';
import { grey600, petrol800, grey1000, buttonPressedPetrol800 } from '../../other/helper/MuiStyles';
import { currentIndexId, currentProjectId, isTestCasesLoading } from '../../../operations/localeStorage';
import { useMutation } from '@apollo/client';
import { CREATETESTCASES } from '../../../operations/mutations/mutations';
import { PROJECTDETAIL, TESTCASES } from '../../../operations/queries/query';

const SelectionModal = ({
  styleProp, categories, filterCategory, project,
  selectedSubSample, selectedIndex,
  allTestSpecs, activeTestSpecs, setAllTestSpecs,
  setActiveTestSpecs, resetTestSpecAmount, isLocked
}) => {
  const { setMessage, setSnackbarType, setIsSnackbarOpen } = useContext(SnackbarContext);

  const handleOnError = async (error) => {
    setMessage(error.message);
    setSnackbarType(SnackbarType.ERROR);
    setIsSnackbarOpen(true);
  }

  const handleOnCompleted = async () => {
    setSnackbarType(SnackbarType.SUCCESS);
    setIsSnackbarOpen(true);
  }

  const [createTestCases, { loading: creatTestCaseLoading }] = useMutation(CREATETESTCASES, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
    refetchQueries: [{ query: TESTCASES(currentIndexId()) }, { query: PROJECTDETAIL(currentProjectId()) }],
    awaitRefetchQueries: true
  });

  useEffect(() => {
    if (creatTestCaseLoading) {
      isTestCasesLoading(true);
    } else {
      isTestCasesLoading(false);
    }
  }, [creatTestCaseLoading]); // eslint-disable-line


  const [activeCategories, setActiveCategories] = useState([]);
  const [show, setShow] = useState(false);
  const [filterButton, setFilterButton] = useState(false);
  const [selectedButton, setSelectedButton] = useState(true);
  const [filterKey, setFilterKey] = useState(false);
  const [selectedTestSpecifications, setSelectedTestSpecifications] = useState([]);
  // for styling purpose (calculating available height for test specifications list)
  const [heightFilter, setHeightFilter] = useState(0);
  const [heightSelected, setHeightSelected] = useState(0);
  const refFilter = useRef(null);
  const refSelected = useRef(null);

  const addSelectedTestSpecs = (testSpec) => {
    const currSelectedTestSpecs = [...selectedTestSpecifications];
    const allSpecs = JSON.parse(JSON.stringify(allTestSpecs));
    const activeSpecs = JSON.parse(JSON.stringify(activeTestSpecs));
    if (currSelectedTestSpecs.find(i => i.testSpecificationId === testSpec.testSpecificationId)) {
      currSelectedTestSpecs.filter(function (spec) { return spec.testSpecificationId === testSpec.testSpecificationId })[0].amount += 1;
    } else {
      currSelectedTestSpecs.push({ testSpecificationId: testSpec.testSpecificationId, amount: 1, name: testSpec.name, version: testSpec.version })
    }
    allSpecs.find(x => x.testSpecificationId === testSpec.testSpecificationId).amount += 1;
    activeSpecs.find(x => x.testSpecificationId === testSpec.testSpecificationId).amount += 1;
    setAllTestSpecs(allSpecs);
    setActiveTestSpecs(activeSpecs);
    setSelectedTestSpecifications(currSelectedTestSpecs);
  };

  const reduceSelectedTestSpecs = (testSpec) => {
    const currSelectedTestSpecs = [...selectedTestSpecifications];
    const allSpecs = JSON.parse(JSON.stringify(allTestSpecs));
    const activeSpecs = JSON.parse(JSON.stringify(activeTestSpecs));
    if (currSelectedTestSpecs.find(i => i.testSpecificationId === testSpec.testSpecificationId).amount === 1) {
      currSelectedTestSpecs.splice(currSelectedTestSpecs.map(testSpec => testSpec.testSpecificationId).indexOf(testSpec.testSpecificationId), 1);
    } else {
      currSelectedTestSpecs.filter(function (spec) { return spec.testSpecificationId === testSpec.testSpecificationId })[0].amount -= 1;
    }
    allSpecs.find(x => x.testSpecificationId === testSpec.testSpecificationId).amount -= 1;
    activeSpecs.find(x => x.testSpecificationId === testSpec.testSpecificationId).amount -= 1;
    setAllTestSpecs(allSpecs);
    setActiveTestSpecs(activeSpecs);
    setSelectedTestSpecifications(currSelectedTestSpecs);
  };

  const handleSelectedClick = () => {
    setSelectedButton(!selectedButton);
  };

  const handleFilterClick = () => {
    setFilterButton(!filterButton);
  };

  const handleInputChange = (event, value) => {
    let allTestSpecifications = activeTestSpecs;
    let filter = [];
    // let regEx = new RegExp(value);
    let list = allTestSpecifications.filter(function (currentElement) {
      // return regEx.test(currentElement.name);
      return (currentElement.key === value);
    });
    if (value === null) {
      for (let i of activeCategories) {
        filter = allTestSpecs.filter(({ category }) => category === i);
        list = list.concat(filter);
      }
      if (activeCategories.length < 1) {
        list = allTestSpecs;
      }
    }
    setActiveTestSpecs(list);
  };

  const handleCancel = () => {
    setFilterButton(false);
    setActiveCategories([]);
    setSelectedTestSpecifications([]);
    setShow(!show);
    resetTestSpecAmount();
  };


  const handleAddTestCases = (e) => {
    e.preventDefault();
    const cleanSelectedTestSpecs = [];
    for (let i of selectedTestSpecifications) {
      cleanSelectedTestSpecs.push({ testSpecificationId: i.testSpecificationId, amount: i.amount });
    }
    createTestCases({ variables: { indexId: currentIndexId(), testSpecifications: cleanSelectedTestSpecs } });
    setMessage('Test Case(s) has been added');
    setFilterButton(false);
    setActiveCategories([]);
    setSelectedTestSpecifications([]);
    setShow(!show);
  };

  const handleCategoryClick = (event, category) => {
    setActiveCategories(category);
  };

  useEffect(() => {
    let value = [];
    let allValues = [];
    setActiveTestSpecs(value);
    for (let i of activeCategories) {
      value = allTestSpecs.filter(({ category }) => category === i);
      allValues = allValues.concat(value);
    }
    if (activeCategories.length < 1) {
      allValues = allTestSpecs;
    }
    setActiveTestSpecs(allValues);
    setFilterKey(!filterKey);
  }, [activeCategories]); // eslint-disable-line

  useEffect(() => {
    setHeightFilter(refFilter.current !== null ? refFilter?.current?.clientHeight : 0);
    setHeightSelected(refSelected.current !== null ? (refSelected?.current?.clientHeight + 16) : 0);
  })

  let sizeWindow = window.innerHeight;
  let heightList = sizeWindow * 0.75 - 56 - heightFilter - 40 - heightSelected - 9 - 24 - 64;

  const styleProps = {
    heightList: heightList + 'px',
  };

  const classes = useStyles(styleProps);

  return <>
    <Button
      variant="contained"
      color="primary"
      onClick={() => { setShow(!show); setActiveCategories(filterCategory ? [`${filterCategory}`] : []) }}
      disabled={isLocked}
      style={{ marginRight: styleProp }}
    >
      <div style={{ display: 'flex', marginRight: '8px' }}>
        <Icon iconName={"AddIcon"} fill={'#FFF'} />
      </div>
      Add Test Cases
    </Button>

    {show &&
      <Dialog
        fullWidth={true}
        PaperProps={{
          classes: {
            root: classes.dialogbg
          }
        }}
        maxWidth="xl"
        open={show}
        onClose={() => setShow(!show)}
        aria-labelledby="form-dialog-title">
        <Box display="flex" alignItems="center">
          {/* LEFT PART */}
          <Grid item xs={4}>
            <Paper className={classes.paper}>

              {/* TITLE & ID */}
              <Box display="flex" flexDirection="row">
                <Box display="flex" flexDirection="row" alignItems="baseline" flexWrap="wrap">
                  <Typography variant="h2" style={{ marginRight: '8px' }}>
                    {project.project.name}
                  </Typography>
                  <Typography variant="subtitle1">
                    ID {project.project.projectId}
                  </Typography>
                </Box>
              </Box>

              {/* PLATFORM */}
              <Box mt={4} display="flex" alignItems="center" flexWrap="wrap">
                <div style={{ marginRight: '8px', display: 'flex', alignItems: "center" }}>
                  <Icon iconName={"CarIcon"} fill={grey600} />
                  <Box style={{ color: grey1000, letterSpacing: '0.8px', lineHeight: '24px' }} ml={1}>Vehicle Platform:</Box>
                </div>
                {project.project.platforms.map((platform, index) => {
                  return (
                    <Typography variant="h5" className={classes.info_values} key={platform.platformId}>
                      {(index ? ', ' : '') + platform.name}
                    </Typography>
                  )
                })}
              </Box>

              {/* CAPACITY */}
              <Box mt={3} display="flex" alignItems="center" flexWrap="wrap">
                <div style={{ marginRight: '8px', display: 'flex' }}>
                  <Icon iconName={"BatteryIcon"} fill={grey600} />
                  <Box style={{ color: grey1000, letterSpacing: '0.8px', lineHeight: '24px' }} ml={1}>Cell Capacity:</Box>
                </div>
                <Typography variant="h5" className={classes.info_values}>
                  {project.project.nominalCapacity} Ah
                </Typography>
              </Box>

              {/* LEADING DEVELOPMENT */}
              <Box mt={3} display="flex" alignItems="center" flexWrap="wrap">
                <div style={{ marginRight: '8px', display: 'flex' }}>
                  <Icon iconName={"BuildIcon"} fill={grey600} />
                  <Box style={{ color: grey1000, letterSpacing: '0.8px', lineHeight: '24px' }} ml={1}>Leading Development:</Box>
                </div>
                <Typography variant="h5" className={classes.info_values}>
                  {project.project.leadingDevelopment.name}
                </Typography>
              </Box>

              {/* CALENDAR */}
              <Box mt={3} display="flex" alignItems="center" flexWrap="wrap">
                <div style={{ marginRight: '8px', display: 'flex' }}>
                  <Icon iconName={"CalendarIcon"} fill={grey600} />
                  <Box style={{ color: grey1000, letterSpacing: '0.8px', lineHeight: '24px' }} ml={1}>Start of Production:</Box>
                </div>
                <Typography variant="h5" className={classes.info_values}>
                  CW {project.project.sopWeek} / {project.project.sopYear}
                </Typography>
              </Box>

              {/* SAMPLE STATE */}
              <Box mt={4}>
                <Typography variant="h4" className={classes.title}>
                  Sample State
                </Typography>
              </Box>
              <Box mt={1} display="flex" alignItems="center" flexWrap="wrap">
                <Box className={classes.sampleBox}>
                  {selectedSubSample}-{handleSubSampleName()}
                </Box>
                <Box className={classes.sampleBox}>
                  {selectedIndex}
                </Box>
              </Box>

            </Paper>
          </Grid>


          {/* RIGHT PART */}
          <Grid item xs={8}>
            <Paper className={classes.paper} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <>
                <Box>
                  <Box pb={2} display="flex" alignItems="center" justifyContent="space-between">
                    <Box>
                      <Typography variant="h2">
                        Available Test Cases
                      </Typography>
                    </Box>

                    <Box display="flex" alignItems="center">

                      <div className={classes.search}>
                        <div className={classes.search_icon}>
                          <Icon iconName={'SearchIcon'} fill={grey600} />
                        </div>
                        <Autocomplete
                          id="search-test-case"
                          data-testid='autocomplete-search'
                          key={filterKey}
                          className={classes.search_field}
                          clearOnEscape={true}
                          onChange={handleInputChange}
                          options={activeTestSpecs?.map((name) => name.key)}
                          renderInput={(params) => (
                            <TextField {...params} />
                          )}
                        >
                        </Autocomplete>
                      </div>
                      <Box ml={10}>
                        <FilterButton
                          filterState={!filterButton}
                          activecount={activeCategories?.length}
                          onClick={handleFilterClick}
                        />
                      </Box>
                      <IconButton onClick={handleCancel} className={classes.hover_blue} style={{ padding: '8px', marginLeft: '16px' }}>
                        <Icon iconName={"CloseIcon"} fill={petrol800} />
                      </IconButton>
                    </Box>
                  </Box>

                  <Box>
                    {filterButton &&
                      <ToggleButtonGroup
                        ref={refFilter}
                        value={activeCategories}
                        onChange={handleCategoryClick}
                        aria-label="text alignment"
                        className={classes.category_button_group}
                      >
                        {categories?.categories?.map((i) => {
                          return (
                            <ToggleButton
                              key={i.name}
                              variant="contained"
                              value={i.name}
                              classes={{
                                root: classes.parameter_button,
                                selected: classes.parameter_button_selected,
                              }}
                            >{i.name}</ToggleButton>
                          )
                        })}
                      </ToggleButtonGroup>
                    }

                    <Box display="flex" justifyContent="space-between">
                      <Box display="flex">
                        <Box display="flex" flexDirection="column" justifyContent="center">
                          <Counter number={selectedTestSpecifications.length} />
                        </Box>
                        <Box pl={2} display="flex" flexDirection="column" justifyContent="center">
                          <Typography variant="h5" style={{ lineHeight: '40px' }}>
                            Newly Selected Test Cases
                          </Typography>
                        </Box>
                      </Box>
                      {selectedTestSpecifications.length > 0 &&
                        <Box display="flex" flexDirection="column" justifyContent="center">
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleSelectedClick}
                            style={selectedButton ? {} : { backgroundColor: buttonPressedPetrol800 }}
                          >
                            {selectedButton ? "Show Selected" : "Hide Selected"}
                            <div className={classes.filter_icon}>
                              <Icon iconName={selectedButton ? "ArrowDownIcon" : "ArrowUpIcon"} fill={petrol800} />
                            </div>
                          </Button>
                        </Box>
                      }
                    </Box>

                    {!selectedButton &&
                      <Box ref={refSelected} mt={selectedTestSpecifications.length > 0 ? 2 : 0} display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                        {selectedTestSpecifications?.map(i => {
                          return <SelectedTestSpec name={i.name} version={i.version} activecount={i.amount} key={i.name} />
                        })}
                      </Box>
                    }

                    <Box mt={1} className={classes.line}></Box>

                    <Box className={classes.list}>
                      {activeTestSpecs.map((testspec, index) => {
                        return (
                          <SelectionTestCaseBox
                            testspec={testspec}
                            key={index}
                            addSelectedTestSpecs={addSelectedTestSpecs}
                            reduceSelectedTestSpecs={reduceSelectedTestSpecs}
                          />
                        )
                      })}
                    </Box>
                  </Box>
                </Box>

                <Box pt={3} display="flex" justifyContent="space-between">
                  <Button
                    variant="contained"
                    onClick={handleAddTestCases}
                    color="primary"
                    disabled={selectedTestSpecifications.length < 1}
                  >
                    Add Test Cases
                  </Button>
                </Box>
              </>
              {/* } */}

            </Paper>
          </Grid>
          <Paper>

          </Paper>
        </Box>
      </Dialog>
    }
  </>;
}

export default SelectionModal;