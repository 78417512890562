import React, { useState } from 'react';
import { Button, Dialog, DialogContent, Box, Typography, IconButton } from '@mui/material';
import { useStyles } from '../UniversalDialog.styles.js';
import Icon from '../../../../assets/icons/Icon.js';
import { petrol800 } from '../../helper/MuiStyles.js';

function AddUser() {
    const [show, setShow] = useState(false);

    const ToggleModal = () => {
        setShow(!show);
    };

    const classes = useStyles();

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={ToggleModal}
            >
                <div style={{ display: 'flex', marginRight: '8px' }}>
                    <Icon iconName={"AddIcon"} fill={'#FFF'} />
                </div>
                Add User
            </Button>

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={show}
                onClose={ToggleModal}
            >
                <DialogContent>
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                        <Typography variant="h2">
                            Add User
                        </Typography>
                        <IconButton onClick={ToggleModal} className={classes.hover_blue}>
                            <Icon iconName={"CloseIcon"} fill={petrol800} />
                        </IconButton>
                    </Box>
                    <Box mt={2} display="flex" flexDirection="row" alignItems="center">
                        <Typography variant="h4" style={{ fontWeight: '400' }}>
                            New users need to request the <b>KIRA</b> role VWAG_TONIC42_USER via an Administrator. <br />  <br />
                            Once they have ordered the ressource, they can login for the first time and their user is generated automatically. The Administrator can change their role in the application after their initial login.
                        </Typography>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AddUser;