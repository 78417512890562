import makeStyles from '@mui/styles/makeStyles';
import { grey100, grey1000, grey600, red400 } from '../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    root: {
        padding: '24px',
        backgroundColor: grey100,
    },
    paper: {
        margin: "auto",
        minWidth: "1232px",
        maxWidth: "1232px",
        ['@media (min-width: 1580px)']: { // eslint-disable-line no-useless-computed-key
          minWidth: "1532px",
          maxWidth: "1532px",
        },
        ['@media (min-width: 1880px)']: { // eslint-disable-line no-useless-computed-key
          minWidth: "1832px",
          maxWidth: "1832px",
        },
        ['@media (min-width: 2180px)']: { // eslint-disable-line no-useless-computed-key
          minWidth: "2132px",
          maxWidth: "2132px",
        },
        ['@media (min-width: 2480px)']: { // eslint-disable-line no-useless-computed-key
          minWidth: "2432px",
          maxWidth: "2432px",
        },
        ['@media (min-width: 2780px)']: { // eslint-disable-line no-useless-computed-key
          minWidth: "2732px",
          maxWidth: "2732px",
        },
        backgroundColor: '#FFF',
    },
    title: {
        lineHeight: '40px',
        letterSpacing: '0.6px',
    },
    snackbar: {
        position: 'sticky',
        height: '100%',
        top: '0',
        zIndex: '1'
    },
    hover_title: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.8px',
        textAlign: 'left',
        color: grey600,
        minWidth: '50px',
    },
    input_title: {
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '20px',
        letterSpacing: '0.7px',
        color: grey1000,
    },
    input_title2: {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '20px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    standard_box: {
        backgroundColor: grey100,
        padding: '4px 16px',
        borderRadius: '8px',
        marginRight: '16px',
        marginBottom: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        //ROBERT TBD: switched from 24px to 32px to have same Box height for data without Edit and Delete Buttons
        lineHeight: '32px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    inactive_box: {
        backgroundColor: red400,
        padding: '4px 16px',
        borderRadius: '8px',
        marginRight: '16px',
        marginBottom: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: '#FFF',
    },
    parameter_box: {
        backgroundColor: grey100,
        borderRadius: '8px',
        marginRight: '8px',
        marginLeft: '8px',
        marginBottom: '16px',
        minWidth: '284px',
        maxWidth: '284px',
    },
    parameter_description_wrapper: {
        height: '64px',
        display: 'block'
    },
    parameter_one_line: {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.8px',
        color: grey1000,
        textAlign: 'left',
    },
    parameter_subtitle: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '24px',
        letterSpacing: '0.8px',
        color: grey1000,
        textAlign: 'left',
    },
    error_icon_wrapper: {
        padding: '4px',
        marginLeft: '8px',
        transform: 'rotate(45deg)',
    },
    error_text: {
        marginTop: '8px',
        fontWeight: '400',
    },
    error_type: {
        padding: '0px 24px',
        textAlign: 'center',
        marginTop: '4px',
        fontWeight: '400',
        color: grey600,
    },
    arrow_icon_wrapper: {
        marginLeft: '8px',
        padding: '4px',
    }
}));