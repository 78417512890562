import makeStyles from '@mui/styles/makeStyles';
import { signalRed800, grey1000, grey100, grey400, grey600, buttonHoverPetrol800, grey800 } from '../../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    root: {
        padding: '24px',
        backgroundColor: grey100,
    },
    paper: {
      minWidth: "1232px",
      backgroundColor: '#FFF',  
    },
    input_title: {
        marginBottom: '4px',
    },
    input_title_big:{
        marginBottom: '8px',
    },
    freeText2: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '28px',
        letterSpacing: '0.3px',
        padding: '10px 0px 10px 0px',
        border: `1px solid ${grey400}`,
        boxSizing: "border-box",
        borderRadius: "2px",
        marginTop: '8px',
        minHeight: '177.33px',
        height: 'auto',
        alignItems: 'start',
    },
    freeText3: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        display: 'block',
        lineHeight: '28px',
        letterSpacing: '0.3px',
        padding: '10px 0px 10px 0px',
        border: `1px solid ${grey400}`,
        boxSizing: "border-box",
        borderRadius: "2px",
        minHeight: '120px',
        height: 'auto',
        alignItems: 'start',
    },
    alert_message: {
        marginTop: '4px',
        color: signalRed800,
    },
    tbd_text: {
      marginLeft: '16px',
      color: grey600,
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      lineHeight: '18px',
      letterSpacing: '0.8px',
      fontWeight: '400',
    },
    parameter_box: {
        backgroundColor: grey100,
    },
    add_condition_button: {
        border: `1px solid ${grey100}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    parameter_add_first:{
        color: grey800,
        fontWeight: "400",
        lineHeight: "28px",
        letterSpacing: "0.3px",
    },
    condition_title: {
        height: '40px',
        lineHeight: "18px",
        letterSpacing: "0.7px",
        minWidth: '183px',
        maxWidth: '183px',
        display: 'flex',
        alignItems: 'center',
    },
    assigned_parameter: {
        height: '24px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
        backgroundColor: '#FFFFFF',
        padding: '8px 16px',
        borderRadius: '8px',
        marginBottom: '16px',
    },
    error_icon_wrapper: {
        marginLeft: '8px',
        transform: 'rotate(45deg)',
    },
    trash_icon_wrapper: {    
        marginRight: '5px',
        '&:hover': {
            backgroundColor: buttonHoverPetrol800,
          },
    },
    condition_box: {
        display: 'flex',
        alignItems: 'center',
        minWidth: '183px',
        maxWidth: '183px',
        height: '40px',
        backgroundColor: '#FFFFFF',
        border: `1px solid ${grey400}`,
        boxSizing: 'border-box',
        borderRadius: '2px',
        marginBottom: '16px',
    },
    condition_value: {
        maxWidth: '140px',
        border: 'none',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px !important',
        letterSpacing: '0.6px',
    },
    condition_unit: {
        display: "flex",
        justifyContent: "center",
        fontWeight: '400',
        width: "40px",
        lineHeight: "28px",
        letterSpacing: "0.6px",
        marginBottom: '0',
        borderLeft: `1px solid ${grey400}`,
        color: grey1000,
    },
    steps_box: {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
    },
    step_box: {
        border: `1px solid ${grey400}`,
        boxSizing: 'border-box',
        borderRadius: '4px',
    },
    step_icon:{
        marginLeft: '16px',
    },
    step_bubble: {
        marginLeft: '16px',
        backgroundColor: grey100,
        minWidth: '50px',
        height: '50px',
        borderRadius: '50px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '22px',
        letterSpacing: '0.8px',
        color: grey1000,
    },
    step_step: {
        marginLeft: '16px',
        minWidth: '70px',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
        textAlign: 'left',
    },
    step_description: {
        marginLeft: '16px',
        flexGrow: '1',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    step_edit: {
        marginLeft: '8px',
    },
    step_delete: {
        marginLeft: '8px',
    },    
    box: {    
        width: '160px',
    },
    required_tag: {
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '18px',
        letterSpacing: '0.8px',
        color: grey600,
        marginLeft: '24px',
    },
    error_text: {
        marginTop: '8px',
        fontWeight: '400',
    },
    error_type: {
        padding: '0px 24px',
        textAlign: 'center',
        marginTop: '4px',
        fontWeight: '400',
        color: grey600,
    },
}));