import React, { useContext } from "react";
import { Box, Select, MenuItem, TableCell } from "@mui/material";
import { convertTimeStampToCwYear } from '../../other/helper/timeConverter/timeConverter';
import TestResult from "../table_utils/TestResult";
import TestStatus from '../table_utils/TestStatus';
import { SnackbarContext, SnackbarType } from "../../other/contexts/SnackBarContext";
import AgingStatusInfo from "../../other/tooltips/AgingStatusInfo/AgingStatusInfo";
import BatteryLoader from "../../other/helper/BatteryLoader/BatteryLoader";
import { currentIndexId, currentTestCaseId, currentUpdateTestCaseId } from "../../../operations/localeStorage";
import { useMutation } from "@apollo/client";
import { UPDATETESTCASE } from "../../../operations/mutations/mutations";
import { TESTCASE, TESTCASES } from "../../../operations/queries/query";
import DatePicker from "../../other/helper/CustomDatePicker/DatePicker";

const testCaseToMutation = (test) => ({ 
    testCaseId: test.testCaseId, 
    testResult: test.testResult.testResultId, 
    startDate: test.startDate, 
    plannedStartDate: test.plannedStartDate,
    endDate: test.endDate, 
    plannedEndDate: test.plannedEndDate, 
    testInstance: test.testInstance.testInstanceId, 
    testStatus: test.testStatus.testStatusId,
    agingStatus: test.agingStatus.agingStatusId,
    requirementIds: test.requirementIds
})

export function AgingStatusDropdown({ classes, edit, test, updateTestCase, agingStatus, sizeWindow }) {
    const { setMessage } = useContext(SnackbarContext);

    return (
        <TableCell>
            {edit && (currentUpdateTestCaseId() !== test.testCaseId) &&
                <Select
                    className={classes.box}
                    name="agingStatus"
                    value={test?.agingStatus?.agingStatusId}
                    onChange={(event) => { 
                        currentUpdateTestCaseId(test.testCaseId); 
                        updateTestCase({ 
                            variables: { 
                                ...testCaseToMutation(test), 
                                agingStatus: event.target.value 
                            }
                        }); 
                        setMessage('Test Case has been updated'); 
                    }}
                    style={{ maxWidth: '100px' }}
                >
                    {agingStatus?.agingStatus.map((option) => (
                        <MenuItem
                            key={option.agingStatusId}
                            value={option.agingStatusId}
                            disabled={!option.active}
                        >
                            {option.name} {option.description?.length > 0 ? ` (${option.description})` : ''}
                        </MenuItem>

                    ))}
                </Select>
            }
            {!edit && (currentUpdateTestCaseId() !== test.testCaseId) &&
                <AgingStatusInfo agingStatus={test?.agingStatus?.name} description={agingStatus?.agingStatus.filter(function (agingStatus) { return agingStatus.agingStatusId === test?.agingStatus?.agingStatusId; }).map(function (agingStatus) { return agingStatus.description; })} sizeWindow={sizeWindow} />
            }
            {(currentUpdateTestCaseId() === test.testCaseId) && <BatteryLoader type="small" />}
        </TableCell>
    );
}

export function TestInstanceDropdown({ classes, edit, test, updateTestCase, testInstances }) {
    const { setMessage } = useContext(SnackbarContext);

    return (
        <TableCell>
            {edit && (currentUpdateTestCaseId() !== test.testCaseId) &&
                <Select
                    className={classes.box}
                    name="testInstance"
                    value={test?.testInstance?.testInstanceId}
                    onChange={(event) => { 
                        currentUpdateTestCaseId(test.testCaseId); 
                        updateTestCase({ 
                            variables: { 
                                ...testCaseToMutation(test),
                                testInstance: event.target.value
                            } 
                        }); 
                        setMessage('Test Case has been updated'); 
                    }}
                    style={{ maxWidth: '195px' }}
                >
                    {testInstances?.testInstances.map((option) => (
                        <MenuItem
                            key={option.testInstanceId}
                            value={option.testInstanceId}
                            disabled={!option.active}
                        >
                            {option.name}
                        </MenuItem>

                    ))}
                </Select>
            }
            {!edit && (currentUpdateTestCaseId() !== test.testCaseId) &&
                <Box className={classes.text}>{test?.testInstance?.name}</Box>
            }
            {(currentUpdateTestCaseId() === test.testCaseId) && <BatteryLoader type="small" />}

        </TableCell>
    );
}

export function TestStatusDropdown({ classes, edit, test, updateTestCase, testStatuses, sizeWindow }) {
    const { setMessage } = useContext(SnackbarContext);

    return (
        <TableCell>
            {edit && (currentUpdateTestCaseId() !== test.testCaseId) &&
                <Select
                    className={classes.box}
                    name="testStatusId"
                    value={test?.testStatus?.testStatusId}
                    onChange={(event) => { 
                        currentUpdateTestCaseId(test.testCaseId); 
                        updateTestCase({ 
                            variables: { 
                                ...testCaseToMutation(test),
                                testStatus: event.target.value
                            } 
                        }); 
                        setMessage('Test Case has been updated'); 
                    }}
                >
                    {testStatuses?.testStatuses.map((option) => (
                        <MenuItem
                            key={option.testStatusId}
                            value={option.testStatusId}
                            className={classes.dropdown_list}
                        >
                            <TestStatus testStatusName={option.name} width={sizeWindow < 5 ? '90px' : '100px'} sizeWindow={sizeWindow} />
                        </MenuItem>
                    ))}
                </Select>
            }
            {!edit && (currentUpdateTestCaseId() !== test.testCaseId) &&
                <Box display="flex" flexDirection="row" justifyContent="left">
                    <TestStatus testStatusName={test?.testStatus?.name} width={sizeWindow < 5 ? '90px' : '100px'} sizeWindow={sizeWindow} />
                </Box>
            }
            {(currentUpdateTestCaseId() === test.testCaseId) && <BatteryLoader type="small" />}
        </TableCell>
    );
}

export function PlannedStartDateDropdown({ edit, test, updateTestCase }) {
    const { setMessage } = useContext(SnackbarContext);

    return (
        <TableCell>
            {edit && (currentUpdateTestCaseId() !== test.testCaseId) &&
                <Box style={{ maxWidth: '160px' }}>
                    {test && <DatePicker date={test.plannedStartDate} trackInitDate={true}
                        onBlur = {
                            (validValue) => { 
                                currentUpdateTestCaseId(test.testCaseId); 
                                updateTestCase({ 
                                    variables: { 
                                        ...testCaseToMutation(test),
                                        plannedStartDate: validValue,
                                        startDate: validValue
                                    }
                                }); 
                                setMessage('Test Case has been updated'); 
                            }
                        }
                    />}
                </Box>
            }
            {!edit && (currentUpdateTestCaseId() !== test.testCaseId) &&
                convertTimeStampToCwYear(test.plannedStartDate)
            }
            {(currentUpdateTestCaseId() === test.testCaseId) && <BatteryLoader type="small" />}
        </TableCell>
    );
}

export function StartDateDropdown({ edit, test, updateTestCase }) {
    const { setMessage } = useContext(SnackbarContext);

    return (
        <TableCell>
            {edit && (currentUpdateTestCaseId() !== test.testCaseId) &&
                <Box style={{ maxWidth: '160px' }}>
                    {test && <DatePicker date={test.startDate} trackInitDate={true}
                        onBlur = {
                            (validValue) => { 
                                currentUpdateTestCaseId(test.testCaseId); 
                                updateTestCase({ 
                                    variables: { 
                                        ...testCaseToMutation(test),
                                        startDate: validValue
                                    }
                                }); 
                                setMessage('Test Case has been updated'); 
                            }
                        }
                    />}
                </Box>
            }
            {!edit && (currentUpdateTestCaseId() !== test.testCaseId) &&
                convertTimeStampToCwYear(test.startDate)
            }
            {(currentUpdateTestCaseId() === test.testCaseId) && <BatteryLoader type="small" />}
        </TableCell>
    );
}

export function PlannedEndDateDropdown({ edit, test, updateTestCase }) {
    const { setMessage } = useContext(SnackbarContext);

    return (
        <TableCell>
            {edit && (currentUpdateTestCaseId() !== test.testCaseId) &&
                <Box style={{ maxWidth: '160px' }}>
                    {test && <DatePicker date={test.plannedEndDate} trackInitDate={true}
                        onBlur = {
                            (validValue) => { 
                                currentUpdateTestCaseId(test.testCaseId); 
                                updateTestCase({ 
                                    variables: { 
                                        ...testCaseToMutation(test),
                                        plannedEndDate: validValue,
                                        endDate: validValue
                                    } 
                                }); 
                                setMessage('Test Case has been updated'); 
                            }
                        }
                        endOfWeek={true}
                    />}
                </Box>
            }
            {!edit && (currentUpdateTestCaseId() !== test.testCaseId) &&
                convertTimeStampToCwYear(test.plannedEndDate)
            }
            {(currentUpdateTestCaseId() === test.testCaseId) && <BatteryLoader type="small" />}
        </TableCell>
    );
}


export function EndDateDropdown({ edit, test, updateTestCase }) {
    const { setMessage } = useContext(SnackbarContext);

    return (
        <TableCell>
            {edit && (currentUpdateTestCaseId() !== test.testCaseId) &&
                <Box style={{ maxWidth: '160px' }}>
                    {test && <DatePicker date={test.endDate} trackInitDate={true}
                        onBlur = {
                            (validValue) => { 
                                currentUpdateTestCaseId(test.testCaseId); 
                                updateTestCase({ 
                                    variables: { 
                                        ...testCaseToMutation(test),
                                        endDate: validValue
                                    } 
                                }); 
                                setMessage('Test Case has been updated'); 
                            }
                        }
                        endOfWeek={true}
                    />}
                </Box>
            }
            {!edit && (currentUpdateTestCaseId() !== test.testCaseId) &&
                convertTimeStampToCwYear(test.endDate)
            }
            {(currentUpdateTestCaseId() === test.testCaseId) && <BatteryLoader type="small" />}
        </TableCell>
    );
}

export function TestResultDropdown({ edit, test, updateTestCase, testResults, sizeWindow }) {
    const { setMessage } = useContext(SnackbarContext);

    return (
        <TableCell>
            {edit && (currentUpdateTestCaseId() !== test.testCaseId) &&
                <Select
                    name="testResultId"
                    value={test?.testResult?.testResultId}
                    onChange={(event) => { 
                        currentUpdateTestCaseId(test.testCaseId); 
                        updateTestCase({ 
                            variables: { 
                                ...testCaseToMutation(test),
                                testResult: event.target.value
                            } 
                        }); 
                        setMessage('Test Case has been updated'); }}
                >
                    {testResults?.testResults.map((option) => (
                        <MenuItem
                            key={option.testResultId}
                            value={option.testResultId}
                        >
                            <TestResult testResultName={option.name} width={sizeWindow < 5 ? '115px' : '130px'} sizeWindow={sizeWindow} />
                        </MenuItem>
                    ))}
                </Select>
            }
            {!edit && (currentUpdateTestCaseId() !== test.testCaseId) &&
                <Box display="flex" flexDirection="row" justifyContent="left">
                    <TestResult testResultName={test?.testResult?.name} width={sizeWindow < 5 ? '115px' : '130px'} sizeWindow={sizeWindow} />
                </Box>
            }
            {(currentUpdateTestCaseId() === test.testCaseId) && <BatteryLoader type="small" />}
        </TableCell>
    );
}


export function TableElements({ classes, edit, test, testInstances, testStatuses, testResults, agingStatus, sizeWindow }) {
    const { setIsSnackbarOpen, setMessage, setSnackbarType } = useContext(SnackbarContext);
    const handleOnCompleted = async () => {
        currentUpdateTestCaseId(0);
        setSnackbarType(SnackbarType.SUCCESS);
        setIsSnackbarOpen(true);
    }

    const handleOnError = async (error) => {
        currentUpdateTestCaseId(0);
        setMessage(error.message);
        setSnackbarType(SnackbarType.ERROR);
        setIsSnackbarOpen(true);
    }

    const [updateTestCase] = useMutation(UPDATETESTCASE, { 
        onCompleted: handleOnCompleted, 
        onError: handleOnError, 
        refetchQueries: [{ query: (currentTestCaseId() === 1) ? '' : TESTCASE(currentTestCaseId()) }, { query: (currentIndexId() === undefined || currentIndexId() === -1) ? '' : TESTCASES(currentIndexId()) }], 
        awaitRefetchQueries: true 
    });

    return <>
        {/* AGING STATUS */}
        <AgingStatusDropdown test={test} classes={classes} edit={edit} updateTestCase={updateTestCase} agingStatus={agingStatus} sizeWindow={sizeWindow} />
        {/* TEST INSTANCE */}
        <TestInstanceDropdown test={test} classes={classes} edit={edit} updateTestCase={updateTestCase} testInstances={testInstances} />
        {/* TEST STATUS */}
        <TestStatusDropdown test={test} classes={classes} edit={edit} updateTestCase={updateTestCase} testStatuses={testStatuses} sizeWindow={sizeWindow} />
        {/* START DATE */}
        <PlannedStartDateDropdown classes={classes} edit={edit} test={test} updateTestCase={updateTestCase} />
        <StartDateDropdown classes={classes} edit={edit} test={test} updateTestCase={updateTestCase} />
        {/* END DATE */}
        <PlannedEndDateDropdown classes={classes} edit={edit} test={test} updateTestCase={updateTestCase} />
        <EndDateDropdown classes={classes} edit={edit} test={test} updateTestCase={updateTestCase} />
        {/* TEST RESULT */}
        <TestResultDropdown test={test} classes={classes} edit={edit} updateTestCase={updateTestCase} testResults={testResults} sizeWindow={sizeWindow} />
    </>
}