import makeStyles from '@mui/styles/makeStyles';
import { buttonHoverSignalRed800, grey100, grey400, grey600, buttonHoverPetrol800, signalRed800, grey1000, petrol800, petrol600, buttonHoverPetrol600 } from '../helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    paper: {
        width: 'auto'
    },
    state_name: {
        fontWeight: "400",
        fontStyle: 'italic',
        paddingLeft: '4px',
    },
    state_questionmark:{
        paddingLeft: '4px',
    },
    delete_icon_wrapper: {
        marginLeft: '8px',
        transform: 'rotate(45deg)',       
        '&:hover': {
            backgroundColor: props => props.deleteIconHoverBackground,
          },
    },
    index_title: {
        marginBottom: '4px',
        fontWeight: '700',
    },
    hover_blue: {
        '&:hover': {
            backgroundColor: buttonHoverPetrol800,
          },
    },
    box_freeText: {
        minHeight: '40px',
        paddingTop: '5px',
        backgroundColor: '#FFF',
        textAlign: 'left',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        letterSpacing: '0.3px',
        border: `1px solid ${grey400}`,
        boxSizing: "border-box",
        borderRadius: "2px",
        height: 'auto',
        alignItems: 'start',
    },
    alert_message: {
        marginTop: '4px',
        color: signalRed800,
    },
    button_delete: {
        color: signalRed800,
        backgroundColor: '#FFF',
        '&:hover': {
            backgroundColor: buttonHoverSignalRed800,
        },
    },
    delete_icon_wrapper_big: {
        padding: '8px',
        marginLeft: '8px',
    },  
    edit_icon_wrapper: {
        marginLeft: props => props.editIconWrapperMarginLeft,        
        '&:hover': {
            backgroundColor: props => props.editIconHoverBackground,
          },
    },
    add_button: {
        marginBottom: props => props.addButtonMarginBottom,   
        border: `1px solid ${petrol600}`,
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: '400',
        color: petrol600,
        backgroundColor: props => props.addButtonBackgroundColor,
        borderRadius: '8px',
        letterSpacing: '0.6px',
        paddingLeft: '16px !important',
        paddingRight: '16px !important',
        minWidth: 'auto',
        '&:hover': {
            backgroundColor: buttonHoverPetrol600,
        },
    },
    add_parameter_button: {
        textTransform: 'none',
        fontSize: '16px',
        border: `1px solid ${petrol800}`,
        fontWeight: '400',
        color: petrol800,
        backgroundColor: '#FFF', 
        borderRadius: '8px',
        marginRight: '8px',
        marginLeft: '8px',
        marginBottom: '16px',
        minWidth: '284px',
        maxWidth: '284px',
        minHeight: '264px',
        maxHeight: '264px',
        '&:hover': {
            backgroundColor: buttonHoverPetrol800,
        },
    },
    large_box: {
        maxWidth: '436px',
    },
    add_button_information: {
        marginLeft: '16px',
        border: `1px solid ${petrol600}`,
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: '400',
        color: petrol600,
        backgroundColor: grey100,
        borderRadius: '8px',
        letterSpacing: '0.6px',
        paddingLeft: '16px !important',
        paddingRight: '16px !important',
        minWidth: 'auto',
        '&:hover': {
            backgroundColor: buttonHoverPetrol600,
        },
    },
    required_tag: {
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '18px',
        letterSpacing: '0.8px',
        color: grey600,
        marginLeft: '24px',
    },
    standard_box: {
        backgroundColor: grey100,
        // width: '10%',
        padding: '4px 16px',
        borderRadius: '8px',
        marginRight: '16px',
        marginBottom: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        //ROBERT TBD: switched from 24px to 32px to have same Box height for data without Edit and Delete Buttons
        lineHeight: '32px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    upload_box: {
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.1)',
        padding: '24px',
        borderRadius: '2px',
    },
    add_unit_button: {
        "&.Mui-disabled": {
            backgroundColor: 'initial',
            opacity: '0.2',
        }
    },
    grid_level_info: {
        fontSize: '18px',
        margin: '16px 0'
    },
    grid_info: {
        margin: '16px 0'
    }
}));