import React from 'react';
import Box from '@mui/material/Box';
import { HtmlTooltip } from '../HtmlTooltip/HtmlTooltip';
import { useStyles } from '../Tooltip.styles';
import Icon from '../../../../assets/icons/Icon';

export default function IndexInformation(props) {
    const { info } = props;
    const classes = useStyles();

    return (
        <HtmlTooltip data-testid="tooltip" maxwidth="500px"
            title={
                <Box display="flex" justifyContent="space-between">

                    <Box p={1} display="flex" flexDirection="column" justifyContent="space-between">
                            <Box className={classes.title} style={{ minWidth: '90px' }}>Information on Index:</Box>
                            <Box className={classes.value}>{info === null ? '-' : info}</Box>
                    </Box>

                </Box>
            }
        >
            <Box display="flex" alignItems="center" className={classes.normalButton}><div className={classes.icon_wrapper}><Icon iconName={"InfoIcon"} fill={"#FFF"}/></div>Information</Box>
        </HtmlTooltip>
    )
}