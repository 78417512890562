import React, {useState, useEffect, useContext} from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { TESTSPECIFICATION, CATEGORIES, APPROVALS, GROUPSTANDARDREFERENCES, ALLGROUPSTANDARDREFERENCES, ALLCATEGORIES, ALLAPPROVALS, ALLPARAMETERS, PARAMETERS } from '../../../operations/queries/query';
import { Grid, Paper, Button, Box, Typography, InputLabel, Select, MenuItem, InputBase, IconButton, InputAdornment } from '@mui/material';
import Icon from '../../../assets/icons/Icon.js';
import AddNewStep from '../Step/AddNewStep/AddNewStep';
import { useStyles } from './CreateNewSpecification.styles.js';
import ParameterInfo from '../../other/tooltips/ParameterInfo/ParameterInfo';
import BatteryLoader from '../../other/helper/BatteryLoader/BatteryLoader';
import { handleInputChange, handleInputChangeFreeText, handleRemoveConditionColumn } from '../../other/helper/globalFunctions/globalFunctions';
import StepDescription from '../Step/StepDescription/StepDescription';
import ParameterSelection from '../ParameterSelection/ParameterSelection';
import { TestSpecErrorMessage } from '../TestSpecErrorMessage';
import {
    SnackbarContext, SnackbarType
} from '../../other/contexts/SnackBarContext';
import { useNavigate, useLocation } from 'react-router-dom';
import StickyBar from '../../stickybar/StickyBar';
import { grey1000, petrol800 } from '../../other/helper/MuiStyles';
import RequirementIdsInfo from '../../other/tooltips/RequirementIdsInfo/RequirementIdsInfo';
import { ADDTESTSPECIFICATION } from '../../../operations/mutations/mutations';

function CreateNewSpecification() {

    const navigate = useNavigate();
    let location = useLocation();
    const { setIsSnackbarOpen, setMessage, setSnackbarType } = useContext(SnackbarContext);

    const onCompleted = async () => {
        setMessage('Test Specification has been created.');
        setSnackbarType(SnackbarType.SUCCESS);
        setIsSnackbarOpen(true);
        navigate("/")
    }

    let newVersion = location?.state?.versionValue?.versionNumber;
    let title = newVersion > 1 ? 'Version' : 'Specification';

    const handleOnError = async (error) => {
        setMessage(error.message);
        setSnackbarType(SnackbarType.ERROR);
        setIsSnackbarOpen(true);
    }

    // QUERIES
    const { data: parametersData, loading: parametersLoading, error: parametersError } = useQuery(PARAMETERS(true));
    const { data: allParametersData, loading: allParametersLoading, error: allParametersError } = useQuery(ALLPARAMETERS);
    const [getTestSpecData, { data: testSpecification, loading: testSpecificationLoading, error: testSpecificationError }] = useLazyQuery(TESTSPECIFICATION(location?.state?.availableTestSpecification?.testSpecificationId));
    const { data: categories, loading: categoriesLoading, error: categoriesError } = useQuery(CATEGORIES(true));
    const { data: allCategories, loading: allCategoriesLoading, error: allCategoriesError } = useQuery(ALLCATEGORIES);
    const { data: approvals, loading: approvalsLoading, error: approvalsError } = useQuery(APPROVALS(true));
    const { data: allApprovals, loading: allApprovalsLoading, error: allApprovalsError } = useQuery(ALLAPPROVALS);
    const { data: groupStandardReferences, loading: groupStandardReferencesLoading, error: groupStandardReferencesError } = useQuery(GROUPSTANDARDREFERENCES(true));
    const { data: allGroupStandardReferences, loading: allGroupStandardReferencesLoading, error: allGroupStandardReferencesError } = useQuery(ALLGROUPSTANDARDREFERENCES);

    // MUTATIONS
    const [createTestSpecification, { loading: createTestSpecificationLoading }] = useMutation(ADDTESTSPECIFICATION, {
        onCompleted: onCompleted,
        onError: handleOnError,
    });

    // FORM VALUES
    const [formValues, setFormValues] = useState({
        category: '',
        approval: '',
        groupStandardReference: '',
    });

    // MUTATIONS VALUES
    const [mutationValues, setMutationValues] = useState({
        version: newVersion,
        tag: "DRAFT",
        category: '',
        approval: '',
        groupStandardReference: '',
        description: '',
        terminationCriteria: '',
        testProtocolContents: '',
        postCondition: '',
        reference: '',
        testDuration: '',
        requirementIds: '',
    });


    // PARAMETER VALUES
    const [selectedParams, setSelectedParams] = useState([]);
    const [allParameters, setAllParameters] = useState();
    const [selectedConditions, setSelectedConditions] = useState([]);
    //Currently displayed parameters in list
    const [activeParameters, setActiveParameters] = useState([]);
    //number of currently active condition columns (starting with 1!) 
    const [conditionCounter, setConditionCounter] = useState(() => {
        return 0
    });

    //STEPS VALUES    
    const [pretreatmentSteps, setPretreatmentSteps] = useState([]);
    const [procedureSteps, setProcedureSteps] = useState([]);

    // VALIDATION CHECKS
    const [checkTestDuration, setCheckTestDuration] = useState(false);
    const [checkRequirementIds, setCheckRequirementIds] = useState(false);

    const insertSteps = (steps, stepType) => {
        if (stepType === 'PreTreatment') {
            let value = [];
            for (let i = 0; i < steps.length; i++) {
                value[i] = { number: steps[i].number, stepDescription: steps[i].stepDescription };
            }
            setPretreatmentSteps(value);
        }
        if (stepType === 'Procedure') {
            let value = [];
            for (let i = 0; i < steps.length; i++) {
                value[i] = { number: steps[i].number, stepDescription: steps[i].stepDescription };
            }
            setProcedureSteps(value);
        }
    };

    const insertParameters = (params) => {
        let allPara = JSON.parse(JSON.stringify(allParameters));
        let filterPara = [];
        let filterCond = [];
        let conditionLength = params[0]?.conditions?.length;
        if (conditionLength === undefined) {
            conditionLength = 0;
        }

        params?.map((parameter) => {
            const conditionArray = [];
            return (
                allPara.find(x => x.name === parameter.parameter.name).selected = true,
                filterPara.push(allPara.find(x => x.name === parameter.parameter.name)),
                parameter?.conditions?.map((condition) => {
                    return (
                        conditionArray.push(condition.condition)
                    )
                }),
                filterCond.push(conditionArray)
            )
        });
        setAllParameters(allPara);
        setActiveParameters(allPara);
        setSelectedParams(filterPara);
        setSelectedConditions(filterCond);
        setConditionCounter(conditionLength);
    };

    const handleAddConditionColumn = () => {
        let conditions = JSON.parse(JSON.stringify(selectedConditions));
        conditions.map(i => {
            return (
                i.push("")
            )
        });
        setSelectedConditions(conditions);
        setConditionCounter(conditionCounter + 1);
    };

    const handleRemoveParameter = (index) => {
        let params = JSON.parse(JSON.stringify(selectedParams));
        let allparams = JSON.parse(JSON.stringify(allParameters));
        let conditions = JSON.parse(JSON.stringify(selectedConditions));
        allparams.find(x => x.name === params[index].name).selected = false;
        setAllParameters(allparams);
        setActiveParameters(allparams);
        params.splice(index, 1);
        setSelectedParams(params);
        conditions.splice(index, 1);
        setSelectedConditions(conditions);
        if (params.length === 0) {
            setConditionCounter(0);
        }
    };

    const handleConditionChange = (event, index, index2) => {
        const conditions = JSON.parse(JSON.stringify(selectedConditions));
        conditions[index][index2] = event.target.value;
        if (event.target.value.length < 256) {
            setSelectedConditions(conditions);
        }
    };

    const submitState = () => {
        return (mutationValues.category < 1 || procedureSteps.length < 1 || !checkTestDuration || mutationValues.description.length > 2000 || mutationValues.terminationCriteria.length > 2000 || mutationValues.testProtocolContents.length > 2000 || mutationValues.postCondition.length > 2000 || mutationValues.reference.length > 2000)
    };

    const isLoading = () => {
        return (createTestSpecificationLoading || allApprovalsLoading || categoriesLoading || allCategoriesLoading || approvalsLoading || groupStandardReferencesLoading || allGroupStandardReferencesLoading || parametersLoading || allParametersLoading || testSpecificationLoading)
    };

    const hasError = () => {
        return !(allApprovalsError === undefined && categoriesError === undefined && allCategoriesError === undefined && approvalsError === undefined && groupStandardReferencesError === undefined && allGroupStandardReferencesError === undefined && parametersError === undefined && allParametersError === undefined && testSpecificationError === undefined)
    };

    const handleSubmit = async (e) => {
        let para = JSON.parse(JSON.stringify(selectedParams));
        let cond = JSON.parse(JSON.stringify(selectedConditions));
        let cleanedMutationParameters = [];
        for (let i = 0; i < cond.length; i++) {
            cleanedMutationParameters.push({ parameterId: para[i].parameterId, conditions: cond[i] });
        }   
        e.preventDefault();
        createTestSpecification({ variables: { name: location?.state?.searchField, version: mutationValues.version, category: mutationValues.category, procedureSteps: procedureSteps, approval: mutationValues.approval === '' ? null : mutationValues.approval, groupStandardReference: mutationValues.groupStandardReference === '' ? null : mutationValues.groupStandardReference, description: mutationValues.description, pretreatmentSteps: pretreatmentSteps, terminationCriteria: mutationValues.terminationCriteria, testProtocolContents: mutationValues.testProtocolContents, postCondition: mutationValues.postCondition, reference: mutationValues.reference, testDuration: mutationValues.testDuration === '' ? null : mutationValues.testDuration, parameters: cleanedMutationParameters, requirementIds: mutationValues.requirementIds === '' ? null : mutationValues.requirementIds } });
    };

    useEffect(() => {
        if (testSpecification !== undefined && testSpecification !== null) {
            setFormValues({ ...formValues, category: testSpecification?.testSpecification?.category?.name, approval: testSpecification?.testSpecification?.approval?.name, groupStandardReference: testSpecification?.testSpecification?.groupStandardReference?.name });
            setMutationValues({ ...mutationValues, approval: testSpecification?.testSpecification?.approval?.approvalId, groupStandardReference: testSpecification?.testSpecification?.groupStandardReference?.groupStandardReferenceId, category: testSpecification?.testSpecification?.category?.categoryId, description: testSpecification?.testSpecification?.description, terminationCriteria: testSpecification?.testSpecification?.terminationCriteria, testProtocolContents: testSpecification?.testSpecification?.testProtocolContents, postCondition: testSpecification?.testSpecification?.postCondition, reference: testSpecification?.testSpecification?.reference, testDuration: testSpecification?.testSpecification?.testDuration, requirementIds: testSpecification?.testSpecification?.requirementIds })
            insertSteps(testSpecification.testSpecification.pretreatmentSteps, 'Pre Treatment');
            insertSteps(testSpecification?.testSpecification?.procedureSteps, 'Procedure');
            insertParameters(testSpecification?.testSpecification?.parameters);
        }
    }, [testSpecification]); // eslint-disable-line

    //validation for testDuration
    useEffect(() => {
        const num = /^-?\d+$/;
        if (num.test(mutationValues.testDuration) || mutationValues.testDuration === null || mutationValues.testDuration === "") {
            setCheckTestDuration(true);
        } else {
            setCheckTestDuration(false);
        }
    }, [mutationValues.testDuration]);

    //validation for requirementIds
    useEffect(() => {
        // [0-9]{1,5}(; [0-9]{1,5})*
        const reg = /^[0-9]{1,5}(; [0-9]{1,5})*$/;
        if (reg.test(mutationValues.requirementIds) || mutationValues.requirementIds === "" || mutationValues.requirementIds === null) {
            setCheckRequirementIds(true);
        } else {
            setCheckRequirementIds(false);
        }
    }, [mutationValues?.requirementIds]);

    useEffect(() => {
        if ((parametersData !== undefined) && (allParametersData !== undefined)) {
            let parameters = [];
            // load only active parameters if new specification and all if new version
            if (newVersion > 1) {
                for (let i of allParametersData.parameters) {
                    parameters.push({ parameterId: i.parameterId, name: i.name, physicalQuantity: i.physicalQuantity.name, unit: i.unit === null ? "-" : i.unit.abbreviation, description: i.description, selected: false, active: i.active });
                }
            } else {
                for (let i of parametersData.parameters) {
                    parameters.push({ parameterId: i.parameterId, name: i.name, physicalQuantity: i.physicalQuantity.name, unit: i.unit === null ? "-" : i.unit.abbreviation, description: i.description, selected: false, active: i.active });
                }
            }
            setAllParameters(parameters);
            setActiveParameters(parameters);
            if (newVersion > 1) {
                getTestSpecData()
            }
        }
    }, [parametersData, allParametersData]); // eslint-disable-line

    const classes = useStyles();

    function CategoryDropDown({ cat, all }) {
        return (<>
            <InputLabel className={classes.input_title}>Category *</InputLabel>
            <Select
                name="category"
                value={formValues.category == null ? '' : formValues.category}                                                
                onChange={handleInputChange(setFormValues, formValues, setMutationValues, mutationValues)}
            >
                {cat.map((option) => (
                    <MenuItem
                        datamutationvalue={option.categoryId}
                        key={option.categoryId}
                        value={option.name}
                        diabled={!all ? "false" : `"${option.active}"`}
                    >
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
        </>
        )
    }

    return <>
        <div className={classes.root} >
            <Grid container spacing={3} pb={8}>
                <Grid item xs={12} display="grid" >
                    <Paper className={classes.paper}>

                        {/* ERROR */}
                        {hasError() &&
                            <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 128px - 24px - 24px)' }}>
                                <Box p={4} display="flex" alignItems="center">
                                    <Typography variant="h2">
                                        Test Specification Title
                                    </Typography>
                                </Box>
                                <TestSpecErrorMessage newVersion testSpecificationError={testSpecificationError} categoriesError={categoriesError} allCategoriesError={allCategoriesError} approvalsError={approvalsError} allApprovalsError={allApprovalsError} groupStandardReferencesError={groupStandardReferencesError} allGroupStandardReferencesError={allGroupStandardReferencesError} parametersError={parametersError} allParametersError={allParametersError} />
                            </Box>
                        }

                        {/* LOADING */}
                        {isLoading() && !hasError() &&
                            <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 64px - 24px - 24px)' }}>
                                <Box p={4} display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                                    <Typography variant="h2">
                                        Test Specification Title
                                    </Typography>
                                </Box>
                                <Box mx={4} display="flex" alignItems="center" justifyContent="center" flexGrow="1">
                                    <BatteryLoader type="standard" />
                                </Box>
                            </Box>
                        }

                        {!isLoading() && !hasError() &&
                            <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 64px - 24px - 24px)' }}>
                                <Box m={4} display="flex" flexDirection="column">
                                    {/* HEADER */}
                                    <Box pb={4} display="flex" alignItems="baseline">
                                        <Typography variant="h2">
                                            {location?.state?.searchField}
                                        </Typography>
                                        <Typography variant="subtitle1" style={{ marginLeft: '8px' }}>
                                            V. {mutationValues.version}
                                        </Typography>
                                    </Box>

                                    {/* TITLE - VERSION */}
                                    <Grid item xs={12} display="grid">
                                        <Grid container spacing={0}>
                                            {/* TITLE */}
                                            <Grid item xs={4}>
                                                <Box pr={2} display="flex" flexDirection="column">
                                                    <InputLabel className={classes.input_title}>Title *</InputLabel>
                                                    <InputBase
                                                        name="testSpecificationTitle"
                                                        value={location?.state?.searchField}
                                                        disabled={true}
                                                    />
                                                </Box>
                                            </Grid>
                                            {/* VERSION */}
                                            <Grid item xs={4}>
                                                <Box px={1} display="flex" flexDirection="column">
                                                    <InputLabel className={classes.input_title}>Version *</InputLabel>
                                                    <InputBase
                                                        name="version"
                                                        value={mutationValues.version}
                                                        disabled={true}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* CATEGORY - APPROVAL - REFERENCE */}
                                    <Grid item xs={12} display="grid" style={{ marginTop: '88px' }}>
                                        <Grid container spacing={0}>
                                            {/* CATEGORY */}
                                            <Grid item xs={4}>
                                                <Box pt={5} pr={2} display="flex" flexDirection="column">
                                                    {(newVersion === 1) && <CategoryDropDown cat={categories?.categories} />}
                                                    {(newVersion > 1) && <CategoryDropDown cat={allCategories?.categories} all />}
                                                </Box>
                                            </Grid>
                                            {/* APPROVAL */}
                                            <Grid item xs={4}>
                                                <Box pt={5} px={1} display="flex" flexDirection="column" justifyContent="center">
                                                    <InputLabel className={classes.input_title}>Approval</InputLabel>
                                                    <Select
                                                        name="approval"
                                                        value={formValues.approval == null ? '' : formValues.approval}
                                                        onChange={handleInputChange(setFormValues, formValues, setMutationValues, mutationValues)}
                                                    >
                                                        <MenuItem
                                                            value=''
                                                            datamutationvalue=''
                                                        >
                                                            <em>None</em>
                                                        </MenuItem>
                                                        {(newVersion === 1) && approvals?.approvals.map((option) => (
                                                            <MenuItem
                                                                datamutationvalue={option.approvalId}
                                                                key={option.approvalId}
                                                                value={option.name}
                                                            >
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                        {(newVersion > 1) && allApprovals?.approvals.map((option) => (
                                                            <MenuItem
                                                                datamutationvalue={option.approvalId}
                                                                key={option.approvalId}
                                                                value={option.name}
                                                                disabled={!option.active}
                                                            >
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </Box>
                                            </Grid>
                                            {/* REFERENCE */}
                                            <Grid item xs={4}>
                                                <Box pt={5} pl={2} display="flex" flexDirection="column" justifyContent="center">
                                                    <InputLabel className={classes.input_title}>Reference</InputLabel>
                                                    <Select
                                                        name="groupStandardReference"
                                                        value={formValues.groupStandardReference == null ? '' : formValues.groupStandardReference}
                                                onChange={handleInputChange(setFormValues, formValues, setMutationValues, mutationValues)}
                                                    >
                                                        <MenuItem
                                                            value=''
                                                            datamutationvalue=''
                                                        >
                                                            <em>None</em>
                                                        </MenuItem>
                                                        {(newVersion === 1) && groupStandardReferences?.groupStandardReferences.map((option) => (
                                                            <MenuItem
                                                                datamutationvalue={option.groupStandardReferenceId}
                                                                key={option.groupStandardReferenceId}
                                                                value={option.name}
                                                            >
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                        {(newVersion > 1) && allGroupStandardReferences?.groupStandardReferences.map((option) => (
                                                            <MenuItem
                                                                datamutationvalue={option.groupStandardReferenceId}
                                                                key={option.groupStandardReferenceId}
                                                                value={option.name}
                                                                disabled={!option.active}
                                                            >
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* REQ IDS*/}
                                    <Grid item xs={12} display="grid">
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <Box mt={3}>
                                                    <Box pb={0.5} display="flex" alignItems="center">
                                                        <InputLabel>Referred Requirement IDs</InputLabel>
                                                        <RequirementIdsInfo />
                                                    </Box>
                                                    <InputBase
                                                        name="requirementIds"
                                                        value={mutationValues.requirementIds == null ? '' : mutationValues.requirementIds}
                                                onChange={handleInputChangeFreeText(setMutationValues, mutationValues)}
                                                        error={!checkRequirementIds}
                                                    />
                                                    {!checkRequirementIds &&
                                                        <Typography variant="subtitle2" className={classes.alert_message}>Incorrect input format. Check info icon above for further information.</Typography>
                                                    }
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* DESCRIPTION*/}
                                    <Grid item xs={12} display="grid">
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <Box mt={3} display="flex" flexDirection="column" alignItems="flex-start">
                                                    <InputLabel className={classes.input_title}>Description</InputLabel>
                                                    <InputBase
                                                        name="description"
                                                        multiline={true}
                                                        fullWidth={true}
                                                        value={mutationValues.description == null ? '' : mutationValues.description}
                                                onChange={handleInputChangeFreeText(setMutationValues, mutationValues)}
                                                    />
                                                    {mutationValues.description.length > 2000 &&
                                                        <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 2000 characters.</Typography>
                                                    }
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* PARAMETERS & CONDITIONS */}
                                    <Box pt={4} display="flex" alignItems="center" justifyContent="space-between">
                                        <Typography variant="h3">
                                            Parameters & Conditions
                                        </Typography>
                                        <Box display="flex">
                                            {selectedParams?.length > 0 && conditionCounter < 12 &&
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={() => handleAddConditionColumn()}
                                                >
                                                    <div style={{ display: 'flex', marginRight: '8px' }}>
                                                        <Icon iconName={"AddIcon"} fill={petrol800} />
                                                    </div>
                                                    Add Condition
                                                </Button>
                                            }
                                            <ParameterSelection
                                                newParameter
                                                conditionCounter={conditionCounter}
                                                setConditionCounter={setConditionCounter}
                                                allParameters={allParameters}
                                                setAllParameters={setAllParameters}
                                                selectedParameters={selectedParams}
                                                setSelectedParameters={setSelectedParams}
                                                selectedConditions={selectedConditions}
                                                setSelectedConditions={setSelectedConditions}
                                                activeParameters={activeParameters}
                                                setActiveParameters={setActiveParameters}
                                            />
                                        </Box>
                                    </Box>

                                    <Box mt={2} display="flex" flexDirection="column" className={classes.parameter_box}>
                                        <Box pt={3} px={3} display="flex" style={conditionCounter > 0 ? { marginBottom: '0px' } : { marginBottom: '4px' }}>
                                            {selectedParams?.length < 1 &&
                                                <Box pb={2} display="flex" flexDirection="column" alignItems="flex-start">
                                                    <Box display="flex" alignItems="center">
                                                        <Typography variant="h5" className={classes.parameter_add_first}>
                                                            No parameters have been added yet.
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            }
                                            {selectedParams?.length > 0 &&
                                                <>
                                                    <Box display="flex" flexDirection="column">
                                                        <Box className={classes.condition_title}>
                                                            <Typography variant="h4" >
                                                                Parameter
                                                            </Typography>
                                                        </Box>
                                                        <Box mt={4} mb={2} display="flex" flexDirection="column" alignItems="flex-start">
                                                            {selectedParams?.map(function (i, index) {
                                                                return (
                                                                    <Box key={index} display="flex" alignItems="center" className={classes.assigned_parameter}>
                                                                        <ParameterInfo parameter={i.name} description={i.description} active={i.active} />
                                                                        <IconButton
                                                                            className={classes.error_icon_wrapper}
                                                                            onClick={() => handleRemoveParameter(index)}
                                                                        >
                                                                            <Icon iconName={"AddIcon"} fill={grey1000} />
                                                                        </IconButton>
                                                                    </Box>
                                                                );
                                                            })}
                                                        </Box>
                                                    </Box>
                                                    {conditionCounter > 0 &&
                                                        <Box overflow="auto">
                                                            <Box display="flex">
                                                                {selectedConditions[0]?.map(function (i, index) {
                                                                    return (
                                                                        <Box ml={3} key={index} style={{ width: '185px' }} display="flex" justifyContent="space-between" className={classes.condition_title}>
                                                                            <Typography variant="h4" >
                                                                                Condition {index + 1}
                                                                            </Typography>
                                                                            <IconButton
                                                                                className={classes.trash_icon_wrapper}
                                                                                onClick={() => handleRemoveConditionColumn(setSelectedConditions, selectedConditions, setConditionCounter, conditionCounter, index)}
                                                                            >
                                                                                <Icon iconName={"TrashIcon"} fill={petrol800} />
                                                                            </IconButton>
                                                                        </Box>
                                                                    );
                                                                })
                                                                }
                                                            </Box>
                                                            <Box mt={4}>
                                                                {selectedParams?.map(function (i, indexi) {
                                                                    return (
                                                                        <Box key={indexi} display="flex" alignItems="center" >
                                                                            {selectedConditions[indexi]?.map(function (j, indexj) {
                                                                                return (
                                                                                    <Box ml={3} key={indexj} className={classes.condition_box}>
                                                                                        <InputBase
                                                                                            id={`${indexi},${indexj}`}
                                                                                            name="condition"
                                                                                            value={j}
                                                                                            className={classes.condition_value}
                                                                                            onChange={event => handleConditionChange(event, indexi, indexj)}
                                                                                        >
                                                                                        </InputBase>
                                                                                        <Typography variant="h5" className={classes.condition_unit}>
                                                                                            {i.unit}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                )
                                                                            })}
                                                                        </Box>
                                                                    )
                                                                })}
                                                            </Box>
                                                        </Box>
                                                    }
                                                </>
                                            }
                                        </Box>
                                    </Box>

                                    {/* PRE TREATMENT STEPS */}
                                    <Box id="search-node">
                                        <Box mt={4} display="flex" flexDirection="column" className={classes.steps_box}>
                                            <Grid item xs={12}>
                                                <Box mx={3} my={3} display="flex">
                                                    <Typography variant="h3">
                                                        Pre Treatment / Pre Condition Steps
                                                    </Typography>
                                                </Box>
                                                {pretreatmentSteps.map(function (i, index) {
                                                    return (
                                                        <StepDescription entry="create" key={index} steps={pretreatmentSteps} setSteps={setPretreatmentSteps} step={i} stepIndex={index} selectedParameters={selectedParams} category={'Pre Treatment'} mutationValues={mutationValues} />
                                                    )
                                                })}
                                                <AddNewStep entry="create" selectedParameters={selectedParams} steps={pretreatmentSteps} setSteps={setPretreatmentSteps} category={'Pre Treatment'} />
                                            </Grid>
                                        </Box>

                                        {/* PROCEDURE STEPS */}
                                        <Box mt={4} display="flex" flexDirection="column" className={classes.steps_box}>
                                            <Grid item xs={12}>
                                                <Box mx={3} my={3} display="flex">
                                                    <Typography variant="h3">
                                                        Procedure Steps *
                                                    </Typography>
                                                </Box>
                                                {procedureSteps.map(function (i, index) {
                                                    return (
                                                        <StepDescription entry="create" key={index} steps={procedureSteps} setSteps={setProcedureSteps} step={i} stepIndex={index} selectedParameters={selectedParams} category={'Procedure'} mutationValues={mutationValues} />
                                                    )
                                                })}
                                                <AddNewStep entry="create" selectedParameters={selectedParams} steps={procedureSteps} setSteps={setProcedureSteps} category={'Procedure'} />
                                            </Grid>
                                        </Box>
                                    </Box>

                                    {/* TERMINATION CRITERIA - TEST PROTOCOAL CONTENTS */}
                                    <Grid item xs={12} display="grid">
                                        <Grid container spacing={0}>
                                            {/* TERMINATION CRITERIA */}
                                            <Grid item xs={6}>
                                                <Box pt={4} pr={1.5} display="flex" flexDirection="column" alignItems="flex-start">
                                                    <InputLabel className={classes.input_title}>Termination Criteria</InputLabel>
                                                    <InputBase
                                                        name="terminationCriteria"
                                                        multiline={true}
                                                        fullWidth={true}
                                                        value={mutationValues.terminationCriteria == null ? '' : mutationValues.terminationCriteria}
                                                onChange={handleInputChangeFreeText(setMutationValues, mutationValues)}
                                                    />
                                                    {mutationValues.terminationCriteria.length > 2000 &&
                                                        <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 2000 characters.</Typography>
                                                    }
                                                </Box>
                                            </Grid>
                                            {/* TEST PROTOCOAL CONTENTS */}
                                            <Grid item xs={6}>
                                                <Box pt={4} pl={1.5} display="flex" flexDirection="column" alignItems="flex-start">
                                                    <InputLabel className={classes.input_title}>Test Protocol Contents / Measurement Values</InputLabel>
                                                    <InputBase
                                                        name="testProtocolContents"
                                                        multiline={true}
                                                        fullWidth={true}
                                                        value={mutationValues.testProtocolContents == null ? '' : mutationValues.testProtocolContents}
                                                onChange={handleInputChangeFreeText(setMutationValues, mutationValues)}
                                                    />
                                                    {mutationValues.testProtocolContents.length > 2000 &&
                                                        <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 2000 characters.</Typography>
                                                    }
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* POST CONDITION - REFERENCE */}
                                    <Grid item xs={12} display="grid">
                                        <Grid container spacing={0}>
                                            {/* POST CONDITION */}
                                            <Grid item xs={6}>
                                                <Box pt={3} pr={1.5} display="flex" flexDirection="column" alignItems="flex-start">
                                                    <InputLabel className={classes.input_title}>Post Condition</InputLabel>
                                                    <InputBase
                                                        name="postCondition"
                                                        multiline={true}
                                                        fullWidth={true}
                                                        value={mutationValues.postCondition == null ? '' : mutationValues.postCondition}
                                                onChange={handleInputChangeFreeText(setMutationValues, mutationValues)}
                                                    />
                                                    {mutationValues.postCondition.length > 2000 &&
                                                        <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 2000 characters.</Typography>
                                                    }
                                                </Box>
                                            </Grid>
                                            {/* REFERENCE */}
                                            <Grid item xs={6}>
                                                <Box pt={3} pl={1.5} display="flex" flexDirection="column" alignItems="flex-start">
                                                    <InputLabel className={classes.input_title}>Reference</InputLabel>
                                                    <InputBase
                                                        name="reference"
                                                        multiline={true}
                                                        fullWidth={true}
                                                        value={mutationValues.reference == null ? '' : mutationValues.reference}
                                                onChange={handleInputChangeFreeText(setMutationValues, mutationValues)}
                                                    />
                                                    {mutationValues.reference.length > 2000 &&
                                                        <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 2000 characters.</Typography>
                                                    }
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* TEST DURATION */}
                                    <Box pt={3} display="flex" flexDirection="column" alignItems="flex-start" style={{ maxWidth: '25%' }}>
                                        <InputLabel className={classes.input_title}>Test Duration Estimate</InputLabel>
                                        <InputBase
                                            name="testDuration"
                                            value={mutationValues.testDuration == null ? '' : mutationValues.testDuration}
                                            onChange={handleInputChangeFreeText(setMutationValues, mutationValues)}
                                            endAdornment={<InputAdornment position="end">{mutationValues.testDuration === '1' ? 'Day' : 'Days'}</InputAdornment>}
                                            error={!checkTestDuration}
                                        />
                                        {!checkTestDuration &&
                                            <Typography variant="subtitle2" className={classes.alert_message}>Incorrect input format. Only integer values allowed.</Typography>
                                        }
                                    </Box>

                                </Box>
                            </Box>
                        }
                    </Paper>
                </Grid>
            </Grid>
            <StickyBar
                backButton
                backpath={`/`}
                requiredField
                ksuNotification
                customButton={`Save new ${title}`}
                customOnClick={handleSubmit}
                customDisabled={submitState()}
                scrollButton
            />
        </div >
    </>;
}
export default CreateNewSpecification;