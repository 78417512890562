import { Box, IconButton, Typography, Button } from "@mui/material";
import { useStyles } from './CellDataSheet.styles.js';
import React, { useContext, useState } from "react";
import Icon from "../../../assets/icons/Icon";
import LockComponent from "../../other/dialogs/LockComponent/LockComponent";
import BatteryLoader from "../../other/helper/BatteryLoader/BatteryLoader";
import { buttonPressedPetrol800, petrol800 } from "../../other/helper/MuiStyles";
import { handleSampleName, handleSubSampleName } from '../ProjectDetails/samplefunctions.js';
import SampleState from "../SampleState";
import { CDSContext } from "../../other/contexts/CDSContext.js";
import { projectRole, sampleVar, subSampleVar } from '../../../operations/localeStorage';
import { EditSoc, EditTemperature, EditPulseTime } from "../../other/dialogs/EditSocTempPulse/EditSocTempPulse.js";

export default function CellDataSheet(
    {
        selectedSample, selectedSubSample, setSelectedSample, setSelectedSubSample, selectedIndex, setSelectedIndex, setFormSample,
        formSample, project, projectError, projectLoading, cellDataSheetData, subSampleId, isProjectLocked
    }
) {

    const role = projectRole();

    const [samplesOpen, setSamplesOpen] = useState(false);
    const [editCDSOpen, setEditCDSOpen] = useState(false);
    const { currentPowerOCV } = useContext(CDSContext);

    const classes = useStyles();

    const handleEditCDSClick = () => {
        setEditCDSOpen(!editCDSOpen);
    };

    let unique_socs = [...new Set(cellDataSheetData?.cellDataSheet[0]?.temperatures[0]?.socs?.map(i => i.soc))];
    let unique_temps = [...new Set(cellDataSheetData?.cellDataSheet[0]?.temperatures?.map(i => i.temperature))];
    let unique_measurement = currentPowerOCV === 'POWER_LIMITS' ? [...new Set(cellDataSheetData?.cellDataSheet?.map(i => i.pulseTime))] : [...new Set(cellDataSheetData?.cellDataSheet?.map(i => i.agingStatus))];

    return (
        <>
            <Box id="samplestates" mx={4} py={4} display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                <Typography variant="h2" className={classes.subheader}>
                    Cell Data Sheet
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Box>
                        {(role === 'Administrator' || role === 'Project User') &&
                            <LockComponent headline={'Cell Data Sheet'} subSampleId={subSampleId} lock={formSample === undefined ? true : !(formSample[sampleVar()]?.subSamples[subSampleVar()]?.isCellDataSheetLocked)} isLockDisabled={isProjectLocked} />
                        }
                        {(role === 'Administrator' || role === 'Project User') &&
                            <Button
                                variant="outlined"
                                color="secondary"
                                disabled={formSample === undefined ? true : (formSample[sampleVar()]?.subSamples[subSampleVar()]?.isCellDataSheetLocked)}
                                onClick={handleEditCDSClick}
                                style={editCDSOpen ? { backgroundColor: buttonPressedPetrol800 } : {}}
                                data-testid={"edit-grid-points-button"}
                            >
                                <div style={{ marginRight: '8px', display: 'flex' }}>
                                    <Icon iconName={'EditIcon'} fill={petrol800} />
                                </div>
                                Edit Grid Points
                            </Button>
                        }
                        <IconButton className={classes.arrow_hover} onClick={() => { setSamplesOpen(!samplesOpen) }} style={{ marginLeft: '24px' }} >
                            <Icon
                                fill={petrol800}
                                iconName={samplesOpen ? "ArrowUpIcon" : "ArrowDownIcon"}
                                width={24}
                                height={24}

                            />
                        </IconButton>
                    </Box>
                </Box>

            </Box>

            {

                <>
                    <Box display="flex" justifyContent={samplesOpen ? 'flex-end' : 'space-between'}>
                        {!samplesOpen &&
                            <Box ml={4} mb={4} display="flex">
                                <Box className={classes.normalButton}>{handleSampleName(selectedSample)}</Box>
                                <Box className={classes.normalButton}>{selectedSubSample}-{handleSubSampleName()}</Box>
                            </Box>
                        }
                        {editCDSOpen &&
                            <Box mx={4} pb={4} display="flex" alignItems="center" justifyContent="flex-end" flexWrap="wrap">
                                <EditSoc values={unique_socs} subSampleId={subSampleId} />
                                <EditTemperature values={unique_temps} subSampleId={subSampleId} />
                                {currentPowerOCV === 'POWER_LIMITS' &&
                                    <EditPulseTime values={unique_measurement} subSampleId={subSampleId} />
                                }
                            </Box>
                        }
                    </Box>
                    {samplesOpen && <Box></Box>}

                    {samplesOpen &&
                        <Box display="flex" flexDirection="column" mx={4} mb={4}>
                            {projectError === undefined && !projectLoading && formSample !== undefined &&
                                <SampleState
                                    projectId={project?.project?.projectId}
                                    formSample={formSample}
                                    setFormSample={setFormSample}
                                    selectedSample={selectedSample}
                                    setSelectedSample={setSelectedSample}
                                    setSelectedSubSample={setSelectedSubSample}
                                    selectedIndex={selectedIndex}
                                    setSelectedIndex={setSelectedIndex}
                                    projectLoading={projectLoading}
                                />
                            }
                            {
                                (projectLoading) &&
                                (projectError === undefined) &&
                                <>
                                    <Box mt={8} mb={4} display="flex" alignItems="center" justifyContent="center">
                                        <BatteryLoader type="standard" />
                                    </Box>
                                </>
                            }
                        </Box>
                    }
                </>
            }
        </>
    )
}