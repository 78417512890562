const isKeyEqual = (key, val1, val2) => {
    switch(key) {
        case 'requirementIds':
            return val1 === val2 || ['', null].includes(val1) && ['', null].includes(val2)
        case 'startDate':
        case 'plannedStartDate':
        case 'endDate':
        case 'plannedEndDate':
            return (new Date(val1)).getTime() === (new Date(val2)).getTime()
        default:
            return val1 === val2
    }
}

export const areTestCasesEqual = (testCase1, testCase2) => {
    return Object.keys(testCase1).reduce(
        (acc, val) => acc && isKeyEqual(val, testCase1[val], testCase2[val]),
        true
      )
}