import React from 'react';
import Box from '@mui/material/Box';
import { HtmlTooltip } from '../HtmlTooltip/HtmlTooltip';
import { useStyles } from '../Tooltip.styles';
import Icon from '../../../../assets/icons/Icon';
import { grey600 } from '../../helper/MuiStyles';

export default function DutOverview(props) {
    const { duts, icon } = props;
    const classes = useStyles();

    return (
        <HtmlTooltip
            title={
                <Box display="flex " flexDirection="column" justifyContent="space-between">
                    <Box display="flex " flexDirection="row" px={1} pt={1}>
                        <Box className={classes.title} style={{ minWidth: '80px' }}>Planned:</Box>
                        <Box ml={0.5} className={classes.value}>{duts.planned}</Box>
                    </Box>
                    <Box display="flex " flexDirection="row" px={1} pt={1}>
                        <Box className={classes.title} style={{ minWidth: '80px' }}>Started:</Box>
                        <Box ml={0.5} className={classes.value}>{duts.started}</Box>
                    </Box>
                    <Box display="flex " flexDirection="row" p={1}>
                        <Box className={classes.title} style={{ minWidth: '80px' }}>Finished:</Box>
                        <Box ml={0.5} className={classes.value}>{duts.finished}</Box>
                    </Box>
                </Box>
            }
        >
            {!icon ? <span style={{ 'cursor': 'help' }}>{props.duts.planned + '/' + props.duts.started + '/' + props.duts.finished}</span> : <span style={{ cursor: 'help', display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '8px' }}><Icon iconName={"InfoIcon"} fill={grey600}/></span>}
        </HtmlTooltip>
    )
}
