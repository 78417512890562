import React, { useState, useEffect, useContext } from 'react';
import { Button, Dialog, IconButton, DialogContent, Box, Typography, InputBase } from '@mui/material';
import { useStyles } from '../UniversalDialog.styles.js';
import Icon from '../../../../assets/icons/Icon.js';
import { SnackbarContext, SnackbarType } from '../../contexts/SnackBarContext.js';
import { grey1000, petrol800, buttonHoverGrey800, petrol600 } from '../../helper/MuiStyles.js';

function AddEditUnit(props) {
    const {
        edit,
        headline,
        unit,
        mutateFunction,
        maxCharacterName,
        maxCharacterAbbreviation,
        activeValues,
        inactiveValues,
        queryName,
    } = props;
    const { setIsSnackbarOpen, setMessage, setSnackbarType } = useContext(SnackbarContext);

    const [show, setShow] = useState(false);
    const [newName, setNewName] = useState("");
    const [newAbbreviation, setNewAbbreviation] = useState("");
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [maxCharNameExceeded, setMaxCharNameExceeded] = useState(false);
    const [maxCharAbbreviationExceeded, setMaxCharAbbreviationExceeded] = useState(false);
    const [alreadyActive, setAlreadyActive] = useState(false);
    const [alreadyInactive, setAlreadyInactive] = useState(false);

    useEffect(() => {
        if (activeValues !== undefined && inactiveValues !== undefined) {
            setSaveDisabled(false);
            setMaxCharNameExceeded(false);
            setMaxCharAbbreviationExceeded(false);
            setAlreadyActive(false);
            setAlreadyInactive(false);

            if (newName.length > maxCharacterName) {
                setSaveDisabled(true);
                setMaxCharNameExceeded(true);
            }

            if (newAbbreviation.length > maxCharacterAbbreviation) {
                setSaveDisabled(true);
                setMaxCharAbbreviationExceeded(true);
            }

            if (newAbbreviation === unit?.abbreviation && newName === unit?.name) {
                setSaveDisabled(true);
            }

            for (let i = 0; i < activeValues[queryName]?.length; i++) {
                if (activeValues[queryName][i]['abbreviation'] === newAbbreviation) {
                    if (activeValues[queryName][i]['name'].toUpperCase() === newName.toUpperCase()) {
                        setSaveDisabled(true);
                        if (newAbbreviation !== unit?.abbreviation && newName !== unit?.name) {
                            setAlreadyActive(true);
                        }
                        break;
                    }
                }
            }

            for (let i = 0; i < inactiveValues[queryName]?.length; i++) {
                if (inactiveValues[queryName][i]['abbreviation'] === newAbbreviation) {
                    if (inactiveValues[queryName][i]['name'].toUpperCase() === newName.toUpperCase()) {
                        setSaveDisabled(true);
                        setAlreadyInactive(true);
                        break;
                    }
                }
            }
        }
        else if (activeValues === undefined || inactiveValues === undefined) {
            setSaveDisabled(true);
        }
    }, [activeValues, inactiveValues, newAbbreviation, newName]) // eslint-disable-line

    useEffect(() => {
        if (unit !== undefined) {
            setNewName(unit.name);
            setNewAbbreviation(unit.abbreviation);
        }
    }, [unit]) // eslint-disable-line

    const ToggleModal = () => {
        setShow(!show);
    };

    const handleCancel = () => {
        if (edit === true) {
            setNewName(unit.name);
            setNewAbbreviation(unit.abbreviation);
        } else {
            setNewName("");
            setNewAbbreviation("");
        }
        ToggleModal();
    };

    const handleNameChange = () => (event) => {
        setNewName(event.target.value);
    };

    const handleAbbreviationChange = () => (event) => {
        setNewAbbreviation(event.target.value);
    };

    const handleSave = () => {
        mutateFunction({ variables: { id: unit?.unitId, name: newName, abbreviation: newAbbreviation, active: true } });
        setNewName("");
        setNewAbbreviation("");
        setMessage(`${headline} has been updated.`);
        setSnackbarType(SnackbarType.SUCCESS);
        setIsSnackbarOpen(true);
        setShow(false);
    };

    const addData = () => {
        mutateFunction({ variables: { name: newName, abbreviation: newAbbreviation } });
        setNewName("");
        setNewAbbreviation("");
        setMessage(`${headline} has been added.`);
        setSnackbarType(SnackbarType.SUCCESS);
        setIsSnackbarOpen(true);
        setShow(false);
    };

    const styleProps = {
        editIconWrapperMarginLeft: '8px',
        editIconHoverBackground: buttonHoverGrey800,
        addButtonMarginBottom: '16px',
        addButtonBackgroundColor: '#FFF',
    };

    const classes = useStyles(styleProps);

    return (
        <>
            {edit ?
                <IconButton className={classes.edit_icon_wrapper} onClick={ToggleModal} size="large">
                    <Icon iconName={"EditIcon"} fill={grey1000} />
                </IconButton>
                :
                <Button variant="contained" color="secondary" className={classes.add_button} onClick={ToggleModal}>
                    <div style={{ display: 'flex', marginRight: '8px' }}><Icon iconName={"AddIcon"} fill={petrol600} /></div> Add more
                </Button>
            }

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={show}
                onClose={handleCancel}
            >
                <DialogContent>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h2">
                            {edit ? 'Edit' : 'Add'} {edit ? `${unit?.abbreviation} (${unit?.name})` : headline}
                        </Typography>
                        <IconButton onClick={handleCancel} className={classes.hover_blue}>
                            <Icon iconName={"CloseIcon"} fill={petrol800} />
                        </IconButton>
                    </Box>
                    <Box mt={2} display="flex" flexDirection="column" alignItems="flex-start">
                        <Typography variant="h4" style={{ fontWeight: '400', textAlign: 'left' }}>
                            Please assign a new abbreviation and name to this {headline}.
                        </Typography>
                        {newName.length > 0 && newAbbreviation.length > 0 &&
                            <Typography variant="h4" style={{ fontWeight: '400', textAlign: 'left' }}>
                                New {headline} will look as follows: {newAbbreviation} ({newName}).
                            </Typography>
                        }
                    </Box>
                    <Box mt={3} display="flex" justifyContent="space-between">
                        <Box display='flex' flexDirection='column' justifyContent="flex-start" flexGrow="1">
                            <Typography variant="h6" className={classes.index_title}>
                                Abbreviation *
                            </Typography>
                            <InputBase
                                name="newValue"
                                className={classes.box_freeText}
                                multiline={true}
                                fullWidth={true}
                                placeholder="Add new abbreviation"
                                value={newAbbreviation}
                                onChange={handleAbbreviationChange()}
                                error={maxCharAbbreviationExceeded}
                            />
                        </Box>
                    </Box>
                    {maxCharAbbreviationExceeded &&
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="subtitle2" className={classes.alert_message}>The abbreviation must not contain more than {maxCharacterAbbreviation} characters.</Typography>
                            <Typography variant="subtitle2" className={classes.alert_message}>{newAbbreviation.length}/{maxCharacterAbbreviation}</Typography>
                        </Box>
                    }
                    <Box mt={3} display="flex" justifyContent="space-between">
                        <Box display='flex' flexDirection='column' justifyContent="flex-start" flexGrow="1">
                            <Typography variant="h6" className={classes.index_title}>
                                Name *
                            </Typography>
                            <InputBase
                                name="newValue"
                                className={classes.box_freeText}
                                multiline={true}
                                fullWidth={true}
                                placeholder="Add new name"
                                value={newName}
                                onChange={handleNameChange()}
                                error={maxCharNameExceeded}
                            />
                        </Box>
                    </Box>
                    {maxCharNameExceeded &&
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="subtitle2" className={classes.alert_message}>The name must not contain more than {maxCharacterName} characters.</Typography>
                            <Typography variant="subtitle2" className={classes.alert_message}>{newName.length}/{maxCharacterName}</Typography>
                        </Box>
                    }
                    {alreadyActive &&
                        <Box display="flex">
                            <Typography variant="subtitle2" className={classes.alert_message}>This Unit is already in use by another active {headline}.</Typography>
                        </Box>
                    }
                    {alreadyInactive &&
                        <Box display="flex" flexWrap="wrap">
                            <Typography variant="subtitle2" className={classes.alert_message}>This Unit already belongs to an inactive {headline}. <br /> Please reactivate that instead.</Typography>
                        </Box>
                    }
                    <Box mt={4} display="flex" alignItems="center">
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={newAbbreviation?.length < 1 || newName?.length < 1 || saveDisabled}
                            onClick={edit ? handleSave : addData}
                        >
                            Save
                        </Button>
                        <Typography variant="h6" className={classes.required_tag}>
                            * Mandatory field
                        </Typography>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AddEditUnit;