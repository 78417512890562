import React, { useRef } from "react";
import { useStyles } from "./ReleaseReport.styles";
import { PDFExport } from "@progress/kendo-react-pdf";
import { Box } from "@mui/system";
import { Backdrop, Button, Typography } from "@mui/material";
import Icon from "../../../../../assets/icons/Icon";
import { petrol800, grey600, grey200 } from "../../../../other/helper/MuiStyles";
import { IMAGE_MAP } from "../../../../other/helper/ImgMapper/ImgMapper";
import { CREATEREPORT } from "../../../../../operations/queries/query";
import { useQuery } from "@apollo/client";
import { currentIndexId } from "../../../../../operations/localeStorage";
import BatteryLoader from "../../../../other/helper/BatteryLoader/BatteryLoader";
import { TestResultProgressBars } from "./TestResultProgressBars";
import { TableBody, TableHead } from "./Table";
import { PageTemplate } from "./PageTemplate";

function ReleaseReport({ project, selectedSubSample, selectedIndex, testCases }) {
  const table = React.useRef(null);

  const { data: reportData, loading: reportDataLoading } = useQuery(CREATEREPORT(currentIndexId()), {
    fetchPolicy: "network-only", skip: testCases === undefined || currentIndexId() === undefined
  });

  const pdfExportComponent = useRef(null);

  const exportPDFWithComponent = async () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  // console.log("project.platforms", project?.platforms);
  
  // const unique_platforms = project?.platforms.map(platform => platform.name).join('_');  
  // console.log("unique_platforms", unique_platforms);

  const myDateString = new Date().getFullYear() + '' + ('0' + (new Date().getMonth() + 1)).slice(-2) + '' + ('0' + (new Date().getDate())).slice(-2);

  const styleProps = {
    supplierImg: IMAGE_MAP.SUPPLIER[project?.cellSupplier?.name]
  };

  const classes = useStyles(styleProps);

  const ExtendedPageTemplate = props => (
    <PageTemplate {...props} project={project} />
  );

  return (
    <div>
      <Backdrop
        sx={{ backgroundColor: 'rgba(0,53,77, 0.90)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={false}
      >
        <BatteryLoader type="backdrop" />
      </Backdrop>
      <Box>
        <Button variant="contained" color="primary" onClick={exportPDFWithComponent} disabled={reportDataLoading || (project === undefined) || (testCases === undefined)}>
          Export Release Report
        </Button>
      </Box>
      <div className="border rounded p-2">
        <div
          style={{
            position: "absolute",
            left: "-1000px",
            top: 0
          }}
        >
          <PDFExport
            forcePageBreak=".page-break"
            paperSize="A4"
            margin={{ top: "1.75cm", left: '1cm', right: '1cm', bottom: '1.75cm' }}
            ref={pdfExportComponent}
            fileName={`${myDateString}_${project?.platforms.map(platform => platform.name).join('_')}_${project?.cellSupplier?.name}_${project?.nominalCapacity}Ah_${selectedSubSample}_${selectedIndex}`}
            author="TONIC42"
            landscape={true}
            repeatHeaders={true}
            pageTemplate={ExtendedPageTemplate}
          >
            <div className={classes.my_content}>
              <div className="row">
                {/* Box with Meta Information */}
                <Box style={{ border: `1px solid ${grey200}` }}>
                  {/* NAME+ ID + LOGO */}
                  <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                    <Box display="flex" flexDirection="row" alignItems="baseline" mx={1.5} pt={1} pb={0.5}>
                      <Box style={{ fontWeight: 'bold', fontSize: '14px' }}>
                        {project?.name}
                      </Box>
                      <Typography variant="subtitle1" style={{ marginLeft: '8px', fontSize: '10px' }}>
                        ID {project?.projectId}
                      </Typography>
                    </Box>
                    <Box mx={1.5} display="flex">
                      <div className={classes.supplierimage} title="SupplierLogo"></div>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="row">
                    {/* SubSample */}
                    <Box ml={1.5} display="flex" alignItems="center" style={{ color: petrol800 }} className={classes.normalButton}>
                      <Box>
                        {selectedSubSample}-Sample
                      </Box>
                    </Box>
                    {/* Index */}
                    <Box display="flex" alignItems="center" style={{ color: petrol800 }} className={classes.normalButton}>
                      <Box>
                        Index {selectedIndex}
                      </Box>
                    </Box>
                  </Box>

                  {/* Platform + Leading Development + Specification */}
                  <Box display="flex">
                    <Box ml={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" className={classes.meta_width}>
                      <Box mr={1} display="flex">
                        <Icon iconName={"CarIcon"} fill={grey600} width="15px" />
                        <Box className={classes.meta_title} ml={1}>Vehicle Platform:</Box>
                      </Box>
                      <Box className={classes.meta_value}>
                        {project?.platforms.map((platform, index) => {
                          return (
                            <span key={platform.platformId}>
                              {(index ? ', ' : '') + platform.name}
                            </span>
                          )
                        })}
                      </Box>
                    </Box>
                    <Box ml={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" className={classes.meta_width}>
                      <Box mr={1} display="flex">
                        <Icon iconName={"GlobeIcon"} fill={grey600} width="15px" />
                        <Box className={classes.meta_title} ml={1}>Leading Development:</Box>
                      </Box>
                      <Box className={classes.meta_value}>
                        {project?.leadingDevelopment?.name}
                      </Box>
                    </Box>
                    <Box ml={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" className={classes.meta_width}>
                      <Box mr={1} display="flex">
                        <Icon iconName={"GlobeIcon"} fill={grey600} width="15px" />
                        <Box className={classes.meta_title} ml={1}>Specification:</Box>
                      </Box>
                      <Box className={classes.meta_value}>
                        &nbsp; {project?.cellSpecification}
                      </Box>
                    </Box>
                  </Box>
                  {/* Cell Capacity + SOP */}
                  <Box display="flex">
                    <Box ml={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" className={classes.meta_width}>
                      <Box mr={1} display="flex">
                        <Icon iconName={"BatteryIcon"} fill={grey600} width="15px" />
                        <Box className={classes.meta_title} ml={1}>Cell Capacity:</Box>
                      </Box>
                      <Box className={classes.meta_value}>
                        {project?.nominalCapacity} Ah
                      </Box>
                    </Box>
                    <Box ml={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" className={classes.meta_width}>
                      <Box mr={1} display="flex">
                        <Icon iconName={"BatteryIcon"} fill={grey600} width="15px" />
                        <Box className={classes.meta_title} ml={1}>Start of Production:</Box>
                      </Box>
                      <Box className={classes.meta_value}>
                        {project?.sopWeek} / {project?.sopYear}
                      </Box>
                    </Box>
                    <Box ml={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" className={classes.meta_width}>
                      <Box mr={1} display="flex">
                        <Icon iconName={"GlobeIcon"} fill={'transparent'} width="15px" />
                        <Box className={classes.meta_title} ml={1}>&nbsp;</Box>
                      </Box>
                      <Box className={classes.meta_value}>
                        &nbsp;
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {/* Test Result Bars */}
                <Box mt={1}>
                  Test Results Overall
                </Box>
                <Box className={classes.single_bar_container}>
                  <TestResultProgressBars category="All Tests" single
                    passedTests={reportData?.createReport?.categoryTestScores.map(item => item.testScore).map(i => i.testsPassed).reduce((prev, curr) => prev + curr, 0)}
                    failedTests={reportData?.createReport?.categoryTestScores.map(item => item.testScore).map(i => i.testsFailed).reduce((prev, curr) => prev + curr, 0)}
                    notEvaluatedTests={reportData?.createReport?.categoryTestScores.map(item => item.testScore).map(i => i.testsNotEvaluated).reduce((prev, curr) => prev + curr, 0)}
                    notAvailableTests={reportData?.createReport?.categoryTestScores.map(item => item.testScore).map(i => i.testsNotAvailable).reduce((prev, curr) => prev + curr, 0)}
                  />
                </Box>
                <Box mt={1} className={classes.seperator}></Box>
                {/* ----------------------------------------- */}
                <Box display="flex">
                  <Box className={classes.dual_bar_container}>
                    <TestResultProgressBars category="Safety"
                      passedTests={reportData?.createReport?.categoryTestScores?.filter(i => i.category === "Safety")[0]?.testScore?.testsPassed}
                      failedTests={reportData?.createReport?.categoryTestScores?.filter(i => i.category === "Safety")[0]?.testScore?.testsFailed}
                      notEvaluatedTests={reportData?.createReport?.categoryTestScores?.filter(i => i.category === "Safety")[0]?.testScore?.testsNotEvaluated}
                      notAvailableTests={reportData?.createReport?.categoryTestScores?.filter(i => i.category === "Safety")[0]?.testScore?.testsNotAvailable}
                    />
                  </Box>
                  <Box className={classes.dual_bar_container}>
                    <TestResultProgressBars category="Electrical" right
                      passedTests={reportData?.createReport?.categoryTestScores?.filter(i => i.category === "Electrical")[0]?.testScore?.testsPassed}
                      failedTests={reportData?.createReport?.categoryTestScores?.filter(i => i.category === "Electrical")[0]?.testScore?.testsFailed}
                      notEvaluatedTests={reportData?.createReport?.categoryTestScores?.filter(i => i.category === "Electrical")[0]?.testScore?.testsNotEvaluated}
                      notAvailableTests={reportData?.createReport?.categoryTestScores?.filter(i => i.category === "Electrical")[0]?.testScore?.testsNotAvailable}
                    />
                  </Box>
                </Box>
                <Box display="flex">
                  <Box className={classes.dual_bar_container}>
                    <TestResultProgressBars category="Durability"
                      passedTests={reportData?.createReport?.categoryTestScores?.filter(i => i.category === "Durability")[0]?.testScore?.testsPassed}
                      failedTests={reportData?.createReport?.categoryTestScores?.filter(i => i.category === "Durability")[0]?.testScore?.testsFailed}
                      notEvaluatedTests={reportData?.createReport?.categoryTestScores?.filter(i => i.category === "Durability")[0]?.testScore?.testsNotEvaluated}
                      notAvailableTests={reportData?.createReport?.categoryTestScores?.filter(i => i.category === "Durability")[0]?.testScore?.testsNotAvailable}
                    />
                  </Box>
                  <Box className={classes.dual_bar_container}>
                    <TestResultProgressBars category="Mechanical" right
                      passedTests={reportData?.createReport?.categoryTestScores?.filter(i => i.category === "Mechanical")[0]?.testScore?.testsPassed}
                      failedTests={reportData?.createReport?.categoryTestScores?.filter(i => i.category === "Mechanical")[0]?.testScore?.testsFailed}
                      notEvaluatedTests={reportData?.createReport?.categoryTestScores?.filter(i => i.category === "Mechanical")[0]?.testScore?.testsNotEvaluated}
                      notAvailableTests={reportData?.createReport?.categoryTestScores?.filter(i => i.category === "Mechanical")[0]?.testScore?.testsNotAvailable}
                    />
                  </Box>
                </Box>
                <Box display="flex">
                  <Box className={classes.dual_bar_container}>
                    <TestResultProgressBars category="Environmental"
                      passedTests={reportData?.createReport?.categoryTestScores?.filter(i => i.category === "Environmental")[0]?.testScore?.testsPassed}
                      failedTests={reportData?.createReport?.categoryTestScores?.filter(i => i.category === "Environmental")[0]?.testScore?.testsFailed}
                      notEvaluatedTests={reportData?.createReport?.categoryTestScores?.filter(i => i.category === "Environmental")[0]?.testScore?.testsNotEvaluated}
                      notAvailableTests={reportData?.createReport?.categoryTestScores?.filter(i => i.category === "Environmental")[0]?.testScore?.testsNotAvailable}
                    />
                  </Box>
                  <Box className={classes.dual_bar_container}>
                    <TestResultProgressBars category="Other" right
                      passedTests={reportData?.createReport?.categoryTestScores?.filter(i => i.category === "Other")[0]?.testScore?.testsPassed}
                      failedTests={reportData?.createReport?.categoryTestScores?.filter(i => i.category === "Other")[0]?.testScore?.testsFailed}
                      notEvaluatedTests={reportData?.createReport?.categoryTestScores?.filter(i => i.category === "Other")[0]?.testScore?.testsNotEvaluated}
                      notAvailableTests={reportData?.createReport?.categoryTestScores?.filter(i => i.category === "Other")[0]?.testScore?.testsNotAvailable}
                    />
                  </Box>
                </Box>

                {/* TABLES */}
                <div className="page-break"></div>
                <table className={classes.table} ref={table}>
                  <TableHead title="Not Available Test Results" />
                  <TableBody testResults={reportData?.createReport?.testCases?.filter(i => i.testResult.name === "NOT_AVAILABLE")[0]} />
                </table>

                <div className="page-break"></div>
                <table className={classes.table} ref={table}>
                  <TableHead title="Passed Test Results" />
                  <TableBody testResults={reportData?.createReport?.testCases?.filter(i => i.testResult.name === "PASSED")[0]} />
                </table>

                <div className="page-break"></div>
                <table className={classes.table} ref={table}>
                  <TableHead title="Failed Test Results" />
                  <TableBody testResults={reportData?.createReport?.testCases?.filter(i => i.testResult.name === "FAILED")[0]} />
                </table>

                <div className="page-break"></div>
                <table className={classes.table} ref={table}>
                  <TableHead title="Not Evaluated Test Results" />
                  <TableBody testResults={reportData?.createReport?.testCases?.filter(i => i.testResult.name === "NOT_EVALUATED")[0]} />
                </table>

                {/* Comments and Signatures */}
                <Box mb={2} className="page-break">
                  Your Comments
                </Box>
                <div className={classes.comments_field}>
                </div>
                <Box my={2} >
                  Signatures
                </Box>
                <Box display="flex" justifyContent="space-between">
                  {[...Array(5)].map((n, index) => {
                    return (
                      <div key={index} className={classes.signature_box}>
                        <div className={classes.signature_field}>Digital Signature</div>
                      </div>
                    )
                  })}
                </Box>
              </div>
            </div>
          </PDFExport>
        </div>
      </div >
    </div>
  )
}

export default ReleaseReport;