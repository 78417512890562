import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Link } from "react-router-dom";

import Icon from '../../assets/icons/Icon.js';
import { grey600, petrol800 } from '../other/helper/MuiStyles.js';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

a11yProps.propTypes = {
  index: PropTypes.string,
};


const CustomTabs = withStyles(() => ({
  root: {
    minHeight: "38px",
    display: "flex",
  },
  indicator: {
    backgroundColor: "transparent",
  }
}))(Tabs)

const CustomTab = withStyles(() => ({
  root: {
    minHeight: "38px",
    minWidth: "220px",
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '0.6px',
    color: grey600
  },
  selected: {}
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  tab_box: {
    marginTop: '40px',
    color: petrol800,
  },
  tag: {
    background: petrol800,
    borderRadius: '20px',
    height: '32px',
    width: '70px',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.6px',
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none',
  }
}));

export default function ScrollableVerisonTabs(props) {
  const { testSpecId, relations, currentVersion } = props;
  const [myValue, setMyValue] = useState(0);


  useEffect(() => {
    if (currentVersion > 0) {
      setMyValue(currentVersion - 1);
    }
  }, [currentVersion]); // eslint-disable-line

  const classes = useStyles();

  return (
    <div>
      <CustomTabs
        value={myValue}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        className={classes.tab_box}
      >
        {relations.map((i, j) => {
          return (
            <CustomTab
              disabled={testSpecId ? true : false}
              key={j}
              component={Link}
              to={`/testspecification/${i.testSpecificationId}`}
              label={
                <Box display="flex" >
                  V. {String(i.version).padStart(2, '0')}
                  {i.tag !== 'DRAFT' &&
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" style={{ marginLeft: '8px' }}>
                      <Icon iconName={"CorrectIcon"} width={27} height={27} fill={petrol800} />
                    </Box>
                  }
                  {i.tag === 'DRAFT' &&
                    <Box ml={1.5} className={classes.tag}>Draft</Box>

                  }
                </Box>
              } {...a11yProps(j)}

            />
          )
        })}
      </CustomTabs>
    </div>
  );
}

ScrollableVerisonTabs.propTypes = {
  relations: PropTypes.array,
  currentVersion: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
};