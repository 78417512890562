import React from 'react';
import { Button, Dialog, IconButton, DialogContent, Box, Typography } from '@mui/material';
import { petrol800 } from '../../helper/MuiStyles.js';
import Icon from '../../../../assets/icons/Icon.js';

const LeaveConfirmation = ({
  showDialog,
  onStay,
  onLeave,
}) => {
  if (!showDialog){
    return <></>
  }

  return (<Dialog
      fullWidth={true}
      maxWidth="sm"
      open={true}
    >
      <DialogContent >
          <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h2">
                Save Changes
              </Typography>
              <IconButton onClick={onStay} size="large" aria-label='Close'>
                <Icon iconName={"CloseIcon"} fill={petrol800} />
              </IconButton>
          </Box>
          <Box mt={2} display="flex" alignItems="center">
              <Typography variant="h4" style={{ fontWeight: '400' }}>
                Do you want to leave the page without saving your changes?
              </Typography>
          </Box>
          <Box mt={4} display="flex" alignItems="center" justifyContent="space-between">
              <Button
                  variant="outlined"
                  color="primary"
                  onClick={onStay}
              >
                Stay on the page
              </Button>
              <Button
                  variant="contained"
                  color="primary"
                  onClick={onLeave}
              >
                Leave
              </Button>
          </Box>
      </DialogContent>
    </Dialog>)

}
export default LeaveConfirmation
