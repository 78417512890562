import React from "react";
import { useStyles } from "./ReleaseReport.styles";
import { Box } from "@mui/system";
import Icon from "../../../../../assets/icons/Icon";
import { grey600, grey200 } from "../../../../other/helper/MuiStyles";
import DutOverview from "../../../../other/tooltips/DutOverview/DutOverview";
import TestResult from "../../../../testcasesection/table_utils/TestResult";
import TestStatus from "../../../../testcasesection/table_utils/TestStatus";
import { convertTimeStampToCwYear } from "../../../../other/helper/timeConverter/timeConverter";
import { handleCategoryIcon } from "../../../../other/helper/IconHandler/IconHandler";

export function TableHead({ title }) {
    return (
        <thead>
            <tr
                style={{
                    borderTop: `1px solid ${grey200}`,
                    borderLeft: `1px solid ${grey200}`,
                    borderRight: `1px solid ${grey200}`,
                }}><th colSpan="11" style={{
                    color: grey600,
                    fontSize: '14px',
                    fontWeight: 'bold',
                    padding: '4px'
                }}>{title}</th></tr>
            <tr
                style={{
                    borderTop: `1px solid ${grey200}`,
                    borderLeft: `1px solid ${grey200}`,
                    borderRight: `1px solid ${grey200}`,
                    borderBottom: `1px solid ${grey200}`,
                }}>
                <th>#</th>
                <th>Test Specification</th>
                <th>Requirements IDs</th>
                <th>Aging Status</th>
                <th>Reference</th>
                <th>DUTs</th>
                <th>Test Instance</th>
                <th>Test Status</th>
                <th>Actual Start Date</th>
                <th>Actual End Date</th>
                <th>Test Result</th>
            </tr>
        </thead>
    )
}

export function TableBody({ testResults }) {
    const classes = useStyles();
    return testResults?.testCases.map((product, index) => {

        return (
            <tbody key={index}>
                <tr style={{
                    borderLeft: `1px solid ${grey200}`,
                    borderRight: `1px solid ${grey200}`,
                }}>
                    <td colSpan="11">
                        <Box display="flex">
                            <Icon fill={grey600} iconName={handleCategoryIcon(product.category.name)} width="14px" height="14px" />
                            <Box ml={1} px={1} className={classes.category_name}>{product.category.name}</Box>
                        </Box>
                    </td>
                </tr>
                {product.testCases?.map((testCase, index) => {
                    return (
                        <tr key={index} style={index + 1 === product.testCases?.length ? {
                            border: `1px solid ${grey200}`,
                        } : {
                            borderTop: `1px solid ${grey200}`,
                            borderLeft: `1px solid ${grey200}`,
                            borderRight: `1px solid ${grey200}`,
                        }} >
                            <td>{testCase?.testCaseId}</td>
                            <td>{testCase?.testSpecification?.name}</td>
                            <td>{testCase?.requirementIds}</td>
                            <td>{testCase?.agingStatus?.name}</td>
                            <td>{testCase?.testSpecification?.groupStandardReference?.name}</td>
                            <td><DutOverview duts={testCase?.dutInfo} /></td>
                            {/* <td>{testCase?.dutInfo.planned}/{testCase?.dutInfo.started}/{testCase?.dutInfo.finished}</td> */}
                            <td>{testCase?.testInstance?.name}</td>
                            <td><TestStatus testStatusName={testCase?.testStatus?.name} width="50px" height="12px" /></td>
                            <td>{convertTimeStampToCwYear(testCase?.startDate)}</td>
                            <td>{convertTimeStampToCwYear(testCase?.endDate)}</td>
                            <td><TestResult testResultName={testCase?.testResult?.name} width="50px" height="12px" /></td>
                        </tr>
                    )
                })}
            </tbody>
        );
    })
}