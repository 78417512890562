import makeStyles from '@mui/styles/makeStyles';
import { buttonHoverPetrol800, grey100, grey1000, grey200, grey600, petrol800 } from '../../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    dialogbg: {
        overflow: 'hidden',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        marginTop: '0px',
        marginBottom: '0px',
        margin: 'auto !important',
    },
    paper: {
        padding: '24px',
        margin: '24px 12px',
        textAlign: 'left',
        color: 'rgba(0, 0, 0, 0.54)',
        minHeight: '75vh',
        maxHeight: '75vh',
    },
    title: {
        lineHeight: '24px',
        letterSpacing: '0.4px',
    },
    value: {
        lineHeight: '18px',
        letterSpacing: '0.8px',
    },
    button_add_testcases: {
        justifyContent: 'flex-end',
    },
    sampleBox: {
        color: '#FFF',
        backgroundColor: petrol800,
        borderRadius: '8px',
        marginRight: '16px',
        marginBottom: '8px',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '40px',
        letterSpacing: '0.6px',
        fontWeight: '400',
        padding: '0px 16px', 
    },
    line: {
        borderBottom: `1px solid ${grey200}`,
    },    
    hover_blue: {
        '&:hover': {
            backgroundColor: buttonHoverPetrol800,
          },
    },
    list: {
        marginTop: '24px',
        maxHeight: props => props.heightList,
        overflowY: "auto",
        margin: 0,
        padding: 0,
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: grey600,
            borderRadius: '8px'
        },
        // [theme.breakpoints.between('md', 'xl')]: {
        //     maxHeight: props => props.heightList,
        // },
        // [theme.breakpoints.up('lg')]: {
        //     maxHeight: "60vh",
        // },
    },
    cancel_button: {
        borderColor: '#006384 !important',
        boxShadow: 'none !important'
    },
    search: {
        width: '216px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      search_icon: {
        padding: '0 16px',
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      search_field: {
        "& #search-test-case": {
          width: '184px',
          paddingLeft: '32px !important',
        },
      },
    filter_icon: {
        display: "flex",
        marginLeft: '8px',
    },
    parameter_button: {
        marginRight: '16px',
        marginBottom: '16px',
        marginLeft: '0',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: petrol800,
        border: `1px solid ${grey600} !important`,
        boxSizing: "border-box",
        borderRadius: "8px !important",
        boxShadow: 'none !important',
        textTransform: 'none',
        minWidth: '0px',
        padding: '8px 16px',
    },
    parameter_button_selected: {
        backgroundColor: '#006384 !important',
        borderColor: '#006384 !important',
    },    
    category_button_group: {
        display: 'block'
    },
    selected_parameter_box: {
        backgroundColor: grey100,
        padding: '8px 16px',
        borderRadius: '8px',
        marginRight: '8px',
        marginBottom: '8px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    error_icon_wrapper: {
        marginLeft: '8px',
        transform: 'rotate(45deg)',
    },
    error_text: {
        marginTop: '8px',
        fontWeight: '400',
    },
    error_type: {
        padding: '0px 24px',
        textAlign: 'center',
        marginTop: '4px',
        fontWeight: '400',
        color: grey600,
    },
}));
