import React, { useContext } from 'react';
import { Box, Select, MenuItem, TableRow, TableCell } from '@mui/material';
import { useStyles } from './Table.styles';
import TestResult from '../table_utils/TestResult';
import TestStatus from '../table_utils/TestStatus';
import { convertTimeStampToCwYear } from '../../other/helper/timeConverter/timeConverter';
import DeleteDut from '../../other/dialogs/DeleteDut/DeleteDut';
import { DutContext } from './dutContext'
import {
    SnackbarContext, SnackbarType
} from '../../other/contexts/SnackBarContext';
import { useMutation } from '@apollo/client';
import { DELETEDUT, UPDATEDUT } from '../../../operations/mutations/mutations';
import { TESTCASES } from '../../../operations/queries/query';
import { currentIndexId } from '../../../operations/localeStorage';
import DatePicker from '../../other/helper/CustomDatePicker/DatePicker';

export default function Dut(props) {
    const { dut, dutIndex, test, edit, testStatuses, testResults, sizeWindow } = props;
    const { setIsSnackbarOpen, setMessage, setSnackbarType } = useContext(SnackbarContext);

    const handleOnError = async (error) => {
        setMessage(error.message);
        setSnackbarType(SnackbarType.ERROR);
        setIsSnackbarOpen(true);
    }

    const handleOnCompleted = async () => {
        setSnackbarType(SnackbarType.SUCCESS);
        setIsSnackbarOpen(true);
    }

    const [updateDut] = useMutation(UPDATEDUT, {
        onCompleted: handleOnCompleted,
        onError: handleOnError,
        refetchQueries: [{ query: TESTCASES(currentIndexId()) }],
        awaitRefetchQueries: true
    });
    const [deleteDut] = useMutation(DELETEDUT, {
        onCompleted: handleOnCompleted,
        onError: handleOnError,
        refetchQueries: [{ query: TESTCASES(currentIndexId()) }],
        awaitRefetchQueries: true
    });

    const styleProps = {
        fontSizeTable: (sizeWindow < 5) ? '14px' : '16px',
        inputFieldPaddingLeft: (sizeWindow < 5) ? '8px !important' : '',
        statusFieldPaddingRight: (sizeWindow < 5) ? '30px !important' : '',
        datePickerPadding: (sizeWindow < 5) ? '0px 0px 0px 8px !important' : '0px 0px 0px 16px !important',
    };



    const classes = useStyles(styleProps);

    const { state, testId } = React.useContext(DutContext);

    return (
        <>
            {state[0] && testId[0] === test.testCaseId ?
                <TableRow key={dut.dutId} className={classes.dut}>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        {/* CONDITION */}
                        <TableCell>
                            {/* TBD TEST CONDITION */}
                            {test.condition === null ? '-' : test.condition}
                        </TableCell>
                        {/* NUMBER */}
                        <TableCell>
                            {dutIndex + 1}
                        </TableCell>
                        {/* AGING STATUS */}
                        <TableCell>
                            <Box className={classes.text}>{test.agingStatus.name}</Box>
                        </TableCell>
                        {/* TEST INSTANCE */}
                        <TableCell>
                            <Box className={classes.text}>{test.testInstance.name}</Box>
                        </TableCell>
                        {/* TEST STATUS */}
                        <TableCell>
                            {edit &&
                                <Select
                                    className={classes.box}
                                    name="testStatusId"
                                    inputProps={{ "data-testid": "test-status-select" }}
                                    value={dut.testStatus?.testStatusId}
                                    onChange={(event) => { updateDut({ variables: { dutId: dut.dutId, testStatus: event.target.value } }); setMessage('DUT has been updated.'); }}
                                >
                                    {testStatuses.testStatuses.map((option) => (
                                        <MenuItem
                                            key={option.testStatusId}
                                            value={option.testStatusId}
                                            className={classes.dropdown_list}
                                        >
                                            <TestStatus testStatusName={option.name} width={sizeWindow < 5 ? '90px' : '100px'} sizeWindow={sizeWindow} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            }
                            {!edit &&
                                <Box display="flex" flexDirection="row" justifyContent="left">
                                    <TestStatus testStatusName={dut.testStatus?.name} width={sizeWindow < 5 ? '90px' : '100px'} sizeWindow={sizeWindow} />
                                </Box>
                            }
                        </TableCell>
                        {/* START DATE */}
                        <TableCell></TableCell>
                        <TableCell>
                            {edit &&
                                <Box style={{ maxWidth: '160px' }}>
                                    {dut && <DatePicker date={dut.startDate} trackInitDate={true}
                                        onBlur = {
                                            (validValue) => { 
                                                updateDut({ variables: { dutId: dut.dutId, startDate: validValue } }); 
                                                setMessage('DUT has been updated.'); 
                                            }
                                        }
                                    />}
                                </Box>
                            }{!edit &&
                                convertTimeStampToCwYear(dut.startDate)
                            }
                        </TableCell>
                        {/* END DATE */}
                        <TableCell></TableCell>
                        <TableCell>
                            {edit &&
                                <Box style={{ maxWidth: '160px' }}>
                                    {dut && <DatePicker date={dut.endDate} trackInitDate={true}
                                        onBlur = {
                                            (validValue) => { 
                                                updateDut({ variables: { dutId: dut.dutId, endDate: validValue } }); 
                                                setMessage('DUT has been updated.'); 
                                            }
                                        }
                                        endOfWeek={true}
                                    />}
                                </Box>
                            }{!edit &&
                                convertTimeStampToCwYear(dut.endDate)
                            }
                        </TableCell>
                        {/* TEST RESULT */}
                        <TableCell>
                            {edit &&
                                <Select
                                    name="testResultId"
                                    value={dut.testResult.testResultId}
                                    onChange={(event) => { updateDut({ variables: { dutId: dut.dutId, testResult: event.target.value } }); setMessage('DUT has been updated.'); }}
                                >
                                    {testResults.testResults.map((option) => (
                                        <MenuItem
                                            key={option.testResultId}
                                            value={option.testResultId}
                                        >
                                            <TestResult testResultName={option.name} width={sizeWindow < 5 ? '115px' : '130px'} sizeWindow={sizeWindow} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            }
                            {!edit &&
                                <Box display="flex" flexDirection="row" justifyContent="left">
                                    <TestResult testResultName={dut.testResult.name} width={sizeWindow < 5 ? '115px' : '130px'} sizeWindow={sizeWindow} />
                                </Box>
                            }
                        </TableCell>
                        {/* DELETE DUT */}
                        <TableCell>
                            <Box>
                                {edit &&
                                    <Box display="flex" flexDirection="row" justifyContent="flex-end" style={{ marginRight: '16px' }}>
                                        <DeleteDut
                                            testName={test.testSpecification.name}
                                            testVersion={test.testSpecification.version}
                                            deleteDut={deleteDut}
                                            dutId={dut.dutId}
                                            dutIndex={dutIndex}
                                            sizeWindow={sizeWindow}
                                        />
                                    </Box>
                                }
                                {!edit &&
                                    ""
                                }
                            </Box>
                        </TableCell>
                    </TableRow>
                
                : null}
        </>
    )
}
