import React, {useContext, useState} from 'react';
import { Button, Dialog, IconButton, DialogContent, Box, Typography } from '@mui/material';
import Icon from '../../../../assets/icons/Icon.js';
import { useStyles } from '../UniversalDialog.styles.js';
import { SnackbarContext, SnackbarType } from '../../contexts/SnackBarContext.js';
import { petrol800 } from '../../helper/MuiStyles.js';

function AddDut(props) {
    const { testName, testVersion, handleAddDut, sizeWindow } = props;
    const { setIsSnackbarOpen, setMessage, setSnackbarType } = useContext(SnackbarContext);

    const [show, setShow] = useState(false);

    const ToggleModal = () => {
        setShow(!show);
    };

    const createDut = (e) => {
        handleAddDut(e);
        setMessage('DUT has been added.');
        setSnackbarType(SnackbarType.SUCCESS);
        ToggleModal();
        setIsSnackbarOpen(true);
    }

    const classes = useStyles();

    return <>
        <IconButton onClick={ToggleModal} className={classes.hover_blue} data-testid={"add-dut-button"}>
            <Icon
                iconName={"AddIcon"}
                fill={petrol800}
                height={sizeWindow < 5 ? 20 : 24}
                width={sizeWindow < 5 ? 20 : 24}
            />
        </IconButton>

        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={show}
            onClose={ToggleModal}
        >
            <DialogContent>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h2">
                        Add DUT to {testName} V. {testVersion}
                    </Typography>
                    <IconButton onClick={ToggleModal} className={classes.hover_blue}>
                        <Icon iconName={"CloseIcon"} fill={petrol800} />
                    </IconButton>
                </Box>
                <Box mt={2} display="flex" alignItems="center">
                    <Typography variant="h4" style={{ fontWeight: '400' }}>
                        This will add a further DUT to the end of the existing list.
                    </Typography>
                </Box>
                <Box mt={3} display="flex" alignItems="center">
                    <Button
                        variant="contained"
                        color="primary"
                        data-testid={"confirm-add-dut-button"}
                        onClick={(e) => createDut(e)}
                    >
                        Add new DUT
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    </>
}

export default AddDut;