import React, {useContext, useState} from 'react';
import { useReactiveVar } from '@apollo/client';
import { calcLatestSample, sampleVar, subSampleVar } from '../../../../operations/localeStorage'
import { useStyles } from '../UniversalDialog.styles.js';
import { Button, Dialog, IconButton, DialogContent, Box, Typography } from '@mui/material';
import Icon from '../../../../assets/icons/Icon.js';
import { SnackbarContext } from '../../contexts/SnackBarContext';
import { petrol800, petrol900 } from '../../helper/MuiStyles';

function DeleteIndex(props) {
    const { mutationFunction, formSample, indexName, index, changeIndex } = props;
    const { setMessage } = useContext(SnackbarContext);

    const [show, setShow] = useState(false);
    const selectedSamplePosition = useReactiveVar(sampleVar);
    const selectedSubSamplePosition = useReactiveVar(subSampleVar);

    const ToggleModal = () => {
        setShow(!show);
    };

    const deleteIndex = () => {
        calcLatestSample(false)
        if (index > 0) {
            let indName = formSample[selectedSamplePosition]?.subSamples[selectedSubSamplePosition]?.indices[index - 1].name;
            changeIndex(indName, index - 1);
        }
        if (index === 0) {
            let indName = formSample[selectedSamplePosition]?.subSamples[selectedSubSamplePosition]?.indices[index + 1].name;
            changeIndex(indName, index);
        }
        mutationFunction({ variables: { indexId: formSample[selectedSamplePosition]?.subSamples[selectedSubSamplePosition]?.indices[index].indexId } })
        setMessage('Index has been deleted');
        setShow(!show);
    };

    const styleProps = {
        deleteIconHoverBackground: petrol900,
      };

    const classes = useStyles(styleProps);

    return <>
        <IconButton className={classes.delete_icon_wrapper} onClick={ToggleModal} size="large">
            <Icon iconName={"AddIcon"} fill={"#FFF"} />
        </IconButton>

        <Dialog 
        fullWidth={true} 
        maxWidth="sm" 
        open={show} 
        onClose={ToggleModal}
        >
            <DialogContent>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h2">
                        Delete {indexName}
                    </Typography>
                        <IconButton onClick={ToggleModal} className={classes.hover_blue}>
                            <Icon iconName={"CloseIcon"} fill={petrol800} />
                        </IconButton>
                </Box>
                <Box mt={2} display="flex" alignItems="center">
                    <Typography variant="h4" style={{ fontWeight: '400'}}>
                        Are you sure that you want to delete the selected index? <br/>All test cases of this index will be deleted.
                    </Typography>
                </Box>
                <Box mt={4} display="flex" alignItems="center">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={deleteIndex}
                    >
                        Delete
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    </>
}

export default DeleteIndex;