import React, { useState } from 'react'

const SnackbarContext = React.createContext(null);

const SnackbarType = {
    ERROR: 'error',
    INFO: 'info',
    SUCCESS: 'success'
};

const SnackbarProvider = ({ children }) => {
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [snackbarType, setSnackbarType] = useState(SnackbarType.INFO);
    const [message, setMessage] = useState('');

    return (
        <SnackbarContext.Provider value={{
            isSnackbarOpen, setIsSnackbarOpen,
            snackbarType, setSnackbarType,
            message, setMessage
        }}
        >
            {children}
        </SnackbarContext.Provider>
    );
};

export {
    SnackbarContext,
    SnackbarType,
    SnackbarProvider
}
