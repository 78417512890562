import React from 'react';
import { Box } from '@mui/material';
import { useStyles } from '../ProjectList.styles';
import { useNavigate } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import TestResultInfo from '../../../other/tooltips/TestResultInfo/TestResultInfo';
import SampleIcon from '../../../other/tooltips/SampleIcon/SampleIcon';
import UserInfo from '../../../other/tooltips/UserInfo/UserInfo';
import { IMAGE_MAP } from '../../../other/helper/ImgMapper/ImgMapper';
import { handleSampleClick } from '../../../other/tooltips/SampleIcon/handleSampleClick';
import { subSampleVar, indexVar, calcLatestSample } from '../../../../operations/localeStorage';
import Icon from '../../../../assets/icons/Icon';
import { signalRed800 } from '../../../other/helper/MuiStyles';

function ProjectListElement(props) {
    const { project } = props;

    const navigate = useNavigate()

    const failedTests = project.testResult.testsFailed;
    const passedTests = project.testResult.testsPassed;
    const notEvaluatedTests = project.testResult.testsNotEvaluated;
    const notAvailableTests = project.testResult.testsNotAvailable;
    const totalTestNumber = failedTests + passedTests + notEvaluatedTests + notAvailableTests;

    const styleProps = {
        supplierImg: IMAGE_MAP.SUPPLIER[project.cellSupplier.name],
    };

    const classes = useStyles(styleProps);

    return (
        <Box px={4} key={project.projectId} display="flex" className={classes.border} data-testid={"project-list-item"}
            onClick={() => { navigate(`/projectdetails/${project.projectId}`); calcLatestSample(true); subSampleVar(0); indexVar(0); }}
        >

            {/* CELL SUPPLIER */}
            <Box display="flex" flexDirection="column" justifyContent="center" className={classes.header2_text} >
                <div className={classes.supplierimage} title="SupplierLogo"></div>
            </Box>

            {/* TITLE */}
            <Box display="flex" alignItems="center" className={classes.header1_text} >
                <Box className={classes.text} style={{paddingRight: '0px'}} >{project.name}</Box>
                {project.isLocked &&
                    <Box mx={1} className={classes.tag}>
                        <div style={{ display: 'flex' }}>
                            <Icon iconName={"LockIcon"} width={24} height={24} fill={signalRed800} />
                        </div>
                    </Box>
                }
            </Box>

            {/* NOMINAL CAPACITY */}
            <Box display="flex" justifyContent="flex-start" alignItems="center" className={classes.header3_text}>
                <Box className={classes.text}>{project.nominalCapacity} Ah</Box>
            </Box>

            {/* VEHICLE PLATFORM */}
            <Box display="flex" justifyContent="flex-start" alignItems="center" className={classes.header4_text}>
                <Box display="flex" flexWrap="wrap" className={classes.text}>
                    {project.platforms.map((platform, index) => {
                        return (
                            <Box key={platform.name} title="Platform" style={index === project.platforms.length - 1 ? {} : { marginRight: '4px' }}>{platform.name + (index === project.platforms.length - 1 ? '' : ', ')}</Box>
                        )
                    })}
                </Box>
            </Box>

            {/* SAMPLE STATES */}
            <Box display="flex" justifyContent="flex-start" alignItems="center" className={classes.header6_text}>
                {project.samples.map((sample, index) => {
                    return (
                        <Box key={sample.name} onClick={(e) => { calcLatestSample(false); handleSampleClick(sample.name); e.stopPropagation(); }}>
                            <HashLink key={sample.name} to={`/projectdetails/${project.projectId}#samplestates`} style={{ textDecoration: 'none' }}>
                                <SampleIcon sampleValue={sample.name} hasTestCases={sample.hasTestCases} samples={project.samples} index={index} />
                            </HashLink>
                        </Box>
                    )
                })}
            </Box>

            {/* TEST RESULTS */}
            <Box display="flex" flexDirection="column" justifyContent="center" className={classes.header5_text}>
                <TestResultInfo failedTests={failedTests} passedTests={passedTests} notEvaluatedTests={notEvaluatedTests} notAvailableTests={notAvailableTests} totalTestNumber={totalTestNumber} accessPoint="Table" />
            </Box>

            {/* TEAM */}
            <Box pb={1} display="flex" justifyContent="flex-start" alignItems="center" className={classes.header7_text} flexWrap="wrap">
                {project.users?.map((i, index) => {
                    return (
                        <UserInfo user={i} key={i.userId + index} accessPoint="Table" />
                    )
                })}
            </Box>

        </Box>
    )
}

export default ProjectListElement;