import React, { useState } from 'react'

const DutContext = React.createContext(null);

const DutProvider = ({ children }) => {
  const [state, setState] = useState(false);
  const [testId, setTestId] = useState(0);
  return (
    // <DutContext.Provider value={[state, setState]}>
    <DutContext.Provider value={{state : [state, setState], testId: [testId, setTestId]}}>
      {children}
    </DutContext.Provider>
  )
}

export { DutContext, DutProvider }
