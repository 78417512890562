import makeStyles from '@mui/styles/makeStyles';
import { grey100, grey1000, grey200, grey800, signalRed800 } from '../../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    root: {
        padding: '24px',
        backgroundColor: grey100,
    },
    paper: {
        textAlign: 'center',
        backgroundColor: '#FFF',
        boxShadow: 'none',
        borderBottom: `1px solid ${grey200}`,
    },
    head: {
        borderBottom: `1px solid ${grey200}`,
    },
    header: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.8px',
        textAlign: 'left',
        color: grey800,
        height: '30px',
        paddingTop: '8px',
        paddingBottom: '8px',
    },
    header1: {
        minWidth: '20%',
        textAlign: 'left',
    },
    header1_text:{
        minWidth: '20%',
        maxWidth: '20%',
        textAlign: 'left',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    header2: {
        minWidth: '10%',
        textAlign: 'left',
    },
    header2_text: {
        minWidth: '10%',
        maxWidth: '10%',
        textAlign: 'left',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    header3: {
        minWidth: '10%',
        textAlign: 'left',
    },
    header3_text: {
        minWidth: '10%',
        maxWidth: '10%',
        textAlign: 'left',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    header4: {
        minWidth: '12.5%',
        textAlign: 'left',
    },
    header4_text:{
        minWidth: '12.5%',
        maxWidth: '12.5%',
        textAlign: 'left',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    header5: {
        minWidth: '11.5%',
        textAlign: 'left',
    },
    header5_text: {
        minWidth: '11.5%',
        maxWidth: '11.5%',
        textAlign: 'left',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    header6: {
        minWidth: '18.5%',
        textAlign: 'left',
    },
    header6_text:{
        minWidth: '18.5%',
        maxWidth: '18.5%',
        textAlign: 'left',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    header7: {
        minWidth: '17.5%',
        textAlign: 'left',
    },
    header7_text:{
        minWidth: '17.5%',
        maxWidth: '17.5%',
        textAlign: 'left',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
    },
    supplierimage: {
      backgroundImage: props => `url(${props.supplierImg})`,
      backgroundPosition: 'left',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      height: '32px',
      maxWidth: '72px',
      width: 'auto',
      display: 'flex',
    },
    border: {
        border: `1px solid ${grey200}`,
        borderStyle: 'solid none none none',
        minHeight: '48px',
        textDecoration: 'none',
        cursor: 'pointer',
    },
    text: {        
        paddingRight: '8px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    tag: {
      border: `1px solid ${signalRed800}`,
      borderRadius: '20px',
      height: '30px',
      padding: '0 8px',    
      color: signalRed800,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  },
}));