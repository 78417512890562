import React from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './Step.styles.js';

export const setParameters = (selectedParameters, entry) => {
    let parameters = [];
    if (selectedParameters !== undefined) {
        if (entry === "create") {
            selectedParameters.map(i => {
                return parameters.push({
                    id: i?.name,
                    display: i?.name
                });
            })
        } else {
            selectedParameters.map(i => {
                return parameters.push({
                    id: i?.parameter?.name,
                    display: i?.parameter?.name
                });
            })
        }
    }
    return parameters;
}


export const HintBox = () => {
    const classes = useStyles();
    return (
        <Box mt={1} display="flex" flexDirection="row" alignItems="center" marginLeft="122px">
            <Typography variant="h5" className={classes.parameter_hint}>
                Use
            </Typography>
            <Typography variant="h5" className={classes.parameter_hint_italic}>
                “@parametername”
            </Typography>
            <Typography variant="h5" className={classes.parameter_hint}>
                to reference parameters inline in the description.
            </Typography>
        </Box>
    )
};