import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { subSampleVar, indexVar, calcLatestSample } from '../../../operations/localeStorage'

import TestResultInfo from '../../other/tooltips/TestResultInfo/TestResultInfo';
import UserInfo from '../../other/tooltips/UserInfo/UserInfo';
import { Box, Typography } from '@mui/material';
import { useStyles } from './ProjectBox.styles.js';
import SampleIcon from '../../other/tooltips/SampleIcon/SampleIcon';
import { handleSampleClick } from '../../other/tooltips/SampleIcon/handleSampleClick';
import { IMAGE_MAP } from '../../other/helper/ImgMapper/ImgMapper';
import { signalRed800 } from '../../other/helper/MuiStyles';
import Icon from '../../../assets/icons/Icon';


function ProjectBox({ i }) {
  const navigate = useNavigate()

  const failedTests = i.testResult.testsFailed;
  const passedTests = i.testResult.testsPassed;
  const notEvaluatedTests = i.testResult.testsNotEvaluated;
  const notAvailableTests = i.testResult.testsNotAvailable;

  const totalTestNumber = failedTests + passedTests + notEvaluatedTests + notAvailableTests

  const styleProps = {
    supplierImg: IMAGE_MAP.SUPPLIER[i.cellSupplier.name],
  };

  const classes = useStyles(styleProps);

  return (
    <>
      <Box id="project-box" display="flex" flexDirection="column" onClick={() => { navigate(`/projectdetails/${i.projectId}/testcases`); calcLatestSample(true); subSampleVar(0); indexVar(0) }}>

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box p={2}>
            <div className={classes.supplierimage} title="SupplierLogo"></div>
          </Box>
          {i.isLocked &&
            <Box mx={2} className={classes.tag}>
              <div style={{ display: 'flex' }}>
                <Icon iconName={"LockIcon"} width={24} height={24} fill={signalRed800} />
              </div>
            </Box>
          }
        </Box>
        <Box mx={2}>
          <Typography variant="h3" className={classes.project_title}>
            {/* {i.name} */}
            {/* hotfix for bug in name nominalCapacity calculation */}
            {i.cellSupplier.name} {i.nominalCapacity} Ah {i.name.split(/\s+/).splice(-1)}
          </Typography>
        </Box>
        <Box mx={2} mt={2} mb={1} display="flex" flexDirection="row" justifyContent="space-between" className={classes.platformamperebox}>
          <Box display="flex" flexDirection="row" className={classes.platformbox}>
            {i.platforms.map((platform, index) => {
              return (
                <Typography key={platform.name} variant="h5" title="Platform" className={classes.box_typo} style={index === i.platforms.length - 1 ? {} : { marginRight: '4px' }}>{platform.name + (index === i.platforms.length - 1 ? '' : ', ')}</Typography>
              )
            })}
          </Box>
          <Typography variant="h5" className={classes.box_typo}>{i.nominalCapacity} Ah</Typography>
        </Box>
        <Box mx={2} mb={2} display="flex" justifyContent="left" >
          {i.samples.map((sample, index) => {
            return (
              <Box data-testid="sampleclick" key={sample.name} onClick={(e) => { calcLatestSample(false); handleSampleClick(sample.name); e.stopPropagation(); }}
              >
                <HashLink to={`/projectdetails/${i.projectId}/testcases#samplestates`} style={{ textDecoration: 'none' }}>
                  <SampleIcon sampleValue={sample.name} hasTestCases={sample.hasTestCases} samples={i.samples} index={index} />
                </HashLink>
              </Box>
            )
          })}
        </Box>
        <Box mx={2} mb={0.5} display="flex" justifyContent="space-between">
          <Box className={classes.test_line}>Test Results</Box>
          <Box className={classes.test_line} style={{ textAlign: 'right' }}>
            {failedTests + passedTests + notEvaluatedTests}
            /
            {totalTestNumber}
          </Box>
        </Box>
        <TestResultInfo failedTests={failedTests} passedTests={passedTests} notEvaluatedTests={notEvaluatedTests} notAvailableTests={notAvailableTests} totalTestNumber={totalTestNumber} />
        <Box className={classes.user}>
          <Box m={1} display="flex" flexDirection="row" flexWrap="wrap">
            {i.users?.map((user, index) => {
              return (
                <UserInfo user={user} key={user.userId + index} />
              )
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ProjectBox;