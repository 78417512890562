import { useQuery } from '@apollo/client';
import { Autocomplete, Box, Button, Grid, IconButton, InputBase, InputLabel, Paper, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Icon from '../../../assets/icons/Icon';
import { TESTSPECIFICATIONS } from '../../../operations/queries/query';
import BatteryLoader from '../../other/helper/BatteryLoader/BatteryLoader';
import { grey600, petrol800, signalRed800 } from '../../other/helper/MuiStyles';
import { StyledNavLink } from '../Homepage/Homepage.styles';
import TestSpecificationBox from '../TestSpecificationBox/TestSpecificationBox';
import { useStyles } from './AddTestSpecification.styles.js';

function AddTestSpecification() {

    const navigate = useNavigate();

    const { data: testSpecifications, error: testSpecificationsError, loading: testSpecificationsLoading } = useQuery(TESTSPECIFICATIONS(true, true));

    const [searchField, setSearchField] = useState('');
    const [titleValue, setTitleValue] = useState('');
    const [versionValue, setVersionValue] = useState({
        versionMessage: '',
        versionNumber: '',
    });
    const [tagValue, setTagValue] = useState();
    const [versionButton, setVersionButton] = useState(false);
    const [specificationButton, setSpecificationButton] = useState(false);
    const [blockSearchField, setBlockSearchField] = useState(false);
    const [clearedField, setClearedField] = useState(false);

    const [availableTestSpecification, setAvailableTestSpecification] = useState(
        {
            testSpecificationId: null,
            name: '',
            tag: '',
            category: '',
            version: '',
            groupStandardReference: '',
            approval: '',
        },
    );

    const handleInputChange = (_event, value) => {
        setSearchField(value);
    };

    const handleOptionSelected = (_event, value) => {
        const test = (testSpecifications.testSpecifications).filter(({ name }) => name === value);
        if (test?.length > 0) {
            checkForAvailableTestSpecifications(value)
        }
    };

    const checkForAvailableTestSpecifications = (search) => {
        setBlockSearchField(true);
        const value = (testSpecifications.testSpecifications).filter(({ name }) => name.toUpperCase() === search.toUpperCase());

        let titleMessage = '';
        let tagMessage = '';
        let versionMessage = '';
        let versionNumber = '';
        let newVersion = false;
        let newSpecification = false;

        if (value.length > 0) {
            titleMessage = 'Test specification already exists.';

            setAvailableTestSpecification({ ...availableTestSpecification, testSpecificationId: value[0].testSpecificationId, name: value[0].name, tag: value[0].tag, category: value[0].category, version: value[0].version, groupStandardReference: value[0].groupStandardReference, approval: value[0].approval });
            if (value[0].tag === 'DRAFT') {
                tagMessage = 'A new Version can only be created after the current draft version has been finalized.';
            } else {
                versionMessage = 'This version will be created after saving.';
                versionNumber = value[0].version + 1;
                newVersion = true;
            }
        } else {
            setAvailableTestSpecification({ ...availableTestSpecification, testSpecificationId: null, name: '', tag: '', category: '', version: '', groupStandardReference: '', approval: '' });
            titleMessage = 'Test specification does not exist yet.';
            versionMessage = 'This version will be created after saving.';
            versionNumber = 1;
            newSpecification = true;
        }
        setTitleValue(titleMessage);
        setTagValue(tagMessage);
        setVersionValue({ ...versionValue, 'versionMessage': versionMessage, 'versionNumber': versionNumber });
        setVersionButton(newVersion);
        setSpecificationButton(newSpecification);
    };

    const handleClear = () => {
        setSearchField('');
        setClearedField(!clearedField);
        setBlockSearchField(false);
    };

    const handleAddNewSpecification = () => {
        navigate('/createtestspecification', { state: { searchField, versionValue } }
        )
    };

    const handleAddNewVersion = () => {
        navigate('/createtestspecification', { state: { searchField, versionValue, availableTestSpecification } }
        )
    };

    useEffect(() => {
        if (searchField === '') {
            setAvailableTestSpecification({ ...availableTestSpecification, testSpecificationId: null, name: '', tag: '', category: '', version: '', groupStandardReference: '', approval: '' });
            setTitleValue('');
            setTagValue('');
            setVersionValue({ ...versionValue, 'versionMessage': '', 'versionNumber': '' });
            setVersionButton(false);
            setSpecificationButton(false);
        }
    }, [searchField]); // eslint-disable-line

    const classes = useStyles();

    return (

        <div className={classes.root} >
            <Grid container spacing={3}>
                <Grid item xs={12} display="grid">
                    <Paper className={classes.paper}>

                        {/* ERROR */}
                        {testSpecificationsError !== undefined &&
                            <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 64px - 24px - 24px)' }}>
                                <Box p={4} display="flex" alignItems="center">
                                    <Typography variant="h2">
                                        Add Test Specification
                                    </Typography>
                                </Box>
                                <Box mx={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow="1">
                                    <Icon iconName={"WarningIcon"} width={54} height={54} fill={signalRed800} />
                                    <Typography variant="h3" className={classes.error_text}>
                                        Something went wrong. The following errors have occurred:
                                    </Typography>
                                    <Typography variant="h5" className={classes.error_type}>
                                        {testSpecificationsError.message}
                                    </Typography>
                                </Box>
                                <Box my={4} display="flex" alignItems="center" justifyContent="center">
                                    <StyledNavLink to="/">
                                        <Button
                                            variant="outlined"
                                            color="primary">
                                            Cancel
                                        </Button>
                                    </StyledNavLink>
                                </Box>
                            </Box>
                        }

                        {/* LOADING */}
                        {testSpecificationsError === undefined && (testSpecificationsLoading) &&
                            <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 64px - 24px - 24px)' }}>
                                <Box p={4} display="flex" alignItems="center">
                                    <Typography variant="h2">
                                        Add Specification Title
                                    </Typography>
                                </Box>
                                <Box mx={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow="1">
                                    <BatteryLoader type="standard" />
                                </Box>
                            </Box>
                        }

                        {testSpecificationsError === undefined && !testSpecificationsLoading &&
                            <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 64px - 24px - 24px)' }}>
                                <Box display="flex" flexDirection="column">
                                    <Box m={4} display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                                        <Typography variant="h2">
                                            Add Test Specification
                                        </Typography>
                                        <StyledNavLink to="/">
                                            <IconButton size="large" className={classes.hover_blue}>
                                                <Icon iconName={"CloseIcon"} fill={petrol800} />
                                            </IconButton>
                                        </StyledNavLink>
                                    </Box>
                                    < Box px={4} pb={2} display="flex">
                                        <Typography variant="h5" className={classes.user_hint}>
                                            Enter a test specification title so we can match it with existing titles to identify a possible match.
                                        </Typography>
                                    </Box>
                                    <Box px={4} display="flex">
                                        <Box display="flex" flexDirection="column">
                                            <InputLabel className={classes.input_title}>Title *</InputLabel>
                                            <div className={classes.search}>
                                                <div className={classes.search_icon}>
                                                    <Icon iconName={'SearchIcon'} fill={grey600} />
                                                </div>
                                                <Autocomplete
                                                    key={clearedField}
                                                    id="search-test-specification"
                                                    data-testid='autocomplete-search'
                                                    className={classes.search_field}
                                                    freeSolo
                                                    onInputChange={handleInputChange}
                                                    onChange={handleOptionSelected}
                                                    disabled={blockSearchField}
                                                    options={testSpecifications?.testSpecifications.map((name) => name.name)}
                                                    renderInput={(params) => (
                                                        <TextField {...params} placeholder="Search Test Specification" />
                                                    )}
                                                >
                                                </Autocomplete>
                                            </div>
                                            <Typography variant="subtitle2" className={classes.availability_message}>{titleValue}</Typography>
                                        </Box>
                                        {versionValue.versionMessage.length > 0 &&
                                            <Box ml={11} display="flex" flexDirection="column">
                                                <InputLabel className={classes.input_title}>Version *</InputLabel>
                                                <InputBase
                                                    name="version"
                                                    className={classes.freeText}
                                                    disabled={true}
                                                    value={versionValue.versionNumber}
                                                />
                                                <Typography variant="subtitle2" className={classes.availability_message}>{versionValue.versionMessage}</Typography>
                                            </Box>
                                        }
                                        {blockSearchField ?
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.button_clear}
                                                onClick={() => handleClear()}
                                            >
                                                <div style={{ display: 'flex', marginRight: '8px' }}>
                                                    <Icon iconName={"SearchCrossIcon"} width={24} height={24} fill={signalRed800} />
                                                </div>
                                                Clear Search
                                            </Button>
                                            :
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={searchField === '' || searchField.replace(/\s/g, '').length === 0 || searchField.length > 255}
                                                className={classes.button_search_test_specification}
                                                style={{marginLeft: versionValue.versionMessage.length > 0 ? '24px' : '88px'}}
                                                onClick={() => checkForAvailableTestSpecifications(searchField)}
                                            >
                                                <div style={{ display: 'flex', marginRight: '8px' }}>
                                                    <Icon iconName={"SearchIcon"} width={24} height={24} fill={'#FFF'} />
                                                </div>
                                                Search Test Specification
                                            </Button>
                                        }
                                    </Box>
                                    {(availableTestSpecification.testSpecificationId !== null) &&
                                        <>
                                            <Box px={4} pt={4} pb={2} display="flex" alignItems="center">
                                                <Typography variant="h3">
                                                    Available Specification
                                                </Typography>
                                            </Box>

                                            <Box component={Link} to={{ pathname: `/testspecification/${availableTestSpecification.testSpecificationId}` }} mx={4} className={classes.test_specification_box}>
                                                <TestSpecificationBox i={availableTestSpecification} />
                                            </Box>
                                            <Typography variant="subtitle2" className={classes.draft_message} >{tagValue}</Typography>
                                        </>
                                    }
                                </Box>
                                <Box m={4} display="flex" alignItems="center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={!specificationButton}
                                        onClick={handleAddNewSpecification}
                                    >
                                        <div style={{ display: 'flex', marginRight: '8px' }}>
                                            <Icon iconName={"AddIcon"} fill={'#FFF'} />
                                        </div>
                                        Add new Specification
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={!versionButton}
                                        onClick={handleAddNewVersion}
                                        style={{ marginLeft: '24px' }}
                                    >
                                        <div style={{ display: 'flex', marginRight: '8px' }}>
                                            <Icon iconName={"AddIcon"} fill={'#FFF'} />
                                        </div>
                                        Add new Version
                                    </Button>
                                </Box>
                            </Box>
                        }

                    </Paper>
                </Grid>
            </Grid>
        </div>

    )
}

export default AddTestSpecification;