export const isValid = (value) => {
    const num1 = /^[-+]?\d+\.\d+$/;
    const num2 = /^-?\d+$/;
    return num1.test(value) || num2.test(value) || value === "" || value === null
}

export const parseDataInputItem = (val) => {
    if (val === '') {
        return val
    }
    const regexp = /^[-+]?\d+(?:[.,]\d+)?$/
    return regexp.test(val) ? parseFloat(val.replace(',', '.')) : NaN
}

export const parseDataInput = (userInput) => {
    return userInput.split('\n').map(r => r.split(/\t|\s/).map(parseDataInputItem))
}

export const mergeUserInputAndScale = (values, rows_scale, cols_scale) => {
    return values.map((row, row_ind) => {
        return row.map((col, col_ind) => ({
                value: col,
                temperature: rows_scale[row_ind],
                soc: cols_scale[col_ind]
            }))
    }).flat()
}

export const getUpdatedCDSValues = (cdsValues, cdsIndex, powerUnit, isCharged, userInput, isOCV) => {
    const mutations = []
    const newCDSValues = JSON.parse(JSON.stringify(cdsValues)) // copy

    newCDSValues['cellDataSheet'][cdsIndex].temperatures.forEach((t, t_ind) => {
        t.socs.forEach((s, s_ind) => {
            // OCV values don't have stateOfCharge
            if (s.isCharged === isCharged && s.stateOfCharge === (!isOCV ? powerUnit : undefined)) {
                const userInputValue = userInput.find(
                    el => el.soc === s.soc && el.temperature === t.temperature
                )

                if (userInputValue !== undefined) {
                    mutations.push({
                        value: userInputValue.value, 
                        soc: {...newCDSValues['cellDataSheet'][cdsIndex].temperatures[t_ind].socs[s_ind]}
                    })

                    newCDSValues['cellDataSheet'][cdsIndex].temperatures[t_ind].socs[s_ind].value = userInputValue.value
                }
            }

        })
    })

    return [newCDSValues, mutations]
}

export const getUpdatedCDSMutationValues = (cdsMutationValues, userInputMutations) => {
    const newCDSMutationValues = JSON.parse(JSON.stringify(cdsMutationValues)) // copy

    userInputMutations.forEach(({ value, soc }) => {
        const indexOfId = newCDSMutationValues.findIndex(el => el.cellDataId === soc.cellDataId);
        if (isValid(value)) {
            value = parseFloat(value)

            // check whether cellDataId is manipulated first time or has already been changed in this session 
            if (indexOfId >= 0) {
                newCDSMutationValues[indexOfId].value = value;
            } else {
                newCDSMutationValues.push({ cellDataId: soc.cellDataId, isSimulated: soc.isSimulated, value: value });
            }
        }
        else {
            // check whether cellDataId is manipulated first time or has already been changed in this session 
            if (indexOfId >= 0) {
                // delete entry since new value is invalid
                newCDSMutationValues.splice(indexOfId, 1)
            }
        }
    })

    return newCDSMutationValues
}