import React, {useContext} from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { AGINGSTATUS, LEADINGDEVELOPMENTS, PLATFORMS, TESTINSTANCES, ROLES, CATEGORIES, GROUPSTANDARDREFERENCES, PROJECTTYPES, APPROVALS, PHYSICALQUANTITIES, PARAMETERS, RATINGS, UNITS, SUPPLIERPORTFOLIOS, ALLPHYSICALQUANTITIES, ALLUNITS } from '../../operations/queries/query';
import { UPDATELEADINGDEVELOPMENT, UPDATEPLATFORM, UPDATETESTINSTANCE, UPDATEGROUPSTANDARDREFERENCE, UPDATECATEGORY, UPDATEPROJECTROLE, ADDPROJECTTYPE, ADDPLATFORM, ADDTESTINSTANCE, ADDLEADINGDEVELOPMENT, ADDCATEGORY, ADDPROJECTROLE, ADDGROUPSTANDARDREFERENCE, ADDAPPROVAL, ADDPHYSICALQUANTITY, ADDUNIT, ADDRATING, ADDSUPPLIERPORTFOLIO, ADDPARAMETER, UPDATEAPPROVAL, UPDATEPROJECTTYPE, UPDATEPHYSICALQUANTITY, UPDATESUPPLIERPORTFOLIO, UPDATEUNIT, UPDATERATING, UPDATEPARAMETER, ADDAGINGSTATUS, UPDATEAGINGSTATUS } from '../../operations/mutations/mutations';
import { Paper, Grid, Box, Typography, InputLabel, Button } from '@mui/material';
import { useStyles } from './DataManagement.styles';
import Icon from '../../assets/icons/Icon.js';
import AddEditData from '../other/dialogs/AddEditData/AddEditData';
import DeleteData from '../other/dialogs/DeleteData/DeleteData';
import BatteryLoader from '../other/helper/BatteryLoader/BatteryLoader';
import { StyledNavLink } from '../homepage/Homepage/Homepage.styles';
import ScrollUpIcon from '../other/helper/ScrollUpIcon/ScrollUpIcon';
import ParameterNameInfo from '../other/tooltips/ParameterNameInfo/ParameterNameInfo';
import ParameterDescriptionInfo from '../other/tooltips/ParameterDescriptionInfo/ParameterDescriptionInfo';
import AddEditParameter from '../other/dialogs/AddEditParameter/AddEditParameter';
import AddEditUnit from '../other/dialogs/AddEditUnit/AddEditUnit';
import AddEditAgingStatus from '../other/dialogs/AddEditAgingStatus/AddEditAgingStatus';
import { grey200, red400, signalRed800 } from '../other/helper/MuiStyles';
import {SnackbarContext, SnackbarType} from '../other/contexts/SnackBarContext';

function DataManagement() {

    const { setIsSnackbarOpen, setMessage, setSnackbarType } = useContext(SnackbarContext);

    //PROJECTS
    const { data: activeAgingStatus, loading: activeAgingStatusLoading, error: activeAgingStatusError } = useQuery(AGINGSTATUS(true));
    const { data: inactiveAgingStatus, loading: inactiveAgingStatusLoading, error: inactiveAgingStatusError } = useQuery(AGINGSTATUS(false));
    const { data: activeProjectTypes, loading: activeProjectTypesLoading, error: activeProjectTypesError } = useQuery(PROJECTTYPES(true));
    const { data: inactiveProjectTypes, loading: inactiveProjectTypesLoading, error: inactiveProjectTypesError } = useQuery(PROJECTTYPES(false));
    const { data: activeLeadingDevelopments, loading: activeLeadingDevelopmentsLoading, error: activeLeadingDevelopmentsError } = useQuery(LEADINGDEVELOPMENTS(true));
    const { data: inactiveLeadingDevelopments, loading: inactiveLeadingDevelopmentsLoading, error: inactiveLeadingDevelopmentsError } = useQuery(LEADINGDEVELOPMENTS(false));
    const { data: activePlatforms, loading: activePlatformsLoading, error: activePlatformsError } = useQuery(PLATFORMS(true));
    const { data: inactivePlatforms, loading: inactivePlatformsLoading, error: inactivePlatformsError } = useQuery(PLATFORMS(false));
    const { data: activeTestInstances, loading: activeTestInstancesLoading, error: activeTestInstancesError } = useQuery(TESTINSTANCES(true));
    const { data: inactiveTestInstances, loading: inactiveTestInstancesLoading, error: inactiveTestInstancesError } = useQuery(TESTINSTANCES(false));
    const { data: activeRoles, loading: activeRolesLoading, error: activeRolesError } = useQuery(ROLES(true));
    const { data: inactiveRoles, loading: inactiveRolesLoading, error: inactiveRolesError } = useQuery(ROLES(false));

    //TEST SPECIFICATIONS
    const { data: activeApprovals, loading: activeApprovalsLoading, error: activeApprovalsError } = useQuery(APPROVALS(true));
    const { data: inactiveApprovals, loading: inactiveApprovalsLoading, error: inactiveApprovalsError } = useQuery(APPROVALS(false));
    const { data: activeGroupStandardReferences, loading: activeGroupStandardReferencesLoading, error: activeGroupStandardReferencesError } = useQuery(GROUPSTANDARDREFERENCES(true));
    const { data: inactiveGroupStandardReferences, loading: inactiveGroupStandardReferencesLoading, error: inactiveGroupStandardReferencesError } = useQuery(GROUPSTANDARDREFERENCES(false));
    const { data: activeCategories, loading: activeCategoriesLoading, error: activeCategoriesError } = useQuery(CATEGORIES(true));
    const { data: inactiveCategories, loading: inactiveCategoriesLoading, error: inactiveCategoriesError } = useQuery(CATEGORIES(false));
    const { data: activePhysicalQuantities, loading: activePhysicalQuantitiesLoading, error: activePhysicalQuantitiesError } = useQuery(PHYSICALQUANTITIES(true));
    const { data: inactivePhysicalQuantities, loading: inactivePhysicalQuantitiesLoading, error: inactivePhysicalQuantitiesError } = useQuery(PHYSICALQUANTITIES(false));
    const { data: allPhysicalQuantities, loading: allPhysicalQuantitiesLoading, error: allPhysicalQuantitiesError } = useQuery(ALLPHYSICALQUANTITIES);
    const { data: activeParameters, loading: activeParametersLoading, error: activeParametersError } = useQuery(PARAMETERS(true));
    const { data: inactiveParameters, loading: inactiveParametersLoading, error: inactiveParametersError } = useQuery(PARAMETERS(false));
    const { data: activeUnits, loading: activeUnitsLoading, error: activeUnitsError } = useQuery(UNITS(true));
    const { data: inactiveUnits, loading: inactiveUnitsLoading, error: inactiveUnitsError } = useQuery(UNITS(false));
    const { data: allUnits, loading: allUnitsLoading, error: allUnitsError } = useQuery(ALLUNITS);

    //SUPPLIERS
    const { data: activeRatings, loading: activeRatingsLoading, error: activeRatingsError } = useQuery(RATINGS(true));
    const { data: inactiveRatings, loading: inactiveRatingsLoading, error: inactiveRatingsError } = useQuery(RATINGS(false));
    const { data: activeSupplierPortfolios, loading: activeSupplierPortfoliosLoading, error: activeSupplierPortfoliosError } = useQuery(SUPPLIERPORTFOLIOS(true));
    const { data: inactiveSupplierPortfolios, loading: inactiveSupplierPortfoliosLoading, error: inactiveSupplierPortfoliosError } = useQuery(SUPPLIERPORTFOLIOS(false));

    const handleOnError = async (error) => {
        setMessage(error.message);
        setSnackbarType(SnackbarType.ERROR);
        setIsSnackbarOpen(true);
    };

    const handleOnCompleted = async () => {
        setSnackbarType(SnackbarType.SUCCESS);
        setIsSnackbarOpen(true);
    }

    //PROJECTS
    const [addAgingStatus, { loading: addAgingStatusLoading }] = useMutation(ADDAGINGSTATUS, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: AGINGSTATUS(true) }, { query: AGINGSTATUS(false) }], awaitRefetchQueries: true });
    const [updateAgingStatus, { loading: updateAgingStatusLoading }] = useMutation(UPDATEAGINGSTATUS, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: AGINGSTATUS(true) }, { query: AGINGSTATUS(false) }], awaitRefetchQueries: true });
    const [addProjectType, { loading: addProjectTypeLoading }] = useMutation(ADDPROJECTTYPE, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: PROJECTTYPES(true) }, { query: PROJECTTYPES(false) }], awaitRefetchQueries: true });
    const [updateProjectType, { loading: updateProjectTypeLoading }] = useMutation(UPDATEPROJECTTYPE, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: PROJECTTYPES(true) }, { query: PROJECTTYPES(false) }], awaitRefetchQueries: true });
    const [addLeadingDevelopment, { loading: addLeadingDevelopmentLoading }] = useMutation(ADDLEADINGDEVELOPMENT, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: LEADINGDEVELOPMENTS(true) }, { query: LEADINGDEVELOPMENTS(false) }], awaitRefetchQueries: true });
    const [updateLeadingDevelopment, { loading: updateLeadingDevelopmentLoading }] = useMutation(UPDATELEADINGDEVELOPMENT, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: LEADINGDEVELOPMENTS(true) }, { query: LEADINGDEVELOPMENTS(false) }], awaitRefetchQueries: true });
    const [addPlatform, { loading: addPlatformLoading }] = useMutation(ADDPLATFORM, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: PLATFORMS(true) }, { query: PLATFORMS(false) }], awaitRefetchQueries: true });
    const [updatePlatform, { loading: updatePlatformLoading }] = useMutation(UPDATEPLATFORM, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: PLATFORMS(true) }, { query: PLATFORMS(false) }], awaitRefetchQueries: true });
    const [addTestInstance, { loading: addTestInstanceLoading }] = useMutation(ADDTESTINSTANCE, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: TESTINSTANCES(true) }, { query: TESTINSTANCES(false) }], awaitRefetchQueries: true });
    const [updateTestInstance, { loading: updateTestInstanceLoading }] = useMutation(UPDATETESTINSTANCE, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: TESTINSTANCES(true) }, { query: TESTINSTANCES(false) }], awaitRefetchQueries: true });
    const [addRole, { loading: addRoleLoading }] = useMutation(ADDPROJECTROLE, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: ROLES(true) }, { query: ROLES(false) }], awaitRefetchQueries: true });
    const [updateRole, { loading: updateRoleLoading }] = useMutation(UPDATEPROJECTROLE, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: ROLES(true) }, { query: ROLES(false) }], awaitRefetchQueries: true });

    // TEST SPECIFICATIONS
    const [addApproval, { loading: addApprovalLoading }] = useMutation(ADDAPPROVAL, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: APPROVALS(true) }, { query: APPROVALS(false) }], awaitRefetchQueries: true });
    const [updateApproval, { loading: updateApprovalLoading }] = useMutation(UPDATEAPPROVAL, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: APPROVALS(true) }, { query: APPROVALS(false) }], awaitRefetchQueries: true });
    const [addGroupStandardReference, { loading: addGroupStandardReferenceLoading }] = useMutation(ADDGROUPSTANDARDREFERENCE, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: GROUPSTANDARDREFERENCES(true) }, { query: GROUPSTANDARDREFERENCES(false) }], awaitRefetchQueries: true });
    const [updateGroupStandardReference, { loading: updateGroupStandardReferenceLoading }] = useMutation(UPDATEGROUPSTANDARDREFERENCE, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: GROUPSTANDARDREFERENCES(true) }, { query: GROUPSTANDARDREFERENCES(false) }], awaitRefetchQueries: true });
    const [addCategory, { loading: addCategoryLoading }] = useMutation(ADDCATEGORY, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: CATEGORIES(true) }, { query: CATEGORIES(false) }], awaitRefetchQueries: true });
    const [updateCategory, { loading: updateCategoryLoading }] = useMutation(UPDATECATEGORY, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: CATEGORIES(true) }, { query: CATEGORIES(false) }], awaitRefetchQueries: true });
    const [addPhysicalQuantity, { loading: addPhysicalQuantityLoading }] = useMutation(ADDPHYSICALQUANTITY, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: PHYSICALQUANTITIES(true) }, { query: PHYSICALQUANTITIES(false) }, { query: ALLPHYSICALQUANTITIES }], awaitRefetchQueries: true });
    const [updatePhysicalQuantity, { loading: updatePhysicalQuantityLoading }] = useMutation(UPDATEPHYSICALQUANTITY, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: PHYSICALQUANTITIES(true) }, { query: PHYSICALQUANTITIES(false) }, { query: ALLPHYSICALQUANTITIES }], awaitRefetchQueries: true });
    const [addUnit, { loading: addUnitLoading }] = useMutation(ADDUNIT, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: UNITS(true) }, { query: UNITS(false) }, { query: ALLUNITS }], awaitRefetchQueries: true });
    const [updateUnit, { loading: updateUnitLoading }] = useMutation(UPDATEUNIT, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: UNITS(true) }, { query: UNITS(false) }, { query: ALLUNITS }], awaitRefetchQueries: true });
    const [addParameter, { loading: addParameterLoading }] = useMutation(ADDPARAMETER, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: PARAMETERS(true) }, { query: PARAMETERS(false) }], awaitRefetchQueries: true });
    const [updateParameter, { loading: updateParameterLoading }] = useMutation(UPDATEPARAMETER, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: PARAMETERS(true) }, { query: PARAMETERS(false) }], awaitRefetchQueries: true });

    //SUPPLIERS
    const [addRating, { loading: addRatingLoading }] = useMutation(ADDRATING, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: RATINGS(true) }, { query: RATINGS(false) }], awaitRefetchQueries: true });
    const [updateRating, { loading: updateRatingLoading }] = useMutation(UPDATERATING, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: RATINGS(true) }, { query: RATINGS(false) }], awaitRefetchQueries: true });
    const [addSupplierPortfolio, { loading: addSupplierPortfolioLoading }] = useMutation(ADDSUPPLIERPORTFOLIO, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: SUPPLIERPORTFOLIOS(true) }, { query: SUPPLIERPORTFOLIOS(false) }], awaitRefetchQueries: true });
    const [updateSupplierPortfolio, { loading: updateSupplierPortfolioLoading }] = useMutation(UPDATESUPPLIERPORTFOLIO, { onCompleted: handleOnCompleted, onError: handleOnError, refetchQueries: [{ query: SUPPLIERPORTFOLIOS(true) }, { query: SUPPLIERPORTFOLIOS(false) }], awaitRefetchQueries: true });


    const isLoadingProject = () => {
        return (updateAgingStatusLoading || addAgingStatusLoading || activeAgingStatusLoading || inactiveAgingStatusLoading || inactiveProjectTypesLoading || updateProjectTypeLoading || addRoleLoading || addLeadingDevelopmentLoading || addProjectTypeLoading || addPlatformLoading || addTestInstanceLoading || activeProjectTypesLoading || activeLeadingDevelopmentsLoading || inactiveLeadingDevelopmentsLoading || activePlatformsLoading || inactivePlatformsLoading || activeTestInstancesLoading || inactiveTestInstancesLoading || activeRolesLoading || inactiveRolesLoading || updateLeadingDevelopmentLoading || updatePlatformLoading || updateTestInstanceLoading || updateRoleLoading)
    };

    const isLoadingTestSpec = () => {
        return (allPhysicalQuantitiesLoading || updateParameterLoading || updateUnitLoading || updatePhysicalQuantityLoading || updateApprovalLoading || addParameterLoading || addUnitLoading || activeUnitsLoading || inactiveUnitsLoading || allUnitsLoading || activeParametersLoading || inactiveParametersLoading || addPhysicalQuantityLoading || activePhysicalQuantitiesLoading || inactivePhysicalQuantitiesLoading || addApprovalLoading || addGroupStandardReferenceLoading || addCategoryLoading || activeApprovalsLoading || inactiveApprovalsLoading || activeGroupStandardReferencesLoading || inactiveGroupStandardReferencesLoading || activeCategoriesLoading || inactiveCategoriesLoading || updateGroupStandardReferenceLoading || updateCategoryLoading)
    };

    const isLoadingSupplier = () => {
        return (updateRatingLoading || updateSupplierPortfolioLoading || addSupplierPortfolioLoading || inactiveSupplierPortfoliosLoading || activeSupplierPortfoliosLoading || addRatingLoading || activeRatingsLoading || inactiveRatingsLoading)
    };

    const hasErrorProject = () => {
        return !(inactiveAgingStatusError === undefined && activeAgingStatusError === undefined && inactiveProjectTypesError === undefined && activeProjectTypesError === undefined && activeLeadingDevelopmentsError === undefined && inactiveLeadingDevelopmentsError === undefined && activePlatformsError === undefined && inactivePlatformsError === undefined && activeTestInstancesError === undefined && inactiveTestInstancesError === undefined && activeRolesError === undefined && inactiveRolesError === undefined)
    };

    const hasErrorTestSpec = () => {
        return !(allPhysicalQuantitiesError === undefined && activeUnitsError === undefined && inactiveUnitsError === undefined && allUnitsError === undefined && activeParametersError === undefined && inactiveParametersError === undefined && activePhysicalQuantitiesError === undefined && inactivePhysicalQuantitiesError === undefined && activeApprovalsError === undefined && inactiveApprovalsError === undefined && activeGroupStandardReferencesError === undefined && inactiveGroupStandardReferencesError === undefined && activeCategoriesError === undefined && inactiveCategoriesError === undefined)
    };

    const hasErrorSupplier = () => {
        return !(inactiveSupplierPortfoliosError === undefined && activeSupplierPortfoliosError === undefined && activeRatingsError === undefined && inactiveRatingsError === undefined)
    };

    const classes = useStyles();

    return (
        <>
            <div className={classes.root}>
                <Grid container spacing={3}>

                    <Grid item xs={12} display="grid">
                        <Paper className={classes.paper} style={{ minHeight: 'calc((100vh * (2/5)) - ((64px + 24px + 24px + 24px + 24px)*(2/5)))' }}>

                            {/* ERROR */}
                           {hasErrorProject() &&
                                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc((100vh * (2/5)) - ((64px + 24px + 24px + 24px + 24px)*(2/5)))' }}>
                                    <Box px={3} pt={2} display="flex" flexDirection="row" alignItems="center">
                                        <Typography variant="h3" className={classes.title}>
                                            Data for Projects
                                        </Typography>
                                    </Box>
                                    <Box mx={3} pt={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow="1">
                                        <Icon iconName={"WarningIcon"} width={54} height={54} fill={signalRed800} />
                                        <Typography variant="h3" className={classes.error_text}>
                                            Something went wrong. The following errors have occurred:
                                        </Typography>
                                        {activeAgingStatusError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {activeAgingStatusError.message}
                                            </Typography>
                                        }
                                        {inactiveAgingStatusError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {inactiveAgingStatusError.message}
                                            </Typography>
                                        }
                                        {activeProjectTypesError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {activeProjectTypesError.message}
                                            </Typography>
                                        }
                                        {inactiveProjectTypesError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {inactiveProjectTypesError.message}
                                            </Typography>
                                        }
                                        {activeLeadingDevelopmentsError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {activeLeadingDevelopmentsError.message}
                                            </Typography>
                                        }
                                        {inactiveLeadingDevelopmentsError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {inactiveLeadingDevelopmentsError.message}
                                            </Typography>
                                        }
                                        {activePlatformsError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {activePlatformsError.message}
                                            </Typography>
                                        }
                                        {inactivePlatformsError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {inactivePlatformsError.message}
                                            </Typography>
                                        }
                                        {activeTestInstancesError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {activeTestInstancesError.message}
                                            </Typography>
                                        }
                                        {inactiveTestInstancesError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {inactiveTestInstancesError.message}
                                            </Typography>
                                        }
                                        {activeRolesError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {activeRolesError.message}
                                            </Typography>
                                        }
                                        {inactiveRolesError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {inactiveRolesError.message}
                                            </Typography>
                                        }
                                    </Box>
                                    <Box my={4} display="flex" alignItems="center" justifyContent="center">
                                        <StyledNavLink to="/">
                                            <Button
                                                variant="outlined"
                                                color="primary">
                                                Cancel
                                            </Button>
                                        </StyledNavLink>
                                    </Box>
                                </Box>
                            }

                            {/* LOADING */}
                            {isLoadingProject() && !hasErrorProject() &&
                                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc((100vh * (2/5)) - ((64px + 24px + 24px + 24px + 24px)*(2/5)))' }}>
                                    <Box px={3} pt={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                        <Typography variant="h3" className={classes.title}>
                                            Data for Projects
                                        </Typography>
                                    </Box>
                                    <Box m={3} display="flex" alignItems="center" justifyContent="center" flexGrow="1">
                                        <BatteryLoader type="standard" />
                                    </Box>
                                </Box>
                            }

                            {!isLoadingProject() && !hasErrorProject() &&
                                <Box display="flex" flexDirection="column" style={{ minHeight: 'calc((100vh * (2/5)) - ((64px + 24px + 24px + 24px + 24px)*(2/5)))' }}>
                                    <Box px={3} pt={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                        <Typography variant="h3" className={classes.title}>
                                            Data for Projects
                                        </Typography>
                                    </Box>

                                    <Box mt={2} style={{ backgroundColor: grey200, height: '1px' }}></Box>

                                    {/* AGING STATUS */}
                                    <Box px={3} pt={4} display="flex" flexDirection="column" alignItems="flex-start">
                                        <InputLabel className={classes.input_title}>Aging Status</InputLabel>
                                        <Box mt={3} display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {activeAgingStatus?.agingStatus.map(i => {
                                                return (
                                                    <Box key={i.agingStatusId} className={classes.standard_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box >
                                                            {i.name}{i.description?.length > 0 ? ` (${i.description})` : ''}
                                                        </Box>
                                                        <AddEditAgingStatus edit={true} headline={'Aging Status'} agingStatus={i} mutateFunction={updateAgingStatus} maxCharacterName={100} maxCharacterDescription={255} activeValues={activeAgingStatus} inactiveValues={inactiveAgingStatus} queryName={'agingStatus'} />
                                                        <DeleteData headline={'Aging Status'} agingStatus={i} name={i.name} idValue={i.agingStatusId} mutateFunction={updateAgingStatus} activate={false} />
                                                    </Box>
                                                )
                                            })}
                                            <AddEditAgingStatus edit={false} headline={'Aging Status'} mutateFunction={addAgingStatus} maxCharacterName={100} maxCharacterDescription={255} activeValues={activeAgingStatus} inactiveValues={inactiveAgingStatus} queryName={'agingStatus'} />
                                        </Box>
                                        <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {inactiveAgingStatus?.agingStatus.map(i => {
                                                return (
                                                    <Box key={i.agingStatusId} className={classes.inactive_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box >
                                                            {i.name}{i.description?.length > 0 ? ` (${i.description})` : ''}
                                                        </Box>
                                                        <DeleteData headline={'Aging Status'} agingStatus={i} name={i.name} idValue={i.agingStatusId} mutateFunction={updateAgingStatus} activate={true} />
                                                    </Box>
                                               )
                                            })}
                                        </Box>
                                    </Box>

                                    {/* LEADING DEVELOMENT */}
                                    <Box px={3} pt={3} display="flex" flexDirection="column" alignItems="flex-start">
                                        <InputLabel className={classes.input_title}>Leading Developments</InputLabel>
                                        <Box mt={3} display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {activeLeadingDevelopments?.leadingDevelopments.map(i => {
                                                return (
                                                    <Box key={i.leadingDevelopmentId} className={classes.standard_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box >
                                                            {i.name}
                                                        </Box>
                                                        <AddEditData edit={true} headline={'Leading Development'} name={i.name} idValue={i.leadingDevelopmentId} mutateFunction={updateLeadingDevelopment} maxCharacter={50} activeValues={activeLeadingDevelopments} inactiveValues={inactiveLeadingDevelopments} queryName={'leadingDevelopments'} />
                                                        <DeleteData headline={'Leading Development'} name={i.name} idValue={i.leadingDevelopmentId} mutateFunction={updateLeadingDevelopment} activate={false} />
                                                    </Box>
                                                )
                                            })}
                                            <AddEditData edit={false} headline={'Leading Development'} mutateFunction={addLeadingDevelopment} maxCharacter={50} activeValues={activeLeadingDevelopments} inactiveValues={inactiveLeadingDevelopments} queryName={'leadingDevelopments'} />
                                        </Box>
                                        <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {inactiveLeadingDevelopments?.leadingDevelopments.map(i => {
                                                return (
                                                    <Box key={i.leadingDevelopmentId} className={classes.inactive_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box >
                                                            {i.name}
                                                        </Box>
                                                        <DeleteData headline={'Leading Development'} name={i.name} idValue={i.leadingDevelopmentId} mutateFunction={updateLeadingDevelopment} activate={true} />
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    </Box>

                                    {/* ROLES */}
                                    <Box px={3} pt={3} display="flex" flexDirection="column" alignItems="flex-start">
                                        <InputLabel className={classes.input_title}>Project Roles</InputLabel>
                                        <Box mt={3} display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {activeRoles?.projectRoles.map(i => {
                                                return (
                                                    <Box key={i.roleId} className={classes.standard_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box>
                                                            {i.name}
                                                        </Box>
                                                        <AddEditData edit={true} headline={'Project Role'} name={i.name} idValue={i.roleId} mutateFunction={updateRole} maxCharacter={50} activeValues={activeRoles} inactiveValues={inactiveRoles} queryName={'projectRoles'} />
                                                        <DeleteData headline={'Project Role'} name={i.name} idValue={i.roleId} mutateFunction={updateRole} activate={false} />
                                                    </Box>
                                                )
                                            })}
                                            <AddEditData edit={false} headline={'Project Role'} mutateFunction={addRole} maxCharacter={50} activeValues={activeRoles} inactiveValues={inactiveRoles} queryName={'projectRoles'} />
                                        </Box>
                                        <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {inactiveRoles?.projectRoles.map(i => {
                                                return (
                                                    <Box key={i.roleId} className={classes.inactive_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box >
                                                            {i.name}
                                                        </Box>
                                                        <DeleteData headline={'Project Role'} name={i.name} idValue={i.roleId} mutateFunction={updateRole} activate={true} />
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    </Box>

                                    {/* PROJECT TYPES */}
                                    <Box px={3} pt={3} display="flex" flexDirection="column" alignItems="flex-start">
                                        <InputLabel className={classes.input_title}>Project Types</InputLabel>
                                        <Box mt={3} display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {activeProjectTypes?.projectTypes.map(i => {
                                                return (
                                                    <Box key={i.projectTypeId} className={classes.standard_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box >
                                                            {i.name}
                                                        </Box>
                                                        <AddEditData edit={true} headline={'Project Type'} name={i.name} idValue={i.projectTypeId} mutateFunction={updateProjectType} maxCharacter={50} activeValues={activeProjectTypes} inactiveValues={activeProjectTypes} queryName={'projectTypes'} />
                                                        <DeleteData headline={'Project Type'} name={i.name} idValue={i.projectTypeId} mutateFunction={updateProjectType} activate={false} />
                                                    </Box>
                                                )
                                            })}
                                            <AddEditData edit={false} headline={'Project Type'} mutateFunction={addProjectType} maxCharacter={50} activeValues={activeProjectTypes} inactiveValues={activeProjectTypes} queryName={'projectTypes'} />
                                        </Box>
                                        <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {inactiveProjectTypes?.projectTypes.map(i => {
                                                return (
                                                    <Box key={i.projectTypeId} className={classes.inactive_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box >
                                                            {i.name}
                                                        </Box>
                                                        <DeleteData headline={'Project Type'} name={i.name} idValue={i.projectTypeId} mutateFunction={updateProjectType} activate={true} />
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    </Box>

                                    {/* TEST INSTANCES */}
                                    <Box px={3} pt={3} display="flex" flexDirection="column" alignItems="flex-start">
                                        <InputLabel className={classes.input_title}>Test Instances</InputLabel>
                                        <Box mt={3} display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {activeTestInstances?.testInstances.map(i => {
                                                return (
                                                    <Box key={i.testInstanceId} className={classes.standard_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box>
                                                            {i.name}
                                                        </Box>
                                                       <AddEditData edit={true} headline={'Test Instance'} name={i.name} idValue={i.testInstanceId} mutateFunction={updateTestInstance} maxCharacter={50} activeValues={activeTestInstances} inactiveValues={inactiveTestInstances} queryName={'testInstances'} />
                                                        <DeleteData headline={'Test Instance'} name={i.name} idValue={i.testInstanceId} mutateFunction={updateTestInstance} activate={false} />
                                                    </Box>
                                                )
                                            })}
                                            <AddEditData edit={false} headline={'Test Instance'} mutateFunction={addTestInstance} maxCharacter={50} activeValues={activeTestInstances} inactiveValues={inactiveTestInstances} queryName={'testInstances'} />
                                        </Box>
                                        <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {inactiveTestInstances?.testInstances.map(i => {
                                                return (
                                                    <Box key={i.testInstanceId} className={classes.inactive_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box >
                                                            {i.name}
                                                        </Box>
                                                        <DeleteData headline={'Test Instance'} name={i.name} idValue={i.testInstanceId} mutateFunction={updateTestInstance} activate={true} />
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    </Box>

                                    {/* PLATFORMS */}
                                    <Box px={3} pt={3} pb={2} display="flex" flexDirection="column" alignItems="flex-start">
                                        <InputLabel className={classes.input_title}>Vehicle Platforms</InputLabel>
                                        <Box mt={3} display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {activePlatforms?.platforms.map(i => {
                                                return (
                                                    <Box key={i.platformId} className={classes.standard_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box>
                                                            {i.name}
                                                        </Box>
                                                        <AddEditData edit={true} headline={'Vehicle Platform'} name={i.name} idValue={i.platformId} mutateFunction={updatePlatform} maxCharacter={50} activeValues={activePlatforms} inactiveValues={inactivePlatforms} queryName={'platforms'} />
                                                        <DeleteData headline={'Vehicle Platform'} name={i.name} idValue={i.platformId} mutateFunction={updatePlatform} activate={false} />
                                                    </Box>
                                                )
                                            })}
                                            <AddEditData edit={false} headline={'Vehicle Platform'} mutateFunction={addPlatform} maxCharacter={50} activeValues={activePlatforms} inactiveValues={inactivePlatforms} queryName={'platforms'} />
                                        </Box>
                                        <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {inactivePlatforms?.platforms.map(i => {
                                                return (
                                                    <Box key={i.platformId} className={classes.inactive_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box >
                                                            {i.name}
                                                        </Box>
                                                        <DeleteData headline={'Vehicle Platform'} name={i.name} idValue={i.platformId} mutateFunction={updatePlatform} activate={true} />
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    </Box>

                                </Box>
                            }
                        </Paper>
                    </Grid>


                    <Grid item xs={12} display="grid">
                        <Paper className={classes.paper} style={{ minHeight: 'calc((100vh * (2/5)) - ((64px + 24px + 24px + 24px + 24px)*(2/5)))' }}>

                            {/* ERROR */}
                            {hasErrorTestSpec() &&
                                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc((100vh * (2/5)) - ((64px + 24px + 24px + 24px + 24px)*(2/5)))' }}>
                                    <Box px={3} pt={2} display="flex" flexDirection="row" alignItems="center">
                                        <Typography variant="h3" className={classes.title}>
                                            Data for Test Specifications
                                        </Typography>
                                    </Box>
                                    <Box mx={3} pt={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow="1">
                                        <Icon iconName={"WarningIcon"} width={54} height={54} fill={signalRed800} />
                                        <Typography variant="h3" className={classes.error_text}>
                                            Something went wrong. The following errors have occurred:
                                        </Typography>
                                        {activeGroupStandardReferencesError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {activeGroupStandardReferencesError.message}
                                            </Typography>
                                        }
                                        {inactiveGroupStandardReferencesError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {inactiveGroupStandardReferencesError.message}
                                            </Typography>
                                        }
                                        {activeCategoriesError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {activeCategoriesError.message}
                                            </Typography>
                                        }
                                        {inactiveCategoriesError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {inactiveCategoriesError.message}
                                            </Typography>
                                        }
                                        {activeApprovalsError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {activeApprovalsError.message}
                                            </Typography>
                                        }
                                        {inactiveApprovalsError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {inactiveApprovalsError.message}
                                            </Typography>
                                        }
                                        {activePhysicalQuantitiesError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {activePhysicalQuantitiesError.message}
                                            </Typography>
                                        }
                                        {inactivePhysicalQuantitiesError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {inactivePhysicalQuantitiesError.message}
                                            </Typography>
                                        }
                                        {allPhysicalQuantitiesError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {allPhysicalQuantitiesError.message}
                                            </Typography>
                                        }
                                        {activeParametersError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {activeParametersError.message}
                                            </Typography>
                                        }
                                       {inactiveParametersError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {inactiveParametersError.message}
                                            </Typography>
                                        }
                                        {activeUnitsError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {activeUnitsError.message}
                                            </Typography>
                                        }
                                        {inactiveUnitsError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {inactiveUnitsError.message}
                                            </Typography>
                                        }
                                        {allUnitsError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {allUnitsError.message}
                                            </Typography>
                                        }
                                    </Box>
                                    <Box my={4} display="flex" alignItems="center" justifyContent="center">
                                        <StyledNavLink to="/">
                                            <Button
                                                variant="outlined"
                                                color="primary">
                                                Cancel
                                            </Button>
                                        </StyledNavLink>
                                    </Box>
                                </Box>
                            }

                            {/* LOADING */}
                            {isLoadingTestSpec() && !hasErrorTestSpec() &&
                                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc((100vh * (2/5)) - ((64px + 24px + 24px + 24px + 24px)*(2/5)))' }}>
                                    <Box px={3} pt={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                        <Typography variant="h3" className={classes.title}>
                                            Data for Test Specifications
                                        </Typography>
                                    </Box>
                                    <Box m={3} display="flex" alignItems="center" justifyContent="center" flexGrow="1">
                                        <BatteryLoader type="standard" />
                                    </Box>
                                </Box>
                            }

                            {!isLoadingTestSpec() && !hasErrorTestSpec() &&
                                <Box display="flex" flexDirection="column" style={{ minHeight: 'calc((100vh * (2/5)) - ((64px + 24px + 24px + 24px + 24px)*(2/5)))' }}>
                                    <Box px={3} pt={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                        <Typography variant="h3" className={classes.title}>
                                            Data for Test Specifications
                                        </Typography>
                                    </Box>

                                    <Box mt={2} style={{ backgroundColor: grey200, height: '1px' }}></Box>

                                    {/* APPROVALS */}
                                    <Box px={3} pt={4} display="flex" flexDirection="column" alignItems="flex-start">
                                        <InputLabel className={classes.input_title}>Approvals</InputLabel>
                                        <Box mt={3} display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {activeApprovals?.approvals?.map(i => {
                                                return (
                                                    <Box key={i.approvalId} className={classes.standard_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box>
                                                            {i.name}
                                                        </Box>
                                                        <AddEditData edit={true} headline={'Approval'} name={i.name} idValue={i.approvalId} mutateFunction={updateApproval} maxCharacter={50} activeValues={activeApprovals} inactiveValues={inactiveApprovals} queryName={'approvals'} />
                                                        <DeleteData headline={'Approval'} name={i.name} idValue={i.approvalId} mutateFunction={updateApproval} activate={false} />
                                                    </Box>
                                                )
                                            })}
                                            <AddEditData edit={false} headline={'Approval'} mutateFunction={addApproval} maxCharacter={50} activeValues={activeApprovals} inactiveValues={inactiveApprovals} queryName={'approvals'} />
                                        </Box>
                                        <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {inactiveApprovals?.approvals.map(i => {
                                                return (
                                                    <Box key={i.approvalId} className={classes.inactive_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box >
                                                            {i.name}
                                                        </Box>
                                                        <DeleteData headline={'Approval'} name={i.name} idValue={i.approvalId} mutateFunction={updateApproval} activate={true} />
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    </Box>

                                    {/* CATEGORIES */}
                                    <Box px={3} pt={3} display="flex" flexDirection="column" alignItems="flex-start">
                                        <InputLabel className={classes.input_title}>Categories</InputLabel>
                                        <Box mt={3} display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {activeCategories?.categories.map(i => {
                                                return (
                                                    <Box key={i.categoryId} className={classes.standard_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box>
                                                            {i.name}
                                                        </Box>
                                                        <AddEditData edit={true} headline={'Category'} name={i.name} idValue={i.categoryId} mutateFunction={updateCategory} maxCharacter={50} activeValues={activeCategories} inactiveValues={inactiveCategories} queryName={'categories'} />
                                                        <DeleteData headline={'Category'} name={i.name} idValue={i.categoryId} mutateFunction={updateCategory} activate={false} />
                                                    </Box>
                                                )
                                            })}
                                            <AddEditData edit={false} headline={'Category'} mutateFunction={addCategory} maxCharacter={50} activeValues={activeCategories} inactiveValues={inactiveCategories} queryName={'categories'} />
                                        </Box>
                                        <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {inactiveCategories?.categories.map(i => {
                                                return (
                                                    <Box key={i.categoryId} className={classes.inactive_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box >
                                                            {i.name}
                                                        </Box>
                                                        <DeleteData headline={'Category'} name={i.name} idValue={i.categoryId} mutateFunction={updateCategory} activate={true} />
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    </Box>

                                    {/* PARAMETERS */}
                                    <Box px={2} pt={3} display="flex" flexDirection="column" alignItems="flex-start">
                                        <InputLabel className={classes.input_title} style={{ marginLeft: "8px" }}>Parameters</InputLabel>
                                        <Box mt={3} display="flex" flexDirection="row" alignItems="flex-start" flexWrap="wrap">
                                            {activeParameters?.parameters.map(i => {
                                                return (
                                                    <Box key={i.parameterId} className={classes.parameter_box}>
                                                        <Box px={2} pt={2} pb={1.5} display="flex" alignItems="center" justifyContent="space-between" >
                                                            <ParameterNameInfo name={i.name} />
                                                            <Box display="flex">
                                                                <AddEditParameter edit={true} headline={'Parameter'} mutateFunction={updateParameter} maxCharacterName={50} maxCharacterDescription={250} currentParameter={i} activeValues={activeParameters} inactiveValues={inactiveParameters} unitValues={allUnits} physicalQuantityValues={allPhysicalQuantities} />
                                                                <DeleteData headline={'Parameter'} parameter={i} name={i.name} idValue={i.parameterId} mutateFunction={updateParameter} activate={false} />
                                                            </Box>
                                                        </Box>
                                                        <Box px={2} pb={2} display="flex" alignItems="center" justifyContent="flex-start" className={classes.parameter_description_wrapper}>
                                                            <ParameterDescriptionInfo description={i.description} />
                                                        </Box>
                                                        <Box px={2} pb={1.5} display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                                                            <Box className={classes.parameter_subtitle}>Physical Quantity: </Box>
                                                            <Box className={classes.parameter_one_line}>{i.physicalQuantity.name}</Box>
                                                        </Box>
                                                        <Box px={2} pb={2} display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                                                            <Box className={classes.parameter_subtitle}>Unit: </Box>
                                                            <Box className={classes.parameter_one_line}>{i.unit === null ? "-" : i.unit.abbreviation}</Box>
                                                        </Box>
                                                    </Box>
                                                )
                                            })}
                                            <AddEditParameter edit={false} headline={'Parameter'} mutateFunction={addParameter} maxCharacterName={50} maxCharacterDescription={255} activeValues={activeParameters} inactiveValues={inactiveParameters} unitValues={activeUnits} physicalQuantityValues={activePhysicalQuantities} />
                                        </Box>
                                        <Box display="flex" flexDirection="row" alignItems="flex-start" flexWrap="wrap">
                                            {inactiveParameters?.parameters.map(i => {
                                                return (
                                                    <Box key={i.parameterId} className={classes.parameter_box} style={{ backgroundColor: red400, color: '#FFF' }}>
                                                        <Box px={2} pt={2} pb={1.5} display="flex" alignItems="center" justifyContent="space-between" >
                                                            <ParameterNameInfo name={i.name} inactive={true} />
                                                            <Box display="flex">
                                                                <DeleteData headline={'Parameter'} parameter={i} name={i.name} idValue={i.parameterId} mutateFunction={updateParameter} activate={true} />
                                                            </Box>
                                                        </Box>
                                                        <Box px={2} pb={2} display="flex" alignItems="center" justifyContent="flex-start" className={classes.parameter_description_wrapper}>
                                                            <ParameterDescriptionInfo description={i.description} />
                                                        </Box>
                                                        <Box px={2} pb={1.5} display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                                                            <Box className={classes.parameter_subtitle} style={{ color: '#FFF' }}>Physical Quantity: </Box>
                                                            <Box className={classes.parameter_one_line} style={{ color: '#FFF' }}>{i.physicalQuantity.name}</Box>
                                                        </Box>
                                                        <Box px={2} pb={2} display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                                                            <Box className={classes.parameter_subtitle} style={{ color: '#FFF' }}>Unit: </Box>
                                                            <Box className={classes.parameter_one_line} style={{ color: '#FFF' }}>{i.unit === null ? "-" : i.unit.abbreviation}</Box>
                                                        </Box>
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    </Box>

                                    {/* PHYSICAL QUANTITIES */}
                                    <Box px={3} pt={3} display="flex" flexDirection="column" alignItems="flex-start">
                                        <InputLabel className={classes.input_title}>Physical Quantitites</InputLabel>
                                        <Box mt={3} display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {activePhysicalQuantities?.physicalQuantities.map(i => {
                                                return (
                                                    <Box key={i.physicalQuantityId} className={classes.standard_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box>
                                                            {i.name}
                                                        </Box>
                                                        <AddEditData edit={true} headline={'Physical Quantity'} name={i.name} idValue={i.physicalQuantityId} maxCharacter={50} mutateFunction={updatePhysicalQuantity} activeValues={activePhysicalQuantities} inactiveValues={inactivePhysicalQuantities} queryName={'physicalQuantities'} />
                                                        <DeleteData headline={'Physical Quantity'} name={i.name} idValue={i.physicalQuantityId} mutateFunction={updatePhysicalQuantity} activate={false} />
                                                    </Box>
                                                )
                                            })}
                                            <AddEditData edit={false} headline={'Physical Quantity'} mutateFunction={addPhysicalQuantity} maxCharacter={50} activeValues={activePhysicalQuantities} inactiveValues={inactivePhysicalQuantities} queryName={'physicalQuantities'} />
                                        </Box>
                                        <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {inactivePhysicalQuantities?.physicalQuantities.map(i => {
                                                return (
                                                    <Box key={i.physicalQuantityId} className={classes.inactive_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box >
                                                            {i.name}
                                                        </Box>
                                                        <DeleteData headline={'Physical Quantity'} name={i.name} idValue={i.physicalQuantityId} mutateFunction={updatePhysicalQuantity} activate={true} />
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    </Box>

                                    {/* REFRENCES */}
                                    <Box px={3} pt={3} display="flex" flexDirection="column" alignItems="flex-start">
                                        <InputLabel className={classes.input_title}>References</InputLabel>
                                        <Box mt={3} display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {activeGroupStandardReferences?.groupStandardReferences.map(i => {
                                                return (
                                                    <Box key={i.groupStandardReferenceId} className={classes.standard_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box>
                                                            {i.name}
                                                        </Box>
                                                        <AddEditData edit={true} headline={'Reference'} name={i.name} idValue={i.groupStandardReferenceId} maxCharacter={50} mutateFunction={updateGroupStandardReference} activeValues={activeGroupStandardReferences} inactiveValues={inactiveGroupStandardReferences} queryName={'groupStandardReferences'} />
                                                        <DeleteData headline={'Reference'} name={i.name} idValue={i.groupStandardReferenceId} mutateFunction={updateGroupStandardReference} activate={false} />
                                                    </Box>
                                                )
                                            })}
                                            <AddEditData edit={false} headline={'Reference'} mutateFunction={addGroupStandardReference} maxCharacter={50} activeValues={activeGroupStandardReferences} inactiveValues={inactiveGroupStandardReferences} queryName={'groupStandardReferences'} />
                                        </Box>
                                        <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {inactiveGroupStandardReferences?.groupStandardReferences.map(i => {
                                                return (
                                                    <Box key={i.groupStandardReferenceId} className={classes.inactive_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box >
                                                            {i.name}
                                                        </Box>
                                                        <DeleteData headline={'Reference'} name={i.name} idValue={i.groupStandardReferenceId} mutateFunction={updateGroupStandardReference} activate={true} />
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    </Box>

                                    {/* UNITS */}
                                    <Box px={3} pt={3} pb={2} display="flex" flexDirection="column" alignItems="flex-start">
                                        <InputLabel className={classes.input_title}>Units</InputLabel>
                                        <Box mt={3} display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {activeUnits?.units.map(i => {
                                                return (
                                                    <Box key={i.unitId} className={classes.standard_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box>
                                                            {i.abbreviation} ({i.name})
                                                        </Box>
                                                        <AddEditUnit edit={true} headline={'Unit'} unit={i} mutateFunction={updateUnit} maxCharacterName={50} maxCharacterAbbreviation={30} activeValues={activeUnits} inactiveValues={inactiveUnits} queryName={'units'} />
                                                        <DeleteData headline={'Unit'} unit={i} name={i.name} idValue={i.unitId} mutateFunction={updateUnit} activate={false} />
                                                    </Box>
                                                )
                                            })}
                                            <AddEditUnit edit={false} headline={'Unit'} mutateFunction={addUnit} maxCharacterName={50} maxCharacterAbbreviation={30} activeValues={activeUnits} inactiveValues={inactiveUnits} queryName={'units'} />
                                        </Box>
                                        <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {inactiveUnits?.units.map(i => {
                                                return (
                                                    <Box key={i.unitId} className={classes.inactive_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box >
                                                            {i.abbreviation} ({i.name})
                                                        </Box>
                                                        <DeleteData headline={'Unit'} unit={i} name={i.name} idValue={i.unitId} mutateFunction={updateUnit} activate={true} />
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    </Box>

                                </Box>
                            }
                        </Paper>
                    </Grid>

                    <Grid item xs={12} display="grid">
                        <Paper className={classes.paper} style={{ minHeight: 'calc((100vh * (1/5)) - ((64px + 24px + 24px + 24px + 24px)*(1/5)))' }}>

                            {/* ERROR */}
                            {hasErrorSupplier() &&
                                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc((100vh * (1/5)) - ((64px + 24px + 24px + 24px + 24px)*(1/5)))' }}>
                                    <Box px={3} pt={2} display="flex" flexDirection="row" alignItems="center">
                                        <Typography variant="h3" className={classes.title}>
                                            Data for Suppliers
                                        </Typography>
                                    </Box>
                                    <Box mx={3} pt={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow="1">
                                        <Icon iconName={"WarningIcon"} width={54} height={54} fill={signalRed800} />
                                        <Typography variant="h3" className={classes.error_text}>
                                            Something went wrong. The following errors have occurred:
                                        </Typography>
                                        {activeRatingsError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {activeRatingsError.message}
                                            </Typography>
                                        }
                                        {inactiveRatingsError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {inactiveRatingsError.message}
                                            </Typography>
                                        }
                                        {activeSupplierPortfoliosError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {activeSupplierPortfoliosError.message}
                                            </Typography>
                                        }
                                        {inactiveSupplierPortfoliosError !== undefined &&
                                            <Typography variant="h5" className={classes.error_type}>
                                                {inactiveSupplierPortfoliosError.message}
                                            </Typography>
                                        }
                                    </Box>
                                    <Box my={4} display="flex" alignItems="center" justifyContent="center">
                                        <StyledNavLink to="/">
                                            <Button
                                                variant="outlined"
                                                color="primary">
                                                Cancel
                                            </Button>
                                        </StyledNavLink>
                                    </Box>
                                </Box>
                            }

                            {/* LOADING */}
                            {isLoadingSupplier() && !hasErrorSupplier() &&
                                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc((100vh * (1/5)) - ((64px + 24px + 24px + 24px + 24px)*(1/5)))' }}>
                                    <Box px={3} pt={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                        <Typography variant="h3" className={classes.title}>
                                            Data for Suppliers
                                        </Typography>
                                    </Box>
                                    <Box m={3} display="flex" alignItems="center" justifyContent="center" flexGrow="1">
                                        <BatteryLoader type="standard" />
                                    </Box>
                                </Box>
                            }

                            {!isLoadingSupplier() && !hasErrorSupplier() &&
                                <Box display="flex" flexDirection="column" style={{ minHeight: 'calc((100vh * (1/5)) - ((64px + 24px + 24px + 24px + 24px)*(1/5)))' }}>
                                    <Box px={3} pt={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                        <Typography variant="h3" className={classes.title}>
                                            Data for Suppliers
                                        </Typography>
                                    </Box>

                                    <Box mt={2} style={{ backgroundColor: grey200, height: '1px' }}></Box>

                                    {/* RATINGS */}
                                    <Box px={3} pt={4} display="flex" flexDirection="column" alignItems="flex-start">
                                        <InputLabel className={classes.input_title}>Ratings</InputLabel>
                                        <Box mt={3} display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {activeRatings?.ratings.map(i => {
                                                return (
                                                    <Box key={i.ratingId} className={classes.standard_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box>
                                                            {i.name}
                                                        </Box>
                                                        <AddEditData edit={true} headline={'Rating'} name={i.name} idValue={i.ratingId} mutateFunction={updateRating} maxCharacter={50} activeValues={activeRatings} inactiveValues={inactiveRatings} queryName={'ratings'} />
                                                        <DeleteData headline={'Rating'} name={i.name} idValue={i.ratingId} mutateFunction={updateRating} activate={false} />
                                                    </Box>
                                                )
                                            })}
                                            <AddEditData edit={false} headline={'Rating'} mutateFunction={addRating} maxCharacter={50} activeValues={activeRatings} inactiveValues={inactiveRatings} queryName={'ratings'} />
                                        </Box>
                                        <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {inactiveRatings?.ratings.map(i => {
                                                return (
                                                    <Box key={i.ratingId} className={classes.inactive_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box >
                                                            {i.name}
                                                        </Box>
                                                        <DeleteData headline={'Rating'} name={i.name} idValue={i.ratingId} mutateFunction={updateRating} activate={true} />
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    </Box>

                                    {/* SUPPLIER PORTFOLIO */}
                                    <Box px={3} pt={3} pb={2} display="flex" flexDirection="column" alignItems="flex-start">
                                        <InputLabel className={classes.input_title}>Supplier Portfolios</InputLabel>
                                        <Box mt={3} display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {activeSupplierPortfolios?.supplierPortfolios.map(i => {
                                                return (
                                                    <Box key={i.supplierPortfolioId} className={classes.standard_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box>
                                                            {i.name}
                                                        </Box>
                                                        <AddEditData edit={true} headline={'Supplier Portfolio'} name={i.name} idValue={i.supplierPortfolioId} mutateFunction={updateSupplierPortfolio} maxCharacter={50} activeValues={activeSupplierPortfolios} inactiveValues={inactiveSupplierPortfolios} queryName={'supplierPortfolios'} />
                                                        <DeleteData headline={'Supplier Portfolio'} name={i.name} idValue={i.supplierPortfolioId} mutateFunction={updateSupplierPortfolio} activate={false} />
                                                    </Box>
                                                )
                                            })}
                                            <AddEditData edit={false} headline={'Supplier Portfolio'} mutateFunction={addSupplierPortfolio} maxCharacter={50} activeValues={activeSupplierPortfolios} inactiveValues={inactiveSupplierPortfolios} queryName={'supplierPortfolios'} />
                                        </Box>
                                        <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                                            {inactiveSupplierPortfolios?.supplierPortfolios.map(i => {
                                                return (
                                                    <Box key={i.supplierPortfolioId} className={classes.inactive_box} display="flex" flexDirection="row" alignItems="center">
                                                        <Box >
                                                            {i.name}
                                                        </Box>
                                                        <DeleteData headline={'Supplier Portfolio'} name={i.name} idValue={i.supplierPortfolioId} mutateFunction={updateSupplierPortfolio} activate={true} />
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    </Box>
                                </Box>
                            }
                        </Paper>
                    </Grid>

                </Grid>
                <ScrollUpIcon />
            </div >
        </>
    )
}

export default DataManagement;
