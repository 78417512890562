import React from 'react';
import { IconButton } from '@mui/material';
import Icon from '../../../assets/icons/Icon.js';
import { useStyles } from '../editing/Table.styles';


export default function CollapseRow({ collapsed, setAllTestCases, setCollapsed, allTestCases, testCaseList, sizeWindow }) {
    const classes = useStyles();
    
    return (
        <IconButton
            className={classes.collapseicon}
            aria-controls={collapsed ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={collapsed ? () => { setAllTestCases(0); setCollapsed(!collapsed); } : () => { setAllTestCases(allTestCases + testCaseList?.testCases?.length - 3); setCollapsed(!collapsed); }}
            >
            <Icon
                fill="rgb(3, 125, 166)"
                iconName={collapsed ? "ArrowUpIcon" : "ArrowDownIcon"}
                height={sizeWindow < 5 ? 20 : 24}    
                width={sizeWindow < 5 ? 20 : 24}
            />
        </IconButton>
    )
}
