import { createTheme } from '@mui/material/styles';

// GLOBAL COLORS
// https://volkswagen.frontify.com/d/rzB71PwpjXgt/documentation#/basics/color/usage
export const grey1000 = '#1B1E1F';
export const grey800 = '#4C5356';
export const grey600 = '#7A8084';     //grey icons
export const grey400 = '#A8ADB3';
export const grey200 = '#DFE2E6';
export const grey100 = '#F0F1F2';
export const petrol1000 = '#00354D';
export const petrol900 = '#004666';
export const petrol800 = '#20607E';
export const petrol700 = '#35718E';
export const petrol600 = '#4A849F';
export const petrol100 = '#EAF4F7';
export const signalRed800 = '#DA0C1F';
export const signalGreen800 = '#64A844';
export const signalOrange800 = '#FF9B00';
export const red400 = '#C26D88';
export const red500 = '#BA5A79';
export const blue700 = '#268DE0';
export const red700 = '#A21E4D'

export const buttonHoverPetrol800 = 'rgba(32, 96, 126, 0.1)';
export const buttonHoverPetrol600 = 'rgba(74, 132, 159, 0.1)';
export const buttonHoverGrey800 = 'rgba(76, 83, 86, 0.1)';
export const buttonHoverSignalRed800 = 'rgba(218, 12, 31, 0.1)';
export const buttonPressedPetrol800 = 'rgba(32, 96, 126, 0.2)';

// creats an intermediate theme to make theme accesiable in createTheme 
export let theme = createTheme()

theme = createTheme(theme, {
  components: {
    MuiSnackbar: {
      variants: [
        {
          props: { variant: 'topbar' },
          style: {
            width: '100%',
            display: 'block',
            position: 'sticky'
          }
        },
        {
          props: { variant: 'bottomleft' },
          style: {
            width: '60%',
            bottom: '90px !important'
          }
        }
      ]
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: petrol800,
        }
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          height: '48px',
          fontSize: '16px',
          letterSpacing: '0.6px',
          borderBottom: `1px solid ${grey200}`,
          textTransform: 'none',
          "&.MuiTab-textColorPrimary": {
            color: petrol800,
            '&:hover': {
              backgroundColor: buttonHoverPetrol800,
            },
            "&.Mui-selected": {
              fontWeight: 'bold',
              color: petrol800,
              borderBottom: `2px solid ${petrol800}`,
            }
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontStyle: 'normal',
          fontWeight: '700',
          fontFamily: 'VWAG TheSans',
          color: petrol800,
          backgroundColor: '#FFF',
          fill: petrol800,
          height: '40px',
          border: `1px solid ${petrol800}`,
          borderRadius: 0,
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '0.0375rem',
          marginLeft: '0px !important',
          boxShadow: 'none !important',
          paddingLeft: '16px',
          paddingRight: '16px',
          textTransform: 'none',
          "&.Mui-selected": {
            color: '#FFF !important',
            backgroundColor: petrol800,
            fill: '#FFF',
            '&:hover': {
              backgroundColor: petrol800,
            }
          },
          '&:hover': {
            backgroundColor: buttonHoverPetrol800,
          }
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontFamily: 'VWAG TheSans',
          borderRadius: '0px',
          alignItems: 'center'
        },
        action: {
          padding: '0 0 0 16px',
        },
        filledSuccess: {
          backgroundColor: signalGreen800,
        },
        filledInfo: {
          backgroundColor: petrol800,
        },
        filledError: {
          backgroundColor: signalRed800,
        }
      }
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          fontFamily: 'VWAG TheSans',
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&$selected': {
            backgroundColor: '#EBF5F8',
            color: '#208CB0',
          },
          '&:hover': {
            backgroundColor: '#EBF5F8 !important',
            color: '#208CB0',
          },
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: 'VWAG TheSans !important',
        }
      }
    },
    MuiButton: {
      //check if we really need this!!! - A: Not all of it, but some..
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          borderRadius: '2px',
          boxShadow: 'none !important',
          height: '40px',
          fontWeight: '700',
          fontStyle: 'normal',
          fontSize: '16px',
          fontFamily: 'VWAG TheSans',
          lineHeight: '24px',
          letterSpacing: '0.0375rem',
          textAlign: 'center',
          textTransform: 'none',
          padding: '6px 16px !important',
          whiteSpace: 'nowrap',
          minWidth: 'auto',
        },
        outlined: {
          color: petrol800,
          '&.Mui-disabled': {
            color: grey400,
            backgroundColor: '#FFF',
            border: `1px solid ${grey400}`,
          },
          '&:hover': {
            backgroundColor: buttonHoverPetrol800,
            color: petrol800,
          },
        },
        // eg. ADD PROJECT
        outlinedPrimary: {
          border: `1px solid ${petrol800} !important`,
        },
        //  eg. EDIT
        outlinedSecondary: {
          border: '1px solid #FFF !important',
        },
        contained: {
          color: '#FFF',
          '&.Mui-disabled': {
            color: '#FFF',
            backgroundColor: grey400,
          },
        },
        // eg. SAVE
        containedPrimary: {
          backgroundColor: petrol800,
          '&:hover': {
            backgroundColor: petrol900,
          },
        },
        // eg. ADD MORE, SAMPLE STATE
        containedSecondary: {
          backgroundColor: petrol800,
          '&:hover': {
            backgroundColor: petrol900,
          },
        },
        text: {
          border: '0px',
          color: petrol800,
          '&:hover': {
            backgroundColor: grey100,
          },
        },
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          padding: '0px !important',
        },
        inputRoot: {
          height: '40px',
          padding: '6px 24px 6px 16px !important',
          // border: `1px solid ${grey400}`,
          boxSizing: "border-box",
          borderRadius: "2px",
          display: 'flex',
          alignItems: 'center',
          color: grey600,
          fontWeight: "400",
          fontSize: "16px",
          fontStyle: 'normal',
          lineHeight: "28px",
          letterSpacing: "0.3px",
          margin: "0px",
        },
        clearIndicator: {
          display: 'flex',
          alignItems: 'center',
          marginRight: '4px',
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'VWAG TheSans',
          letterSpacing: 0,
        },
        h1: {
          color: grey1000,
          fontWeight: "700",
          fontSize: "28px",
          fontStyle: 'normal',
          lineHeight: "35px",
          letterSpacing: "0.4px",
        },
        h2: {
          color: grey1000,
          fontWeight: "700",
          fontSize: "24px",
          fontStyle: 'normal',
          lineHeight: "35px",
          letterSpacing: "0.4px",
        },
        h3: {
          color: grey1000,
          fontWeight: "700",
          fontSize: "20px",
          fontStyle: 'normal',
          lineHeight: "25px",
          letterSpacing: "0.6px",
        },
        h4: {
          color: grey1000,
          fontWeight: "700",
          fontSize: "18px",
          fontStyle: 'normal',
          lineHeight: "25px",
          letterSpacing: "0.4px",
        },
        h5: {
          color: grey1000,
          fontWeight: "700",
          fontSize: "16px",
          fontStyle: 'normal',
          lineHeight: "25px",
          letterSpacing: "0.4px",
        },
        h6: {
          color: grey1000,
          fontWeight: "700",
          fontSize: "14px",
          fontStyle: 'normal',
          lineHeight: "20px",
          letterSpacing: "0.7px",
        },
        subtitle1: {
          color: grey1000,
          fontWeight: "normal",
          fontSize: "16px",
          fontStyle: 'normal',
          lineHeight: "18px",
          letterSpacing: "0.8px",
          textAlign: "left",
        },
        subtitle2: {
          color: grey1000,
          fontWeight: "400",
          fontSize: "14px",
          fontStyle: 'normal',
          lineHeight: "21px",
          letterSpacing: "0.2px",
        },
        body1: {
          color: grey1000,
          fontWeight: "400",
          fontSize: "16px",
          letterSpacing: "0.2px",
          lineHeight: "150%",
        },        
        caption: {
          fontWeight: "400",
          fontSize: "14px",
          height: "20px",
          lineHeight: "20px",
          textTransform: 'uppercase',
          color: grey600,
        },
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '4px',
          "&.Mui-disabled": {
            backgroundColor: 'rgb(168, 173, 179)'
          },
          '&:hover': {
            backgroundColor: 'none',
            // borderRadius: '2px',
          },
        }
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: 'VWAG TheSans',
          borderRadius: '8px',
        },
        label: {
          paddingLeft: '16px',
          paddingRight: '16px',
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.1)',
        },
        elevation1: {
          boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1), 0px 3px 4px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.1)',
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            fontFamily: 'VWAG TheSans',
            backgroundColor: '#EBF5F8',
            color: '#208CB0',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthSm: {
          minWidth: '600px',
          maxWidth: '600px',
        },
        paperWidthMd: {
          minWidth: '960px',
          maxWidth: '960px',
        },
        paperWidthXl: {
          minWidth: '1168px',
          maxWidth: '1920px',
        },
        paper: {
          margin: '0px',
          borderRadius: '2px',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.1)',
          marginTop: '12.5vh',
          marginBottom: '12.5vh',
        },
        container: {
          backgroundColor: 'rgba(0, 70, 102, 0.9)',
        },
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '32px',
          msOverflowStyle: ' none',  /* IE and Edge */
          scrollbarWidth: 'none',  /* Firefox */
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }
      }
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          marginBottom: '0px',
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: grey1000,
          fontWeight: "700",
          fontSize: "14px",
          fontStyle: 'normal',
          fontFamily: 'VWAG TheSans',
          lineHeight: "20px",
          letterSpacing: "0.7px",
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          top: 'calc(0% + 8px)',
        },
        nativeInput: {
          top: '0',
        },
        select: {
          '&:focus': {
            borderRadius: "2px",
            backgroundColor: "none",
          },
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          height: '40px',
          border: `1px solid ${grey400}`,
          boxSizing: "border-box",
          borderRadius: "2px",
          display: 'flex',
          alignItems: 'center',
          color: grey1000,
          fontWeight: "400",
          fontSize: "16px",
          fontStyle: 'normal',
          lineHeight: "28px",
          letterSpacing: "0.3px",
          margin: "0px",
        },
        input: {
          padding: '6px 24px 6px 16px',
        },
        underline: {
          '&.Mui-disabled': {
            '&:before': {
              borderBottomStyle: "none",
            }
          },
          '&::before': {
            borderBottom: "none",
          },
          '&:hover': {
            borderBottom: "none !important",
          },
          '&:after': {
            borderBottom: "none !important",
          },
        },
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          paddingRight: '16px',
        }
      }
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          padding: '0px !important',
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'VWAG TheSans',
          minHeight: '40px !important',
          border: `1px solid ${grey400}`,
          boxSizing: "border-box",
          borderRadius: "2px",
          display: 'flex',
          alignItems: 'center',
          color: grey1000,
          margin: "0px",
          "&.Mui-focused": {
            border: `1px solid ${petrol800}`,
          },
          '&.Mui-error': {
            border: `1px solid ${signalRed800}`,
            color: signalRed800,
          },
        },
        input: {
          lineHeight: '28px',
          padding: '6px 16px 6px 16px !important',
        },
        // ROBERT TBD: textarea gets added once multiline={true} is applied. This sets fix height to 40px and adds 28px for every further line. (applying styles to textara in App.css solves problem for first line, but dynamic height does not work anymore)
        inputMultiline: {
          lineHeight: '28px',
          padding: '0px 16px !important',
          width: 'calc(100% - 32px)',
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: 0,
          borderRadius: "2px",
          "&.Mui-focused, &.Mui-error": {
            border: 0,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: petrol800,
          },
        }
      }
    },
    MainGridContainer: {
      styleOverrides: {
        width: '101%'
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '.MuiDataGrid-window': {
            width: '101%'
          },
          window: {
            width: '101%',
            overflowY: 'hidden'
          },
        },
        window: {
          width: '101%',
          overflowY: 'hidden'
        },
        row: {
          color: 'red',
        }
      }
    },
    MUIDataGridBodyCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#FF0000",
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontFamily: 'VWAG TheSans',
          paddingTop: '10px',
          paddingBottom: '10px',
          borderBottom: 'none',
          borderTop: `1px solid ${grey200}`
        }
      }
    }
  },
});