import React from 'react';
import { Paper, Grid, Box, IconButton } from '@mui/material';
import { useStyles } from '../TestSpecificationList.styles';
import Icon from '../../../../assets/icons/Icon.js';
import TestSpecificationListElement from '../TestSpecificationListElement/TestSpecificationListElement';
import { dynamicSort } from '../../../other/helper/globalFunctions/globalFunctions';
import { grey800 } from '../../../other/helper/MuiStyles';

function TestSpecificationListTable(props) {
    const { testSpecifications, testSpecKey, setTestSpecKey, sortTitleTestSpec, setSortTitleTestSpec, sortDraftTestSpec, setSortDraftTestSpec, sortCategoryTestSpec, setSortCategoryTestSpec, sortReferenceTestSpec, setSortReferenceTestSpec, sortApprovalTestSpec, setSortApprovalTestSpec } = props;

    const classes = useStyles();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} display="grid" id="testspecs">
                <Paper className={classes.paper}>

                    <Box className={classes.head} />
                    <Box px={4} display="flex" alignItems="center" className={classes.header}>
                        <Box display="flex" alignItems="center" className={classes.header1}>Title <IconButton data-testid={"test-specification-filter-name-button"} style={sortTitleTestSpec === true ? {marginLeft: '8px', padding: '4px'} : {transform: 'rotate(-180deg)', marginLeft: '8px', padding: '4px'}} onClick={() => { testSpecifications.sort(dynamicSort(sortTitleTestSpec === true ? "name" : "-name")); setSortTitleTestSpec(!sortTitleTestSpec); setTestSpecKey(!testSpecKey) }}><Icon iconName={"DirectionIcon"} width={14} height={14} fill={grey800} /></IconButton></Box>
                        <Box className={classes.header2}>Current Version</Box>
                        <Box display="flex" alignItems="center" className={classes.header6}>Status <IconButton data-testid={"test-specification-filter-status-button"} style={sortDraftTestSpec === true ? {marginLeft: '8px', padding: '4px'} : {transform: 'rotate(-180deg)', marginLeft: '8px', padding: '4px'}} onClick={() => { testSpecifications.sort(dynamicSort(sortDraftTestSpec === true ? "tag" : "-tag")); setSortDraftTestSpec(!sortDraftTestSpec); setTestSpecKey(!testSpecKey) }}><Icon iconName={"DirectionIcon"} width={14} height={14} fill={grey800} /></IconButton></Box>
                        <Box display="flex" alignItems="center" className={classes.header3}>Category <IconButton data-testid={"test-specification-filter-category-button"} style={sortCategoryTestSpec === true ? {marginLeft: '8px', padding: '4px'} : {transform: 'rotate(-180deg)', marginLeft: '8px', padding: '4px'}} onClick={() => { testSpecifications.sort(dynamicSort(sortCategoryTestSpec === true ? "name" : "-name", "category")); setSortCategoryTestSpec(!sortCategoryTestSpec); setTestSpecKey(!testSpecKey) }}><Icon iconName={"DirectionIcon"} width={14} height={14} fill={grey800} /></IconButton></Box>
                        <Box display="flex" alignItems="center" className={classes.header4}>Reference <IconButton data-testid={"test-specification-filter-reference-button"} style={sortReferenceTestSpec === true ? {marginLeft: '8px', padding: '4px'} : {transform: 'rotate(-180deg)', marginLeft: '8px', padding: '4px'}} onClick={() => { testSpecifications.sort(dynamicSort(sortReferenceTestSpec === true ? "name" : "-name", "groupStandardReference")); setSortReferenceTestSpec(!sortReferenceTestSpec); setTestSpecKey(!testSpecKey) }}><Icon iconName={"DirectionIcon"} width={14} height={14} fill={grey800} /></IconButton></Box>
                        <Box display="flex" alignItems="center" className={classes.header5}>Approval <IconButton data-testid={"test-specification-filter-approval-button"} style={sortApprovalTestSpec === true ? {marginLeft: '8px', padding: '4px'} : {transform: 'rotate(-180deg)', marginLeft: '8px', padding: '4px'}} onClick={() => { testSpecifications.sort(dynamicSort(sortApprovalTestSpec === true ? "name" : "-name", "approval")); setSortApprovalTestSpec(!sortApprovalTestSpec); setTestSpecKey(!testSpecKey) }}><Icon iconName={"DirectionIcon"} width={14} height={14} fill={grey800} /></IconButton></Box>
                    </Box>

                    {testSpecifications?.map((testSpecification) => {
                        return (
                            <TestSpecificationListElement
                                testSpecification={testSpecification}
                                key={testSpecification.testSpecificationId}
                            />)
                    })}
                    {testSpecifications?.length < 1 &&
                        <Box className={classes.border}>
                            <Box display="flex" alignItems="center" justifyContent="center" style={{ lineHeight: '48px', color: grey800 }}>No test specification available for currently selected filters or search term.</Box>
                        </Box>
                    }

                </Paper>
            </Grid>
        </Grid>
    )
}

export default TestSpecificationListTable;