import React, {useContext, useState} from 'react';
import { Button, Dialog, DialogContent, Box, Typography, IconButton, Backdrop } from '@mui/material';
import { useStyles } from '../UniversalDialog.styles.js';
import {
    SnackbarContext,
    SnackbarType
} from '../../contexts/SnackBarContext.js';
import { useNavigate } from 'react-router-dom';
import Icon from '../../../../assets/icons/Icon.js';
import { petrol800, signalRed800 } from '../../helper/MuiStyles.js';
import { useMutation } from '@apollo/client';
import { DELETEPROJECT } from '../../../../operations/mutations/mutations.js';
import { MYACCOUNT } from '../../../../operations/queries/query.js';
import BatteryLoader from '../../helper/BatteryLoader/BatteryLoader.js';

function DeleteProject(props) {
    const { project } = props;
    const { setIsSnackbarOpen, setMessage, setSnackbarType } = useContext(SnackbarContext);
    const navigate = useNavigate();

    const handleOnError = async (error) => {
        setMessage(error.message);
        setSnackbarType(SnackbarType.ERROR);
        setIsSnackbarOpen(true);
    }

    const handleOnCompleted = async () => {
        setMessage('Project has been deleted.');
        setSnackbarType(SnackbarType.SUCCESS);
        setIsSnackbarOpen(true);
        navigate("/")
    }

    const [deleteProject, { loading: deleteProjectLoading }] = useMutation(DELETEPROJECT, {
        onError: handleOnError,
        onCompleted: handleOnCompleted,
        refetchQueries: [{ query: MYACCOUNT }],
    });

    const [show, setShow] = useState(false);

    const ToggleModal = () => {
        setShow(!show);
    };

    const handleDeleteProject = async (e) => {
        e.preventDefault();
        deleteProject({ variables: { projectId: project.projectId } });
        setShow(false);
    };

    // useEffect

    const classes = useStyles();

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                className={classes.button_delete}
                onClick={ToggleModal}
                data-testid={"delete-project-button"}
            >
                <div style={{ display: 'flex', marginRight: '8px' }}>
                    <Icon iconName={"TrashIcon"} width={24} height={24} fill={signalRed800} />
                </div>
                Delete Project
            </Button>

            <Backdrop
                sx={{ backgroundColor: 'rgba(0,53,77, 0.90)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={deleteProjectLoading}
            >
                <Box display="flex" flexDirection="column">
                    <Box display="flex" justifyContent="center">
                        <BatteryLoader type="backdrop" />
                    </Box>
                    <Box>Project deletion can take up to 30 seconds.</Box>
                </Box>
            </Backdrop>

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={show}
                onClose={ToggleModal}
            >
                <DialogContent>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h2">
                            Delete {project.name}
                        </Typography>
                        <IconButton onClick={ToggleModal} className={classes.hover_blue}>
                            <Icon iconName={"CloseIcon"} fill={petrol800} />
                        </IconButton>
                    </Box>
                    <Box mt={2} display="flex" alignItems="center">
                        <Typography variant="h4" style={{ fontWeight: '400' }}>
                            Are you sure that you want to delete the selected project? <br />You will loose all information that belongs to this project.
                        </Typography>
                    </Box>
                    <Box mt={4} display="flex" alignItems="center" justifyContent="space-between">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleDeleteProject}
                            data-testid={"delete-project-confirmation-button"}
                        >
                            Delete Project
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default DeleteProject;