import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { MYACCOUNT } from '../../../operations/queries/query';
import { Paper, Grid, Box, Typography, Button } from '@mui/material';
import { useStyles } from './UserProfile.styles';
import { IMAGE_MAP } from '../../other/helper/ImgMapper/ImgMapper';
import BatteryLoader from '../../other/helper/BatteryLoader/BatteryLoader';
import ApplicationRoleInfo from '../../other/dialogs/ApplicationRoleInfo/ApplicationRoleInfo';
import Icon from '../../../assets/icons/Icon';
import { NameRoleHeader } from '../templates';
import { signalRed800 } from '../../other/helper/MuiStyles';

function UserProfile() {
    const navigate = useNavigate();
    const { userId } = useParams();

    const { data: myAccount, error: myAccountError, loading: myAccountLoading } = useQuery(MYACCOUNT);
    const classes = useStyles();

    const handleEditClick = () => {
        navigate({
            pathname: `/selectavatar/${userId}`
        });
    };

    const handleCancelClick = () => {
        navigate({
            pathname: `/`
        });
    };

    return (
        <div className={classes.root} >
            <Grid container spacing={3}>
                <Grid item xs={12} display="grid">
                    <Paper className={classes.paper}>

                        {/* ERROR */}
                        {myAccountError !== undefined &&

                            <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 64px - 24px - 24px)' }}>
                                <Box pt={4} >
                                    <Typography variant="h2" className={classes.title}>
                                        Profile
                                    </Typography>
                                </Box>
                                <Box mx={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow="1">
                                    <Icon iconName={"WarningIcon"} width={54} height={54} fill={signalRed800} />
                                    <Typography variant="h3" className={classes.error_text}>
                                        Something went wrong. The following errors have occurred:
                                    </Typography>
                                    {myAccountError !== undefined &&
                                        <Typography variant="h5" className={classes.error_type}>
                                            {myAccountError.message}
                                        </Typography>
                                    }
                                </Box>
                            </Box>
                        }

                        {/* LOADING */}
                        {myAccountLoading && myAccountError === undefined &&
                            <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 64px - 24px - 24px)' }}>
                                <Box pt={4} display="flex" alignItems="center" justifyContent="center">
                                    <Typography variant="h2" className={classes.title}>
                                        Profile
                                    </Typography>
                                </Box>
                                <Box mx={2} display="flex" alignItems="center" justifyContent="center" flexGrow="1">
                                    <BatteryLoader type="standard" />
                                </Box>
                            </Box>
                        }

                        {!myAccountLoading && myAccountError === undefined &&
                            <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 64px - 24px - 24px)' }}>
                                <Box display="flex" flexDirection="column">

                                    <Box pt={4} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                                        <Typography variant="h2" className={classes.title}>
                                            Profile
                                        </Typography>
                                    </Box>

                                    <Box mt={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                        <img src={IMAGE_MAP.AVATAR[myAccount?.myAccount?.avatarId]?.pngSrc} className={classes.avatar_image} alt="Avatar" />
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            className={classes.edit_avatar_button}
                                            onClick={handleEditClick}
                                        >
                                            Edit Avatar
                                        </Button>
                                    </Box>

                                    <NameRoleHeader myAccount={myAccount} />

                                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                                        <ApplicationRoleInfo />
                                    </Box>

                                    {/* LINE */}
                                    <Box mt={3} mx={3} className={classes.line}></Box>

                                    {/* MID VALUES */}
                                    <Box mt={5} display="flex" flexDirection="column" alignItems="start">
                                        <Box ml={3} display="flex" flexDirection="row" alignItems="flex-start">
                                            <Typography variant="h5" className={classes.attributes}>
                                                Department
                                            </Typography>
                                            <Typography variant="h5" className={classes.values}>
                                                {myAccount?.myAccount?.department}
                                            </Typography>
                                        </Box>

                                        <Box ml={3} display="flex" flexDirection="row" alignItems="flex-start">
                                            <Typography variant="h5" className={classes.attributes}>
                                                VW User ID
                                            </Typography>
                                            <Typography variant="h5" className={classes.values}>
                                                {myAccount?.myAccount?.userId}
                                            </Typography>
                                        </Box>

                                        <Box ml={3} display="flex" flexDirection="row" alignItems="flex-start">
                                            <Typography variant="h5" className={classes.attributes}>
                                                Projects
                                            </Typography>
                                            <Typography display="flex" flexDirection="row" alignItems="center" flexWrap="wrap" variant="h5" className={classes.values}>
                                                {myAccount?.myAccount?.projects?.length > 0 ? myAccount?.myAccount?.projects?.map(((project, index) => { return project.name + (index === myAccount?.myAccount?.projects?.length - 1 ? '' : ', ') })) : '-'}
                                            </Typography>
                                        </Box>

                                    </Box>

                                    {/* LINE */}
                                    <Box mt={1} mb={5} mx={3} className={classes.line}></Box>
                                </Box>

                                <Box mx={3} pb={3} display="flex" flexDirection="row" justifyContent="center">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleCancelClick}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </Box>
                        }
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default UserProfile;