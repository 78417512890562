export const handleInputChange = (setFunction1, values1, setFunction2, values2) => (event, child) => {
    setFunction1({ ...values1, [event.target.name]: event.target.value });
    setFunction2({ ...values2, [event.target.name]: child.props.datamutationvalue });
};

export const handleMutationChange = (setFunction, values) => (event, child) => {
    setFunction({ ...values, [event.target.name]: child.props.datamutationvalue });
};

export const handleInputChangeFreeText = (setFunction, values) => (event) => {
    setFunction({ ...values, [event.target.name]: event.target.value });
};

export const handleRemoveConditionColumn = (setSelectedConditions, selectedConditions, setConditionCounter, conditionCounter, column) => {
    let conditions = [...selectedConditions];
    conditions.forEach(function (currentElement) {
        currentElement.splice(column, 1);
    });
    setSelectedConditions(conditions);
    setConditionCounter(conditionCounter - 1);
};

export const formatSize = (size, decimals = 2) => {
    if (typeof size !== 'number' || size === 0) return ''

    const sizes = ['B', 'KB', 'MB', 'GB']
    const k = 1000  // assume there are 1000 B in KB
    const ind = Math.floor(Math.log(size) / Math.log(k))

    return `${(size / Math.pow(k, ind)).toFixed(decimals)} ${sizes[ind]}`
}

function dynamicSort(property, deepProperty) {
    let sortOrder = 1;

    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    switch (deepProperty) {
        case "category":
            return function (a, b) {
                if (sortOrder === -1) {
                    return b.category[property].toUpperCase().localeCompare(a.category[property].toUpperCase());
                } else {
                    return a.category[property].toUpperCase().localeCompare(b.category[property].toUpperCase());
                }
            };
        case "groupStandardReference":
            return function (a, b) {
                if (a.groupStandardReference === null) {
                    a.groupStandardReference = {
                        groupStandardReferenceId: "",
                        name: ""
                    }
                }
                if (b.groupStandardReference === null) {
                    b.groupStandardReference = {
                        groupStandardReferenceId: "",
                        name: ""
                    }
                }
                if (sortOrder === -1) {
                    return b.groupStandardReference[property].toUpperCase().localeCompare(a.groupStandardReference[property].toUpperCase());
                } else {
                    return a.groupStandardReference[property].toUpperCase().localeCompare(b.groupStandardReference[property].toUpperCase());
                }
            };
        case "approval":
            return function (a, b) {
                if (a.approval === null) {
                    a.approval = {
                        approvalId: "",
                        name: ""
                    }
                }
                if (b.approval === null) {
                    b.approval = {
                        approvalId: "",
                        name: ""
                    }
                }
                if (sortOrder === -1) {
                    return b.approval[property].toUpperCase().localeCompare(a.approval[property].toUpperCase());
                } else {
                    return a.approval[property].toUpperCase().localeCompare(b.approval[property].toUpperCase());
                }
            };
        case "supplier":
            return function (a, b) {
                if (sortOrder === -1) {
                    return b.cellSupplier[property].toUpperCase().localeCompare(a.cellSupplier[property].toUpperCase());
                } else {
                    return a.cellSupplier[property].toUpperCase().localeCompare(b.cellSupplier[property].toUpperCase());
                }
            };
        case "agingStatus":
            return function (a, b) {
                if (sortOrder === -1) {
                    return b.agingStatus[property].toUpperCase().localeCompare(a.agingStatus[property].toUpperCase());
                } else {
                    return a.agingStatus[property].toUpperCase().localeCompare(b.agingStatus[property].toUpperCase());
                }
            };
        case "testInstance":
            return function (a, b) {
                if (sortOrder === -1) {
                    return b.testInstance[property].toUpperCase().localeCompare(a.testInstance[property].toUpperCase());
                } else {
                    return a.testInstance[property].toUpperCase().localeCompare(b.testInstance[property].toUpperCase());
                }
            };
        case "testSpecification":
            return function (a, b) {
                if (sortOrder === -1) {
                    return b.testSpecification[property].toUpperCase().localeCompare(a.testSpecification[property].toUpperCase());
                } else {
                    return a.testSpecification[property].toUpperCase().localeCompare(b.testSpecification[property].toUpperCase());
                }
            };
        case "testStatus":
            return function (a, b) {
                if (sortOrder === -1) {
                    return b.testStatus[property].toUpperCase().localeCompare(a.testStatus[property].toUpperCase());
                } else {
                    return a.testStatus[property].toUpperCase().localeCompare(b.testStatus[property].toUpperCase());
                }
            };
        case "testResult":
            return function (a, b) {
                if (sortOrder === -1) {
                    return b.testResult[property].toUpperCase().localeCompare(a.testResult[property].toUpperCase());
                } else {
                    return a.testResult[property].toUpperCase().localeCompare(b.testResult[property].toUpperCase());
                }
            };
        case "role":
            return function (a, b) {
                if (sortOrder === -1) {
                    return b.role[property].toUpperCase().localeCompare(a.role[property].toUpperCase());
                } else {
                    return a.role[property].toUpperCase().localeCompare(b.role[property].toUpperCase());
                }
            };
        case "classification":
            return function (a, b) {
                if (sortOrder === -1) {
                    return b.classification[property].toUpperCase().localeCompare(a.classification[property].toUpperCase());
                } else {
                    return a.classification[property].toUpperCase().localeCompare(b.classification[property].toUpperCase());
                }
            };
        case "uploader":
            return function (a, b) {
                if (sortOrder === -1) {
                    return b.uploadedBy[property].toUpperCase().localeCompare(a.uploadedBy[property].toUpperCase());
                } else {
                    return a.uploadedBy[property].toUpperCase().localeCompare(b.uploadedBy[property].toUpperCase());
                }
            };
        default:
            return function (a, b) {
                if (sortOrder === -1) {
                    return b[property].toUpperCase().localeCompare(a[property].toUpperCase());
                } else {
                    return a[property].toUpperCase().localeCompare(b[property].toUpperCase());
                }

            };
    }
}

export {
    dynamicSort
}