import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { ALLROLES, ALLUSERS_SLIM } from '../../../operations/queries/query';
import { Grid, Dialog, Button, DialogContent, Box, Typography, Select, MenuItem, IconButton } from '@mui/material';
import { useStyles } from './AddNewRole.styles.js';
import Icon from '../../../assets/icons/Icon.js';
import BatteryLoader from '../../other/helper/BatteryLoader/BatteryLoader';
import { petrol800, signalRed800 } from '../../other/helper/MuiStyles';

const AddNewRole = ({ project, assignedRoles, setAssignedRoles, mutationRoles, setMutationRoles, isLocked }) => {
    const [show, setShow] = useState(false);
    const [checkRoleAssignment, setCheckRoleAssignments] = useState(false);
    const [checkDoubleAssignment, setCheckDoubleAssignments] = useState(false);

    const { data: allRoles, error: allRolesError, loading: allRolesLoading } = useQuery(ALLROLES);
    const { data: users, error: usersError, loading: usersLoading } = useQuery(ALLUSERS_SLIM);

    const ToggleModal = () => {
        setShow(!show);
        let userValues = [];
        let userMutationValues = [];
        for (let i = 0; i < project?.project?.users?.length; i++) {
            userValues.push({ userId: project.project.users[i].userId, avatarId: project.project.users[i].avatarId, firstName: project.project.users[i].firstName, lastName: project.project.users[i].lastName, role: project.project.users[i].projects[0].role.name })
            userMutationValues.push({ userId: project.project.users[i].userId, role: project.project.users[i].projects[0].role.roleId })
        }
        setAssignedRoles(userValues);
        setMutationRoles(userMutationValues);
    };

    const handleRoleChange = (index, event, child) => {
        const values = [...assignedRoles];
        const mutationValues = [...mutationRoles];
        values[index][event.target.name] = event.target.value;
        mutationValues[index][event.target.name] = child.props.datamutationvalue;
        setAssignedRoles(values);
        setMutationRoles(mutationValues);
    };

    const handleUserChange = (index, event, child) => {
        const values = [...assignedRoles];
        const mutationValues = [...mutationRoles];
        values[index][event.target.name] = event.target.value;
        values[index]['firstName'] = child.props.dataformvalue1;
        values[index]['lastName'] = child.props.dataformvalue2;
        values[index]['avatarId'] = child.props.dataformvalue3;
        mutationValues[index][event.target.name] = event.target.value;
        setAssignedRoles(values);
        setMutationRoles(mutationValues);
    };

    const handleAddRole = () => {
        setAssignedRoles([...assignedRoles, { userId: '', avatarId: '', firstName: '', lastName: '', role: '' }]);
        setMutationRoles([...mutationRoles, { userId: '', role: '' }]);
    };

    const handleRemoveRole = (index) => {
        const values = [...assignedRoles];
        const mutationValues = [...mutationRoles];
        values.splice(index, 1);
        mutationValues.splice(index, 1);
        setAssignedRoles(values);
        setMutationRoles(mutationValues);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setShow(!show);
    };

    const isLoading = () => {
        return (allRolesLoading || usersLoading)
    };

    const hasError = () => {
        return !(allRolesError === undefined && usersError === undefined)
    };


    //validation for mutationRoles
    useEffect(() => {
        let check = false;
        for (let i = 0; i < mutationRoles?.length; i++) {
            if ((mutationRoles[i].userId !== "" && mutationRoles[i].role === "") || (mutationRoles[i].userId === "" && mutationRoles[i].role !== "") || (mutationRoles[i].userId === "" && mutationRoles[i].role === "")) {
                check = false;
                break;
            } else {
                check = true;
            }
        }
        if (mutationRoles?.length === 0) {
            check = false;
        }
        setCheckRoleAssignments(check);
    }, [mutationRoles]);

    //validation for mutationRoles
    useEffect(() => {
        let checkArray = mutationRoles?.map(function (item) { return item.userId + ' ' + item.role });
        let isDouble = checkArray.some(function (item, idx) {
            return checkArray.indexOf(item) !== idx
        });
        setCheckDoubleAssignments(isDouble);
    }, [mutationRoles]);

    const classes = useStyles();

    return <>
        <Button
            variant="contained"
            color="primary"
            onClick={ToggleModal}
            disabled={isLocked}
        >
            <div style={{ display: 'flex', marginRight: '8px' }}>
                <Icon iconName={"AddIcon"} fill={'#FFF'} />
            </div>
            Add User
        </Button>

        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={show}
            onClose={ToggleModal}
        >
            <DialogContent>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h2">
                        Assign to Project
                    </Typography>
                    <IconButton onClick={ToggleModal} className={classes.petrol_icon}>
                        <Icon iconName={"CloseIcon"} fill={petrol800} />
                    </IconButton>
                </Box>

                {/* ERROR */}
                {hasError() &&
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Box my={4} display="flex" flexDirection="column" alignItems="center">
                            <Icon iconName={"WarningIcon"} width={54} height={54} fill={signalRed800} />
                            <Typography variant="h3" className={classes.error_text}>
                                Something went wrong. The following errors have occurred:
                            </Typography>
                            {allRolesError !== undefined &&
                                <Typography variant="h5" className={classes.error_type}>
                                    {allRolesError.message}
                                </Typography>
                            }
                            {usersError !== undefined &&
                                <Typography variant="h5" className={classes.error_type}>
                                    {usersError.message}
                                </Typography>
                            }
                        </Box>
                        <Button variant="outlined" onClick={ToggleModal} color="primary">
                            Cancel
                        </Button>
                    </Box>
                }

                {/* LOADING */}
                {isLoading() && !hasError() &&
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Box my={8} display="flex">
                            <BatteryLoader type="standard" />
                        </Box>
                        <Button variant="outlined" onClick={ToggleModal} color="primary">
                            Cancel
                        </Button>
                    </Box>
                }

                {!isLoading() && !hasError() &&
                    <>
                        <Grid item xs={12} display="grid">
                            <Grid container spacing={0}>
                                <Grid item xs={6}>
                                    <Box mt={3} display="flex" flexDirection="row">
                                        <Typography variant="h6" className={classes.index_title}>
                                            Role *
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box mt={3} display="flex" flexDirection="row">
                                        <Typography variant="h6" className={classes.index_title} style={{ marginLeft: '12px' }}>
                                            User *
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        {assignedRoles.map((inputField, index) => (
                            <div className={classes.assignment_box} key={index}>
                                <Select
                                    name="role"
                                    data-testid='input-role'
                                    value={inputField.role}
                                    onChange={(event, child) => handleRoleChange(index, event, child)}
                                    className={classes.left_select_box}
                                >
                                    {allRoles?.projectRoles?.map((option) => (
                                        <MenuItem
                                            datamutationvalue={option.roleId}
                                            key={option.roleId}
                                            value={option.name}
                                            disabled={!option.active}
                                        >
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>

                                <Select
                                    name="userId"
                                    data-testid='input-user'
                                    value={inputField.userId}
                                    onChange={(event, child) => handleUserChange(index, event, child)}
                                    className={classes.right_select_box}
                                >
                                    {users?.users?.map((option) => (
                                        <MenuItem
                                            dataformvalue1={option.firstName}
                                            dataformvalue2={option.lastName}
                                            dataformvalue3={option.avatarId}
                                            key={option.userId}
                                            value={option.userId}
                                            disabled={!option.active}
                                        >
                                            {option.lastName + ", " + option.firstName}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {mutationRoles.length > 1 &&
                                    <IconButton
                                        className={classes.petrol_icon}
                                        onClick={() => handleRemoveRole(index)}
                                        style={{marginLeft: '12px'}}
                                    >
                                        <Icon iconName={"TrashIcon"} width={24} height={24} fill={petrol800} />
                                    </IconButton>
                                }
                            </div>
                        ))}

                        {checkDoubleAssignment &&
                            <Box mt={-3} mb={3} display="flex" flexDirection="row">
                                <Typography variant="subtitle2" className={classes.alert_message}>You have selected the same user with the same role twice.</Typography>
                            </Box>
                        }

                        <Box display="flex" flexDirection="row">
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => handleAddRole()}
                                style={{ width: '140px' }}
                            >
                                <div style={{ display: 'flex', marginRight: '8px' }}>
                                    <Icon iconName={"AddIcon"} fill={petrol800} />
                                </div>
                                Add User
                            </Button>
                        </Box>

                        <Box mt={3} display="flex" flexDirection="row" alignItems="center">
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!checkRoleAssignment || checkDoubleAssignment}
                                style={{ width: '140px' }}
                                onClick={handleSubmit}
                            >
                                Save
                            </Button>
                            <Typography variant="h6" className={classes.required_tag}>
                                * Mandatory field
                            </Typography>
                        </Box>
                    </>
                }
            </DialogContent>
        </Dialog>
    </>;
};

export default AddNewRole;