import makeStyles from '@mui/styles/makeStyles';
import { grey100, grey600, signalRed800, signalOrange800, petrol600, buttonHoverPetrol600, petrol700 } from '../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '8px !important',
    // minWidth: '125px',
    border: '0 !important',
    boxShadow: 'none !important',
    marginRight: '16px',
    marginBottom: '8px',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.6px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'rgba(3, 125, 166)',
      color: '#FFF',
      fontWeight: '700',
    },
  },
  normalButton: {
    backgroundColor: '#FFF',
    color: petrol600,
    padding: '0px 16px',
    borderRadius: '8px !important',
    border: `1px solid ${petrol600} !important`,
    boxShadow: 'none !important',
    marginRight: '16px',
    marginBottom: '8px',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '40px',
    letterSpacing: '0.6px',
    textTransform: 'capitalize',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${buttonHoverPetrol600} !important`,
      color: petrol600,
    },
  },
  selectedButton: {
    backgroundColor: petrol600,
    color: '#FFF',
    padding: '0px 16px',
    borderRadius: '8px !important',
    border: `1px solid ${petrol600} !important`,
    boxShadow: 'none !important',
    marginRight: '16px',
    marginBottom: '8px',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '40px',
    letterSpacing: '0.6px',
    textTransform: 'capitalize',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${petrol700} !important`,
      color: '#FFF',
    },
  },
  smallform: {
    '& .MuiTextField-root': {
      width: '16ch',
      marginTop: '4px'
    },
  },
  form: {
    '& .MuiTextField-root': {
      width: '50ch',
      marginTop: '4px',
      marginLeft: '144px',
    },
  },
  samplebox: {
    backgroundColor: grey100,
  },
  error_icon_wrapper: {
    padding: '0px',
    marginLeft: '8px',
    transform: 'rotate(45deg)',
  },
  box_freeText: {
    textAlign: 'left',
    width: '650px',
  },
  alert_message: {
    marginTop: '4px',
    color: signalRed800,
  },
  sample_toggle: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  tbd_text: {
    marginLeft: '16px',
    color: grey600,
    display: 'flex',
    alignItems: 'center',
    lineHeight: '18px',
    letterSpacing: '0.8px',
    fontWeight: '400',
  },
  add_button_index: {
    marginBottom: '8px',
    border: `1px solid ${petrol600}`,
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: '400',
    color: petrol600,
    backgroundColor: grey100,
    borderRadius: '8px',
    letterSpacing: '0.6px',
    paddingLeft: '16px !important',
    paddingRight: '16px !important',
    minWidth: 'auto',
    '&:hover': {
      backgroundColor: buttonHoverPetrol600,
    },
  },
  hasTestCases: {
    height: "10px",
    width: "10px",
    backgroundColor: signalOrange800,
    borderRadius: "50%",
    display: "inline-block"
  }

}));