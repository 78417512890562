import React, { useContext, useEffect, useState } from 'react';
import { Link, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { Paper, Grid, Box, Typography, Tabs, Tab, Button } from '@mui/material';
import StickyBar from '../../stickybar/StickyBar';
import { useStyles } from './ProjectDetails.styles.js';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { CELLDATASHEET, PROJECTDETAIL, TESTCASES } from '../../../operations/queries/query';
import ProjectInformation from './ProjectInformation';
import CellDataSheet from '../CellDataSheet/CellDataSheet';
import TestCases from '../../testcasesection/editing/TestCases';
import { calcLatestSample, currentIndexId, currentProjectId, indexVar, isLockLoading, sampleVar, subSampleVar } from '../../../operations/localeStorage';
import { CDSContext } from '../../other/contexts/CDSContext';
import Icon from '../../../assets/icons/Icon.js';
import BatteryLoader from '../../other/helper/BatteryLoader/BatteryLoader';
import { IMAGE_MAP } from '../../other/helper/ImgMapper/ImgMapper';
import { grey1000, grey600, signalRed800 } from '../../other/helper/MuiStyles';
import PowerOCVSwitch from '../CellDataSheet/PowerOCVSwitch';
import CDSTable from '../CellDataSheet/CDSTable';
import ReleaseReport from './pdfexports/ReleaseReport/ReleaseReport';
import { UPDATECDS } from '../../../operations/mutations/mutations';
import { SnackbarContext, SnackbarType } from '../../other/contexts/SnackBarContext';
import LeaveConfirmation from '../../other/dialogs/LeaveConfirmation/LeaveConfirmation';
import { useCallbackPrompt } from '../../other/dialogs/LeaveConfirmation/hooks/useCallbackPrompt';

function ProjectDetails() {
  const navigate = useNavigate();
  const { currentPowerOCV } = useContext(CDSContext);
  const { setIsSnackbarOpen, setMessage, setSnackbarType } = useContext(SnackbarContext);
  const lockLoading = useReactiveVar(isLockLoading);
  let { id } = useParams();

  const handleOnCompleted = async () => {
    setSnackbarType(SnackbarType.SUCCESS);
    setIsSnackbarOpen(true);
  };

  const handleOnError = async (error) => {
    setMessage(error.message);
    setSnackbarType(SnackbarType.ERROR);
    setIsSnackbarOpen(true);
  };

  //QUERIES
  const { data: project, loading: projectLoading, error: projectError } = useQuery(PROJECTDETAIL(id), { fetchPolicy: "network-only" });
  const { data: testCases, loading: testCasesLoading, error: testCasesError } = useQuery(TESTCASES(currentIndexId()), { skip: currentIndexId() === undefined || currentIndexId() === -1 });

  // subsampleId + selected CellDataType, isCharged and currentPowerUnit and CDS query
  const subSampleId = project?.project?.samples[sampleVar()]?.subSamples[subSampleVar()]?.subSampleId;
  const { data: ocvData, error: ocvError } = useQuery(CELLDATASHEET(subSampleId, 'OCV'), { skip: subSampleId === undefined });
  const { data: powerLimitsData, error: powerLimitsError } = useQuery(CELLDATASHEET(subSampleId, 'POWER_LIMITS'), { skip: subSampleId === undefined });

  // MUTATIONS
  const [updateCDS, { loading: updateCDSLoading }] = useMutation(UPDATECDS, { 
    onSuccess: handleOnCompleted, 
    onError: handleOnError, 
    refetchQueries: [{ query: CELLDATASHEET(subSampleId, 'OCV') }, { query: CELLDATASHEET(subSampleId, 'POWER_LIMITS') }], 
    awaitRefetchQueries: true 
  });

  let location = useLocation();

  let sizeWindow;

  switch (true) {
    case window.innerWidth < 1576:
      sizeWindow = 4;
      break;
    case window.innerWidth >= 1872:
      sizeWindow = 5;
      break;
    default:
      sizeWindow = 4;
      break;
  }

  // Calc selected tab depending on url
  const tabname = location?.pathname?.split('/')?.[3];
  useEffect(() => {
    if (tabname !== undefined) {
      if (tabname === 'testcases') {
        setTabValue(0)
      }
      if (tabname === 'celldatasheet') {
        setTabValue(1)
      }
      if (tabname === 'projectinformation') {
        setTabValue(2)
      }
    }
  }, [tabname]); // eslint-disable-line

  function LinkTab(props) {
    return (
      <Tab
        component={Link}
        to={props.pathname}
        {...props}
      />
    );
  }

  //currently active sample
  const [selectedSample, setSelectedSample] = useState('');
  //currently active subSample
  const [selectedSubSample, setSelectedSubSample] = useState();

  //currently active index
  const [selectedIndex, setSelectedIndex] = useState();
  const [editInformation, setEditInformation] = useState(false);
  const [assignedRoles, setAssignedRoles] = useState([]);
  const [mutationRoles, setMutationRoles] = useState([]);
  const [formSample, setFormSample] = useState();
  const [formValues, setFormValues] = useState({
    platforms: [],
    leadingDevelopment: '',
    sopYear: '',
    sopWeek: '',
  });
  const [mutationValues, setMutationValues] = useState({
    projectId: '',
    name: '',
    isLocked: false,
    platforms: [],
    cellSupplier: '',
    cellIndex: '',
    nominalCapacity: '',
    sopYear: '',
    sopWeek: '',
    description: '',
    leadingDevelopment: '',
    cellSpecification: '',
    cellSpecificationLink: ''
  });
  const [tabValue, setTabValue] = useState(0);
  const [docList, setDocList] = useState({});
  const [testCaseList, setTestCaseList] = useState();

  // CDS variables
  const [ocvValues, setOcvValues] = useState();
  const [powerValues, setPowerValues] = useState();
  const [ocvMutationValues, setOcvMutationValues] = useState([]);
  const [powerMutationValues, setPowerMutationValues] = useState([]);

  const [showDialog, setShowDialog] = useState(false)
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog)

  const handleCDSSave = () => {
    updateCDS({ variables: { cellDataType: 'OCV', cellDataEntries: ocvMutationValues } });
    updateCDS({ variables: { cellDataType: 'POWER_LIMITS', cellDataEntries: powerMutationValues } });
    setMessage(`Cell Data Sheet has been updated.`);
    setOcvMutationValues([]);
    setPowerMutationValues([]);
  };

  let _ = require('underscore');
  const cdsSocSteps = _.range(0, 101, 5);
  const cdsTempSteps = _.range(-5, 6, 5);
  const cdsPulseTimes = [10, 18, 30, 180, 52];

  const isLoadingHead = () => {
    return (projectLoading || lockLoading)
  };

  const hasErrorHead = () => {
    return projectError !== undefined
  };

  const addTitleName = () => {
    let capacity = '';
    if (mutationValues.nominalCapacity !== '') {
      capacity = " " + mutationValues.nominalCapacity + " Ah";
    }
    return mutationValues.cellSupplier + capacity + " " + mutationValues.cellIndex;
  };

  // handle click on tabs
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // calculate latest sample, which has tests
  useEffect(() => {
    if (project && calcLatestSample()) {
      let hasNoTestCases = project?.project?.samples.every(sample => sample.hasTestCases === false);
      if (hasNoTestCases) {
        sampleVar(0);
      } else {
        let lastIndex = project?.project?.samples.map(sample => sample.hasTestCases).lastIndexOf(true);
        sampleVar(lastIndex);
      }
    }
  }, [project, calcLatestSample]); // eslint-disable-line

  // calculated selected sample dependending on latest sample
  useEffect(() => {
    let initialSelectedSample;
    switch (sampleVar()) {
      case 0:
        initialSelectedSample = 'P'
        break;
      case 1:
        initialSelectedSample = 'A'
        break;
      case 2:
        initialSelectedSample = 'B'
        break;
      case 3:
        initialSelectedSample = 'C'
        break;
      default:
        break;
    }
    setSelectedSample(initialSelectedSample)
  }, [project, sampleVar()])

  // set current index depending on project query
  useEffect(() => {
    // setCurrentIndexId(project?.project?.samples[sampleVar()]?.subSamples[subSampleVar()]?.indices[indexVar()]?.indexId);
    currentIndexId(project?.project?.samples[sampleVar()]?.subSamples[subSampleVar()]?.indices[indexVar()]?.indexId);
    currentProjectId(project?.project?.projectId)
  }, [project, sampleVar(), subSampleVar(), indexVar()]); // eslint-disable-line

  // map testcases from query in local state
  useEffect(() => {
    if (testCases !== undefined) {
      setTestCaseList(testCases);
    }
  }, [testCases]);

  //map project values from query in local state
  useEffect(() => {
    if (project !== undefined) {
      let userValues = [];
      let userMutationValues = [];
      for (let i of project.project.users) {
        userValues.push({ userId: i.userId, avatarId: i.avatarId, firstName: i.firstName, lastName: i.lastName, role: i.projects[0].role.name })
        userMutationValues.push({ userId: i.userId, role: i.projects[0].role.roleId })
      }
      setAssignedRoles(userValues);
      setMutationRoles(userMutationValues);

      let platformFormValues = [];
      let platformMutationValues = [];
      for (let j of project.project.platforms) {
        platformFormValues.push(j.name)
        platformMutationValues.push(j.platformId)
      }
      setFormSample(project.project.samples);
      setFormValues({ ...formValues, platforms: platformFormValues, leadingDevelopment: project.project.leadingDevelopment.name, sopYear: project.project.sopYear, sopWeek: project.project.sopWeek });
      setSelectedSubSample(project?.project?.samples[sampleVar()]?.subSamples[subSampleVar()]?.name);
      setSelectedIndex(project?.project?.samples[sampleVar()]?.subSamples[subSampleVar()]?.indices[indexVar()]?.name);
      let testResultValues = {};
      testResultValues['testsPassed'] = project.project.testResult.testsPassed;
      testResultValues['testsFailed'] = project.project.testResult.testsFailed;
      testResultValues['testsNotEvaluated'] = project.project.testResult.testsNotEvaluated;
      testResultValues['testsNotAvailable'] = project.project.testResult.testsNotAvailable;
      setMutationValues({ ...mutationValues, projectId: project.project.projectId, name: project.project.name, isLocked: project.project.isLocked, nominalCapacity: project.project.nominalCapacity, platforms: platformMutationValues, leadingDevelopment: project.project.leadingDevelopment.leadingDevelopmentId, sopYear: project.project.sopYear, sopWeek: project.project.sopWeek, cellSpecification: project.project.cellSpecification, cellSpecificationLink: project.project.cellSpecificationLink, cellSupplier: project.project.cellSupplier.name, description: project.project.description === null ? '' : project.project.description, cellIndex: project.project.cellIndex });
      
      let allDocs = [];
      if (project.project.documents != undefined) {
        for (let i of project.project.documents) {
          allDocs.push(i);
        }
      }
      setDocList({ documents: allDocs });
    }
  }, [project]); // eslint-disable-line

  useEffect(() => {
    if (ocvData !== undefined) {
      setOcvValues(ocvData);
    }
  }, [ocvData]);

  useEffect(() => {
    if (powerLimitsData !== undefined) {
      setPowerValues(powerLimitsData);
    }
  }, [powerLimitsData]);

  useEffect(() => {
    setShowDialog(powerMutationValues.length > 0 || ocvMutationValues.length > 0)
  }, [powerMutationValues, ocvMutationValues])

  const styleProps = {
    supplierImg: IMAGE_MAP.SUPPLIER[project?.project?.cellSupplier?.name],
    paddingLockBadge: (sizeWindow === 5) ? '0px 16px' : '0px 8px',
  };

  const classes = useStyles(styleProps);

  return (
    <>
      <LeaveConfirmation
        showDialog={showPrompt}
        onLeave={confirmNavigation}
        onStay={cancelNavigation}
      />
      <div className={classes.root} >
        <Grid container spacing={3} pb={8}>
          <Grid item xs={12} display="grid" >
            <Paper className={classes.paper}>

              {/* ERROR */}
              {(projectError !== undefined) &&
                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 128px - 24px - 24px)' }}>
                  <Box m={4} display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                    <Typography variant="h1">
                      Project Details
                    </Typography>
                  </Box><Box mx={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow="1">
                    <Icon iconName={"WarningIcon"} width={54} height={54} fill={signalRed800} />
                    <Typography variant="h3" className={classes.error_text}>
                      Something went wrong. The following errors have occurred:
                    </Typography>
                    {projectError !== undefined &&
                      <Typography variant="h5" className={classes.error_type}>
                        {projectError.message}
                      </Typography>
                    }
                  </Box>
                  <Box my={4} display="flex" alignItems="center" justifyContent="center">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => { navigate("/") }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              }

              {/* LOADING */}
              {isLoadingHead() && !hasErrorHead() &&
                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 128px - 24px - 24px)' }}>
                  <Box m={4} display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                    <Typography variant="h1">
                      Project Details
                    </Typography>
                  </Box>
                  <Box m={4} display="flex" alignItems="center" justifyContent="center" flexGrow="1">
                    <BatteryLoader type="standard" />
                  </Box>
                </Box>
              }

              {!isLoadingHead() && !hasErrorHead() && project !== undefined &&
                <Box display="flex" flexDirection="column" justifyContent="flex-start" style={(tabValue === 1 && ocvError === undefined && powerLimitsError === undefined) ? {} : { minHeight: 'calc(100vh - 128px - 24px - 24px)' }}>
                  {/* PROJECT DETAILS HEADER */}
                  <Box mx={4} py={4} display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                    <Box display="flex" alignItems="center">
                      <Box display="flex" alignItems="baseline">
                        {!editInformation &&
                          <Typography variant="h1">
                            {/* {project.project.name} */}
                            {/* hotfix for bug in name nominalCapacity calculation */}
                            {project.project.cellSupplier.name} {project.project.nominalCapacity} Ah {project.project.name.split(/\s+/).splice(-1)}
                          </Typography>
                        }
                        {editInformation &&
                          <Typography variant="h1">
                            {addTitleName()}
                          </Typography>
                        }
                        <Typography variant="subtitle1" style={{ marginLeft: '8px' }}>
                          ID {project.project.projectId}
                        </Typography>
                      </Box>
                      {mutationValues.isLocked &&
                        <Box ml={3} className={classes.tag}>
                          <div style={{ display: 'flex', marginRight: (sizeWindow === 5) ? '8px' : '' }}>
                            <Icon iconName={"LockIcon"} width={24} height={24} fill={signalRed800} />
                          </div>
                          {(sizeWindow === 5) &&
                            'Locked'
                          }
                        </Box>
                      }
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Box display="flex" alignItems="center" justifyContent="center">
                        <div style={{ marginRight: '8px', display: 'flex', alignItems: "center" }}>
                          <Icon iconName={"CarIcon"} fill={grey600} />
                          <Box style={{ color: grey1000, letterSpacing: '0.8px', lineHeight: '24px' }} ml={1}>Vehicle Platform:</Box>
                        </div>
                        {project.project.platforms.map((platform, index) => {
                          return (
                            <Typography variant="h5" className={classes.info_values} key={platform.platformId}>
                              {(index ? ', ' : '') + platform.name}
                            </Typography>
                          )
                        })}
                      </Box>
                      <Box ml={5} display="flex" alignItems="center" justifyContent="center">
                        <div style={{ marginRight: '8px', display: 'flex' }}>
                          <Icon iconName={"BatteryIcon"} fill={grey600} />
                          <Box style={{ color: grey1000, letterSpacing: '0.8px', lineHeight: '24px' }} ml={1}>Cell Capacity:</Box>
                        </div>
                        <Typography variant="h5" className={classes.info_values}>
                          {project.project.nominalCapacity} Ah
                        </Typography>
                      </Box>
                      <Box ml={5} display="flex">
                        <div className={classes.supplierimage} title="SupplierLogo"></div>
                      </Box>
                    </Box>
                  </Box>

                  {/* TABS */}
                  <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth">
                    <LinkTab pathname="testcases" label="Test Cases" data-testid={"testcases-tab"} />
                    <LinkTab pathname="celldatasheet" label="Cell Data Sheet" data-testid={"cell-data-sheet-tab"} />
                    <LinkTab pathname="projectinformation" label="Project Information" id="project-information-tab" data-testid={"project-information-tab"} />
                  </Tabs>

                  <Routes>
                    {["/", `/testcases`].map((path) => (
                      <Route key={path} path={path} element={<TestCases
                        key={id}
                        currentTestCases={testCases}
                        testCaseList={testCaseList}
                        setTestCaseList={setTestCaseList}
                        id={id}
                        project={project}
                        selectedSample={selectedSample}
                        setSelectedSample={setSelectedSample}
                        selectedSubSample={selectedSubSample}
                        setSelectedSubSample={setSelectedSubSample}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        // LOADINGS
                        testCasesLoading={testCasesLoading}
                        // Sample State stuff could maybe move in TestCases or errors, loadings before <TestCases>
                        formSample={formSample}
                        setFormSample={setFormSample}
                        projectError={projectError}
                        projectLoading={projectLoading}
                        testCasesError={testCasesError}
                      />} />
                    ))}
                    <Route path={`/celldatasheet`} element={
                      <CellDataSheet
                        isProjectLocked={project?.project?.isLocked}
                        subSampleId={subSampleId}
                        cellDataSheetData={currentPowerOCV === 'OCV' ? ocvValues : powerValues}
                        selectedSample={selectedSample}
                        setSelectedSample={setSelectedSample}
                        selectedSubSample={selectedSubSample}
                        setSelectedSubSample={setSelectedSubSample}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        project={project}
                        formSample={formSample}
                        setFormSample={setFormSample}
                        cdsSocSteps={cdsSocSteps}
                        cdsTempSteps={cdsTempSteps}
                        cdsPulseTimes={cdsPulseTimes}
                        // ERRORS
                        projectError={projectError}
                        testCasesError={testCasesError}
                        // LOADINGS
                        projectLoading={projectLoading}
                      />} />
                    <Route path={`/projectinformation`} element={
                      <ProjectInformation
                        id={id}
                        project={project}
                        documents={docList}
                        mutationRoles={mutationRoles}
                        setMutationRoles={setMutationRoles}
                        assignedRoles={assignedRoles}
                        setAssignedRoles={setAssignedRoles}
                        editInformation={editInformation}
                        setEditInformation={setEditInformation}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        mutationValues={mutationValues}
                        setMutationValues={setMutationValues}
                        setFormSample={setFormSample}
                        setSelectedIndex={setSelectedIndex}
                        // ERRORS
                        projectError={projectError}
                        testCasesError={testCasesError}
                        // LOADINGS
                        projectLoading={projectLoading}
                      />
                    } />
                  </Routes>
                  {/* CDS ERROR */}
                  {(tabValue === 1 && (ocvError !== undefined || powerLimitsError !== undefined)) &&
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow="1">
                      <Icon iconName={"WarningIcon"} width={54} height={54} fill={signalRed800} />
                      <Typography variant="h3" className={classes.error_text}>
                        Something went wrong. The following errors have occurred:
                      </Typography>
                      {ocvError !== undefined &&
                        <Typography variant="h5" className={classes.error_type}>
                          {ocvError.message}
                        </Typography>
                      }
                      {powerLimitsError !== undefined &&
                        <Typography variant="h5" className={classes.error_type}>
                          {powerLimitsError.message}
                        </Typography>
                      }
                    </Box>
                  }
                </Box>
              }

            </Paper>
          </Grid>

          {/* OCV | POWER LIMITS SWITCH  */}
          {(ocvError === undefined) && (powerLimitsError === undefined) &&
            <Routes>
              <Route path="celldatasheet" element={
                <Grid item xs={12} display="grid" >
                  <Box ml={2}>
                    <PowerOCVSwitch />
                  </Box>
                </Grid>}>
              </Route>
            </Routes>
          }

          {/* CDS TABLES */}
          {(ocvError === undefined && powerLimitsError === undefined) && currentPowerOCV === 'OCV' ?
            ocvValues?.cellDataSheet?.map((value, index) => {
              return (
                <Routes key={index}>
                  <Route path="celldatasheet" element={
                    <Grid item xs={12} display="grid" >
                      <Paper className={classes.paper}>
                        <CDSTable cdsValues={ocvValues} setCdsValues={setOcvValues} cdsIndex={index} cdsMutationValues={ocvMutationValues} setCdsMutationValues={setOcvMutationValues} updateCDSLoading={updateCDSLoading} isCDSLocked={formSample != null ? formSample[sampleVar()]?.subSamples[subSampleVar()]?.isCellDataSheetLocked : true} />
                      </Paper>
                    </Grid>}>
                  </Route>
                </Routes>
              )
            })
            :
            powerValues?.cellDataSheet?.map((value, index) => {
              return (
                <Routes key={index}>
                  <Route path="celldatasheet" element={
                    <Grid item xs={12} display="grid" >
                      <Paper className={classes.paper}>
                        <CDSTable cdsValues={powerValues} setCdsValues={setPowerValues} cdsIndex={index} cdsMutationValues={powerMutationValues} setCdsMutationValues={setPowerMutationValues} updateCDSLoading={updateCDSLoading} isCDSLocked={formSample != null ? formSample[sampleVar()]?.subSamples[subSampleVar()]?.isCellDataSheetLocked : true} />
                      </Paper>
                    </Grid>}>
                  </Route>
                </Routes>
              )
            })
          }

        </Grid>
        <StickyBar
          backButton
          backpath={`/`}
          ksuNotification
          releaseReport={tabValue === 1 ? false : true}
          releaseReportComponent={tabValue === 1 ? <></> : <ReleaseReport testCases={testCases} selectedSubSample={selectedSubSample} project={project?.project} selectedIndex={selectedIndex} />}
          customButton={tabValue === 1 ? 'Save Cell Data Sheet' : ''}
          customOnClick={tabValue === 1 ? handleCDSSave : {}}
          customDisabled={tabValue === 1 && powerMutationValues.length === 0 && ocvMutationValues.length === 0}
          scrollButton
        />
      </div >
    </>
  )
}

export default ProjectDetails;