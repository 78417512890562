import makeStyles from '@mui/styles/makeStyles';
import { grey200, grey600, petrol1000 } from '../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  popper: {
    marginRight: '16px',
    zIndex: "1200",
  },
  seperator: {
    height: '1px',
    borderTop: `1px dotted ${grey200}`,
  },
  seperatortext: {
    color: grey600,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '16px',
    marginTop: '8px',
    marginBottom: '8px',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '32px',
    letterSpacing: '0.25px',
  },
  background: {
    width: '80vw',
    height: 'calc(100vh - 64px)',
    backgroundColor: 'grey',
    opacity: '0.5',
    position: 'absolute',
    left: '20vw',
    top: '64px',
    zIndex: "1200",
  },
  paper: {
    height: 'calc(100vh - 64px)',
    width: '20vw',
    position: 'absolute',
    top: '64px',
    borderRadius: '0px',
  },
  app_header: {
    backgroundColor: petrol1000,
    height: '64px',
    // font-size: calc(10px + 2vmin)
    color: 'white'
  },
  username: {
    margin: '16px',
    letterSpacing: '0.6px',
    color: '#FFF',
  },
  nolink: {
    margin: '16px',
    textDecoration: 'none',
    color: 'inherit',
    '&:focus': {textDecoration: 'none', color: 'inherit'},
    '&:hover': {textDecoration: 'none', color: 'inherit'},
    '&:visited': {textDecoration: 'none', color: 'inherit'},
    '&:link': {textDecoration: 'none', color: 'inherit'},
    '&:active': {textDecoration: 'none', color: 'inherit'},
  },
  userimage: {
    height: '32px',
    width: '32px',
    borderRadius: '16px',
    display: 'flex',
    margin: '16px',
    marginRight: 0,
  },
  title_box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title_text: {
    letterSpacing: '1.2px',
    color: '#FFF',
  },
  logo: {
    width: '3vh',
    height: '3vh',
    border: '2px solid white',
    borderRadius: '5vh',
    margin: '0 1vw 0 0',
  },
  logo2: {
    width: '30px',
    height: '30px',
  },
  logo_background:{
    backgroundColor: '#FFF',
    width: '40px',
    height: '40px',
    borderRadius: '20px',
  },
  error_type: {
    padding: '0px 24px',
    textAlign: 'center',
    fontWeight: '400',
    color: '#FFF',
},
  }));