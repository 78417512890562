import makeStyles from '@mui/styles/makeStyles';
import { buttonHoverPetrol800 } from '../MuiStyles';

export const useStyles = makeStyles(() => ({
  upicon: {
    // right: '0.75vw',
    // position: 'fixed',
  },
  iconButton: {
    marginLeft: '24px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#FFF',
    '&:hover': {
      backgroundColor: buttonHoverPetrol800,
    },
  }
}));