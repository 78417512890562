import React from 'react';
import {  Box, Typography } from '@mui/material';
import { useStyles } from './SelectAvatar/SelectAvatar.styles';
import { APP_ROLES_MAPPER } from '../other/helper/utilsMapper/utilsMapper';

export function NameRoleHeader({ myAccount }) {
    const classes = useStyles();
    return <>
        <Box mt={2} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
            <Typography variant="h2" className={classes.title}>
                {myAccount?.myAccount?.firstName} {myAccount?.myAccount?.lastName}
            </Typography>
        </Box>
        <Box mt={1} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
            <Typography variant="h6" className={classes.role}>
                {(myAccount?.myAccount !== undefined) ? APP_ROLES_MAPPER.APP_ROLE[myAccount?.myAccount?.role?.roleId] : 'Role'}
            </Typography>
        </Box>
    </>
}