import { grey1000, grey200, grey800, signalGreen800, signalRed800 } from "../MuiStyles";

const TEST_STATUS_MAPPER = {
  NAME: {
    'PLANNED': 'Planned',
    'ASSIGNED': 'Assigned',
    'STARTED': 'Started',
    'CANCELED': 'Canceled',
    'FINISHED': 'Finished',
    'IN_REVIEW': 'In Review',
    'APPROVED': 'Approved'
  }
}

const TEST_RESULT_MAPPER = {
  BGCOLOR: {
    'NOT_EVALUATED': grey800,
    'PASSED': signalGreen800,
    'FAILED': signalRed800,
    'NOT_AVAILABLE': grey200,
  },
  COLOR: {
    'NOT_EVALUATED': '#FFF',
    'PASSED': '#FFF',
    'FAILED': '#FFF',
    'NOT_AVAILABLE': grey1000,
  },
  NAME: {
    'NOT_EVALUATED': 'Not Evaluated',
    'PASSED': 'Passed',
    'FAILED': 'Failed',
    'NOT_AVAILABLE': 'Not Available',
  },
}

const APP_ROLES_MAPPER = {
  APP_ROLE: {
    1: 'Project User',
    2: 'Administrator',
    3: 'New User',
    4: 'Auditor User',
  }
}

const APP_ROLES_ID_MAPPER = {
  APP_ROLE: {
    'PROJECT_USER': 1,
    'ADMIN': 2,
    'NEW_USER': 3,
    'AUDITOR_USER': 4
  }
}

const WEEKS = [];
for (let i = 0; i <= 52; i++) {
	WEEKS.push({ value: i, label: i });
}

const YEARS = [];
for (let i = 1; i <= 10; i++) {
	YEARS.push({ value: 2020 + i, label: 2020 + i });
}

export {
  TEST_RESULT_MAPPER,
  TEST_STATUS_MAPPER,
  APP_ROLES_MAPPER,
  APP_ROLES_ID_MAPPER,
  WEEKS,
  YEARS
}