import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { grey100, grey1000 } from '../MuiStyles';

const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      padding: '16px'
    },
    number: ({ background, radius, width, color, margin }) => ({ 
      background: background || grey100,
      borderRadius: radius || '20px',
      textAlign: 'center',
      width: width || '50px',
      height: '32px',
      color: color || grey1000,
      fontSize: '16px',
      lineHeight: '32px',
      margin: margin || `0`,
      fontWeight: '700'
    }),
  }
  ));

function Counter(props) {  
    const { number, bgcolor, width, radius, color, margin } = props;

    const style_props = {
      background: bgcolor,
      width: width,
      borderRadius: radius,
      color: color,
      margin: margin,
    }
    const classes = useStyles(style_props);

    return (
      <div data-testid="number" className={classes.number}>
          {String(number).padStart(2, '0')}
      </div>
    )
}


export default Counter;