import makeStyles from '@mui/styles/makeStyles';
import { grey100, grey200, grey600 } from '../../other/helper/MuiStyles.js';

export const useStyles = makeStyles(() => ({
    root: {
        padding: '24px',
        backgroundColor: grey100,
    },
    paper: {        
        margin: "auto",
        minWidth: "647px",
        maxWidth: "647px",
        minHeight: 'calc(100vh - 64px - 24px - 24px)',
        textAlign: 'center',
        backgroundColor: '#FFF',
    },
    title: {
        lineHeight: '24px',
        letterSpacing: '0.6px',
    },
    avatar_image: {
        height: '80px',
        width: '80px',
    },
    avatar_option_image: {
        marginTop: '4px',
        height: '80px',
        width: '80px',
        borderRadius: '25%',
        cursor: 'pointer',
    },
    role: {
        fontWeight: '400',
        lineHeight: '18px',
        letterSpacing: '0.8px',
        color: grey600,
    },
    line: {
        borderBottom: `1px solid ${grey200}`,
    },
    bottom_button: {
        marginBottom: '16px',
    },
    selected_circle: {
        margin: '1px',
        height: '88px',
        width: '88px',
        borderRadius: '50%',
    },
    snackbar: {
        position: 'sticky',
        height: '100%',
        top: '0',
        zIndex: '1'
    },
}));