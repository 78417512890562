import React from "react";
import { useStyles } from "./ReleaseReport.styles";
import { Box } from "@mui/system";

export function TestResultProgressBars({ category, single, right, passedTests, failedTests, notEvaluatedTests, notAvailableTests }) {

    if (typeof passedTests === 'undefined') {
      passedTests = 0;
    }
    if (typeof failedTests === 'undefined') {
      failedTests = 0;
    }
    if (typeof notEvaluatedTests === 'undefined') {
      notEvaluatedTests = 0;
    }
    if (typeof notAvailableTests === 'undefined') {
      notAvailableTests = 0;
    }

    const totalTestNumber = passedTests + failedTests + notEvaluatedTests + notAvailableTests;

    const styleProps2 = {
      widthTestsPassed: (passedTests / totalTestNumber) * 100 + '%',
      widthTestsFailed: (failedTests / totalTestNumber) * 100 + '%',
      widthTestsNotEvaluated: (notEvaluatedTests / totalTestNumber) * 100 + '%',
    };

    const classes2 = useStyles(styleProps2);

    return (
      <>
        <Box mt={1.5} display="flex">
          <Box className={classes2.category_bar_text} style={single ? { width: '12.5%' } : (right ? { width: '25%', paddingLeft: '8px' } : { width: '25%' })}>{category}</Box>
          <Box style={single ? { width: '7.5%', textAlign: 'right', paddingRight: '8px' } : { width: '15%', textAlign: 'right', marginRight: '8px' }}>{failedTests + passedTests + notEvaluatedTests}/{totalTestNumber}</Box>
          <Box display="flex" justifyContent="center" style={single ? { width: '80%' } : { width: '60%' }}>
            <Box className={classes2.percent_container_div}>
              <Box className={classes2.percent_bar_passed} style={failedTests === 0 && notEvaluatedTests === 0 && notAvailableTests === 0 ? { borderRadius: '8px' } : { borderRadius: '8px 0 0 8px' }}></Box>
              <Box className={classes2.percent_bar_failed} style={passedTests === 0 && notEvaluatedTests === 0 && notEvaluatedTests === 0 ? { borderRadius: '8px' } : (passedTests === 0 ? { borderRadius: '8px 0 0 8px' } : ((notAvailableTests === 0 && notEvaluatedTests === 0) ? { borderRadius: '0 8px 8px 0' } : { borderRadius: '0' }))}></Box>
              <Box className={classes2.percent_bar_notevaluated} style={failedTests === 0 && passedTests === 0 && notAvailableTests === 0 ? { borderRadius: '8px' } : (passedTests === 0 && failedTests === 0 && notAvailableTests !== 0 ? { borderRadius: '8px 0 0 8px' } : (notAvailableTests === 0 ? { borderRadius: '0 8px 8px 0' } : { borderRadius: '0' }))}></Box>
            </Box>
          </Box>
        </Box>
        <Box mt={1} display="flex">
          <Box style={single ? { width: '12.5%' } : (right ? { width: '25%', paddingLeft: '8px' } : { width: '25%' })}>Not Evaluated</Box>
          <Box style={single ? { width: '7.5%', textAlign: 'right', paddingRight: '8px' } : { width: '15%', textAlign: 'right', marginRight: '8px' }}>{notEvaluatedTests}/{totalTestNumber}</Box>
          <Box display="flex" justifyContent="center" style={single ? { width: '80%' } : { width: '60%' }}>
            <Box className={classes2.percent_container_div}>
              <Box className={classes2.percent_bar_notevaluated} style={{ borderRadius: '8px 0 0 8px' }}></Box>
            </Box>
          </Box>
        </Box>
        <Box mt={1} display="flex">
          <Box style={single ? { width: '12.5%' } : (right ? { width: '25%', paddingLeft: '8px' } : { width: '25%' })}>Passed</Box>
          <Box style={single ? { width: '7.5%', textAlign: 'right', paddingRight: '8px' } : { width: '15%', textAlign: 'right', marginRight: '8px' }} >{passedTests}/{totalTestNumber}</Box>
          <Box display="flex" justifyContent="center" style={single ? { width: '80%' } : { width: '60%' }}>
            <Box className={classes2.percent_container_div}>
              <Box className={classes2.percent_bar_passed} style={{ borderRadius: '8px 0 0 8px' }}></Box>
            </Box>
          </Box>
        </Box>
        <Box mt={1} display="flex">
          <Box style={single ? { width: '12.5%' } : (right ? { width: '25%', paddingLeft: '8px' } : { width: '25%' })}>Failed</Box>
          <Box style={single ? { width: '7.5%', textAlign: 'right', paddingRight: '8px' } : { width: '15%', textAlign: 'right', marginRight: '8px' }} >{failedTests}/{totalTestNumber}</Box>
          <Box display="flex" justifyContent="center" style={single ? { width: '80%' } : { width: '60%' }}>
            <Box className={classes2.percent_container_div}>
              <Box className={classes2.percent_bar_failed} style={{ borderRadius: '8px 0 0 8px' }}></Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  }