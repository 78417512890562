import React, { useState } from 'react';
import { Paper, Grid, Box, Typography, IconButton } from '@mui/material';
import { useStyles } from '../DocumentList.styles';
import Counter from '../../helper/Counter/Counter';
import DocumentListElement from '../DocumentListElement/DocumentListElement';
import UploadDocument from '../../dialogs/UploadDocument/UploadDocument';
import { dynamicSort } from '../../helper/globalFunctions/globalFunctions';
import { projectRole } from '../../../../operations/localeStorage';
import { grey800 } from '../../helper/MuiStyles';
import Icon from '../../../../assets/icons/Icon';

function DocumentListTable(props) {

    const { access, testSpecId, appendId, documents, isLocked } = props;

    const role = projectRole();

    let sizeWindow;

    switch (true) {
        case window.innerWidth < 1576:
            sizeWindow = 4;
            break;
        case window.innerWidth < 1872:
            sizeWindow = 5;
            break;
        case window.innerWidth < 2168:
            sizeWindow = 6;
            break;
        case window.innerWidth < 2464:
            sizeWindow = 7;
            break;
        case window.innerWidth < 2760:
            sizeWindow = 8;
            break;
        case window.innerWidth >= 2760:
            sizeWindow = 9;
            break;
        default:
            sizeWindow = 4;
            break;
    }

    //SORTING VARIABLES
    const [docKey, setDocKey] = useState(false);
    const [sortName, setSortName] = useState(false);
    const [sortClassification, setSortClassification] = useState(false);
    const [sortUploader, setSortUploader] = useState(false);
    const [sortUploadDate, setSortUploadDate] = useState(false);

    const classes = useStyles();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} display="grid">
                <Paper className={classes.paper}>

                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="h3" className={classes.title} style={{ display: 'flex', alignItems: 'center', paddingLeft: '16px' }}>
                                    Documents
                                </Typography>
                            </Box>
                            <Box m={2}>
                                <Counter number={!documents?.documents?.length ? 0 : documents?.documents?.length } />
                            </Box>
                        </Box>
                        {(role === 'Administrator' || role === 'Project User') &&
                            <UploadDocument access={access} appendId={appendId} documents={documents} maxCharacterName={100} testSpecId={testSpecId} isLocked={isLocked} />
                        }
                    </Box>
                    <Box className={classes.head} />
                    <Box display="flex" alignItems="center" className={classes.header}>
                        <Box display="flex" alignItems="center" className={classes.header1}><div style={{ display: 'flex', alignItems: 'center', paddingLeft: '16px' }}>Document<IconButton style={sortName === true ? { marginLeft: '8px' } : { transform: 'rotate(-180deg)', marginLeft: '8px' }} onClick={() => { documents.documents.sort(dynamicSort(sortName === true ? "name" : "-name")); setSortName(!sortName); setDocKey(!docKey) }}><Icon iconName={"DirectionIcon"} width={16} height={16} fill={grey800} /></IconButton></div></Box>
                        <Box className={classes.header2}>Version</Box>
                        <Box className={classes.header3}>Size</Box>
                        <Box className={classes.header4}>Type</Box>
                        <Box display="flex" alignItems="center" className={classes.header5}>Classification<IconButton style={sortClassification === true ? { marginLeft: '8px' } : { transform: 'rotate(-180deg)', marginLeft: '8px' }} onClick={() => { documents.documents.sort(dynamicSort(sortClassification === true ? "classification" : "-classification", "classification")); setSortClassification(!sortClassification); setDocKey(!docKey) }}><Icon iconName={"DirectionIcon"} width={16} height={16} fill={grey800} /></IconButton></Box>
                        <Box display="flex" alignItems="center" className={classes.header6}>Uploader<IconButton style={sortUploader === true ? { marginLeft: '8px' } : { transform: 'rotate(-180deg)', marginLeft: '8px' }} onClick={() => { documents.documents.sort(dynamicSort(sortUploader === true ? "firstName" : "-firstName", "uploader")); setSortUploader(!sortUploader); setDocKey(!docKey) }}><Icon iconName={"DirectionIcon"} width={16} height={16} fill={grey800} /></IconButton></Box>
                        <Box display="flex" alignItems="center" className={classes.header7}>Upload Date<IconButton style={sortUploadDate === true ? { marginLeft: '8px' } : { transform: 'rotate(-180deg)', marginLeft: '8px' }} onClick={() => { documents.documents.sort(dynamicSort(sortUploadDate === true ? "uploadedAt" : "-uploadedAt")); setSortUploadDate(!sortUploadDate); setDocKey(!docKey) }}><Icon iconName={"DirectionIcon"} width={16} height={16} fill={grey800} /></IconButton></Box>
                        <Box className={classes.header8}></Box>
                    </Box>
                    {documents?.documents?.length > 0 && documents?.documents?.map((document) => {
                        return (
                            <DocumentListElement
                                key={document.docId}
                                document={document}
                                access={access}
                                sizeWindow={sizeWindow}
                                isLocked={isLocked}
                            />)
                    })}
                    {(documents?.documents?.length < 1 || Object.keys(documents).length === 0) &&
                        <Box className={classes.border}>
                            <Box display="flex" alignItems="center" justifyContent="center" style={{ lineHeight: '48px', color: grey800 }}>No documents have been added yet.</Box>
                        </Box>
                    }
                </Paper>
            </Grid>
        </Grid>
    )
}

export default DocumentListTable;