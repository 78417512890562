import React from 'react';

import { ToggleButton, ToggleButtonGroup, Box, Typography } from '@mui/material';
import { useStyles } from '../../editing/Table.styles';

import { TEST_STATUS_MAPPER, TEST_RESULT_MAPPER } from '../../../other/helper/utilsMapper/utilsMapper'

export function upDateFilter( setActiveTestCases, testCaseList, activeTestInstances, testInstances, activeTestStatuses, testStatuses, activeTestResults, testResults ) {
    let value1 = [];
    let value2 = [];
    let value3 = [];
    let allValues1 = [];
    let allValues2 = [];
    let allValues3 = [];
    setActiveTestCases([]);
    // TEST INSTANCES
    if (testCaseList !== undefined) {
        if (activeTestInstances?.length < 1) {
            for (let i = 0; i < testInstances?.testInstances?.length; i++) {
                value1 = testCaseList?.testCases?.filter(({ testInstance }) => testInstance.name === testInstances?.testInstances[i]?.name);
                allValues1 = allValues1.concat(value1);
            }
        } else {
            for (let i = 0; i < activeTestInstances?.length; i++) {
                value1 = testCaseList?.testCases?.filter(({ testInstance }) => testInstance.name === activeTestInstances[i]);
                allValues1 = allValues1.concat(value1);
            }
        }
        // TEST STATUSES
        if (activeTestStatuses?.length < 1) {
            for (let i = 0; i < testStatuses?.testStatuses?.length; i++) {
                value2 = allValues1?.filter(({ testStatus }) => testStatus.name === testStatuses?.testStatuses[i]?.name);
                allValues2 = allValues2.concat(value2);
            }
        } else {
            for (let i = 0; i < activeTestStatuses?.length; i++) {
                value2 = allValues1?.filter(({ testStatus }) => testStatus.name === activeTestStatuses[i]);
                allValues2 = allValues2.concat(value2);
            }
        }

        // TEST RESULTS
        if (activeTestResults?.length < 1) {
            for (let i = 0; i < testResults?.testResults?.length; i++) {
                value3 = allValues2?.filter(({ testResult }) => testResult.name === testResults?.testResults[i]?.name);
                allValues3 = allValues3.concat(value3);
            }
        } else {
            for (let i = 0; i < activeTestResults?.length; i++) {
                value3 = allValues2?.filter(({ testResult }) => testResult.name === activeTestResults[i]);
                allValues3 = allValues3.concat(value3);
            }
        }
        // Check if no testresults provided (Planning table)
        if (activeTestResults !== undefined) {
            setActiveTestCases(allValues3);
        } else setActiveTestCases(allValues2);
    }
}

export default function TableFilter(props) {
    const {
        activeTestInstances, activeTestStatuses, activeTestResults,
        testInstances, testStatuses, testResults,
        handleTestInstanceClick, handleTestStatusClick, handleTestResultClick,
        table
    } = props;
    
    let sizeWindow;

    switch (true) {
        case window.innerWidth < 1576:
            sizeWindow = 4;
            break;
        case window.innerWidth < 1872:
            sizeWindow = 5;
            break;
        case window.innerWidth < 2168:
            sizeWindow = 6;
            break;
        case window.innerWidth < 2464:
            sizeWindow = 7;
            break;
        case window.innerWidth < 2760:
            sizeWindow = 8;
            break;
        case window.innerWidth >= 2760:
            sizeWindow = 9;
            break;
        default:
            sizeWindow = 4;
            break;
    }
    
    const classes = useStyles();
    return (
        <>
            <Box mr={2} mt={2} mb={3} display="flex" alignItems="center" flexWrap="wrap" style={{marginLeft: sizeWindow < 5 ? '40px' : '48px'}}>
                <Typography variant="h5" className={classes.filter_title}>
                    Test Instance:
                </Typography>
                <ToggleButtonGroup
                    value={activeTestInstances}
                    onChange={handleTestInstanceClick}
                    className={classes.toggle_button_group}
                >
                    {testInstances.testInstances.map(i => {
                        return (
                            <ToggleButton
                                key={i.testInstanceId}
                                classes={{
                                    root: classes.parameter_button,
                                    selected: classes.parameter_button_selected,
                                }}
                                value={i.name}
                            >
                                {i.name}
                            </ToggleButton>
                        )
                    })}
                </ToggleButtonGroup>
            </Box>

            <Box mr={2} mt={2} mb={3} display="flex" alignItems="center" flexWrap="wrap" style={{marginLeft: sizeWindow < 5 ? '40px' : '48px'}}>
                <Typography variant="h5" className={classes.filter_title}>
                    Test Status:
                </Typography>
                <ToggleButtonGroup
                    value={activeTestStatuses}
                    onChange={handleTestStatusClick}
                    className={classes.toggle_button_group}
                >
                    {testStatuses.testStatuses.map(i => {
                        return (
                            <ToggleButton
                                key={i.name}
                                classes={{
                                    root: classes.parameter_button,
                                    selected: classes.parameter_button_selected,
                                }}
                                value={i.name}
                            >
                                {TEST_STATUS_MAPPER.NAME[i.name]}
                            </ToggleButton>
                        )
                    })}
                </ToggleButtonGroup>
            </Box>

            {
                table &&
                <Box mr={2} mt={2} mb={3} display="flex" alignItems="center" flexWrap="wrap" style={{marginLeft: sizeWindow < 5 ? '40px' : '48px'}}>
                    <Typography variant="h5" className={classes.filter_title}>
                        Test Result:
                    </Typography>
                    <ToggleButtonGroup
                        value={activeTestResults}
                        onChange={handleTestResultClick}
                        className={classes.toggle_button_group}
                    >
                        {testResults.testResults.map(i => {
                            return (
                                <ToggleButton
                                    key={i.testResultId}
                                    classes={{
                                        root: classes.parameter_button,
                                        selected: classes.parameter_button_selected,
                                    }}
                                    value={i.name}
                                >
                                    {TEST_RESULT_MAPPER.NAME[i.name]}
                                </ToggleButton>
                            )
                        })}
                    </ToggleButtonGroup>
                </Box>
            }
        </>
    )
}