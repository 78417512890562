import React, {useState, useEffect, useContext} from 'react';
import { Button, Dialog, IconButton, DialogContent, Box, Typography, InputBase, Select, MenuItem } from '@mui/material';
import { useStyles } from '../UniversalDialog.styles.js';
import Icon from '../../../../assets/icons/Icon.js';
import {
    SnackbarContext, SnackbarType
} from '../../contexts/SnackBarContext.js';
import { handleInputChange } from '../../helper/globalFunctions/globalFunctions.js';
import { grey1000, petrol800, buttonHoverGrey800, petrol600 } from '../../helper/MuiStyles.js';


function AddEditParameter(props) {
    const {
        edit,
        headline,
        mutateFunction,
        maxCharacterName,
        maxCharacterDescription,
        currentParameter,
        activeValues,
        inactiveValues,
        unitValues,
        physicalQuantityValues
    } = props;
    const { setIsSnackbarOpen, setMessage, setSnackbarType } = useContext(SnackbarContext);

    const [show, setShow] = useState(false);
    const [newName, setNewName] = useState("");
    const [newDescription, setNewDescription] = useState("");

    const [formValues, setFormValues] = useState({
        physicalQuantity: '',
        unit: ''
    });

    const [mutationValues, setMutationValues] = useState({
        physicalQuantity: '',
        unit: ''
    });

    const [saveDisabled, setSaveDisabled] = useState(false);
    const [maxCharNameExceeded, setMaxCharNameExceeded] = useState(false);
    const [maxCharDescriptionExceeded, setMaxCharDescriptionExceeded] = useState(false);
    const [alreadyActive, setAlreadyActive] = useState(false);
    const [alreadyInactive, setAlreadyInactive] = useState(false);

    const ToggleModal = () => {
        setShow(!show);
    };

    const handleCancel = () => {
        if (edit === true) {
            setNewName(currentParameter?.name);
            setNewDescription(currentParameter?.description);
            setFormValues({
                physicalQuantity: currentParameter?.physicalQuantity.name,
                unit: currentParameter?.unit?.name
            });
            setMutationValues({
                physicalQuantity: currentParameter?.physicalQuantity.physicalQuantityId,
                unit: currentParameter?.unit?.unitId
            });
        } else {
            setNewName("");
            setNewDescription("");
            setFormValues({ physicalQuantity: '', unit: '' });
            setMutationValues({ physicalQuantity: '', unit: '' });
        }
        ToggleModal();
    };

    const handleNameChange = () => (event) => {
        setNewName(event.target.value);
    };

    const handleDescriptionChange = () => (event) => {
        setNewDescription(event.target.value);
    };

    const handleSave = () => {
        mutateFunction({ variables: { id: currentParameter.parameterId, active: true, name: newName, physicalQuantity: mutationValues.physicalQuantity, description: newDescription, unit: mutationValues.unit } });
        setMessage(`${headline} has been updated.`);
        setSnackbarType(SnackbarType.SUCCESS);
        setIsSnackbarOpen(true);
        setShow(false);
    };

    const addData = () => {
        mutateFunction({ variables: { name: newName, physicalQuantity: mutationValues.physicalQuantity, description: newDescription, unit: mutationValues.unit } });
        setMessage(`${headline} has been added.`);
        setSnackbarType(SnackbarType.SUCCESS);
        setIsSnackbarOpen(true);
        setShow(false);
    };

    useEffect(() => {
        if (activeValues !== undefined && inactiveValues !== undefined) {
            setSaveDisabled(false);
            setMaxCharNameExceeded(false);
            setMaxCharDescriptionExceeded(false);
            setAlreadyActive(false);
            setAlreadyInactive(false);

            if (newName.length > maxCharacterName) {
                setSaveDisabled(true);
                setMaxCharNameExceeded(true);
            }

            if (newDescription.length > maxCharacterDescription) {
                setSaveDisabled(true);
                setMaxCharDescriptionExceeded(true);
            }

            if (mutationValues.physicalQuantity?.length < 1 || newDescription.length < 1 || newName.length < 1) {
                setSaveDisabled(true);
            }

            if (newName.toUpperCase() === currentParameter?.name.toUpperCase() && formValues.physicalQuantity === currentParameter?.physicalQuantity?.name) {
                if (formValues.unit?.length < 1) {
                    if (currentParameter.unit === null) {
                        setSaveDisabled(true);
                    }
                } else {
                    if (currentParameter.unit !== null) {
                        if (formValues.unit === currentParameter.unit?.name) {
                            setSaveDisabled(true);
                        }
                    }
                }
            }

            for (let i = 0; i < activeValues.parameters?.length; i++) {
                if (activeValues.parameters[i].name.toUpperCase() === newName.toUpperCase()) {
                    if (activeValues.parameters[i].physicalQuantity.name === formValues.physicalQuantity) {
                        if (activeValues.parameters[i].unit === null) {
                            if (formValues.unit?.length < 1) {
                                setSaveDisabled(true);
                                if (newName !== currentParameter?.name) {
                                    setAlreadyActive(true);
                                }
                                break;
                            }
                        } else {
                            if (activeValues.parameters[i].unit.name === formValues.unit) {
                                setSaveDisabled(true);
                                if (newName !== currentParameter?.name) {
                                    setAlreadyActive(true);
                                }
                                break;
                            }
                        }
                    }
                }
            }
            for (let i = 0; i < inactiveValues.parameters?.length; i++) {
                if (inactiveValues.parameters[i].name.toUpperCase() === newName.toUpperCase()) {
                    if (inactiveValues.parameters[i].physicalQuantity.name === formValues.physicalQuantity) {
                        if (inactiveValues.parameters[i].unit === null) {
                            if (formValues.unit?.length < 1) {
                                setSaveDisabled(true);
                                setAlreadyInactive(true);
                                break;
                            }
                        } else {
                            if (inactiveValues.parameters[i].unit.name === formValues.unit) {
                                setSaveDisabled(true);
                                setAlreadyInactive(true);
                                break;
                            }
                        }
                    }
                }
            }
        }
        else if (activeValues === undefined || inactiveValues === undefined) {
            setSaveDisabled(true);
        }
    }, [activeValues, inactiveValues, newName, newDescription, mutationValues.physicalQuantity, mutationValues.unit]) // eslint-disable-line

    useEffect(() => {
        if (currentParameter !== undefined) {
            setFormValues({
                physicalQuantity: currentParameter?.physicalQuantity.name,
                unit: currentParameter?.unit?.name
            });
            setMutationValues({
                physicalQuantity: currentParameter?.physicalQuantity.physicalQuantityId,
                unit: currentParameter?.unit?.unitId
            });
            setNewDescription(currentParameter?.description);
            setNewName(currentParameter?.name);
        }
    }, [currentParameter]) // eslint-disable-line

    const styleProps = {
        editIconWrapperMarginLeft: '4px',
        editIconHoverBackground: buttonHoverGrey800,
      };

    const classes = useStyles(styleProps);

    return <>
        {edit ?
            <IconButton
                className={classes.edit_icon_wrapper}
                onClick={ToggleModal}
                size="large">
                <Icon iconName={"EditIcon"} fill={grey1000} />
            </IconButton>
            :
            <Button variant="contained" color="secondary" className={classes.add_parameter_button} onClick={ToggleModal}>
            <div style={{ display: 'flex', marginRight: '8px' }}><Icon iconName={"AddIcon"} fill={petrol600} /></div> Add more
            </Button>
        }

        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={show}
            onClose={handleCancel}
        >
            <DialogContent>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h2">
                        {edit ? 'Edit' : 'Add'} {edit ? currentParameter.name : headline}
                    </Typography>
                    <IconButton onClick={handleCancel} className={classes.hover_blue}>
                        <Icon iconName={"CloseIcon"} fill={petrol800} />
                    </IconButton>
                </Box>
                <Box mt={2} display="flex" alignItems="center">
                    <Typography variant="h4" style={{ fontWeight: '400' }}>
                        Please assign a new name, description, physical quantity and unit to this {headline}.
                    </Typography>
                </Box>

                <Box mt={3} display='flex' flexDirection='column' justifyContent="flex-start">
                    <Typography variant="h6" className={classes.index_title}>
                        Name *
                    </Typography>
                    <InputBase
                        name="newName"
                        className={classes.box_freeText}
                        multiline={true}
                        fullWidth={true}
                        placeholder="Add new name"
                        value={newName}
                        onChange={handleNameChange()}
                        error={maxCharNameExceeded}
                    />
                </Box>
                {maxCharNameExceeded &&
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="subtitle2" className={classes.alert_message}>The name must not contain more than {maxCharacterName} characters.</Typography>
                        <Typography variant="subtitle2" className={classes.alert_message}>{newName.length}/{maxCharacterName}</Typography>
                    </Box>
                }
                {alreadyActive &&
                    <Box display="flex" justifyContent="flex-start">
                        <Typography variant="subtitle2" className={classes.alert_message}>This Parameter is already in use by another active {headline}.</Typography>
                    </Box>
                }
                {alreadyInactive &&
                    <Box display="flex" justifyContent="flex-start" flexWrap="wrap">
                        <Typography variant="subtitle2" className={classes.alert_message}>This Parameter already belongs to an inactive {headline}. <br /> Please reactivate that instead.</Typography>
                    </Box>
                }

                <Box mt={3} display='flex' flexDirection='column' justifyContent="flex-start">
                    <Typography variant="h6" className={classes.index_title}>
                        Description *
                    </Typography>
                    <InputBase
                        name="newDescription"
                        className={classes.box_freeText}
                        multiline={true}
                        fullWidth={true}
                        placeholder="Add new description"
                        value={newDescription}
                        onChange={handleDescriptionChange()}
                        error={maxCharDescriptionExceeded}
                    />
                </Box>
                {maxCharDescriptionExceeded &&
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="subtitle2" className={classes.alert_message}>The description must not contain more than {maxCharacterDescription} characters.</Typography>
                        <Typography variant="subtitle2" className={classes.alert_message}>{newDescription.length}/{maxCharacterDescription}</Typography>

                    </Box>
                }

                <Box mt={3} display="flex" justifyContent="space-between">
                    <Box display='flex' flexDirection='column' justifyContent="flex-start" flexGrow="1" className={classes.large_box}>
                        <Typography variant="h6" className={classes.index_title}>
                            Physical Quantity *
                        </Typography>
                        <Select
                            name="physicalQuantity"
                            data-testid='physicalQuantity'
                            value={formValues.physicalQuantity}
                            onChange={handleInputChange(setFormValues, formValues, setMutationValues, mutationValues)}
                            style={{ minWidth: '130px', textAlign: 'start'}}
                        >
                            {physicalQuantityValues?.physicalQuantities.map((option) => (
                                <MenuItem
                                    datamutationvalue={option.physicalQuantityId}
                                    key={option.physicalQuantityId}
                                    value={option.name}
                                    disabled={!option.active}
                                >
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box display='flex' flexDirection='column' justifyContent="flex-start" flexGrow="1" className={classes.large_box}>
                        <Typography variant="h6" className={classes.index_title}>
                            Unit
                        </Typography>
                        <Select
                            labelId="unit"
                            name="unit"
                            data-testid='unit'
                            value={formValues.unit}
                            onChange={handleInputChange(setFormValues, formValues, setMutationValues, mutationValues)}
                            style={{ minWidth: '130px', textAlign: 'start'}}
                        >
                            <MenuItem
                                value=''
                                datamutationvalue=''
                            >
                                <em>None</em>
                            </MenuItem>
                            {unitValues?.units.map((option) => (
                                <MenuItem
                                    datamutationvalue={option.unitId}
                                    key={option.unitId}
                                    value={option.name}
                                    disabled={!option.active}
                                >
                                    {option.abbreviation} ({option.name})
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>

                </Box>
                <Box mt={4} display="flex" alignItems="center">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={saveDisabled}
                        onClick={edit ? handleSave : addData}
                    >
                        Save
                    </Button>
                    <Typography variant="h6" className={classes.required_tag}>
                        * Mandatory field
                    </Typography>
                </Box>
            </DialogContent>
        </Dialog>
    </>
}

export default AddEditParameter;