import React, {useState, useEffect, useContext} from 'react';
import { Button, Dialog, IconButton, DialogContent, Box, Typography, InputBase } from '@mui/material';
import { useStyles } from '../UniversalDialog.styles.js';
import Icon from '../../../../assets/icons/Icon.js';
import { SnackbarContext, SnackbarType } from '../../contexts/SnackBarContext.js';
import { buttonHoverGrey800, grey1000, petrol600, petrol800 } from '../../helper/MuiStyles.js';

function AddEditAgingStatus(props) {
    const {
        edit,
        headline,
        agingStatus,
        mutateFunction,
        maxCharacterName,
        maxCharacterDescription,
        activeValues,
        inactiveValues,
        queryName,
    } = props;
    const { setIsSnackbarOpen, setMessage, setSnackbarType } = useContext(SnackbarContext);

    const [show, setShow] = useState(false);
    const [edited, setEdited] = useState(false);
    const [newName, setNewName] = useState("");
    const [newDescription, setNewDescription] = useState("");
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [maxCharNameExceeded, setMaxCharNameExceeded] = useState(false);
    const [maxCharDescriptionExceeded, setMaxCharDescriptionExceeded] = useState(false);
    const [alreadyActive, setAlreadyActive] = useState(false);
    const [alreadyInactive, setAlreadyInactive] = useState(false);

    useEffect(() => {
        if (activeValues !== undefined && inactiveValues !== undefined) {
            setSaveDisabled(false);
            setMaxCharNameExceeded(false);
            setMaxCharDescriptionExceeded(false);
            setAlreadyActive(false);
            setAlreadyInactive(false);

            if (newName.length > maxCharacterName) {
                setSaveDisabled(true);
                setMaxCharNameExceeded(true);
            }

            if (newDescription.length > maxCharacterDescription) {
                setSaveDisabled(true);
                setMaxCharDescriptionExceeded(true);
            }

            if (newName === agingStatus?.name && newDescription === agingStatus?.description) {
                setSaveDisabled(true);
            }

            for (let i = 0; i < activeValues[queryName]?.length; i++) {
                if (activeValues[queryName][i]['name'].toUpperCase() === newName.toUpperCase()) {
                    if (activeValues[queryName][i]['description'] === newDescription) {
                        setSaveDisabled(true);
                        if (edited) {
                            setAlreadyActive(true);
                        }
                        break;
                    }
                }
            }

            for (let i = 0; i < inactiveValues[queryName]?.length; i++) {
                if (inactiveValues[queryName][i]['name'].toUpperCase() === newName.toUpperCase()) {
                    if (inactiveValues[queryName][i]['description'] === newDescription) {
                        setSaveDisabled(true);
                        setAlreadyInactive(true);
                        break;
                    }
                }
            }
        }
        else if (activeValues === undefined || inactiveValues === undefined) {
            setSaveDisabled(true);
        }
    }, [activeValues, inactiveValues, newDescription, newName]) // eslint-disable-line

    useEffect(() => {
        if (agingStatus !== undefined) {
            setNewName(agingStatus.name);
            setNewDescription(agingStatus.description);
        }
    }, [agingStatus]) // eslint-disable-line

    const ToggleModal = () => {
        setShow(!show);
    };

    const handleCancel = () => {
        if (edit === true) {
            setNewName(agingStatus.name);
            setNewDescription(agingStatus.description);
        } else {
            setNewName("");
            setNewDescription("");
        }
        ToggleModal();
    };

    const handleNameChange = () => (event) => {
        setNewName(event.target.value);
        setEdited(true);
    };

    const handleDescriptionChange = () => (event) => {
        setNewDescription(event.target.value);
        setEdited(true);
    };

    const handleSave = () => {
        mutateFunction({ variables: { id: agingStatus?.agingStatusId, name: newName, description: newDescription, active: true } });
        setNewName("");
        setNewDescription("");
        setMessage(`${headline} has been updated.`)
        setSnackbarType(SnackbarType.SUCCESS);
        setIsSnackbarOpen(true);
        setShow(false);
    };

    const addData = () => {
        mutateFunction({ variables: { name: newName, description: newDescription } });
        setNewName("");
        setNewDescription("");
        setMessage(`${headline} has been added.`)
        setSnackbarType(SnackbarType.SUCCESS);
        setIsSnackbarOpen(true);
        setShow(false);
    };
       
    const styleProps = {
        editIconWrapperMarginLeft: '8px',
        editIconHoverBackground: buttonHoverGrey800,
        addButtonMarginBottom: '16px',
        addButtonBackgroundColor: '#FFF',
      };

    const classes = useStyles(styleProps);

    return (
        <>
            {edit ?
                <IconButton className={classes.edit_icon_wrapper} onClick={ToggleModal}>
                    <Icon iconName={"EditIcon"} fill={grey1000} />
                </IconButton>
                :
                <Button variant="contained" color="secondary" className={classes.add_button} onClick={ToggleModal}>
                    <div style={{ display: 'flex', marginRight: '8px' }}><Icon iconName={"AddIcon"} fill={petrol600} /></div> Add more
                </Button>
            }

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={show}
                onClose={handleCancel}
            >
                <DialogContent>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h2">
                            {edit ? 'Edit' : 'Add'} {edit ? agingStatus?.name : headline}
                        </Typography>
                        <IconButton onClick={handleCancel} className={classes.hover_blue}>
                            <Icon iconName={"CloseIcon"} fill={petrol800} />
                        </IconButton>
                    </Box>
                    <Box mt={2} display="flex" flexDirection="column" alignItems="flex-start">
                        <Typography variant="h4" style={{ fontWeight: '400', textAlign: 'left' }}>
                            Please assign a new name and description to this {headline}.
                        </Typography>
                        {newName.length > 0 &&
                            <Typography variant="h4" style={{ fontWeight: '400', textAlign: 'left' }}>
                                New {headline} will look as follows: {newName}{newDescription?.length > 0 ? ` (${newDescription})` : ''}.
                            </Typography>
                        }
                    </Box>
                    <Box mt={3} display="flex" justifyContent="space-between">
                        <Box display='flex' flexDirection='column' justifyContent="flex-start" flexGrow="1">
                            <Typography variant="h6" className={classes.index_title}>
                                Name *
                            </Typography>
                            <InputBase
                                name="newValue"
                                className={classes.box_freeText}
                                multiline={true}
                                fullWidth={true}
                                placeholder="Add new name"
                                value={newName}
                                onChange={handleNameChange()}
                                error={maxCharNameExceeded}
                            />
                        </Box>
                    </Box>
                    {maxCharNameExceeded &&
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="subtitle2" className={classes.alert_message}>The name must not contain more than {maxCharacterName} characters.</Typography>
                            <Typography variant="subtitle2" className={classes.alert_message}>{newName.length}/{maxCharacterName}</Typography>
                        </Box>
                    }
                    <Box mt={3} display="flex" justifyContent="space-between">
                        <Box display='flex' flexDirection='column' justifyContent="flex-start" flexGrow="1">
                            <Typography variant="h6" className={classes.index_title}>
                                Description
                            </Typography>
                            <InputBase
                                name="newValue"
                                className={classes.box_freeText}
                                multiline={true}
                                fullWidth={true}
                                placeholder="Add new description"
                                value={newDescription}
                                onChange={handleDescriptionChange()}
                                error={maxCharDescriptionExceeded}
                            />
                        </Box>
                    </Box>
                    {maxCharDescriptionExceeded &&
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="subtitle2" className={classes.alert_message}>The description must not contain more than {maxCharacterDescription} characters.</Typography>
                            <Typography variant="subtitle2" className={classes.alert_message}>{newDescription.length}/{maxCharacterDescription}</Typography>
                        </Box>
                    }
                    {alreadyActive &&
                        <Box display="flex" justifyContent="flex-start">
                            <Typography variant="subtitle2" className={classes.alert_message}>This Aging Status is already in use by another active {headline}.</Typography>
                        </Box>
                    }
                    {alreadyInactive &&
                        <Box display="flex" justifyContent="flex-start" flexWrap="wrap">
                            <Typography variant="subtitle2" className={classes.alert_message}>This Aging Status already belongs to an inactive {headline}. <br /> Please reactivate that instead.</Typography>
                        </Box>
                    }
                    <Box mt={4} display="flex" alignItems="center">
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={newName?.length < 1 || saveDisabled}
                            onClick={edit ? handleSave : addData}
                        >
                            Save
                        </Button>
                        <Typography variant="h6" className={classes.required_tag}>
                            * Mandatory field
                        </Typography>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AddEditAgingStatus;