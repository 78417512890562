import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useLazyQuery, useReactiveVar, useMutation } from '@apollo/client';
import { currentTestSpecificationId, isLockLoading, projectRole } from '../../../operations/localeStorage';
import { TESTSPECIFICATION, ALLPARAMETERS, ALLCATEGORIES, ALLGROUPSTANDARDREFERENCES, ALLAPPROVALS } from '../../../operations/queries/query';
import { UPDATETESTSPECIFICATION, UPDATETESTSPECIFICATIONNAME } from '../../../operations/mutations/mutations';
import { Snackbar, Paper, Grid, Button, Box, Typography, InputLabel, Select, MenuItem, InputBase, IconButton, InputAdornment } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Icon from '../../../assets/icons/Icon.js';
import { useStyles } from './TestSpecificationDetails.styles.js';
import AddNewStep from '../Step/AddNewStep/AddNewStep';
import { handleInputChange, handleInputChangeFreeText } from '../../other/helper/globalFunctions/globalFunctions'
import StepDescription from '../Step/StepDescription/StepDescription';
import ScrollableVerisonTabs from '../ScrollableVerisonTabs';
import LockComponent from '../../other/dialogs/LockComponent/LockComponent';
import AddEditData from '../../other/dialogs/AddEditData/AddEditData';
import ParameterInfo from '../../other/tooltips/ParameterInfo/ParameterInfo';
import BatteryLoader from '../../other/helper/BatteryLoader/BatteryLoader';
import ParameterSelection from '../ParameterSelection/ParameterSelection';
import { TestSpecErrorMessage } from '../TestSpecErrorMessage';
import { SnackbarContext, SnackbarType } from '../../other/contexts/SnackBarContext';
import StickyBar from '../../stickybar/StickyBar';
import { petrol800, grey1000, grey100 } from '../../other/helper/MuiStyles';
import DocumentListTable from '../../other/documenttable/DocumentListTable/DocumentListTable';
import RequirementIdsInfo from '../../other/tooltips/RequirementIdsInfo/RequirementIdsInfo';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const ConditionalWrap = ({ condition, children }) => {
    return (
        <>
            {!condition ? (
                <div style={{ padding: "24px", backgroundColor: grey100 }}>

                    <Grid container spacing={3}>
                        <Grid item xs={12} display="grid" pb={8}>
                            <Paper style={{ minWidth: "1232px", backgroundColor: "#FFF" }}>
                                {children}
                            </Paper>
                        </Grid>
                    </Grid>

                </div>
            ) : (
                children
            )}
        </>
    );
};

function TestSpecificationDetails({ testSpecId }) {
    const role = useReactiveVar(projectRole);
    const lockLoading = useReactiveVar(isLockLoading)
    const navigate = useNavigate();
    const classes = useStyles();
    const { setIsSnackbarOpen, setMessage, setSnackbarType } = useContext(SnackbarContext);
    // Wrap inside div for testspecdetails and without for testcasedetails

    const { data: parametersData, loading: parametersDataLoading, error: parametersDataError } = useQuery(ALLPARAMETERS);
    const { data: categories, loading: categoriesLoading, error: categoriesError } = useQuery(ALLCATEGORIES);
    const { data: approvals, loading: approvalsLoading, error: approvalsError } = useQuery(ALLAPPROVALS);
    const { data: allGroupStandardReferences, loading: allGroupStandardReferencesLoading, error: allGroupStandardReferencesError } = useQuery(ALLGROUPSTANDARDREFERENCES);

    let { id } = useParams();
    let testSpecificationId = testSpecId !== undefined ? testSpecId : id;

    const [getTestSpecData, { data: testSpecification, loading: testSpecificationLoading, error: testSpecificationError }] = useLazyQuery(
        TESTSPECIFICATION(testSpecificationId), 
        { fetchPolicy: 'network-only' }
    );

    const [formValues, setFormValues] = useState({
        category: '',
        approval: '',
        groupStandardReference: '',
    });

    const [mutationValues, setMutationValues] = useState({
        name: '',
        version: '',
        tag: '',
        category: '',
        approval: '',
        groupStandardReference: '',
        description: '',
        terminationCriteria: '',
        testProtocolContents: '',
        postCondition: '',
        reference: '',
        testDuration: '',
        requirementIds: '',
    });

    const [docList, setDocList] = useState({});

    const handleOnCompleted = async () => {
        setSnackbarType(SnackbarType.SUCCESS);
        setIsSnackbarOpen(true);
    }

    const handleOnError = async (error) => {
        setMessage(error.message);
        setSnackbarType(SnackbarType.ERROR);
        setIsSnackbarOpen(true);
    }

    const [updateTestSpec, { loading: updateTestSpecLoading }] = useMutation(UPDATETESTSPECIFICATION, {
        refetchQueries: [{ query: TESTSPECIFICATION(testSpecificationId) }],
        awaitRefetchQueries: true,
        onCompleted: handleOnCompleted,
        onError: handleOnError,
    });

    const [updateTestSpecName, { loading: updateTestSpecNameLoading }] = useMutation(UPDATETESTSPECIFICATIONNAME, {
        refetchQueries: [{ query: TESTSPECIFICATION(testSpecificationId) }],
        awaitRefetchQueries: true,
        onCompleted: handleOnCompleted,
        onError: handleOnError,
    });

    const [relations, setRelations] = useState([]);

    //PARAMETER VALUES
    const [allParameters, setAllParameters] = useState();
    const [selectedParams, setSelectedParams] = useState([]);
    const [selectedConditions, setSelectedConditions] = useState([]);
    //Currently displayed parameters in list
    const [activeParameters, setActiveParameters] = useState([]);
    //number of currently active condition columns (starting with 1!) 
    const [conditionCounter, setConditionCounter] = useState(() => {
        return 0
    });

    //STEPS VALUES    
    const [pretreatmentSteps, setPretreatmentSteps] = useState([]);
    const [procedureSteps, setProcedureSteps] = useState([]);

    //Snackbars
    const [openDraft, setOpenDraft] = useState(false);

    // VALIDATION CHECKS
    const [checkTestDuration, setCheckTestDuration] = useState(false);
    const [checkRequirementIds, setCheckRequirementIds] = useState(false);

    const insertSteps = (steps, stepType) => {
        if (stepType === 'Pre Treatment') {
            let value = [];
            for (let i = 0; i < steps?.length; i++) {
                value[i] = { id: steps[i].id, number: steps[i].number, stepDescription: steps[i].stepDescription };
            }
            setPretreatmentSteps(value);
        }
        if (stepType === 'Procedure') {
            let value = [];
            for (let i = 0; i < steps?.length; i++) {
                value[i] = { id: steps[i].id, number: steps[i].number, stepDescription: steps[i].stepDescription };
            }
            setProcedureSteps(value);
        }
    };

    const handleAddNewVersion = () => {
        navigate('/createtestspecification', { state: { searchField: testSpecification?.testSpecification?.name, versionValue: { versionNumber: testSpecification?.testSpecification?.version + 1 }, availableTestSpecification: { testSpecificationId: testSpecification?.testSpecification?.testSpecificationId } } }
        )
    };

    const insertParameters = (params) => {
        let allPara = JSON.parse(JSON.stringify(allParameters));
        let filterPara = [];
        let filterCond = [];
        let conditionLength = params?.[0]?.conditions?.length;
        if (conditionLength === undefined) {
            conditionLength = 0;
        }

        params?.map(parameter => {
            return (
                allPara.find(x => x.name === parameter.parameter.name).selected = true,
                filterPara.push({ assignParameterId: parameter.assignParameterId, parameter: allPara.find(x => x.name === parameter.parameter.name) }),
                filterCond.push(parameter.conditions)
            )
        });
        setAllParameters(allPara);
        setActiveParameters(allPara);
        setSelectedParams(filterPara);
        setSelectedConditions(filterCond);
        setConditionCounter(conditionLength);
    };

    const handleAddConditionColumn = () => {
        let conditions = JSON.parse(JSON.stringify(selectedConditions));
        conditions.map(i => {
            return (
                i.push({ id: null, condition: "" })
            )
        });
        setSelectedConditions(conditions);
        setConditionCounter(conditionCounter + 1);
    };

    const handleRemoveParameter = (index) => {
        let params = JSON.parse(JSON.stringify(selectedParams));
        let allparams = JSON.parse(JSON.stringify(allParameters));
        let conditions = JSON.parse(JSON.stringify(selectedConditions));
        allparams.find(x => x.name === params[index].parameter.name).selected = false;
        setAllParameters(allparams);
        setActiveParameters(allparams);
        params.splice(index, 1);
        setSelectedParams(params);
        conditions.splice(index, 1);
        setSelectedConditions(conditions);
        if (params.length === 0) {
            setConditionCounter(0);
        }
    };

    const handleConditionChange = (event, index, index2) => {
        const conditions = JSON.parse(JSON.stringify(selectedConditions));
        conditions[index][index2].condition = event.target.value;
        if (event.target.value.length < 256) {
            setSelectedConditions(conditions);
        }
    };

    const handleRemoveConditionColumn = (column) => {
        let conditions = [...selectedConditions];
        conditions.forEach(function (currentElement) {
            currentElement.splice(column, 1);
        });
        setSelectedConditions(conditions);
        setConditionCounter(conditionCounter - 1);
    };

    const submitState = () => {
        return (role === 'Auditor User' || mutationValues.tag !== "DRAFT" || mutationValues.category < 1 || procedureSteps.length < 1 || !checkTestDuration || mutationValues.description.length > 2000 || mutationValues.terminationCriteria.length > 2000 || mutationValues.testProtocolContents.length > 2000 || mutationValues.postCondition.length > 2000 || mutationValues.reference.length > 2000 || !checkRequirementIds)
    };

    const isLoading = () => {
        return (categoriesLoading || approvalsLoading || allGroupStandardReferencesLoading || parametersDataLoading || 
            testSpecificationLoading || updateTestSpecLoading || updateTestSpecNameLoading)
    };

    const hasError = () => {
        return !(categoriesError === undefined && approvalsError === undefined && allGroupStandardReferencesError === undefined && parametersDataError === undefined && testSpecificationError === undefined)
    };

    const handleSubmit = (e) => {
        let para = JSON.parse(JSON.stringify(selectedParams));
        let cond = JSON.parse(JSON.stringify(selectedConditions));
        let cleanedMutationParameters = [];
        for (let i = 0; i < cond.length; i++) {
            cleanedMutationParameters.push({ assignParameterId: para[i].assignParameterId, parameterId: para[i].parameter.parameterId, conditions: [] });
            for (let j = 0; j < cond[i].length; j++) {
                cleanedMutationParameters[i].conditions.push({ id: cond[i][j].id, condition: cond[i][j].condition });
            }
        }
        e.preventDefault();
        setMessage('Test Specification has been updated.');
        updateTestSpec({ variables: { testSpecificationId: testSpecificationId, name: mutationValues.name, version: mutationValues.version, tag: "DRAFT", category: mutationValues.category, procedureSteps: procedureSteps, approval: mutationValues.approval === '' ? null : mutationValues.approval, groupStandardReference: mutationValues.groupStandardReference === '' ? null : mutationValues.groupStandardReference, description: mutationValues.description, pretreatmentSteps: pretreatmentSteps, terminationCriteria: mutationValues.terminationCriteria, testProtocolContents: mutationValues.testProtocolContents, postCondition: mutationValues.postCondition, reference: mutationValues.reference, testDuration: mutationValues.testDuration === '' ? null : mutationValues.testDuration, parameters: cleanedMutationParameters, requirementIds: mutationValues.requirementIds === '' ? null : mutationValues.requirementIds } });
    };

    const handleCloseSnackbarDraft = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenDraft(false);
    };

    useEffect(() => {
        if (parametersData !== undefined) {
            let parameters = [];
            for (let i of parametersData.parameters) {
                parameters.push({ parameterId: i.parameterId, name: i.name, physicalQuantity: i.physicalQuantity.name, unit: i.unit === null ? "-" : i.unit.abbreviation, description: i.description, selected: false, active: i.active });
            }
            setAllParameters(parameters);
            setActiveParameters(parameters);
            getTestSpecData();
        }
    }, [parametersData]); // eslint-disable-line

    useEffect(() => {
        if (testSpecification !== undefined) {
            currentTestSpecificationId(testSpecification?.testSpecification?.testSpecificationId)
            setFormValues({ ...formValues, category: testSpecification?.testSpecification?.category?.name, approval: testSpecification?.testSpecification?.approval?.name, groupStandardReference: testSpecification?.testSpecification?.groupStandardReference?.name });
            setMutationValues({ ...mutationValues, approval: testSpecification?.testSpecification?.approval?.approvalId, groupStandardReference: testSpecification?.testSpecification?.groupStandardReference?.groupStandardReferenceId, name: testSpecification?.testSpecification?.name, version: testSpecification?.testSpecification?.version, tag: testSpecification?.testSpecification?.tag, category: testSpecification?.testSpecification?.category.categoryId, description: testSpecification?.testSpecification?.description, terminationCriteria: testSpecification?.testSpecification?.terminationCriteria, testProtocolContents: testSpecification?.testSpecification?.testProtocolContents, postCondition: testSpecification?.testSpecification?.postCondition, reference: testSpecification?.testSpecification?.reference, testDuration: testSpecification?.testSpecification?.testDuration, requirementIds: testSpecification?.testSpecification?.requirementIds })
            setRelations(testSpecification?.testSpecification?.relations);
            insertSteps(testSpecification?.testSpecification?.pretreatmentSteps, 'Pre Treatment');
            insertSteps(testSpecification?.testSpecification?.procedureSteps, 'Procedure');
            insertParameters(testSpecification?.testSpecification?.parameters);
            let allDocs = [];
            if (testSpecification?.testSpecification?.documents != undefined) {
                for (let i of testSpecification.testSpecification.documents) {
                    allDocs.push(i);
                }
                setDocList({ documents: allDocs });
            }
            if (testSpecification?.testSpecification?.tag === "DRAFT") {
                setOpenDraft(true);
            }
        }
    }, [testSpecification]); // eslint-disable-line

    //validation for testDuration
    useEffect(() => {
        const num = /^-?\d+$/;
        if (num.test(mutationValues.testDuration) || mutationValues.testDuration === "" || mutationValues.testDuration === null) {
            setCheckTestDuration(true);
        } else {
            setCheckTestDuration(false);
        }
    }, [mutationValues.testDuration]);

    //validation for requirementIds
    useEffect(() => {
        // [0-9]{1,5}(; [0-9]{1,5})*
        const reg = /^[0-9]{1,5}(; [0-9]{1,5})*$/;
        if (reg.test(mutationValues.requirementIds) || mutationValues.requirementIds === "" || mutationValues.requirementIds === null) {
            setCheckRequirementIds(true);
        } else {
            setCheckRequirementIds(false);
        }
    }, [mutationValues?.requirementIds]);

    return <>
        {/* DRAFT SNACKBARS */}
        {mutationValues.tag === "DRAFT" &&
            <div className={classes.snackbar}>
                <Snackbar variant="topbar" open={openDraft} onClose={handleCloseSnackbarDraft}>
                    <div>
                        <Alert onClose={handleCloseSnackbarDraft} severity="info">
                            This Test Specification is marked as Draft.
                        </Alert>
                    </div>
                </Snackbar>
            </div>
        }

        <ConditionalWrap condition={testSpecId}>
            <>

                {/* ERROR */}
                {hasError() &&
                    <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 64px - 24px - 24px)' }}>
                        <Box p={4} display="flex" alignItems="center">
                            <Typography variant="h2">
                                Test Specification Title
                            </Typography>
                        </Box>
                        <TestSpecErrorMessage testSpecificationError={testSpecificationError} categoriesError={categoriesError} approvalsError={approvalsError} allGroupStandardReferencesError={allGroupStandardReferencesError} allParametersError={parametersDataError} />
                    </Box>
                }

                {/* LOADING */}
                {(isLoading() && !hasError() || lockLoading) &&
                    <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ minHeight: 'calc(100vh - 64px - 24px - 24px)' }}>
                        <Box p={4} display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                            <Typography variant="h2">
                                Test Specification Title
                            </Typography>
                        </Box>
                        <Box mx={4} display="flex" alignItems="center" justifyContent="center" flexGrow="1">
                            <BatteryLoader type="standard" />
                        </Box>
                    </Box>
                }

                {!isLoading() && !hasError() && !lockLoading &&
                    <Box display="flex" flexDirection="column" justifyContent="space-between" style={testSpecId ? { minHeight: 'none' } : { minHeight: 'calc(100vh - 128px - 64px - 24px - 24px)' }}>
                        <Box m={4} display="flex" flexDirection="column">
                            {/* HEADER */}
                            <Box pb={4} display="flex" alignItems="baseline">
                                <Typography variant="h2">
                                    {mutationValues.name}
                                </Typography>
                                <Typography variant="subtitle1" style={{ marginLeft: '8px' }}>
                                    V. {mutationValues.version}
                                </Typography>
                                <Typography variant="subtitle1" style={{ marginLeft: '8px', marginRight: '8px' }}>
                                    -
                                </Typography>
                                <Typography variant="subtitle1">
                                    ID {testSpecificationId}
                                </Typography>

                                {role === 'Administrator' && <AddEditData 
                                    edit={true} 
                                    headline={'Test Specification'} 
                                    name={mutationValues.name} 
                                    idValue={undefined} 
                                    mutateFunction={updateTestSpecName} 
                                    maxCharacter={50} 
                                    activeValues={[]} 
                                    inactiveValues={[]} 
                                    queryName={undefined}
                                    isTSNameUpdate={true}
                                />}
                            </Box>

                            {/* TITLE - VERSION - FINALIZE DRAFT / ADD NEW VERSION */}
                            <Grid item xs={12} display="grid">
                                <Grid container spacing={0}>
                                    {/* TITLE */}
                                    <Grid item xs={4}>
                                        <Box pr={2} display="flex" flexDirection="column">
                                            {(mutationValues.tag !== "DRAFT" || role === 'Auditor User') ?
                                                <>
                                                    <Typography variant="caption">
                                                        Title
                                                    </Typography>
                                                    <Typography mt={0.5} variant="body1">
                                                        {mutationValues.name}
                                                    </Typography>
                                                </>
                                                :
                                                <>
                                                    <InputLabel className={classes.input_title}>Title *</InputLabel>
                                                    <InputBase
                                                        name="testSpecificationTitle"
                                                        value={mutationValues.name}
                                                        disabled={true}
                                                    />
                                                </>
                                            }
                                        </Box>
                                    </Grid>
                                    {/* VERSION */}
                                    <Grid item xs={4}>
                                        <Box px={1} display="flex" flexDirection="column">
                                            {(mutationValues.tag !== "DRAFT" || role === 'Auditor User') ?
                                                <>
                                                    <Typography variant="caption">
                                                        Version
                                                    </Typography>
                                                    <Typography mt={0.5} variant="body1">
                                                        {mutationValues.version}
                                                    </Typography>
                                                </>
                                                :
                                                <>
                                                    <InputLabel className={classes.input_title}>Version *</InputLabel>
                                                    <InputBase
                                                        name="version"
                                                        value={mutationValues.version}
                                                        disabled={true}
                                                    />
                                                </>
                                            }
                                        </Box>
                                    </Grid>
                                    {/* FINALIZE DRAFT / ADD NEW VERSION */}
                                    <Grid item xs={4}>
                                        <Box display="flex" justifyContent="flex-end">
                                            {/* FINALIZE DRAFT */}
                                            {mutationValues.tag === "DRAFT" && role === 'Administrator' &&
                                                < Box display="flex" flexDirection="row" pt={(mutationValues.tag !== "DRAFT" || role === 'Auditor User') ? 0.5 : 3}>
                                                    <LockComponent headline="Draft" mutationValues={mutationValues} pretreatmentSteps={pretreatmentSteps} procedureSteps={procedureSteps} selectedParams={selectedParams} selectedConditions={selectedConditions} />
                                                </Box>
                                            }
                                            {/* ADD NEW VERSION */}
                                            {mutationValues.tag !== "DRAFT" && !(relations.some(testSpec => testSpec.tag === 'DRAFT')) && testSpecId === undefined && (role === 'Administrator' || role === 'Project User') &&
                                                <Box display="flex" flexDirection="row" pt={(mutationValues.tag !== "DRAFT" || role === 'Auditor User') ? 0.5 : 3}>
                                                    <Button
                                                        variant="outlined"
                                                        color="secondary"
                                                        onClick={handleAddNewVersion}
                                                    >
                                                        <div style={{ display: 'flex', marginRight: '8px' }}><Icon iconName={"AddIcon"} fill={petrol800} /></div>Add new Version
                                                    </Button>
                                                </Box>
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* VERSIONS */}
                            <ScrollableVerisonTabs testSpecId={testSpecId} relations={relations} currentVersion={mutationValues.version} />

                            {/* CATEGORY - APPROVAL - REFERENCE */}
                            <Grid item xs={12} display="grid">
                                <Grid container spacing={0}>
                                    {/* CATEGORY */}
                                    <Grid item xs={4}>
                                        <Box pt={5} pr={2} display="flex" flexDirection="column">
                                            {(mutationValues.tag !== "DRAFT" || role === 'Auditor User') ?
                                                <>
                                                    <Typography variant="caption">
                                                        Category
                                                    </Typography>
                                                    <Typography mt={0.5} variant="body1">
                                                        {formValues.category ? formValues.category : '-'}
                                                    </Typography>
                                                </>
                                                :
                                                <>
                                                    <InputLabel className={classes.input_title}>Category *</InputLabel>
                                                    <Select
                                                        name="category"
                                                        value={formValues.category == null ? '' : formValues.category}
                                                        onChange={handleInputChange(setFormValues, formValues, setMutationValues, mutationValues)}
                                                    >
                                                        {categories?.categories.map((option) => (
                                                            <MenuItem
                                                                datamutationvalue={option.categoryId}
                                                                key={option.categoryId}
                                                                value={option.name}
                                                                disabled={!option.active}
                                                            >
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </>
                                            }
                                        </Box>
                                    </Grid>
                                    {/* APPROVAL */}
                                    <Grid item xs={4}>
                                        <Box pt={5} px={1} display="flex" flexDirection="column" justifyContent="center">
                                            {(mutationValues.tag !== "DRAFT" || role === 'Auditor User') ?
                                                <>
                                                    <Typography variant="caption">
                                                        Approval
                                                    </Typography>
                                                    <Typography mt={0.5} variant="body1">
                                                        {formValues.approval ? formValues.approval : '-'}
                                                    </Typography>
                                                </>
                                                :
                                                <>
                                                    <InputLabel className={classes.input_title}>Approval</InputLabel>
                                                    <Select
                                                        name="approval"
                                                        value={formValues.approval == null ? '' : formValues.approval}
                                                        onChange={handleInputChange(setFormValues, formValues, setMutationValues, mutationValues)}
                                                    >
                                                        <MenuItem
                                                            value=''
                                                            datamutationvalue=''
                                                        >
                                                            <em>None</em>
                                                        </MenuItem>
                                                        {approvals?.approvals.map((option) => (
                                                            <MenuItem
                                                                datamutationvalue={option.approvalId}
                                                                key={option.approvalId}
                                                                value={option.name}
                                                                disabled={!option.active}
                                                            >
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </>
                                            }
                                        </Box>
                                    </Grid>
                                    {/* REFERENCE */}
                                    <Grid item xs={4}>
                                        <Box pt={5} pl={2} display="flex" flexDirection="column" justifyContent="center">
                                            {(mutationValues.tag !== "DRAFT" || role === 'Auditor User') ?
                                                <>
                                                    <Typography variant="caption">
                                                        Reference
                                                    </Typography>
                                                    <Typography mt={0.5} variant="body1">
                                                        {formValues.groupStandardReference ? formValues.groupStandardReference : '-'}
                                                    </Typography>
                                                </>
                                                :
                                                <>
                                                    <InputLabel className={classes.input_title}>Reference</InputLabel>
                                                    <Select
                                                        name="groupStandardReference"
                                                        value={formValues.groupStandardReference == null ? '' : formValues.groupStandardReference}
                                                        onChange={handleInputChange(setFormValues, formValues, setMutationValues, mutationValues)}
                                                    >
                                                        <MenuItem
                                                            value=''
                                                            datamutationvalue=''
                                                        >
                                                            <em>None</em>
                                                        </MenuItem>
                                                        {allGroupStandardReferences?.groupStandardReferences.map((option) => (
                                                            <MenuItem
                                                                datamutationvalue={option.groupStandardReferenceId}
                                                                key={option.groupStandardReferenceId}
                                                                value={option.name}
                                                                disabled={!option.active}
                                                            >
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </>
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* REQ IDS*/}
                            <Grid item xs={12} display="grid">
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Box mt={(mutationValues.tag !== "DRAFT" || role === 'Auditor User') ? 5 : 3}>
                                            {(mutationValues.tag !== "DRAFT" || role === 'Auditor User') ?
                                                <>
                                                    <Typography variant="caption">
                                                        Referred Requirement IDs
                                                    </Typography>
                                                    <Typography mt={0.5} variant="body1">
                                                        {mutationValues.requirementIds ? mutationValues.requirementIds : '-'}
                                                    </Typography>
                                                </>
                                                :
                                                <>
                                                    <Box pb={0.5} display="flex" alignItems="center">
                                                        <InputLabel>Referred Requirement IDs</InputLabel>
                                                        <RequirementIdsInfo />
                                                    </Box>
                                                    <InputBase
                                                        name="requirementIds"
                                                        value={mutationValues.requirementIds == null ? '' : mutationValues.requirementIds}
                                                        onChange={handleInputChangeFreeText(setMutationValues, mutationValues)}
                                                        error={!checkRequirementIds}
                                                    />
                                                </>
                                            }
                                            {!checkRequirementIds &&
                                                <Typography variant="subtitle2" className={classes.alert_message}>Incorrect input format. Check info icon above for further information.</Typography>
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* DESCRIPTION*/}
                            <Grid item xs={12} display="grid">
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Box mt={(mutationValues.tag !== "DRAFT" || role === 'Auditor User') ? 5 : 3} display="flex" flexDirection="column" alignItems="flex-start">
                                            {(mutationValues.tag !== "DRAFT" || role === 'Auditor User') ?
                                                <>
                                                    <Typography variant="caption">
                                                        Description
                                                    </Typography>
                                                    <Typography mt={0.5} variant="body1">
                                                        {mutationValues.description ? mutationValues.description : '-'}
                                                    </Typography>
                                                </>
                                                :
                                                <>
                                                    <InputLabel className={classes.input_title}>Description</InputLabel>
                                                    <InputBase
                                                        name="description"
                                                        multiline={true}
                                                        fullWidth={true}
                                                        value={mutationValues.description == null ? '' : mutationValues.description}
                                                        onChange={handleInputChangeFreeText(setMutationValues, mutationValues)}
                                                    />
                                                </>
                                            }
                                            {mutationValues.description.length > 2000 &&
                                                <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 2000 characters.</Typography>
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* DOCUMENTS */}
                            <Box pt={(mutationValues.tag !== "DRAFT" || role === 'Auditor User') ? 5 : 4}>
                                <DocumentListTable access="test specification" appendId={testSpecificationId} documents={docList} testSpecId={testSpecId} />
                            </Box>

                            {/* PARAMETERS & CONDITIONS */}
                            <Box pt={5} display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="h3">
                                    Parameters & Conditions
                                </Typography>
                                <Box display="flex">
                                    {selectedParams?.length > 0 && conditionCounter < 12 && mutationValues.tag === "DRAFT" && (role === 'Administrator' || role === 'Project User') &&
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={() => handleAddConditionColumn()}
                                        >
                                            <div style={{ display: 'flex', marginRight: '8px' }}>
                                                <Icon iconName={"AddIcon"} fill={petrol800} />
                                            </div>
                                            Add Condition
                                        </Button>
                                    }
                                    {mutationValues.tag === "DRAFT" && (role === 'Administrator' || role === 'Project User') &&
                                        <ParameterSelection
                                            conditionCounter={conditionCounter}
                                            setConditionCounter={setConditionCounter}
                                            allParameters={allParameters}
                                            setAllParameters={setAllParameters}
                                            selectedParameters={selectedParams}
                                            setSelectedParameters={setSelectedParams}
                                            selectedConditions={selectedConditions}
                                            setSelectedConditions={setSelectedConditions}
                                            activeParameters={activeParameters}
                                            setActiveParameters={setActiveParameters}
                                        />
                                    }
                                </Box>
                            </Box>

                            <Box mt={2} display="flex" flexDirection="column" className={classes.parameter_box}>
                                <Box pt={3} px={3} display="flex" style={conditionCounter > 0 ? { marginBottom: '0px' } : { marginBottom: '4px' }}>
                                    {selectedParams?.length < 1 &&
                                        <Box pb={2} display="flex" flexDirection="column" alignItems="flex-start">
                                            <Box display="flex" alignItems="center">
                                                {mutationValues.tag === "DRAFT" &&
                                                    <Typography variant="h5" className={classes.parameter_add_first}>
                                                        No parameters have been added yet.
                                                    </Typography>
                                                }
                                                {mutationValues.tag !== "DRAFT" &&
                                                    <Typography variant="h5" className={classes.parameter_add_first}>
                                                        No parameters were added to this test specification.
                                                    </Typography>
                                                }
                                            </Box>
                                        </Box>
                                    }
                                    {selectedParams?.length > 0 &&
                                        <>
                                            <Box display="flex" flexDirection="column">
                                                <Box className={classes.condition_title}>
                                                    <Typography variant="h4" >
                                                        Parameter
                                                    </Typography>
                                                </Box>
                                                <Box mt={4} mb={1} display="flex" flexDirection="column" alignItems="flex-start">
                                                    {selectedParams?.map(function (i, index) {
                                                        return (
                                                            <Box key={i.parameter?.name + index} display="flex" alignItems="center" className={classes.assigned_parameter}>
                                                                <ParameterInfo parameter={i.parameter?.name} description={i.parameter?.description} active={i.parameter?.active} />
                                                                {mutationValues.tag === "DRAFT" && (role === 'Administrator' || role === 'Project User') &&
                                                                    <IconButton
                                                                        className={classes.error_icon_wrapper}
                                                                        onClick={() => handleRemoveParameter(index)}
                                                                        size="large">
                                                                        <Icon iconName={"AddIcon"} fill={grey1000} />
                                                                    </IconButton>
                                                                }
                                                            </Box>
                                                        );
                                                    })}
                                                </Box>
                                            </Box>
                                            {conditionCounter > 0 &&
                                                <Box ml={3} overflow="auto">
                                                    <Box display="flex">
                                                        {selectedConditions[0].map(function (i, index) {
                                                            return (
                                                                <Box ml={3} key={index} style={{ width: '185px' }} display="flex" flexDirection="row" justifyContent="space-between" className={classes.condition_title}>
                                                                    <Typography variant="h4" >
                                                                        Condition {index + 1}
                                                                    </Typography>
                                                                    {mutationValues.tag === "DRAFT" && (role === 'Administrator' || role === 'Project User') &&
                                                                        <IconButton
                                                                            className={classes.trash_icon_wrapper}
                                                                            onClick={() => handleRemoveConditionColumn(index)}
                                                                            size="large">
                                                                            <Icon iconName={"TrashIcon"} fill={petrol800} />
                                                                        </IconButton>
                                                                    }
                                                                </Box>
                                                            );
                                                        })
                                                        }
                                                    </Box>
                                                    <Box mt={4}>
                                                        {selectedParams?.map(function (i, indexi) {
                                                            return (
                                                                <Box key={indexi} display="flex" flexDirection="row" alignItems="center" >
                                                                    {selectedConditions[indexi].map(function (j, indexj) {
                                                                        return (
                                                                            <Box ml={3} key={indexj} className={classes.condition_box}>
                                                                                <InputBase
                                                                                    id={`${indexi},${indexj}`}
                                                                                    name="condition"
                                                                                    value={j.condition}
                                                                                    disabled={mutationValues.tag !== "DRAFT" || role === 'Auditor User'}
                                                                                    className={classes.condition_value}
                                                                                    onChange={event => handleConditionChange(event, indexi, indexj)}
                                                                                >
                                                                                </InputBase>
                                                                                <Typography variant="h5" className={classes.condition_unit}>
                                                                                    {i.parameter?.unit}
                                                                                </Typography>
                                                                            </Box>
                                                                        )
                                                                    })}
                                                                </Box>
                                                            )
                                                        })}
                                                    </Box>
                                                </Box>
                                            }
                                        </>
                                    }
                                </Box>
                            </Box>

                            {/* PRE TREATMENT STEPS */}
                            <Box id="search-node">
                                <Box mt={5} display="flex" flexDirection="column" className={classes.steps_box}>
                                    <Grid item xs={12}>
                                        <Box mx={3} my={3} display="flex">
                                            <Typography variant="h3">
                                                Pre Treatment / Pre Condition Steps
                                            </Typography>
                                        </Box>
                                        {pretreatmentSteps.map(function (i, index) {
                                            return (
                                                <StepDescription key={index} steps={pretreatmentSteps} setSteps={setPretreatmentSteps} step={i} stepIndex={index} selectedParameters={selectedParams} category={'Pre Treatment'} mutationValues={mutationValues} readOnly={role === 'Auditor User'} />
                                            )
                                        })}
                                        {mutationValues.tag !== "DRAFT" && pretreatmentSteps.length < 1 &&
                                            <Box mx={3} mb={3} display="flex">
                                                <Typography variant="h5" className={classes.parameter_add_first}>
                                                    No steps were added.
                                                </Typography>
                                            </Box>
                                        }
                                        {mutationValues.tag === "DRAFT" && (role === 'Administrator' || role === 'Project User') &&
                                            <AddNewStep selectedParameters={selectedParams} steps={pretreatmentSteps} setSteps={setPretreatmentSteps} category={'Pre Treatment'} />
                                        }
                                    </Grid>
                                </Box>

                                {/* PROCEDURE STEPS */}
                                <Box mt={5} display="flex" flexDirection="column" className={classes.steps_box}>
                                    <Grid item xs={12}>
                                        <Box mx={3} my={3} display="flex">
                                            <Typography variant="h3">
                                                Procedure Steps *
                                            </Typography>
                                        </Box>
                                        {procedureSteps.map(function (i, index) {
                                            return (
                                                <StepDescription key={index} steps={procedureSteps} setSteps={setProcedureSteps} step={i} stepIndex={index} selectedParameters={selectedParams} category={'Procedure'} mutationValues={mutationValues} readOnly={role === 'Auditor User'} />
                                            )
                                        })}
                                        {mutationValues.tag === "DRAFT" && (role === 'Administrator' || role === 'Project User') &&
                                            <AddNewStep selectedParameters={selectedParams} steps={procedureSteps} setSteps={setProcedureSteps} category={'Procedure'} />
                                        }
                                    </Grid>
                                </Box>
                            </Box>

                            {/* TERMINATION CRITERIA - TEST PROTOCOAL CONTENTS */}
                            <Grid item xs={12} display="grid">
                                <Grid container spacing={0}>
                                    {/* TERMINATION CRITERIA */}
                                    <Grid item xs={6}>
                                        <Box pt={5} pr={1.5} display="flex" flexDirection="column" alignItems="flex-start">
                                            {(mutationValues.tag !== "DRAFT" || role === 'Auditor User') ?
                                                <>
                                                    <Typography mb={0.5} variant="caption">
                                                        Termination Criteria
                                                    </Typography>
                                                    <InputBase
                                                        name="terminationCriteria"
                                                        multiline={true}
                                                        fullWidth={true}
                                                        disabled={true}
                                                        value={mutationValues.terminationCriteria ? mutationValues.terminationCriteria : '-'}
                                                        sx={{
                                                            border: 'none',
                                                            "& .MuiInputBase-input": {
                                                                padding: '0px !important',
                                                            },
                                                            "& .MuiInputBase-input.Mui-disabled": {
                                                                WebkitTextFillColor: grey1000,
                                                            },
                                                        }}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <InputLabel className={classes.input_title}>Termination Criteria</InputLabel>
                                                    <InputBase
                                                        name="terminationCriteria"
                                                        multiline={true}
                                                        fullWidth={true}
                                                        value={mutationValues.terminationCriteria ? mutationValues.terminationCriteria : ''}
                                                        onChange={handleInputChangeFreeText(setMutationValues, mutationValues)}
                                                    />
                                                </>
                                            }
                                            {mutationValues.terminationCriteria.length > 2000 &&
                                                <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 2000 characters.</Typography>
                                            }
                                        </Box>
                                    </Grid>
                                    {/* TEST PROTOCOAL CONTENTS */}
                                    <Grid item xs={6}>
                                        <Box pt={5} pl={1.5} display="flex" flexDirection="column" alignItems="flex-start">
                                            {(mutationValues.tag !== "DRAFT" || role === 'Auditor User') ?
                                                <>
                                                    <Typography mb={0.5} variant="caption">
                                                        Test Protocol Contents / Measurement Values
                                                    </Typography>
                                                    <InputBase
                                                        name="testProtocolContents"
                                                        multiline={true}
                                                        fullWidth={true}
                                                        disabled={true}
                                                        value={mutationValues.testProtocolContents ? mutationValues.testProtocolContents : '-'}
                                                        sx={{
                                                            border: 'none',
                                                            "& .MuiInputBase-input": {
                                                                padding: '0px !important',
                                                            },
                                                            "& .MuiInputBase-input.Mui-disabled": {
                                                                WebkitTextFillColor: grey1000,
                                                            },
                                                        }}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <InputLabel className={classes.input_title}>Test Protocol Contents / Measurement Values</InputLabel>
                                                    <InputBase
                                                        name="testProtocolContents"
                                                        multiline={true}
                                                        fullWidth={true}
                                                        value={mutationValues.testProtocolContents ? mutationValues.testProtocolContents : ''}
                                                        onChange={handleInputChangeFreeText(setMutationValues, mutationValues)}
                                                    />
                                                </>
                                            }
                                            {mutationValues.testProtocolContents.length > 2000 &&
                                                <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 2000 characters.</Typography>
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* POST CONDITION - REFERENCE */}
                            <Grid item xs={12} display="grid">
                                <Grid container spacing={0}>
                                    {/* POST CONDITION */}
                                    <Grid item xs={6}>
                                        <Box pt={(mutationValues.tag !== "DRAFT" || role === 'Auditor User') ? 5 : 3} pr={1.5} display="flex" flexDirection="column" alignItems="flex-start">
                                            {(mutationValues.tag !== "DRAFT" || role === 'Auditor User') ?
                                                <>
                                                    <Typography mb={0.5} variant="caption">
                                                        Post Condition
                                                    </Typography>
                                                    <InputBase
                                                        name="postCondition"
                                                        multiline={true}
                                                        fullWidth={true}
                                                        disabled={true}
                                                        value={mutationValues.postCondition ? mutationValues.postCondition : '-'}
                                                        sx={{
                                                            border: 'none',
                                                            "& .MuiInputBase-input": {
                                                                padding: '0px !important',
                                                            },
                                                            "& .MuiInputBase-input.Mui-disabled": {
                                                                WebkitTextFillColor: grey1000,
                                                            },
                                                        }}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <InputLabel className={classes.input_title}>Post Condition</InputLabel>
                                                    <InputBase
                                                        name="postCondition"
                                                        multiline={true}
                                                        fullWidth={true}
                                                        value={mutationValues.postCondition ? mutationValues.postCondition : ''}
                                                        onChange={handleInputChangeFreeText(setMutationValues, mutationValues)}
                                                    />
                                                </>
                                            }
                                            {mutationValues.postCondition.length > 2000 &&
                                                <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 2000 characters.</Typography>
                                            }
                                        </Box>
                                    </Grid>
                                    {/* REFERENCE */}
                                    <Grid item xs={6}>
                                        <Box pt={(mutationValues.tag !== "DRAFT" || role === 'Auditor User') ? 5 : 3} pl={1.5} display="flex" flexDirection="column" alignItems="flex-start">
                                            {(mutationValues.tag !== "DRAFT" || role === 'Auditor User') ?
                                                <>
                                                    <Typography mb={0.5} variant="caption">
                                                        Reference
                                                    </Typography>
                                                    <InputBase
                                                        name="reference"
                                                        multiline={true}
                                                        fullWidth={true}
                                                        disabled={true}
                                                        value={mutationValues.reference ? mutationValues.reference : '-'}
                                                        sx={{
                                                            border: 'none',
                                                            "& .MuiInputBase-input": {
                                                                padding: '0px !important',
                                                            },
                                                            "& .MuiInputBase-input.Mui-disabled": {
                                                                WebkitTextFillColor: grey1000,
                                                            },
                                                        }}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <InputLabel className={classes.input_title}>Reference</InputLabel>
                                                    <InputBase
                                                        name="reference"
                                                        multiline={true}
                                                        fullWidth={true}
                                                        value={mutationValues.reference ? mutationValues.reference : ''}
                                                        onChange={handleInputChangeFreeText(setMutationValues, mutationValues)}
                                                    />
                                                </>
                                            }
                                            {mutationValues.reference.length > 2000 &&
                                                <Typography variant="subtitle2" className={classes.alert_message}>This value must not contain more than 2000 characters.</Typography>
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* TEST DURATION */}
                            <Box pt={(mutationValues.tag !== "DRAFT" || role === 'Auditor User') ? 5 : 3} display="flex" flexDirection="column" alignItems="flex-start" style={{ maxWidth: '25%' }} data-testid={"test-duration"}>
                                {(mutationValues.tag !== "DRAFT" || role === 'Auditor User') ?
                                    <>
                                        <Typography variant="caption">
                                            Test Duration Estimate
                                        </Typography>
                                        <Typography mt={0.5} variant="body1">
                                            {mutationValues.testDuration ? `${mutationValues.testDuration} ${(mutationValues.testDuration === '1' || mutationValues.testDuration === 1) ? 'Day' : 'Days'}` : '-'}
                                        </Typography>
                                    </>
                                    :
                                    <>
                                        <InputLabel className={classes.input_title}>Test Duration Estimate</InputLabel>
                                        <InputBase
                                            name="testDuration"
                                            value={mutationValues.testDuration ? mutationValues.testDuration : ''}
                                            onChange={handleInputChangeFreeText(setMutationValues, mutationValues)}
                                            endAdornment={<InputAdornment position="end">{(mutationValues.testDuration === '1' || mutationValues.testDuration === 1) ? 'Day' : 'Days'}</InputAdornment>}
                                            error={!checkTestDuration}
                                        />
                                    </>
                                }
                                {!checkTestDuration &&
                                    <Typography variant="subtitle2" className={classes.alert_message}>Incorrect input format. Only integer values allowed.</Typography>
                                }
                            </Box>

                        </Box>
                    </Box>
                }
            </>
        </ConditionalWrap >
        <StickyBar
            backButton
            backpath={`/`}
            requiredField
            ksuNotification
            customButton={mutationValues.tag === "DRAFT" && 'Save Changes on Draft'}
            customOnClick={mutationValues.tag === "DRAFT" && handleSubmit}
            customDisabled={submitState()}
            scrollButton />
    </>;
}

export default TestSpecificationDetails;