import makeStyles from '@mui/styles/makeStyles';
import { grey1000, petrol600, petrol800 } from '../helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    title: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '700',    
        fontFamily: 'VWAG TheSans',
        lineHeight: '24px',
        letterSpacing: '0.8px',
        textAlign: 'left',
    },
    value: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontFamily: 'VWAG TheSans',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        textAlign: 'left',
    },
    black_value_ref: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontFamily: 'VWAG TheSans',
        fontSize: '16px',
        lineHeight: '18px',
        letterSpacing: '0.8px',
        color: grey1000,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '155px',
        textAlign: 'end'
    },
    userimage: {
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '32px',
        width: '32px',
        borderRadius: '16px',
        display: 'flex',
        margin: '8px',
    },
    tag: {
        background: petrol800,
        borderRadius: '20px',
        height: '32px',
        width: '70px',
        fontStyle: 'normal',
        fontWeight: '700',
        fontFamily: 'VWAG TheSans',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    parameter_title: {
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: '700',
        fontFamily: 'VWAG TheSans',
        lineHeight: '20px',
        letterSpacing: '0.6px',
        color: grey1000,
        cursor: 'help',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    parameter_description: {
        cursor: 'help',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
        textAlign: 'left',
    },
    normalButton: {
      color: '#FFF',
      padding: '0px 16px',
      borderRadius: '8px !important',
      border: '0 !important',
      boxShadow: 'none !important',
      marginRight: '16px',
      marginBottom: '8px',
      fontStyle: 'normal',
      fontSize: '16px',
      fontFamily: 'VWAG TheSans',
      lineHeight: '40px',
      letterSpacing: '0.6px',
      textTransform: 'capitalize',
      backgroundColor: petrol600,
      cursor: 'help',
    },
    icon_wrapper: {
        display: 'flex',
        marginRight: '8px',
    },
    test_specification_text: {   
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    test_specification_version: {
        marginLeft: '8px',
        color: petrol800,    
        display: 'flex',
        alignItems: 'center',
        lineHeight: '16px',
        fontWeight: '700',        
        fontSize: props => props.fontSizeTable,
        fontFamily: 'VWAG TheSans',
        minWidth: props => props.minWidthTable,
        paddingRight: '8px',
    },
}));