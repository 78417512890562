import makeStyles from '@mui/styles/makeStyles';
import { signalRed800, grey1000, grey100, buttonHoverPetrol800, grey400, petrol800 } from '../../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    highlight_parameter: {
        color: petrol800,
        fontWeight: 'bold',
        margin: '0 6px 0 6px'
    },
    button_add_step: {
        display: 'flex',        
        marginLeft: '24px',
        marginTop: '24px',
        marginBottom: '24px',
    },
    step_box: {
        border: `1px solid ${grey400}`,
        boxSizing: 'border-box',
        borderRadius: '4px',
    },
    menu_icon: {
        marginLeft: '16px',
    },
    step_bubble: {
        marginLeft: '16px',
        backgroundColor: grey100,
        minWidth: '50px',
        height: '50px',
        borderRadius: '50px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '22px',
        letterSpacing: '0.8px',
        color: grey1000,
    },
    step_bubble_selected: {
        marginLeft: '16px',
        backgroundColor: petrol800,
        minWidth: '50px',
        height: '50px',
        borderRadius: '50px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '22px',
        letterSpacing: '0.8px',
        color: '#FFF',
    },
    step_step: {
        marginLeft: '16px',
        minWidth: '70px',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
        textAlign: 'left',
    },
    step_description: {
        display: 'flex',
        flexWrap: 'wrap',
        overflowWrap: 'anywhere',
        marginLeft: '16px',
        flexGrow: '1',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.6px',
        color: grey1000,
        textAlign: 'left',
        maxWidth: '780px',        
        whiteSpace: 'pre-line'
    },
    parameter_hint: {
        display: "flex",
        fontWeight: "400",
        lineHeight: "normal",
        letterSpacing: "0.6px",
        marginBottom: '0',
        color: '#4D5253',
    },
    parameter_hint_italic: {
        display: "flex",
        fontWeight: "700",
        fontStyle: 'italic',
        lineHeight: "27px",
        letterSpacing: "0.6px",
        marginBottom: '0',
        color: grey1000,
        paddingLeft: '2px',
        paddingRight: '6px',
    },
    alert_message: {
        marginTop: '4px',
        color: signalRed800,
    },
    step_icon: {
        marginLeft: '8px',
        '&:hover': {
            backgroundColor: buttonHoverPetrol800,
          },
    }, 
}));