import React from 'react';
import { Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { grey100 } from '../../helper/MuiStyles.js';

const useStyles = makeStyles(() => ({
  title_box: {
    backgroundColor: grey100,
    height: 'calc(100vh - 64px)',
    width: '100vw'
  }
}
));

function NewUser() {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.app_header}>
      <Box className={classes.title_box} display="flex" flexDirection="column" justifyContent="center">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography style={{ marginBottom: '32px' }} variant="h1">Please contact your administrator in order to assign you a role for TONIC42.</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default NewUser;