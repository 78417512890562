import React from 'react';
import { IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';

import Icon from '../../../assets/icons/Icon.js';
import { buttonHoverPetrol800, grey200, grey600, petrol800 } from '../../other/helper/MuiStyles.js';

const useStyles = makeStyles(() => ({
    box: {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.1)',
        marginTop: '2px',
        marginBottom: '16px',
        marginLeft: '2px',
        marginRight: '16px',
        paddingLeft: '16px',
        paddingRight: '16px',
        height: '64px',
        borderRadius: '2px',
    },
    add_icon: {
        fill: '#006384',
        height: '32px',
    },
    button: {
        border: 'none',
        boxShadow: 'none !important',
    },
    text: {
        letterSpacing: '0.6px',
        marginRight: '16px',
    },
    reference: {
        color: grey600,
    },
    count: {
        color: grey600,
        width: '32px',
        textAlign: 'center',
    },
    line: {
        width: '1px',
        height: '40px',
        backgroundColor: grey200,
    },
    hover_blue: {
        padding: '8px',
        '&:hover': {
            backgroundColor: buttonHoverPetrol800,
        },
    },
}
));

function SelectionTestCaseBox({ testspec, addSelectedTestSpecs, reduceSelectedTestSpecs }) {
    const classes = useStyles();

    return (
        <Box key={testspec.testSpecificationId} className={classes.box} display="flex" flexDirection="column" justifyContent="center">

            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" flexDirection="column" flexWrap="wrap">
                    <Typography variant='h5' className={classes.text}>
                        {testspec.name} V. {testspec.version}
                    </Typography>
                    <Typography variant='subtitle1' className={classes.reference}>
                        {testspec.groupStandardReference}
                    </Typography>
                </Box>
                <Box>
                    <Box display="flex" alignItems="center">
                        <>
                            {testspec.amount > 0 &&
                                <IconButton onClick={() => reduceSelectedTestSpecs(testspec)} className={classes.hover_blue}>
                                    <Icon iconName={"DeleteIcon"} width={24} height={24} fill={petrol800} />
                                </IconButton>
                            }
                            {testspec.amount > 0 ? <Box mx={2} className={classes.line}></Box> : null}
                            <Typography variant='subtitle1' className={classes.count}>
                                {testspec.amount > 0 ? testspec.amount : ''}
                            </Typography>
                            {testspec.amount > 0 ? <Box mx={2} className={classes.line}></Box> : null}
                            <IconButton onClick={() => addSelectedTestSpecs(testspec)} className={classes.hover_blue}>
                                <Icon iconName={"AddIcon"} width={24} height={24} fill={petrol800} />
                            </IconButton>
                        </>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default SelectionTestCaseBox