import React, { useState, useEffect } from 'react';
import Icon from '../../../../assets/icons/Icon.js';
import IconButton from '@mui/material/IconButton';
import { useStyles } from './ScrollUpIcon.styles';
import { petrol800 } from '../MuiStyles.js';

function ScrollUpIcon() {

  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const classes = useStyles();

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 64) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility)
  }, []);

  return (
    <div className={classes.upicon}>
      {isVisible &&
        <IconButton size="small" aria-label="scroll back to top" className={classes.iconButton} onClick={scrollToTop}>
          <Icon iconName={"ArrowUpIcon"} fill={petrol800} />
        </IconButton>
      }
    </div>
  );
}



export default ScrollUpIcon;