import { sampleVar } from '../../../../operations/localeStorage'

export function handleSampleClick(value) {
    switch (value) {
      case "P":
        sampleVar(0);
        break;
      case "A":
        sampleVar(1);
        break;
      case "B":
        sampleVar(2);
        break;
      case "C":
        sampleVar(3);
        break;
      default:
        break;
    }
  }